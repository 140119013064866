import { Navigate } from 'react-router-dom';
import { RouteObject } from '../app';
import { routes as deviceGroupsRoutes } from '../device-groups/routes';
import { routes as myDevicesRoutes } from '../devices/routes';
import AcceptEulaPage, {
  loader as eulasDataLoader,
} from '../eula/accept-eula-page/accept-eula-page';
import ManageSoftwareLayout from '../software-assignment/manage-software-layout/manage-software-layout';
import { routes as softwareAssignmentRoutes } from '../software-assignment/routes';
import { routes as mySoftwareRoutes } from '../software/routes';
import EditUserPermissionsPage, {
  loader as editUserPermissionsLoader,
} from '../users/edit-user-permissions-page/edit-user-permissions-page';
import InviteUserDetailsPage from '../users/invite-user-details-page/invite-user-details-page';
import InviteUserPage from '../users/invite-user-page/invite-user-page';
import InviteUserPermissionsPage from '../users/invite-user-permissions-page/invite-user-permissions-page';
import MyTeamPage, {
  loader as myTeamPageLoader,
} from '../users/my-team-page/my-team-page';
import UserDetailsPage, {
  loader as userDetailsPageLoader,
} from '../users/user-details-page/user-details-page';
import UsersControlPanelPage, {
  loader as usersControlPanelLoader,
} from '../users/users-control-panel-page/users-control-panel-page';
import CreateDeviceGroupCardPage, {
  loader as createDeviceGroupCardPageLoader,
} from '../work-orders/create-device-group-card-page/create-device-group-card-page';
import CreateWorkOrderDetailsPage, {
  loader as createWorkOrderDetailsPageLoader,
} from '../work-orders/create-work-order-details-page/create-work-order-details-page';
import CreateWorkOrderPage from '../work-orders/create-work-order-page/create-work-order-page';
import DeviceGroupSoftwareSelectionCardPage, {
  loader as deviceGroupSoftwareSelectionLoader,
} from '../work-orders/device-group-software-selection-card-page/device-group-software-selection-card-page';
import DeviceGroupsDashboardCardPage, {
  loader as deviceGroupsDashboardCardPageLoader,
} from '../work-orders/device-groups-dashboard-card-page/device-groups-dashboard-card-page';
import EditDeviceGroupCardPage, {
  loader as editDeviceGroupCardPageLoader,
} from '../work-orders/edit-device-group-card-page/edit-device-group-card-page';
import EditWorkOrderDetailsPage from '../work-orders/edit-work-order-details-page/edit-work-order-details-page';
import ReviewSoftwareAssignmentPage from '../work-orders/review-seats-assignment-card-page/review-software-assignment-page';
import SelectSalesOrderPage, {
  loader as selectSalesOrderPageLoader,
} from '../work-orders/select-sales-order-page/select-sales-order-page';
import WorkOrderDetailsPage, {
  loader as workOrderDetailsPageLoader,
} from '../work-orders/work-order-details-page/work-order-details-page';
import WorkOrderDeviceGroupsDetailLayout, {
  loader as workOrderDeviceGroupsDetailLayoutLoader,
} from '../work-orders/work-order-device-groups-detail-layout/work-order-device-groups-detail-layout';
import WorkOrdersCompletedTab, {
  loader as workOrdersCompletedTabLoader,
} from '../work-orders/work-orders-completed-tab/work-orders-completed-tab';
import WorkOrdersDashboardPage from '../work-orders/work-orders-dashboard-page/work-orders-dashboard-page';
import WorkOrdersDefaultPage from '../work-orders/work-orders-default-page/work-orders-default-page';
import WorkOrdersDraftsTab, {
  loader as workOrderDraftsTabLoader,
} from '../work-orders/work-orders-drafts-tab/work-orders-drafts-tab';
import WorkOrdersQueueTab, {
  loader as workOrdersQueueTabLoader,
} from '../work-orders/work-orders-queue-tab/work-orders-queue-tab';
import ZspaceInternalHomePage, {
  loader as zspaceInternalHomePageLoader,
} from './zspace-internal-home-page/zspace-internal-home-page';

export const routes: RouteObject[] = [
  {
    index: true,
    loader: zspaceInternalHomePageLoader,
    element: <ZspaceInternalHomePage />,
  },
  {
    path: 'work-orders',
    children: [
      {
        index: true,
        element: <WorkOrdersDefaultPage />,
      },
      {
        path: 'dashboard',
        element: <WorkOrdersDashboardPage />,
        children: [
          {
            loader: workOrderDraftsTabLoader,
            path: 'drafts',
            element: <WorkOrdersDraftsTab />,
          },
          {
            loader: workOrdersQueueTabLoader,
            path: 'queue',
            element: <WorkOrdersQueueTab />,
          },
          {
            loader: workOrdersCompletedTabLoader,
            path: 'completed',
            element: <WorkOrdersCompletedTab />,
          },
        ],
      },
      {
        path: 'create',
        element: <CreateWorkOrderPage />,
        children: [
          {
            index: true,
            element: <Navigate to="sales-orders" replace />,
          },
          {
            path: 'sales-orders',
            loader: selectSalesOrderPageLoader,
            element: <SelectSalesOrderPage />,
          },
        ],
      },
      {
        loader: createWorkOrderDetailsPageLoader,
        path: 'create/details',
        element: <CreateWorkOrderDetailsPage />,
      },
      {
        loader: workOrderDeviceGroupsDetailLayoutLoader,
        path: ':id/edit',
        element: <EditWorkOrderDetailsPage />,
      },
      {
        loader: workOrderDetailsPageLoader,
        path: ':id',
        element: <WorkOrderDetailsPage />,
      },
      {
        path: ':id/device-groups',
        element: <WorkOrderDeviceGroupsDetailLayout />,
        loader: workOrderDeviceGroupsDetailLayoutLoader,
        children: [
          {
            index: true,
            loader: deviceGroupsDashboardCardPageLoader,
            element: <DeviceGroupsDashboardCardPage />,
          },
          {
            path: 'create',
            loader: createDeviceGroupCardPageLoader,
            element: <CreateDeviceGroupCardPage />,
          },
          {
            path: ':groupId/edit',
            loader: editDeviceGroupCardPageLoader,
            element: <EditDeviceGroupCardPage />,
          },
          {
            path: 'device-models/:modelId',
            element: <ManageSoftwareLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={'software'} replace />,
              },
              {
                path: 'software',
                children: [
                  {
                    index: true,
                    element: <Navigate to={'select'} replace />,
                  },
                  {
                    path: 'select',
                    loader: deviceGroupSoftwareSelectionLoader,
                    element: <DeviceGroupSoftwareSelectionCardPage />,
                  },
                  {
                    path: 'review',
                    element: <ReviewSoftwareAssignmentPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'users',
    children: [
      {
        index: true,
        element: <Navigate to="/users/panel" replace />,
      },
      {
        loader: usersControlPanelLoader,
        path: 'panel',
        element: <UsersControlPanelPage />,
      },
      {
        path: 'panel/invite',
        element: <InviteUserPage internalViewMode />,
        children: [
          {
            index: true,
            element: <Navigate to="details" replace />,
          },
          {
            path: 'details',
            element: <InviteUserDetailsPage />,
          },
        ],
      },
      {
        loader: myTeamPageLoader,
        path: 'my-team',
        element: <MyTeamPage />,
      },
      {
        path: 'my-team/invite',
        element: <InviteUserPage />,
        children: [
          {
            index: true,
            element: <Navigate to="details" replace />,
          },
          {
            path: 'details',
            element: <InviteUserDetailsPage />,
          },
          {
            path: 'permissions',
            element: <InviteUserPermissionsPage />,
          },
        ],
      },
      {
        loader: editUserPermissionsLoader,
        path: 'my-team/:id/edit',
        element: <EditUserPermissionsPage />,
      },
      {
        loader: userDetailsPageLoader,
        path: ':id',
        element: <UserDetailsPage />,
      },
    ],
  },
  {
    loader: eulasDataLoader,
    path: 'eula',
    element: <AcceptEulaPage />,
    isMobileFriendly: true,
  },
  ...mySoftwareRoutes,
  ...myDevicesRoutes,
  ...deviceGroupsRoutes,
  ...softwareAssignmentRoutes,
];
