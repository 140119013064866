import { useCallback, useState } from 'react';
import { Element, Form, Icon, Tag } from 'react-bulma-components';
import { FaChevronDown } from 'react-icons/fa6';
import FeedbackMessage from '../feedback-message/feedback-message';
import styles from './tag-list-input.module.scss';

type BulmaFormInput = React.ComponentProps<typeof Form.Input>;

export type TagListInputProps = BulmaFormInput & {
  elements: string[];
  onChange: (elements: string[]) => void;
  keys?: string[];
  validate?: (value: string) => boolean;
  errorText?: string;
};

export function TagListInput({
  elements,
  onChange,
  keys = ['Enter', ' ', ','],
  validate = () => true,
  errorText,
  ...inputProps
}: TagListInputProps) {
  const [input, setInput] = useState('');
  const [showError, setShowError] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);

  const handleInputChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setInput(evt.target.value);
      if (evt.target.value === '') {
        setShowError(false);
      }
    },
    []
  );

  const handleOnChange = useCallback(() => {
    const value = input.trim();
    const isValid = validate(value);
    if (value !== '') {
      if (isValid) {
        onChange([...elements, value]);
        setInput('');
        setShowError(false);
      } else {
        setShowError(true);
      }
    }
  }, [elements, input, onChange, validate]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (evt) => {
        if (['Enter', ' ', ','].includes(evt.key)) {
          evt.preventDefault();

          handleOnChange();
        }
      },
      [handleOnChange]
    );

  const handleElementRemove = useCallback(
    (index: number) => {
      const newElements = [...elements];
      newElements.splice(index, 1);
      onChange(newElements);
    },
    [elements, onChange]
  );

  const handleOnBlur = useCallback(() => {
    handleOnChange();
    setIsInputActive(false);
  }, [handleOnChange]);

  return (
    <>
      <Form.Control>
        <Form.Input
          className="placeholder-text-gray"
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsInputActive(true)}
          onBlur={handleOnBlur}
          onMouseEnter={() => setIsInputActive(true)}
          onMouseLeave={() => setIsInputActive(false)}
          {...inputProps}
        />
        <Icon align="right" size={'medium'}>
          <FaChevronDown
            className={
              isInputActive ? styles.chevronDownActive : styles.chevronDown
            }
          />
        </Icon>
      </Form.Control>

      {showError && errorText && <FeedbackMessage>{errorText}</FeedbackMessage>}

      <Element mt={2} display="flex" alignItems="center" flexWrap="wrap">
        {elements.map((element, index) => (
          <Tag.Group
            hasAddons
            key={`${index}-${element}`}
            className={`mr-2 ${index === elements.length - 1 ? 'mb-4' : ''}`}
          >
            <Tag
              rounded
              remove
              className={'is-primary is-light'}
              colorVariant={'light'}
              onClick={() => handleElementRemove(index)}
            />
            <Tag
              rounded
              className={'is-primary is-light'}
              colorVariant={'light'}
            >
              {element}
            </Tag>
          </Tag.Group>
        ))}
      </Element>
    </>
  );
}

export default TagListInput;
