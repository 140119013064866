import {
  DeviceGroupsCriteria,
  DeviceHomeAlertData,
  DeviceModel,
  MyDevicesData,
} from '@zspace/types';
import { http } from '../shared/http';

export interface DevicesService {
  fetchDeviceModels: () => Promise<DeviceModel[]>;
  fetchMyDevices: (criteria: DeviceGroupsCriteria) => Promise<MyDevicesData>;
  registerDevice: (
    serialNumber: string,
    approvalUrlCode: string
  ) => Promise<void>;
  fetchDevicesHomeAlertData: () => Promise<DeviceHomeAlertData>;
}

export class DevicesServiceImpl implements DevicesService {
  public async fetchDeviceModels(): Promise<DeviceModel[]> {
    const { data } = await http('/v1/devices/models');

    return data;
  }

  public async fetchMyDevices(
    criteria: DeviceGroupsCriteria
  ): Promise<MyDevicesData> {
    const { data } = await http(
      '/v1/devices?' +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async registerDevice(serialNumber: string, approvalUrlCode: string) {
    const params = {
      serialNumber,
      approvalUrlCode,
    };
    await http.post(
      '/v1/devices?' +
        new URLSearchParams(params as unknown as Record<string, string>)
    );
  }

  public async fetchDevicesHomeAlertData(): Promise<DeviceHomeAlertData> {
    const { data } = await http('/v1/devices/home-alerts');
    return data;
  }
}

export const fetchDeviceModels = async (): Promise<DeviceModel[]> => {
  const service = new DevicesServiceImpl();

  return service.fetchDeviceModels();
};

export const fetchMyDevices = async (
  criteria: DeviceGroupsCriteria
): Promise<MyDevicesData> => {
  const service = new DevicesServiceImpl();

  return service.fetchMyDevices(criteria);
};

export const registerDevice = async (
  serialNumber: string,
  approvalUrlCode: string
): Promise<void> => {
  const service = new DevicesServiceImpl();

  return service.registerDevice(serialNumber, approvalUrlCode);
};

export const fetchDevicesHomeAlertData =
  async (): Promise<DeviceHomeAlertData> => {
    const service = new DevicesServiceImpl();

    return service.fetchDevicesHomeAlertData();
  };
