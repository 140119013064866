import {
  BasicDeviceGroupData,
  Device,
  DeviceGroup,
  DeviceGroupDetails,
  DeviceGroupsCriteria,
  MoveDevicesData,
  NewDeviceGroup,
  PaginatedAPIResponse,
} from '@zspace/types';
import { http } from '../shared/http';

export interface DeviceGroupsService {
  fetchDeviceGroup: (id: string) => Promise<DeviceGroup>;
  fetchDeviceGroups: (
    criteria: DeviceGroupsCriteria
  ) => Promise<PaginatedAPIResponse<DeviceGroup>>;
  fetchDeviceGroupDevices: (
    deviceGroupId: string,
    criteria: DeviceGroupsCriteria
  ) => Promise<PaginatedAPIResponse<Device>>;
  fetchAllAvailableDeviceGroups: () => Promise<BasicDeviceGroupData[]>;
  deleteDeviceGroupById: (deviceGroupId: string) => Promise<void>;
  moveDevicesToDeviceGroup: (moveDevicesData: MoveDevicesData) => Promise<void>;
  removeDevicesFromDeviceGroup: (deviceIds: string[]) => Promise<void>;
  createDeviceGroup(newDeviceGroup: NewDeviceGroup): Promise<DeviceGroup>;
  updateDeviceGroup(
    id: string,
    deviceGroupData: DeviceGroupDetails
  ): Promise<DeviceGroup>;
}

export class DeviceGroupServiceImpl implements DeviceGroupsService {
  public async fetchDeviceGroup(id: string): Promise<DeviceGroup> {
    const { data } = await http(`/v1/device-groups/${id}`);

    return data;
  }

  public async fetchDeviceGroups(
    criteria: DeviceGroupsCriteria
  ): Promise<PaginatedAPIResponse<DeviceGroup>> {
    const { data } = await http(
      '/v1/device-groups?' +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchDeviceGroupDevices(
    deviceGroupId: string,
    criteria: DeviceGroupsCriteria
  ): Promise<PaginatedAPIResponse<Device>> {
    const { data } = await http(
      `/v1/device-groups/${deviceGroupId}/devices?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchAllAvailableDeviceGroups(): Promise<
    BasicDeviceGroupData[]
  > {
    const { data } = await http('/v1/device-groups/available');

    return data;
  }

  public async deleteDeviceGroupById(deviceGroupId: string): Promise<void> {
    return await http.delete(`/v1/device-groups/${deviceGroupId}`);
  }

  public async moveDevicesToDeviceGroup(
    moveDevicesData: MoveDevicesData
  ): Promise<void> {
    return await http.post(`/v1/device-groups/devices/move`, moveDevicesData);
  }

  public async removeDevicesFromDeviceGroup(
    devicesIds: string[]
  ): Promise<void> {
    return await http.delete('/v1/device-groups/devices', {
      data: { devicesIds },
    });
  }

  public async createDeviceGroup(
    newDeviceGroup: NewDeviceGroup
  ): Promise<DeviceGroup> {
    const { data } = await http.post(`/v1/device-groups`, newDeviceGroup);

    return data;
  }

  public async updateDeviceGroup(
    id: string,
    deviceGroupData: DeviceGroupDetails
  ): Promise<DeviceGroup> {
    const { data } = await http.patch(
      `/v1/device-groups/${id}`,
      deviceGroupData
    );

    return data;
  }
}

export const fetchDeviceGroup = async (id: string): Promise<DeviceGroup> => {
  const service = new DeviceGroupServiceImpl();

  return service.fetchDeviceGroup(id);
};

export const fetchDeviceGroups = async (
  criteria: DeviceGroupsCriteria
): Promise<PaginatedAPIResponse<DeviceGroup>> => {
  const service = new DeviceGroupServiceImpl();

  return service.fetchDeviceGroups(criteria);
};

export const fetchDeviceGroupDevices = async (
  deviceGroupId: string,
  criteria: DeviceGroupsCriteria
): Promise<PaginatedAPIResponse<Device>> => {
  const service = new DeviceGroupServiceImpl();

  return service.fetchDeviceGroupDevices(deviceGroupId, criteria);
};

export const fetchAllAvailableDeviceGroups = async (): Promise<
  BasicDeviceGroupData[]
> => {
  const service = new DeviceGroupServiceImpl();

  return service.fetchAllAvailableDeviceGroups();
};

export const deleteDeviceGroupById = async (
  deviceGroupId: string
): Promise<void> => {
  const service = new DeviceGroupServiceImpl();

  return service.deleteDeviceGroupById(deviceGroupId);
};

export const moveDevicesToDeviceGroup = async (
  moveDevicesData: MoveDevicesData
): Promise<void> => {
  const service = new DeviceGroupServiceImpl();

  return service.moveDevicesToDeviceGroup(moveDevicesData);
};

export const removeDevicesFromDeviceGroup = async (
  devicesIds: string[]
): Promise<void> => {
  const service = new DeviceGroupServiceImpl();

  return service.removeDevicesFromDeviceGroup(devicesIds);
};

export const createDeviceGroup = async (
  newDeviceGroup: NewDeviceGroup
): Promise<DeviceGroup> => {
  const service = new DeviceGroupServiceImpl();

  return service.createDeviceGroup(newDeviceGroup);
};

export const updateDeviceGroup = async (
  id: string,
  deviceGroupData: DeviceGroupDetails
): Promise<DeviceGroup> => {
  const service = new DeviceGroupServiceImpl();

  return service.updateDeviceGroup(id, deviceGroupData);
};
