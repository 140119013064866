import { ComponentProps, useMemo } from 'react';
import { FaChevronDown } from 'react-icons/fa6';
import { IconType } from 'react-icons/lib';

export type AnimatedChevronIconProps = ComponentProps<IconType> & {
  active: boolean;
};

export function AnimatedChevronIcon({
  active,
  color = 'primary-dark',
  className,
  ...props
}: AnimatedChevronIconProps) {
  const animatedClassName = useMemo(() => {
    return active ? 'animate-chevron-icon rotated' : 'animate-chevron-icon';
  }, [active]);

  return (
    <FaChevronDown
      color={color}
      className={`${animatedClassName} ${className}`}
      {...props}
    />
  );
}

export default AnimatedChevronIcon;
