import { capitalizeString } from '@zspace/format';
import {
  FilterTableModalFieldAlignment,
  InputType,
  RegistrationFilesModalFilterDataType,
  RegistrationFileStatus,
} from '@zspace/types';
import FilterTableModal from '../../../shared/filter-table-modal/filter-table-modal';

export type RegistrationFilesFilterModalProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: (value: RegistrationFilesModalFilterDataType) => void;
  data: RegistrationFilesModalFilterDataType;
};

export function RegistrationFilesFilterModal({
  show,
  data,
  onClose,
  onSubmit,
}: RegistrationFilesFilterModalProps) {
  return (
    <FilterTableModal
      show={show}
      onClose={onClose}
      data={data}
      onSubmit={onSubmit}
      fields={[
        {
          input: {
            type: InputType.FILTER_CRITERIA,
            valueKey: 'name',
            filterValueKey: 'nameFilter',
          },
          name: 'name',
          label: 'Registration file',
          alignment: FilterTableModalFieldAlignment.LEFT,
        },
        {
          input: {
            type: InputType.DATE_RANGE,
            fromDateValueKey: 'expirationDateFrom',
            toDateValueKey: 'expirationDateTo',
          },
          name: 'expiresAt',
          label: 'Expiration date range',
          alignment: FilterTableModalFieldAlignment.LEFT,
        },
        {
          input: {
            type: InputType.DATE_RANGE,
            fromDateValueKey: 'creationDateFrom',
            toDateValueKey: 'creationDateTo',
          },
          name: 'createdAt',
          label: 'Creation date range',
          alignment: FilterTableModalFieldAlignment.RIGHT,
        },
        {
          input: {
            type: InputType.SELECT,
            valueKey: 'status',
            options: [
              {
                label: capitalizeString(RegistrationFileStatus.ALL),
                value: RegistrationFileStatus.ALL,
              },
              {
                label: capitalizeString(RegistrationFileStatus.ACTIVE),
                value: RegistrationFileStatus.ACTIVE,
              },
              {
                label: capitalizeString(RegistrationFileStatus.EXPIRED),
                value: RegistrationFileStatus.EXPIRED,
              },
            ],
          },
          name: 'status',
          label: 'Status',
          alignment: FilterTableModalFieldAlignment.RIGHT,
        },
      ]}
    />
  );
}

export default RegistrationFilesFilterModal;
