import { ChecklistInputType } from '@zspace/types';
import { useCallback } from 'react';
import { Columns, Element, Form } from 'react-bulma-components';
import FeedbackMessage from '../../../../ui/feedback-message/feedback-message';
import { FilterModalInput } from '../../filter-table-modal-input/filter-table-modal-input';
import styles from './checklist-input.module.scss';

export type ChecklistInputProps<T> = FilterModalInput<T> & {
  input: ChecklistInputType<T>;
};

export function ChecklistInput<T>({
  input: { valueKey, options },
  localData,
  setLocalData,
  name,
  errorText,
}: ChecklistInputProps<T>) {
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, option: string) => {
      let newCheckedItems = localData[valueKey] as string[];
      if (e.target.checked) {
        newCheckedItems = [...newCheckedItems, option];
      } else {
        newCheckedItems = (localData[valueKey] as string[]).filter(
          (e) => e !== option
        );
      }
      setLocalData({
        ...localData,
        [valueKey]: newCheckedItems,
      });
    },
    [localData, setLocalData, valueKey]
  );

  return (
    <Element>
      <Columns marginless display="flex">
        <Columns.Column paddingless>
          <Form.Field id="type" alignItems="center">
            <Form.Control>
              {options.map((option, index) => (
                <Form.Checkbox
                  id={name}
                  key={`${option}-${index}`}
                  checked={(localData[valueKey] as string[]).includes(option)}
                  onChange={(e) => handleOnChange(e, option)}
                  className={styles.customCheckbox}
                >
                  {option.replace('_', ' ')}
                </Form.Checkbox>
              ))}
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>
      <FeedbackMessage>{errorText}</FeedbackMessage>
    </Element>
  );
}

export default ChecklistInput;
