import {
  Criteria,
  PaginatedAPIResponse,
  SalesOrder,
  SalesOrderData,
  SalesOrdersCriteria,
  User,
} from '@zspace/types';
import { http } from '../shared/http';

export interface SalesOrdersService {
  fetchSalesOrders: (
    criteria: Partial<SalesOrdersCriteria>
  ) => Promise<PaginatedAPIResponse<SalesOrder>>;
  fetchSalesOrder: (id: string) => Promise<SalesOrderData>;
  fetchSalesOrderRelatedUsers: (
    salesOrderId: string,
    criteria: Criteria
  ) => Promise<PaginatedAPIResponse<User>>;
}

export class SalesOrdersServiceImpl implements SalesOrdersService {
  public async fetchSalesOrders(
    criteria: Partial<SalesOrdersCriteria>
  ): Promise<PaginatedAPIResponse<SalesOrder>> {
    const { data } = await http(
      '/v1/sales-orders?' +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchSalesOrder(id: string): Promise<SalesOrderData> {
    const { data } = await http(`/v1/sales-orders/${id}`);

    return data;
  }

  public async fetchSalesOrderRelatedUsers(
    salesOrderId: string,
    criteria: Criteria
  ): Promise<PaginatedAPIResponse<User>> {
    const { data } = await http(
      `/v1/sales-orders/${salesOrderId}/users?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchAvailableSalesOrders(
    criteria: Partial<SalesOrdersCriteria>
  ): Promise<PaginatedAPIResponse<SalesOrder>> {
    const { data } = await http(
      '/v1/sales-orders/available?' +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }
}

export const fetchSalesOrders = async (
  criteria: Partial<SalesOrdersCriteria>
): Promise<PaginatedAPIResponse<SalesOrder>> => {
  const service = new SalesOrdersServiceImpl();

  return service.fetchSalesOrders(criteria);
};

export const fetchSalesOrder = async (id: string): Promise<SalesOrderData> => {
  const service = new SalesOrdersServiceImpl();

  return service.fetchSalesOrder(id);
};

export const fetchSalesOrderRelatedUsers = async (
  salesOrderId: string,
  criteria: Criteria
): Promise<PaginatedAPIResponse<User>> => {
  const service = new SalesOrdersServiceImpl();

  return service.fetchSalesOrderRelatedUsers(salesOrderId, criteria);
};

export const fetchAvailableSalesOrders = async (
  criteria: Criteria
): Promise<PaginatedAPIResponse<SalesOrder>> => {
  const service = new SalesOrdersServiceImpl();

  return service.fetchAvailableSalesOrders(criteria);
};
