import { PermissionsGroupName, UserPermissions } from '@zspace/roles';
import {
  DeferredResponse,
  EditedUser,
  SalesOrder,
  User,
  UserRoleType,
  UserSalesOrderPermission,
  UserSalesOrderPermissionTableRow,
} from '@zspace/types';
import { differenceInDays, endOfDay } from 'date-fns';
import { ReactNode, Suspense, useCallback, useMemo, useState } from 'react';
import { Element, Icon, Section } from 'react-bulma-components';
import { FaFloppyDisk, FaTrash } from 'react-icons/fa6';
import {
  defer,
  LoaderFunction,
  useAsyncValue,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { environment } from '../../../environments/environment';
import CancelAlertModal from '../../shared/cancel-alert-modal/cancel-alert-modal';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import useIsZspaceInternalUser from '../../shared/hooks/is-zspace-internal-user';
import useSalesOrderPermissions from '../../shared/hooks/sales-order-permissions';
import useUserSalesOrders from '../../shared/hooks/user-sales-orders';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import FeedbackMessage from '../../ui/feedback-message/feedback-message';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import UserPermissionsTable from '../user-permissions-table/user-permissions-table';
import { editTeamUserPermissions, fetchUser } from '../users-service';
import { Header } from './header';
import RevokeUserPermissionConfirmationModal from './revoke-user-permission-confirmation-modal/revoke-user-permission-confirmation-modal';

const CANCEL_ALERT_MODAL_TITLE = 'Cancel edit user permissions';
const CANCEL_ALERT_MODAL_SUBTITLE =
  'Are you sure you want to cancel the user permissions edition process?';
const CANCEL_BUTTON_TEXT = 'Cancel';
const SAVE_BUTTON_TEXT = 'Save changes';
const REVOKE_ALL_ROLES_TITLE =
  'Are you sure you want to revoke all roles for this user?';
const REVOKE_ALL_ROLES_MESSAGE =
  'This will set all permission for sales orders that you manage to None';
const REVOKE_ALL_BUTTON_TEXT = 'Revoke all roles';
const EDIT_USER_PERMISSIONS_ERROR_MESSAGE =
  'The user permissions could not be updated. Please try again';
const REVOKE_USER_PERMISSIONS_ERROR_MESSAGE =
  'The user permissions could not be revoked. Please try again';
const INVALID_TEMPORARY_PERMISSION_DAYS_DURATION_ERROR_MESSAGE = `Temporary permissions cannot be granted more than ${Number(
  environment.temporaryUserPermissionMaxDaysDuration
)} days`;

export const loader: LoaderFunction = async ({ params }) => {
  const { id } = params;

  const response = fetchUser(id as string);

  return defer({ response });
};

export function EditUserPermissionsPageContent() {
  const navigate = useNavigate();

  const userToEdit = useAsyncValue() as User;
  const { userSalesOrders } = useUserSalesOrders();
  const { userHasSalesOrderManagePermissions } = useSalesOrderPermissions();
  const isZspaceInternalUser = useIsZspaceInternalUser();

  const [showCancelAlertModal, setShowCancelAlertModal] =
    useState<boolean>(false);
  const [showRevokePermissionsModal, setShowRevokePermissionsModal] =
    useState(false);
  const [filters, setFilters] = useState<ReactNode>(null);
  const { executeHttpRequest, isLoading: isEditingUserPermissions } =
    useHttpRequest();
  const [editUserButtonPressed, setEditUserButtonPressed] =
    useState<boolean>(false);

  const salesOrdersAssignedToUserToEdit: UserSalesOrderPermissionTableRow[] =
    useMemo(() => {
      const userRole = isZspaceInternalUser
        ? UserRoleType.ZSPACE_INTERNAL
        : UserRoleType.CUSTOMER;
      const userToEditRole = userToEdit.roles.find(
        (roleAssignment) => roleAssignment.role.name === userRole
      );
      return (
        userToEditRole?.permissionsGroupUserRoleAssignments
          .filter(
            (permissionsGroupAssignment) =>
              permissionsGroupAssignment.salesOrder &&
              userHasSalesOrderManagePermissions(
                permissionsGroupAssignment.salesOrder?.id
              )
          )
          .map(({ salesOrder, expiresAt, permissionsGroup }) => ({
            id: salesOrder!.id,
            number: salesOrder!.number,
            opportunity: salesOrder!.opportunity,
            closeDate: salesOrder!.closeDate,
            permissionsGroup: {
              name: permissionsGroup.name,
              daysDuration: expiresAt
                ? differenceInDays(new Date(expiresAt), endOfDay(new Date())) +
                  1
                : undefined,
            },
          })) ?? []
      );
    }, [
      isZspaceInternalUser,
      userHasSalesOrderManagePermissions,
      userToEdit.roles,
    ]);

  const salesOrders: SalesOrder[] = useMemo(() => {
    return userSalesOrders;
  }, [userSalesOrders]);

  const tablePermissions = useMemo(
    () =>
      salesOrders.map((so) => {
        const assignedSalesOrder = salesOrdersAssignedToUserToEdit.find(
          (s) => s.id === so.id
        );

        if (assignedSalesOrder) {
          return {
            id: so.id,
            number: so.number,
            opportunity: so.opportunity,
            closeDate: so.closeDate,
            permissionsGroup: assignedSalesOrder.permissionsGroup,
          };
        }

        return {
          id: so.id,
          number: so.number,
          opportunity: so.opportunity,
          closeDate: so.closeDate,
          permissionsGroup: {
            name: '',
          },
        };
      }),
    [salesOrders, salesOrdersAssignedToUserToEdit]
  );

  const [userPermissions, setUserPermissions] = useState<
    UserSalesOrderPermission[]
  >(() =>
    salesOrdersAssignedToUserToEdit.map((p) => ({
      salesOrderId: p.id,
      permissionsGroup: p.permissionsGroup,
    }))
  );

  const navigateToPermissionsHome = useCallback(() => {
    navigate('/users/my-team');
  }, [navigate]);

  const showInvalidTemporaryPermissionDaysDurationError = useMemo(() => {
    return (
      userPermissions.filter(
        (p) =>
          p.permissionsGroup?.name ===
            PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER &&
          p.permissionsGroup.daysDuration &&
          p.permissionsGroup.daysDuration >
            Number(environment.temporaryUserPermissionMaxDaysDuration)
      ).length > 0 && editUserButtonPressed
    );
  }, [userPermissions, editUserButtonPressed]);

  const invalidTemporaryPermissionDaysDurationError = useMemo(
    () =>
      showInvalidTemporaryPermissionDaysDurationError &&
      INVALID_TEMPORARY_PERMISSION_DAYS_DURATION_ERROR_MESSAGE,
    [showInvalidTemporaryPermissionDaysDurationError]
  );

  const handleRevokeUserPermissions = useCallback(
    () =>
      executeHttpRequest({
        asyncFunction: async () => {
          const editedUser: EditedUser = {
            permissions: userPermissions.map((p) => ({
              salesOrderId: p.salesOrderId,
              permissionsGroup: undefined,
            })),
          };

          await editTeamUserPermissions(userToEdit.id, editedUser);

          navigateToPermissionsHome();
        },
        customErrorMessage: REVOKE_USER_PERMISSIONS_ERROR_MESSAGE,
      }),
    [
      executeHttpRequest,
      navigateToPermissionsHome,
      userPermissions,
      userToEdit.id,
    ]
  );

  const handleSaveChangesClick = useCallback(
    () =>
      executeHttpRequest({
        asyncFunction: async () => {
          const validSelectedPermissions = userPermissions.filter(
            (p) =>
              p.permissionsGroup?.name !==
                PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER ||
              (p.permissionsGroup.daysDuration &&
                p.permissionsGroup.daysDuration <=
                  Number(environment.temporaryUserPermissionMaxDaysDuration))
          );

          if (userPermissions.length !== validSelectedPermissions.length) {
            return setEditUserButtonPressed(true);
          }

          const editedUser: EditedUser = {
            permissions: userPermissions.map((p) => ({
              salesOrderId: p.salesOrderId,
              permissionsGroup: p.permissionsGroup?.name.length
                ? {
                    name: p.permissionsGroup.name,
                    daysDuration: p.permissionsGroup.daysDuration,
                  }
                : undefined,
            })),
          };

          await editTeamUserPermissions(userToEdit.id, editedUser);

          navigateToPermissionsHome();
        },
        customErrorMessage: EDIT_USER_PERMISSIONS_ERROR_MESSAGE,
      }),
    [
      executeHttpRequest,
      navigateToPermissionsHome,
      userPermissions,
      userToEdit.id,
    ]
  );

  return (
    <ProtectedPage permissions={UserPermissions.USERS_ASSIGN_ROLES}>
      <BoxLayout
        className="is-min-height-80 mx-10 my-4"
        flex
        header={
          <Header
            navigateToPermissionsHome={navigateToPermissionsHome}
            userToEdit={userToEdit}
            filters={filters}
          />
        }
      >
        <UserPermissionsTable
          dataSource={tablePermissions}
          errorMessage={
            <Element mb={3}>
              <FeedbackMessage>
                {invalidTemporaryPermissionDaysDurationError}
              </FeedbackMessage>
            </Element>
          }
          setFilters={setFilters}
          selectedPermissions={userPermissions}
          setSelectedPermissions={setUserPermissions}
        />

        <Section
          paddingless
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          p={4}
          className="gap-6 is-flex-grow-1"
        >
          <Button
            color="primary-dark"
            outlined
            onClick={() => setShowCancelAlertModal(true)}
          >
            {CANCEL_BUTTON_TEXT}
          </Button>
          <Button
            color="danger-dark"
            onClick={() => {
              setShowRevokePermissionsModal(true);
            }}
          >
            <Icon>
              <FaTrash />
            </Icon>
            <span>{REVOKE_ALL_BUTTON_TEXT}</span>
          </Button>
          <Button
            color="primary-dark"
            onClick={handleSaveChangesClick}
            isExecutingAction={isEditingUserPermissions}
          >
            <Button.LoadingIcon icon={FaFloppyDisk} />
            <span>{SAVE_BUTTON_TEXT}</span>
          </Button>
        </Section>
      </BoxLayout>

      <CancelAlertModal
        show={showCancelAlertModal}
        title={CANCEL_ALERT_MODAL_TITLE}
        subtitle={CANCEL_ALERT_MODAL_SUBTITLE}
        onCancel={navigateToPermissionsHome}
        onClose={() => setShowCancelAlertModal(false)}
      />
      <RevokeUserPermissionConfirmationModal
        show={showRevokePermissionsModal}
        title={REVOKE_ALL_ROLES_TITLE}
        message={REVOKE_ALL_ROLES_MESSAGE}
        user={userToEdit}
        loading={isEditingUserPermissions}
        onCancel={() => {
          setShowRevokePermissionsModal(false);
        }}
        onRevoke={handleRevokeUserPermissions}
      />
    </ProtectedPage>
  );
}

function EditUserPermissionsPage() {
  const { response } = useLoaderData() as DeferredResponse<User>;

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <EditUserPermissionsPageContent />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default EditUserPermissionsPage;
