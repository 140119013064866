import { formatSalesOrderNumber, formatWorkOrderNumber } from '@zspace/format';
import { useCallback, useMemo } from 'react';
import { Columns, Section } from 'react-bulma-components';
import { FaArrowRight } from 'react-icons/fa6';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';
import { formatItemFulfillmentNumber } from '../../../shared/utils';
import BoxLayout from '../../../ui/box-layout/box-layout';
import Button from '../../../ui/button/button';
import SummaryCard, {
  DeviceSwapSummary,
} from '../../../ui/summary-card/summary-card';
import { DeviceReplacementContext } from '../device-replacement-layout/device-replacement-layout';

export function DeviceReplacementSummaryPage() {
  const { cleanDeviceReplacementData, faultyDevice, replacementDevice } =
    useOutletContext<DeviceReplacementContext>();
  const navigate = useNavigate();

  const faultyDeviceSummary: DeviceSwapSummary = useMemo(
    () => ({
      serialNumber: faultyDevice?.serialNumber,
      workOrder: {
        previous: formatWorkOrderNumber(faultyDevice?.workOrder?.number || ''),
      },
      salesOrder: {
        previous: formatSalesOrderNumber(
          faultyDevice?.salesOrder?.number || ''
        ),
      },
      itemFulfillment: {
        previous: formatItemFulfillmentNumber(
          faultyDevice?.itemFulfillment?.number || ''
        ),
      },
      assignedSoftware: { previous: faultyDevice?.assignedSoftware },
    }),
    [faultyDevice]
  );

  const replacementDeviceSummary: DeviceSwapSummary = useMemo(
    () => ({
      serialNumber: replacementDevice?.serialNumber,
      workOrder: {
        current: formatWorkOrderNumber(faultyDevice?.workOrder?.number || ''),
      },
      salesOrder: {
        current: formatSalesOrderNumber(faultyDevice?.salesOrder?.number || ''),
      },
      itemFulfillment: {
        current: formatItemFulfillmentNumber(
          faultyDevice?.itemFulfillment?.number || ''
        ),
      },
      assignedSoftware: { current: faultyDevice?.assignedSoftware },
    }),
    [
      faultyDevice?.assignedSoftware,
      faultyDevice?.itemFulfillment?.number,
      faultyDevice?.salesOrder?.number,
      faultyDevice?.workOrder?.number,
      replacementDevice?.serialNumber,
    ]
  );

  const onReplaceAnotherDeviceClick = useCallback(() => {
    cleanDeviceReplacementData();
    navigate('/device-replacement');
  }, [cleanDeviceReplacementData, navigate]);

  const onFinishClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  if (!faultyDevice || !replacementDevice) {
    return <Navigate to="/device-replacement" />;
  }

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Section p={4}>
          <Columns display="flex" alignItems="center" paddingless>
            <Columns.Column>
              <h1 className="is-size-3 has-text-weight-light">
                Device successfully replaced
              </h1>
            </Columns.Column>
            <Columns.Column
              display="flex"
              justifyContent="flex-end"
              className="gap-4"
            >
              <Button
                color="primary-dark"
                outlined
                onClick={onReplaceAnotherDeviceClick}
              >
                Replace another device
              </Button>
              <Button color="primary-dark" onClick={onFinishClick}>
                <span>Finish</span>
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      }
    >
      <Section
        paddingless
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="gap-8"
      >
        <Columns display="flex" alignItems="center" className="w-full">
          <Columns.Column>
            <h2 className="is-size-5">Faulty device</h2>
            <SummaryCard {...faultyDeviceSummary} />
          </Columns.Column>
          <FaArrowRight size={34} />
          <Columns.Column>
            <h2 className="is-size-5">Replacement device</h2>
            <SummaryCard {...replacementDeviceSummary} />
          </Columns.Column>
        </Columns>
      </Section>
    </BoxLayout>
  );
}

export default DeviceReplacementSummaryPage;
