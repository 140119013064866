import { capitalizeString } from '@zspace/format';
import {
  DeviceRegistrationMethod,
  DeviceRegistrationStatus,
  FilterTableModalFieldAlignment,
  HardwareModel,
  InputType,
  RegistrationFileDevicesModalFilterDataType,
} from '@zspace/types';
import { useMemo } from 'react';
import FilterTableModal from '../../../shared/filter-table-modal/filter-table-modal';

export type RegisteredDevicesFilterModalProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: (value: RegistrationFileDevicesModalFilterDataType) => void;
  data: RegistrationFileDevicesModalFilterDataType;
  deviceTypeOptions: { label: string; value: string }[];
};

export function RegisteredDevicesFilterModal({
  show,
  data,
  onClose,
  onSubmit,
  deviceTypeOptions,
}: RegisteredDevicesFilterModalProps) {
  const statusOptions = useMemo(
    () =>
      Object.entries(DeviceRegistrationStatus).map(([key, value]) => ({
        label: capitalizeString(key),
        value,
      })),
    []
  );

  const registrationMethodOptions = useMemo(
    () => Object.entries(DeviceRegistrationMethod).map(([_, value]) => value),
    []
  );

  return (
    <FilterTableModal
      show={show}
      onClose={onClose}
      data={data}
      onSubmit={onSubmit}
      fields={[
        {
          input: {
            type: InputType.TAG_INPUT,
            valueKey: 'serialNumber',
            placeholder: '',
            errorText: 'Please enter a valid serial number',
          },
          name: 'serialNumber',
          label: 'Serial number',
          alignment: FilterTableModalFieldAlignment.LEFT,
        },
        {
          input: {
            type: InputType.SELECT,
            valueKey: 'type',
            options: [
              {
                label: 'All',
                value: HardwareModel.ALL,
              },
              ...deviceTypeOptions,
            ],
          },
          name: 'type',
          label: 'Device type',
          alignment: FilterTableModalFieldAlignment.LEFT,
        },
        {
          input: {
            type: InputType.SELECT,
            valueKey: 'status',
            options: [...statusOptions],
          },
          name: 'status',
          label: 'Status',
          alignment: FilterTableModalFieldAlignment.LEFT,
        },
        {
          input: {
            type: InputType.FILTER_CRITERIA,
            valueKey: 'registrationFileName',
            filterValueKey: 'registrationFileNameFilter',
          },
          name: 'registrationFileName',
          label: 'Registration file used',
          alignment: FilterTableModalFieldAlignment.RIGHT,
        },
        {
          input: {
            type: InputType.FILTER_CRITERIA,
            valueKey: 'name',
            filterValueKey: 'nameFilter',
          },
          name: 'name',
          label: 'Device name',
          alignment: FilterTableModalFieldAlignment.RIGHT,
        },
        {
          input: {
            type: InputType.CHECKLIST,
            valueKey: 'registrationMethods',
            options: registrationMethodOptions,
          },
          name: 'registrationMethod',
          label: 'Registration method',
          alignment: FilterTableModalFieldAlignment.RIGHT,
        },
      ]}
    />
  );
}

export default RegisteredDevicesFilterModal;
