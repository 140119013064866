import { PermissionsGroupName, SalesOrderPermissions } from '@zspace/roles';
import {
  DeferredResponse,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  SalesOrder,
  SalesOrderData,
  SortDirection,
  User,
  UserSalesOrdersCriteria,
} from '@zspace/types';
import { AxiosError } from 'axios';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import { FaFileInvoice, FaPenToSquare, FaUser, FaXmark } from 'react-icons/fa6';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import BackButton from '../../shared/back-button/back-button';
import Conditional from '../../shared/conditional/conditional';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useError from '../../shared/hooks/error';
import useIsUserEditable from '../../shared/hooks/is-user-editable';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import DetailsCard from '../../ui/details-card/details-card';
import InfoLabel from '../../ui/info-label/info-label';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { OnTableChangeData } from '../../ui/table/types';
import SalesOrdersTable from '../../work-orders/sales-orders-table/sales-orders-table';
import { fetchUser, fetchUserSalesOrders } from '../users-service';
import { userDetailsSalesOrderDetailsColumns } from './constants';
import styles from './user-details-page.module.scss';

type selectablePermissionType = {
  label: string;
  permissionName: PermissionsGroupName;
  selected: boolean;
};

export const loader: LoaderFunction = async ({ params }) => {
  const id = params.id;

  const response = fetchUser(id!);

  return defer({ response });
};

export function UserDetailsPageContent() {
  const navigate = useNavigate();
  const { setError } = useError();
  const user = useAsyncValue() as User;
  const [searchParams] = useSearchParams();
  const [relatedSalesOrder, setRelatedSalesOrder] = useState<
    PaginatedAPIResponse<SalesOrder>
  >({
    data: [],
    count: 0,
    page: 0,
    total: 0,
    pages: 0,
  });
  const [selectedPermissions, setSelectedPermissions] = useState<
    Map<PermissionsGroupName, boolean>
  >(new Map());

  const [salesOrdersTableCriteria, setSalesOrdersTableCriteria] =
    useState<UserSalesOrdersCriteria>({
      itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
      pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
      search: searchParams.get('q') ?? '',
      sortBy: searchParams.get('sortBy') ?? '',
      sortDirection: searchParams.get('sortDirection') as SortDirection,
      userPermissionsGroups: [],
    });

  const { isEditableByCustomerUser } = useIsUserEditable();
  const permissionsFilter: selectablePermissionType[] = useMemo(() => {
    return [
      {
        label: 'End User Software Contact',
        permissionName: PermissionsGroupName.END_USER_SOFTWARE_CONTACT,
        selected: !!selectedPermissions.get(
          PermissionsGroupName.END_USER_SOFTWARE_CONTACT
        ),
      },
      {
        label: 'Billing Contact',
        permissionName: PermissionsGroupName.BILLING_CONTACT,
        selected: !!selectedPermissions.get(
          PermissionsGroupName.BILLING_CONTACT
        ),
      },
      {
        label: 'Shipping Contact',
        permissionName: PermissionsGroupName.SHIPPING_CONTACT,
        selected: !!selectedPermissions.get(
          PermissionsGroupName.SHIPPING_CONTACT
        ),
      },
      {
        label: 'Manager',
        permissionName: PermissionsGroupName.SALES_ORDER_MANAGER,
        selected: !!selectedPermissions.get(
          PermissionsGroupName.SALES_ORDER_MANAGER
        ),
      },
      {
        label: 'Viewer',
        permissionName: PermissionsGroupName.VIEW_ONLY_SALES_ORDER_MANAGER,
        selected: !!selectedPermissions.get(
          PermissionsGroupName.VIEW_ONLY_SALES_ORDER_MANAGER
        ),
      },
      {
        label: 'Manager - Temporary',
        permissionName: PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER,
        selected: !!selectedPermissions.get(
          PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER
        ),
      },
    ];
  }, [selectedPermissions]);

  const [loadingRelatedSalesOrder, setLoadingRelatedSalesOrder] =
    useState<boolean>(true);

  const fetchRelatedSalesOrder = useCallback(async () => {
    try {
      setLoadingRelatedSalesOrder(true);
      const salesOrders = await fetchUserSalesOrders(
        user.id,
        salesOrdersTableCriteria
      );
      setRelatedSalesOrder(salesOrders);
    } catch (error) {
      if (error instanceof AxiosError) {
        setError({
          status: error.response?.status || 500,
          message: error.response?.data?.message || error.message,
        });
      }
    } finally {
      setLoadingRelatedSalesOrder(false);
    }
  }, [salesOrdersTableCriteria, setError, user.id]);

  useEffect(() => {
    fetchRelatedSalesOrder();
  }, [fetchRelatedSalesOrder, user, searchParams]);

  const salesOrderTableConfig: PaginatedContentConfig = useMemo(
    () => ({ ...salesOrdersTableCriteria, pages: relatedSalesOrder.pages }),
    [relatedSalesOrder.pages, salesOrdersTableCriteria]
  );

  const emptySalesOrdersContentText = useMemo(() => {
    const hasAppliedFilters = salesOrdersTableCriteria.search !== '';
    if (hasAppliedFilters) {
      return 'There are no sales orders that match the selected criteria';
    }
    return 'There are no sales orders at the moment';
  }, [salesOrdersTableCriteria.search]);

  const handleOnSalesOrdersTableChange = useCallback(
    (value: OnTableChangeData<SalesOrder>) => {
      const newData = {
        ...salesOrdersTableCriteria,
        ...value.config,
        sortBy: value.column?.key || salesOrdersTableCriteria.sortBy,
      };
      setSalesOrdersTableCriteria(newData);
    },
    [salesOrdersTableCriteria]
  );

  const handleOnPermissionsFilterChange = useCallback(
    (col: selectablePermissionType) => {
      setSelectedPermissions(
        new Map(selectedPermissions.set(col.permissionName, !col.selected))
      );
      const newData = {
        ...salesOrdersTableCriteria,
        userPermissionsGroups: permissionsFilter
          .filter((col) => selectedPermissions.get(col.permissionName))
          .map((col) => col.permissionName),
      };
      setSalesOrdersTableCriteria(newData);
    },
    [permissionsFilter, salesOrdersTableCriteria, selectedPermissions]
  );

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Element className="p-4">
          <BackButton />
          <h1 className="is-size-3 has-text-weight-light">{user.email}</h1>
        </Element>
      }
    >
      <Columns className="p-4">
        <Columns display="flex" flexDirection="column" className={`pr-7`}>
          <Columns.Column>
            <DetailsCard title="Basic information">
              <InfoLabel
                icon={<FaUser />}
                label="First Name"
                content={user.firstName}
              />
              <InfoLabel
                icon={<FaUser />}
                label="Last Name"
                content={user.lastName}
              />
            </DetailsCard>
          </Columns.Column>
        </Columns>
        <Columns.Column className={`pt-0 ${styles.tableColumn}`}>
          <DetailsCard>
            <>
              <section className="is-flex is-align-items-center">
                <Icon color="primary" mr={2}>
                  <FaFileInvoice size={24} />
                </Icon>
                <span className="is-size-4 has-text-weight-light">
                  Related Sales Orders
                </span>
                <If condition={isEditableByCustomerUser(user)}>
                  <Button
                    ml={1}
                    text
                    className={`has-text-primary-dark is-size-7 `}
                    onClick={() => {
                      navigate(`/users/${user.id}/edit`);
                    }}
                  >
                    <Icon>
                      <FaPenToSquare />
                    </Icon>
                    <span>Edit</span>
                  </Button>
                </If>
              </section>
              <Conditional condition={loadingRelatedSalesOrder}>
                <Conditional.True>
                  <PageSpinner />
                </Conditional.True>
                <Conditional.False>
                  <section className={styles.filtersSection}>
                    {permissionsFilter.map((col, index) => (
                      <Button
                        key={col.permissionName}
                        type="button"
                        color="primary-dark"
                        className={index === 0 ? '' : 'ml-2'}
                        outlined={!col.selected}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.currentTarget.blur();
                          handleOnPermissionsFilterChange(col);
                        }}
                      >
                        <Conditional condition={col.selected}>
                          <Conditional.True>
                            <Icon>
                              <FaXmark />
                            </Icon>
                          </Conditional.True>
                        </Conditional>
                        <span>{col.label}</span>
                      </Button>
                    ))}
                  </section>
                  <SalesOrdersTable
                    columns={userDetailsSalesOrderDetailsColumns}
                    dataSource={relatedSalesOrder.data}
                    config={salesOrderTableConfig}
                    onChange={handleOnSalesOrdersTableChange}
                    empty={
                      <Element
                        display="flex"
                        justifyContent="center"
                        className="my-2"
                      >
                        <span className="has-text-weight-light">
                          {emptySalesOrdersContentText}
                        </span>
                      </Element>
                    }
                  />
                </Conditional.False>
              </Conditional>
            </>
          </DetailsCard>
        </Columns.Column>
      </Columns>
    </BoxLayout>
  );
}

export function UserDetailsPage() {
  const { response } = useLoaderData() as DeferredResponse<SalesOrderData>;

  return (
    <ProtectedPage
      permissions={SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ}
    >
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <UserDetailsPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default UserDetailsPage;
