import { UnauthorizedResponse } from '@zspace/types';
import { AxiosError, HttpStatusCode } from 'axios';
import { useEffect, useMemo } from 'react';
import Conditional from '../conditional/conditional';
import ErrorMessageCard from '../error-message-card/error-message-card';
import NoAccessErrorCard from '../no-access-error-card/no-access-error-card';
import RedirectToLoginCard from './redirect-to-login-card/redirect-to-login-card';

type UserErrorElementProps = {
  error: AxiosError;
  timeout?: number;
  defaultAuthUrl?: string;
};

export function UserErrorElement({
  error,
  timeout = 2000,
  defaultAuthUrl,
}: UserErrorElementProps) {
  const errorStatus = useMemo(
    () => error?.response?.status,
    [error.response?.status]
  );

  const authUrl = useMemo(() => {
    if (defaultAuthUrl) {
      return defaultAuthUrl;
    }
    return (error as AxiosError<UnauthorizedResponse>).response?.data?.authUrl;
  }, [defaultAuthUrl, error]);

  useEffect(() => {
    if (authUrl) {
      setTimeout(() => {
        window.location.assign(authUrl as string);
      }, timeout);
    }
  }, [authUrl, timeout]);

  return (
    <Conditional
      condition={!!authUrl && errorStatus === HttpStatusCode.Unauthorized}
    >
      <Conditional.True>
        <RedirectToLoginCard />
      </Conditional.True>
      <Conditional.False>
        <Conditional condition={errorStatus === HttpStatusCode.Forbidden}>
          <Conditional.True>
            <NoAccessErrorCard />
          </Conditional.True>
          <Conditional.False>
            <ErrorMessageCard />
          </Conditional.False>
        </Conditional>
      </Conditional.False>
    </Conditional>
  );
}

export default UserErrorElement;
