import { filter } from '@zspace/data';
import { WorkOrderPermissions } from '@zspace/roles';
import {
  DeferredResponse,
  EditWorkOrderDetails,
  PaginatedContentConfig,
  SalesOrder,
  SalesOrdersIncludedCriteria,
  WorkOrderData,
  WorkOrderDetails,
  WorkOrderStatus,
  WorkOrderType,
} from '@zspace/types';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import { FaCheck, FaCircleInfo, FaTrash } from 'react-icons/fa6';
import {
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';
import CancelAlertModal from '../../shared/cancel-alert-modal/cancel-alert-modal';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { OnTableChangeData } from '../../ui/table/types';
import CreateWorkOrderDetailsForm, {
  WorkOrderDetailsFormData,
} from '../create-work-order-details-page/create-work-order-details-form/create-work-order-details-form';
import { salesOrderOverviewColumns } from '../sales-orders-table/constants';
import SalesOrdersTable from '../sales-orders-table/sales-orders-table';
import WorkOrderConfirmDeletionModal from '../work-order-confirm-deletion-modal/work-order-confirm-deletion-modal';
import {
  deleteWorkOrderById,
  updateWorkOrderById,
} from '../work-orders-service';

const validateForm = {
  name: (value: string) =>
    !value.trim() ? 'Please provide a work order name' : null,
  type: null,
  description: null,
  complex: null,
};

const CANCEL_ALERT_MODAL_TITLE = 'Cancel work order';
const CANCEL_ALERT_MODAL_SUBTITLE =
  'Are you sure you want to cancel the work order editing process?\nYou will lose all your progress';
const DELETE_WORK_ORDER_ERROR_MESSAGE =
  'The work order could not be deleted. Please try again';
const UPDATE_WORK_ORDER_ERROR_MESSAGE =
  'The work order could not be updated. Please try again';

export function EditWorkOrderDetailsPageContent() {
  const navigate = useNavigate();

  const { id: workOrderId } = useParams();
  const workOrderData = useAsyncValue() as WorkOrderData;
  const [tableData, setTableData] = useState<SalesOrder[]>(
    workOrderData.salesOrders
  );
  const [salesOrdersTableCriteria, setSalesOrdersTableCriteria] =
    useState<SalesOrdersIncludedCriteria>({
      itemsPerPage: 30,
      pageNumber: 1,
      search: '',
      sortBy: '',
      salesOrders: workOrderData.salesOrders.map((so) => so.number.toString()),
    });
  const [formData, setFormData] = useState<WorkOrderDetailsFormData>({
    name: {
      value: workOrderData.name ?? '',
      touched: false,
      error: null,
    },
    type: {
      value: workOrderData.type ?? WorkOrderType.FULL_CONFIGURATION,
      touched: false,
      error: null,
    },
    description: {
      value: workOrderData.description ?? '',
      touched: false,
      error: null,
    },
    complex: {
      value: workOrderData.complex ? '1' : '0',
      touched: false,
      error: null,
    },
  });
  const [displayDeleteConfirmationModal, setDisplayDeleteConfirmationModal] =
    useState(false);
  const [isCancelAlertModalVisible, setIsCancelAlertModalVisible] =
    useState(false);
  const {
    executeHttpRequest: deleteWorkOrderRequest,
    isLoading: isDeletingWorkOrder,
  } = useHttpRequest();
  const {
    executeHttpRequest: updateWorkOrderRequest,
    isLoading: isUpdatingWorkOrder,
  } = useHttpRequest();

  const tableConfig: PaginatedContentConfig = useMemo(() => {
    return {
      ...salesOrdersTableCriteria,
      pages: Math.ceil(
        workOrderData.salesOrders.length / salesOrdersTableCriteria.itemsPerPage
      ),
    };
  }, [salesOrdersTableCriteria, workOrderData.salesOrders.length]);

  const shouldDisplayDeleteButton = useMemo(
    () =>
      workOrderData.status === WorkOrderStatus.DRAFT &&
      !workOrderData.deviceGroups.length,
    [workOrderData.deviceGroups.length, workOrderData.status]
  );

  const handleOnTableChange = useCallback(
    (value: OnTableChangeData<SalesOrder>): void => {
      const newData = {
        ...salesOrdersTableCriteria,
        ...value.config,
        sortBy: value.column?.key || salesOrdersTableCriteria.sortBy,
      };
      const newTableData = filter(workOrderData.salesOrders, newData, []);
      setSalesOrdersTableCriteria(newData);
      setTableData(newTableData);
    },
    [salesOrdersTableCriteria, workOrderData.salesOrders]
  );

  const navigateToWorkOrderDeviceGroupsDetailsPage = useCallback(() => {
    navigate(`/work-orders/${workOrderId}/device-groups`);
  }, [navigate, workOrderId]);

  const handleUpdateWorkOrder = useCallback(
    () =>
      updateWorkOrderRequest({
        asyncFunction: async () => {
          const newWorkOrderData: EditWorkOrderDetails = {
            name: formData.name.value,
            description: formData.description.value,
          };
          await updateWorkOrderById(workOrderId!, newWorkOrderData);
          navigateToWorkOrderDeviceGroupsDetailsPage();
        },
        customErrorMessage: UPDATE_WORK_ORDER_ERROR_MESSAGE,
      }),
    [
      formData.description.value,
      formData.name.value,
      navigateToWorkOrderDeviceGroupsDetailsPage,
      updateWorkOrderRequest,
      workOrderId,
    ]
  );

  const onUpdateWorkOrder = useCallback(async () => {
    //Validate form
    const formValidation = Object.keys(formData).reduce(
      (acc, key) => {
        const formDataKey = key as keyof WorkOrderDetails;
        const error = validateForm[formDataKey]?.(formData[formDataKey].value);
        setFormData((prev) => ({
          ...prev,
          [formDataKey]: { ...prev[formDataKey], error, touched: true },
        }));
        return {
          values: { ...acc.values, [key]: formData[formDataKey].value },
          errors: { ...acc.errors, [key]: error },
        };
      },
      { values: {}, errors: {} }
    );
    const isValidForm = Object.values(formValidation.errors).every(
      (fieldError) => !fieldError
    );
    if (isValidForm) {
      await handleUpdateWorkOrder();
    } else {
      //Scroll to top to show errors
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [formData, handleUpdateWorkOrder]);

  const handleDeleteWorkOrderClick = useCallback(() => {
    setDisplayDeleteConfirmationModal(true);
  }, []);

  const handleCancelWorkOrderDeletion = useCallback(() => {
    setDisplayDeleteConfirmationModal(false);
  }, []);

  const handleWorkOrderDeletion = useCallback(
    () =>
      deleteWorkOrderRequest({
        asyncFunction: async () => {
          deleteWorkOrderById(workOrderData.id);
          setDisplayDeleteConfirmationModal(false);
          navigate('/');
        },
        customErrorMessage: DELETE_WORK_ORDER_ERROR_MESSAGE,
      }),
    [deleteWorkOrderRequest, navigate, workOrderData.id]
  );

  useEffect(() => {
    if (workOrderData.status !== WorkOrderStatus.DRAFT) {
      navigateToWorkOrderDeviceGroupsDetailsPage();
    }
  }, [
    navigate,
    navigateToWorkOrderDeviceGroupsDetailsPage,
    workOrderData.status,
    workOrderId,
  ]);

  return (
    <BoxLayout
      className="mx-10 my-4"
      header={
        <Columns marginless>
          <Columns.Column className="pl-4">
            <section>
              <h1 className="is-size-3 has-text-weight-light">
                Edit work order
              </h1>
            </section>
          </Columns.Column>
        </Columns>
      }
    >
      <section>
        <CreateWorkOrderDetailsForm
          data={formData}
          onChangeData={setFormData}
          validate={validateForm}
          editMode={true}
        />
      </section>

      <section>
        <Element display="flex" alignItems="center" mt={8} my={4}>
          <div>
            <h2 className="subtitle is-size-5">Sales orders included</h2>
          </div>
        </Element>
        <Element display="flex" alignItems="center" mb={4}>
          <Icon mr={1} className="has-text-primary-dark">
            <FaCircleInfo />
          </Icon>
          <span className="is-size" style={{ fontSize: 14 }}>
            You cannot modify included sales orders once a work order has been
            created
          </span>
        </Element>
        <SalesOrdersTable
          columns={salesOrderOverviewColumns}
          dataSource={tableData}
          config={tableConfig}
          onChange={handleOnTableChange}
        />
      </section>

      <section
        className="is-flex is-justify-content-flex-end mt-8"
        style={{ gap: '1rem' }}
      >
        <Button
          color="primary-dark"
          outlined
          onClick={() => setIsCancelAlertModalVisible(true)}
        >
          Cancel
        </Button>
        {shouldDisplayDeleteButton && (
          <Button
            color="danger-dark"
            outlined
            onClick={handleDeleteWorkOrderClick}
          >
            <Icon>
              <FaTrash />
            </Icon>
            <span>Delete work order</span>
          </Button>
        )}
        <Button
          color="primary-dark"
          onClick={onUpdateWorkOrder}
          isExecutingAction={isUpdatingWorkOrder}
        >
          <Button.LoadingIcon icon={FaCheck} />
          <span>Save and continue</span>
        </Button>
      </section>
      <WorkOrderConfirmDeletionModal
        show={displayDeleteConfirmationModal}
        workOrder={workOrderData}
        loading={isDeletingWorkOrder}
        onCancel={handleCancelWorkOrderDeletion}
        onDelete={handleWorkOrderDeletion}
      />
      <CancelAlertModal
        show={isCancelAlertModalVisible}
        title={CANCEL_ALERT_MODAL_TITLE}
        subtitle={CANCEL_ALERT_MODAL_SUBTITLE}
        onCancel={navigateToWorkOrderDeviceGroupsDetailsPage}
        onClose={() => setIsCancelAlertModalVisible(false)}
      />
    </BoxLayout>
  );
}

function EditWorkOrderDetailsPage() {
  const { response } = useLoaderData() as DeferredResponse<WorkOrderData>;

  return (
    <ProtectedPage permissions={WorkOrderPermissions.WORK_ORDERS_DRAFTS_UPDATE}>
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <EditWorkOrderDetailsPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default EditWorkOrderDetailsPage;
