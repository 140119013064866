import { useState } from 'react';
import { Form as BulmaForm, Icon } from 'react-bulma-components';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import Button from '../button/button';
import styles from './input-spinner.module.scss';

type BulmaFormInput = React.ComponentProps<typeof BulmaForm.Input>;

export type InputSpinnerProps = BulmaFormInput & {
  onValueChange: (value: number) => void;
  minValue: number;
  maxValue: number;
};

export function InputSpinner({
  onValueChange,
  minValue,
  maxValue,
  ...inputProps
}: InputSpinnerProps) {
  const [input, setInput] = useState('0');
  const isDecrementEnabled = parseInt(input) > minValue;
  const isIncrementEnabled = parseInt(input) < maxValue;

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(e.target.value);
    if (newValue > maxValue) {
      newValue = maxValue;
    } else if (newValue < minValue) {
      newValue = minValue;
    }
    setInput(newValue.toString());
    onValueChange(newValue);
  };

  const handleValueDecrement = () => {
    const newValue = parseInt(input) - 1;
    if (newValue >= minValue) {
      setInput(newValue.toString());
      onValueChange(newValue);
    }
  };

  const handleValueIncrement = () => {
    const newValue = parseInt(input) + 1;
    if (newValue <= maxValue) {
      setInput(newValue.toString());
      onValueChange(newValue);
    }
  };

  const handleMaxValueIncrement = () => {
    setInput(maxValue.toString());
    onValueChange(maxValue);
  };

  return (
    <div
      className={'is-flex is-align-items-center is-justify-content-flex-end'}
    >
      <div className="mr-2">
        <Button
          className="is-borderless is-transparent"
          outlined
          disabled={!isDecrementEnabled}
          onClick={handleValueDecrement}
          role="button"
        >
          <Icon>
            <FaMinus size={7} />
          </Icon>
        </Button>
      </div>
      <div className="mr-2">
        <BulmaForm.Input
          type="number"
          min={minValue}
          max={maxValue}
          value={input}
          onChange={(e) => {
            handleValueChange(e);
          }}
          className={styles.container}
          role="spinbutton"
          {...inputProps}
        ></BulmaForm.Input>
      </div>
      <div className="mr-2">
        <Button
          className="is-borderless is-transparent"
          disabled={!isIncrementEnabled}
          onClick={handleValueIncrement}
          role="button"
        >
          <Icon>
            <FaPlus size={7} />
          </Icon>
        </Button>
      </div>
      <div className="mr-10">
        <Button
          color="primary"
          outlined
          onClick={handleMaxValueIncrement}
          role="button"
        >
          Max
        </Button>
      </div>
    </div>
  );
}

export default InputSpinner;
