import {
  Criteria,
  DeviceGroup,
  DeviceGroupDetails,
  DeviceGroupsTableRow,
  GroupCode,
  NewDeviceGroup,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  SelectedSalesOrderDevices,
  SortDirection,
} from '@zspace/types';
import { useCallback, useMemo, useState } from 'react';
import { Element } from 'react-bulma-components';
import { FaCheck } from 'react-icons/fa6';
import { useNavigation, useSearchParams } from 'react-router-dom';
import BackButton from '../../shared/back-button/back-button';
import CancelAlertModal from '../../shared/cancel-alert-modal/cancel-alert-modal';
import Conditional from '../../shared/conditional/conditional';
import useUpdateUrl from '../../shared/hooks/url';
import If from '../../shared/if/if';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import FeedbackMessage from '../../ui/feedback-message/feedback-message';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { OnTableChangeData } from '../../ui/table/types';
import DeviceGroupsTable, {
  QuantitySelection,
} from '../device-groups-table/device-groups-table';
import DeviceGroupCreatedConfirmationModal from './device-group-created-confirmation-modal/device-group-created-confirmation-modal';
import DeviceGroupDetailsForm, {
  DeviceGroupDetailsFormData,
} from './device-group-form/device-group-details-form';

const NO_DEVICES_ERROR_MESSAGE =
  'Device groups must contain at least one device';
const CANCEL_ALERT_MODAL_TITLE = 'Cancel device group';
const CANCEL_ALERT_MODAL_SUBTITLE =
  'Are you sure you want to cancel the device group creation process?\nYou will lose all your progress';

const validateForm = {
  name: (value: string) =>
    !value.trim() ? 'Please input a device group name' : null,
  description: null,
};

const initialFormData = {
  name: {
    value: '',
    touched: false,
    error: null,
  },
  description: {
    value: '',
    touched: false,
    error: null,
  },
};

export type CreateDeviceGroupLayoutProps = {
  devices?: PaginatedAPIResponse<DeviceGroupsTableRow>;
  onCancel: () => void;
  isCreatingNewDeviceGroup: boolean;
  onCreateDeviceGroup: (
    deviceGroupData: NewDeviceGroup
  ) => Promise<void | DeviceGroup>;
  onCloseConfirmationModal?: () => void;
  customer: boolean;
};

function CreateDeviceGroupLayout({
  devices = {
    data: [],
    count: 0,
    page: 0,
    total: 0,
    pages: 0,
  },
  onCancel,
  isCreatingNewDeviceGroup,
  onCreateDeviceGroup,
  onCloseConfirmationModal,
  customer,
}: CreateDeviceGroupLayoutProps) {
  const [searchParams] = useSearchParams();
  const updateUrl = useUpdateUrl();
  const navigation = useNavigation();

  const [formData, setFormData] =
    useState<DeviceGroupDetailsFormData>(initialFormData);
  const [selectedDevices, setSelectedDevices] = useState<
    SelectedSalesOrderDevices[]
  >([]);
  const [data, setData] = useState<Criteria>({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: searchParams.get('sortBy') ?? '',
    sortDirection: (searchParams.get('sortDirection') as SortDirection) ?? '',
  });
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  const [isCancelAlertModalVisible, setIsCancelAlertModalVisible] =
    useState(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [groupCode, setGroupCode] = useState<GroupCode | null>(null);

  const tableData: DeviceGroupsTableRow[] = useMemo(
    () => devices.data,
    [devices.data]
  );

  const tableConfig: PaginatedContentConfig = useMemo(
    () => ({ ...data, pages: devices.pages }),
    [data, devices.pages]
  );

  const showNoDevicesSelectedError = useMemo(
    () => selectedDevices.length === 0 && submitButtonPressed && !customer,
    [customer, selectedDevices.length, submitButtonPressed]
  );

  const errorText = useMemo(() => {
    if (showNoDevicesSelectedError) {
      return NO_DEVICES_ERROR_MESSAGE;
    }
  }, [showNoDevicesSelectedError]);

  const emptyContentText = useMemo(() => {
    const hasAppliedFilters = data.search !== '';

    if (hasAppliedFilters) {
      return 'There are no devices that match the selected criteria';
    }
    return 'There are no devices at the moment';
  }, [data.search]);

  const isDataLoading = useMemo(
    () => navigation.state === 'loading',
    [navigation.state]
  );

  const quantitySelection: QuantitySelection = useMemo(() => {
    return {
      onChange: (
        selectedSalesOrderId: string,
        selectedSalesOrderNumber: string,
        selectedDeviceModelId: string,
        quantity: number
      ) => {
        setSelectedDevices((oldSelectedDevices) => {
          const foundRowIndex = oldSelectedDevices.findIndex(
            (d) =>
              d.salesOrder.id === selectedSalesOrderId &&
              d.deviceModelId === selectedDeviceModelId
          );
          const newSelectedDevice: SelectedSalesOrderDevices = {
            salesOrder: {
              id: selectedSalesOrderId,
              number: Number(selectedSalesOrderNumber),
            },
            deviceModelId: selectedDeviceModelId,
            quantity,
          };
          if (foundRowIndex > -1) {
            oldSelectedDevices.splice(foundRowIndex, 1);
          }
          if (quantity === 0) {
            return [...oldSelectedDevices];
          } else {
            return [...oldSelectedDevices, newSelectedDevice];
          }
        });
      },
      selectedDevices,
    };
  }, [selectedDevices]);

  const handleOnTableChange = useCallback(
    (value: OnTableChangeData<DeviceGroupsTableRow>): void => {
      const newData = {
        ...data,
        ...value.config,
        sortBy: value.column?.key || data.sortBy,
      };

      setData(newData);
      updateUrl(newData);
    },
    [data, updateUrl]
  );

  const handleOnCreateDeviceGroup = useCallback(async () => {
    const formValidation = Object.keys(formData).reduce(
      (acc, key) => {
        const formDataKey = key as keyof DeviceGroupDetails;
        const error = validateForm[formDataKey]?.(formData[formDataKey].value);
        setFormData((prev) => ({
          ...prev,
          [formDataKey]: { ...prev[formDataKey], error, touched: true },
        }));
        return {
          values: { ...acc.values, [key]: formData[formDataKey].value },
          errors: { ...acc.errors, [key]: error },
        };
      },
      { values: {}, errors: {} }
    );

    let isValidForm = Object.values(formValidation.errors).every(
      (fieldError) => !fieldError
    );

    if (!customer) {
      isValidForm = isValidForm && selectedDevices.length > 0;
    }

    if (isValidForm) {
      const newDeviceGroupData: NewDeviceGroup = {
        name: formData.name.value,
        description: formData.description.value ?? '',
        devices: selectedDevices,
      };

      const createdDeviceGroup = await onCreateDeviceGroup(newDeviceGroupData);
      if (createdDeviceGroup && createdDeviceGroup.groupCodes?.length) {
        setGroupCode(createdDeviceGroup.groupCodes[0]);
      }
      setShowConfirmationModal(true);
    } else {
      setSubmitButtonPressed(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [formData, customer, selectedDevices, onCreateDeviceGroup]);

  const resetDeviceGroupData = useCallback(async () => {
    setFormData(initialFormData);
    setSelectedDevices([]);
    setShowConfirmationModal(false);
    setGroupCode(null);
  }, []);

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      childrenClassName="p-8"
      header={
        <Element className="p-4">
          <BackButton onClick={onCancel} />
          <h1 className="is-size-3 has-text-weight-light">
            Create device group
          </h1>
        </Element>
      }
    >
      <DeviceGroupDetailsForm
        data={formData}
        onChangeData={setFormData}
        validate={validateForm}
      />

      <If condition={!customer}>
        <Conditional condition={isDataLoading}>
          <Conditional.True>
            <PageSpinner />
          </Conditional.True>
          <Conditional.False>
            <h2 className="is-size-4 mb-8">Devices to be included</h2>
            <DeviceGroupsTable
              dataSource={tableData}
              onChange={handleOnTableChange}
              config={tableConfig}
              empty={
                <Element
                  display="flex"
                  justifyContent="center"
                  className="my-2"
                >
                  <span className="my-2 has-text-weight-light">
                    {emptyContentText}
                  </span>
                </Element>
              }
              quantitySelection={quantitySelection}
              errorMessage={
                showNoDevicesSelectedError && (
                  <Element mb={3}>
                    <FeedbackMessage>{errorText}</FeedbackMessage>
                  </Element>
                )
              }
            />
          </Conditional.False>
        </Conditional>
      </If>

      <section className="is-flex is-justify-content-flex-end mt-8">
        <Button
          mr={4}
          color="primary-dark"
          outlined
          onClick={() => setIsCancelAlertModalVisible(true)}
        >
          Cancel
        </Button>
        <Button
          color="primary-dark"
          onClick={handleOnCreateDeviceGroup}
          isExecutingAction={isCreatingNewDeviceGroup}
        >
          <Button.LoadingIcon icon={FaCheck} />
          <span>Create device group</span>
        </Button>
      </section>

      <If condition={customer && !!groupCode}>
        <DeviceGroupCreatedConfirmationModal
          show={showConfirmationModal}
          groupCode={groupCode!}
          onCreateAnotherDeviceGroup={resetDeviceGroupData}
          onClose={() => {
            onCloseConfirmationModal?.();
          }}
        />
      </If>

      <CancelAlertModal
        show={isCancelAlertModalVisible}
        title={CANCEL_ALERT_MODAL_TITLE}
        subtitle={CANCEL_ALERT_MODAL_SUBTITLE}
        onCancel={onCancel}
        onClose={() => setIsCancelAlertModalVisible(false)}
      />
    </BoxLayout>
  );
}

export default CreateDeviceGroupLayout;
