import { FilterTableModalField, WorkOrdersCriteria } from '@zspace/types';
import FilterTableModal from '../../shared/filter-table-modal/filter-table-modal';

export type WorkOrdersTableFilterProps = {
  show: boolean;
  data: WorkOrdersCriteria;
  onClose: () => void;
  onSubmit: (data: WorkOrdersCriteria) => void;
  fields: FilterTableModalField<WorkOrdersCriteria>[];
};

export function WorkOrdersTableFilterModal({
  show,
  data,
  onClose,
  onSubmit,
  fields,
}: WorkOrdersTableFilterProps) {
  return (
    <FilterTableModal
      show={show}
      onClose={onClose}
      data={data}
      onSubmit={onSubmit}
      fields={fields}
    />
  );
}

export default WorkOrdersTableFilterModal;
