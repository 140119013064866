import { faker } from '@faker-js/faker';
import { Factory, define, extend, random } from 'cooky-cutter';
import {
  DeviceGroup,
  UserRoleType,
  WorkOrder,
  WorkOrderData,
  WorkOrderStatus,
  WorkOrderStatusRecord,
  WorkOrderType,
} from '../types';
import { userFactory } from './user';

export const workOrderFactory: Factory<WorkOrder> = define<WorkOrder>({
  id: () => faker.string.uuid(),
  number: random,
  name: () => `${faker.date.month()} update`,
  description: () => faker.lorem.lines({ min: 1, max: 3 }),
  type: () => faker.helpers.enumValue(WorkOrderType),
  complex: () => faker.datatype.boolean(),
  statusHistory: () => [],
  deviceSetupStatuses: () => [],
  createdAt: () => faker.date.anytime(),
  createdBy: () => faker.internet.email().toLowerCase(),
  salesOrders: () => [],
  deviceGroups: () => [],
});

type WorkOrderDetailStatus = Pick<
  WorkOrderStatusRecord,
  'status' | 'createdAt'
>;

type WorkOrderDetails = Omit<WorkOrder, 'statusHistory'> & {
  currentStatus: WorkOrderDetailStatus;
};

const workOrderStatusRecordFactory: Factory<WorkOrderDetailStatus> =
  define<WorkOrderDetailStatus>({
    status: () =>
      faker.helpers.arrayElement([
        WorkOrderStatus.DRAFT,
        WorkOrderStatus.QUEUE,
        WorkOrderStatus.COMPLETED,
      ]),
    createdAt: () => faker.date.anytime(),
  });

export const workOrderDetailsFactory: Factory<WorkOrderDetails> =
  define<WorkOrderDetails>({
    id: () => faker.string.uuid(),
    number: random,
    name: () => `${faker.date.month()} update`,
    description: () => faker.lorem.lines({ min: 1, max: 3 }),
    type: () => faker.helpers.enumValue(WorkOrderType),
    currentStatus: () => workOrderStatusRecordFactory(),
    complex: () => faker.datatype.boolean(),
    deviceSetupStatuses: () => [],
    createdAt: () => faker.date.anytime(),
    createdBy: () => faker.internet.email().toLowerCase(),
    salesOrders: () => [],
    deviceGroups: () => [],
  });

export const workOrderDataFactory: Factory<WorkOrderData> = extend<
  WorkOrder,
  WorkOrderData
>(workOrderFactory, {
  status: () =>
    faker.helpers.arrayElement([
      WorkOrderStatus.DRAFT,
      WorkOrderStatus.COMPLETED,
    ]),
  devices: (i: number) => ({
    assigned: i,
    unassigned: i,
    total: i,
  }),
  softwareSeats: (i: number) => ({
    assigned: i,
    unassigned: i,
  }),
});

export const deviceGroupFactory: Factory<DeviceGroup> = define<DeviceGroup>({
  id: () => faker.string.uuid(),
  name: () => faker.lorem.word(3),
  devices: () => [],
  workOrder: workOrderFactory,
  creator: userFactory,
  groupCodes: () => [],
  visibility: () => faker.helpers.enumValue(UserRoleType),
});
