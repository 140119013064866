import { WorkOrderPermissions } from '@zspace/roles';
import { Navigate } from 'react-router-dom';
import CheckPermissions from '../../shared/check-permissions/check-permissions';

export function WorkOrdersDefaultPage() {
  return (
    <CheckPermissions
      permissions={WorkOrderPermissions.WORK_ORDERS_DRAFTS_READ}
    >
      <CheckPermissions.Render>
        <Navigate to="/work-orders/dashboard/drafts" replace />
      </CheckPermissions.Render>
      <CheckPermissions.Fallback>
        <CheckPermissions
          permissions={WorkOrderPermissions.WORK_ORDERS_QUEUE_READ}
        >
          <CheckPermissions.Render>
            <Navigate to="/work-orders/dashboard/queue" replace />
          </CheckPermissions.Render>
          <CheckPermissions.Fallback>
            <Navigate to="/work-orders/dashboard/completed" replace />
          </CheckPermissions.Fallback>
        </CheckPermissions>
      </CheckPermissions.Fallback>
    </CheckPermissions>
  );
}

export default WorkOrdersDefaultPage;
