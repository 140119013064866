import { Box, Element } from 'react-bulma-components';
import Spinner from '../../../ui/spinner/spinner';

export function RedirectToLoginCard() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      paddingless
      my={4}
      mx={10}
      className="box-container is-min-height-50"
    >
      <Element
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className="gap-2"
      >
        <h1 className="is-size-4 has-text-weight-light mb-2">
          Redirecting to login page
        </h1>
        <Spinner />
      </Element>
    </Box>
  );
}

export default RedirectToLoginCard;
