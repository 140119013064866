import { faker } from '@faker-js/faker';
import { Factory, define } from 'cooky-cutter';
import { SoftwareSeat, SoftwareSeatStatus } from '../types';
import { licenseKeyFactory } from './license-keys';
import { licensingProductGroupFactory } from './licensing-product-groups';
import { salesOrderLineFactory } from './sales-order-lines';

export const softwareSeatFactory: Factory<SoftwareSeat> = define<SoftwareSeat>({
  id: () => faker.string.uuid(),
  status: () => SoftwareSeatStatus.ACTIVE,
  salesOrderLine: salesOrderLineFactory,
  licenseKey: licenseKeyFactory,
  licensingProductGroup: licensingProductGroupFactory,
});
