export enum FeaturePermissions {
  /**
   * Can Access Full Configuration flows
   */
  FULL_CONFIGURATION_ACCESS = 'FULL_CONFIGURATION_ACCESS',

  /**
   * Can Access Renewals flows
   */
  RENEWALS_ACCESS = 'RENEWALS_ACCESS',

  /**
   * Can Access Partners flows
   */
  PARTNERS_ACCESS = 'PARTNERS_ACCESS',
}

export enum WorkOrderPermissions {
  /**
   * Can Create Work Orders
   */
  WORK_ORDERS_CREATE = 'WORK_ORDERS_CREATE',

  /**
   * Can View Work Orders drafts
   */
  WORK_ORDERS_DRAFTS_READ = 'WORK_ORDERS_DRAFTS_READ',

  /**
   * Can View Work Orders queue
   */
  WORK_ORDERS_QUEUE_READ = 'WORK_ORDERS_QUEUE_READ',

  /**
   * Can View Work Orders completed
   */
  WORK_ORDERS_COMPLETED_READ = 'WORK_ORDERS_COMPLETED_READ',

  /**
   * Can Update Work Orders drafts
   */
  WORK_ORDERS_DRAFTS_UPDATE = 'WORK_ORDERS_DRAFTS_UPDATE',

  /**
   * Can Update Work Orders queue
   */
  WORK_ORDERS_QUEUE_UPDATE = 'WORK_ORDERS_QUEUE_UPDATE',

  /**
   * Can Delete Work Orders drafts
   */
  WORK_ORDERS_DRAFTS_DELETE = 'WORK_ORDERS_DRAFTS_DELETE',

  /**
   * Can Prioritize Work Orders in queue
   */
  WORK_ORDERS_QUEUE_PRIORITIZATION = 'WORK_ORDERS_QUEUE_PRIORITIZATION',
}

export enum SalesOrderPermissions {
  /**
   * Can View Sales Orders Summary (does not include pricing)
   */
  SALES_ORDERS_SUMMARY_READ = 'SALES_ORDERS_SUMMARY_READ',

  /**
   * Can View Sales Orders Details (Bill + Shipping)
   */
  SALES_ORDER_DETAILS_READ = 'SALES_ORDER_DETAILS_READ',

  /**
   * Can View Salesforce Account / Parent Account
   */
  SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ = 'SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ',

  /**
   * Can Update Salesforce Account / Parent Account
   */
  SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_UPDATE = 'SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_UPDATE',

  /**
   * Can View Other Sales Order Summaries in Account
   */
  SALES_ORDERS_OTHER_SUMMARIES_READ = 'SALES_ORDERS_OTHER_SUMMARIES_READ',

  /**
   * Can View Other Sales Orders Summaries in Parent Account
   */
  SALES_ORDERS_OTHER_SUMMARIES_PARENT_ACCOUNT_READ = 'SALES_ORDERS_OTHER_SUMMARIES_PARENT_ACCOUNT_READ',

  /**
   * Can request access to other Sales Orders
   */
  SALES_ORDERS_REQUEST_ACCESS = 'SALES_ORDERS_REQUEST_ACCESS',
}

export enum DeviceGroupPermissions {
  /**
   * Can Create Device Groups
   */
  DEVICE_GROUPS_CREATE = 'DEVICE_GROUPS_CREATE',

  /**
   * Can View Device Groups
   */
  DEVICE_GROUPS_READ = 'DEVICE_GROUPS_READ',

  /**
   * Can Update Device Groups
   */
  DEVICE_GROUPS_UPDATE = 'DEVICE_GROUPS_UPDATE',

  /**
   * Can Delete Device Groups
   */
  DEVICE_GROUPS_DELETE = 'DEVICE_GROUPS_DELETE',

  /**
   * Can Join a Device Group
   */
  DEVICE_GROUPS_JOIN = 'DEVICE_GROUPS_JOIN',
}

export enum SoftwareSeatPermissions {
  /**
   * Can View Software Seats
   */
  SOFTWARE_SEATS_READ = 'SOFTWARE_SEATS_READ',

  /**
   * Can Update Software Seats
   */
  SOFTWARE_SEATS_UPDATE = 'SOFTWARE_SEATS_UPDATE',

  /**
   * Can Assign Software Seats
   */
  SOFTWARE_SEATS_ASSIGN = 'SOFTWARE_SEATS_ASSIGN',

  /**
   * Can Reclaim Software Seats
   */
  SOFTWARE_SEATS_RECLAIM = 'SOFTWARE_SEATS_RECLAIM',
}

export enum RMAProcessPermissions {
  /**
   * Can Start RMA Process
   */
  RMA_PROCESS_START = 'RMA_PROCESS_START',
}

export enum UserPermissions {
  /**
   * Can Create Users
   */
  USERS_CREATE = 'USERS_CREATE',

  /**
   * Can View Users
   */
  USERS_READ = 'USERS_READ',

  /**
   * Can Modify Users
   */
  USERS_UPDATE = 'USERS_UPDATE',

  /**
   * Can Delete Users
   */
  USERS_DELETE = 'USERS_DELETE',

  /**
   * Can Assign Users to Roles
   */
  USERS_ASSIGN_ROLES = 'USERS_ASSIGN_ROLES',
}

export enum EULAPermissions {
  /**
   * Can Accept EULA
   */
  EULA_ACCEPT = 'EULA_ACCEPT',
  /**
   * Can download EULA Audit Logs
   */
  EULA_DOWNLOAD_AUDIT_LOGS = 'EULA_DOWNLOAD_AUDIT_LOGS',
}

export enum DevicePermissions {
  /**
   * Can Register Devices
   */
  DEVICES_REGISTER = 'DEVICES_REGISTER',
  /**
   * Can View Registered Devices
   */
  REGISTERED_DEVICES_READ = 'REGISTERED_DEVICES_READ',
}

export enum RegistrationFilePermissions {
  /**
   * Can View Registration Files
   */
  REGISTRATION_FILES_VIEW = 'REGISTRATION_FILES_VIEW',
  /**
   * Can Create Registration Files
   */
  REGISTRATION_FILES_CREATE = 'REGISTRATION_FILES_CREATE',
}

export enum NotificationPermissions {
  /**
   * Can receive Work Order Notifications
   */
  NOTIFICATIONS_WORK_ORDERS = 'NOTIFICATIONS_WORK_ORDERS',

  /**
   * Can receive Groups / Subgroups / Software Seats Notifications
   */
  NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS = 'NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS',

  /**
   * Can receive Renewal / Billing Notifications
   */
  NOTIFICATIONS_RENEWAL_BILLING = 'NOTIFICATIONS_RENEWAL_BILLING',

  /**
   * Can Manage Notifications
   */
  NOTIFICATIONS_MANAGE = 'NOTIFICATIONS_MANAGE',
}

export enum ExportDataPermissions {
  /**
   * Can Export Data
   */
  EXPORT_DATA = 'EXPORT_DATA',
}

export const superAdminPermissions = [
  FeaturePermissions.FULL_CONFIGURATION_ACCESS,
  WorkOrderPermissions.WORK_ORDERS_CREATE,
  WorkOrderPermissions.WORK_ORDERS_DRAFTS_READ,
  WorkOrderPermissions.WORK_ORDERS_QUEUE_READ,
  WorkOrderPermissions.WORK_ORDERS_COMPLETED_READ,
  WorkOrderPermissions.WORK_ORDERS_DRAFTS_UPDATE,
  WorkOrderPermissions.WORK_ORDERS_DRAFTS_DELETE,
  WorkOrderPermissions.WORK_ORDERS_QUEUE_PRIORITIZATION,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_UPDATE,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_PARENT_ACCOUNT_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_CREATE,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_UPDATE,
  DeviceGroupPermissions.DEVICE_GROUPS_DELETE,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_UPDATE,
  SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN,
  SoftwareSeatPermissions.SOFTWARE_SEATS_RECLAIM,
  RMAProcessPermissions.RMA_PROCESS_START,
  UserPermissions.USERS_CREATE,
  UserPermissions.USERS_READ,
  UserPermissions.USERS_UPDATE,
  UserPermissions.USERS_DELETE,
  UserPermissions.USERS_ASSIGN_ROLES,
  NotificationPermissions.NOTIFICATIONS_WORK_ORDERS,
  NotificationPermissions.NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS,
  NotificationPermissions.NOTIFICATIONS_RENEWAL_BILLING,
  NotificationPermissions.NOTIFICATIONS_MANAGE,
  ExportDataPermissions.EXPORT_DATA,
  EULAPermissions.EULA_ACCEPT,
  EULAPermissions.EULA_DOWNLOAD_AUDIT_LOGS,
];

export const itAdminPermissions = [
  FeaturePermissions.FULL_CONFIGURATION_ACCESS,
  WorkOrderPermissions.WORK_ORDERS_DRAFTS_READ,
  WorkOrderPermissions.WORK_ORDERS_QUEUE_READ,
  WorkOrderPermissions.WORK_ORDERS_COMPLETED_READ,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_PARENT_ACCOUNT_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_RECLAIM,
  RMAProcessPermissions.RMA_PROCESS_START,
  UserPermissions.USERS_CREATE,
  UserPermissions.USERS_READ,
  UserPermissions.USERS_ASSIGN_ROLES,
  NotificationPermissions.NOTIFICATIONS_WORK_ORDERS,
  NotificationPermissions.NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS,
  NotificationPermissions.NOTIFICATIONS_RENEWAL_BILLING,
  ExportDataPermissions.EXPORT_DATA,
];

export const supportStandardPermissions = [
  FeaturePermissions.FULL_CONFIGURATION_ACCESS,
  WorkOrderPermissions.WORK_ORDERS_QUEUE_READ,
  WorkOrderPermissions.WORK_ORDERS_COMPLETED_READ,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_PARENT_ACCOUNT_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_RECLAIM,
  RMAProcessPermissions.RMA_PROCESS_START,
  UserPermissions.USERS_CREATE,
  UserPermissions.USERS_READ,
  UserPermissions.USERS_ASSIGN_ROLES,
  NotificationPermissions.NOTIFICATIONS_WORK_ORDERS,
  NotificationPermissions.NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS,
  NotificationPermissions.NOTIFICATIONS_RENEWAL_BILLING,
  ExportDataPermissions.EXPORT_DATA,
];

export const customerSuccessPermissions = [
  FeaturePermissions.FULL_CONFIGURATION_ACCESS,
  WorkOrderPermissions.WORK_ORDERS_QUEUE_READ,
  WorkOrderPermissions.WORK_ORDERS_COMPLETED_READ,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_PARENT_ACCOUNT_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  UserPermissions.USERS_READ,
  NotificationPermissions.NOTIFICATIONS_WORK_ORDERS,
  NotificationPermissions.NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS,
  NotificationPermissions.NOTIFICATIONS_RENEWAL_BILLING,
  ExportDataPermissions.EXPORT_DATA,
];

export const workOrderCreatorPermissions = [
  FeaturePermissions.FULL_CONFIGURATION_ACCESS,
  WorkOrderPermissions.WORK_ORDERS_CREATE,
  WorkOrderPermissions.WORK_ORDERS_DRAFTS_READ,
  WorkOrderPermissions.WORK_ORDERS_QUEUE_READ,
  WorkOrderPermissions.WORK_ORDERS_COMPLETED_READ,
  WorkOrderPermissions.WORK_ORDERS_DRAFTS_UPDATE,
  WorkOrderPermissions.WORK_ORDERS_DRAFTS_DELETE,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_REQUEST_ACCESS,
  DeviceGroupPermissions.DEVICE_GROUPS_CREATE,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_UPDATE,
  DeviceGroupPermissions.DEVICE_GROUPS_DELETE,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_UPDATE,
  SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN,
  SoftwareSeatPermissions.SOFTWARE_SEATS_RECLAIM,
  UserPermissions.USERS_READ,
  NotificationPermissions.NOTIFICATIONS_WORK_ORDERS,
];

export const workOrderManagerPermissions = [
  FeaturePermissions.FULL_CONFIGURATION_ACCESS,
  WorkOrderPermissions.WORK_ORDERS_DRAFTS_READ,
  WorkOrderPermissions.WORK_ORDERS_QUEUE_READ,
  WorkOrderPermissions.WORK_ORDERS_COMPLETED_READ,
  WorkOrderPermissions.WORK_ORDERS_QUEUE_PRIORITIZATION,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  UserPermissions.USERS_READ,
  NotificationPermissions.NOTIFICATIONS_WORK_ORDERS,
];

export const warehousePartnerPermissions = [
  FeaturePermissions.PARTNERS_ACCESS,
  FeaturePermissions.FULL_CONFIGURATION_ACCESS,
  WorkOrderPermissions.WORK_ORDERS_QUEUE_READ,
  WorkOrderPermissions.WORK_ORDERS_COMPLETED_READ,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  UserPermissions.USERS_READ,
  UserPermissions.USERS_CREATE,
  UserPermissions.USERS_ASSIGN_ROLES,
  NotificationPermissions.NOTIFICATIONS_WORK_ORDERS,
];

export const endUserSoftwareContactPermissions = [
  FeaturePermissions.RENEWALS_ACCESS,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_REQUEST_ACCESS,
  DeviceGroupPermissions.DEVICE_GROUPS_CREATE,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_UPDATE,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_UPDATE,
  SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN,
  SoftwareSeatPermissions.SOFTWARE_SEATS_RECLAIM,
  RMAProcessPermissions.RMA_PROCESS_START,
  UserPermissions.USERS_READ,
  UserPermissions.USERS_CREATE,
  UserPermissions.USERS_UPDATE,
  UserPermissions.USERS_DELETE,
  UserPermissions.USERS_ASSIGN_ROLES,
  EULAPermissions.EULA_ACCEPT,
  NotificationPermissions.NOTIFICATIONS_WORK_ORDERS,
  NotificationPermissions.NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS,
  NotificationPermissions.NOTIFICATIONS_RENEWAL_BILLING,
  NotificationPermissions.NOTIFICATIONS_MANAGE,
  ExportDataPermissions.EXPORT_DATA,
  RegistrationFilePermissions.REGISTRATION_FILES_VIEW,
  RegistrationFilePermissions.REGISTRATION_FILES_CREATE,
  DevicePermissions.REGISTERED_DEVICES_READ,
];

export const billingContactPermissions = [
  FeaturePermissions.RENEWALS_ACCESS,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_PARENT_ACCOUNT_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  UserPermissions.USERS_READ,
  NotificationPermissions.NOTIFICATIONS_WORK_ORDERS,
  NotificationPermissions.NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS,
  NotificationPermissions.NOTIFICATIONS_RENEWAL_BILLING,
  NotificationPermissions.NOTIFICATIONS_MANAGE,
];

export const shippingContactPermissions = [
  FeaturePermissions.RENEWALS_ACCESS,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  UserPermissions.USERS_READ,
  NotificationPermissions.NOTIFICATIONS_WORK_ORDERS,
  NotificationPermissions.NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS,
];

export const salesOrderManagerPermissions = [
  FeaturePermissions.RENEWALS_ACCESS,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_REQUEST_ACCESS,
  DeviceGroupPermissions.DEVICE_GROUPS_CREATE,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_UPDATE,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_UPDATE,
  SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN,
  SoftwareSeatPermissions.SOFTWARE_SEATS_RECLAIM,
  RMAProcessPermissions.RMA_PROCESS_START,
  UserPermissions.USERS_READ,
  UserPermissions.USERS_CREATE,
  UserPermissions.USERS_ASSIGN_ROLES,
  NotificationPermissions.NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS,
  NotificationPermissions.NOTIFICATIONS_RENEWAL_BILLING,
  ExportDataPermissions.EXPORT_DATA,
];

export const viewOnlySalesOrderManagerPermissions = [
  FeaturePermissions.RENEWALS_ACCESS,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_READ,
  SalesOrderPermissions.SALES_ORDERS_OTHER_SUMMARIES_PARENT_ACCOUNT_READ,
  SalesOrderPermissions.SALES_ORDERS_REQUEST_ACCESS,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  RMAProcessPermissions.RMA_PROCESS_START,
  UserPermissions.USERS_READ,
  NotificationPermissions.NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS,
  NotificationPermissions.NOTIFICATIONS_RENEWAL_BILLING,
  ExportDataPermissions.EXPORT_DATA,
];

export const temporarySalesOrderManagerPermissions = [
  FeaturePermissions.RENEWALS_ACCESS,
  SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
  SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_CREATE,
  DeviceGroupPermissions.DEVICE_GROUPS_READ,
  DeviceGroupPermissions.DEVICE_GROUPS_UPDATE,
  SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
  SoftwareSeatPermissions.SOFTWARE_SEATS_UPDATE,
  SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN,
  SoftwareSeatPermissions.SOFTWARE_SEATS_RECLAIM,
  UserPermissions.USERS_READ,
  NotificationPermissions.NOTIFICATIONS_DEVICE_GROUPS_SOFTWARE_SEATS,
  NotificationPermissions.NOTIFICATIONS_RENEWAL_BILLING,
];

export type Permissions =
  | FeaturePermissions
  | WorkOrderPermissions
  | SalesOrderPermissions
  | DeviceGroupPermissions
  | SoftwareSeatPermissions
  | RMAProcessPermissions
  | UserPermissions
  | EULAPermissions
  | DevicePermissions
  | NotificationPermissions
  | ExportDataPermissions
  | RegistrationFilePermissions;

export enum PermissionsGroupName {
  SUPER_ADMIN = 'Super Admin',
  IT_ADMIN = 'IT Admin',
  SUPPORT_STANDARD = 'Support Standard',
  CUSTOMER_SUCCESS = 'Customer Success',
  WORK_ORDER_CREATOR = 'Work Order Creator',
  WORK_ORDER_MANAGER = 'Work Order Manager',
  WAREHOUSE_PARTNER = 'Warehouse Partner',
  END_USER_SOFTWARE_CONTACT = 'End User Software Contact',
  BILLING_CONTACT = 'Billing Contact',
  SHIPPING_CONTACT = 'Shipping Contact',
  SALES_ORDER_MANAGER = 'Sales Order Manager',
  VIEW_ONLY_SALES_ORDER_MANAGER = 'View Only Sales Order Manager',
  TEMPORARY_SALES_ORDER_MANAGER = 'Temporary Sales Order Manager',
}

/**
 * Array containing all zSpace sales order related permissions groups in a descending role sorting criteria
 */
export const ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS: PermissionsGroupName[] =
  [
    PermissionsGroupName.END_USER_SOFTWARE_CONTACT,
    PermissionsGroupName.BILLING_CONTACT,
    PermissionsGroupName.SHIPPING_CONTACT,
    PermissionsGroupName.SALES_ORDER_MANAGER,
    PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER,
    PermissionsGroupName.VIEW_ONLY_SALES_ORDER_MANAGER,
  ];

/**
 * Array containing all zSpace staff permissions groups in a descending role sorting criteria
 */
export const ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS: PermissionsGroupName[] = [
  PermissionsGroupName.SUPER_ADMIN,
  PermissionsGroupName.IT_ADMIN,
  PermissionsGroupName.SUPPORT_STANDARD,
  PermissionsGroupName.CUSTOMER_SUCCESS,
  PermissionsGroupName.WORK_ORDER_CREATOR,
  PermissionsGroupName.WORK_ORDER_MANAGER,
];

/**
 * Array containing all zSpace internal permissions groups (both sales order related or not)
 */
export const ALL_ZSPACE_INTERNAL_PERMISSIONS_GROUPS: PermissionsGroupName[] =
  ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS.concat(
    ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS
  );

/**
 * Array containing all partners permissions groups in a descending role sorting criteria
 */
export const ALL_PARTNER_PERMISSIONS_GROUPS: PermissionsGroupName[] = [
  PermissionsGroupName.WAREHOUSE_PARTNER,
];

/**
 * Array containing all zSpace internal permissions groups and all partner permissions groups in
 * a descending role sorting criteria
 */
export const ALL_ZSPACE_STAFF_AND_PARTNER_PERMISSIONS_GROUPS = [
  ...ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS,
  ...ALL_PARTNER_PERMISSIONS_GROUPS,
];

export const NETSUITE_DERIVED_PERMISSIONS_GROUPS: PermissionsGroupName[] = [
  PermissionsGroupName.END_USER_SOFTWARE_CONTACT,
  PermissionsGroupName.BILLING_CONTACT,
  PermissionsGroupName.SHIPPING_CONTACT,
];

/**
 * Array containing all system defined permissions groups
 */
export const SYSTEM_DEFINED_PERMISSIONS_GROUPS: PermissionsGroupName[] = [
  PermissionsGroupName.SALES_ORDER_MANAGER,
  PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER,
  PermissionsGroupName.VIEW_ONLY_SALES_ORDER_MANAGER,
];

/**
 * Array containing all customer manager permissions groups
 */
export const ALL_CUSTOMER_MANAGER_PERMISSIONS_GROUPS: PermissionsGroupName[] = [
  PermissionsGroupName.END_USER_SOFTWARE_CONTACT,
  PermissionsGroupName.SALES_ORDER_MANAGER,
  PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER,
];
