import { differenceInDays } from 'date-fns';
import { remainingDays } from './dates';

export function isEmail(email: string): boolean {
  return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
}

export function extractEmailDomain(email: string) {
  const regex = /@([\w\d.-]+)/;
  const emailStructure = email.match(regex);

  if (emailStructure) {
    return emailStructure[1];
  } else {
    return null;
  }
}

export function formatDateToLocaleString(date: string | Date): string {
  return new Date(date).toLocaleString(navigator?.language ?? 'en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
}

export function formatLastActiveDate(date: Date) {
  const daysDiff = remainingDays(date);

  if (daysDiff === 0) {
    return 'Today';
  } else if (daysDiff <= 7) {
    return `${daysDiff} ${daysDiff === 1 ? 'day' : 'days'} ago`;
  } else {
    return formatDateToLocaleString(date);
  }
}

export function formatExpiresAt(expiresAt: Date): string {
  const daysDiff = differenceInDays(expiresAt, new Date());

  if (daysDiff === 0) {
    return 'Less than a day';
  } else if (daysDiff < 0) {
    return `Expired`;
  } else {
    return `${daysDiff}`;
  }
}

export function removeDuplicates<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export function formatItemFulfillmentNumber(input: string | number): string {
  return `IF${input}`;
}
