import { Columns } from 'react-bulma-components';
import HomeCardCta, { HomeCardCtaProps } from '../home-card-cta/home-card-cta';
import HomeCardSeparator from '../home-card-separator/home-card-separator';
import If from '../if/if';

export type HomeCardsProps = {
  cards: HomeCardCtaProps[];
  resolveCardsOnClick: (handler: () => void, sectionName: string) => () => void;
};

export function HomeCards({ cards, resolveCardsOnClick }: HomeCardsProps) {
  return (
    <Columns display="flex" justifyContent="center">
      {cards.map((card, index) => (
        <Columns.Column
          display="flex"
          tablet={{ size: 6 }}
          fullhd={{ size: 3 }}
          justifyContent="space-between"
          key={`${card.title}-${index}`}
        >
          <HomeCardCta
            title={card.title}
            description={card.description}
            pendingTasks={card.pendingTasks}
            onClick={resolveCardsOnClick(card.onClick, card.title)}
          />
          <If condition={index < cards.length - 1}>
            <HomeCardSeparator />
          </If>
        </Columns.Column>
      ))}
    </Columns>
  );
}

export default HomeCards;
