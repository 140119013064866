import { HTMLAttributeAnchorTarget, ReactNode, useMemo } from 'react';
import { Element, Icon } from 'react-bulma-components';
import If from '../../shared/if/if';

export type QuickAccessAppProps = {
  icon: ReactNode;
  app: string;
  url?: string;
  target?: HTMLAttributeAnchorTarget;
  onClick?: () => void;
};

export function QuickAccessApp({
  icon,
  app,
  url,
  target,
  onClick,
}: QuickAccessAppProps) {
  const appName = useMemo(() => {
    const lines = app.split('\n');

    return (
      <span className="is-size-7 has-text-weight-normal has-text-black">
        {lines.map((line, index) => (
          <span key={index}>
            {line}
            <If condition={index < lines.length - 1}>
              <br />
            </If>
          </span>
        ))}
      </span>
    );
  }, [app]);

  return (
    <Element
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className="gap-3"
      textAlign="center"
      renderAs="a"
      target={target ?? '_blank'}
      href={url}
      onClick={onClick}
    >
      <Icon>{icon}</Icon>
      {appName}
    </Element>
  );
}

export default QuickAccessApp;
