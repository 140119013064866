import { SVGProps } from 'react';
const Books = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#267ABF"
        d="M0 1.5C0 .67.67 0 1.5 0h3C5.33 0 6 .67 6 1.5v3H0v-3ZM0 6h6v12H0V6Zm0 13.5h6v3c0 .83-.67 1.5-1.5 1.5h-3C.67 24 0 23.33 0 22.5v-3Zm7.5-18C7.5.67 8.17 0 9 0h3c.83 0 1.5.67 1.5 1.5v3h-6v-3Zm0 4.5h6v12h-6V6Zm0 13.5h6v3c0 .83-.67 1.5-1.5 1.5H9c-.83 0-1.5-.67-1.5-1.5v-3Zm6.066-13.978 5.756-1.538.384 1.449-5.756 1.542-.384-1.448v-.005Zm3.478 13.045 5.756-1.542.384 1.448-5.756 1.543-.384-1.449Z"
        opacity={0.4}
      />
      <path
        fill="#0A0A0A"
        d="m13.5 5.278.066.244 5.756-1.538-.769-2.873A1.492 1.492 0 0 0 16.73.051l-2.878.774A1.46 1.46 0 0 0 13.5.97V4.5h-6V6h6v-.722Zm6.206.15L13.95 6.975l3.094 11.597L22.8 17.03 19.706 5.433v-.005Zm-1.51 17.461a1.492 1.492 0 0 0 1.824 1.06l2.878-.774a1.503 1.503 0 0 0 1.055-1.833l-.764-2.864-5.76 1.538.763 2.873h.005ZM0 4.5V6h6V4.5H0ZM6 18H0v1.5h6V18Zm7.5 0h-6v1.5h6V18Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Books;
