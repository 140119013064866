import { removeTimeFromStringDate } from '@zspace/dates';
import { SoftwareSeatPermissions } from '@zspace/roles';
import {
  DeferredResponse,
  FilterType,
  LicenseKeysCriteria,
  LicenseKeysExpandableTableRow,
  LicenseKeysModalFilterDataType,
  LicenseKeysTableRow,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  SoftwareData,
  SortDirection,
} from '@zspace/types';
import { AxiosError } from 'axios';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import { FaBolt, FaFilter, FaUser } from 'react-icons/fa6';
import {
  defer,
  LoaderFunction,
  useAsyncValue,
  useLoaderData,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import LicenseKeysFilterModal from '../../devices/filter-modals/license-keys-filter-modal/license-keys-filter-modal';
import SalesOrdersList from '../../sales-orders/sales-orders-list/sales-orders-list';
import BackButton from '../../shared/back-button/back-button';
import Conditional from '../../shared/conditional/conditional';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useError from '../../shared/hooks/error';
import useHttpRequest from '../../shared/hooks/http-request';
import useUpdateUrl from '../../shared/hooks/url';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import DetailsCard from '../../ui/details-card/details-card';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import InfoLabel from '../../ui/info-label/info-label';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { OnTableChangeData } from '../../ui/table/types';
import LicenseKeysTable from '../license-keys-table/license-keys-table';
import {
  fetchSoftwareDetails,
  fetchSoftwareRelatedSeats,
} from '../software-service';
import styles from './software-details-page.module.scss';

export const loader: LoaderFunction = async ({ params }) => {
  const id = params.id;

  const response = fetchSoftwareDetails(id!);

  return defer({ response });
};

const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 50];

const initialCriteria: LicenseKeysCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
  licenseKey: '',
  licenseKeyFilter: FilterType.CONTAINS,
  expirationDateFrom: '',
  expirationDateTo: '',
  minTotalSeats: '',
  maxTotalSeats: '',
  assignedDevice: '',
  assignedDeviceFilter: FilterType.CONTAINS,
  salesOrders: [],
  endUsers: [],
  licensingProductGroupId: '',
};

export function SoftwareDetailsPageContent() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { setError } = useError();
  const updateUrl = useUpdateUrl();
  const softwareData = useAsyncValue() as SoftwareData;
  const { executeHttpRequest, isLoading: isLoadingSoftwareSeats } =
    useHttpRequest();
  const [relatedSoftwareSeats, setRelatedSoftwareSeats] = useState<
    PaginatedAPIResponse<LicenseKeysTableRow>
  >({
    data: [],
    count: 0,
    page: 0,
    total: 0,
    pages: 0,
  });
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [filterCriteria, setFilterCriteria] = useState<LicenseKeysCriteria>({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '50'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: '',
    sortBy: searchParams.get('sortBy') ?? '',
    sortDirection:
      (searchParams.get('sortDirection') as SortDirection) ?? SortDirection.ASC,
    licenseKey: searchParams.get('licenseKey') ?? '',
    licenseKeyFilter:
      (searchParams.get('licenseKeyFilter') as FilterType) ??
      FilterType.CONTAINS,
    expirationDateFrom: searchParams.get('expirationDateFrom') ?? '',
    expirationDateTo: searchParams.get('expirationDateTo') ?? '',
    minTotalSeats: searchParams.get('minTotalSeats') ?? '',
    maxTotalSeats: searchParams.get('maxTotalSeats') ?? '',
    assignedDevice: searchParams.get('assignedDevice') ?? '',
    assignedDeviceFilter:
      (searchParams.get('assignedDeviceFilter') as FilterType) ??
      FilterType.CONTAINS,
    salesOrders: searchParams.get('salesOrders')?.split(',') ?? [],
    endUsers: searchParams.get('endUsers')?.split(',') ?? [],
    licensingProductGroupId: params.id!,
  });

  const fetchRelatedSoftwareSeats = useCallback(async () => {
    return executeHttpRequest({
      asyncFunction: async () => {
        const seats = await fetchSoftwareRelatedSeats(
          params.id!,
          filterCriteria
        );
        setRelatedSoftwareSeats(seats);
      },
      customErrorHandler: (error: AxiosError) => {
        setError(error.message);
      },
    });
  }, [executeHttpRequest, params.id, filterCriteria, setError]);

  const tableData: LicenseKeysTableRow[] = useMemo(
    () => relatedSoftwareSeats.data,
    [relatedSoftwareSeats.data]
  );

  const tableConfig: PaginatedContentConfig = useMemo(
    () => ({ ...filterCriteria, pages: relatedSoftwareSeats.pages }),
    [filterCriteria, relatedSoftwareSeats.pages]
  );

  const handleTableChange = useCallback(
    (
      value: OnTableChangeData<
        LicenseKeysTableRow,
        LicenseKeysExpandableTableRow
      >
    ) => {
      const newData: LicenseKeysCriteria = {
        ...filterCriteria,
        ...value.config,
        sortBy: value.column?.key || filterCriteria.sortBy,
      };

      setFilterCriteria(newData);
      updateUrl(newData);
    },
    [filterCriteria, updateUrl]
  );

  useEffect(() => {
    fetchRelatedSoftwareSeats();
  }, [fetchRelatedSoftwareSeats]);

  const handleTableFilterSubmit = useCallback(
    (value: LicenseKeysModalFilterDataType) => {
      const newCriteria: LicenseKeysCriteria = {
        ...filterCriteria,
        ...value,
        ...{ pageNumber: 1 },
      };
      setFilterCriteria(newCriteria);
      fetchSoftwareRelatedSeats(params.id!, newCriteria);
      setShowFilterModal(false);
    },
    [filterCriteria, params.id]
  );

  const filterTagsData = useMemo(() => {
    return {
      licenseKey: {
        label: 'License key',
        value:
          filterCriteria.licenseKey.length > 0
            ? filterCriteria.licenseKeyFilter
                .toLowerCase()
                .replace('_', ' ')
                .concat(' ', filterCriteria.licenseKey)
            : '',
      },
      expirationDateFrom: {
        label: 'Expiration date from',
        value: removeTimeFromStringDate(filterCriteria.expirationDateFrom),
      },
      expirationDateTo: {
        label: 'Expiration date to',
        value: removeTimeFromStringDate(filterCriteria.expirationDateTo),
      },
      minTotalSeats: {
        label: 'Min total seats',
        value: filterCriteria.minTotalSeats,
      },
      maxTotalSeats: {
        label: 'Max total seats',
        value: filterCriteria.maxTotalSeats,
      },
      assignedDevice: {
        label: 'Assigned device',
        value:
          filterCriteria.assignedDevice.length > 0
            ? filterCriteria.assignedDeviceFilter
                .toLowerCase()
                .replace('_', ' ')
                .concat(' ', filterCriteria.assignedDevice)
            : '',
      },
      salesOrders: {
        label: 'Sales order #',
        value: filterCriteria.salesOrders.join(', '),
      },
      endUsers: {
        label: 'End users',
        value: filterCriteria.endUsers.join(', '),
      },
    };
  }, [filterCriteria]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof LicenseKeysModalFilterDataType) => {
      const newCriteria: LicenseKeysCriteria = { ...filterCriteria };

      newCriteria[filterDataKey] = initialCriteria[filterDataKey] as string &
        number &
        string[];

      handleTableFilterSubmit(newCriteria);
    },
    [filterCriteria, handleTableFilterSubmit]
  );

  const filterTagList = useMemo(() => {
    let appliedFiltersList = null;
    if (appliedFilters.length > 0) {
      appliedFiltersList = (
        <FilterTagList
          list={filterTagsData}
          onRemove={(item) => {
            onRemoveFilterTag(item as keyof LicenseKeysModalFilterDataType);
          }}
          title="Filters"
        />
      );
    }
    return appliedFiltersList;
  }, [appliedFilters.length, filterTagsData, onRemoveFilterTag]);

  const emptyContent = useMemo(() => {
    const emptyContentText =
      appliedFilters.length > 0
        ? 'There are no software seats that match the selected criteria'
        : 'No software seats have been registered yet';
    return (
      <Element display="flex" justifyContent="center" className="my-2">
        <span>{emptyContentText}</span>
      </Element>
    );
  }, [appliedFilters.length]);

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Element className="p-4">
          <BackButton />
          <h1 className="is-size-3 has-text-weight-light">
            {softwareData.displayName}
          </h1>
          <h2 className="is-size-5 has-text-weight-light">
            <SalesOrdersList
              list={softwareData.salesOrders}
              textColor="primary"
            />
          </h2>
          <If condition={!!filterTagList}>
            <Element mt={3}>{filterTagList}</Element>
          </If>
        </Element>
      }
    >
      <Columns className="p-4">
        <Columns
          display="flex"
          flexDirection="column"
          className={`pr-7 ${styles.informationColumn}`}
        >
          <Columns.Column>
            <DetailsCard title="Basic information">
              <InfoLabel
                icon={<FaBolt />}
                label="Software title"
                content={
                  <span className={styles.content}>
                    {softwareData.displayName}
                  </span>
                }
              />
              <InfoLabel
                icon={<FaUser />}
                label="Total seats"
                content={
                  <span className={styles.content}>
                    {softwareData.seats.total}
                  </span>
                }
              />
              <InfoLabel
                icon={<FaUser />}
                label="Available seats"
                content={
                  <span className={styles.content}>
                    {softwareData.seats.available}
                  </span>
                }
              />
            </DetailsCard>
          </Columns.Column>
        </Columns>
        <Columns.Column className={`pt-0 ${styles.tablesColumn}`}>
          <Conditional condition={isLoadingSoftwareSeats}>
            <Conditional.True>
              <PageSpinner />
            </Conditional.True>
            <Conditional.False>
              <LicenseKeysTable
                dataSource={tableData}
                onChange={handleTableChange}
                config={tableConfig}
                empty={emptyContent}
                form={{
                  disableSearch: true,
                  extra: (
                    <Button
                      type="button"
                      color="primary-dark"
                      className="ml-2"
                      outlined={appliedFilters.length === 0}
                      onClick={() => setShowFilterModal(true)}
                    >
                      <Icon>
                        <FaFilter />
                      </Icon>
                      <span>
                        Filter
                        {appliedFilters.length > 0 &&
                          `(${appliedFilters.length})`}
                      </span>
                    </Button>
                  ),
                  itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
                  disableItemsPerPageSelection: true,
                }}
              />
            </Conditional.False>
          </Conditional>
        </Columns.Column>
      </Columns>
      <LicenseKeysFilterModal
        show={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        onSubmit={handleTableFilterSubmit}
        data={filterCriteria}
      />
    </BoxLayout>
  );
}

export function SoftwareDetailsPage() {
  const { response } = useLoaderData() as DeferredResponse<SoftwareData>;

  return (
    <ProtectedPage permissions={SoftwareSeatPermissions.SOFTWARE_SEATS_READ}>
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <SoftwareDetailsPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default SoftwareDetailsPage;
