import { FilterOption, filter } from '@zspace/data';
import {
  PaginatedContentConfig,
  SalesOrderDevicesTableRow,
} from '@zspace/types';
import { useCallback, useMemo, useState } from 'react';
import { Element } from 'react-bulma-components';
import Conditional from '../../shared/conditional/conditional';
import useIsEmpty from '../../shared/hooks/is-empty';
import BasicTable from '../../ui/table/basic-table/basic-table';
import { Column, OnTableChangeData } from '../../ui/table/types';

const columns: Column<SalesOrderDevicesTableRow>[] = [
  {
    key: 'model',
    widthClassname: 'is-size-80',
    render: (el) => el.model,
    title: 'Hardware model',
    sortable: false,
  },
  {
    key: 'amount',
    widthClassname: 'is-size-20',
    render: (el) => el.amount,
    title: 'Amount',
    sortable: false,
  },
];

const filterOptions: FilterOption<SalesOrderDevicesTableRow>[] = [
  {
    getter: (el) => el.model,
    searchable: true,
  },
  {
    getter: (el) => String(el.amount),
    searchable: false,
  },
];

export interface SalesOrderDevicesTableProps {
  data: SalesOrderDevicesTableRow[];
}

const INITIAL_ITEMS_PER_PAGE = 10;

export function SalesOrderDevicesTable({ data }: SalesOrderDevicesTableProps) {
  const [tableData, setTableData] = useState<SalesOrderDevicesTableRow[]>(
    data.slice(0, INITIAL_ITEMS_PER_PAGE)
  );

  const [tableConfig, setTableConfig] = useState<PaginatedContentConfig>({
    itemsPerPage: INITIAL_ITEMS_PER_PAGE,
    pageNumber: 1,
    pages: Math.ceil(data.length / INITIAL_ITEMS_PER_PAGE),
    search: '',
    sortBy: '',
  });

  const isDataEmpty = useIsEmpty(data);

  const emptyContentText = useMemo(() => {
    const hasAppliedFilters = tableConfig.search !== '';
    if (hasAppliedFilters) {
      return 'There are no devices that match the selected criteria';
    }
    return 'This sales order does not contain any devices';
  }, [tableConfig.search]);

  const handleTableChange = useCallback(
    (value: OnTableChangeData<SalesOrderDevicesTableRow>) => {
      const newTableConfig: PaginatedContentConfig = {
        ...tableConfig,
        ...value.config,
        sortBy: value.column?.key || tableConfig.sortBy,
      };
      const filteredData = filter(data, newTableConfig, filterOptions);
      const newTableData = filteredData.slice(
        (newTableConfig.pageNumber - 1) * newTableConfig.itemsPerPage,
        newTableConfig.pageNumber * newTableConfig.itemsPerPage
      );

      newTableConfig.pages = Math.ceil(
        filteredData.length / newTableConfig.itemsPerPage
      );

      setTableData(newTableData);
      setTableConfig(newTableConfig);
    },
    [data, tableConfig]
  );

  return (
    <Conditional condition={isDataEmpty}>
      <Conditional.True>
        <Element display="flex" justifyContent="center" className="my-2">
          <span className="my-2 has-text-weight-light">{emptyContentText}</span>
        </Element>
      </Conditional.True>
      <Conditional.False>
        <Element>
          <BasicTable
            dataSource={tableData}
            columns={columns}
            onChange={handleTableChange}
            config={tableConfig}
            rowKey={'model'}
          />
        </Element>
      </Conditional.False>
    </Conditional>
  );
}

export default SalesOrderDevicesTable;
