import { DeviceGroup, MyDevicesFilter } from '@zspace/types';
import { ComponentProps, useCallback, useMemo } from 'react';
import { Element } from 'react-bulma-components';
import Conditional from '../../../shared/conditional/conditional';
import InboxItem from '../../../ui/inbox-table/inbox-item/inbox-item';
import InfiniteScroll from '../../../ui/infinite-scroll/infinite-scroll';
import PageSpinner from '../../../ui/page-spinner/page-spinner';
import DeviceGroupInboxItem from '../device-group-inbox-item/device-group-inbox-item';
import { MyDevices } from '../device-group-inbox-table';

export type DeviceGroupInboxProps = Omit<
  ComponentProps<typeof InfiniteScroll>,
  'children'
> & {
  myDevices?: MyDevices;
  deviceGroups: DeviceGroup[];
  selectedDeviceGroupId?: string;
  onClick: (deviceGroupId: string) => Promise<void>;
  onClickDeviceGroup?: (deviceGroup: DeviceGroup) => void;
  loadingInitialData?: boolean;
};

export function DeviceGroupInbox({
  fetchMore,
  hasMore,
  myDevices,
  deviceGroups,
  selectedDeviceGroupId,
  onClick,
  onClickDeviceGroup,
  loadingInitialData,
}: DeviceGroupInboxProps) {
  const handleDeviceGroupClick = useCallback(
    (deviceGroup: DeviceGroup) => {
      onClick(deviceGroup.id);
      onClickDeviceGroup?.(deviceGroup);
    },
    [onClick, onClickDeviceGroup]
  );

  const UnregisteredDevices = useCallback(() => {
    if (!myDevices || !myDevices.displayUnregistered) return null;

    return (
      <DeviceGroupInboxItem
        active={selectedDeviceGroupId === MyDevicesFilter.UNREGISTERED}
        title="Unregistered"
        deviceCount={myDevices.unregisteredDevicesCount}
        danger={myDevices.unregisteredDevicesCount > 0}
        onClick={() => onClick(MyDevicesFilter.UNREGISTERED)}
      />
    );
  }, [myDevices, onClick, selectedDeviceGroupId]);

  const UngroupedDevices = useCallback(() => {
    if (!myDevices || !myDevices.displayUngrouped) return null;

    return (
      <DeviceGroupInboxItem
        active={selectedDeviceGroupId === MyDevicesFilter.UNGROUPED}
        title="Ungrouped"
        deviceCount={myDevices?.ungroupedDevicesCount}
        danger={myDevices.ungroupedDevicesCount > 0}
        onClick={() => onClick(MyDevicesFilter.UNGROUPED)}
      />
    );
  }, [myDevices, onClick, selectedDeviceGroupId]);

  const footer = useMemo(() => {
    if (!myDevices) return null;

    const myDevicesCount = myDevices.allDevicesCount;
    const myDevicesText = myDevicesCount > 1 ? 'devices' : 'device';
    const allDevicesText = `All devices - ${myDevicesCount} ${myDevicesText}`;

    return (
      <InboxItem
        active={selectedDeviceGroupId === MyDevicesFilter.ALL}
        onClick={() => onClick(MyDevicesFilter.ALL)}
      >
        {allDevicesText}
      </InboxItem>
    );
  }, [myDevices, onClick, selectedDeviceGroupId]);

  const Empty = useCallback(
    () => (
      <Element
        display="flex"
        justifyContent="center"
        alignItems="center"
        className="h-full"
      >
        <span>There are no device groups to show</span>
      </Element>
    ),
    []
  );

  if (!myDevices && deviceGroups.length === 0) {
    return <Empty />;
  }

  return (
    <Conditional condition={!!loadingInitialData}>
      <Conditional.True>
        <PageSpinner />
      </Conditional.True>
      <Conditional.False>
        <InfiniteScroll fetchMore={fetchMore} hasMore={hasMore} footer={footer}>
          <UnregisteredDevices />
          <UngroupedDevices />
          {deviceGroups.map((deviceGroup) => (
            <DeviceGroupInboxItem
              key={deviceGroup.id}
              title={deviceGroup.name}
              deviceCount={deviceGroup.devices.length}
              active={selectedDeviceGroupId === deviceGroup.id}
              onClick={() => handleDeviceGroupClick(deviceGroup)}
              groupCode={deviceGroup.groupCode?.code}
            />
          ))}
        </InfiniteScroll>
      </Conditional.False>
    </Conditional>
  );
}

export default DeviceGroupInbox;
