import { useCallback, useMemo } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaCopy } from 'react-icons/fa';
import { FaFolder, FaFolderOpen, FaKey } from 'react-icons/fa6';
import { PiWarningFill } from 'react-icons/pi';
import { useThrottledCallback } from 'use-debounce';
import Conditional from '../../../shared/conditional/conditional';
import useToast from '../../../shared/hooks/toasts';
import If from '../../../shared/if/if';
import InboxItem from '../../../ui/inbox-table/inbox-item/inbox-item';

export type DeviceGroupInboxItemProps = {
  title: string;
  deviceCount: number;
  danger?: boolean;
  active: boolean;
  groupCode?: string;
  onClick: () => void;
};

export function DeviceGroupInboxItem({
  title,
  deviceCount,
  active,
  danger = false,
  groupCode,
  onClick,
}: DeviceGroupInboxItemProps) {
  const toast = useToast();
  const deviceGroupTitle = useMemo(() => {
    const devicesText = deviceCount === 1 ? 'device' : 'devices';
    return `${title} - ${deviceCount} ${devicesText}`;
  }, [title, deviceCount]);

  const copyGroupCode = useThrottledCallback(() => {
    navigator.clipboard.writeText(groupCode!);
    toast.success('Group code copied to clipboard');
  }, 3000);

  const handleCopyGroupCode = useCallback(
    (e: React.MouseEvent) => {
      if (!groupCode) return;

      e.stopPropagation();
      copyGroupCode();
    },
    [copyGroupCode, groupCode]
  );

  return (
    <InboxItem active={active} onClick={onClick}>
      <Conditional condition={active}>
        <Conditional.True>
          <Element display="flex" flexDirection="column" className="gap-1">
            <Element>
              <Icon color="white">
                <FaFolderOpen />
              </Icon>
              <span>{deviceGroupTitle}</span>
            </Element>
            <If condition={!!groupCode}>
              <Element>
                <Icon color="white">
                  <FaKey />
                </Icon>
                <span>Group code {groupCode}</span>
                <Icon
                  className="is-clickable"
                  color="white"
                  onClick={handleCopyGroupCode}
                >
                  <FaCopy />
                </Icon>
              </Element>
            </If>
          </Element>
        </Conditional.True>
        <Conditional.False>
          <Icon color="primary-dark">
            <FaFolder />
          </Icon>
          <span>{deviceGroupTitle}</span>
          <If condition={danger}>
            <Icon color="danger-dark">
              <PiWarningFill />
            </Icon>
          </If>
        </Conditional.False>
      </Conditional>
    </InboxItem>
  );
}

export default DeviceGroupInboxItem;
