const ZSPACE_URL = 'https://zspace.com';
const SUPPORT_URL = 'https://support.zspace.com';
const ZCENTRAL_URL = 'https://go.zspace.com';
const DEVELOPERS_URL = 'https://developer.zspace.com';
const DEVELOPER_COMMUNITY_URL = 'https://dev-community.zspace.com';
const INVESTOR_RELATIONS_URL = 'https://investor.zspace.com';

export {
  DEVELOPERS_URL,
  DEVELOPER_COMMUNITY_URL,
  INVESTOR_RELATIONS_URL,
  SUPPORT_URL,
  ZCENTRAL_URL,
  ZSPACE_URL,
};
