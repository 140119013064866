import { ComponentProps, ReactNode } from 'react';
import Conditional from '../../../shared/conditional/conditional';
import useIsEmpty from '../../../shared/hooks/is-empty';
import InfiniteScroll from '../../infinite-scroll/infinite-scroll';
import PageSpinner from '../../page-spinner/page-spinner';
import BasicTable, { BasicTableProps } from '../basic-table/basic-table';

type InfiniteScrollProps = Omit<
  ComponentProps<typeof InfiniteScroll>,
  'children'
>;

export type InfiniteTableProps<T, K = unknown> = InfiniteScrollProps &
  BasicTableProps<T, K> & {
    loadingInitialData?: boolean;
    empty: ReactNode;
  };

export type CustomInfiniteTableProps<T> = Omit<
  InfiniteTableProps<T>,
  'columns' | 'rowKey'
>;

export function InfiniteTable<T, K = unknown>({
  fetchMore,
  hasMore,
  loadingInitialData = false,
  empty,
  ...tableProps
}: InfiniteTableProps<T, K>) {
  const isDataEmpty = useIsEmpty(tableProps.dataSource);

  return (
    <Conditional condition={loadingInitialData}>
      <Conditional.True>
        <PageSpinner />
      </Conditional.True>
      <Conditional.False>
        <Conditional condition={isDataEmpty}>
          <Conditional.True>{empty}</Conditional.True>
          <Conditional.False>
            <InfiniteScroll fetchMore={fetchMore} hasMore={hasMore}>
              <BasicTable {...tableProps} bordered={false} />
            </InfiniteScroll>
          </Conditional.False>
        </Conditional>
      </Conditional.False>
    </Conditional>
  );
}

export default InfiniteTable;
