import { UserRoleType } from '@zspace/types';
import { FocusEvent, useCallback, useMemo, useState } from 'react';
import { Navbar as BulmaNavbar, Icon } from 'react-bulma-components';
import { FaFileInvoice } from 'react-icons/fa6';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Grid from '../../../assets/icons/grid';
import ManagementHubLogo from '../../../assets/icons/management-hub-logo';
import useUser from '../../shared/hooks/user';
import useUserHasZspaceStaffPermissions from '../../shared/hooks/user-has-zspace-staff-permissions';
import If from '../../shared/if/if';
import AppLauncher from '../app-launcher/app-launcher';
import Profile from '../profile/profile';
import ProfileActions from '../profile/profile-actions/profile-actions';
import styles from './navbar.module.scss';

export function Navbar() {
  const [activeAccountDropdown, setActiveAccountDropdown] = useState(false);
  const [activeAppLauncher, setActiveAppLauncher] = useState(false);
  const { user } = useUser();
  const hasZspaceStaffPermissions = useUserHasZspaceStaffPermissions();
  const location = useLocation();
  const navigate = useNavigate();

  const isInHomePage = useMemo(
    () => location.pathname === '/',
    [location.pathname]
  );

  const profileContainerClassName = useMemo(() => {
    const isOnlyCustomerUser = user.roles.every(
      (roleAssignment) => roleAssignment.role.name === UserRoleType.CUSTOMER
    );

    if (isOnlyCustomerUser || !user.activeRole) {
      return styles.item;
    }
    const defaultClassName = `${styles.item} ${styles.profileContainer}`;
    return activeAccountDropdown
      ? `${styles.item} active-navbar-item`
      : defaultClassName;
  }, [activeAccountDropdown, user.activeRole, user.roles]);

  const handleAccountOnClick = useCallback(() => {
    setActiveAccountDropdown((prev) => !prev);
  }, []);

  const handleAppLauncherOnClick = useCallback(() => {
    setActiveAppLauncher((prev) => !prev);
  }, []);

  const handleDropdownOnBlur = useCallback(
    (event: FocusEvent<HTMLAnchorElement>) => {
      if (!event.currentTarget.contains(event.relatedTarget as Node)) {
        setActiveAccountDropdown(false);
        setActiveAppLauncher(false);
      }
    },
    []
  );

  const navigateToWorkOrders = useCallback(() => {
    navigate('/work-orders');
  }, [navigate]);

  return (
    <BulmaNavbar className={styles.navbar}>
      <BulmaNavbar.Brand className="is-justify-content-center is-justify-content-start-tablet">
        <BulmaNavbar.Item renderAs={Link} to={'/'}>
          <ManagementHubLogo />
        </BulmaNavbar.Item>
      </BulmaNavbar.Brand>
      <BulmaNavbar.Menu className="is-hidden-mobile">
        <BulmaNavbar.Container align="right">
          <If condition={!isInHomePage && hasZspaceStaffPermissions}>
            <BulmaNavbar.Link
              className={styles.item}
              arrowless
              onClick={navigateToWorkOrders}
            >
              <Icon color="primary-dark">
                <FaFileInvoice />
              </Icon>
              <span className="is-underlined has-text-primary-dark">
                Work orders
              </span>
            </BulmaNavbar.Link>
          </If>
          <BulmaNavbar.Item
            active={activeAppLauncher}
            onClick={handleAppLauncherOnClick}
            onBlur={handleDropdownOnBlur}
            className={styles.item}
            tabIndex={0}
          >
            <BulmaNavbar.Link className={styles.item} arrowless>
              <Icon>
                <Grid />
              </Icon>
            </BulmaNavbar.Link>
            <BulmaNavbar.Dropdown
              className={`is-borderless ${styles.appLauncher}`}
              right
              p={4}
            >
              <AppLauncher />
            </BulmaNavbar.Dropdown>
          </BulmaNavbar.Item>
          <BulmaNavbar.Item
            active={activeAccountDropdown}
            onClick={handleAccountOnClick}
            onBlur={handleDropdownOnBlur}
            className={profileContainerClassName}
            tabIndex={0}
          >
            <BulmaNavbar.Link className={styles.item} arrowless>
              <Profile active={activeAccountDropdown} />
            </BulmaNavbar.Link>
            <BulmaNavbar.Dropdown className="is-borderless is-size-6" right>
              <ProfileActions />
            </BulmaNavbar.Dropdown>
          </BulmaNavbar.Item>
        </BulmaNavbar.Container>
      </BulmaNavbar.Menu>
    </BulmaNavbar>
  );
}

export default Navbar;
