import { DeviceDetailsTableRow, DeviceSwapError } from '@zspace/types';
import { AxiosError, HttpStatusCode } from 'axios';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Columns, Section } from 'react-bulma-components';
import { FaCheck } from 'react-icons/fa6';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';
import BackButton from '../../../shared/back-button/back-button';
import useHttpRequest from '../../../shared/hooks/http-request';
import BoxLayout from '../../../ui/box-layout/box-layout';
import Button from '../../../ui/button/button';
import DefaultErrorMessage from '../../../ui/default-error-message/default-error-message';
import FeedbackMessage from '../../../ui/feedback-message/feedback-message';
import { replaceDevice } from '../../partners-service';
import DeviceDetailsTable from '../device-details-table/device-details-table';
import { DeviceReplacementContext } from '../device-replacement-layout/device-replacement-layout';

export function DeviceReplacementConfirmPage() {
  const { faultyDevice, deviceFailureReason, replacementDevice } =
    useOutletContext<DeviceReplacementContext>();
  const { executeHttpRequest, isLoading: isLoadingReplace } = useHttpRequest();
  const navigate = useNavigate();
  const [replaceDeviceError, setReplaceDeviceError] = useState<
    string | ReactNode
  >();

  const faultyDeviceTableDatasource: DeviceDetailsTableRow[] = useMemo(
    () => [faultyDevice],
    [faultyDevice]
  );

  const replacementDeviceTableDatasource: DeviceDetailsTableRow[] = useMemo(
    () => [replacementDevice],
    [replacementDevice]
  );

  const onBackClick = useCallback(
    () => navigate('/device-replacement/new-device'),
    [navigate]
  );

  const cleanError = useCallback(() => setReplaceDeviceError(undefined), []);

  const onDeviceReplace = useCallback(
    () =>
      executeHttpRequest({
        asyncFunction: async () => {
          cleanError();
          await replaceDevice({
            faultyDeviceSerialNumber: faultyDevice?.serialNumber,
            replacementDeviceSerialNumber: replacementDevice?.serialNumber,
            deviceFailureReason,
          });
          navigate('/device-replacement/summary');
        },
        customErrorHandler: (error: AxiosError<DeviceSwapError>) => {
          switch (error.response?.status) {
            case HttpStatusCode.BadRequest:
            case HttpStatusCode.Conflict:
              setReplaceDeviceError(error.response?.data.message);
              break;
            default:
              setReplaceDeviceError(DefaultErrorMessage);
              break;
          }
        },
      }),
    [
      cleanError,
      deviceFailureReason,
      executeHttpRequest,
      faultyDevice?.serialNumber,
      navigate,
      replacementDevice?.serialNumber,
    ]
  );

  if (!faultyDevice || !deviceFailureReason || !replacementDevice) {
    return <Navigate to="/device-replacement" replace />;
  }

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Section p={4}>
          <Columns display="flex" alignItems="center" paddingless>
            <Columns.Column>
              <BackButton onClick={onBackClick} />
              <h1 className="is-size-3 has-text-weight-light">
                Replace faulty device
              </h1>
              <h3 className="is-size-5 has-text-weight-light">
                New device validation with Netsuite
              </h3>
            </Columns.Column>
            <Columns.Column display="flex" justifyContent="flex-end">
              <Button
                color="primary-dark"
                onClick={onDeviceReplace}
                isExecutingAction={isLoadingReplace}
              >
                <Button.LoadingIcon icon={FaCheck} />
                <span>Replace</span>
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      }
    >
      <Section
        paddingless
        display="flex"
        className="gap-8"
        flexDirection="column"
      >
        <span className="is-size-6">
          You are replacing the following faulty device:
        </span>
        <DeviceDetailsTable dataSource={faultyDeviceTableDatasource} />

        <span className="is-size-6">New device:</span>
        <DeviceDetailsTable dataSource={replacementDeviceTableDatasource} />

        <FeedbackMessage>{replaceDeviceError}</FeedbackMessage>
      </Section>
    </BoxLayout>
  );
}

export default DeviceReplacementConfirmPage;
