import { ReactNode, useMemo } from 'react';
import { Box, Element } from 'react-bulma-components';
import If from '../../shared/if/if';
import styles from './box-layout.module.scss';

export type BoxLayoutProps = {
  header?: ReactNode;
  children: ReactNode;
  className?: string;
  flex?: boolean;
  headerClassName?: string;
  childrenClassName?: string;
};

export function BoxLayout({
  header,
  children,
  className,
  flex = false,
  headerClassName: headerClassNameProp,
  childrenClassName: childrenClassNameProp,
}: BoxLayoutProps) {
  const boxClassName = useMemo(() => {
    let _className = className;
    if (flex) {
      _className += ' is-flex is-flex-direction-column';
    }
    return _className;
  }, [className, flex]);

  const headerClassName = useMemo(() => {
    let _className = styles.header;
    if (headerClassNameProp) {
      _className += ` ${headerClassNameProp}`;
    }
    return _className;
  }, [headerClassNameProp]);

  const childrenClassName = useMemo(() => {
    let _className = styles.container;
    if (flex) {
      _className += ' is-flex is-flex-direction-column is-flex-grow-1';
    }
    if (childrenClassNameProp) {
      _className += ` ${childrenClassNameProp}`;
    }
    return _className;
  }, [childrenClassNameProp, flex]);

  return (
    <Box paddingless className={boxClassName}>
      <If condition={!!header}>
        <Element p={4} className={headerClassName}>
          {header}
        </Element>
      </If>

      <Element p={4} className={childrenClassName}>
        {children}
      </Element>
    </Box>
  );
}

export default BoxLayout;
