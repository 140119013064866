import { useCallback } from 'react';
import { Box, Columns, Element, Icon } from 'react-bulma-components';
import { FaArrowLeft, FaLock } from 'react-icons/fa6';
import Button from '../../ui/button/button';

export function NoAccessErrorCard() {
  const navigateToZSpace = useCallback(() => {
    window.location.assign('https://zspace.com');
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      paddingless
      my={4}
      mx={10}
      className="is-min-height-90"
    >
      <Element
        display="flex"
        alignItems="center"
        flexDirection="column"
        mt={10}
        className="gap-9"
      >
        <Icon>
          <FaLock size={32} />
        </Icon>
        <h1 className="is-size-4 has-text-weight-light">
          You don’t have permission to access this page
        </h1>
        <Columns paddingless>
          <Columns.Column>
            <Button color="primary-dark" outlined onClick={navigateToZSpace}>
              <Icon>
                <FaArrowLeft />
              </Icon>
              <span>Back to zSpace.com</span>
            </Button>
          </Columns.Column>
        </Columns>
      </Element>
    </Box>
  );
}

export default NoAccessErrorCard;
