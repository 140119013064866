import { faker } from '@faker-js/faker';
import { Factory, define, sequence } from 'cooky-cutter';
import {
  BasicSalesOrderData,
  SalesOrder,
  SalesOrderData,
  SalesOrderShippingStatus,
  SalesOrderStatus,
} from '../types';
import { opportunityFactory } from './opportunities';

const shippingStatusFactory = () =>
  faker.helpers.arrayElement([
    SalesOrderShippingStatus.SCHEDULED_PENDING_SHIPMENT,
    SalesOrderShippingStatus.OPS_TO_SCHEDULE,
    SalesOrderShippingStatus.READY_FOR_BOOKING,
    SalesOrderShippingStatus.HOLD_SOFTWARE,
    SalesOrderShippingStatus.FULLY_SHIPPED,
    SalesOrderShippingStatus.ADVANCE_PAYMENT,
    SalesOrderShippingStatus.RELEASE_SOFTWARE,
    SalesOrderShippingStatus.INVENTORY_SHORTAGE,
    SalesOrderShippingStatus.HOLD_FOR_FIRM_PO,
    SalesOrderShippingStatus.PARTIAL_SHIPPED,
    SalesOrderShippingStatus.HOLD_OTHER,
    SalesOrderShippingStatus.DEBOOKED,
    SalesOrderShippingStatus.PRE_CONFIG_PENDING,
  ]);

export const salesOrderFactory: Factory<SalesOrder> = define<SalesOrder>({
  id: () => faker.string.uuid(),
  number: sequence,
  status: () =>
    faker.helpers.arrayElement([
      SalesOrderStatus.FULFILLED,
      SalesOrderStatus.NOT_FULFILLED,
      SalesOrderStatus.PARTIALLY_FULFILLED,
    ]),
  lines: () => [],
  opportunity: opportunityFactory,
  closeDate: () => faker.date.anytime(),
  purchaseDate: () => faker.date.anytime(),
  endUser: () => faker.internet.email().toLocaleLowerCase(),
  permissionsGroupUserRoleAssignments: () => [],
  shippingStatus: shippingStatusFactory,
});

export const salesOrderDataFactory: Factory<SalesOrderData> =
  define<SalesOrderData>({
    id: () => faker.string.uuid(),
    number: sequence,
    status: () =>
      faker.helpers.arrayElement([
        SalesOrderStatus.FULFILLED,
        SalesOrderStatus.NOT_FULFILLED,
        SalesOrderStatus.PARTIALLY_FULFILLED,
      ]),
    lines: () => [],
    opportunity: opportunityFactory,
    closeDate: () => faker.date.anytime(),
    purchaseDate: () => faker.date.anytime(),
    permissionsGroupUserRoleAssignments: () => [],
    managersCount: () => faker.number.int(),
    workOrders: () => [],
    shippingStatus: shippingStatusFactory,
  });

export const salesOrderBasicDataFactory: Factory<BasicSalesOrderData> =
  define<BasicSalesOrderData>({
    id: () => faker.string.uuid(),
    number: sequence,
    devicesCount: () => faker.number.int(),
    licensingProductGroupsCount: () => faker.number.int(),
    eulaPending: () => faker.datatype.boolean(),
  });
