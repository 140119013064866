import { capitalizeString } from '@zspace/format';
import {
  PaginatedAPIResponse,
  PaginatedContentConfig,
  WorkOrderData,
  WorkOrderStatus,
  WorkOrderType,
  WorkOrdersCriteria,
  WorkOrdersTableModalFilterDataType,
} from '@zspace/types';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Icon } from 'react-bulma-components';
import { FaFilter } from 'react-icons/fa6';
import useUpdateUrl from '../../shared/hooks/url';
import Button from '../../ui/button/button';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import { OnTableChangeData } from '../../ui/table/types';
import { workOrderDraftsAndCompletedTableFilterModalFields } from '../work-orders-table-filter-modal/utils';
import WorkOrdersTableFilterModal from '../work-orders-table-filter-modal/work-orders-table-filter-modal';
import WorkOrdersTable from '../work-orders-table/work-orders-table';

export interface WorkOrdersTableCardProps {
  initialCriteria: WorkOrdersCriteria;
  criteria: WorkOrdersCriteria;
  setCriteria: Dispatch<SetStateAction<WorkOrdersCriteria>>;
  tableData: PaginatedAPIResponse<WorkOrderData>;
  workOrdersStatus: WorkOrderStatus;
  emptyTableContent: ReactNode;
  emptyTableContentWithFilters: ReactNode;
  setFilterTagList: Dispatch<SetStateAction<ReactNode>>;
}

export function WorkOrdersTableCard({
  initialCriteria,
  criteria,
  setCriteria,
  tableData,
  workOrdersStatus,
  emptyTableContent,
  emptyTableContentWithFilters,
  setFilterTagList,
}: WorkOrdersTableCardProps) {
  const updateUrl = useUpdateUrl();

  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);

  const filterTagsData = useMemo(() => {
    return {
      workOrderName: {
        label: 'Work order name',
        value: criteria.workOrderName
          ? criteria.workOrderNameFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', criteria.workOrderName)
          : '',
      },
      account: {
        label: 'Account',
        value:
          criteria.account.length > 0
            ? criteria.account
                .toLowerCase()
                .replace('_', ' ')
                .concat(' ', criteria.account)
            : '',
      },
      minTotalDevicesNumber: {
        label: 'Min number of devices',
        value: criteria.minTotalDevicesNumber ?? '',
      },
      maxTotalDevicesNumber: {
        label: 'Max number of devices',
        value: criteria.maxTotalDevicesNumber ?? '',
      },
      salesOrder: {
        label: 'Sales order #',
        value: criteria.salesOrder.join(', '),
      },
      creationDateFrom: {
        label: 'Creation date from',
        value: criteria.creationDateFrom,
      },
      creationDateTo: {
        label: 'Creation date to',
        value: criteria.creationDateTo,
      },
      type: {
        label: 'Type',
        value:
          criteria.type !== WorkOrderType.ALL
            ? capitalizeString(criteria.type.replace('_', ' '))
            : '',
      },
      createdBy: {
        label: 'Created by',
        value: criteria.createdBy.join(', '),
      },
    };
  }, [criteria]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { label, value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const handleOnTableChange = useCallback(
    (value: OnTableChangeData<WorkOrderData>): void => {
      const newData = {
        ...criteria,
        ...value.config,
        sortBy: value.column?.key || criteria.sortBy,
      };

      setCriteria(newData);
      updateUrl(newData);
    },
    [criteria, setCriteria, updateUrl]
  );

  const tableConfig: PaginatedContentConfig = useMemo(
    () => ({ ...criteria, pages: tableData.pages }),
    [criteria, tableData.pages]
  );

  const showForm = useMemo(
    () =>
      tableData.data.length > 0 ||
      criteria.search !== '' ||
      appliedFilters.length > 0,
    [appliedFilters.length, criteria.search, tableData.data.length]
  );

  const hasAppliedFilters = useMemo(
    () => criteria.search !== '' || appliedFilters.length > 0,
    [appliedFilters.length, criteria.search]
  );

  const emptyContent = useMemo(() => {
    const emptyContentText = hasAppliedFilters
      ? emptyTableContentWithFilters
      : emptyTableContent;
    return <span className="has-text-weight-light">{emptyContentText}</span>;
  }, [emptyTableContent, emptyTableContentWithFilters, hasAppliedFilters]);

  const handleTableFilterSubmit = useCallback(
    (value: WorkOrdersTableModalFilterDataType): void => {
      const newCriteria: WorkOrdersCriteria = {
        itemsPerPage: criteria.itemsPerPage,
        pageNumber: criteria.pageNumber,
        search: criteria.search,
        sortBy: criteria.sortBy,
        status: workOrdersStatus,
        ...value,
      };
      setCriteria(newCriteria);
      updateUrl(newCriteria);
    },
    [criteria, setCriteria, updateUrl, workOrdersStatus]
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof WorkOrdersTableModalFilterDataType) => {
      const newCriteria: WorkOrdersCriteria = { ...criteria };

      newCriteria[filterDataKey] = initialCriteria[filterDataKey] as string &
        number &
        string[];

      handleTableFilterSubmit(newCriteria);
    },
    [criteria, handleTableFilterSubmit, initialCriteria]
  );

  useEffect(() => {
    let appliedFiltersList = null;
    if (appliedFilters.length > 0) {
      appliedFiltersList = (
        <FilterTagList
          list={filterTagsData}
          onRemove={(item) => {
            onRemoveFilterTag(item as keyof WorkOrdersTableModalFilterDataType);
          }}
          title="Filters"
        />
      );
    }
    setFilterTagList(appliedFiltersList);
  }, [setFilterTagList, appliedFilters, filterTagsData, onRemoveFilterTag]);

  return (
    <>
      <WorkOrdersTable
        dataSource={tableData.data}
        onChange={handleOnTableChange}
        config={tableConfig}
        form={{
          show: showForm,
          extra: (
            <Button
              type="button"
              color="primary-dark"
              className="ml-2"
              outlined={appliedFilters.length === 0}
              onClick={() => setIsFilterModalVisible(true)}
            >
              <Icon>
                <FaFilter />
              </Icon>
              <span>
                Filter
                {appliedFilters.length > 0 && `(${appliedFilters.length})`}
              </span>
            </Button>
          ),
        }}
        empty={emptyContent}
      />
      <WorkOrdersTableFilterModal
        show={isFilterModalVisible}
        data={criteria}
        fields={workOrderDraftsAndCompletedTableFilterModalFields}
        onClose={() => setIsFilterModalVisible(false)}
        onSubmit={handleTableFilterSubmit}
      />
    </>
  );
}

export default WorkOrdersTableCard;
