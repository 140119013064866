import { Element } from 'react-bulma-components';
import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';

export type UserInvitedConfirmationModalProps = {
  show: boolean;
  email: string;
  onInviteAnotherUser: () => void;
  navigateToPermissionsHome: () => void;
};

const TITLE = 'User invited';
const SUBTITLE = (email: string) =>
  `You have successfully invited the user ${email}`;
const BACK_BUTTON_TEXT = 'Back to permissions home';
const INVITE_ANOTHER_USER_BUTTON_TEXT = 'Invite another user';

export function UserInvitedConfirmationModal({
  show,
  email,
  onInviteAnotherUser,
  navigateToPermissionsHome,
}: UserInvitedConfirmationModalProps) {
  return (
    <Modal show={show}>
      <h1 className="has-text-weight-light is-size-4 mb-4">{TITLE}</h1>
      <p className="is-size-7">{SUBTITLE(email)}</p>
      <Element
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={8}
      >
        <Button
          color="primary-dark"
          outlined
          onClick={navigateToPermissionsHome}
        >
          {BACK_BUTTON_TEXT}
        </Button>
        <Button color="primary-dark" outlined onClick={onInviteAnotherUser}>
          {INVITE_ANOTHER_USER_BUTTON_TEXT}
        </Button>
      </Element>
    </Modal>
  );
}

export default UserInvitedConfirmationModal;
