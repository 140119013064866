import { GroupCode } from '@zspace/types';
import { Element } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import Button from '../../../ui/button/button';
import Modal from '../../../ui/modal/modal';
import { DeviceGroupCode } from '../../device-group-code/device-group-code';

export type DeviceGroupCreatedConfirmationModalProps = {
  show: boolean;
  groupCode: GroupCode;
  onCreateAnotherDeviceGroup: () => void;
  onClose: () => void;
};

const TITLE = 'Device group created';
const SUBTITLE =
  'Share this code with members of your organization to enroll their devices into this group.';
const LEARN_MORE_TEXT = 'Learn more';
const CLOSE_BUTTON_TEXT = 'Close';
const CREATE_ANOTHER_GROUP_BUTTON_TEXT = 'Create another group';

export function DeviceGroupCreatedConfirmationModal({
  show,
  groupCode,
  onCreateAnotherDeviceGroup,
  onClose,
}: DeviceGroupCreatedConfirmationModalProps) {
  return (
    <Modal show={show}>
      <h1 className="has-text-weight-light is-size-4 mb-4">{TITLE}</h1>
      <Element className="is-size-7">
        <DeviceGroupCode groupCode={groupCode} />
      </Element>
      <p className="is-size-7 py-2">{SUBTITLE}</p>
      <p className="is-size-7">
        <Link className="is-underlined has-text-primary" to={'#'}>
          {LEARN_MORE_TEXT}
        </Link>
      </p>
      <Element
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={8}
      >
        <Button color="primary-dark" outlined onClick={onClose}>
          {CLOSE_BUTTON_TEXT}
        </Button>
        <Button
          color="primary-dark"
          outlined
          onClick={onCreateAnotherDeviceGroup}
        >
          {CREATE_ANOTHER_GROUP_BUTTON_TEXT}
        </Button>
      </Element>
    </Modal>
  );
}

export default DeviceGroupCreatedConfirmationModal;
