import {
  DeviceSwapError,
  DeviceSwapResponseCodes,
  FaultyDeviceLookup,
} from '@zspace/types';
import { AxiosError, HttpStatusCode } from 'axios';
import { ChangeEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { Columns, Form, Section } from 'react-bulma-components';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { useNavigate, useOutletContext } from 'react-router-dom';
import BackButton from '../../../shared/back-button/back-button';
import { FormFieldData } from '../../../shared/form';
import useHttpRequest from '../../../shared/hooks/http-request';
import BoxLayout from '../../../ui/box-layout/box-layout';
import Button from '../../../ui/button/button';
import DefaultErrorMessage from '../../../ui/default-error-message/default-error-message';
import FeedbackMessage from '../../../ui/feedback-message/feedback-message';
import { faultyDeviceLookup } from '../../partners-service';
import {
  columnSizes,
  DeviceReplacementContext,
} from '../device-replacement-layout/device-replacement-layout';

type FaultyDeviceFormData = FormFieldData<FaultyDeviceLookup>;

const INVALID_SERIAL_NUMBER_ERROR = 'Invalid Serial No';
const SERIAL_NUMBER_NOT_ASSIGNED_TO_OPEN_WORK_ORDER_ERROR =
  'Serial No must be assigned to an open work order';

const validateForm = {
  serialNumber: (value: string) =>
    !value.trim() ? 'Please provide a serial number' : null,
};

export function FaultyDeviceLookupPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FaultyDeviceFormData>({
    serialNumber: { value: '', touched: false, error: null },
  });
  const { executeHttpRequest, isLoading: isLoadingDeviceLookup } =
    useHttpRequest();
  const [customErrorMessage, setCustomErrorMessage] = useState<
    string | ReactNode
  >();
  const { setFaultyDevice: setFailedDevice } =
    useOutletContext<DeviceReplacementContext>();

  const errorMessage = useMemo(() => {
    let error: string | ReactNode = '';

    if (formData.serialNumber.error) {
      error = formData.serialNumber.error;
    }

    if (customErrorMessage) {
      error = customErrorMessage;
    }

    return error;
  }, [customErrorMessage, formData.serialNumber.error]);

  const onBackClick = useCallback(() => navigate('/'), [navigate]);

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setFormData({
        ...formData,
        serialNumber: {
          ...formData.serialNumber,
          value: newValue,
          touched: true,
        },
      });
    },
    [formData]
  );

  const cleanCustomErrorMessage = useCallback(() => {
    setCustomErrorMessage(undefined);
  }, []);

  const onFaultyDeviceLookupErrorHandler = useCallback(
    (error: AxiosError<DeviceSwapError>) => {
      switch (error.response?.status) {
        case HttpStatusCode.BadRequest: {
          let message = INVALID_SERIAL_NUMBER_ERROR;
          const errorCode = error.response?.data.code;
          if (
            errorCode ===
            DeviceSwapResponseCodes.ORIGINAL_SERIAL_NUMBER_FULFILLMENT_PERIOD_CLOSED
          ) {
            message = error.response?.data.message;
          }
          setCustomErrorMessage(message);
          break;
        }
        case HttpStatusCode.Conflict:
          setCustomErrorMessage(
            SERIAL_NUMBER_NOT_ASSIGNED_TO_OPEN_WORK_ORDER_ERROR
          );
          break;
        default:
          setCustomErrorMessage(DefaultErrorMessage);
          break;
      }
    },
    []
  );

  const onFaultyDeviceLookup = useCallback(async () => {
    cleanCustomErrorMessage();
    // Validate form
    const formValidation = Object.keys(formData).reduce(
      (acc, key) => {
        const formDataKey = key as keyof FaultyDeviceLookup;
        const error = validateForm[formDataKey]?.(formData[formDataKey].value);
        setFormData((prev) => ({
          ...prev,
          [formDataKey]: { ...prev[formDataKey], error, touched: true },
        }));
        return {
          values: { ...acc.values, [key]: formData[formDataKey].value },
          errors: { ...acc.errors, [key]: error },
        };
      },
      { values: {}, errors: {} }
    );

    const isValidForm = Object.values(formValidation.errors).every(
      (fieldError) => !fieldError
    );

    if (isValidForm) {
      executeHttpRequest({
        asyncFunction: async () => {
          const failedDeviceDetails = await faultyDeviceLookup(
            formData.serialNumber.value
          );
          setFailedDevice(failedDeviceDetails);
          navigate('/device-replacement/reason-selection');
        },
        customErrorHandler: onFaultyDeviceLookupErrorHandler,
      });
    }
  }, [
    cleanCustomErrorMessage,
    executeHttpRequest,
    formData,
    navigate,
    onFaultyDeviceLookupErrorHandler,
    setFailedDevice,
  ]);

  const onBlur = useCallback(() => {
    const value = formData.serialNumber.value;
    const error = validateForm.serialNumber(value);
    setFormData({
      ...formData,
      serialNumber: { ...formData.serialNumber, error },
    });
  }, [formData]);

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Section p={4}>
          <BackButton onClick={onBackClick} />
          <h1 className="is-size-3 has-text-weight-light">
            Replace faulty device
          </h1>
          <h3 className="is-size-5 has-text-weight-light">
            Original device validation
          </h3>
        </Section>
      }
    >
      <span className="is-size-6">
        Please scan or manually input the serial number of the device you wish
        to replace.
      </span>
      <br />
      <span className="is-size-6">
        If you are using a scanner, make sure you click on the input before
        scanning
      </span>
      <Columns mt={4} className="mb-0" display="flex" alignItems="flex-end">
        <Columns.Column {...columnSizes}>
          <Form.Field>
            <Form.Label
              className="is-vertical has-text-weight-normal"
              htmlFor="serialNumber"
            >
              Faulty device serial number
            </Form.Label>
            <Form.Control>
              <Form.Input
                id="serialNumber"
                value={formData.serialNumber.value}
                onChange={onInputChange}
                onBlur={onBlur}
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Control>
            <Button
              color="primary-dark"
              onClick={onFaultyDeviceLookup}
              isExecutingAction={isLoadingDeviceLookup}
            >
              <Button.LoadingIcon icon={FaMagnifyingGlass} />
              <span>Device lookup</span>
            </Button>
          </Form.Control>
        </Columns.Column>
      </Columns>
      <FeedbackMessage>{errorMessage}</FeedbackMessage>
    </BoxLayout>
  );
}

export default FaultyDeviceLookupPage;
