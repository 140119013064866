import { Permissions, PermissionsGroupName } from '@zspace/roles';
import { ReactNode, createContext, useContext, useMemo } from 'react';
import usePermissions from '../hooks/permissions';

export type CheckPermissionsProps = {
  children: ReactNode;
  permissions: Permissions | Permissions[];
  salesOrderIds?: string[];
  allowedPermissionGroups?: PermissionsGroupName[];
};

type CheckPermissionsContextType = {
  hasPermissions: boolean;
};

const CheckPermissionsContext = createContext<CheckPermissionsContextType>({
  hasPermissions: false,
});

export function CheckPermissions({
  children,
  permissions,
  salesOrderIds,
  allowedPermissionGroups,
}: CheckPermissionsProps) {
  const userHasPermissions = usePermissions();

  const hasPermissions = useMemo(
    () =>
      userHasPermissions({
        permissions,
        salesOrderIdsRelatedPermissions: salesOrderIds,
        allowedPermissionGroups,
      }),
    [allowedPermissionGroups, permissions, salesOrderIds, userHasPermissions]
  );

  return (
    <CheckPermissionsContext.Provider value={{ hasPermissions }}>
      {children}
    </CheckPermissionsContext.Provider>
  );
}

type CheckPermissionsRenderProps = {
  children: ReactNode;
};

const CheckPermissionsRender = ({ children }: CheckPermissionsRenderProps) => {
  const { hasPermissions } = useContext(CheckPermissionsContext);
  return hasPermissions ? children : null;
};

type CheckPermissionsFallbackProps = {
  children: ReactNode;
};

const CheckPermissionsFallback = ({
  children,
}: CheckPermissionsFallbackProps) => {
  const { hasPermissions } = useContext(CheckPermissionsContext);
  return hasPermissions ? null : children;
};

CheckPermissions.Render = CheckPermissionsRender;
CheckPermissions.Fallback = CheckPermissionsFallback;

export default CheckPermissions;
