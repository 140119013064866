import { Element, Icon } from 'react-bulma-components';
import CircleDisplay from '../../../assets/icons/circle-display';
import styles from './resolution-warning-message.module.scss';

const TITLE = 'zSpace Management Hub is optimized for large screens';
const SUBTITLE =
  'Please access the portal through a computer for an optimal experience';

export function ResolutionWarningMessage() {
  return (
    <Element
      className={styles.container}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Icon className={styles.icon}>
        <CircleDisplay />
      </Icon>
      <h1 className="is-size-4 mb-8">{TITLE}</h1>
      <h2 className="is-size-8">{SUBTITLE}</h2>
    </Element>
  );
}

export default ResolutionWarningMessage;
