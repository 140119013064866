import { User } from '@zspace/types';
import { Columns, Element } from 'react-bulma-components';
import { FaTrash } from 'react-icons/fa6';
import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';

export type DisableUserConfirmationModalProps = {
  show: boolean;
  user: User;
  loading: boolean;
  onCancel: () => void;
  onDisable: (id: string) => Promise<void>;
};

export function DisableUserConfirmationModal({
  show,
  user,
  loading,
  onCancel,
  onDisable,
}: DisableUserConfirmationModalProps) {
  return (
    <Modal show={show}>
      <h1 className="has-text-weight-light is-size-4">Disable user</h1>
      <Element renderAs="p" my={4}>
        Are you sure you want to disable
        <span className="has-text-weight-bold"> {user?.email}?</span>
      </Element>
      <Columns mt={4}>
        <Columns.Column>
          <Button color="primary-dark" outlined onClick={onCancel}>
            No, cancel
          </Button>
        </Columns.Column>
        <Columns.Column display="flex" justifyContent="flex-end">
          <Button
            color="danger-dark"
            onClick={async () => {
              await onDisable(user?.id);
            }}
            isExecutingAction={loading}
          >
            <Button.LoadingIcon icon={FaTrash} />
            <span>Yes, disable user</span>
          </Button>
        </Columns.Column>
      </Columns>
    </Modal>
  );
}

export default DisableUserConfirmationModal;
