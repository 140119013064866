import { faker } from '@faker-js/faker';
import { define } from 'cooky-cutter';
import { UserRoleAssignment } from '../types';
import { rolesFactory } from './roles';
import { userFactory } from './user';

export const userRoleAssignmentFactory = define<UserRoleAssignment>({
  id: () => faker.string.uuid(),
  role: () => rolesFactory(),
  user: () => userFactory(),
  createdAt: () => faker.date.recent(),
  active: false,
  permissionsGroupUserRoleAssignments: () => [],
});
