import { SVGProps } from 'react';
const Grid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#363636"
      d="M0 2.375C0 1.339.84.5 1.875.5h2.25C5.161.5 6 1.34 6 2.375v2.25C6 5.661 5.16 6.5 4.125 6.5h-2.25A1.874 1.874 0 0 1 0 4.625v-2.25Zm0 7.5C0 8.839.84 8 1.875 8h2.25C5.161 8 6 8.84 6 9.875v2.25C6 13.161 5.16 14 4.125 14h-2.25A1.875 1.875 0 0 1 0 12.125v-2.25Zm6 7.5v2.25c0 1.036-.84 1.875-1.875 1.875h-2.25A1.875 1.875 0 0 1 0 19.625v-2.25C0 16.339.84 15.5 1.875 15.5h2.25C5.161 15.5 6 16.34 6 17.375Zm1.5-15C7.5 1.339 8.34.5 9.375.5h2.25c1.036 0 1.875.84 1.875 1.875v2.25c0 1.036-.84 1.875-1.875 1.875h-2.25A1.874 1.874 0 0 1 7.5 4.625v-2.25Zm6 7.5v2.25c0 1.036-.84 1.875-1.875 1.875h-2.25A1.875 1.875 0 0 1 7.5 12.125v-2.25C7.5 8.839 8.34 8 9.375 8h2.25c1.036 0 1.875.84 1.875 1.875Zm-6 7.5c0-1.036.84-1.875 1.875-1.875h2.25c1.036 0 1.875.84 1.875 1.875v2.25c0 1.036-.84 1.875-1.875 1.875h-2.25A1.875 1.875 0 0 1 7.5 19.625v-2.25Zm13.5-15v2.25c0 1.036-.84 1.875-1.875 1.875h-2.25A1.875 1.875 0 0 1 15 4.625v-2.25C15 1.339 15.84.5 16.875.5h2.25C20.161.5 21 1.34 21 2.375Zm-6 7.5C15 8.839 15.84 8 16.875 8h2.25C20.161 8 21 8.84 21 9.875v2.25C21 13.161 20.16 14 19.125 14h-2.25A1.875 1.875 0 0 1 15 12.125v-2.25Zm6 7.5v2.25c0 1.036-.84 1.875-1.875 1.875h-2.25A1.875 1.875 0 0 1 15 19.625v-2.25c0-1.036.84-1.875 1.875-1.875h2.25c1.036 0 1.875.84 1.875 1.875Z"
    />
  </svg>
);
export default Grid;
