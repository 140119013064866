import { ReactNode } from 'react';
import Conditional from '../../shared/conditional/conditional';
import styles from './tooltip.module.scss';

type TooltipProps = {
  children: ReactNode;
  message?: ReactNode;
  backgroundColor?: string;
};

export function Tooltip({
  children,
  message,
  backgroundColor = '#333',
}: TooltipProps) {
  return (
    <Conditional condition={!!message}>
      <Conditional.True>
        <div className={styles.checkboxContainer}>
          {children}
          <span
            className={styles.tooltipContent}
            style={{ backgroundColor: backgroundColor }}
          >
            {message}
          </span>
        </div>
      </Conditional.True>
      <Conditional.False>{children}</Conditional.False>
    </Conditional>
  );
}
