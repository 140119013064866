import { SoftwareAssignmentReviewData } from '@zspace/types';
import { Columns, Element, Icon } from 'react-bulma-components';
import { FaArrowRight } from 'react-icons/fa6';
import ReviewSoftwareAssignmentCard from '../review-software-assignment-card/review-software-assignment-card';
import styles from './updated-license-keys.module.scss';

export type UpdatedLicenseKeysProps = {
  updatedAssignments: SoftwareAssignmentReviewData['updatedAssignments'];
};

export function UpdatedLicenseKeys({
  updatedAssignments,
}: UpdatedLicenseKeysProps) {
  return (
    <Element display="flex" flexDirection="column" className="gap-6">
      <h2 className="is-size-5 has-text-weight-light">Updated license keys</h2>
      {updatedAssignments.map(({ oldAssignment, newAssignment }) => (
        <Columns display="flex" alignItems="center" key={oldAssignment.id}>
          <Columns.Column>
            <ReviewSoftwareAssignmentCard assignment={oldAssignment} />
          </Columns.Column>
          <Icon className={styles.arrowRight}>
            <FaArrowRight />
          </Icon>
          <Columns.Column>
            <ReviewSoftwareAssignmentCard assignment={newAssignment} />
          </Columns.Column>
        </Columns>
      ))}
    </Element>
  );
}

export default UpdatedLicenseKeys;
