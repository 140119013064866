import { ReactNode } from 'react';
import { Box, Element } from 'react-bulma-components';
import If from '../../shared/if/if';
import styles from './details-card.module.scss';

export interface DetailsCardProps {
  title?: string;
  children: ReactNode;
}

export function DetailsCard({ title, children }: DetailsCardProps) {
  return (
    <Box className={styles.container}>
      <If condition={!!title}>
        <Element pb={5}>
          <span className="is-size-4 has-text-weight-light">{title}</span>
        </Element>
      </If>
      {children}
    </Box>
  );
}

export default DetailsCard;
