import { capitalizeString } from '@zspace/format';
import { DevicePermissions } from '@zspace/roles';
import {
  DeviceModel,
  DeviceRegistrationStatus,
  FilterType,
  HardwareModel,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  RegistrationFileData,
  RegistrationFileDevicesCriteria,
  RegistrationFileDevicesExpandableTableRow,
  RegistrationFileDevicesModalFilterDataType,
  RegistrationFileDevicesTableRow,
  SortDirection,
} from '@zspace/types';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import { FaFilter } from 'react-icons/fa6';
import {
  defer,
  LoaderFunction,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import BackButton from '../../shared/back-button/back-button';
import Conditional from '../../shared/conditional/conditional';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { OnTableChangeData } from '../../ui/table/types';
import { fetchDeviceModels } from '../devices-service';
import RegisteredDevicesFilterModal from '../filter-modals/registered-devices-filter-modal/registered-devices-filter-modal';
import RegistrationFileDevicesTable from '../registration-file-devices-table/registration-file-devices-table';
import { fetchRegistrationFile } from '../registration-files-service';

const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 50];

type RegistrationFileDetailsPageData = {
  deviceModels: DeviceModel[];
  registrationFile: RegistrationFileData;
};

type RegistrationFileDetailsPageResponse = [
  DeviceModel[],
  RegistrationFileData
];

const initialCriteria: RegistrationFileDevicesCriteria = {
  itemsPerPage: 50,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
  serialNumber: [],
  name: '',
  nameFilter: FilterType.CONTAINS,
  type: '',
  status: DeviceRegistrationStatus.ALL,
  registrationFileName: '',
  registrationFileNameFilter: FilterType.CONTAINS,
  registrationMethods: [],
  relatedRegisteredDevices: false,
};

export const loader: LoaderFunction = async ({ params }) => {
  const id = params.id;

  const deviceModels = fetchDeviceModels();

  const registrationFile = fetchRegistrationFile(id!, initialCriteria);

  const response = Promise.all([deviceModels, registrationFile]);

  return defer({ response });
};

export function RegistrationFileDetailsPageContent() {
  const asyncValues = useAsyncValue() as RegistrationFileDetailsPageResponse;
  const navigation = useNavigation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { executeHttpRequest, isLoading } = useHttpRequest();
  const params = useParams();
  const [filterCriteria, setFilterCriteria] =
    useState<RegistrationFileDevicesCriteria>(initialCriteria);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [showRelatedRegisteredDevices, setShowRelatedRegisteredDevices] =
    useState<boolean>(searchParams.get('relatedRegisteredDevices') === 'true');

  const response: RegistrationFileDetailsPageData = useMemo(
    () => ({
      deviceModels: asyncValues[0],
      registrationFile: asyncValues[1],
    }),
    [asyncValues]
  );

  const deviceTypeOptions = useMemo(
    () =>
      response.deviceModels.map((model) => ({
        label: model.displayName,
        value: model.displayName,
      })),
    [response.deviceModels]
  );

  const registrationFileData = useMemo(
    () => response.registrationFile,
    [response.registrationFile]
  );

  const [tableData, setTableData] = useState<RegistrationFileDevicesTableRow[]>(
    registrationFileData.devices.data
  );

  const [tableConfig, setTableConfig] = useState<PaginatedContentConfig>({
    ...filterCriteria,
    pages: registrationFileData.devices.pages,
  });

  const isDataLoading = useMemo(
    () => navigation.state === 'loading',
    [navigation.state]
  );

  const fetchRegistrationFileDevices = useCallback(
    (criteria: RegistrationFileDevicesCriteria) => {
      const registrationFileId = params.id;
      executeHttpRequest({
        asyncFunction: async () => {
          const response = await fetchRegistrationFile(
            registrationFileId!,
            criteria
          );
          setTableData(response.devices.data);
          setTableConfig({ ...criteria, pages: response.devices.pages });
        },
      });
    },
    [executeHttpRequest, params.id]
  );

  const handleOnTableChange = useCallback(
    (
      value: OnTableChangeData<
        RegistrationFileDevicesTableRow,
        RegistrationFileDevicesExpandableTableRow
      >
    ): void => {
      const newCriteria = {
        ...filterCriteria,
        ...value.config,
        sortBy: value.column?.key || filterCriteria.sortBy,
      };

      setFilterCriteria(newCriteria);
      fetchRegistrationFileDevices(newCriteria);
    },
    [fetchRegistrationFileDevices, filterCriteria]
  );

  const handleTableFilterSubmit = useCallback(
    (value: RegistrationFileDevicesModalFilterDataType) => {
      const newCriteria: RegistrationFileDevicesCriteria = {
        ...filterCriteria,
        ...value,
      };
      setFilterCriteria(newCriteria);
      fetchRegistrationFileDevices(newCriteria);
      setShowFilterModal(false);
    },
    [fetchRegistrationFileDevices, filterCriteria]
  );

  const handleShowRelatedRegisteredDevicesClick = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      const newCriteria = {
        ...filterCriteria,
        relatedRegisteredDevices: !showRelatedRegisteredDevices,
      };
      setFilterCriteria(newCriteria);
      fetchRegistrationFileDevices(newCriteria);
      setShowRelatedRegisteredDevices(!showRelatedRegisteredDevices);
      e.currentTarget.blur();
    },
    [fetchRegistrationFileDevices, filterCriteria, showRelatedRegisteredDevices]
  );

  const filterTagsData = useMemo(() => {
    return {
      serialNumber: {
        label: 'Serial number',
        value: filterCriteria.serialNumber.join(', '),
      },
      type: {
        label: 'Device type',
        value:
          filterCriteria.type !== HardwareModel.ALL
            ? capitalizeString(filterCriteria.type)
            : '',
      },
      status: {
        label: 'Status',
        value:
          filterCriteria.status !== DeviceRegistrationStatus.ALL
            ? capitalizeString(filterCriteria.status)
            : '',
      },
      registrationFileName: {
        label: 'Registration file used',
        value: filterCriteria.registrationFileName.length
          ? filterCriteria.registrationFileNameFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', filterCriteria.registrationFileName)
          : '',
      },
      name: {
        label: 'Device name',
        value: filterCriteria.name.length
          ? filterCriteria.nameFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', filterCriteria.name)
          : '',
      },
      registrationMethods: {
        label: 'Registration method',
        value: filterCriteria.registrationMethods.join(', '),
      },
    };
  }, [filterCriteria]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const emptyContent = useMemo(() => {
    const emptyContentText =
      appliedFilters.length > 0 || tableConfig.search !== ''
        ? 'There are no devices that match the selected criteria'
        : 'No devices have been registered yet';
    return (
      <Element display="flex" justifyContent="center" className="my-2">
        <span>{emptyContentText}</span>
      </Element>
    );
  }, [appliedFilters.length, tableConfig.search]);

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof RegistrationFileDevicesModalFilterDataType) => {
      const newCriteria: RegistrationFileDevicesCriteria = {
        ...filterCriteria,
      };

      newCriteria[filterDataKey] = initialCriteria[filterDataKey] as string &
        number &
        string[];
      handleTableFilterSubmit(newCriteria);
    },
    [filterCriteria, handleTableFilterSubmit]
  );

  const filterTagList = useMemo(() => {
    let appliedFiltersList = null;
    if (appliedFilters.length > 0) {
      appliedFiltersList = (
        <FilterTagList
          list={filterTagsData}
          onRemove={(item) => {
            onRemoveFilterTag(
              item as keyof RegistrationFileDevicesModalFilterDataType
            );
          }}
          title="Filters"
        />
      );
    }
    return appliedFiltersList;
  }, [appliedFilters.length, filterTagsData, onRemoveFilterTag]);

  const navigateToRegisteredDevices = useCallback(() => {
    navigate('/my-devices/device-registration/registration-files');
  }, [navigate]);

  if (isDataLoading) {
    return (
      <Element display="flex" justifyContent="center">
        <PageSpinner />
      </Element>
    );
  }

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Element className="p-4">
          <BackButton onClick={navigateToRegisteredDevices} />
          <Columns marginless>
            <Columns.Column className="pl-0">
              <h1 className="is-size-3 has-text-weight-light">
                {registrationFileData.name} details
              </h1>
            </Columns.Column>
          </Columns>

          <If condition={!!filterTagList}>
            <Element mt={3}>{filterTagList}</Element>
          </If>
        </Element>
      }
    >
      <Element display="flex" alignItems="center" mb={4}>
        <h2 className="is-size-6 has-text-weight-light">
          Here you can find a list of devices authorized to be registered using
          this file and their status
        </h2>
      </Element>

      <Element display="flex" alignItems="center" mb={4}>
        <Button
          type="button"
          color="primary-dark"
          outlined={!showRelatedRegisteredDevices}
          onClick={handleShowRelatedRegisteredDevicesClick}
        >
          <span>Only devices registered using this file</span>
        </Button>
      </Element>

      <Conditional condition={isLoading}>
        <Conditional.True>
          <PageSpinner />
        </Conditional.True>
        <Conditional.False>
          <RegistrationFileDevicesTable
            dataSource={tableData}
            config={tableConfig}
            onChange={handleOnTableChange}
            empty={emptyContent}
            form={{
              extra: (
                <Button
                  type="button"
                  color="primary-dark"
                  className="ml-2"
                  outlined={appliedFilters.length === 0}
                  onClick={() => setShowFilterModal(true)}
                >
                  <Icon>
                    <FaFilter />
                  </Icon>
                  <span>
                    Filter
                    {appliedFilters.length > 0 && `(${appliedFilters.length})`}
                  </span>
                </Button>
              ),
              itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
            }}
          />
        </Conditional.False>
      </Conditional>

      <RegisteredDevicesFilterModal
        show={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        onSubmit={handleTableFilterSubmit}
        data={filterCriteria}
        deviceTypeOptions={deviceTypeOptions}
      />
    </BoxLayout>
  );
}

export function RegistrationFileDetailsPage() {
  const { response } = useLoaderData() as {
    response: Promise<PaginatedAPIResponse<RegistrationFileDevicesTableRow>>;
  };

  return (
    <ProtectedPage permissions={DevicePermissions.REGISTERED_DEVICES_READ}>
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <RegistrationFileDetailsPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default RegistrationFileDetailsPage;
