import { SalesOrder } from '@zspace/types';
import PaginatedTable, {
  CustomPaginatedTableProps,
} from '../../ui/table/paginated-table/paginated-table';
import { Column } from '../../ui/table/types';
import { salesOrderSummaryColumns } from './constants';

export type SalesOrdersTableProps = CustomPaginatedTableProps<SalesOrder> & {
  columns?: Column<SalesOrder>[];
};

export function SalesOrdersTable({
  columns = salesOrderSummaryColumns,
  dataSource,
  onChange,
  config,
  form,
  empty,
  rowSelection,
  errorMessage,
  children,
}: SalesOrdersTableProps) {
  return (
    <PaginatedTable
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      config={config}
      form={form}
      empty={empty}
      rowKey={'id'}
      rowSelection={rowSelection}
      errorMessage={errorMessage}
      children={children}
    />
  );
}

export default SalesOrdersTable;
