import { ComponentProps } from 'react';
import { Modal as BulmaModal } from 'react-bulma-components';
import styles from './modal.module.scss';

export type ModalProps = ComponentProps<typeof BulmaModal>;

export function Modal({
  children,
  showClose = false,
  closeOnEsc = false,
  ...props
}: ModalProps) {
  return (
    <BulmaModal {...props} showClose={showClose} closeOnEsc={closeOnEsc}>
      <BulmaModal.Card>
        <BulmaModal.Card.Body p={9} className={styles.bodyContainer}>
          {children}
        </BulmaModal.Card.Body>
      </BulmaModal.Card>
    </BulmaModal>
  );
}

export default Modal;
