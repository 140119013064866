import { Element, Icon } from 'react-bulma-components';
import { FaCheck } from 'react-icons/fa6';
import If from '../if/if';
import styles from './task-tag.module.scss';

export type TaskTagProps = {
  taskContent: string;
  pending?: boolean;
};

export function TaskTag({ taskContent, pending }: TaskTagProps) {
  return (
    <Element
      display="flex"
      alignItems="center"
      backgroundColor={pending ? 'danger-light' : 'success-light'}
      className={styles.container}
    >
      <If condition={!pending}>
        <Icon color={'success-dark'}>
          <FaCheck />
        </Icon>
      </If>
      <span
        className={pending ? 'has-text-danger-dark' : 'has-text-success-dark'}
      >
        {taskContent}
      </span>
    </Element>
  );
}

export default TaskTag;
