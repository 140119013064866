import { faker } from '@faker-js/faker';
import { define } from 'cooky-cutter';
import { Item } from '../types';

export const itemFactory = define<Item>({
  id: faker.string.uuid(),
  name: faker.lorem.word(10),
  numberName: faker.string.alphanumeric({ length: 5 }),
  externalId: faker.number.int(),
});
