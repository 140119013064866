import { formatSalesOrderNumber } from '@zspace/format';
import { SalesOrder } from '@zspace/types';
import { useMemo } from 'react';
import { Element } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import Conditional from '../../shared/conditional/conditional';
import If from '../../shared/if/if';

export type SalesOrdersListProps = {
  list: Pick<SalesOrder, 'id' | 'number'>[];
  textColor?: 'primary' | 'primary-dark';
  link?: string;
  salesOrderLink?: string;
};

const MAX_ELEMENTS = 3;

export function SalesOrdersList({
  list,
  textColor = 'primary-dark',
  link,
  salesOrderLink,
}: SalesOrdersListProps) {
  const elementClassName = useMemo(() => {
    const textColorClassName =
      textColor === 'primary' ? 'has-text-primary' : 'has-text-primary-dark';
    return `${textColorClassName}`;
  }, [textColor]);

  const hasMore = useMemo(() => list.length > MAX_ELEMENTS, [list.length]);
  const hasMoreLink = useMemo(
    () => salesOrderLink ?? link,
    [link, salesOrderLink]
  );

  if (list.length === 0) {
    return null;
  }

  return (
    <Element display="inline-flex" alignItems="center">
      <span>
        {list.slice(0, MAX_ELEMENTS).map((so, index, { length }) => (
          <Link key={so.id} to={salesOrderLink || `/sales-orders/${so.id}`}>
            <span className={`${elementClassName} is-underlined`}>
              {formatSalesOrderNumber(so.number)}
            </span>
            <If condition={index < length - 1}>{', '}</If>
          </Link>
        ))}
      </span>
      <If condition={hasMore}>
        <Conditional condition={!!hasMoreLink}>
          <Conditional.True>
            <Link to={hasMoreLink!} className="ml-1">
              <span className={`${elementClassName} is-underlined`}>
                and {list.length - MAX_ELEMENTS} more
              </span>
            </Link>
          </Conditional.True>
          <Conditional.False>
            <span className={`${elementClassName} ml-1`}>
              and {list.length - MAX_ELEMENTS} more
            </span>
          </Conditional.False>
        </Conditional>
      </If>
    </Element>
  );
}

export default SalesOrdersList;
