import { Criteria } from '@zspace/types';
import { useCallback, useEffect, useState } from 'react';
import {
  LoaderFunction,
  defer,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import DeviceGroupSoftwareSelectionCard from '../../device-groups/device-group-software-selection-card/device-group-software-selection-card';
import { ManageSoftwareLayoutContext } from '../../software-assignment/manage-software-layout/manage-software-layout';
import Spinner from '../../ui/spinner/spinner';
import {
  fetchWorkOrderSoftwareBySalesOrder,
  fetchWorkOrderSoftwareSalesOrders,
} from '../work-orders-service';

const initialCriteria: Criteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
};

export const loader: LoaderFunction = async ({ params }) => {
  const workOrderId = params.id;
  const modelId = params.modelId;

  const response = fetchWorkOrderSoftwareSalesOrders(workOrderId!, {
    ...initialCriteria,
    modelId: modelId as string,
  });

  return defer({ response });
};

export function DeviceGroupSoftwareSelectionCardPage() {
  const { id: workOrderId, modelId } = useParams();
  const navigate = useNavigate();

  const { selectedDevices, setTitle } =
    useOutletContext<ManageSoftwareLayoutContext>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedDevices.length === 0) {
      setLoading(true);
      navigate(`/work-orders/${workOrderId}/device-groups`, { replace: true });
    }
  }, [navigate, selectedDevices.length, workOrderId]);

  const handleOnCancel = useCallback(() => {
    navigate(`/work-orders/${workOrderId}/device-groups`);
  }, [navigate, workOrderId]);

  const handleOnNext = useCallback(async () => {
    navigate(
      `/work-orders/${workOrderId}/device-groups/device-models/${modelId}/software/review`
    );
  }, [modelId, navigate, workOrderId]);

  const fetchSalesOrders = useCallback(
    (updatedCriteria: Criteria) =>
      fetchWorkOrderSoftwareSalesOrders(workOrderId!, {
        ...updatedCriteria,
        modelId: modelId as string,
      }),
    [modelId, workOrderId]
  );

  const fetchSoftwareBySalesOrder = useCallback(
    (salesOrderId: string, updatedCriteria: Criteria) =>
      fetchWorkOrderSoftwareBySalesOrder(workOrderId!, salesOrderId!, {
        ...updatedCriteria,
        modelId: modelId as string,
      }),
    [modelId, workOrderId]
  );

  useEffect(() => {
    setTitle('Assign software');
  }, [setTitle]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <DeviceGroupSoftwareSelectionCard
      onNext={handleOnNext}
      onCancel={handleOnCancel}
      fetchSalesOrders={fetchSalesOrders}
      fetchSoftwareBySalesOrder={fetchSoftwareBySalesOrder}
    />
  );
}

export default DeviceGroupSoftwareSelectionCardPage;
