import { faker } from '@faker-js/faker';
import { define } from 'cooky-cutter';
import { LicensingProductGroup, SoftwareData } from '../types';
import { licensingSystemFactory } from './licensing-systems';

export const licensingProductGroupFactory = define<LicensingProductGroup>({
  id: () => faker.string.uuid(),
  nameId: () => faker.lorem.word({ strategy: 'any-length' }),
  displayName: () => faker.lorem.word({ strategy: 'any-length' }),
  active: () => faker.datatype.boolean(),
  items: () => [],
  licensingSystem: licensingSystemFactory,
  applicationModeVariants: () => [],
  applicationSupplements: () => [],
});

export const licensingProductGroupDataFactory = define<SoftwareData>({
  id: () => faker.string.uuid(),
  displayName: () => faker.lorem.word({ strategy: 'any-length' }),
  seats: () => ({
    available: faker.number.int(),
    total: faker.number.int(),
  }),
  salesOrders: () => [],
});
