import { formatSalesOrderNumber } from '@zspace/format';
import { SalesOrderPermissions } from '@zspace/roles';
import { DeferredResponse, SalesOrderData } from '@zspace/types';
import { Suspense } from 'react';
import { Element } from 'react-bulma-components';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
} from 'react-router-dom';
import BackButton from '../../shared/back-button/back-button';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { fetchSalesOrder } from '../../work-orders/sales-orders-service';
import SalesOrderDetailsLayout from '../sales-order-details-layout/sales-order-details-layout';

export const loader: LoaderFunction = async ({ params }) => {
  const id = params.id;

  const response = fetchSalesOrder(id!);

  return defer({ response });
};

export function SalesOrderDetailsPageContent() {
  const salesOrder = useAsyncValue() as SalesOrderData;

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Element className="p-4">
          <BackButton />
          <h1 className="is-size-3 has-text-weight-light">
            {formatSalesOrderNumber(salesOrder.number)} details
          </h1>
          <If condition={!!salesOrder.opportunity}>
            <h2 className="is-size-5 has-text-weight-light">
              Opportunity - {salesOrder.opportunity?.name}
            </h2>
          </If>
        </Element>
      }
    >
      <SalesOrderDetailsLayout salesOrder={salesOrder} />
    </BoxLayout>
  );
}

export function SalesOrderDetailsPage() {
  const { response } = useLoaderData() as DeferredResponse<SalesOrderData>;

  return (
    <ProtectedPage
      permissions={SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ}
    >
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <SalesOrderDetailsPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default SalesOrderDetailsPage;
