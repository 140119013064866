import {
  BasicTableConfig,
  PaginatedContentConfig,
  SortDirection,
} from '@zspace/types';
import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { Table as BulmaTable, Element, Icon } from 'react-bulma-components';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa6';
import Conditional from '../../../shared/conditional/conditional';
import If from '../../../shared/if/if';
import Checkbox from '../../checkbox/checkbox';
import TableRow, { TableRowCheckboxTooltip } from '../table-row/table-row';
import { resolveRowKey } from '../table-row/utils';
import {
  Column,
  ColumnIconProps,
  ExpandableRow,
  OnTableChangeFunction,
  RowInteraction,
  RowSelection,
} from '../types';
import styles from './basic-table.module.scss';

export type BasicTableProps<T, K = unknown> = {
  dataSource: T[];
  columns: Column<T, K>[];
  rowKey: keyof T;
  config: BasicTableConfig;
  onChange?: OnTableChangeFunction<T, K>;
  rowSelection?: RowSelection;
  rowInteraction?: RowInteraction;
  errorMessage?: ReactNode;
  children?: ReactNode;
  isInvalidRowValue?: (row: T) => boolean;
  isInvalidExpandableRowValue?: (row: K) => boolean;
  isInvalidSelection?: (selectedElements: T[]) => boolean;
  isDisabledSelection?: (row: T) => boolean;
  checkboxTooltip?: (row: T) => TableRowCheckboxTooltip;
  expandableRow?: ExpandableRow<T, K>;
  bordered?: boolean;
};

export function BasicTable<T, K = unknown>({
  dataSource,
  columns,
  rowKey,
  config,
  onChange,
  rowSelection,
  rowInteraction,
  isInvalidRowValue,
  isInvalidExpandableRowValue,
  isInvalidSelection,
  isDisabledSelection,
  checkboxTooltip,
  expandableRow,
  bordered = true,
}: BasicTableProps<T, K>) {
  const headerCheckboxRef = useRef<HTMLInputElement>(null);

  const tableClassName = useMemo(() => {
    const defaultClassName = styles.table;
    return bordered
      ? `${defaultClassName} ${styles.tableBordered}`
      : defaultClassName;
  }, [bordered]);

  const allRowsChecked = useMemo(
    () => rowSelection?.selectedRowKeys.length === dataSource.length,
    [rowSelection?.selectedRowKeys.length, dataSource.length]
  );

  const someRowsChecked = useMemo(
    () => !allRowsChecked && (rowSelection?.selectedRowKeys ?? []).length > 0,
    [allRowsChecked, rowSelection?.selectedRowKeys]
  );

  const SortableColumnIcon = useCallback(
    ({ column }: ColumnIconProps<T, K>) => (
      <Icon>
        <Conditional condition={config?.sortBy === column.key}>
          <Conditional.True>
            <Conditional
              condition={config?.sortDirection === SortDirection.ASC}
            >
              <Conditional.True>
                <FaSortUp />
              </Conditional.True>
              <Conditional.False>
                <Conditional
                  condition={config?.sortDirection === SortDirection.DES}
                >
                  <Conditional.True>
                    <FaSortDown />
                  </Conditional.True>
                  <Conditional.False>
                    <FaSort />
                  </Conditional.False>
                </Conditional>
              </Conditional.False>
            </Conditional>
          </Conditional.True>
          <Conditional.False>
            <FaSort />
          </Conditional.False>
        </Conditional>
      </Icon>
    ),
    [config?.sortBy, config?.sortDirection]
  );

  function resolveColumnClassName(column: Column<T, K>): string | undefined {
    if (column.widthClassname) {
      return styles[column.widthClassname];
    }
  }

  function resolveRowClassName(
    row: T,
    checked: boolean = false
  ): string | undefined {
    if (isInvalidRowValue?.(row)) {
      return 'has-background-danger-light';
    }
    if (checked && rowSelection) {
      if (isInvalidSelection?.([row])) {
        return 'has-background-danger-light';
      } else {
        return 'has-background-primary-light';
      }
    }
    if (isDisabledSelection?.(row)) {
      return 'has-background-white-ter';
    }
  }

  function handleSortClick(column: Column<T, K>): void {
    let newSortDirection;
    if (column.key !== config.sortBy) {
      newSortDirection = SortDirection.ASC;
    } else if (config.sortDirection === SortDirection.ASC) {
      newSortDirection = SortDirection.DES;
    } else if (config.sortDirection === SortDirection.DES) {
      newSortDirection = undefined;
    } else {
      newSortDirection = SortDirection.ASC;
    }

    onChange?.({
      column,
      config: {
        ...config,
        sortDirection: newSortDirection,
      } as PaginatedContentConfig,
    });
  }

  function toggleAllRows(): void {
    if (rowSelection?.selectedRowKeys.length) {
      rowSelection?.onChange(rowSelection?.selectedRowKeys);
    } else {
      const allRows = dataSource
        .filter((el) => !isDisabledSelection?.(el))
        .map((el) => resolveRowKey(el, rowKey));
      rowSelection?.onChange(allRows);
    }
  }

  useEffect(() => {
    if (headerCheckboxRef.current) {
      (headerCheckboxRef.current as unknown as HTMLInputElement).indeterminate =
        someRowsChecked;
    }
  }, [rowSelection?.selectedRowKeys, allRowsChecked, someRowsChecked]);

  return (
    <BulmaTable role="table" size="fullwidth" className={tableClassName}>
      <thead className="has-background-primary-light">
        <tr>
          <If condition={!!expandableRow}>
            <th></th>
          </If>
          {columns.map((col, index) => (
            <th key={col.key} className={resolveColumnClassName(col)}>
              <div className="is-flex is-align-items-center">
                <If
                  condition={
                    !!rowSelection &&
                    !rowSelection.toggleAllDisabled &&
                    index === 0
                  }
                >
                  <Element className="mr-5">
                    <Checkbox
                      onChange={toggleAllRows}
                      checked={allRowsChecked}
                      indeterminate={someRowsChecked}
                    />
                  </Element>
                </If>
                <Conditional condition={!!col.sortable}>
                  <Conditional.True>
                    <span
                      className="is-flex is-clickable"
                      onClick={() => handleSortClick(col)}
                    >
                      {col.title}
                      <SortableColumnIcon column={col} />
                    </span>
                  </Conditional.True>
                  <Conditional.False>{col.title}</Conditional.False>
                </Conditional>
              </div>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {dataSource.map((el) => (
          <TableRow
            key={el[rowKey] as string}
            el={el}
            rowKey={rowKey}
            columns={columns}
            resolveRowClassName={resolveRowClassName}
            isInvalidExpandableRowValue={isInvalidExpandableRowValue}
            rowSelection={rowSelection}
            rowInteraction={rowInteraction}
            isDisabledSelection={isDisabledSelection}
            checkboxTooltip={checkboxTooltip}
            expandableRow={expandableRow}
          />
        ))}
      </tbody>
    </BulmaTable>
  );
}

export default BasicTable;
