import { faker } from '@faker-js/faker';
import { define } from 'cooky-cutter';
import { LicensingSystem } from '../types';

export const licensingSystemFactory = define<LicensingSystem>({
  id: () => faker.string.uuid(),
  nameId: () => faker.lorem.word({ strategy: 'any-length' }),
  displayName: () => faker.lorem.word({ strategy: 'any-length' }),
  requiresManualActivation: () => faker.datatype.boolean(),
  active: () => faker.datatype.boolean(),
});
