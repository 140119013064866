import { ReactNode } from 'react';
import { Box, Columns, Element } from 'react-bulma-components';
import { FaArrowRight } from 'react-icons/fa6';
import Conditional from '../../shared/conditional/conditional';
import styles from './summary-card.module.scss';

type StateChange = {
  current?: string;
  previous?: string;
};

export type DeviceSwapSummary = {
  serialNumber: string;
  workOrder: StateChange;
  salesOrder: StateChange;
  itemFulfillment: StateChange;
  assignedSoftware: StateChange;
};

type SummaryCardProps = DeviceSwapSummary;

const NoneConditionalWrapper = ({ children }: { children: ReactNode }) => (
  <Conditional condition={!!children}>
    <Conditional.True>{children}</Conditional.True>
    <Conditional.False>
      <span className="is-italic">None</span>
    </Conditional.False>
  </Conditional>
);

export function SummaryCard({
  serialNumber,
  workOrder,
  salesOrder,
  itemFulfillment,
  assignedSoftware,
}: SummaryCardProps) {
  return (
    <Box className={styles.container} paddingless>
      <Element p={4}>
        <span className="is-size-4 has-text-weight-light">{serialNumber}</span>

        <hr className="my-2" />

        <Columns marginless>
          <Columns.Column
            paddingless
            display="flex"
            className={`gap-3 ${styles.responsiveContainer}`}
            alignItems="center"
          >
            <span className="is-size-6">Work order:</span>
            <NoneConditionalWrapper>
              {workOrder.previous}
            </NoneConditionalWrapper>

            <FaArrowRight />

            <span className="is-size-6">Work order:</span>
            <NoneConditionalWrapper>{workOrder.current}</NoneConditionalWrapper>
          </Columns.Column>

          <Columns.Column
            paddingless
            display="flex"
            className={`gap-3 ${styles.responsiveContainer} ${styles.rightContainer}`}
            alignItems="center"
          >
            <span className="is-size-6">Sales order:</span>
            <NoneConditionalWrapper>
              {salesOrder.previous}
            </NoneConditionalWrapper>

            <FaArrowRight />

            <span className="is-size-6">Sales order:</span>
            <NoneConditionalWrapper>
              {salesOrder.current}
            </NoneConditionalWrapper>
          </Columns.Column>
        </Columns>

        <hr className="my-2" />

        <Element display="flex" className="gap-3" alignItems="center">
          <span className="is-size-6">Item fulfillment:</span>
          <NoneConditionalWrapper>
            {itemFulfillment.previous}
          </NoneConditionalWrapper>

          <FaArrowRight />

          <span className="is-size-6">Item fulfillment:</span>
          <NoneConditionalWrapper>
            {itemFulfillment.current}
          </NoneConditionalWrapper>
        </Element>
      </Element>

      <Element p={4} className={styles.background}>
        <span className="is-size-6">Assigned software:</span>
        <Element display="flex" className="gap-3" alignItems="center">
          <NoneConditionalWrapper>
            {assignedSoftware.previous}
          </NoneConditionalWrapper>

          <FaArrowRight />

          <NoneConditionalWrapper>
            {assignedSoftware.current}
          </NoneConditionalWrapper>
        </Element>
      </Element>
    </Box>
  );
}

export default SummaryCard;
