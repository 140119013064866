import { SVGProps } from 'react';
const Developers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#267ABF"
      d="M0 4.5c0-1.655 1.345-3 3-3h18c1.655 0 3 1.345 3 3v15c0 1.655-1.345 3-3 3H3c-1.655 0-3-1.345-3-3v-15Zm13.79 3.37a1.122 1.122 0 0 0 .085 1.59L16.692 12l-2.822 2.54a1.122 1.122 0 0 0-.084 1.59 1.122 1.122 0 0 0 1.589.084l3.75-3.375a1.125 1.125 0 0 0 0-1.669l-3.75-3.375a1.122 1.122 0 0 0-1.59.085l.006-.01Zm-3.66 1.59a1.122 1.122 0 0 0 .084-1.59 1.122 1.122 0 0 0-1.589-.084l-3.75 3.375a1.125 1.125 0 0 0 0 1.669l3.75 3.375c.464.417 1.172.38 1.59-.085a1.122 1.122 0 0 0-.085-1.589L7.308 12l2.822-2.54Z"
      opacity={0.4}
    />
    <path
      fill="#0A0A0A"
      d="M10.13 9.46a1.122 1.122 0 0 0 .084-1.59 1.122 1.122 0 0 0-1.589-.084l-3.75 3.375a1.125 1.125 0 0 0 0 1.669l3.75 3.375c.464.417 1.172.38 1.59-.085a1.122 1.122 0 0 0-.085-1.589L7.308 12l2.822-2.54Zm3.745 0L16.692 12l-2.822 2.54a1.122 1.122 0 0 0-.084 1.59 1.122 1.122 0 0 0 1.59.084l3.75-3.375a1.125 1.125 0 0 0 0-1.669l-3.75-3.375a1.122 1.122 0 0 0-1.59.085 1.122 1.122 0 0 0 .085 1.589l.004-.01Z"
    />
  </svg>
);
export default Developers;
