import { LogoutResponse } from '@zspace/types';
import { http } from './http';

export interface AuthService {
  logout: () => Promise<LogoutResponse>;
}

export class AuthServiceImpl implements AuthService {
  public async logout(): Promise<LogoutResponse> {
    const { data } = await http.post<LogoutResponse>('/v1/auth/logout');
    return data;
  }
}

export const logout = async (): Promise<LogoutResponse> => {
  const service = new AuthServiceImpl();

  return service.logout();
};
