import { isCustomerUser } from '@zspace/roles';
import useUser from './user';

export function useIsCustomerUser() {
  const { user: currentUser } = useUser();

  return isCustomerUser(currentUser);
}

export default useIsCustomerUser;
