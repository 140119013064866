import { Element, Icon } from 'react-bulma-components';
import { FaArrowRight } from 'react-icons/fa6';
import Button from '../../../ui/button/button';
import Modal from '../../../ui/modal/modal';

export type UserAlreadyExistsModalProps = {
  show: boolean;
  onEditPermissions: () => void;
  onCancel: () => void;
};

const TITLE = 'User already exists';
const SUBTITLE =
  'This email is in use and is associated with one or more of the sales orders you can manage. Would you like to edit the existing user’s permissions?';
const CANCEL_BUTTON_TEXT = 'Cancel';
const EDIT_PERMISSIONS_BUTTON_TEXT = 'Edit permissions';

export function UserAlreadyExistsModal({
  show,
  onEditPermissions,
  onCancel,
}: UserAlreadyExistsModalProps) {
  return (
    <Modal show={show}>
      <h1 className="has-text-weight-light is-size-4 mb-4">{TITLE}</h1>
      <p className="is-size-7">{SUBTITLE}</p>
      <Element
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={8}
      >
        <Button color="primary-dark" outlined onClick={onCancel}>
          {CANCEL_BUTTON_TEXT}
        </Button>
        <Button color="primary-dark" onClick={onEditPermissions}>
          <span>{EDIT_PERMISSIONS_BUTTON_TEXT}</span>
          <Icon>
            <FaArrowRight />
          </Icon>
        </Button>
      </Element>
    </Modal>
  );
}

export default UserAlreadyExistsModal;
