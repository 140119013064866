import { environment } from '../../../environments/environment';
import styles from './default-error-message.module.scss';

export function DefaultErrorMessage() {
  return (
    <span>
      Something went wrong. Please try again or{' '}
      <a
        href={`mailto:${environment.supportEmail}`}
        className={styles.contactLink}
      >
        contact us
      </a>{' '}
      if the error persists
    </span>
  );
}

export default DefaultErrorMessage;
