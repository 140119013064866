import {
  Criteria,
  MySoftwareCriteria,
  MySoftwareTableRow,
  PaginatedAPIResponse,
  SalesOrder,
  SoftwareFilter,
  SortDirection,
} from '@zspace/types';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaRegFolderOpen } from 'react-icons/fa6';
import useHttpRequest from '../../shared/hooks/http-request';
import InboxTable from '../../ui/inbox-table/inbox-table';
import { OnTableChangeData } from '../../ui/table/types';
import MySoftwareTable from '../my-software-table/my-software-table';
import SoftwareInbox from '../software-inbox/software-inbox';
import { fetchMySoftwareBySalesOrder as fetchMySoftwareBySalesOrderRequest } from '../software-service';

const FETCH_MY_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE =
  'The software could not be fetched. Please try again';

const initialCriteria: Criteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
};

export type SoftwareInboxTableProps = {
  salesOrders: SalesOrder[];
  fetchMoreSalesOrders: () => void;
  hasMoreSalesOrders: boolean;
  mySoftwareCriteriaFilter: MySoftwareCriteria;
  loadingInitialData?: boolean;
  leftContent?: ReactNode;
  appliedFilters?: object[];
};

export function SoftwareInboxTable({
  salesOrders,
  fetchMoreSalesOrders,
  hasMoreSalesOrders,
  mySoftwareCriteriaFilter,
  loadingInitialData,
  leftContent,
  appliedFilters,
}: SoftwareInboxTableProps) {
  const [selectedSalesOrderId, setSelectedSalesOrderId] = useState<string>();
  const [software, setSoftware] = useState<MySoftwareTableRow[]>([]);
  const [mySoftwareResponse, setMySoftwareResponse] =
    useState<PaginatedAPIResponse<MySoftwareTableRow>>();
  const [loadingInitialSoftware, setLoadingInitialSoftware] = useState(false);
  const [criteriaFilter, setCriteriaFilter] = useState<Criteria>({
    ...initialCriteria,
  });
  const { executeHttpRequest } = useHttpRequest();

  const fetchSoftwareBySalesOrder = useCallback(
    (salesOrderId: string, criteria: MySoftwareCriteria) =>
      executeHttpRequest({
        asyncFunction: async () => {
          const response = await fetchMySoftwareBySalesOrderRequest(
            salesOrderId,
            criteria
          );
          setMySoftwareResponse(response);
          setSoftware((prevSoftware) => [...prevSoftware, ...response.data]);
        },
        customErrorMessage: FETCH_MY_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE,
      }),
    [executeHttpRequest]
  );

  const fetchMoreSoftware = useCallback(async () => {
    const updatedCriteriaFilter = {
      ...criteriaFilter,
      pageNumber: criteriaFilter.pageNumber + 1,
    };

    if (selectedSalesOrderId) {
      await fetchSoftwareBySalesOrder(selectedSalesOrderId, {
        ...mySoftwareCriteriaFilter,
        ...updatedCriteriaFilter,
      });
      setCriteriaFilter(updatedCriteriaFilter);
    }
  }, [
    criteriaFilter,
    fetchSoftwareBySalesOrder,
    mySoftwareCriteriaFilter,
    selectedSalesOrderId,
  ]);

  const onSalesOrderSelect = useCallback(
    async (salesOrderId: string) => {
      const { itemsPerPage, pageNumber, sortBy, sortDirection } =
        initialCriteria;
      const updatedCriteria = {
        ...mySoftwareCriteriaFilter,
        itemsPerPage,
        pageNumber,
        sortBy,
        sortDirection,
      };
      setCriteriaFilter((prevCriteria) => ({
        ...initialCriteria,
        search: prevCriteria.search,
      }));
      setSoftware([]);
      setSelectedSalesOrderId(salesOrderId);
      setLoadingInitialSoftware(true);
      await fetchSoftwareBySalesOrder(salesOrderId, updatedCriteria);
      setLoadingInitialSoftware(false);
    },
    [fetchSoftwareBySalesOrder, mySoftwareCriteriaFilter]
  );

  const onTableChange = useCallback(
    async (value: OnTableChangeData<MySoftwareTableRow>) => {
      const updatedCriteria = {
        ...value.config,
        sortBy: value.column?.key ?? '',
      };
      const { pageNumber } = initialCriteria;
      setCriteriaFilter((prevCriteria) => ({
        ...prevCriteria,
        ...updatedCriteria,
        pageNumber,
      }));
      setLoadingInitialSoftware(true);
      setSoftware([]);
      await fetchSoftwareBySalesOrder(selectedSalesOrderId!, {
        ...mySoftwareCriteriaFilter,
        ...updatedCriteria,
      });
      setLoadingInitialSoftware(false);
    },
    [fetchSoftwareBySalesOrder, mySoftwareCriteriaFilter, selectedSalesOrderId]
  );

  const tableConfig = useMemo(() => {
    const { search, sortBy, sortDirection } = criteriaFilter;
    return {
      search,
      sortBy,
      sortDirection,
    };
  }, [criteriaFilter]);

  const inbox = useMemo(
    () => (
      <SoftwareInbox
        fetchMore={fetchMoreSalesOrders}
        hasMore={hasMoreSalesOrders}
        onClick={onSalesOrderSelect}
        salesOrders={salesOrders}
        selectedSalesOrderId={selectedSalesOrderId}
        loadingInitialData={loadingInitialData}
      />
    ),
    [
      fetchMoreSalesOrders,
      hasMoreSalesOrders,
      loadingInitialData,
      onSalesOrderSelect,
      salesOrders,
      selectedSalesOrderId,
    ]
  );

  const NoSalesOrderSelected = useCallback(
    () => (
      <Element
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="h-full gap-8"
      >
        <Icon className="folder-icon-big">
          <FaRegFolderOpen />
        </Icon>
        <Element textAlign="center">
          <h1 className="is-size-3 has-text-weight-light">
            Nothing to show here
          </h1>
          <h3 className="is-size-5 has-text-weight-light">
            Please select a sales order to display software
          </h3>
        </Element>
      </Element>
    ),
    []
  );

  const table = useMemo(() => {
    if (selectedSalesOrderId) {
      return (
        <MySoftwareTable
          dataSource={software}
          fetchMore={fetchMoreSoftware}
          hasMore={!!mySoftwareResponse?.hasMore}
          loadingInitialData={loadingInitialSoftware}
          config={tableConfig}
          onChange={onTableChange}
          includeSalesOrderColumn={selectedSalesOrderId === SoftwareFilter.ALL}
          softwareVariantType={mySoftwareCriteriaFilter.type}
        />
      );
    }
    return <NoSalesOrderSelected />;
  }, [
    NoSalesOrderSelected,
    fetchMoreSoftware,
    loadingInitialSoftware,
    mySoftwareCriteriaFilter.type,
    mySoftwareResponse?.hasMore,
    onTableChange,
    selectedSalesOrderId,
    software,
    tableConfig,
  ]);

  useEffect(() => {
    setSelectedSalesOrderId(undefined);
  }, [appliedFilters]);

  return (
    <InboxTable
      inbox={inbox}
      table={table}
      config={tableConfig}
      leftContent={leftContent}
      showSearchAndFilter={false}
    />
  );
}

export default SoftwareInboxTable;
