import {
  DeferredResponse,
  DeviceHomeAlertData,
  EulaOutstandingData,
  SoftwareHomeAlertData,
  UserRoleType,
} from '@zspace/types';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Columns } from 'react-bulma-components';
import {
  defer,
  LoaderFunction,
  useAsyncValue,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { fetchDevicesHomeAlertData } from '../../devices/devices-service';
import { fetchEulasOutstandingData } from '../../eula/eula-service';
import ActionsPendingModal from '../../shared/actions-pending-modal/actions-pending-modal';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import HomeCards from '../../shared/home-cards/home-cards';
import HomeHeader from '../../shared/home-header/home-header';
import useHttpRequest from '../../shared/hooks/http-request';
import useUser from '../../shared/hooks/user';
import useUserPendingTasks from '../../shared/hooks/user-pending-tasks';
import If from '../../shared/if/if';
import { fetchSoftwareSeatsHomeAlertData } from '../../software/software-service';
import BoxLayout from '../../ui/box-layout/box-layout';
import HomeButton from '../../ui/home-button/home-button';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { switchRole } from '../../users/users-service';
import SwitchToCustomerRoleModal from '../switch-to-customer-role-modal/switch-to-customer-role-modal';
import styles from './zspace-internal-home-page.module.scss';

const SWITCH_ROLE_ERROR_MESSAGE = 'Could not switch role. Please try again';

type LoaderFunctionData = [
  EulaOutstandingData,
  DeviceHomeAlertData,
  SoftwareHomeAlertData
];

export const loader: LoaderFunction = async () => {
  const eulasOutstandingResponse = fetchEulasOutstandingData();
  const devicesHomeAlertResponse = fetchDevicesHomeAlertData();
  const softwareSeatsHomeAlertResponse = fetchSoftwareSeatsHomeAlertData();

  return defer({
    response: Promise.all([
      eulasOutstandingResponse,
      devicesHomeAlertResponse,
      softwareSeatsHomeAlertResponse,
    ]),
  });
};

function ZspaceInternalHomePageContent() {
  const { user } = useUser();
  const navigate = useNavigate();
  const [salesOrdersAndEulaTasksData, devicesTasksData, softwareTasksData] =
    useAsyncValue() as LoaderFunctionData;
  const userTasks = useUserPendingTasks(
    salesOrdersAndEulaTasksData,
    devicesTasksData,
    softwareTasksData
  );
  const { executeHttpRequest, isLoading } = useHttpRequest();
  const [showSwitchRoleModal, setShowSwitchRoleModal] =
    useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [cardOnClickHandler, setCardOnClickHandler] = useState<() => void>(
    () => () => {}
  );
  const [selectedSection, setSelectedSection] = useState<string>('');

  const hasAlert = useMemo(() => {
    return !![
      ...userTasks.salesOrderAndEulaPendingTasks,
      ...userTasks.devicesPendingTasks,
      ...userTasks.softwarePendingTasks,
    ].length;
  }, [
    userTasks.salesOrderAndEulaPendingTasks,
    userTasks.devicesPendingTasks,
    userTasks.softwarePendingTasks,
  ]);

  const resolveCardsOnClick = useCallback(
    (handler: () => void, sectionName: string) => {
      return () => {
        if (hasAlert) {
          setShowModal(true);
          setCardOnClickHandler(() => handler);
          setSelectedSection(sectionName);
        } else {
          handler();
        }
      };
    },
    [hasAlert]
  );

  const navigateToMySalesOrders = useCallback(() => {
    navigate('/my-sales-orders');
  }, [navigate]);

  const navigateToMyTeam = useCallback(() => {
    navigate('/users/my-team');
  }, [navigate]);

  const navigateToMySoftware = useCallback(() => {
    navigate('/my-software');
  }, [navigate]);

  const navigateToMyDevices = useCallback(() => {
    navigate('/my-devices');
  }, [navigate]);

  const homeCards = useMemo(() => {
    return [
      {
        title: 'My Sales orders',
        description:
          'Review your sales order history, their contents and outstanding EULAs.',
        pendingTasks: userTasks.salesOrderAndEulaPendingTasks,
        onClick: navigateToMySalesOrders,
      },
      {
        title: 'My Team',
        description: 'View all the users related to your orders.',
        pendingTasks: [],
        onClick: navigateToMyTeam,
      },
      {
        title: 'My Devices',
        description:
          'View and rearrange all the devices in your organization and their software assignments.',
        pendingTasks: userTasks.devicesPendingTasks,
        onClick: navigateToMyDevices,
      },
      {
        title: 'My Software',
        description:
          'View all your current software titles and to which devices they have been assigned.',
        pendingTasks: userTasks.softwarePendingTasks,
        onClick: navigateToMySoftware,
      },
    ];
  }, [
    userTasks.salesOrderAndEulaPendingTasks,
    userTasks.devicesPendingTasks,
    userTasks.softwarePendingTasks,
    navigateToMySalesOrders,
    navigateToMyTeam,
    navigateToMyDevices,
    navigateToMySoftware,
  ]);

  const handleGetStartedButtonClick = useCallback(() => {
    const firstStepWithPendingActions = homeCards.find(
      (card) => card.pendingTasks.length > 0
    );
    firstStepWithPendingActions?.onClick();
  }, [homeCards]);

  const handleSwitchRole = useCallback(async () => {
    const userRoleToSwitch = user.roles.find(
      (roleAssignment) => roleAssignment.role.name === UserRoleType.CUSTOMER
    );
    return executeHttpRequest({
      asyncFunction: async () => {
        await switchRole(userRoleToSwitch!.id);
        window.location.replace('/');
      },
      customErrorMessage: SWITCH_ROLE_ERROR_MESSAGE,
    });
  }, [executeHttpRequest, user.roles]);

  return (
    <BoxLayout
      className={`is-min-height-80 mx-10 my-4 ${styles.layout}`}
      childrenClassName={styles.container}
    >
      <Columns
        display="flex"
        flexDirection="column"
        className="is-min-height-80"
      >
        <Columns.Column display="flex" flexDirection="column" p={4}>
          <HomeHeader
            children={
              <If condition={hasAlert}>
                <HomeButton onClick={handleGetStartedButtonClick} />
              </If>
            }
          />
        </Columns.Column>
        <Columns.Column>
          <HomeCards
            cards={homeCards}
            resolveCardsOnClick={resolveCardsOnClick}
          />
        </Columns.Column>
      </Columns>
      <SwitchToCustomerRoleModal
        show={showSwitchRoleModal}
        loading={isLoading}
        onClose={() => setShowSwitchRoleModal(false)}
        onSwitchRole={handleSwitchRole}
      />
      <ActionsPendingModal
        show={showModal}
        onSectionClick={cardOnClickHandler}
        selectedSection={selectedSection}
        onGetStartedClick={handleGetStartedButtonClick}
      />
    </BoxLayout>
  );
}

export function ZspaceInternalHomePage() {
  const { response } = useLoaderData() as DeferredResponse<LoaderFunctionData>;

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <ZspaceInternalHomePageContent />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default ZspaceInternalHomePage;
