import {
  capitalizeString,
  formatSalesOrderNumber,
  formatWorkOrderNumber,
} from '@zspace/format';
import { DeviceGroupPermissions, SoftwareSeatPermissions } from '@zspace/roles';
import { WorkOrderData, WorkOrderStatus } from '@zspace/types';
import { Link } from 'react-router-dom';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import { formatDateToLocaleString } from '../../shared/utils';
import PaginatedTable, {
  CustomPaginatedTableProps,
} from '../../ui/table/paginated-table/paginated-table';
import { Column } from '../../ui/table/types';
import ViewAllList from '../../ui/view-all-list/view-all-list';

export type WorkOrdersTableProps = CustomPaginatedTableProps<WorkOrderData>;

function getWorkOrderDetailsPath(workOrder: WorkOrderData) {
  if (workOrder.status === WorkOrderStatus.DRAFT) {
    return `/work-orders/${workOrder.id}/device-groups`;
  }

  return `/work-orders/${workOrder.id}`;
}

const columns: Column<WorkOrderData>[] = [
  {
    key: 'number',
    widthClassname: 'is-size-15',
    render: (el) => (
      <CheckPermissions
        permissions={[
          DeviceGroupPermissions.DEVICE_GROUPS_READ,
          SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
        ]}
      >
        <CheckPermissions.Render>
          <Link to={getWorkOrderDetailsPath(el)}>
            {formatWorkOrderNumber(el.number)}
          </Link>
        </CheckPermissions.Render>
        <CheckPermissions.Fallback>
          <span className="has-text-primary-dark">
            {formatWorkOrderNumber(el.number)}
          </span>
        </CheckPermissions.Fallback>
      </CheckPermissions>
    ),
    title: 'WO Number',
    sortable: true,
  },
  {
    key: 'name',
    widthClassname: 'is-size-15',
    render: (el) => (
      <CheckPermissions
        permissions={[
          DeviceGroupPermissions.DEVICE_GROUPS_READ,
          SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
        ]}
      >
        <CheckPermissions.Render>
          <Link to={getWorkOrderDetailsPath(el)}>{el.name}</Link>
        </CheckPermissions.Render>
        <CheckPermissions.Fallback>
          <span className="has-text-primary-dark">{el.name}</span>
        </CheckPermissions.Fallback>
      </CheckPermissions>
    ),
    title: 'WO Name',
    sortable: true,
  },
  {
    key: 'salesOrders.opportunity.account.name',
    widthClassname: 'is-size-15',
    render: (el) => (
      <ViewAllList
        list={el.salesOrders
          .filter((so) => so.opportunity)
          .map((so) => so.opportunity?.account)}
        horizontalList
        renderItem={(account) => (
          <Link to={`/accounts/${account?.id}`}>{account?.name}</Link>
        )}
        separator=", "
      />
    ),
    title: 'Accounts',
    sortable: true,
  },
  {
    key: 'salesOrders',
    widthClassname: 'is-size-10',
    render: (el) => (
      <ViewAllList
        list={el.salesOrders}
        renderItem={(el) => <p>{formatSalesOrderNumber(el.number)}</p>}
      />
    ),
    title: 'Sales orders',
    sortable: false,
  },
  {
    key: 'type',
    widthClassname: 'is-size-10',
    render: (el) => capitalizeString(el.type).replace('_', ' '),
    title: 'Type',
    sortable: false,
  },
  {
    key: 'devicesNumber',
    widthClassname: 'is-size-10',
    render: (el) => {
      return el.devices.total && el.devices.total > 0
        ? `${el.devices.unassigned}/${el.devices.total}`
        : 'N/A';
    },
    title: 'Available devices',
    sortable: false,
  },
  {
    key: 'complex',
    widthClassname: 'is-size-5',
    render: (el) => (el.complex ? 'Yes' : 'No'),
    title: 'Complex',
    sortable: false,
  },
  {
    key: 'createdBy',
    widthClassname: 'is-size-15',
    title: 'Created by',
    dataIndex: 'createdBy',
    sortable: false,
  },
  {
    key: 'createdAt',
    widthClassname: 'is-size-5',
    render: (el) => formatDateToLocaleString(el.createdAt),
    title: 'Date',
    sortable: true,
  },
];

export function WorkOrdersTable({
  dataSource,
  onChange,
  config,
  form,
  empty,
  children,
}: WorkOrdersTableProps) {
  return (
    <PaginatedTable
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      config={config}
      form={form}
      empty={empty}
      rowKey={'id'}
      children={children}
    />
  );
}

export default WorkOrdersTable;
