import { Element, Icon } from 'react-bulma-components';
import { FaArrowRight } from 'react-icons/fa6';
import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';

export type SoftwareAssignedConfirmationModalProps = {
  show: boolean;
  deviceList: string;
  onNavigateBackToHome: () => void;
  onKeepAssigningSoftware: () => void;
};

const TITLE = 'Software assigned';
const SUBTITLE = 'You have successfully assigned the software to';
const BACK_BUTTON_TEXT = 'Back to home';
const KEEP_ASSIGNING_SOFTWARE_BUTTON_TEXT = 'Keep assigning software';

export function SoftwareAssignedConfirmationModal({
  show,
  deviceList,
  onNavigateBackToHome,
  onKeepAssigningSoftware,
}: SoftwareAssignedConfirmationModalProps) {
  return (
    <Modal show={show}>
      <h1 className="has-text-weight-light is-size-4 mb-4">{TITLE}</h1>
      <p className="is-size-7">
        {SUBTITLE} <span className="has-text-weight-bold">{deviceList}</span>
      </p>
      <Element
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={8}
      >
        <Button color="primary-dark" outlined onClick={onNavigateBackToHome}>
          {BACK_BUTTON_TEXT}
        </Button>
        <Button color="primary-dark" onClick={onKeepAssigningSoftware}>
          <span>{KEEP_ASSIGNING_SOFTWARE_BUTTON_TEXT}</span>
          <Icon>
            <FaArrowRight />
          </Icon>
        </Button>
      </Element>
    </Modal>
  );
}

export default SoftwareAssignedConfirmationModal;
