import { TagInputType } from '@zspace/types';
import { Columns, Element } from 'react-bulma-components';
import TagListInput from '../../../../ui/tag-list-input/tag-list-input';
import { FilterModalInput } from '../../filter-table-modal-input/filter-table-modal-input';

export type TagInputProps<T> = FilterModalInput<T> & {
  input: TagInputType<T>;
};

export function TagInput<T>({
  input: { placeholder, valueKey, validateFn, errorText },
  name,
  localData,
  setLocalData,
  onValidateField,
}: TagInputProps<T>) {
  const handleValidate = (value: string) => {
    const isValidValue = value === '' || (validateFn?.(value) ?? true);
    onValidateField?.(name, isValidValue);
    return isValidValue;
  };

  return (
    <Element>
      <Columns marginless display="flex">
        <Columns.Column paddingless>
          <TagListInput
            id={name}
            placeholder={placeholder}
            validate={handleValidate}
            errorText={errorText}
            elements={localData[valueKey] as string[]}
            keys={['Enter', ' ', ',', '.']}
            onChange={(elements) =>
              setLocalData({
                ...localData,
                [valueKey]: elements as string[],
              })
            }
          />
        </Columns.Column>
      </Columns>
    </Element>
  );
}

export default TagInput;
