import { formatSalesOrderNumber } from '@zspace/format';
import { BasicSalesOrderData, SalesOrderData } from '@zspace/types';
import { useCallback, useEffect, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import Conditional from '../../shared/conditional/conditional';
import useHttpRequest from '../../shared/hooks/http-request';
import If from '../../shared/if/if';
import TaskTag from '../../shared/task-tag/task-tag';
import AnimatedChevronIcon from '../../ui/animated-chevron-icon/animated-chevron-icon';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { fetchSalesOrder } from '../../work-orders/sales-orders-service';
import SalesOrderDetailsLayout from '../sales-order-details-layout/sales-order-details-layout';
import styles from './sales-order-summary-list-item.module.scss';

export interface SalesOrderSummaryListItemProps {
  data: BasicSalesOrderData;
  collapsed?: boolean;
}

export function SalesOrderSummaryListItem({
  data,
  collapsed = true,
}: SalesOrderSummaryListItemProps) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const [detailsLoaded, setDetailsLoaded] = useState(false);
  const [salesOrderDetails, setSalesOrderDetails] = useState<SalesOrderData>();
  const { executeHttpRequest } = useHttpRequest();

  const fetchSalesOrderData = useCallback(async () => {
    executeHttpRequest({
      asyncFunction: async () => {
        const salesOrder = await fetchSalesOrder(data.id);
        setSalesOrderDetails(salesOrder);
        setDetailsLoaded(true);
      },
    });
  }, [data.id, executeHttpRequest]);

  const handleCollapse = useCallback(() => {
    if (isCollapsed) {
      fetchSalesOrderData();
    } else {
      setDetailsLoaded(false);
    }
    setIsCollapsed(!isCollapsed);
  }, [fetchSalesOrderData, isCollapsed]);

  useEffect(() => {
    if (!collapsed) {
      fetchSalesOrderData();
    }
  }, [collapsed, fetchSalesOrderData]);

  return (
    <Element>
      <Columns
        multiline={false}
        className={styles.salesOrderItem}
        onClick={handleCollapse}
      >
        <Columns.Column
          display="flex"
          alignItems="center"
          className="gap-7"
          size={11}
        >
          <Element display="flex" alignItems="center" className="gap-6">
            <Element display="flex" alignItems="center" className="gap-2">
              <Element className="is-clickable">
                <Icon color="primary-dark">
                  <AnimatedChevronIcon active={!isCollapsed} />
                </Icon>
              </Element>
              <span className="is-size-4 has-text-weight-light has-text-primary-dark">
                {formatSalesOrderNumber(data.number)}
              </span>
            </Element>
            <span className="is-size-6">
              {data.devicesCount}{' '}
              {data.devicesCount === 1 ? 'device' : 'devices'}
              {' - '}
              {data.licensingProductGroupsCount}{' '}
              {data.licensingProductGroupsCount === 1
                ? 'software title'
                : 'software titles'}
            </span>
            <If condition={data.eulaPending}>
              <TaskTag taskContent="EULA acceptance pending" pending />
            </If>
          </Element>
        </Columns.Column>
      </Columns>

      <If condition={!isCollapsed}>
        <Conditional condition={detailsLoaded}>
          <Conditional.True>
            <SalesOrderDetailsLayout salesOrder={salesOrderDetails!} />
          </Conditional.True>
          <Conditional.False>
            <PageSpinner />
          </Conditional.False>
        </Conditional>
      </If>

      <hr className="my-4 has-background-grey-lighter" />
    </Element>
  );
}

export default SalesOrderSummaryListItem;
