import { SVGProps } from 'react';
const ManagementHubIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      fill="#002C5C"
      d="M10.48 17.3c0-.57.22-1.1.61-1.5.28-.28.62-.47 1-.56H7.96c-.11 0-.17-.12-.11-.21.55-.78 6.9-8.36 7.24-8.75.09-.1.14-.22.14-.35V3.65c0-.29-.24-.53-.53-.53H3.16c-.29 0-.53.24-.53.53v2.68c0 .29.24.53.53.53H9.1c.11 0 .17.13.1.22-.49.59-6.45 8.44-6.86 9.12-.05.08-.07.17-.07.27v1.98c0 .29.24.53.53.53h8.53c-.51-.39-.84-.99-.85-1.67v-.01Z"
    />
    <path
      fill="#2982CC"
      d="M16.51 13.06c0 .3-.11.57-.3.77l.48 1.01a2.558 2.558 0 0 1 2.43.61l2.67-2.34c-.02-.09-.03-.18-.03-.28 0-.62.5-1.12 1.12-1.13.62-.01 1.12.5 1.13 1.12.01.62-.5 1.12-1.12 1.13-.22 0-.42-.06-.59-.17l-2.67 2.34c.17.34.27.72.27 1.13 0 .32-.06.64-.17.92l1.18.7c.19-.14.42-.23.67-.23.62 0 1.12.5 1.13 1.12.01.62-.5 1.12-1.12 1.13-.62.01-1.12-.5-1.13-1.12 0-.06 0-.12.01-.17l-1.19-.7c-.46.54-1.14.89-1.91.89-1.25 0-2.3-.9-2.5-2.09h-1.24c-.16.42-.57.71-1.04.71-.62 0-1.12-.5-1.13-1.12-.01-.62.5-1.12 1.12-1.13.47 0 .88.28 1.04.7h1.24c.11-.69.5-1.29 1.05-1.67l-.48-1.01h-.06c-.62 0-1.12-.5-1.13-1.12-.01-.62.5-1.12 1.12-1.13.62-.01 1.12.5 1.13 1.12l.02.01Zm.87 5.88a1.686 1.686 0 1 0-.012-3.372 1.686 1.686 0 0 0 .012 3.372Z"
    />
  </svg>
);
export default ManagementHubIcon;
