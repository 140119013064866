import { GroupCode } from '@zspace/types';
import { useCallback, useMemo } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaCopy, FaKey } from 'react-icons/fa6';
import useToast from '../../shared/hooks/toasts';
import Button from '../../ui/button/button';

export type DeviceGroupCodeProps = {
  groupCode: GroupCode;
  onCopy?: () => void;
};
const GROUP_CODE_LABEL = 'Group code: ';
const DEVICE_GROUP_CODE_COPIED_TO_CLIPBOARD_MESSAGE =
  'Group code copied to clipboard';

export function DeviceGroupCode({ groupCode, onCopy }: DeviceGroupCodeProps) {
  const toast = useToast();
  const groupCodeText = useMemo(() => {
    const firstPart = groupCode.code.slice(0, 3);
    const secondPart = groupCode.code.slice(3);

    return `${firstPart} ${secondPart}`;
  }, [groupCode]);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      await navigator.clipboard.writeText(groupCode.code);

      toast.success(DEVICE_GROUP_CODE_COPIED_TO_CLIPBOARD_MESSAGE);

      onCopy?.();
    },
    [onCopy, groupCode.code, toast]
  );

  return (
    <Element display="flex" alignItems="center">
      <Icon color="primary" justifyContent="flex-start">
        <FaKey />
      </Icon>

      {GROUP_CODE_LABEL}

      <Element renderAs="span" ml={1} className="has-text-primary">
        {groupCodeText}
      </Element>

      <Button
        onClick={handleClick}
        text
        className="is-borderless is-transparent"
      >
        <Icon
          color="primary"
          className="is-clickable"
          justifyContent="flex-end"
        >
          <FaCopy />
        </Icon>
      </Button>
    </Element>
  );
}

export default DeviceGroupCode;
