import { WorkOrder } from '@zspace/types';
import { Columns, Element } from 'react-bulma-components';
import { FaTrash } from 'react-icons/fa6';
import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';

export type WorkOrderConfirmDeletionModalProps = {
  show: boolean;
  workOrder: WorkOrder;
  loading: boolean;
  onCancel: () => void;
  onDelete: () => void;
};

export function WorkOrderConfirmDeletionModal({
  show,
  workOrder,
  loading,
  onCancel,
  onDelete,
}: WorkOrderConfirmDeletionModalProps) {
  return (
    <Modal show={show}>
      <h1 className="is-size-4">Delete work order</h1>
      <Element renderAs="p" my={4}>
        Are you sure you want to delete
        <span className="has-text-weight-bold">
          {` #${workOrder.number} - ${workOrder.name}?`}
        </span>
      </Element>
      <Columns mt={4}>
        <Columns.Column>
          <Button color="primary-dark" outlined onClick={onCancel}>
            No, cancel
          </Button>
        </Columns.Column>
        <Columns.Column display="flex" justifyContent="flex-end">
          <Button
            color="danger-dark"
            onClick={onDelete}
            isExecutingAction={loading}
          >
            <Button.LoadingIcon icon={FaTrash} />
            <span>Yes, delete work order</span>
          </Button>
        </Columns.Column>
      </Columns>
    </Modal>
  );
}

export default WorkOrderConfirmDeletionModal;
