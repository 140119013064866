import { AxiosError, HttpStatusCode } from 'axios';
import { useCallback, useState } from 'react';
import useError from './error';
import useToast from './toasts';

const FORBIDDEN_ERROR_MESSAGE =
  'You do not have permission to perform this action';
const UNAUTHORIZED_ERROR_MESSAGE =
  'Your session has expired. Please log in again';
const DEFAULT_ERROR_MESSAGE =
  'An error occurred while processing your request. Please try again';

type AsyncFunction<T> = () => Promise<T>;
type ErrorHandler<T> = (error: AxiosError<T>) => void;

type ExecuteHttpRequestOptions<T, U> = {
  asyncFunction: AsyncFunction<T>;
  customErrorMessage?: string;
  customErrorHandler?: ErrorHandler<U>;
};

export function useHttpRequest() {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { setError } = useError();

  const executeHttpRequest = useCallback(
    async <T, U>({
      asyncFunction,
      customErrorHandler,
      customErrorMessage,
    }: ExecuteHttpRequestOptions<T, U>) => {
      try {
        setIsLoading(true);
        return await asyncFunction();
      } catch (e) {
        if (e instanceof AxiosError) {
          switch (e.response?.status) {
            case HttpStatusCode.Forbidden:
              toast.error(FORBIDDEN_ERROR_MESSAGE);
              break;
            case HttpStatusCode.Unauthorized:
              toast.error(UNAUTHORIZED_ERROR_MESSAGE);
              setError({
                status: HttpStatusCode.Unauthorized,
                data: e.response?.data,
              });
              break;
            default:
              if (customErrorHandler) {
                customErrorHandler(e);
              } else {
                toast.error(customErrorMessage ?? DEFAULT_ERROR_MESSAGE);
              }
          }
        }
      } finally {
        setIsLoading(false);
      }
    },
    [setError, toast]
  );

  return { isLoading, executeHttpRequest };
}

export default useHttpRequest;
