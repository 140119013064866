import { faker } from '@faker-js/faker';
import { PartialBy } from '@zspace/types';
import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  FaCircleCheck,
  FaCircleInfo,
  FaTriangleExclamation,
} from 'react-icons/fa6';
import Toast, { ToastProps } from '../../ui/toast/toast';
import ToastsList from '../../ui/toast/toasts-list/toasts-list';

export type ToastsContextProps = {
  success: (message: string) => void;
  warning: (message: string) => void;
  info: (message: string) => void;
  error: (message: string) => void;
  remove: (id: string) => void;
};

export const ToastsContext = createContext<ToastsContextProps>({
  success: () => {},
  warning: () => {},
  info: () => {},
  error: () => {},
  remove: () => {},
});

type Toast = ToastProps & {
  id: string;
};

type ToastsProviderProps = {
  children: ReactNode;
};

export const ToastsProvider = ({ children }: ToastsProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback((toast: PartialBy<Toast, 'id'>) => {
    setToasts((currentToasts) => {
      const newToastId = faker.string.uuid();
      const newToast: Toast = {
        ...toast,
        id: newToastId,
      };
      return [...currentToasts, newToast];
    });
  }, []);

  const success = useCallback(
    (message: string) => {
      addToast({ message, color: 'success-dark', icon: <FaCircleCheck /> });
    },
    [addToast]
  );

  const warning = useCallback(
    (message: string) => {
      addToast({
        message,
        color: 'warning-dark',
        icon: <FaTriangleExclamation />,
      });
    },
    [addToast]
  );

  const info = useCallback(
    (message: string) => {
      addToast({ message, color: 'info-dark', icon: <FaCircleInfo /> });
    },
    [addToast]
  );

  const error = useCallback(
    (message: string) => {
      addToast({
        message,
        color: 'danger-dark',
        icon: <FaTriangleExclamation />,
      });
    },
    [addToast]
  );

  const removeToast = useCallback((id: string) => {
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );
  }, []);

  const contextValue = useMemo(
    () => ({ success, warning, info, error, remove: removeToast }),
    [error, info, removeToast, success, warning]
  );

  return (
    <ToastsContext.Provider value={contextValue}>
      {children}
      <ToastsList toasts={toasts} />
    </ToastsContext.Provider>
  );
};
