import { useCallback } from 'react';
import Conditional from '../../../shared/conditional/conditional';
import { Column } from '../types';

export type TableRowExpandableContentProps<T, K = unknown> = {
  columns: Column<T, K>[];
  item: K;
  rowClassName: string;
  parentElement: T;
};

export function TableRowExpandableContent<T, K = unknown>({
  columns,
  item,
  rowClassName,
  parentElement,
}: TableRowExpandableContentProps<T, K>) {
  const expandableContentRows = Array.from({ length: columns.length });

  const EmptyCell = useCallback(() => {
    return <td />;
  }, []);

  return (
    <tr className={rowClassName}>
      <EmptyCell />
      {expandableContentRows.map((_, index) => (
        <Conditional
          condition={!!columns[index].expandableContentRender}
          key={`${JSON.stringify(item)}-expandable-column-${index}`}
        >
          <Conditional.True>
            <td>
              <span>
                {columns[index].expandableContentRender?.(item, parentElement)}
              </span>
            </td>
          </Conditional.True>
          <Conditional.False>
            <EmptyCell />
          </Conditional.False>
        </Conditional>
      ))}
    </tr>
  );
}

export default TableRowExpandableContent;
