import { Navigate } from 'react-router-dom';
import { RouteObject } from '../app';
import DeviceGroupSoftwareSelectionCardPage, {
  loader as deviceGroupSoftwareSelectionLoader,
} from './device-group-software-selection-card-page/device-group-software-selection-card-page';
import ManageSoftwareLayout from './manage-software-layout/manage-software-layout';
import ReviewSoftwareAssignmentPage from './review-software-assignment/review-software-assignment-page/review-software-assignment-page';

export const routes: RouteObject[] = [
  {
    path: 'device-models/:modelId',
    element: <ManageSoftwareLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={'software'} replace />,
      },
      {
        path: 'software',
        children: [
          {
            index: true,
            element: <Navigate to={'select'} replace />,
          },
          {
            loader: deviceGroupSoftwareSelectionLoader,
            path: 'select',
            element: <DeviceGroupSoftwareSelectionCardPage />,
          },
          {
            path: 'review',
            element: <ReviewSoftwareAssignmentPage />,
          },
        ],
      },
    ],
  },
];
