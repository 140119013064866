import { ComponentProps } from 'react';
import { Icon } from 'react-bulma-components';
import { FaSpinner } from 'react-icons/fa6';
import styles from './spinner.module.scss';

type SpinnerProps = ComponentProps<typeof Icon>;

export function Spinner(props: SpinnerProps) {
  return (
    <Icon {...props} className={`animate-spin ${styles.spinner}`}>
      <FaSpinner className={styles.spinner} />
    </Icon>
  );
}

export default Spinner;
