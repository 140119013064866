import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Element, Icon, Notification } from 'react-bulma-components';
import { Color, ColorProps } from 'react-bulma-components/src/components';
import { FaX } from 'react-icons/fa6';
import useToast from '../../shared/hooks/toasts';
import If from '../../shared/if/if';
import Button from '../button/button';
import styles from './toast.module.scss';

export type ToastProps = {
  id: string;
  color?: Color;
  colorVariant?: ColorProps['colorVariant'];
  icon?: ReactNode;
  message: string;
};

export function Toast({
  id,
  color = 'info',
  colorVariant,
  icon,
  message,
}: ToastProps) {
  const toast = useToast();
  const [dismissed, setDismissed] = useState(false);

  const className = useMemo(() => {
    const toastStyle = styles.toast;

    if (dismissed) {
      return `${toastStyle} ${styles.toastDismissed}`;
    }

    return toastStyle;
  }, [dismissed]);

  const handleOnClose = useCallback(() => {
    setDismissed(true);
    setTimeout(() => {
      toast.remove(id);
    }, 400);
  }, [id, toast]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleOnClose();
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [handleOnClose, id, toast]);

  return (
    <Notification
      color={color}
      colorVariant={colorVariant}
      className={className}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={4}
      py={3}
    >
      <Element display="flex" className="gap-3">
        <If condition={!!icon}>
          <Icon size="small">{icon}</Icon>
        </If>
        <span className="is-size-7">{message}</span>
      </Element>
      <Button
        size="small"
        color={color}
        colorVariant={colorVariant}
        onClick={handleOnClose}
      >
        <Icon>
          <FaX />
        </Icon>
      </Button>
    </Notification>
  );
}

export default Toast;
