import { ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS } from '@zspace/roles';
import { UsersTableFilterDataType } from '@zspace/types';
import { useMemo } from 'react';
import FilterTableModal from '../../../shared/filter-table-modal/filter-table-modal';
import { CUSTOMER_FILTER_MODAL_FIELDS } from '../constants';

export type UsersTableFilterModalProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: (value: UsersTableFilterDataType) => void;
  data: UsersTableFilterDataType;
};

export function UsersTableFilterModal({
  show,
  data,
  onClose,
  onSubmit,
}: UsersTableFilterModalProps) {
  const customerUsersPermissionsGroupOptions = useMemo(() => {
    const permissionsGroups = [{ label: 'All', value: '' }];
    permissionsGroups.push(
      ...ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS.map((role) => ({
        label: role,
        value: role,
      }))
    );
    return permissionsGroups;
  }, []);

  const customerUsersFilterFields = useMemo(
    () => CUSTOMER_FILTER_MODAL_FIELDS(customerUsersPermissionsGroupOptions),
    [customerUsersPermissionsGroupOptions]
  );

  return (
    <FilterTableModal
      show={show}
      data={data}
      onClose={onClose}
      onSubmit={onSubmit}
      fields={customerUsersFilterFields}
    />
  );
}

export default UsersTableFilterModal;
