import { ReactNode } from 'react';
import { Columns, Element } from 'react-bulma-components';
import If from '../../shared/if/if';
import styles from './step-tab.module.scss';

export type StepTabProps = {
  title: string;
  onClick: () => void;
  informationTag?: ReactNode;
  active?: boolean;
};

export function StepTab({
  title,
  onClick,
  informationTag,
  active,
}: StepTabProps) {
  return (
    <Element
      className={`is-clickable p-2 ${styles.container} ${
        active ? `${styles.active}` : ''
      }`}
      onClick={onClick}
    >
      <Columns display="flex" className={styles.content} px={4}>
        <Columns.Column
          className={`${styles.stepLabel} ${
            !informationTag ? `${styles.taglessStep}` : ''
          }`}
        >
          <span className="has-text-primary-dark has-text-weight-light is-size-4">
            {title}
          </span>
        </Columns.Column>
        <If condition={!!informationTag}>
          <Columns.Column display="flex">{informationTag}</Columns.Column>
        </If>
      </Columns>
    </Element>
  );
}

export default StepTab;
