import { ReactNode } from 'react';
import { environment } from '../../../environments/environment';
import If from '../if/if';

export type DevOnlyProps = {
  children: ReactNode;
};

export function DevOnly({ children }: DevOnlyProps) {
  const { isDev } = environment;

  return <If condition={isDev}>{children}</If>;
}

export default DevOnly;
