import {
  FilterTableModalField,
  FilterTableModalFieldAlignment,
} from '@zspace/types';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Columns, Icon, Modal } from 'react-bulma-components';
import { FaCheck } from 'react-icons/fa6';
import Button from '../../ui/button/button';
import FilterTableModalInput from './filter-table-modal-input/filter-table-modal-input';
import styles from './filter-table-modal.module.scss';

type FilterModalChildrenProps<T> = {
  localData: T;
  setLocalData: Dispatch<SetStateAction<T>>;
};

export type FilterTableModalProps<T> = {
  show: boolean;
  fields: FilterTableModalField<T>[];
  data: T;
  onClose: () => void;
  onSubmit: (values: T) => void;
  children?: (params: FilterModalChildrenProps<T>) => React.ReactNode;
};

export function FilterTableModal<T>({
  show,
  data,
  fields,
  onClose,
  onSubmit,
  children,
}: FilterTableModalProps<T>) {
  const [localData, setLocalData] = useState<T>(data);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const [validFields, setValidFields] = useState<Record<string, boolean>>({});

  const isValidFilterValue = useMemo(() => {
    return Object.values(validFields).every((isValid) => isValid);
  }, [validFields]);

  const leftInputs = useMemo(
    () =>
      fields.filter(
        (field) => field.alignment === FilterTableModalFieldAlignment.LEFT
      ),
    [fields]
  );

  const rightInputs = useMemo(
    () =>
      fields.filter(
        (field) => field.alignment === FilterTableModalFieldAlignment.RIGHT
      ),
    [fields]
  );

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      if (isValidFilterValue) {
        onSubmit(localData);
        onClose();
      }
    }, [localData, isValidFilterValue, onClose, onSubmit]);

  const onValidateField = useCallback((fieldName: string, valid: boolean) => {
    setValidFields((prev) => ({ ...prev, [fieldName]: valid }));
  }, []);

  const handleOnClose = useCallback(() => {
    onClose();
    setLocalData({ ...localData, ...data });
  }, [data, localData, onClose]);

  return (
    <Modal show={show} closeOnBlur closeOnEsc showClose onClose={handleOnClose}>
      <Modal.Card className={`${styles.modalCard} mx-4`}>
        <Modal.Card.Body>
          <div className="p-5">
            <p className="is-size-4 mb-4 has-text-weight-light">Filter</p>
            <form>
              <Columns desktop={{ gap: 8 }} multiline>
                {/* LEFT COLUMNS */}
                <Columns.Column>
                  {leftInputs.map((field, index) => (
                    <FilterTableModalInput
                      key={`${field.name}-${index}`}
                      name={field.name}
                      label={field.label}
                      input={field.input}
                      localData={localData}
                      setLocalData={setLocalData}
                      onValidateField={onValidateField}
                    />
                  ))}
                </Columns.Column>

                {/* RIGHT COLUMNS */}
                <Columns.Column>
                  {rightInputs.map((field, index) => (
                    <FilterTableModalInput
                      key={`${field.name}-${index}`}
                      name={field.name}
                      label={field.label}
                      input={field.input}
                      localData={localData}
                      setLocalData={setLocalData}
                      onValidateField={onValidateField}
                    />
                  ))}
                </Columns.Column>
              </Columns>
            </form>
            {children?.({ localData, setLocalData })}
            <div className="is-flex is-justify-content-space-between mt-6">
              <Button
                color="primary-dark"
                outlined
                onClick={handleOnClose}
                type="button"
              >
                Cancel
              </Button>
              <Button color="primary-dark" onClick={handleSubmit}>
                <Icon>
                  <FaCheck />
                </Icon>
                <span>Apply</span>
              </Button>
            </div>
          </div>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
}

export default FilterTableModal;
