import { faker } from '@faker-js/faker';
import { define } from 'cooky-cutter';
import { SalesOrderLine } from '../types';
import { itemFulfillmentFactory } from './item-fulfillments';
import { itemFactory } from './items';
import { salesOrderFactory } from './sales-orders';

export const salesOrderLineFactory = define<SalesOrderLine>({
  id: () => faker.string.uuid(),
  salesOrder: salesOrderFactory,
  eulaAccepted: () => faker.datatype.boolean(),
  item: itemFactory,
  itemFulfillment: itemFulfillmentFactory,
});
