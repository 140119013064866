import { Element } from 'react-bulma-components';
import ExternalLink from '../external-link/external-link';
import styles from './footer.module.scss';

export function Footer() {
  return (
    <Element className={`${styles.footer} is-hidden-mobile`} py={4}>
      <span className={styles.footerItem}>© 2024 zSpace, Inc.</span>
      <ExternalLink
        to={'https://zspace.com/legal/terms-of-use'}
        className={styles.footerItem}
      >
        Terms of use
      </ExternalLink>
      <ExternalLink
        to={'https://zspace.com/legal/privacy-policy'}
        className={styles.footerItem}
      >
        Privacy Policy
      </ExternalLink>
      <ExternalLink
        to={'https://zspace.com/legal'}
        className={styles.footerItem}
      >
        Legal
      </ExternalLink>
    </Element>
  );
}

export default Footer;
