import {
  SessionStorageServiceImpl,
  USER_INVITATION_STORAGE_KEY,
} from '../shared/storage';

export class UserInvitationStorageService {
  private storageService: SessionStorageServiceImpl<string> =
    new SessionStorageServiceImpl();

  public get() {
    return this.storageService.getItem(USER_INVITATION_STORAGE_KEY) || '';
  }
  public save(userEmail: string) {
    return this.storageService.setItem(USER_INVITATION_STORAGE_KEY, userEmail);
  }
  public remove() {
    return this.storageService.removeItem(USER_INVITATION_STORAGE_KEY);
  }
}
