import { formatSalesOrderNumber } from '@zspace/format';
import {
  FilterTableModalField,
  FilterTableModalFieldAlignment,
  InputType,
  SalesOrdersCriteria,
} from '@zspace/types';
import { isEmail } from '../../shared/utils';

const salesOrderSummaryFilterFields: FilterTableModalField<SalesOrdersCriteria>[] =
  [
    {
      input: {
        type: InputType.FILTER_CRITERIA,
        valueKey: 'opportunityName',
        filterValueKey: 'opportunityNameFilter',
      },
      name: 'opportunityName',
      label: 'Opportunity Name',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.FILTER_CRITERIA,
        valueKey: 'account',
        filterValueKey: 'accountFilter',
      },
      name: 'account',
      label: 'Account',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.MIN_MAX,
        minValueKey: 'minTotalSeats',
        maxValueKey: 'maxTotalSeats',
      },
      name: 'totalSeats',
      label: 'Total Seats',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.TAG_INPUT,
        valueKey: 'salesOrders',
        placeholder: formatSalesOrderNumber('12345'),
      },
      name: 'salesOrders',
      label: 'Sales Orders',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.DATE_RANGE,
        fromDateValueKey: 'closeDateFrom',
        toDateValueKey: 'closeDateTo',
      },
      name: 'closeDate',
      label: 'Close Date',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
    {
      input: {
        type: InputType.FILTER_CRITERIA,
        valueKey: 'parentAccountName',
        filterValueKey: 'parentAccountNameFilter',
      },
      name: 'parentAccount',
      label: 'Parent Account',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
    {
      input: {
        type: InputType.TAG_INPUT,
        valueKey: 'endUser',
        placeholder: 'name@mail.com',
        validateFn: (value: string) => isEmail(value),
        errorText: 'Please enter a valid email address',
      },
      name: 'endUser',
      label: 'End user',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
  ];

const salesOrderDetailsFilterFields: FilterTableModalField<SalesOrdersCriteria>[] =
  [
    {
      input: {
        type: InputType.TAG_INPUT,
        valueKey: 'salesOrders',
        placeholder: formatSalesOrderNumber('12345'),
      },
      name: 'salesOrders',
      label: 'Sales Orders',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.MIN_MAX,
        minValueKey: 'minTotalSeats',
        maxValueKey: 'maxTotalSeats',
      },
      name: 'totalSeats',
      label: 'Total Seats',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.MIN_MAX,
        minValueKey: 'minHardwareNumber',
        maxValueKey: 'maxHardwareNumber',
      },
      name: 'hardware',
      label: 'Hardware',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.TAG_INPUT,
        valueKey: 'endUser',
        placeholder: 'name@mail.com',
        validateFn: (value: string) => isEmail(value),
        errorText: 'Please enter a valid email address',
      },
      name: 'endUser',
      label: 'End user',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
    {
      input: {
        type: InputType.DATE_RANGE,
        fromDateValueKey: 'closeDateFrom',
        toDateValueKey: 'closeDateTo',
      },
      name: 'closeDate',
      label: 'Close date range',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
  ];

export { salesOrderDetailsFilterFields, salesOrderSummaryFilterFields };
