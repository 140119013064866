import { formatSalesOrderNumber } from '@zspace/format';
import {
  FilterTableModalField,
  FilterTableModalFieldAlignment,
  InputType,
  WorkOrderType,
  WorkOrdersCriteria,
} from '@zspace/types';
import { isEmail } from '../../shared/utils';

export const workOrderDraftsAndCompletedTableFilterModalFields: FilterTableModalField<WorkOrdersCriteria>[] =
  [
    {
      input: {
        type: InputType.FILTER_CRITERIA,
        valueKey: 'workOrderName',
        filterValueKey: 'workOrderNameFilter',
      },
      name: 'workOrderName',
      label: 'Work order name',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.FILTER_CRITERIA,
        valueKey: 'account',
        filterValueKey: 'accountFilter',
      },
      name: 'account',
      label: 'Account',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.MIN_MAX,
        minValueKey: 'minTotalDevicesNumber',
        maxValueKey: 'maxTotalDevicesNumber',
      },
      name: 'totalNumberOfDevices',
      label: 'Total number of devices',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.TAG_INPUT,
        valueKey: 'salesOrder',
        placeholder: formatSalesOrderNumber('12345'),
      },
      name: 'salesOrder',
      label: 'Sales order #',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.DATE_RANGE,
        fromDateValueKey: 'creationDateFrom',
        toDateValueKey: 'creationDateTo',
      },
      name: 'creationDateRange',
      label: 'Creation date range',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
    {
      input: {
        type: InputType.SELECT,
        valueKey: 'type',
        options: [
          {
            label: 'Full configuration',
            value: WorkOrderType.FULL_CONFIGURATION,
          },
          {
            label: 'Install only',
            value: WorkOrderType.INSTALL_ONLY,
          },
          {
            label: 'Hardware only',
            value: WorkOrderType.HARDWARE_ONLY,
          },
        ],
      },
      name: 'type',
      label: 'Type',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
    {
      input: {
        type: InputType.TAG_INPUT,
        valueKey: 'createdBy',
        placeholder: 'name@mail.com',
        validateFn: (value: string) => isEmail(value),
        errorText: 'Please enter a valid email address',
      },
      name: 'createdBy',
      label: 'Created by',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
  ];

export const workOrderQueueTableFilterModalFields: FilterTableModalField<WorkOrdersCriteria>[] =
  [
    {
      input: {
        type: InputType.FILTER_CRITERIA,
        valueKey: 'workOrderName',
        filterValueKey: 'workOrderNameFilter',
      },
      name: 'workOrderName',
      label: 'Work order name',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.FILTER_CRITERIA,
        valueKey: 'account',
        filterValueKey: 'accountFilter',
      },
      name: 'account',
      label: 'Account',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.MIN_MAX,
        minValueKey: 'minAssignedDevicesNumber',
        maxValueKey: 'maxAssignedDevicesNumber',
      },
      name: 'numberOfAssignedDevices',
      label: 'Devices',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.TAG_INPUT,
        valueKey: 'salesOrder',
        placeholder: formatSalesOrderNumber('12345'),
      },
      name: 'salesOrder',
      label: 'Sales order #',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.DATE_RANGE,
        fromDateValueKey: 'creationDateFrom',
        toDateValueKey: 'creationDateTo',
      },
      name: 'creationDateRange',
      label: 'Creation date range',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
    {
      input: {
        type: InputType.SELECT,
        valueKey: 'type',
        options: [
          {
            label: 'Full configuration',
            value: WorkOrderType.FULL_CONFIGURATION,
          },
          {
            label: 'Install only',
            value: WorkOrderType.INSTALL_ONLY,
          },
          {
            label: 'Hardware only',
            value: WorkOrderType.HARDWARE_ONLY,
          },
        ],
      },
      name: 'type',
      label: 'Type',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
    {
      input: {
        type: InputType.MIN_MAX,
        minValueKey: 'minPriority',
        maxValueKey: 'maxPriority',
      },
      name: 'priority',
      alignment: FilterTableModalFieldAlignment.RIGHT,
      label: 'Priority',
    },
    {
      input: {
        type: InputType.TAG_INPUT,
        valueKey: 'createdBy',
        placeholder: 'name@mail.com',
        validateFn: (value: string) => isEmail(value),
        errorText: 'Please enter a valid email address',
      },
      name: 'createdBy',
      label: 'Created by',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
  ];
