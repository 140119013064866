import {
  ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS,
  ALL_ZSPACE_STAFF_AND_PARTNER_PERMISSIONS_GROUPS,
} from '@zspace/roles';
import {
  UserRoleType,
  UserViewMode,
  UsersTableFilterDataType,
} from '@zspace/types';
import { useMemo } from 'react';
import FilterTableModal from '../../../shared/filter-table-modal/filter-table-modal';
import useIsCustomerUser from '../../../shared/hooks/is-customer-user';
import useUser from '../../../shared/hooks/user';
import {
  CUSTOMER_FILTER_MODAL_FIELDS,
  CUSTOMER_FILTER_MODAL_FIELDS_ORG_END_USER_SOFTWARE_CONTACTS,
  NON_CUSTOMER_FILTER_MODAL_FIELDS,
  WAREHOUSE_PARTNER_FILTER_MODAL_FIELDS,
} from '../constants';

export type UsersControlPanelFilterModalProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: (value: UsersTableFilterDataType) => void;
  data: UsersTableFilterDataType;
  viewMode?: UserViewMode;
};

export function UsersControlPanelFilterModal({
  show,
  data,
  onClose,
  onSubmit,
  viewMode,
}: UsersControlPanelFilterModalProps) {
  const { user } = useUser();
  const isCustomerUser = useIsCustomerUser();

  const roleOptions = useMemo(() => {
    const permissionsGroups = [{ label: 'All', value: '' }];
    if (isCustomerUser) {
      permissionsGroups.push(
        ...ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS.map((role) => ({
          label: role,
          value: role,
        }))
      );
    } else {
      permissionsGroups.push(
        ...ALL_ZSPACE_STAFF_AND_PARTNER_PERMISSIONS_GROUPS.map((role) => ({
          label: role,
          value: role,
        }))
      );
    }

    return permissionsGroups;
  }, [isCustomerUser]);

  const fields = useMemo(() => {
    if (isCustomerUser) {
      return viewMode === UserViewMode.ORG_END_USER_SOFTWARE_CONTACTS
        ? CUSTOMER_FILTER_MODAL_FIELDS_ORG_END_USER_SOFTWARE_CONTACTS
        : CUSTOMER_FILTER_MODAL_FIELDS(roleOptions);
    }

    const isWarehousePartner =
      user.activeRole?.role.name === UserRoleType.PARTNER;

    if (isWarehousePartner) {
      return WAREHOUSE_PARTNER_FILTER_MODAL_FIELDS;
    }
    return NON_CUSTOMER_FILTER_MODAL_FIELDS(roleOptions);
  }, [isCustomerUser, roleOptions, user.activeRole?.role.name, viewMode]);

  return (
    <FilterTableModal
      show={show}
      data={data}
      onClose={onClose}
      onSubmit={onSubmit}
      fields={fields}
    />
  );
}

export default UsersControlPanelFilterModal;
