import { formatSalesOrderNumber } from '@zspace/format';
import {
  ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS,
  ALL_ZSPACE_STAFF_AND_PARTNER_PERMISSIONS_GROUPS,
  PermissionsGroupName,
  UserPermissions,
  getUserHighestPermissionsGroupUserRoleAssignment,
} from '@zspace/roles';
import { SalesOrder, User, UserRoleType } from '@zspace/types';
import { useCallback, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaPenToSquare } from 'react-icons/fa6';
import SalesOrdersList from '../../sales-orders/sales-orders-list/sales-orders-list';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import Conditional from '../../shared/conditional/conditional';
import { remainingDays } from '../../shared/dates';
import useIsCustomerUser from '../../shared/hooks/is-customer-user';
import useIsUserEditable from '../../shared/hooks/is-user-editable';
import useIsZspaceInternalUser from '../../shared/hooks/is-zspace-internal-user';
import useUserAllowedPermissionsGroupsToManage from '../../shared/hooks/user-allowed-permissions-groups-to-manage';
import If from '../../shared/if/if';
import { formatLastActiveDate } from '../../shared/utils';
import Button from '../../ui/button/button';
import PaginatedTable, {
  CustomPaginatedTableProps,
} from '../../ui/table/paginated-table/paginated-table';
import {
  Column,
  ExpandableRow,
  OnTableChangeFunction,
  RowInteraction,
} from '../../ui/table/types';
import DisableUserConfirmationModal from '../disable-user-confirmation-modal/disable-user-confirmation-modal';
import EditUserPermissionsModal from '../edit-user-permissions-modal/edit-user-permissions-modal';
import { UserStatusText } from '../types';
import styles from './users-table.module.scss';

export type UsersTableProps = CustomPaginatedTableProps<User> & {
  onChange: OnTableChangeFunction<User, PermissionsGroupBySalesOrder>;
  handleDisableUser?: (id: string) => Promise<void>;
  handleEditUserPermissions?: (
    id: string,
    newPermissionsGroup: PermissionsGroupName
  ) => Promise<void>;
  handleUserTemporaryPermissionsClick?: (user: User) => void;
  loading?: boolean;
  showRelatedBySalesOrders?: boolean;
};

export type PermissionsGroupBySalesOrder = {
  permissionsGroupName: PermissionsGroupName;
  salesOrders: SalesOrder[];
};

export function UsersTable({
  dataSource,
  onChange,
  config,
  empty,
  form,
  handleDisableUser,
  handleEditUserPermissions,
  handleUserTemporaryPermissionsClick,
  loading,
  showRelatedBySalesOrders,
}: UsersTableProps) {
  const currentUserAllowedPermissionsGroupsToManage =
    useUserAllowedPermissionsGroupsToManage();
  const isCustomerUser = useIsCustomerUser();
  const isZspaceInternalUser = useIsZspaceInternalUser();

  const [activeRowId, setActiveRowId] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [showDisableConfirmationModal, setShowDisableConfirmationModal] =
    useState(false);
  const [showEditPermissionsModal, setShowEditPermissionsModal] =
    useState(false);

  const rowInteraction: RowInteraction = {
    onRowEnter: function (rowKey: string): void {
      setActiveRowId(rowKey);
    },
    onRowLeave: function (): void {
      setActiveRowId('');
    },
  };

  const onDisableUser = useCallback(
    async (id: string) => {
      await handleDisableUser?.(id);
      setSelectedUser(undefined);
      setShowDisableConfirmationModal(false);
    },
    [handleDisableUser]
  );

  const {
    isOwnUser,
    isEditableByCustomerUser,
    isEditableByZspaceInternalUser,
  } = useIsUserEditable();

  const onEditUserPermissions = useCallback(
    async (id: string, newPermissionsGroup: PermissionsGroupName) => {
      await handleEditUserPermissions?.(id, newPermissionsGroup);
      setSelectedUser(undefined);
      setShowEditPermissionsModal(false);
    },
    [handleEditUserPermissions]
  );

  const isExpandableRow = useCallback(
    (row: User) => {
      const userRoles = new Set<PermissionsGroupName>();
      row.roles.forEach((userRoleAssignment) => {
        if (
          isZspaceInternalUser &&
          showRelatedBySalesOrders &&
          userRoleAssignment.role.name !== UserRoleType.ZSPACE_INTERNAL
        ) {
          return;
        }
        userRoleAssignment.permissionsGroupUserRoleAssignments.forEach(
          (permissionsGroupUserRoleAssignment) => {
            userRoles.add(
              permissionsGroupUserRoleAssignment.permissionsGroup
                .name as PermissionsGroupName
            );
          }
        );
      });
      return userRoles.size > 1;
    },
    [isZspaceInternalUser, showRelatedBySalesOrders]
  );

  const isDisabledRow = useCallback((row: User) => row.deletedAt !== null, []);

  const hasDisabledText = useCallback(
    (row: User) => {
      if (isDisabledRow(row)) {
        return 'has-text-grey-light';
      }
    },
    [isDisabledRow]
  );

  const usersRelatedBySalesOrderColumns: Column<
    User,
    PermissionsGroupBySalesOrder
  >[] = useMemo(
    () => [
      {
        key: 'email',
        render: (el) => (
          <Element display="flex" alignItems="center">
            <span className={hasDisabledText(el)}>{el.email}</span>
            <CheckPermissions
              permissions={UserPermissions.USERS_ASSIGN_ROLES}
              allowedPermissionGroups={
                ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS
              }
            >
              <CheckPermissions.Render>
                <If
                  condition={
                    isCustomerUser
                      ? isEditableByCustomerUser(el)
                      : isEditableByZspaceInternalUser(el)
                  }
                >
                  <Button
                    ml={1}
                    text
                    className={`has-text-primary-dark is-size-7 ${
                      el.id === activeRowId ? '' : `${styles.invisible}`
                    }`}
                    onClick={() => {
                      setSelectedUser(el);
                      handleUserTemporaryPermissionsClick?.(el);
                    }}
                  >
                    <Icon>
                      <FaPenToSquare />
                    </Icon>
                    <span>Edit details</span>
                  </Button>
                </If>
              </CheckPermissions.Render>
            </CheckPermissions>
          </Element>
        ),
        widthClassname: 'is-size-25',
        title: 'User',
        sortable: true,
      },
      {
        key: 'role',
        widthClassname: 'is-size-20',
        render: (el) => {
          if (isExpandableRow(el)) {
            return null;
          }
          const role =
            el.roles[0].permissionsGroupUserRoleAssignments[0].permissionsGroup
              .name;
          return <span className={hasDisabledText(el)}>{role}</span>;
        },
        title: 'Role',
        expandableContentRender: (el) => el.permissionsGroupName,
      },
      {
        key: 'salesOrders',
        render: (el) => {
          if (isExpandableRow(el)) {
            return null;
          }
          const parsedSalesOrdersSet = el.roles.reduce(
            (acc, roleAssignment) => {
              roleAssignment.permissionsGroupUserRoleAssignments.forEach(
                (permissionsGroupUserRoleAssignment) => {
                  if (permissionsGroupUserRoleAssignment.salesOrder) {
                    acc.add(permissionsGroupUserRoleAssignment.salesOrder);
                  }
                }
              );
              return acc;
            },
            new Set<SalesOrder>()
          );

          const parsedSalesOrders = Array.from(parsedSalesOrdersSet.values());
          const isEditableByUser = isCustomerUser
            ? isEditableByCustomerUser(el)
            : isEditableByZspaceInternalUser(el);
          const link = isEditableByUser ? `/users/${el.id}/edit` : '';
          const viewMoreLink = `/users/${el.id}`;

          return (
            <SalesOrdersList
              list={parsedSalesOrders}
              textColor="primary"
              link={link}
              salesOrderLink={viewMoreLink}
            />
          );
        },
        title: 'Sales orders',
        sortable: false,
        expandableContentRender: (el, parentElement) => {
          if (!el.salesOrders) {
            return null;
          }

          const salesOrders = el.salesOrders;

          const isEditableByUser = isCustomerUser
            ? isEditableByCustomerUser(parentElement)
            : isEditableByZspaceInternalUser(parentElement);
          const link = isEditableByUser
            ? `/users/${parentElement.id}/edit`
            : '';
          const viewMoreLink = `/users/${parentElement.id}`;

          return (
            <SalesOrdersList
              list={salesOrders}
              textColor="primary"
              link={link}
              salesOrderLink={viewMoreLink}
            />
          );
        },
      },
      {
        key: 'deletedAt',
        widthClassname: 'is-size-15',
        render: (el) => {
          const userStatus = el.lastActive
            ? UserStatusText.ACTIVE
            : UserStatusText.PENDING_FIRST_LOGIN;

          return <span className={hasDisabledText(el)}>{userStatus}</span>;
        },
        title: 'Status',
        sortable: false,
      },
    ],
    [
      hasDisabledText,
      isCustomerUser,
      isEditableByCustomerUser,
      isEditableByZspaceInternalUser,
      activeRowId,
      handleUserTemporaryPermissionsClick,
      isExpandableRow,
    ]
  );

  const zspaceStaffAndPartnerColumns: Column<User>[] = useMemo(
    () => [
      {
        key: 'email',
        dataIndex: 'email',
        title: 'User',
        widthClassname: 'is-size-25',
        sortable: true,
      },
      {
        key: 'roles.role.name',
        widthClassname: 'is-size-20',
        render: (el) => {
          const isWarehousePartnerUser = el.roles.every(
            (role) => role.role.name === UserRoleType.PARTNER
          );
          const userPermissionsGroupUserRoleAssignment = isWarehousePartnerUser
            ? el.roles.find((role) => role.role.name === UserRoleType.PARTNER)
                ?.permissionsGroupUserRoleAssignments[0]
            : getUserHighestPermissionsGroupUserRoleAssignment(
                el,
                UserRoleType.ZSPACE_INTERNAL
              );
          const role =
            userPermissionsGroupUserRoleAssignment?.permissionsGroup?.name ??
            'Staff';
          const hasEditableRole =
            !isWarehousePartnerUser &&
            currentUserAllowedPermissionsGroupsToManage.length > 0 &&
            (role === 'Staff' ||
              currentUserAllowedPermissionsGroupsToManage.includes(
                role as PermissionsGroupName
              )) &&
            !isOwnUser(el) &&
            !el.deletedAt;

          return (
            <Element display="flex" alignItems="center">
              <span>{role}</span>
              <CheckPermissions
                permissions={UserPermissions.USERS_ASSIGN_ROLES}
              >
                <CheckPermissions.Render>
                  <If condition={hasEditableRole}>
                    <Button
                      ml={1}
                      text
                      className={`has-text-primary-dark is-size-7 ${
                        el.id === activeRowId ? '' : `${styles.invisible}`
                      }`}
                      onClick={() => {
                        setSelectedUser(el);
                        setShowEditPermissionsModal(true);
                      }}
                    >
                      <Icon>
                        <FaPenToSquare />
                      </Icon>
                      <span>Edit</span>
                    </Button>
                  </If>
                </CheckPermissions.Render>
              </CheckPermissions>
            </Element>
          );
        },
        title: 'Role',
      },
      {
        key: 'temporaryRoles',
        render: (el) => {
          const temporaryPermissionsGroupUserRoleAssignments = el.roles
            .find(
              (roleAssignment) =>
                roleAssignment.role.name === UserRoleType.CUSTOMER
            )
            ?.permissionsGroupUserRoleAssignments.filter(
              (roleAssignment) =>
                roleAssignment.permissionsGroup.name ===
                PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER
            );

          if (
            !temporaryPermissionsGroupUserRoleAssignments ||
            temporaryPermissionsGroupUserRoleAssignments.length === 0
          ) {
            return '';
          }

          return temporaryPermissionsGroupUserRoleAssignments.map(
            (permissionsGroupUserRoleAssignment) => {
              const daysLeft = remainingDays(
                new Date(permissionsGroupUserRoleAssignment.expiresAt!)
              );

              if (!daysLeft || !permissionsGroupUserRoleAssignment.salesOrder) {
                return null;
              }

              const content = `${
                permissionsGroupUserRoleAssignment.salesOrder.opportunity
                  ?.account.name ??
                formatSalesOrderNumber(
                  permissionsGroupUserRoleAssignment.salesOrder.number
                )
              } (${daysLeft})`;

              return (
                <Element>
                  <span className="is-size-7 is-underlined">{content}</span>
                </Element>
              );
            }
          );
        },
        title: 'Temporary manager roles (days remaining)',
        sortable: false,
      },
      {
        key: 'lastActive',
        widthClassname: 'is-size-10',
        render: (el) =>
          el.lastActive ? formatLastActiveDate(new Date(el.lastActive)) : '',
        title: 'Last active',
        sortable: true,
      },
      {
        key: 'deletedAt',
        widthClassname: 'is-size-15',
        render: (el) => (
          <Element display="flex" alignItems="center">
            <Conditional condition={el.deletedAt === null}>
              <Conditional.True>
                <span>
                  {el.lastActive
                    ? UserStatusText.ACTIVE
                    : UserStatusText.PENDING_FIRST_LOGIN}
                </span>
                <CheckPermissions
                  permissions={UserPermissions.USERS_DELETE}
                  allowedPermissionGroups={
                    ALL_ZSPACE_STAFF_AND_PARTNER_PERMISSIONS_GROUPS
                  }
                >
                  <CheckPermissions.Render>
                    <If condition={!isOwnUser(el)}>
                      <Button
                        ml={1}
                        text
                        className={`has-text-danger-dark is-size-7 ${
                          el.id === activeRowId ? '' : `${styles.invisible}`
                        }`}
                        onClick={() => {
                          setSelectedUser(el);
                          setShowDisableConfirmationModal(true);
                        }}
                      >
                        <span className="is-underlined">Disable</span>
                      </Button>
                    </If>
                  </CheckPermissions.Render>
                </CheckPermissions>
              </Conditional.True>
              <Conditional.False>
                <span>Inactive</span>
              </Conditional.False>
            </Conditional>
          </Element>
        ),
        title: 'Status',
        sortable: false,
      },
    ],
    [activeRowId, isOwnUser, currentUserAllowedPermissionsGroupsToManage]
  );

  const columns = useMemo(
    () =>
      showRelatedBySalesOrders
        ? usersRelatedBySalesOrderColumns
        : zspaceStaffAndPartnerColumns,
    [
      usersRelatedBySalesOrderColumns,
      showRelatedBySalesOrders,
      zspaceStaffAndPartnerColumns,
    ]
  );

  const expandableRow = useMemo(():
    | ExpandableRow<User, PermissionsGroupBySalesOrder>
    | undefined => {
    if (showRelatedBySalesOrders) {
      return {
        expandable: (row: User) => isExpandableRow(row),
        expandableRowIterator: (row: User) => {
          const permissionsGroupsBySalesOrderMap = new Map<
            PermissionsGroupName,
            PermissionsGroupBySalesOrder
          >();

          const userRoleAssignment = row.roles.find((assignment) => {
            if (isCustomerUser) {
              return assignment.role.name === UserRoleType.CUSTOMER;
            } else {
              return assignment.role.name === UserRoleType.ZSPACE_INTERNAL;
            }
          });

          if (!userRoleAssignment) {
            return [];
          }

          userRoleAssignment.permissionsGroupUserRoleAssignments.forEach(
            (assignment) => {
              const permissionsGroupName = assignment.permissionsGroup
                .name as PermissionsGroupName;

              if (!assignment.salesOrder) return;

              const existingGroup =
                permissionsGroupsBySalesOrderMap.get(permissionsGroupName);
              const salesOrders = existingGroup
                ? [...existingGroup.salesOrders, assignment.salesOrder]
                : [assignment.salesOrder];

              permissionsGroupsBySalesOrderMap.set(permissionsGroupName, {
                permissionsGroupName,
                salesOrders,
              });
            }
          );

          return Array.from(permissionsGroupsBySalesOrderMap.values());
        },
      };
    }
  }, [isCustomerUser, isExpandableRow, showRelatedBySalesOrders]);

  return (
    <>
      <PaginatedTable
        dataSource={dataSource}
        columns={columns}
        onChange={onChange}
        config={config}
        empty={empty}
        rowKey={'id'}
        form={form}
        rowInteraction={rowInteraction}
        isDisabledSelection={isDisabledRow}
        expandableRow={expandableRow}
      />
      <If condition={!!selectedUser}>
        <DisableUserConfirmationModal
          show={showDisableConfirmationModal}
          user={selectedUser!}
          loading={!!loading}
          onCancel={() => {
            setShowDisableConfirmationModal(false);
            setSelectedUser(undefined);
          }}
          onDisable={onDisableUser}
        />
        <EditUserPermissionsModal
          show={showEditPermissionsModal}
          user={selectedUser!}
          onCancel={() => {
            setShowEditPermissionsModal(false);
            setSelectedUser(undefined);
          }}
          onEditUserPermissions={onEditUserPermissions}
        />
      </If>
    </>
  );
}

export default UsersTable;
