import { Device } from '@zspace/types';
import {
  DEVICE_FOR_SOFTWARE_ASSIGNMENT_STORAGE_KEY,
  SessionStorageServiceImpl,
} from '../shared/storage';

export class DeviceForSoftwareAssignmentStorageService {
  private storageService: SessionStorageServiceImpl<Device[]> =
    new SessionStorageServiceImpl();

  public get() {
    return (
      this.storageService.getItem(DEVICE_FOR_SOFTWARE_ASSIGNMENT_STORAGE_KEY) ||
      []
    );
  }
  public save(devicesForSoftwareAssignment: Device[]) {
    return this.storageService.setItem(
      DEVICE_FOR_SOFTWARE_ASSIGNMENT_STORAGE_KEY,
      devicesForSoftwareAssignment
    );
  }
  public remove() {
    return this.storageService.removeItem(
      DEVICE_FOR_SOFTWARE_ASSIGNMENT_STORAGE_KEY
    );
  }
}
