import { DownloadableData, EulaData, EulaOutstandingData } from '@zspace/types';
import { http } from '../shared/http';

export interface EulaService {
  fetchEulasData: () => Promise<EulaData>;
  acceptEula: () => Promise<void>;
  fetchEulasOutstandingData: () => Promise<EulaOutstandingData>;
  downloadEulaAuditLog: () => Promise<DownloadableData>;
}

export class EulaServiceImpl implements EulaService {
  public async fetchEulasData(): Promise<EulaData> {
    const { data } = await http('/v1/eula');

    return data;
  }

  public async acceptEula(): Promise<void> {
    return await http.post('/v1/eula');
  }

  public async downloadEulaAuditLog(): Promise<DownloadableData> {
    const { data } = await http.get<DownloadableData>('/v1/eula/audit-log');
    return data;
  }

  public async fetchEulasOutstandingData(): Promise<EulaOutstandingData> {
    const { data } = await http('/v1/eula/outstandings');

    return data;
  }
}

export const fetchEulasData = async (): Promise<EulaData> => {
  const service = new EulaServiceImpl();

  return service.fetchEulasData();
};

export const acceptEula = async (): Promise<void> => {
  const service = new EulaServiceImpl();

  return service.acceptEula();
};

export const downloadEulaAuditLog = async (): Promise<DownloadableData> => {
  const service = new EulaServiceImpl();

  return service.downloadEulaAuditLog();
};

export const fetchEulasOutstandingData =
  async (): Promise<EulaOutstandingData> => {
    const service = new EulaServiceImpl();

    return service.fetchEulasOutstandingData();
  };
