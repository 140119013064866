import { HttpStatusCode } from 'axios';
import { Box, Element } from 'react-bulma-components';
import DevOnly from '../dev-only/dev-only';
import useError from '../hooks/error';
import If from '../if/if';

export function ErrorMessageCard() {
  const { error } = useError();

  const hasError = typeof error !== 'undefined';
  const errorMessage =
    (error as { message: string })?.message ?? (error as string) ?? '';
  const errorStatus = (error as { status: HttpStatusCode })?.status ?? '';

  return (
    <Box
      display="flex"
      justifyContent="center"
      paddingless
      my={4}
      mx={10}
      className="box-container is-min-height-50"
    >
      <Element
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className="gap-2"
      >
        <h1 className="is-size-4 has-text-weight-light mb-2">
          Oh snap! Something went wrong
        </h1>
        <DevOnly>
          <If condition={hasError}>
            <h2 className="is-size-6 has-text-weight-light mb-2">
              {errorStatus ? `${errorStatus} - ` : ''}
              {errorMessage}
            </h2>
          </If>
        </DevOnly>
      </Element>
    </Box>
  );
}

export default ErrorMessageCard;
