import { UserPermissions } from '@zspace/roles';
import { ReactNode, useCallback, useState } from 'react';
import { Element, Section } from 'react-bulma-components';
import { Outlet, useNavigate } from 'react-router-dom';
import BackButton from '../../shared/back-button/back-button';
import useIsCustomerUser from '../../shared/hooks/is-customer-user';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import { UserInvitationStorageService } from '../user-invitation-storage-service';
import { InviteUserPageContext, InviteUserPageSteps } from './types';

const userInvitationStorage = new UserInvitationStorageService();

export type InviteUserPageProps = {
  internalViewMode?: boolean;
};

export function InviteUserPage({ internalViewMode }: InviteUserPageProps) {
  const isCustomerUser = useIsCustomerUser();
  const navigate = useNavigate();
  const [title, setTitle] = useState('Invite new user');
  const [email, _setEmail] = useState<string>(userInvitationStorage.get());
  const [step, setStep] = useState<number>(1);
  const [filterTagList, setFilterTagList] = useState<ReactNode>(null);

  const setEmail = (value: string): void => {
    userInvitationStorage.save(value);
    if (value.length === 0) {
      userInvitationStorage.remove();
    }

    _setEmail(value);
  };

  const navigateToPermissionsHome = useCallback(() => {
    userInvitationStorage.remove();
    if (internalViewMode) {
      navigate('/users/panel');
    } else {
      navigate('/users/my-team');
    }
  }, [internalViewMode, navigate]);

  const navigateToInviteUserDetailsPage = useCallback(() => {
    navigate('/users/my-team/invite/details');
  }, [navigate]);

  const handleBackButtonClick = useCallback(() => {
    switch (step) {
      case InviteUserPageSteps.EMAIL:
        navigateToPermissionsHome();
        break;
      case InviteUserPageSteps.PERMISSIONS:
        navigateToInviteUserDetailsPage();
        break;
    }
  }, [navigateToInviteUserDetailsPage, navigateToPermissionsHome, step]);

  const Header = useCallback(() => {
    return (
      <Element className="p-4">
        <Section paddingless>
          <If
            condition={isCustomerUser || (!isCustomerUser && !internalViewMode)}
          >
            <BackButton onClick={handleBackButtonClick} />
          </If>
          <h1 className="is-size-3 has-text-weight-light">{title}</h1>
        </Section>
        <If condition={!!filterTagList}>
          <Element>{filterTagList}</Element>
        </If>
      </Element>
    );
  }, [
    internalViewMode,
    filterTagList,
    handleBackButtonClick,
    isCustomerUser,
    title,
  ]);

  return (
    <ProtectedPage permissions={UserPermissions.USERS_CREATE}>
      <BoxLayout
        className="is-min-height-80 mx-10 my-4"
        flex
        header={<Header />}
      >
        <Outlet
          context={
            {
              email,
              setEmail,
              setStep,
              setTitle,
              setFilterTagList,
              navigateToPermissionsHome,
              internalViewMode,
            } as InviteUserPageContext
          }
        />
      </BoxLayout>
    </ProtectedPage>
  );
}

export default InviteUserPage;
