import { DeviceGroupPermissions, SoftwareSeatPermissions } from '@zspace/roles';
import { Criteria } from '@zspace/types';
import { useCallback, useEffect, useState } from 'react';
import {
  LoaderFunction,
  defer,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import DeviceGroupSoftwareSelectionCard from '../../device-groups/device-group-software-selection-card/device-group-software-selection-card';
import ProtectedPage from '../../shared/protected-page/protected-page';
import Spinner from '../../ui/spinner/spinner';
import { ManageSoftwareLayoutContext } from '../manage-software-layout/manage-software-layout';
import {
  fetchAvailableSoftwareBySalesOrderForAssignment,
  fetchAvailableSoftwareSalesOrderForAssignment,
} from '../software-assignment-service';

const initialCriteria: Criteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
};

export const loader: LoaderFunction = async ({ params }) => {
  const modelId = params.modelId;

  const response = fetchAvailableSoftwareSalesOrderForAssignment({
    ...initialCriteria,
    modelId: modelId as string,
  });

  return defer({ response });
};

export function DeviceGroupSoftwareSelectionCardPage() {
  const navigate = useNavigate();
  const { modelId } = useParams();

  const { selectedDevices, setTitle } =
    useOutletContext<ManageSoftwareLayoutContext>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedDevices.length === 0) {
      setLoading(true);
      navigate(`/device-groups`, { replace: true });
    }
  }, [navigate, selectedDevices.length]);

  const handleOnNext = useCallback(() => {
    navigate(`/device-models/${modelId}/software/review`);
  }, [modelId, navigate]);

  const handleOnCancel = useCallback(() => {
    navigate('/device-groups');
  }, [navigate]);

  useEffect(() => {
    setTitle(`Assign software`);
  }, [setTitle]);

  const fetchSalesOrders = useCallback(
    (updatedCriteria: Criteria) =>
      fetchAvailableSoftwareSalesOrderForAssignment({
        ...updatedCriteria,
        modelId: modelId as string,
      }),
    [modelId]
  );

  const fetchSoftwareBySalesOrder = useCallback(
    (salesOrderId: string, updatedCriteria: Criteria) =>
      fetchAvailableSoftwareBySalesOrderForAssignment(salesOrderId, {
        ...updatedCriteria,
        modelId: modelId as string,
      }),
    [modelId]
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <ProtectedPage
      permissions={[
        DeviceGroupPermissions.DEVICE_GROUPS_READ,
        SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
        SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN,
      ]}
    >
      <DeviceGroupSoftwareSelectionCard
        onNext={handleOnNext}
        onCancel={handleOnCancel}
        fetchSalesOrders={fetchSalesOrders}
        fetchSoftwareBySalesOrder={fetchSoftwareBySalesOrder}
      />
    </ProtectedPage>
  );
}

export default DeviceGroupSoftwareSelectionCardPage;
