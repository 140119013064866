import { NewAssignments, SoftwareForAssignment } from '@zspace/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaArrowLeft, FaFloppyDisk } from 'react-icons/fa6';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import DeviceGroupTable from '../../device-groups/device-group-table/device-group-table';
import useHttpRequest from '../../shared/hooks/http-request';
import { ManageSoftwareLayoutContext } from '../../software-assignment/manage-software-layout/manage-software-layout';
import Button from '../../ui/button/button';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { assignSoftware } from '../work-orders-service';

export function ReviewSoftwareAssignmentPage() {
  const navigate = useNavigate();
  const { id: workOrderId, modelId } = useParams();

  const {
    selectedDevices,
    selectedSoftware,
    setSelectedDevices,
    setSelectedSoftware,
    revalidate,
    setHeaderRightContent,
    setTitle,
  } = useOutletContext<ManageSoftwareLayoutContext>();

  const [loading, setLoading] = useState(false);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  const { executeHttpRequest, isLoading: isAssigningSoftware } =
    useHttpRequest();

  const navigateToSoftwareSelectPage = useCallback(async () => {
    setHeaderRightContent(undefined);
    navigate(
      `/work-orders/${workOrderId}/device-groups/device-models/${modelId}/software/select`,
      { replace: true }
    );
  }, [modelId, navigate, setHeaderRightContent, workOrderId]);

  const navigateToDeviceGroupsPage = useCallback(() => {
    navigate(`/work-orders/${workOrderId}/device-groups`, { replace: true });
  }, [navigate, workOrderId]);

  const handleSaveButtonClick = useCallback(
    async () =>
      executeHttpRequest({
        asyncFunction: async () => {
          setSubmitButtonPressed(true);
          const softwareForAssignment: SoftwareForAssignment[] =
            selectedSoftware.map((sw) => ({
              salesOrderId: sw.salesOrder.id,
              licensingProductGroupId: sw.licensingProductGroup.id,
              itemFulfillmentId: sw.itemFulfillment.id,
            }));

          const assignments: NewAssignments = {
            devices: selectedDevices,
            software: softwareForAssignment,
          };

          await assignSoftware(workOrderId as string, assignments);

          revalidate();
          setSelectedDevices([]);
          setSelectedSoftware([]);
          navigateToDeviceGroupsPage();
        },
      }),
    [
      executeHttpRequest,
      navigateToDeviceGroupsPage,
      revalidate,
      selectedDevices,
      selectedSoftware,
      setSelectedDevices,
      setSelectedSoftware,
      workOrderId,
    ]
  );

  const headerRightContent = useMemo(
    () => (
      <Element display="flex" className="gap-6">
        <Button
          color="primary-dark"
          outlined
          className="outlined-button-white-background"
          onClick={navigateToSoftwareSelectPage}
        >
          <Icon>
            <FaArrowLeft />
          </Icon>
          <span>Back</span>
        </Button>
        <Button
          color="primary-dark"
          onClick={handleSaveButtonClick}
          isExecutingAction={isAssigningSoftware}
        >
          <Button.LoadingIcon icon={FaFloppyDisk} />
          <span>Save and continue</span>
        </Button>
      </Element>
    ),
    [handleSaveButtonClick, isAssigningSoftware, navigateToSoftwareSelectPage]
  );

  useEffect(() => {
    if (
      !submitButtonPressed &&
      (selectedDevices.length === 0 || selectedSoftware.length === 0)
    ) {
      setLoading(true);
      if (selectedDevices.length === 0) {
        navigateToDeviceGroupsPage();
      } else if (selectedSoftware.length === 0) {
        navigateToSoftwareSelectPage();
      }
    }
  }, [
    navigateToDeviceGroupsPage,
    navigateToSoftwareSelectPage,
    selectedDevices.length,
    selectedSoftware.length,
    submitButtonPressed,
  ]);

  useEffect(() => {
    setHeaderRightContent(headerRightContent);
  }, [headerRightContent, setHeaderRightContent]);

  useEffect(() => {
    setTitle(`Review software assignment`);
  }, [setTitle]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <DeviceGroupTable
      data={selectedDevices}
      selectedSoftware={selectedSoftware.map(
        (sw) => sw.licensingProductGroup.displayName
      )}
      disableFilter
      disableSearch
    />
  );
}

export default ReviewSoftwareAssignmentPage;
