import { ReactNode } from 'react';
import { Box, Columns, Section } from 'react-bulma-components';
import Spinner from '../../../ui/spinner/spinner';
import styles from './work-order-device-groups-detail-layout-columns.module.scss';

export function WorkOrderDeviceGroupsDetailLayoutColumns({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Section paddingless py={4} mx={10}>
      <Columns>{children}</Columns>
    </Section>
  );
}

const Left = ({
  children,
  className = '',
}: {
  children?: ReactNode;
  className?: string;
}) => (
  <Columns.Column size={'three-fifths'}>
    <Box
      className={`box-container h-full ${styles.column} ${className}`}
      display="flex"
      alignItems="center"
    >
      {children}
    </Box>
  </Columns.Column>
);

const Right = ({
  children,
  className = '',
}: {
  children?: ReactNode;
  className?: string;
}) => (
  <Columns.Column>
    <Box className={`box-container h-full ${styles.column} ${className}`}>
      {children}
    </Box>
  </Columns.Column>
);

const Skeleton = () => (
  <WorkOrderDeviceGroupsDetailLayoutColumns>
    <Left className="is-justify-content-center">
      <Spinner />
    </Left>
    <Right className="is-flex is-justify-content-center is-align-items-center">
      <Spinner />
    </Right>
  </WorkOrderDeviceGroupsDetailLayoutColumns>
);

WorkOrderDeviceGroupsDetailLayoutColumns.Left = Left;
WorkOrderDeviceGroupsDetailLayoutColumns.Right = Right;
WorkOrderDeviceGroupsDetailLayoutColumns.Skeleton = Skeleton;

export default WorkOrderDeviceGroupsDetailLayoutColumns;
