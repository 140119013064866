import Clarity from '@microsoft/clarity';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './instrument';

import '@fontsource/ubuntu';
import './styles.scss';

import App from './app/app';
import { environment } from './environments/environment';

const clarityProjectId = environment.clarityProjectId;

if (clarityProjectId) {
  Clarity.init(clarityProjectId);
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
