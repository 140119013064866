import { SalesOrder } from '@zspace/types';
import { ComponentProps, useMemo } from 'react';
import Conditional from '../../shared/conditional/conditional';
import InboxItem from '../../ui/inbox-table/inbox-item/inbox-item';
import InfiniteScroll from '../../ui/infinite-scroll/infinite-scroll';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import SoftwareInboxItem from '../software-inbox-item/software-inbox-item';

const ALL_SOFTWARE_TITLES = 'ALL';

export type SoftwareInboxProps = Omit<
  ComponentProps<typeof InfiniteScroll>,
  'children'
> & {
  salesOrders: SalesOrder[];
  selectedSalesOrderId?: string;
  onClick: (salesOrderId: string) => void;
  loadingInitialData?: boolean;
};

export function SoftwareInbox({
  fetchMore,
  hasMore,
  salesOrders,
  onClick,
  selectedSalesOrderId,
  loadingInitialData,
}: SoftwareInboxProps) {
  const footer = useMemo(
    () => (
      <InboxItem
        onClick={() => onClick(ALL_SOFTWARE_TITLES)}
        active={selectedSalesOrderId === ALL_SOFTWARE_TITLES}
      >
        All software titles
      </InboxItem>
    ),
    [onClick, selectedSalesOrderId]
  );

  return (
    <Conditional condition={!!loadingInitialData}>
      <Conditional.True>
        <PageSpinner />
      </Conditional.True>
      <Conditional.False>
        <InfiniteScroll fetchMore={fetchMore} hasMore={hasMore} footer={footer}>
          {salesOrders.map((salesOrder) => (
            <SoftwareInboxItem
              key={salesOrder.id}
              active={selectedSalesOrderId === salesOrder.id}
              onClick={() => onClick(salesOrder.id)}
              salesOrder={salesOrder}
            />
          ))}
        </InfiniteScroll>
      </Conditional.False>
    </Conditional>
  );
}

export default SoftwareInbox;
