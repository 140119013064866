import { Device } from '@zspace/types';
import { useCallback } from 'react';
import { PiWarningFill } from 'react-icons/pi';
import TagList from '../../../ui/tag-list/tag-list';

export type SelectedDeviceTagListProps = {
  selectedDevices: Device[];
  onManageDeviceClick?: (devices: Device[], add: boolean) => void;
  isInvalidSelection?: (devices: Device[]) => boolean;
};

export function SelectedDeviceTagList({
  selectedDevices,
  onManageDeviceClick,
  isInvalidSelection = () => false,
}: SelectedDeviceTagListProps) {
  const TagListItem = useCallback(
    (item: Device) => {
      let deviceIdentifier = item.serialNumber?.number;
      if (!deviceIdentifier) {
        deviceIdentifier = item.name ?? item.defaultName;
      }
      const showError = isInvalidSelection([item]);
      return (
        <>
          {deviceIdentifier}
          {showError && (
            <PiWarningFill
              className="ml-2"
              style={{ verticalAlign: 'text-bottom' }}
            />
          )}
        </>
      );
    },
    [isInvalidSelection]
  );

  const handleSelectedDeviceRemoval = useCallback(
    (item: Device) => {
      onManageDeviceClick?.([item], false);
    },
    [onManageDeviceClick]
  );

  return (
    <TagList
      list={selectedDevices}
      renderItem={TagListItem}
      onRemove={handleSelectedDeviceRemoval}
      title={<span className="mx-2">Selected</span>}
      itemColor={(item) =>
        isInvalidSelection([item]) ? 'danger-dark' : 'primary'
      }
      colorVariant={'dark'}
    />
  );
}

export default SelectedDeviceTagList;
