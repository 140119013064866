import { FilterTableModalField, SalesOrdersCriteria } from '@zspace/types';
import FilterTableModal from '../../shared/filter-table-modal/filter-table-modal';

export type SalesOrdersTableFilterModalProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: (value: SalesOrdersCriteria) => void;
  data: SalesOrdersCriteria;
  fields: FilterTableModalField<SalesOrdersCriteria>[];
};

export function SalesOrdersTableFilterModal({
  show,
  data,
  onClose,
  onSubmit,
  fields,
}: SalesOrdersTableFilterModalProps) {
  return (
    <FilterTableModal
      show={show}
      onClose={onClose}
      data={data}
      onSubmit={onSubmit}
      fields={fields}
    />
  );
}

export default SalesOrdersTableFilterModal;
