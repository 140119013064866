import { capitalizeString } from '@zspace/format';
import {
  ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS,
  PermissionsGroupName,
  UserPermissions,
} from '@zspace/roles';
import {
  AllYesNoFilter,
  DeferredResponse,
  FilterType,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  SortDirection,
  User,
  UserStatus,
  UserViewMode,
  UsersCriteria,
  UsersTableFilterDataType,
} from '@zspace/types';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import { FaFilter, FaPlus } from 'react-icons/fa6';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useNavigation,
  useSearchParams,
} from 'react-router-dom';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import Conditional from '../../shared/conditional/conditional';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import useUpdateUrl from '../../shared/hooks/url';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import { createSearchParams } from '../../shared/url';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { OnTableChangeData } from '../../ui/table/types';
import UsersControlPanelFilterModal from '../filter-modals/users-control-panel-filter-modal/users-control-panel-filter-modal';
import { disableUser, editUserPermissions, fetchUsers } from '../users-service';
import UsersTable, {
  PermissionsGroupBySalesOrder,
} from '../users-table/users-table';

const UPDATE_USER_PERMISSIONS_ERROR_MESSAGE =
  'The user permissions could not be updated. Please try again';
const DISABLE_USER_ERROR_MESSAGE =
  'The user could not be disabled. Please try again';

export const loader: LoaderFunction = async ({ request }) => {
  const searchParams = createSearchParams(request);

  const response = fetchUsers({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '50'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: searchParams.get('sortBy') ?? '',
    sortDirection: searchParams.get('sortDirection') as SortDirection,
    email: searchParams.get('email') ?? '',
    emailFilter:
      (searchParams.get('emailFilter') as FilterType) ?? FilterType.CONTAINS,
    lastActiveDateFrom: searchParams.get('lastActiveDateFrom') ?? '',
    lastActiveDateTo: searchParams.get('lastActiveDateTo') ?? '',
    permissionsGroup: searchParams.get('permissionsGroup') ?? '',
    temporaryRoles:
      (searchParams.get('temporaryRoles') as AllYesNoFilter) ??
      AllYesNoFilter.ALL,
    status: (searchParams.get('status') as UserStatus) ?? UserStatus.ALL,
    viewMode:
      (searchParams.get('viewMode') as UserViewMode) ??
      UserViewMode.RELATED_USERS,
    salesOrder: searchParams.getAll('salesOrder') ?? [],
  });

  return defer({ response });
};

const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 50];

const initialCriteriaData: UsersCriteria = {
  itemsPerPage: 50,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
  email: '',
  emailFilter: FilterType.CONTAINS,
  lastActiveDateFrom: '',
  lastActiveDateTo: '',
  permissionsGroup: '',
  temporaryRoles: AllYesNoFilter.ALL,
  status: UserStatus.ALL,
  salesOrder: [],
};

export function UsersControlPanelPageContent() {
  const response = useAsyncValue() as PaginatedAPIResponse<User>;

  const [searchParams] = useSearchParams();
  const updateUrl = useUpdateUrl();
  const navigation = useNavigation();
  const navigate = useNavigate();

  const [criteria, setCriteria] = useState<UsersCriteria>({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '50'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: searchParams.get('sortBy') ?? '',
    sortDirection: searchParams.get('sortDirection') as SortDirection,
    email: searchParams.get('email') ?? '',
    emailFilter:
      (searchParams.get('emailFilter') as FilterType) ?? FilterType.CONTAINS,
    lastActiveDateFrom: searchParams.get('lastActiveDateFrom') ?? '',
    lastActiveDateTo: searchParams.get('lastActiveDateTo') ?? '',
    permissionsGroup: searchParams.get('permissionsGroup') ?? '',
    temporaryRoles:
      (searchParams.get('temporaryRoles') as AllYesNoFilter) ??
      AllYesNoFilter.ALL,
    status: (searchParams.get('status') as UserStatus) ?? UserStatus.ALL,
    salesOrder: searchParams.getAll('salesOrder') ?? [],
  });
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const { executeHttpRequest, isLoading } = useHttpRequest();

  const tableData: User[] = useMemo(() => response.data, [response.data]);

  const tableConfig: PaginatedContentConfig = useMemo(
    () => ({ ...criteria, pages: response.pages }),
    [criteria, response.pages]
  );

  const filterTagsData = useMemo(() => {
    return {
      email: {
        label: 'Email',
        value:
          criteria.email.length > 0
            ? criteria.emailFilter
                .toLowerCase()
                .replace('_', ' ')
                .concat(' ', criteria.email)
            : '',
      },
      lastActiveDateFrom: {
        label: 'Last active from',
        value: criteria.lastActiveDateFrom,
      },
      lastActiveDateTo: {
        label: 'Last active to',
        value: criteria.lastActiveDateTo,
      },
      permissionsGroup: {
        label: 'Role',
        value: criteria.permissionsGroup,
      },
      temporaryRoles: {
        label: 'Temporary roles',
        value:
          criteria.temporaryRoles !== AllYesNoFilter.ALL
            ? capitalizeString(criteria.temporaryRoles)
            : '',
      },
      status: {
        label: 'Status',
        value:
          criteria.status !== UserStatus.ALL
            ? capitalizeString(criteria.status)
            : '',
      },
      salesOrder: {
        label: 'Sales order #',
        value: criteria.salesOrder.join(', '),
      },
    };
  }, [criteria]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const emptyContentText = useMemo(() => {
    const hasAppliedFilters =
      criteria.search !== '' || appliedFilters.length > 0;
    if (hasAppliedFilters) {
      return 'There are no users that match the selected criteria';
    }
    return 'There are no users at the moment';
  }, [appliedFilters.length, criteria.search]);

  const handleOnTableChange = useCallback(
    (value: OnTableChangeData<User, PermissionsGroupBySalesOrder>): void => {
      const newCriteria = {
        ...criteria,
        ...value.config,
        sortBy: value.column?.key || criteria.sortBy,
      };

      setCriteria(newCriteria);
      updateUrl(newCriteria);
    },
    [criteria, updateUrl]
  );

  const handleInviteUser = useCallback(() => {
    navigate('/users/panel/invite');
  }, [navigate]);

  const handleDisableUser = useCallback(
    async (id: string) =>
      executeHttpRequest({
        asyncFunction: async () => {
          await disableUser(id);
          setCriteria(initialCriteriaData);
          navigate('/users/panel');
        },
        customErrorMessage: DISABLE_USER_ERROR_MESSAGE,
      }),
    [executeHttpRequest, navigate]
  );

  const handleEditUserPermissions = useCallback(
    async (id: string, permissionsGroup: PermissionsGroupName) =>
      executeHttpRequest({
        asyncFunction: async () => {
          await editUserPermissions(id, { permissionsGroup });
          setCriteria(initialCriteriaData);
          navigate(`/users/panel`);
        },
        customErrorMessage: UPDATE_USER_PERMISSIONS_ERROR_MESSAGE,
      }),
    [executeHttpRequest, navigate]
  );

  const handleTableFilterSubmit = useCallback(
    (tableFilterValue: UsersTableFilterDataType) => {
      const newCriteria = { ...criteria, ...tableFilterValue };
      setCriteria(newCriteria);
      updateUrl(newCriteria);
      setIsFilterModalVisible(false);
    },
    [criteria, updateUrl]
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof UsersTableFilterDataType) => {
      const newCriteria = { ...criteria };

      newCriteria[filterDataKey] = initialCriteriaData[
        filterDataKey
      ] as string & string[] & boolean;

      handleTableFilterSubmit(newCriteria);
    },
    [criteria, handleTableFilterSubmit]
  );

  const navigateToEditUserPermissions = useCallback(
    (user: User) => {
      navigate(`/users/${user.id}/edit`);
    },
    [navigate]
  );

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <>
          <Columns marginless>
            <Columns.Column className="pl-4">
              <h1 className="is-size-3 has-text-weight-light">
                User control panel
              </h1>
            </Columns.Column>
            <CheckPermissions permissions={UserPermissions.USERS_CREATE}>
              <CheckPermissions.Render>
                <Columns.Column
                  display="flex"
                  justifyContent="flex-end"
                  className="gap-3"
                >
                  <Button color="primary-dark" onClick={handleInviteUser}>
                    <Icon>
                      <FaPlus />
                    </Icon>
                    <span>New user</span>
                  </Button>
                </Columns.Column>
              </CheckPermissions.Render>
            </CheckPermissions>
          </Columns>

          <If condition={appliedFilters.length > 0}>
            <FilterTagList
              list={filterTagsData}
              onRemove={(item) =>
                onRemoveFilterTag(item as keyof UsersTableFilterDataType)
              }
              title="Filters"
            />
          </If>
        </>
      }
    >
      <Element>
        <Columns multiline={false}>
          <Columns.Column
            display="flex"
            alignItems="center"
            className="gap-5"
            size={11}
          >
            <Element display="flex" alignItems="center" className="gap-6">
              <span className="is-size-4 has-text-weight-light has-text-primary-dark">
                Internal users
              </span>
              <span className="is-size-6">{response.total} users</span>
            </Element>
          </Columns.Column>
        </Columns>

        <Conditional condition={navigation.state === 'loading'}>
          <Conditional.True>
            <PageSpinner />
          </Conditional.True>
          <Conditional.False>
            <UsersTable
              dataSource={tableData}
              config={tableConfig}
              empty={
                <Element
                  display="flex"
                  justifyContent="center"
                  className="my-2 has-text-weight-light"
                >
                  <span>{emptyContentText}</span>
                </Element>
              }
              onChange={handleOnTableChange}
              form={{
                extra: (
                  <Button
                    type="button"
                    color="primary-dark"
                    className="ml-2"
                    outlined={appliedFilters.length === 0}
                    onClick={() => setIsFilterModalVisible(true)}
                  >
                    <Icon>
                      <FaFilter />
                    </Icon>
                    <span>
                      Filter
                      {appliedFilters.length > 0 &&
                        `(${appliedFilters.length})`}
                    </span>
                  </Button>
                ),
                itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
              }}
              handleDisableUser={handleDisableUser}
              handleEditUserPermissions={handleEditUserPermissions}
              handleUserTemporaryPermissionsClick={
                navigateToEditUserPermissions
              }
              loading={isLoading}
            />
          </Conditional.False>
        </Conditional>

        <UsersControlPanelFilterModal
          show={isFilterModalVisible}
          onClose={() => setIsFilterModalVisible(false)}
          data={criteria}
          onSubmit={handleTableFilterSubmit}
          viewMode={criteria.viewMode}
        />
      </Element>
    </BoxLayout>
  );
}

export function UsersControlPanelPage() {
  const { response } = useLoaderData() as DeferredResponse<
    PaginatedAPIResponse<User>
  >;

  const Skeleton = () => (
    <BoxLayout
      className={`is-min-height-80 mx-10 my-4`}
      header={
        <Columns marginless>
          <Columns.Column className="pl-4">
            <h1 className="is-size-3 has-text-weight-light">
              User control panel
            </h1>
          </Columns.Column>
        </Columns>
      }
    >
      <PageSpinner />
    </BoxLayout>
  );

  return (
    <ProtectedPage
      permissions={UserPermissions.USERS_READ}
      allowedPermissionGroups={ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS}
    >
      <Suspense fallback={<Skeleton />}>
        <ErrorHandlingAwait resolve={response}>
          <UsersControlPanelPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default UsersControlPanelPage;
