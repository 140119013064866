import { NewUser, User } from '@zspace/types';
import { AxiosError, HttpStatusCode } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Section } from 'react-bulma-components';
import { FaCheck } from 'react-icons/fa6';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import CancelAlertModal from '../../shared/cancel-alert-modal/cancel-alert-modal';
import useIsCustomerUser from '../../shared/hooks/is-customer-user';
import useIsZspaceInternalUser from '../../shared/hooks/is-zspace-internal-user';
import useUser from '../../shared/hooks/user';
import { extractEmailDomain, isEmail } from '../../shared/utils';
import Button from '../../ui/button/button';
import {
  InviteUserPageContext,
  InviteUserPageSteps,
} from '../invite-user-page/types';
import UserInvitedConfirmationModal from '../user-invited-confirmation-modal/user-invited-confirmation-modal';
import { fetchEmailAvailability, inviteUser } from '../users-service';
import InviteUserForm, {
  InviteUserFields,
  InviteUserFormData,
  InviteUserFormValidation,
} from './invite-user-form/invite-user-form';
import UserAlreadyExistsModal from './user-already-exists-modal/user-already-exists-modal';

const INVALID_EMAIL_ERROR = 'Invalid format';
const EMAIL_MISMATCH_ERROR = 'Emails don’t match';
const EMAIL_ALREADY_IN_USE_ERROR = 'This email is already in use';
const CURRENT_USER_EMAIL_PROVIDED_ERROR =
  'The email you provided is your current email';
const INVALID_EMAIL_DOMAIN_ERROR =
  'The email you provided does not have a valid domain';
const INVITE_USER_ERROR = 'An error occurred while inviting the user';
const MISSING_ROLE_ERROR = 'Please select a role';
const CANCEL_ALERT_MODAL_TITLE = 'Cancel user invite';
const CANCEL_ALERT_MODAL_SUBTITLE =
  'Are you sure you want to cancel the user invite process?';
const CANCEL_BUTTON_TEXT = 'Cancel';
const INVITE_BUTTON_TEXT = 'Invite user';
const CONTINUE_BUTTON_TEXT = 'Continue';

const customerFields = ['email', 'emailConfirmation'];
const zspaceStaffAndPartnerFields = ['email', 'permissionsGroup'];

export function InviteUserDetailsPage() {
  const navigate = useNavigate();
  const { user } = useUser();
  const isCustomerUser = useIsCustomerUser();
  const isZspaceInternalUser = useIsZspaceInternalUser();
  const {
    email,
    setEmail,
    setStep,
    setTitle,
    navigateToPermissionsHome,
    internalViewMode,
  } = useOutletContext<InviteUserPageContext>();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showExistingUserModal, setShowExistingUserModal] =
    useState<boolean>(false);
  const [existingUser, setExistingUser] = useState<User | null>(null);
  const [showCancelAlertModal, setShowCancelAlertModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [inviteError, setInviteError] = useState<string | null>(null);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const allowedInternalEmailDomains = useMemo(
    () => environment.internalUserEmailDomains.split(','),
    []
  );

  useEffect(() => {
    setStep(InviteUserPageSteps.EMAIL);
    setTitle(`Invite new user`);
  }, [email, setEmail, setStep, setTitle]);

  const validateForm: InviteUserFormValidation = useMemo(
    () => ({
      email: (value: string) => {
        if (!value || !isEmail(value)) {
          return INVALID_EMAIL_ERROR;
        }
        if (value === user?.email) {
          return CURRENT_USER_EMAIL_PROVIDED_ERROR;
        }
        const emailDomain = extractEmailDomain(value) as string;
        if (
          isZspaceInternalUser &&
          !allowedInternalEmailDomains.includes(emailDomain)
        ) {
          return INVALID_EMAIL_DOMAIN_ERROR;
        }

        return null;
      },
      emailConfirmation: (value: string) => {
        if (!value || !isEmail(value)) {
          return INVALID_EMAIL_ERROR;
        }
        return null;
      },
      permissionsGroup: (value: string) => {
        if (!value) {
          return MISSING_ROLE_ERROR;
        }
        return null;
      },
    }),
    [allowedInternalEmailDomains, isZspaceInternalUser, user?.email]
  );

  const initialFormData: InviteUserFormData = useMemo(() => {
    return {
      email: { value: email, touched: false, error: null },
      emailConfirmation: { value: email, touched: false, error: null },
      permissionsGroup: { value: '', touched: false, error: null },
    };
  }, [email]);

  const [formData, setFormData] = useState<InviteUserFormData>(initialFormData);

  const isCustomerInvite = useMemo(
    () => isCustomerUser || (!isCustomerUser && !internalViewMode),
    [internalViewMode, isCustomerUser]
  );

  const submitButtonText = useMemo(() => {
    if (isCustomerInvite) {
      return CONTINUE_BUTTON_TEXT;
    } else {
      return INVITE_BUTTON_TEXT;
    }
  }, [isCustomerInvite]);

  const showEmailMismatchError = useMemo(() => {
    const hasInputValues =
      formData['email'].touched && formData['emailConfirmation'].touched;
    const hasValidValues =
      !formData['email'].error && !formData['emailConfirmation'].error;
    if (hasInputValues && hasValidValues) {
      return formData['email'].value !== formData['emailConfirmation'].value;
    }
    return false;
  }, [formData]);

  const formError = useMemo(() => {
    if (showEmailMismatchError) {
      return EMAIL_MISMATCH_ERROR;
    }
    return inviteError;
  }, [showEmailMismatchError, inviteError]);

  const navigateToUserPermissionsSetup = useCallback(() => {
    navigate('/users/my-team/invite/permissions');
  }, [navigate]);

  const navigateToEditUser = useCallback(
    (user: User) => {
      navigate(`/users/my-team/${user.id}/edit`);
    },
    [navigate]
  );

  const handleInviteUserError = useCallback((error: AxiosError) => {
    setLoading(false);
    if (error.response?.status === HttpStatusCode.Conflict) {
      return setFormData((prev) => ({
        ...prev,
        email: { ...prev.email, error: EMAIL_ALREADY_IN_USE_ERROR },
      }));
    }
    setInviteError(INVITE_USER_ERROR);
  }, []);

  const validateData = useCallback(() => {
    const fieldsToValidate = Object.keys(formData).filter((formDataKey) => {
      if (isCustomerInvite) {
        return customerFields.includes(formDataKey);
      } else {
        return zspaceStaffAndPartnerFields.includes(formDataKey);
      }
    });

    return fieldsToValidate.reduce(
      (acc, key) => {
        const formDataKey = key as keyof InviteUserFields;
        const error = validateForm[formDataKey]?.(formData[formDataKey].value);
        setFormData((prev) => ({
          ...prev,
          [formDataKey]: { ...prev[formDataKey], error, touched: true },
        }));
        return {
          values: { ...acc.values, [key]: formData[formDataKey].value },
          errors: { ...acc.errors, [key]: error },
        };
      },
      { values: {}, errors: {} }
    );
  }, [formData, isCustomerInvite, validateForm]);

  const handleEditPermissionsClick = useCallback(() => {
    navigateToEditUser(existingUser as User);
  }, [existingUser, navigateToEditUser]);

  const handleInviteUser = useCallback(async () => {
    setInviteError(null);
    setSubmitButtonPressed(true);
    const formValidation = validateData();
    const isValidForm = Object.values(formValidation.errors).every(
      (fieldError) => !fieldError
    );
    if (isValidForm) {
      try {
        const newUser: NewUser = {
          email: formData.email.value,
          permissionsGroup: formData.permissionsGroup.value,
        };

        setLoading(true);
        await inviteUser(newUser);
        setLoading(false);
        setShowConfirmationModal(true);
      } catch (error) {
        handleInviteUserError(error as AxiosError);
      }
    }
  }, [
    formData.email.value,
    formData.permissionsGroup.value,
    handleInviteUserError,
    validateData,
  ]);

  const handleValidateEmailAvailability = useCallback(async () => {
    setInviteError(null);
    setSubmitButtonPressed(true);

    const formValidation = validateData();
    const isValidForm =
      Object.values(formValidation.errors).every((fieldError) => !fieldError) &&
      !showEmailMismatchError;

    if (isValidForm) {
      setLoading(true);

      const emailAvailabilityResponse = await fetchEmailAvailability(
        formData.email.value
      );

      setLoading(false);
      setEmail(formData.email.value);

      if (emailAvailabilityResponse.available) {
        navigateToUserPermissionsSetup();
      } else {
        setShowExistingUserModal(true);
        setExistingUser(emailAvailabilityResponse.user as User);
      }
    }
  }, [
    formData.email.value,
    navigateToUserPermissionsSetup,
    setEmail,
    showEmailMismatchError,
    validateData,
  ]);

  const handleInviteAnotherUser = useCallback(() => {
    setFormData(initialFormData);
    setShowConfirmationModal(false);
    setSubmitButtonPressed(false);
  }, [initialFormData]);

  return (
    <>
      <Section paddingless p={4}>
        <InviteUserForm
          data={formData}
          onChangeData={setFormData}
          validate={validateForm}
          formError={formError}
          submitButtonPressed={submitButtonPressed}
          isCustomerInvite={isCustomerInvite}
        />
      </Section>
      <Section
        paddingless
        display="flex"
        justifyContent={isCustomerInvite ? 'flex-start' : 'flex-end'}
        alignItems={isCustomerInvite ? 'flex-start' : 'flex-end'}
        className="gap-6 is-flex-grow-1 pt-0 pl-4"
      >
        <Button
          color="primary-dark"
          outlined
          onClick={() => setShowCancelAlertModal(true)}
        >
          {CANCEL_BUTTON_TEXT}
        </Button>
        <Button
          color="primary-dark"
          onClick={
            isCustomerInvite
              ? handleValidateEmailAvailability
              : handleInviteUser
          }
          isExecutingAction={loading}
        >
          <Button.LoadingIcon icon={FaCheck} />
          <span>{submitButtonText}</span>
        </Button>
      </Section>

      <UserInvitedConfirmationModal
        show={showConfirmationModal}
        email={formData.email.value}
        onInviteAnotherUser={handleInviteAnotherUser}
        navigateToPermissionsHome={navigateToPermissionsHome}
      />
      <UserAlreadyExistsModal
        show={showExistingUserModal}
        onEditPermissions={handleEditPermissionsClick}
        onCancel={() => setShowExistingUserModal(false)}
      />

      <CancelAlertModal
        show={showCancelAlertModal}
        title={CANCEL_ALERT_MODAL_TITLE}
        subtitle={CANCEL_ALERT_MODAL_SUBTITLE}
        onCancel={navigateToPermissionsHome}
        onClose={() => setShowCancelAlertModal(false)}
      />
    </>
  );
}

export default InviteUserDetailsPage;
