import { SVGProps } from 'react';
const UserGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#0A0A0A"
        d="M8.4 14.6a4.8 4.8 0 1 0 0-9.6 4.8 4.8 0 0 0 0 9.6Zm-1.714 1.8A6.685 6.685 0 0 0 0 23.086c0 .615.499 1.114 1.114 1.114h14.572c.615 0 1.114-.499 1.114-1.114a6.685 6.685 0 0 0-6.686-6.686H6.686Z"
      />
      <path
        fill="#267ABF"
        d="M22.849 24.2h-5.172c.203-.352.323-.761.323-1.2v-.3a7.482 7.482 0 0 0-2.618-5.692c.09-.004.177-.008.267-.008h2.302A6.048 6.048 0 0 1 24 23.049c0 .637-.518 1.151-1.151 1.151Zm-6.65-9.6a4.191 4.191 0 0 1-2.973-1.234A5.97 5.97 0 0 0 14.4 9.8a5.96 5.96 0 0 0-.686-2.786A4.198 4.198 0 0 1 16.2 6.2c2.321 0 4.2 1.879 4.2 4.2s-1.879 4.2-4.2 4.2Z"
        opacity={0.4}
      />
      <path
        fill="#0A0A0A"
        d="M19.627.917a.524.524 0 0 0 .039-.742.524.524 0 0 0-.742-.04l-1.751 1.577a.526.526 0 0 0 0 .78l1.751 1.576a.524.524 0 0 0 .742-.04.524.524 0 0 0-.04-.742L18.31 2.104 19.627.917Zm1.748 0 1.316 1.187-1.318 1.186a.524.524 0 0 0-.039.742.524.524 0 0 0 .742.04l1.751-1.576a.526.526 0 0 0 0-.78L22.076.14a.524.524 0 0 0-.742.04.524.524 0 0 0 .04.741l.002-.004Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default UserGroup;
