import { useMemo } from 'react';
import { Element, Icon, Tag } from 'react-bulma-components';
import { FaXmark } from 'react-icons/fa6';
import Button from '../button/button';
import styles from './filter-tag-list.module.scss';

export type FilterTagList = Record<
  string,
  {
    label?: string;
    value: string;
  }
>;

export type FilterTagListProps = {
  list: FilterTagList;
  title?: string;
  onRemove?: (item: string) => void;
};

export function FilterTagList({ list, title, onRemove }: FilterTagListProps) {
  const appliedFilters = useMemo(() => {
    return Object.entries(list).filter(
      ([_, { label, value }]) => value.length > 0
    );
  }, [list]);

  return (
    <Element
      display="flex"
      flexWrap="wrap"
      className="w-full"
      alignItems="center"
      style={{
        opacity: appliedFilters.length > 0 ? 1 : 0,
        gap: '0.5rem 1.25rem',
      }}
    >
      <span>{title}:</span>

      {appliedFilters.map(([key, { label, value }], index) => (
        <Tag.Group key={key} marginless>
          <Button
            className="is-transparent"
            text
            paddingless
            mr={2}
            style={{ boxShadow: 'none', height: 'auto' }}
            onClick={() => {
              onRemove?.(key);
            }}
          >
            <Icon color="primary-dark">
              <FaXmark />
            </Icon>
          </Button>

          <span className={styles.tagLabel}>
            {label ? `${label}: ${value}` : value}
          </span>
        </Tag.Group>
      ))}
    </Element>
  );
}

export default FilterTagList;
