import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export type UseUpdateUrl = (data: object) => void;

export function useUpdateUrl(): UseUpdateUrl {
  const setSearchParams = useSearchParams()[1];

  const updateUrl = useCallback(
    (data: object) => {
      setSearchParams({
        ...data,
      });
    },
    [setSearchParams]
  );

  return updateUrl;
}

export default useUpdateUrl;
