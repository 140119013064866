import { capitalizeString } from '@zspace/format';
import {
  ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS,
  DeviceGroupPermissions,
  SoftwareSeatPermissions,
  WorkOrderPermissions,
} from '@zspace/roles';
import {
  AllYesNoFilter,
  DeferredResponse,
  Device,
  DeviceGroup,
  DeviceGroupsCriteria,
  DeviceGroupsCriteriaFilterDataType,
  DeviceModel,
  FilterType,
  HardwareModel,
  PaginatedAPIResponse,
  SortDirection,
  WorkOrderDetailPageContext,
  WorkOrderStatus,
} from '@zspace/types';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Box, Columns, Element, Icon, Section } from 'react-bulma-components';
import { FaArrowRight, FaCheck, FaMinus, FaPen, FaPlus } from 'react-icons/fa6';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { DeviceForSoftwareAssignmentStorageService } from '../../device-groups/device-for-software-assignment-storage-service';
import DeviceGroupConfirmDeletionModal from '../../device-groups/device-group-confirm-deletion-modal/device-group-confirm-deletion-modal';
import DeviceGroupInboxTable from '../../device-groups/device-group-inbox-table/device-group-inbox-table';
import SelectedDeviceTagList from '../../device-groups/device-group-table/selected-device-tag-list/selected-device-tag-list';
import { deleteDeviceGroupById } from '../../device-groups/device-groups-service';
import { SoftwareTitlesForAssignmentStorageService } from '../../device-groups/software-titles-for-assignment-storage-service';
import { fetchDeviceModels } from '../../devices/devices-service';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import Conditional from '../../shared/conditional/conditional';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import useIsEmpty from '../../shared/hooks/is-empty';
import usePermissions from '../../shared/hooks/permissions';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import FeedbackMessage from '../../ui/feedback-message/feedback-message';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import Sticky from '../../ui/sticky/sticky';
import {
  fetchWorkOrderDeviceGroups as fetchWorkOrderDeviceGroupsRequest,
  sendWorkOrderToQueue,
} from '../work-orders-service';
import styles from './device-groups-dashboard-card-page.module.scss';

const devicesStorage = new DeviceForSoftwareAssignmentStorageService();
const softwareStorage = new SoftwareTitlesForAssignmentStorageService();

const FETCH_DEVICES_ERROR_MESSAGE =
  'Work order devices could not be fetched. Please try again';
const SEND_WORK_ORDER_TO_QUEUE_ERROR_MESSAGE =
  'The work order could not be sent to the queue. Please try again';
const MULTIPLE_DEVICE_TYPES_ERROR_MESSAGE =
  'You can only manage one device type at a time';
const NO_DEVICE_SELECTED_ERROR_MESSAGE =
  'You must select at least one device to manage software';

const initialDeviceGroupsCriteria: DeviceGroupsCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
  deviceName: '',
  deviceNameFilter: FilterType.CONTAINS,
  deviceType: HardwareModel.ALL,
  softwareAssigned: AllYesNoFilter.ALL,
  softwareTitle: '',
  softwareTitleFilter: FilterType.CONTAINS,
  salesOrders: [],
  serialNumber: '',
  serialNumberFilter: FilterType.CONTAINS,
  excludeEmptyGroups: true,
};

export const loader: LoaderFunction = async ({ params }) => {
  const { id } = params;

  const deviceGroups = fetchWorkOrderDeviceGroupsRequest(
    id as string,
    initialDeviceGroupsCriteria
  );
  const deviceModels = fetchDeviceModels();

  const response = Promise.all([deviceGroups, deviceModels]);

  return defer({ response });
};

export function DeviceGroupsDashboardCardPageContent() {
  const navigate = useNavigate();
  const [deviceGroupsInitialResponse, deviceModels] = useAsyncValue() as [
    PaginatedAPIResponse<DeviceGroup>,
    DeviceModel[]
  ];
  const userHasPermissions = usePermissions();
  const { id } = useParams();
  const { workOrder, revalidate } =
    useOutletContext<WorkOrderDetailPageContext>();
  const { executeHttpRequest, isLoading: isSendingWorkOrderToQueue } =
    useHttpRequest();
  const [deviceGroupsDataResponse, setDeviceGroupsDataResponse] = useState<
    PaginatedAPIResponse<DeviceGroup>
  >(deviceGroupsInitialResponse);
  const { executeHttpRequest: executeFetchMoreWorkOrderDeviceGroups } =
    useHttpRequest();
  const {
    executeHttpRequest: executeFetchWorkOrderDeviceGroups,
    isLoading: isLoadingWorkOrderDeviceGroups,
  } = useHttpRequest();
  const {
    executeHttpRequest: executeDeviceGroupDeletion,
    isLoading: isDeletingDeviceGroup,
  } = useHttpRequest();
  const [deviceGroupsFilterCriteria, setDeviceGroupsFilterCriteria] =
    useState<DeviceGroupsCriteria>(initialDeviceGroupsCriteria);
  const isDataEmpty = useIsEmpty(deviceGroupsInitialResponse.data);
  const [deviceGroups, setDeviceGroups] = useState<DeviceGroup[]>(
    deviceGroupsInitialResponse.data
  );
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<DeviceGroup>();
  const [displayDeleteConfirmationModal, setDisplayDeleteConfirmationModal] =
    useState(false);
  const [manageSoftwareButtonPressed, setManageSoftwareButtonPressed] =
    useState(false);

  const hasMoreDeviceGroups = useMemo(
    () => !!deviceGroupsDataResponse.hasMore,
    [deviceGroupsDataResponse]
  );

  const canBeSentToQueue = useMemo(() => {
    return workOrder.status === WorkOrderStatus.DRAFT;
  }, [workOrder.status]);

  const filterTagsData = useMemo(() => {
    return {
      deviceType: {
        label: 'Device type',
        value:
          deviceGroupsFilterCriteria.deviceType !== HardwareModel.ALL
            ? capitalizeString(deviceGroupsFilterCriteria.deviceType)
            : '',
      },
      softwareAssigned: {
        label: 'Has software assigned',
        value:
          deviceGroupsFilterCriteria.softwareAssigned !== AllYesNoFilter.ALL
            ? capitalizeString(deviceGroupsFilterCriteria.softwareAssigned)
            : '',
      },
      softwareTitle: {
        label: 'Assigned software title',
        value: deviceGroupsFilterCriteria.softwareTitle.length
          ? deviceGroupsFilterCriteria.softwareTitleFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', deviceGroupsFilterCriteria.softwareTitle)
          : '',
      },
      deviceName: {
        label: 'Device name',
        value: deviceGroupsFilterCriteria.deviceName
          ? deviceGroupsFilterCriteria.deviceNameFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', deviceGroupsFilterCriteria.deviceName)
          : '',
      },
      salesOrders: {
        label: 'Sales order #',
        value: deviceGroupsFilterCriteria.salesOrders.join(', '),
      },
      serialNumber: {
        label: 'Serial number',
        value: deviceGroupsFilterCriteria.serialNumber
          ? deviceGroupsFilterCriteria.serialNumberFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', deviceGroupsFilterCriteria.serialNumber)
          : '',
      },
    };
  }, [
    deviceGroupsFilterCriteria.deviceName,
    deviceGroupsFilterCriteria.deviceNameFilter,
    deviceGroupsFilterCriteria.deviceType,
    deviceGroupsFilterCriteria.salesOrders,
    deviceGroupsFilterCriteria.serialNumber,
    deviceGroupsFilterCriteria.serialNumberFilter,
    deviceGroupsFilterCriteria.softwareAssigned,
    deviceGroupsFilterCriteria.softwareTitle,
    deviceGroupsFilterCriteria.softwareTitleFilter,
  ]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const showNoDeviceSelectedError = useMemo(
    () => selectedDevices.length === 0,
    [selectedDevices]
  );

  const showMultipleDeviceModelsError = useMemo(() => {
    if (selectedDevices.length === 0) return false;

    const deviceModels = selectedDevices.map((device) => device.model.id);
    return deviceModels.some((deviceModel) => deviceModel !== deviceModels[0]);
  }, [selectedDevices]);

  const showErrorText = useMemo(
    () =>
      (showMultipleDeviceModelsError || showNoDeviceSelectedError) &&
      manageSoftwareButtonPressed,
    [
      showMultipleDeviceModelsError,
      showNoDeviceSelectedError,
      manageSoftwareButtonPressed,
    ]
  );

  const errorText = useMemo(() => {
    if (showMultipleDeviceModelsError) {
      return MULTIPLE_DEVICE_TYPES_ERROR_MESSAGE;
    } else if (showNoDeviceSelectedError) {
      return NO_DEVICE_SELECTED_ERROR_MESSAGE;
    }
  }, [showMultipleDeviceModelsError, showNoDeviceSelectedError]);

  const showFooter = useMemo(
    () =>
      userHasPermissions({
        permissions: WorkOrderPermissions.WORK_ORDERS_CREATE,
        allowedPermissionGroups: ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS,
      }) ||
      userHasPermissions({
        permissions: SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN,
        allowedPermissionGroups: ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS,
      }),
    [userHasPermissions]
  );

  const disableDevicesSelection = useMemo(
    () =>
      !userHasPermissions({
        permissions: SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN,
        allowedPermissionGroups: ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS,
      }),
    [userHasPermissions]
  );

  const navigateToDeviceGroupCreation = useCallback(() => {
    navigate(`/work-orders/${id}/device-groups/create`);
  }, [id, navigate]);

  const handleOnManageSoftware = useCallback(() => {
    setManageSoftwareButtonPressed(true);
    if (!showNoDeviceSelectedError && !showMultipleDeviceModelsError) {
      devicesStorage.save(selectedDevices);
      softwareStorage.remove();

      const modelId = selectedDevices[0].model.id;
      navigate(
        `/work-orders/${id}/device-groups/device-models/${modelId}/software/select`
      );
    }
  }, [
    id,
    navigate,
    selectedDevices,
    showMultipleDeviceModelsError,
    showNoDeviceSelectedError,
  ]);

  const onDeleteDeviceGroup = useCallback(() => {
    setDisplayDeleteConfirmationModal(true);
  }, []);

  const handleDeviceGroupDeletion = useCallback(
    () =>
      executeDeviceGroupDeletion({
        asyncFunction: async () => {
          if (selectedDeviceGroup) {
            await deleteDeviceGroupById(selectedDeviceGroup.id);
            setDisplayDeleteConfirmationModal(false);
            revalidate();
          }
        },
      }),
    [executeDeviceGroupDeletion, revalidate, selectedDeviceGroup]
  );

  const onEditDeviceGroup = useCallback(() => {
    if (selectedDeviceGroup) {
      navigate(
        `/work-orders/${id}/device-groups/${selectedDeviceGroup.id}/edit`
      );
    }
  }, [id, navigate, selectedDeviceGroup]);

  const handleExitClick = useCallback(() => {
    navigate('/work-orders/dashboard/drafts');
  }, [navigate]);

  const handleSendToQueueClick = useCallback(
    async () =>
      executeHttpRequest({
        asyncFunction: async () => {
          await sendWorkOrderToQueue(id!);
          navigate('/work-orders/dashboard/queue');
        },
        customErrorMessage: SEND_WORK_ORDER_TO_QUEUE_ERROR_MESSAGE,
      }),
    [executeHttpRequest, id, navigate]
  );

  const fetchMoreDeviceGroups = useCallback(
    () =>
      executeFetchMoreWorkOrderDeviceGroups({
        asyncFunction: async () => {
          const nextPage = deviceGroupsFilterCriteria.pageNumber + 1;
          const updatedDeviceGroupCriteria = {
            ...deviceGroupsFilterCriteria,
            pageNumber: nextPage,
          };
          const workOrderDeviceGroupsResponse =
            await fetchWorkOrderDeviceGroupsRequest(
              id as string,
              updatedDeviceGroupCriteria
            );
          setDeviceGroupsFilterCriteria(updatedDeviceGroupCriteria);
          setDeviceGroups((prevDeviceGroups) =>
            prevDeviceGroups.concat(workOrderDeviceGroupsResponse.data)
          );
          setDeviceGroupsDataResponse(workOrderDeviceGroupsResponse);
        },
      }),
    [deviceGroupsFilterCriteria, executeFetchMoreWorkOrderDeviceGroups, id]
  );

  const fetchWorkOrderDeviceGroups = useCallback(
    (deviceGroupsFilterCriteria: DeviceGroupsCriteria) =>
      executeFetchWorkOrderDeviceGroups({
        asyncFunction: async () => {
          const workOrderDeviceGroupsResponse =
            await fetchWorkOrderDeviceGroupsRequest(
              id as string,
              deviceGroupsFilterCriteria
            );
          setDeviceGroups(workOrderDeviceGroupsResponse.data);
        },
        customErrorMessage: FETCH_DEVICES_ERROR_MESSAGE,
      }),
    [executeFetchWorkOrderDeviceGroups, id]
  );

  const handleManageDeviceClick = useCallback(
    (newDevices: Device[], add: boolean) => {
      const userHasDeviceGroupUpdatePermissions = userHasPermissions({
        permissions: DeviceGroupPermissions.DEVICE_GROUPS_UPDATE,
        allowedPermissionGroups: ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS,
      });

      if (userHasDeviceGroupUpdatePermissions) {
        setSelectedDevices((devices) => {
          if (add) {
            const updatedDevices = [...devices, ...newDevices];
            return updatedDevices.filter(
              (device, index) =>
                index === updatedDevices.findIndex((d) => d.id === device.id)
            );
          } else {
            const newDevicesId = newDevices.map((device) => device.id);
            return devices.filter(
              (device) => !newDevicesId.includes(device.id)
            );
          }
        });
      }
    },
    [userHasPermissions]
  );

  const handleTableFilterSubmit = useCallback(
    async (value: DeviceGroupsCriteriaFilterDataType) => {
      const { itemsPerPage, pageNumber } = initialDeviceGroupsCriteria;
      const updatedCriteria = {
        ...value,
        itemsPerPage,
        pageNumber,
      } as DeviceGroupsCriteria;
      setDeviceGroupsFilterCriteria(updatedCriteria);
      await fetchWorkOrderDeviceGroups(updatedCriteria);
    },
    [fetchWorkOrderDeviceGroups]
  );

  const isInvalidSelection = useCallback(
    (devices: Device[]) => {
      let isUniqueDeviceModelSelected = true;

      devices.forEach((device) => {
        isUniqueDeviceModelSelected =
          isUniqueDeviceModelSelected &&
          !selectedDevices.some(
            (selectedDevice) =>
              selectedDevice.id === device.id &&
              selectedDevices.some(
                (device) => device.model.id !== selectedDevice.model.id
              )
          );
      });

      return !isUniqueDeviceModelSelected;
    },
    [selectedDevices]
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof DeviceGroupsCriteria) => {
      const newData = { ...deviceGroupsFilterCriteria };
      newData[filterDataKey] = initialDeviceGroupsCriteria[
        filterDataKey
      ] as string & string[] & boolean;
      handleTableFilterSubmit(newData);
    },
    [deviceGroupsFilterCriteria, handleTableFilterSubmit]
  );

  const handleCancelDeviceGroupDeletion = useCallback(() => {
    setDisplayDeleteConfirmationModal(false);
  }, []);

  const Header = useCallback(
    () => (
      <Element className="p-4">
        <Columns marginless>
          <Columns.Column>
            <h1 className="is-size-3 has-text-weight-light">Device groups</h1>
          </Columns.Column>
          <Columns.Column
            display="flex"
            justifyContent="flex-end"
            className="gap-3"
          >
            <CheckPermissions
              permissions={DeviceGroupPermissions.DEVICE_GROUPS_CREATE}
              allowedPermissionGroups={ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS}
            >
              <CheckPermissions.Render>
                <Button
                  color="primary-dark"
                  onClick={navigateToDeviceGroupCreation}
                >
                  <Icon>
                    <FaPlus />
                  </Icon>
                  <span>Create device group</span>
                </Button>
              </CheckPermissions.Render>
            </CheckPermissions>
            <CheckPermissions
              permissions={SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN}
              allowedPermissionGroups={ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS}
            >
              <CheckPermissions.Render>
                <Button
                  className="outlined-button-white-background"
                  color="primary-dark"
                  outlined
                  onClick={handleOnManageSoftware}
                >
                  <span>Manage software</span>
                  <Icon>
                    <FaArrowRight />
                  </Icon>
                </Button>
              </CheckPermissions.Render>
            </CheckPermissions>
          </Columns.Column>
        </Columns>
        <If condition={appliedFilters.length > 0}>
          <Element ml={3}>
            <FilterTagList
              list={filterTagsData}
              onRemove={(item) =>
                onRemoveFilterTag(item as keyof DeviceGroupsCriteria)
              }
              title="Filters"
            />
          </Element>
        </If>
      </Element>
    ),
    [
      appliedFilters.length,
      filterTagsData,
      handleOnManageSoftware,
      navigateToDeviceGroupCreation,
      onRemoveFilterTag,
    ]
  );

  const EmptyState = useCallback(
    () => (
      <Section
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingless
      >
        <span className="mb-3 has-text-weight-light is-size-5">
          No device groups yet
        </span>
        <CheckPermissions
          permissions={DeviceGroupPermissions.DEVICE_GROUPS_CREATE}
          allowedPermissionGroups={ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS}
        >
          <CheckPermissions.Render>
            <span className="is-size-6 mb-5">
              You need to group your devices in order to move forward
            </span>
            <Button
              color="primary-dark"
              onClick={navigateToDeviceGroupCreation}
            >
              <Icon>
                <FaPlus />
              </Icon>
              <span>Create device group</span>
            </Button>
          </CheckPermissions.Render>
        </CheckPermissions>
      </Section>
    ),
    [navigateToDeviceGroupCreation]
  );

  const Footer = useCallback(
    () => (
      <Box
        display="flex"
        justifyContent="center"
        py={4}
        className={styles.footer}
      >
        <CheckPermissions
          permissions={SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN}
          allowedPermissionGroups={ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS}
        >
          <CheckPermissions.Render>
            <Button color="primary-dark" outlined onClick={handleExitClick}>
              Save & exit
            </Button>
          </CheckPermissions.Render>
        </CheckPermissions>
        <CheckPermissions
          permissions={WorkOrderPermissions.WORK_ORDERS_CREATE}
          allowedPermissionGroups={ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS}
        >
          <CheckPermissions.Render>
            <If condition={canBeSentToQueue}>
              <Button
                color="primary-dark"
                onClick={handleSendToQueueClick}
                isExecutingAction={isSendingWorkOrderToQueue}
              >
                <Button.LoadingIcon icon={FaCheck} />
                <span>Send to queue</span>
              </Button>
            </If>
          </CheckPermissions.Render>
        </CheckPermissions>
      </Box>
    ),
    [
      canBeSentToQueue,
      handleExitClick,
      handleSendToQueueClick,
      isSendingWorkOrderToQueue,
    ]
  );

  const DeviceGroupActions = useCallback(
    () => (
      <If condition={!!selectedDeviceGroup}>
        <Element display="flex" className="gap-2">
          <CheckPermissions
            permissions={DeviceGroupPermissions.DEVICE_GROUPS_UPDATE}
            allowedPermissionGroups={ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS}
          >
            <CheckPermissions.Render>
              <Button
                color="transparent"
                className="is-borderless"
                onClick={onEditDeviceGroup}
              >
                <Icon color="primary-dark">
                  <FaPen />
                </Icon>
                <span className="is-underlined has-text-primary-dark">
                  Edit device group
                </span>
              </Button>
            </CheckPermissions.Render>
          </CheckPermissions>
          <CheckPermissions
            permissions={DeviceGroupPermissions.DEVICE_GROUPS_DELETE}
            allowedPermissionGroups={ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS}
          >
            <CheckPermissions.Render>
              <Button
                color="transparent"
                className="is-borderless"
                onClick={onDeleteDeviceGroup}
              >
                <Icon color="primary-dark">
                  <FaMinus />
                </Icon>
                <span className="is-underlined has-text-primary-dark">
                  Delete device group
                </span>
              </Button>
            </CheckPermissions.Render>
          </CheckPermissions>
        </Element>
      </If>
    ),
    [onDeleteDeviceGroup, onEditDeviceGroup, selectedDeviceGroup]
  );

  return (
    <>
      <BoxLayout className="is-min-height-80 mx-10 my-4" header={<Header />}>
        <Conditional condition={isDataEmpty}>
          <Conditional.True>
            <EmptyState />
          </Conditional.True>
          <Conditional.False>
            <If condition={showErrorText}>
              <Element mb={2}>
                <FeedbackMessage>{errorText}</FeedbackMessage>
              </Element>
            </If>
            <SelectedDeviceTagList
              selectedDevices={selectedDevices}
              onManageDeviceClick={handleManageDeviceClick}
              isInvalidSelection={isInvalidSelection}
            />
            <DeviceGroupInboxTable
              deviceGroups={deviceGroups}
              fetchMoreDeviceGroups={fetchMoreDeviceGroups}
              hasMoreDeviceGroups={hasMoreDeviceGroups}
              onManageDeviceClick={handleManageDeviceClick}
              selectedDevices={selectedDevices}
              loadingInitialData={isLoadingWorkOrderDeviceGroups}
              onTableFilterSubmit={handleTableFilterSubmit}
              deviceGroupsCriteriaFilter={deviceGroupsFilterCriteria}
              deviceModels={deviceModels}
              appliedFilters={appliedFilters}
              isInvalidSelection={isInvalidSelection}
              leftContent={<DeviceGroupActions />}
              onDeviceGroupClick={setSelectedDeviceGroup}
              workOrderId={id}
              disableSelection={disableDevicesSelection}
            />
          </Conditional.False>
        </Conditional>
      </BoxLayout>

      <If condition={!isDataEmpty && showFooter}>
        <Sticky>
          <Footer />
        </Sticky>
      </If>

      <DeviceGroupConfirmDeletionModal
        show={displayDeleteConfirmationModal}
        deviceGroupName={selectedDeviceGroup?.name ?? ''}
        loading={isDeletingDeviceGroup}
        onCancel={handleCancelDeviceGroupDeletion}
        onDelete={handleDeviceGroupDeletion}
      />
    </>
  );
}

export function DeviceGroupsDashboardCardPage() {
  const { response } = useLoaderData() as DeferredResponse<
    [PaginatedAPIResponse<DeviceGroup>, DeviceModel[]]
  >;

  return (
    <ProtectedPage
      permissions={[
        DeviceGroupPermissions.DEVICE_GROUPS_READ,
        SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
      ]}
    >
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <DeviceGroupsDashboardCardPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default DeviceGroupsDashboardCardPage;
