import { capitalizeString } from '@zspace/format';
import {
  ApplicationVariantType,
  DeferredResponse,
  MySoftwareCriteria,
  MySoftwareTab,
  PaginatedAPIResponse,
  SalesOrder,
  SoftwareAvailabilityStatus,
} from '@zspace/types';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  defer,
  LoaderFunction,
  useAsyncValue,
  useLoaderData,
  useNavigation,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import { createSearchParams } from '../../shared/url';
import Button from '../../ui/button/button';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { MySoftwarePageOutletContext } from '../my-software-page/my-software-page';
import SoftwareInboxTable from '../software-inbox-table/software-inbox-table';
import { fetchMySoftwareSalesOrders as fetchMySoftwareSalesOrdersRequest } from '../software-service';

const initialCriteria = (searchParams?: URLSearchParams) => {
  return {
    itemsPerPage: 10,
    pageNumber: 1,
    search: '',
    sortBy: '',
    availabilityStatus: SoftwareAvailabilityStatus.ALL,
    type: ApplicationVariantType.NATIVE,
    salesOrders: searchParams?.get('salesOrders')?.split(',') ?? [],
  } as MySoftwareCriteria;
};

const FETCH_MY_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE =
  'My software could not be fetched. Please try again';

export const loader: LoaderFunction = ({ request }) => {
  const searchParams = createSearchParams(request);
  const response = fetchMySoftwareSalesOrdersRequest(
    initialCriteria(searchParams)
  );

  return defer({ response });
};

export function MySoftwareDeviceBasedTabContent() {
  const { setActiveTab, setFilterTagList } =
    useOutletContext<MySoftwarePageOutletContext>();
  const navigation = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [mySoftwareFilterCriteria, setMySoftwareFilterCriteria] =
    useState<MySoftwareCriteria>(initialCriteria(searchParams));

  const mySoftwareSalesOrdersInitialResponse =
    useAsyncValue() as PaginatedAPIResponse<SalesOrder>;

  const [mySoftwareSalesOrders, setMySoftwareSalesOrders] = useState<
    SalesOrder[]
  >(mySoftwareSalesOrdersInitialResponse.data);
  const [
    mySoftwareSalesOrdersDataResponse,
    setMySoftwareSalesOrdersDataResponse,
  ] = useState<PaginatedAPIResponse<SalesOrder>>(
    mySoftwareSalesOrdersInitialResponse
  );

  const { executeHttpRequest: executeFetchMoreMySoftwareSalesOrdersRequest } =
    useHttpRequest();
  const {
    executeHttpRequest: executeFetchMySoftwareSalesOrdersRequest,
    isLoading: isLoadingMySoftwareSalesOrders,
  } = useHttpRequest();

  const hasAppliedFilters = useMemo(
    () =>
      mySoftwareFilterCriteria.availabilityStatus ===
      SoftwareAvailabilityStatus.AVAILABLE,
    [mySoftwareFilterCriteria.availabilityStatus]
  );

  const isDataLoading = useMemo(
    () => navigation.state === 'loading',
    [navigation.state]
  );

  const hasMoreSalesOrders = useMemo(
    () => !!mySoftwareSalesOrdersDataResponse.hasMore,
    [mySoftwareSalesOrdersDataResponse.hasMore]
  );

  const filterTagsData = useMemo(() => {
    return {
      availabilityStatus: {
        label: 'Availability status',
        value:
          mySoftwareFilterCriteria.availabilityStatus !==
          SoftwareAvailabilityStatus.ALL
            ? capitalizeString(mySoftwareFilterCriteria.availabilityStatus)
            : '',
      },
      salesOrders: {
        label: 'Sales order #',
        value: mySoftwareFilterCriteria.salesOrders.join(', '),
      },
    };
  }, [
    mySoftwareFilterCriteria.availabilityStatus,
    mySoftwareFilterCriteria.salesOrders,
  ]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const fetchMySoftwareSalesOrders = useCallback(
    (mySoftwareFilterCriteria: MySoftwareCriteria) =>
      executeFetchMySoftwareSalesOrdersRequest({
        asyncFunction: async () => {
          const mySoftwareSalesOrdersResponse =
            await fetchMySoftwareSalesOrdersRequest(mySoftwareFilterCriteria);
          setMySoftwareSalesOrders(mySoftwareSalesOrdersResponse.data);
          setMySoftwareSalesOrdersDataResponse(mySoftwareSalesOrdersResponse);
        },
        customErrorMessage: FETCH_MY_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE,
      }),
    [executeFetchMySoftwareSalesOrdersRequest]
  );

  const onAvailableSeatsOnlyFilterToggle = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      let availabilityStatus: SoftwareAvailabilityStatus;

      switch (mySoftwareFilterCriteria.availabilityStatus) {
        case SoftwareAvailabilityStatus.ALL: {
          availabilityStatus = SoftwareAvailabilityStatus.AVAILABLE;
          break;
        }
        case SoftwareAvailabilityStatus.AVAILABLE: {
          availabilityStatus = SoftwareAvailabilityStatus.ALL;
          break;
        }
      }

      event.currentTarget.blur();
      const updatedCriteria = {
        ...initialCriteria(searchParams),
        availabilityStatus,
      };
      setMySoftwareFilterCriteria(updatedCriteria);
      await fetchMySoftwareSalesOrders(updatedCriteria);
    },
    [
      fetchMySoftwareSalesOrders,
      mySoftwareFilterCriteria.availabilityStatus,
      searchParams,
    ]
  );

  const fetchMoreMySoftwareSalesOrders = useCallback(
    () =>
      executeFetchMoreMySoftwareSalesOrdersRequest({
        asyncFunction: async () => {
          const nextPage = mySoftwareFilterCriteria.pageNumber + 1;
          const updatedMySoftwareFilterCriteria = {
            ...mySoftwareFilterCriteria,
            pageNumber: nextPage,
          };
          const mySoftwareSalesOrdersResponse =
            await fetchMySoftwareSalesOrdersRequest(
              updatedMySoftwareFilterCriteria
            );
          setMySoftwareFilterCriteria(updatedMySoftwareFilterCriteria);
          setMySoftwareSalesOrders((prevSalesOrders) =>
            prevSalesOrders.concat(mySoftwareSalesOrdersResponse.data)
          );
          setMySoftwareSalesOrdersDataResponse(mySoftwareSalesOrdersResponse);
        },
        customErrorMessage: FETCH_MY_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE,
      }),
    [executeFetchMoreMySoftwareSalesOrdersRequest, mySoftwareFilterCriteria]
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof MySoftwareCriteria) => {
      const newData = { ...mySoftwareFilterCriteria };
      newData[filterDataKey] = initialCriteria()[filterDataKey] as string &
        string[] &
        boolean;
      setMySoftwareFilterCriteria(newData);
      setSearchParams();
    },
    [mySoftwareFilterCriteria, setSearchParams]
  );

  const availableSeatsFilterButton = useMemo(
    () => (
      <Button
        color="primary-dark"
        outlined={!hasAppliedFilters}
        onClick={onAvailableSeatsOnlyFilterToggle}
      >
        <span>Only software with available seats</span>
      </Button>
    ),
    [hasAppliedFilters, onAvailableSeatsOnlyFilterToggle]
  );

  useEffect(() => {
    setActiveTab(MySoftwareTab.DEVICE_BASED_SOFTWARE);
  }, [setActiveTab]);

  useEffect(() => {
    let filterTagList = null;
    const { salesOrders } = filterTagsData;
    if (appliedFilters.length > 0) {
      filterTagList = (
        <FilterTagList
          list={{ salesOrders }}
          onRemove={(item) =>
            onRemoveFilterTag(item as keyof MySoftwareCriteria)
          }
          title="Filters"
        />
      );
    }
    setFilterTagList(filterTagList);
  }, [setFilterTagList, appliedFilters, filterTagsData, onRemoveFilterTag]);

  useEffect(() => {
    if (mySoftwareSalesOrdersInitialResponse) {
      setMySoftwareSalesOrders(mySoftwareSalesOrdersInitialResponse.data);
      setMySoftwareSalesOrdersDataResponse(
        mySoftwareSalesOrdersInitialResponse
      );
    }
  }, [mySoftwareSalesOrdersInitialResponse]);

  useEffect(() => {
    if (searchParams) {
      const salesOrders = searchParams.get('salesOrders')?.split(',') ?? [];
      setMySoftwareFilterCriteria((prevCriteria) => ({
        ...prevCriteria,
        salesOrders,
      }));
    }
  }, [searchParams]);

  return (
    <SoftwareInboxTable
      salesOrders={mySoftwareSalesOrders}
      fetchMoreSalesOrders={fetchMoreMySoftwareSalesOrders}
      hasMoreSalesOrders={hasMoreSalesOrders}
      leftContent={availableSeatsFilterButton}
      loadingInitialData={isDataLoading || isLoadingMySoftwareSalesOrders}
      mySoftwareCriteriaFilter={mySoftwareFilterCriteria}
      appliedFilters={appliedFilters}
    />
  );
}

export function MySoftwareDeviceBasedTab() {
  const { response } = useLoaderData() as DeferredResponse<
    PaginatedAPIResponse<SalesOrder>
  >;

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <MySoftwareDeviceBasedTabContent />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default MySoftwareDeviceBasedTab;
