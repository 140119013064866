import { Navigate } from 'react-router-dom';
import { RouteObject } from '../app';
import { routes as deviceGroupsRoutes } from '../device-groups/routes';
import { routes as myDevicesRoutes } from '../devices/routes';
import AcceptEulaPage, {
  loader as eulasDataLoader,
} from '../eula/accept-eula-page/accept-eula-page';
import { routes as softwareAssignmentRoutes } from '../software-assignment/routes';
import { routes as mySoftwareRoutes } from '../software/routes';
import EditUserPermissionsPage, {
  loader as editUserPermissionsLoader,
} from '../users/edit-user-permissions-page/edit-user-permissions-page';
import InviteUserDetailsPage from '../users/invite-user-details-page/invite-user-details-page';
import InviteUserPage from '../users/invite-user-page/invite-user-page';
import InviteUserPermissionsPage from '../users/invite-user-permissions-page/invite-user-permissions-page';
import MyTeamPage, {
  loader as myTeamPageLoader,
} from '../users/my-team-page/my-team-page';
import UserDetailsPage, {
  loader as userDetailsPageLoader,
} from '../users/user-details-page/user-details-page';
import CustomersHomePage, {
  loader as customersHomePageLoader,
} from './customers-home-page/customers-home-page';

export const routes: RouteObject[] = [
  {
    index: true,
    loader: customersHomePageLoader,
    element: <CustomersHomePage />,
  },
  {
    loader: eulasDataLoader,
    path: 'eula',
    element: <AcceptEulaPage />,
    isMobileFriendly: true,
  },
  {
    path: 'users',
    children: [
      {
        index: true,
        element: <Navigate to="/users/my-team" replace />,
      },
      {
        loader: myTeamPageLoader,
        path: 'my-team',
        element: <MyTeamPage />,
      },
      {
        path: 'my-team/invite',
        element: <InviteUserPage />,
        children: [
          {
            index: true,
            element: <Navigate to="details" replace />,
          },
          {
            path: 'details',
            element: <InviteUserDetailsPage />,
          },
          {
            path: 'permissions',
            element: <InviteUserPermissionsPage />,
          },
        ],
      },
      {
        loader: editUserPermissionsLoader,
        path: 'my-team/:id/edit',
        element: <EditUserPermissionsPage />,
      },
      {
        loader: userDetailsPageLoader,
        path: ':id',
        element: <UserDetailsPage />,
      },
    ],
  },

  ...mySoftwareRoutes,
  ...myDevicesRoutes,
  ...deviceGroupsRoutes,
  ...softwareAssignmentRoutes,
];
