import { DeviceFailureReason } from '@zspace/types';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Columns, Form, Icon, Section } from 'react-bulma-components';
import { FaArrowRight } from 'react-icons/fa6';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';
import BackButton from '../../../shared/back-button/back-button';
import { FormFieldData } from '../../../shared/form';
import useHttpRequest from '../../../shared/hooks/http-request';
import If from '../../../shared/if/if';
import BoxLayout from '../../../ui/box-layout/box-layout';
import Button from '../../../ui/button/button';
import FeedbackMessage from '../../../ui/feedback-message/feedback-message';
import { getDeviceFailureReasons } from '../../partners-service';
import DeviceDetailsTable from '../device-details-table/device-details-table';
import {
  columnSizes,
  DeviceReplacementContext,
} from '../device-replacement-layout/device-replacement-layout';

type FailureReasonComment = {
  failureReasonComment: string;
};

type FailureReasonCommentFormData = FormFieldData<FailureReasonComment>;

const validateForm = {
  failureReasonComment: (value: string) =>
    !value.trim() ? 'Please provide a comment' : null,
};

const initialFormData: FailureReasonCommentFormData = {
  failureReasonComment: { value: '', touched: false, error: null },
};

const OTHER_FAILURE_REASON = 'Other';
const EMPTY_DEFAULT_FAILURE_REASON = '';
const EMPTY_DEFAULT_FAILURE_REASON_ERROR =
  'Please provide a device failure reason';

export function DeviceFailureReasonSelectionPage() {
  const { faultyDevice, setDeviceFailureReason } =
    useOutletContext<DeviceReplacementContext>();
  const navigate = useNavigate();
  const [failureReasons, setFailureReasons] = useState<DeviceFailureReason[]>(
    []
  );
  const { executeHttpRequest, isLoading: isLoadingFailReasons } =
    useHttpRequest();
  const [selectedFailureReason, setSelectedFailureReason] = useState<string>(
    EMPTY_DEFAULT_FAILURE_REASON
  );
  const [formData, setFormData] = useState<FailureReasonCommentFormData>({
    failureReasonComment: { value: '', touched: false, error: null },
  });
  const [selectNewDeviceButtonPressed, setSelectNewDeviceButtonPressed] =
    useState<boolean>(false);

  const tableDataSource = useMemo(() => [faultyDevice], [faultyDevice]);

  const isOtherSelectedFailureReason = useMemo(
    () => selectedFailureReason === OTHER_FAILURE_REASON,
    [selectedFailureReason]
  );

  const showEmptyDefaultFailureReasonError = useMemo(
    () =>
      !isOtherSelectedFailureReason &&
      selectedFailureReason === EMPTY_DEFAULT_FAILURE_REASON &&
      selectNewDeviceButtonPressed,
    [
      isOtherSelectedFailureReason,
      selectNewDeviceButtonPressed,
      selectedFailureReason,
    ]
  );

  const onBackClick = useCallback(
    () => navigate('/device-replacement'),
    [navigate]
  );

  const fetchDeviceFailureReasons = useCallback(() => {
    executeHttpRequest({
      asyncFunction: async () => {
        const deviceFailureReasonsResponse = await getDeviceFailureReasons();
        setFailureReasons([
          ...deviceFailureReasonsResponse,
          { displayName: OTHER_FAILURE_REASON } as DeviceFailureReason,
        ]);
      },
    });
  }, [executeHttpRequest]);

  const onDeviceFailureReasonSelection = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const selection = event.target.value;
      setSelectedFailureReason(selection);
      setFormData(initialFormData);
    },
    [setSelectedFailureReason]
  );

  const onChangeFailureReasonComment = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const comment = event.target.value;
      setFormData({
        ...formData,
        failureReasonComment: {
          ...formData.failureReasonComment,
          value: comment,
          touched: true,
        },
      });
    },
    [formData]
  );

  const onBlurReasonComment = useCallback(() => {
    const value = formData.failureReasonComment.value;
    const error = validateForm.failureReasonComment(value);
    setFormData({
      ...formData,
      failureReasonComment: { ...formData.failureReasonComment, error },
    });
  }, [formData]);

  const onSelectNewDevice = useCallback(() => {
    let deviceFailureReason = selectedFailureReason;
    let isValidForm = false;

    setSelectNewDeviceButtonPressed(true);

    if (isOtherSelectedFailureReason) {
      // Validate form
      deviceFailureReason = formData.failureReasonComment.value;

      const formValidation = Object.keys(formData).reduce(
        (acc, key) => {
          const formDataKey = key as keyof FailureReasonComment;
          const error = validateForm[formDataKey]?.(
            formData[formDataKey].value
          );
          setFormData((prev) => ({
            ...prev,
            [formDataKey]: { ...prev[formDataKey], error, touched: true },
          }));
          return {
            values: { ...acc.values, [key]: formData[formDataKey].value },
            errors: { ...acc.errors, [key]: error },
          };
        },
        { values: {}, errors: {} }
      );

      isValidForm = Object.values(formValidation.errors).every(
        (fieldError) => !fieldError
      );
    } else {
      isValidForm = selectedFailureReason !== EMPTY_DEFAULT_FAILURE_REASON;
    }

    if (isValidForm) {
      setDeviceFailureReason(deviceFailureReason!);
      navigate('/device-replacement/new-device');
    }
  }, [
    formData,
    isOtherSelectedFailureReason,
    navigate,
    selectedFailureReason,
    setDeviceFailureReason,
  ]);

  const EmptyDefaultFailureReason = useCallback(
    () => (
      <option value={EMPTY_DEFAULT_FAILURE_REASON} disabled>
        Please select an option
      </option>
    ),
    []
  );

  useEffect(() => {
    if (faultyDevice) {
      fetchDeviceFailureReasons();
    }
  }, [faultyDevice, fetchDeviceFailureReasons]);

  if (!faultyDevice) {
    return <Navigate to="/device-replacement" replace />;
  }

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Section p={4}>
          <Columns display="flex" alignItems="center" paddingless>
            <Columns.Column marginless>
              <BackButton onClick={onBackClick} />
              <h1 className="is-size-3 has-text-weight-light">
                Replace faulty device
              </h1>
              <h3 className="is-size-5 has-text-weight-light">
                Original device validation
              </h3>
            </Columns.Column>
            <Columns.Column display="flex" justifyContent="flex-end">
              <Button color="primary-dark" onClick={onSelectNewDevice}>
                <span>Select new device</span>
                <Icon>
                  <FaArrowRight />
                </Icon>
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      }
    >
      <Section
        paddingless
        display="flex"
        className="gap-6"
        flexDirection="column"
      >
        <DeviceDetailsTable dataSource={tableDataSource} />
        <Columns>
          <Columns.Column {...columnSizes}>
            <Form.Field>
              <Form.Label
                className="is-vertical has-text-weight-normal"
                htmlFor="failReason"
              >
                What is the nature of the malfunction?
              </Form.Label>
              <Form.Control>
                <Form.Select
                  id="failReason"
                  fullwidth
                  loading={isLoadingFailReasons}
                  value={selectedFailureReason}
                  onChange={onDeviceFailureReasonSelection}
                >
                  <EmptyDefaultFailureReason />
                  {failureReasons.map((failReason) => (
                    <option
                      key={failReason.id ?? failReason.displayName}
                      value={failReason.displayName}
                    >
                      {failReason.displayName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Control>
              <If condition={showEmptyDefaultFailureReasonError}>
                <FeedbackMessage>
                  {EMPTY_DEFAULT_FAILURE_REASON_ERROR}
                </FeedbackMessage>
              </If>
              <If condition={isOtherSelectedFailureReason}>
                <Form.Control mt={3}>
                  <Form.Textarea
                    placeholder="Please provide additional details about the malfunction"
                    value={formData.failureReasonComment.value}
                    onChange={onChangeFailureReasonComment}
                    onBlur={onBlurReasonComment}
                  />
                </Form.Control>
                <FeedbackMessage>
                  {formData.failureReasonComment.error}
                </FeedbackMessage>
              </If>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Section>
    </BoxLayout>
  );
}

export default DeviceFailureReasonSelectionPage;
