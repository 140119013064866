import { User, UserRoleType } from '@zspace/types';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RouteObject as DefaultReactRouter,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { routes as accountRoutes } from './accounts/routes';
import { routes as customerUserRoutes } from './customers/routes';
import { routes as partnersRoutes } from './partners/routes';
import { routes as salesOrderRoutes } from './sales-orders/routes';
import AppErrorElement from './shared/app-error-element/app-error-element';
import Conditional from './shared/conditional/conditional';
import { ErrorsProvider } from './shared/context/errors-context';
import { GuidedProcessProvider } from './shared/context/guided-process-context';
import { ToastsProvider } from './shared/context/toasts-context';
import { UsersProvider } from './shared/context/users-context';
import useUser from './shared/hooks/user';
import RootLayout from './shared/layout/layout';
import UserErrorElement from './shared/user-error-element/user-error-element';
import PageSpinner from './ui/page-spinner/page-spinner';
import ResponsiveRouteWrapper from './ui/responsive-route-wrapper/responsive-route-wrapper';
import { fetchMe } from './users/users-service';
import { routes as zspaceInternalRoutes } from './zspace-internal/routes';

export type RouteObject = DefaultReactRouter & {
  isMobileFriendly?: boolean;
  children?: RouteObject[];
};

export function AppRouter() {
  const { user, loading, error: userRequestError } = useUser();

  const featureRoutes = useMemo(() => {
    let featureRoutes: RouteObject[] = [];
    const userActiveRole = user?.activeRole;

    if (
      !user ||
      loading ||
      userRequestError ||
      user.roles.length === 0 ||
      !userActiveRole
    )
      return featureRoutes;

    switch (userActiveRole.role.name) {
      case UserRoleType.CUSTOMER:
        featureRoutes = customerUserRoutes;
        break;
      case UserRoleType.ZSPACE_INTERNAL:
        featureRoutes = zspaceInternalRoutes;
        break;
      case UserRoleType.PARTNER:
        featureRoutes = partnersRoutes;
        break;
    }
    return featureRoutes;
  }, [loading, user, userRequestError]);

  const resolveResponsiveRoutes = useCallback((routes: RouteObject[]) => {
    return routes.map((route) => {
      const responsiveRoute: RouteObject = route;

      if (route.element) {
        responsiveRoute.element = (
          <ResponsiveRouteWrapper route={route}>
            {route.element}
          </ResponsiveRouteWrapper>
        );
      }
      if (route.children) {
        responsiveRoute.children = resolveResponsiveRoutes(route.children);
      }

      return responsiveRoute;
    });
  }, []);

  const router = useMemo(() => {
    const childRoutes = [
      ...featureRoutes,
      ...salesOrderRoutes,
      ...accountRoutes,
    ];

    return createBrowserRouter([
      {
        path: '/',
        element: <RootLayout />,
        errorElement: <AppErrorElement />,
        children: resolveResponsiveRoutes(childRoutes),
      },
    ]);
  }, [featureRoutes, resolveResponsiveRoutes]);

  return (
    <Conditional condition={!!userRequestError}>
      <Conditional.True>
        <UserErrorElement error={userRequestError!} />
      </Conditional.True>
      <Conditional.False>
        <Conditional condition={loading}>
          <Conditional.True>
            <PageSpinner />
          </Conditional.True>
          <Conditional.False>
            <RouterProvider router={router} />
          </Conditional.False>
        </Conditional>
      </Conditional.False>
    </Conditional>
  );
}

function App() {
  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState(true);
  const [userError, setUserError] = useState<AxiosError>();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await fetchMe();
        setUser(user);
      } catch (error) {
        setUserError(error as AxiosError);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  return (
    <ErrorsProvider>
      <UsersProvider user={user} loading={loading} error={userError}>
        <ToastsProvider>
          <GuidedProcessProvider>
            <AppRouter />
          </GuidedProcessProvider>
        </ToastsProvider>
      </UsersProvider>
    </ErrorsProvider>
  );
}

export default App;
