import { Box, Element } from 'react-bulma-components';
import BackButton from '../back-button/back-button';

export function NotFoundErrorCard() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      paddingless
      my={4}
      mx={10}
      className="is-min-height-90"
    >
      <Element
        display="flex"
        alignItems="center"
        flexDirection="column"
        className="gap-8"
        mt={10}
      >
        <h1 className="is-size-3 has-text-weight-light">404: Page not found</h1>
        <h3 className="is-size-6 has-text-weight-light">
          If you typed in a URL please make sure you typed it in correctly.
        </h3>
        <BackButton />
      </Element>
    </Box>
  );
}

export default NotFoundErrorCard;
