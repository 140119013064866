import { EULAPermissions, SalesOrderPermissions } from '@zspace/roles';
import {
  BasicSalesOrderData,
  DeferredResponse,
  DeviceModel,
  EulaOutstandingData,
  FilterType,
  GuidedProcessSteps,
  HardwareModel,
  MySalesOrdersCriteria,
  MySalesOrdersCriteriaFilterDataType,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  SortDirection,
} from '@zspace/types';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import { CgSpinner } from 'react-icons/cg';
import { FaArrowRight, FaFilter } from 'react-icons/fa6';
import {
  defer,
  LoaderFunction,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useNavigation,
} from 'react-router-dom';
import { fetchDeviceModels } from '../../devices/devices-service';
import { fetchEulasOutstandingData } from '../../eula/eula-service';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import Conditional from '../../shared/conditional/conditional';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import GuidedProcessStepperTabs from '../../shared/guided-process-stepper-tabs/guided-process-stepper-tabs';
import useHttpRequest from '../../shared/hooks/http-request';
import useIsPartnerUser from '../../shared/hooks/is-partner-user';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import TableForm from '../../ui/table/table-form/table-form';
import { fetchMySalesOrders } from '../../users/users-service';
import MySalesOrdersTableFilterModal from '../my-sales-orders-table-filter-modal/my-sales-orders-table-filter-modal';
import SalesOrderSummaryList from '../sales-order-summary-list/sales-order-summary-list';

const FETCH_MY_SALES_ORDERS_ERROR_MESSAGE =
  'My sales orders could not be fetched. Please try again';

const initialCriteria: MySalesOrdersCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
  salesOrders: [],
  softwareTitle: '',
  softwareTitleFilter: FilterType.CONTAINS,
  deviceType: HardwareModel.ALL,
  relatedUsers: [],
  minDevicesNumber: '',
  maxDevicesNumber: '',
};

export const loader: LoaderFunction = async () => {
  const salesOrders = fetchMySalesOrders(initialCriteria);

  const eulaData = fetchEulasOutstandingData();

  const deviceModels = fetchDeviceModels();

  const response = Promise.all([salesOrders, eulaData, deviceModels]);

  return defer({ response });
};

export function MySalesOrdersPageContent() {
  const navigation = useNavigation();
  const navigate = useNavigate();
  const [mySalesOrdersInitialResponse, eulaData, deviceModels] =
    useAsyncValue() as [
      PaginatedAPIResponse<BasicSalesOrderData>,
      EulaOutstandingData,
      DeviceModel[]
    ];
  const isPartnerUser = useIsPartnerUser();
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [criteria, setCriteria] =
    useState<MySalesOrdersCriteria>(initialCriteria);
  const { executeHttpRequest, isLoading: isLoadingSalesOrders } =
    useHttpRequest();
  const [mySalesOrders, setMySalesOrders] = useState<
    PaginatedAPIResponse<BasicSalesOrderData>
  >(mySalesOrdersInitialResponse);

  const listConfig: PaginatedContentConfig = useMemo(
    () => ({ ...criteria, pages: mySalesOrders.pages }),
    [criteria, mySalesOrders.pages]
  );

  const isPageDataLoading = useMemo(
    () => navigation.state === 'loading',
    [navigation.state]
  );

  const filterTagsData = useMemo(() => {
    return {
      salesOrders: {
        label: 'Sales order #',
        value: criteria.salesOrders.join(', '),
      },
      softwareTitle: {
        label: 'Software title',
        value: criteria.softwareTitle.length
          ? criteria.softwareTitleFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', criteria.softwareTitle)
          : '',
      },
      deviceType: {
        label: 'Device type',
        value:
          criteria.deviceType !== HardwareModel.ALL ? criteria.deviceType : '',
      },
      relatedUsers: {
        label: 'Related user',
        value: criteria.relatedUsers.join(', '),
      },
      minDevicesNumber: {
        label: 'Min amount of devices',
        value: criteria.minDevicesNumber,
      },
      maxDevicesNumber: {
        label: 'Max amount of devices',
        value: criteria.maxDevicesNumber,
      },
    };
  }, [
    criteria.deviceType,
    criteria.maxDevicesNumber,
    criteria.minDevicesNumber,
    criteria.relatedUsers,
    criteria.salesOrders,
    criteria.softwareTitle,
    criteria.softwareTitleFilter,
  ]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const deviceTypeOptions = useMemo(() => {
    return deviceModels.map((model) => ({
      label: model.displayName,
      value: model.displayName,
    }));
  }, [deviceModels]);

  const emptySalesOrdersContentText = useMemo(() => {
    const hasAppliedFilters =
      criteria.search !== '' || appliedFilters.length > 0;
    if (hasAppliedFilters) {
      return 'There are no sales orders that match the selected criteria';
    }
    return 'There are no sales orders at the moment';
  }, [appliedFilters.length, criteria.search]);

  const navigateToEulaAcceptance = useCallback(() => {
    navigate('/eula');
  }, [navigate]);

  const fetchRelatedSalesOrders = useCallback(
    (criteria: MySalesOrdersCriteria) =>
      executeHttpRequest({
        asyncFunction: async () => {
          const relatedSalesOrdersResponse = await fetchMySalesOrders(criteria);
          setMySalesOrders(relatedSalesOrdersResponse);
        },
        customErrorMessage: FETCH_MY_SALES_ORDERS_ERROR_MESSAGE,
      }),
    [executeHttpRequest]
  );

  const handleSearchChange = useCallback(
    (value: PaginatedContentConfig): void => {
      const newCriteria = {
        ...criteria,
        ...value,
      };

      setCriteria(newCriteria);
      fetchRelatedSalesOrders(newCriteria);
    },
    [criteria, fetchRelatedSalesOrders]
  );

  const onTableFilterModalSubmit = useCallback(
    (value: MySalesOrdersCriteriaFilterDataType) => {
      const newCriteria: MySalesOrdersCriteria = {
        ...criteria,
        ...value,
      };
      setCriteria(newCriteria);
      fetchRelatedSalesOrders(newCriteria);
    },
    [criteria, fetchRelatedSalesOrders]
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof MySalesOrdersCriteria) => {
      const newData = { ...criteria };
      newData[filterDataKey] = initialCriteria[filterDataKey] as string &
        string[] &
        boolean;
      onTableFilterModalSubmit(newData);
    },
    [criteria, onTableFilterModalSubmit]
  );

  return (
    <>
      <BoxLayout
        className={`is-min-height-80 mx-10 ${isPartnerUser ? 'my-4' : 'mb-4'}`}
        headerClassName={isPartnerUser ? '' : 'is-radiusless'}
        header={
          <>
            <Columns marginless>
              <Columns.Column>
                <h1 className="is-size-3 has-text-weight-light">
                  My sales orders
                </h1>
              </Columns.Column>
              <CheckPermissions permissions={EULAPermissions.EULA_ACCEPT}>
                <CheckPermissions.Render>
                  <If condition={eulaData.acceptableByUser}>
                    <Columns.Column display="flex" justifyContent="flex-end">
                      <Button
                        color="primary-dark"
                        onClick={navigateToEulaAcceptance}
                      >
                        <span>Review outstanding EULA</span>
                        <Icon>
                          <FaArrowRight />
                        </Icon>
                      </Button>
                    </Columns.Column>
                  </If>
                </CheckPermissions.Render>
              </CheckPermissions>
            </Columns>
            <If condition={appliedFilters.length > 0}>
              <Element ml={3}>
                <FilterTagList
                  list={filterTagsData}
                  onRemove={(item) =>
                    onRemoveFilterTag(item as keyof MySalesOrdersCriteria)
                  }
                  title="Filters"
                />
              </Element>
            </If>
          </>
        }
      >
        <Conditional condition={isPageDataLoading}>
          <Conditional.True>
            <PageSpinner />
          </Conditional.True>
          <Conditional.False>
            <TableForm
              config={listConfig}
              onChange={handleSearchChange}
              extra={
                <Button
                  type="button"
                  color="primary-dark"
                  className="ml-2"
                  outlined={appliedFilters.length === 0}
                  onClick={() => setIsFilterModalVisible(true)}
                >
                  <Icon>
                    <FaFilter />
                  </Icon>
                  <span>
                    Filter
                    {appliedFilters.length > 0 && `(${appliedFilters.length})`}
                  </span>
                </Button>
              }
            />
            <Conditional condition={isLoadingSalesOrders}>
              <Conditional.True>
                <PageSpinner />
              </Conditional.True>
              <Conditional.False>
                <Conditional condition={mySalesOrders.data.length > 0}>
                  <Conditional.True>
                    <SalesOrderSummaryList salesOrders={mySalesOrders.data} />
                  </Conditional.True>
                  <Conditional.False>
                    <Element
                      display="flex"
                      justifyContent="center"
                      className="my-2"
                    >
                      <span className="has-text-weight-light">
                        {emptySalesOrdersContentText}
                      </span>
                    </Element>
                  </Conditional.False>
                </Conditional>
              </Conditional.False>
            </Conditional>
          </Conditional.False>
        </Conditional>
      </BoxLayout>
      <MySalesOrdersTableFilterModal
        show={isFilterModalVisible}
        onSubmit={onTableFilterModalSubmit}
        onClose={() => setIsFilterModalVisible(false)}
        data={criteria}
        deviceTypeOptions={deviceTypeOptions}
      />
    </>
  );
}

export function MySalesOrdersPage() {
  const { response } = useLoaderData() as DeferredResponse<
    [
      PaginatedAPIResponse<BasicSalesOrderData>,
      EulaOutstandingData,
      DeviceModel[]
    ]
  >;
  const isPartnerUser = useIsPartnerUser();

  const Skeleton = () => (
    <BoxLayout
      className={`is-min-height-80 mx-10 ${isPartnerUser ? 'my-4' : 'mb-4'}`}
      headerClassName={isPartnerUser ? '' : 'is-radiusless'}
      header={
        <Columns marginless>
          <Columns.Column className="pl-4">
            <h1 className="is-size-3 has-text-weight-light">My sales orders</h1>
            <CgSpinner className="animate-spin" />
          </Columns.Column>
        </Columns>
      }
    >
      <PageSpinner />
    </BoxLayout>
  );

  return (
    <ProtectedPage
      permissions={[SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ]}
    >
      <If condition={!isPartnerUser}>
        <GuidedProcessStepperTabs
          activeTab={GuidedProcessSteps.SALES_ORDERS_AND_EULA}
        />
      </If>
      <Suspense fallback={<Skeleton />}>
        <ErrorHandlingAwait resolve={response}>
          <MySalesOrdersPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default MySalesOrdersPage;
