import { ButtonHTMLAttributes, ComponentProps, useCallback } from 'react';
import { Icon } from 'react-bulma-components';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import Button from '../../ui/button/button';

type ButtonProps = ComponentProps<typeof Button>;

export type BackButtonProps = ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

export function BackButton({ onClick, ...props }: BackButtonProps) {
  const navigate = useNavigate();

  const navigateBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <Button
      className="is-borderless is-transparent"
      paddingless
      onClick={onClick ?? navigateBack}
      {...props}
    >
      <Icon
        className="is-justify-content-flex-start"
        color={'primary'}
        marginless
      >
        <FaArrowLeft />
      </Icon>
      <span className="is-underlined has-text-primary">Back</span>
    </Button>
  );
}

export default BackButton;
