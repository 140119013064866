import { isPartnerUser } from '@zspace/roles';
import useUser from './user';

export function useIsPartnerUser() {
  const { user: currentUser } = useUser();

  return isPartnerUser(currentUser);
}

export default useIsPartnerUser;
