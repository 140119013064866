import { hasZspaceStaffPermissions } from '@zspace/roles';
import useUser from './user';

export function useUserHasZspaceStaffPermissions() {
  const { user: currentUser } = useUser();

  return hasZspaceStaffPermissions(currentUser);
}

export default useUserHasZspaceStaffPermissions;
