import { BasicSalesOrderData } from '@zspace/types';
import SalesOrderSummaryListItem from '../sales-order-summary-list-item/sales-order-summary-list-item';

export type SalesOrderSummaryListProps = {
  salesOrders: BasicSalesOrderData[];
};

export function SalesOrderSummaryList({
  salesOrders,
}: SalesOrderSummaryListProps) {
  return (
    <>
      {salesOrders.map((so, idx) => (
        <SalesOrderSummaryListItem
          key={so.id}
          data={so}
          collapsed={idx !== 0}
        />
      ))}
    </>
  );
}

export default SalesOrderSummaryList;
