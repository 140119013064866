import {
  capitalizeString,
  formatSalesOrderNumber,
  formatWorkOrderNumber,
} from '@zspace/format';
import {
  DeviceGroupPermissions,
  SoftwareSeatPermissions,
  WorkOrderPermissions,
} from '@zspace/roles';
import { WorkOrderData } from '@zspace/types';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import { formatDateToLocaleString } from '../../shared/utils';
import NumericInput from '../../ui/numeric-input/numeric-input';
import PaginatedTable, {
  CustomPaginatedTableProps,
} from '../../ui/table/paginated-table/paginated-table';
import { Column } from '../../ui/table/types';
import ViewAllList from '../../ui/view-all-list/view-all-list';

export type WorkOrdersQueueTableProps =
  CustomPaginatedTableProps<WorkOrderData> & {
    includePriority?: boolean;
    onPriorityUpdate?: (workOrderId: string, priority: number) => void;
    isUpdatingPriority?: boolean;
  };

export function WorkOrdersQueueTable({
  dataSource,
  onChange,
  config,
  form,
  empty,
  includePriority = false,
  onPriorityUpdate,
  isUpdatingPriority = false,
  children,
}: WorkOrdersQueueTableProps) {
  const columns = useMemo(() => {
    const tableColumns: Column<WorkOrderData>[] = [
      {
        key: 'number',
        widthClassname: 'is-size-15',
        render: (el) => (
          <CheckPermissions
            permissions={[
              DeviceGroupPermissions.DEVICE_GROUPS_READ,
              SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
            ]}
          >
            <CheckPermissions.Render>
              <Link to={`/work-orders/${el.id}`}>
                {formatWorkOrderNumber(el.number)}
              </Link>
            </CheckPermissions.Render>
            <CheckPermissions.Fallback>
              <span className="has-text-primary-dark">
                {formatWorkOrderNumber(el.number)}
              </span>
            </CheckPermissions.Fallback>
          </CheckPermissions>
        ),
        title: 'WO Number',
        sortable: false,
      },
      {
        key: 'name',
        widthClassname: 'is-size-15',
        render: (el) => (
          <CheckPermissions
            permissions={[
              DeviceGroupPermissions.DEVICE_GROUPS_READ,
              SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
            ]}
          >
            <CheckPermissions.Render>
              <Link to={`/work-orders/${el.id}`}>{el.name}</Link>
            </CheckPermissions.Render>
            <CheckPermissions.Fallback>
              <span className="has-text-primary-dark">{el.name}</span>
            </CheckPermissions.Fallback>
          </CheckPermissions>
        ),
        title: 'WO Name',
        sortable: false,
      },
      {
        key: 'account',
        widthClassname: 'is-size-15',
        render: (el) => (
          <ViewAllList
            list={el.salesOrders
              .filter((so) => so.opportunity)
              .map((so) => so.opportunity?.account)}
            horizontalList
            renderItem={(account) => (
              <Link to={`/accounts/${account?.id}`}>{account?.name}</Link>
            )}
            separator=", "
          />
        ),
        title: 'Accounts',
        sortable: false,
      },
      {
        key: 'salesOrders',
        widthClassname: 'is-size-10',
        render: (el) => (
          <ViewAllList
            list={el.salesOrders}
            renderItem={(el) => <p>{formatSalesOrderNumber(el.number)}</p>}
          />
        ),
        title: 'Sales orders',
        sortable: false,
      },
      {
        key: 'type',
        widthClassname: 'is-size-10',
        render: (el) => capitalizeString(el.type).replace('_', ' '),
        title: 'Type',
        sortable: false,
      },
      {
        key: 'devices',
        widthClassname: includePriority ? 'is-size-5' : 'is-size-10',
        render: (el) => {
          return el.devices.assigned ?? 'N/A';
        },
        title: 'Devices',
      },
      {
        key: 'complex',
        widthClassname: 'is-size-5',
        render: (el) => (el.complex ? 'Yes' : 'No'),
        title: 'Complex',
        sortable: false,
      },
      {
        key: 'createdBy',
        widthClassname: 'is-size-15',
        dataIndex: 'createdBy',
        title: 'Created by',
        sortable: false,
      },
      {
        key: 'createdAt',
        widthClassname: 'is-size-5',
        render: (el) => formatDateToLocaleString(el.createdAt),
        title: 'Date',
        sortable: false,
      },
    ];

    const priorityColumn: Column<WorkOrderData> = {
      key: 'priority',
      widthClassname: 'is-size-5',
      title: 'Priority',
      render: (el) => (
        <CheckPermissions
          permissions={WorkOrderPermissions.WORK_ORDERS_QUEUE_PRIORITIZATION}
        >
          <CheckPermissions.Render>
            <NumericInput
              value={el.priority!}
              disabled={isUpdatingPriority}
              onChange={(priority: number) =>
                onPriorityUpdate?.(el.id, priority)
              }
            />
          </CheckPermissions.Render>
          <CheckPermissions.Fallback>
            <span className="pl-4 has-text-primary-dark">{el.priority}</span>
          </CheckPermissions.Fallback>
        </CheckPermissions>
      ),
      sortable: false,
    };
    return includePriority ? [priorityColumn, ...tableColumns] : tableColumns;
  }, [includePriority, isUpdatingPriority, onPriorityUpdate]);

  return (
    <PaginatedTable
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      config={config}
      rowKey={'id'}
      form={form}
      empty={empty}
      children={children}
    />
  );
}

export default WorkOrdersQueueTable;
