import { FilterTableModalInputType, InputType } from '@zspace/types';
import { Element, Form } from 'react-bulma-components';
import ChecklistInput from '../inputs/checklist-input/checklist-input';
import DateRangeInput from '../inputs/date-range-input/date-range-input';
import { FilterCriteriaInput } from '../inputs/filter-criteria-input/filter-criteria-input';
import MinMaxInput from '../inputs/min-max-input/min-max-input';
import SelectInput from '../inputs/select-input/select-input';
import TagInput from '../inputs/tag-input/tag-input';

export type FilterModalInput<T> = Pick<
  FilterTableModalInputProps<T>,
  'localData' | 'setLocalData' | 'name' | 'onValidateField' | 'errorText'
>;

export type FilterTableModalInputProps<T> = {
  name: string;
  label: string;
  input: FilterTableModalInputType<T>;
  errorText?: string;
  localData: T;
  setLocalData: (value: T) => void;
  onValidateField?: (fieldName: string, valid: boolean) => void;
};

export function FilterTableModalInput<T>({
  input,
  label,
  ...inputProps
}: FilterTableModalInputProps<T>) {
  return (
    <Element display="flex" flexDirection="column" className="gap-4">
      <Form.Label
        className="has-text-weight-normal is-size-6"
        marginless
        htmlFor={inputProps.name}
      >
        {label}
      </Form.Label>
      {input.type === InputType.FILTER_CRITERIA && (
        <FilterCriteriaInput {...inputProps} input={input} />
      )}
      {input.type === InputType.SELECT && (
        <SelectInput {...inputProps} input={input} />
      )}
      {input.type === InputType.MIN_MAX && (
        <MinMaxInput {...inputProps} input={input} />
      )}
      {input.type === InputType.TAG_INPUT && (
        <TagInput {...inputProps} input={input} />
      )}
      {input.type === InputType.DATE_RANGE && (
        <DateRangeInput {...inputProps} input={input} />
      )}
      {input.type === InputType.CHECKLIST && (
        <ChecklistInput {...inputProps} input={input} />
      )}
    </Element>
  );
}

export default FilterTableModalInput;
