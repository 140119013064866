import { DeviceGroupPermissions, SoftwareSeatPermissions } from '@zspace/roles';
import {
  NewAssignmentFromReview,
  SoftwareAssignmentReviewData,
} from '@zspace/types';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaArrowLeft, FaFloppyDisk } from 'react-icons/fa6';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { getDeviceName } from '../../../device-groups/utils';
import useError from '../../../shared/hooks/error';
import useHttpRequest from '../../../shared/hooks/http-request';
import If from '../../../shared/if/if';
import ProtectedPage from '../../../shared/protected-page/protected-page';
import Button from '../../../ui/button/button';
import PageSpinner from '../../../ui/page-spinner/page-spinner';
import { ManageSoftwareLayoutContext } from '../../manage-software-layout/manage-software-layout';
import SoftwareAssignedConfirmationModal from '../../software-assigned-confirmation-modal/software-assigned-confirmation-modal';
import {
  assignSoftwareFromReview,
  fetchSoftwareAssignmentReview as fetchSoftwareAssignmentReviewRequest,
} from '../../software-assignment-service';
import NewLicenseKeys from '../new-license-keys/new-license-keys';
import UpdatedLicenseKeys from '../updated-license-keys/updated-license-keys';

const SOFTWARE_ASSIGNMENT_ERROR_MESSAGE =
  'The software could not be assigned. Please try again';

export function ReviewSoftwareAssignmentPage() {
  const { modelId } = useParams();
  const navigate = useNavigate();
  const {
    selectedDevices,
    selectedSoftware,
    setSelectedDevices,
    setSelectedSoftware,
    setTitle,
    setHeaderRightContent,
  } = useOutletContext<ManageSoftwareLayoutContext>();
  const [loading, setLoading] = useState(false);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const {
    executeHttpRequest: executeAssignSoftwareRequest,
    isLoading: isAssigningSoftware,
  } = useHttpRequest();
  const {
    executeHttpRequest: executeFetchSoftwareAssignmentReview,
    isLoading: isLoadingSoftwareAssignmentReview,
  } = useHttpRequest();
  const [softwareAssignmentReview, setSoftwareAssignmentReview] =
    useState<SoftwareAssignmentReviewData>();
  const { setError } = useError();

  const deviceList = useMemo(
    () =>
      selectedDevices
        .map((device) => {
          return getDeviceName(device);
        })
        .join(', '),
    [selectedDevices]
  );

  const fetchSoftwareAssignmentReview = useCallback(
    () =>
      executeFetchSoftwareAssignmentReview({
        asyncFunction: async () => {
          const softwareAssignmentReview =
            await fetchSoftwareAssignmentReviewRequest({
              devices: selectedDevices,
              software: selectedSoftware.map((software) => ({
                licensingProductGroupId: software.licensingProductGroup.id,
                salesOrderId: software.salesOrder.id,
                itemFulfillmentId: software.itemFulfillment.id,
              })),
            });
          setSoftwareAssignmentReview(softwareAssignmentReview);
        },
        customErrorHandler: (error) => {
          if (error instanceof AxiosError) {
            setError({
              status: error.response?.status || 500,
              message:
                (error.response?.data as { message?: string })?.message ||
                error.message,
            });
          }
        },
      }),
    [
      executeFetchSoftwareAssignmentReview,
      selectedDevices,
      selectedSoftware,
      setError,
    ]
  );

  const handleBackButtonClick = useCallback(() => {
    navigate(`/device-models/${modelId}/software/select`);
  }, [modelId, navigate]);

  const handleSaveButtonClick = useCallback(() => {
    return executeAssignSoftwareRequest({
      asyncFunction: async () => {
        setSubmitButtonPressed(true);
        const assignmentsToSaveFromReviewNewAssignments =
          softwareAssignmentReview?.newAssignments ?? [];
        const assignmentsToSaveFromReviewUpdatedAssignments =
          softwareAssignmentReview?.updatedAssignments.map(
            (assignment) => assignment.newAssignment
          ) ?? [];
        const assignmentsToSave: NewAssignmentFromReview[] = [
          ...assignmentsToSaveFromReviewNewAssignments,
          ...assignmentsToSaveFromReviewUpdatedAssignments,
        ].map((assignment) => ({
          device: {
            id: assignment.device.id,
            salesOrder: {
              id: assignment.device.salesOrder.id,
              number: assignment.device.salesOrder.number,
            },
          },
          softwareSeat: {
            id: assignment.softwareSeat.id,
            salesOrder: {
              id: assignment.softwareSeat.salesOrder.id,
              number: assignment.softwareSeat.salesOrder.number,
            },
          },
        }));
        await assignSoftwareFromReview(assignmentsToSave);
        setShowConfirmationModal(true);
      },
      customErrorMessage: SOFTWARE_ASSIGNMENT_ERROR_MESSAGE,
    });
  }, [
    executeAssignSoftwareRequest,
    softwareAssignmentReview?.newAssignments,
    softwareAssignmentReview?.updatedAssignments,
  ]);

  const clearSelectedDevicesAndSoftware = useCallback(() => {
    setSelectedDevices([]);
    setSelectedSoftware([]);
  }, [setSelectedDevices, setSelectedSoftware]);

  const navigateBackToHome = useCallback(() => {
    clearSelectedDevicesAndSoftware();
    navigate('/');
  }, [clearSelectedDevicesAndSoftware, navigate]);

  const navigateToDeviceGroups = useCallback(() => {
    clearSelectedDevicesAndSoftware();
    navigate('/device-groups');
  }, [clearSelectedDevicesAndSoftware, navigate]);

  useEffect(() => {
    fetchSoftwareAssignmentReview();
  }, [
    executeFetchSoftwareAssignmentReview,
    fetchSoftwareAssignmentReview,
    selectedDevices,
    selectedSoftware,
  ]);

  const headerRightContent = useMemo(
    () => (
      <Element display="flex" className="gap-6">
        <Button
          color="primary-dark"
          outlined
          className="outlined-button-white-background"
          onClick={handleBackButtonClick}
        >
          <Icon>
            <FaArrowLeft />
          </Icon>
          <span>Back</span>
        </Button>
        <Button
          color="primary-dark"
          onClick={handleSaveButtonClick}
          isExecutingAction={isAssigningSoftware}
        >
          <Button.LoadingIcon icon={FaFloppyDisk} />
          <span>Save</span>
        </Button>
      </Element>
    ),
    [handleBackButtonClick, handleSaveButtonClick, isAssigningSoftware]
  );

  useEffect(() => {
    setTitle(`Review software assignment`);
  }, [setTitle]);

  useEffect(() => {
    setHeaderRightContent(headerRightContent);
  }, [
    handleBackButtonClick,
    handleSaveButtonClick,
    headerRightContent,
    isAssigningSoftware,
    setHeaderRightContent,
  ]);

  useEffect(() => {
    if (
      !submitButtonPressed &&
      (selectedDevices.length === 0 || selectedSoftware.length === 0)
    ) {
      setLoading(true);
      if (selectedDevices.length === 0) {
        navigate(`/device-groups`, { replace: true });
      } else if (selectedSoftware.length === 0) {
        navigate(`/device-models/${modelId}/software/select`, {
          replace: true,
        });
      }
    }
  }, [
    modelId,
    navigate,
    selectedDevices.length,
    selectedSoftware.length,
    submitButtonPressed,
  ]);

  if (
    loading ||
    isLoadingSoftwareAssignmentReview ||
    !softwareAssignmentReview
  ) {
    return <PageSpinner />;
  }

  return (
    <ProtectedPage
      permissions={[
        DeviceGroupPermissions.DEVICE_GROUPS_READ,
        SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
        SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN,
      ]}
    >
      <If condition={softwareAssignmentReview.updatedAssignments.length > 0}>
        <UpdatedLicenseKeys
          updatedAssignments={softwareAssignmentReview.updatedAssignments}
        />
        <hr className="my-4 has-background-grey-lighter" />
      </If>

      <If condition={softwareAssignmentReview.newAssignments.length > 0}>
        <NewLicenseKeys
          newAssignments={softwareAssignmentReview.newAssignments}
        />
        <hr className="my-4 has-background-grey-lighter" />
      </If>
      <SoftwareAssignedConfirmationModal
        show={showConfirmationModal}
        deviceList={deviceList}
        onNavigateBackToHome={navigateBackToHome}
        onKeepAssigningSoftware={navigateToDeviceGroups}
      />
    </ProtectedPage>
  );
}

export default ReviewSoftwareAssignmentPage;
