import { Icon } from 'react-bulma-components';
import { Color } from 'react-bulma-components/src/components';
import { FaSpinner } from 'react-icons/fa6';
import { IconType } from 'react-icons/lib';
import Conditional from '../../shared/conditional/conditional';

type LoadingIconProps = {
  isLoading: boolean;
  icon: IconType;
  iconColor?: Color;
};

export function LoadingIcon({
  isLoading,
  icon: IconComponent,
  iconColor,
}: LoadingIconProps) {
  return (
    <Conditional condition={isLoading}>
      <Conditional.True>
        <Icon className={`animate-spin`}>
          <FaSpinner />
        </Icon>
      </Conditional.True>
      <Conditional.False>
        <Icon color={iconColor}>
          <IconComponent />
        </Icon>
      </Conditional.False>
    </Conditional>
  );
}

export default LoadingIcon;
