import { DeviceGroupPermissions } from '@zspace/roles';
import { NewDeviceGroup } from '@zspace/types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useHttpRequest from '../../shared/hooks/http-request';
import ProtectedPage from '../../shared/protected-page/protected-page';
import CreateDeviceGroupLayout from '../create-device-group-layout/create-device-group-layout';
import { createDeviceGroup } from '../device-groups-service';

const CREATE_DEVICE_GROUP_ERROR_MESSAGE =
  'The device group could not be created. Please try again';

function CreateDeviceGroupCardPage() {
  const navigate = useNavigate();
  const { executeHttpRequest, isLoading: isCreatingNewDeviceGroup } =
    useHttpRequest();

  const navigateToMyDevices = useCallback(() => {
    navigate('/my-devices');
  }, [navigate]);

  const handleOnCreateDeviceGroup = useCallback(
    async (newDeviceGroupData: NewDeviceGroup) => {
      return executeHttpRequest({
        asyncFunction: async () => {
          const deviceGroup = await createDeviceGroup(newDeviceGroupData);
          return deviceGroup;
        },
        customErrorMessage: CREATE_DEVICE_GROUP_ERROR_MESSAGE,
      });
    },
    [executeHttpRequest]
  );

  return (
    <ProtectedPage permissions={DeviceGroupPermissions.DEVICE_GROUPS_CREATE}>
      <CreateDeviceGroupLayout
        onCancel={navigateToMyDevices}
        isCreatingNewDeviceGroup={isCreatingNewDeviceGroup}
        onCreateDeviceGroup={handleOnCreateDeviceGroup}
        onCloseConfirmationModal={navigateToMyDevices}
        customer={true}
      />
    </ProtectedPage>
  );
}

export default CreateDeviceGroupCardPage;
