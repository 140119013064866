import { capitalizeString } from '@zspace/format';
import {
  DeviceGroupPermissions,
  RegistrationFilePermissions,
  SoftwareSeatPermissions,
} from '@zspace/roles';
import {
  AllYesNoFilter,
  BasicDeviceGroupData,
  DeferredResponse,
  Device,
  DeviceGroup,
  DeviceGroupsCriteria,
  DeviceModel,
  FilterType,
  GuidedProcessSteps,
  HardwareModel,
  MoveDevicesData,
  MyDevicesData,
  PaginatedAPIResponse,
  PartialBy,
  SortDirection,
} from '@zspace/types';
import {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Columns, Element, Icon } from 'react-bulma-components';
import {
  FaArrowRight,
  FaArrowRightFromBracket,
  FaMinus,
  FaPlus,
  FaSpinner,
} from 'react-icons/fa6';
import {
  defer,
  LoaderFunction,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useRevalidator,
  useSearchParams,
} from 'react-router-dom';
import DeviceGroupInboxTable from '../../device-groups/device-group-inbox-table/device-group-inbox-table';
import SelectedDeviceTagList from '../../device-groups/device-group-table/selected-device-tag-list/selected-device-tag-list';
import {
  fetchAllAvailableDeviceGroups,
  fetchDeviceGroups,
  moveDevicesToDeviceGroup,
  removeDevicesFromDeviceGroup,
} from '../../device-groups/device-groups-service';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import Conditional from '../../shared/conditional/conditional';
import {
  GuidedProcessContext,
  GuidedProcessRefreshAction,
} from '../../shared/context/guided-process-context';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import GuidedProcessStepperTabs from '../../shared/guided-process-stepper-tabs/guided-process-stepper-tabs';
import useHttpRequest from '../../shared/hooks/http-request';
import usePermissions from '../../shared/hooks/permissions';
import useToast from '../../shared/hooks/toasts';
import useUser from '../../shared/hooks/user';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import { createSearchParams } from '../../shared/url';
import BoxLayout from '../../ui/box-layout/box-layout';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import {
  fetchDeviceModels,
  fetchMyDevices as fetchMyDevicesRequest,
} from '../devices-service';
import MoveDevicesModal from '../move-devices-modal/move-devices-modal';
import RemoveDevicesModal from '../remove-devices-modal/remove-devices-modal';

const initialDeviceGroupsCriteria: DeviceGroupsCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
  deviceName: '',
  deviceNameFilter: FilterType.CONTAINS,
  deviceType: HardwareModel.ALL,
  softwareAssigned: AllYesNoFilter.ALL,
  softwareTitle: '',
  softwareTitleFilter: FilterType.CONTAINS,
  salesOrders: [],
  serialNumber: '',
  serialNumberFilter: FilterType.CONTAINS,
};

const REMOVE_DEVICES_ERROR_MESSAGE =
  'The devices could not be removed from the selected device group. Please try again';
const FETCH_DEVICES_ERROR_MESSAGE =
  'My devices could not be fetched. Please try again';
const SUCCESSFULLY_REMOVED_DEVICES_MESSAGE =
  'Devices successfully removed from group';
const SUCCESSFULLY_MOVED_DEVICES_MESSAGE = 'Devices successfully moved';

const initialCriteria = (searchParams: URLSearchParams) => {
  return {
    ...initialDeviceGroupsCriteria,
    salesOrders: searchParams.get('salesOrders')?.split(',') || [],
  };
};

export const loader: LoaderFunction = async ({ request }) => {
  const searchParams = createSearchParams(request);
  const myDevicesData = fetchMyDevicesRequest(initialCriteria(searchParams));
  const deviceModels = fetchDeviceModels();
  const myDeviceGroups = fetchDeviceGroups(initialCriteria(searchParams));
  const allAvailableDeviceGroups = fetchAllAvailableDeviceGroups();

  const response = Promise.all([
    myDevicesData,
    myDeviceGroups,
    deviceModels,
    allAvailableDeviceGroups,
  ]);
  return defer({ response });
};

export function MyDevicesPageContent() {
  const navigate = useNavigate();
  const [
    myDevicesDataInitialResponse,
    myDeviceGroupsInitialResponse,
    deviceModels,
    allAvailableDeviceGroups,
  ] = useAsyncValue() as [
    MyDevicesData,
    PaginatedAPIResponse<DeviceGroup>,
    DeviceModel[],
    BasicDeviceGroupData[]
  ];
  const [searchParams] = useSearchParams();
  const [myDeviceGroupsDataResponse, setMyDeviceGroupsDataResponse] = useState<
    PaginatedAPIResponse<DeviceGroup>
  >(myDeviceGroupsInitialResponse);
  const [deviceGroupsFilterCriteria, setDeviceGroupsFilterCriteria] =
    useState<DeviceGroupsCriteria>(initialCriteria(searchParams));

  const [myDeviceGroups, setMyDeviceGroups] = useState<DeviceGroup[]>(
    myDeviceGroupsInitialResponse.data
  );
  const [myDevicesData, setMyDevicesData] = useState<MyDevicesData>(
    myDevicesDataInitialResponse
  );
  const { executeHttpRequest: executeFetchMoreMyDeviceGroupsRequest } =
    useHttpRequest();
  const {
    executeHttpRequest: executeFetchMyDeviceGroupsRequest,
    isLoading: isLoadingMyDeviceGroups,
  } = useHttpRequest();
  const {
    executeHttpRequest: executeFetchMyDevicesRequest,
    isLoading: isLoadingMyDevicesData,
  } = useHttpRequest();
  const {
    executeHttpRequest: executeRemoveDevicesFromDeviceGroup,
    isLoading: isRemovingDevices,
  } = useHttpRequest();
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [isMoveDevicesModalVisible, setIsMoveDevicesModalVisible] =
    useState(false);
  const [isRemoveDevicesModalVisible, setIsRemoveDevicesModalVisible] =
    useState(false);
  const { user } = useUser();
  const userHasPermissions = usePermissions();
  const revalidator = useRevalidator();
  const { setRefreshAction } = useContext(GuidedProcessContext);
  const toast = useToast();

  const navigateToCreateDeviceGroup = useCallback(() => {
    navigate('/my-devices/device-groups/create');
  }, [navigate]);

  const navigateToDeviceRegistration = useCallback(() => {
    navigate('/my-devices/device-registration/registration-files');
  }, [navigate]);

  const devicesText = useMemo(() => {
    const devicesString =
      myDevicesDataInitialResponse.allDevicesCount > 1 ? 'devices' : 'device';
    const totalDevices = `${myDevicesDataInitialResponse.allDevicesCount} total`;
    const unregisteredDevices = `${myDevicesDataInitialResponse.unregisteredDevicesCount} unregistered`;
    const ungroupedDevices = `${myDevicesDataInitialResponse.ungroupedDevicesCount} ungrouped`;

    return `${totalDevices} ${devicesString} - ${unregisteredDevices} - ${ungroupedDevices}`;
  }, [
    myDevicesDataInitialResponse.allDevicesCount,
    myDevicesDataInitialResponse.ungroupedDevicesCount,
    myDevicesDataInitialResponse.unregisteredDevicesCount,
  ]);

  const hasMoreDeviceGroups = useMemo(
    () => !!myDeviceGroupsDataResponse.hasMore,
    [myDeviceGroupsDataResponse]
  );

  const selectedGroupedDevices = useMemo(
    () => selectedDevices.filter((device) => !!device.deviceGroup),
    [selectedDevices]
  );

  const deviceGroupsToBeEmptied = useMemo(() => {
    const deviceGroupMovedDevicesCount = selectedGroupedDevices.reduce(
      (acc, device) => {
        const key = device.deviceGroup!.id;
        if (!acc[key]) {
          acc[key] = 0;
        }
        acc[key]++;
        return acc;
      },
      {} as Record<string, number>
    );
    return myDeviceGroups.filter((group) => {
      const movedDevicesCount = deviceGroupMovedDevicesCount[group.id];
      /* If both counts match, this means that all devices are being moved from this group.
       * Since creators can still see empty groups, they need to be excluded
       */
      return (
        movedDevicesCount === group.devices.length &&
        group.creator.id !== user.id
      );
    });
  }, [myDeviceGroups, selectedGroupedDevices, user.id]);

  const filterTagsData = useMemo(() => {
    return {
      deviceType: {
        label: 'Device type',
        value:
          deviceGroupsFilterCriteria.deviceType !== HardwareModel.ALL
            ? capitalizeString(deviceGroupsFilterCriteria.deviceType)
            : '',
      },
      softwareAssigned: {
        label: 'Has software assigned',
        value:
          deviceGroupsFilterCriteria.softwareAssigned !== AllYesNoFilter.ALL
            ? capitalizeString(deviceGroupsFilterCriteria.softwareAssigned)
            : '',
      },
      softwareTitle: {
        label: 'Assigned software title',
        value: deviceGroupsFilterCriteria.softwareTitle.length
          ? deviceGroupsFilterCriteria.softwareTitleFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', deviceGroupsFilterCriteria.softwareTitle)
          : '',
      },
      deviceName: {
        label: 'Device name',
        value: deviceGroupsFilterCriteria.deviceName
          ? deviceGroupsFilterCriteria.deviceNameFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', deviceGroupsFilterCriteria.deviceName)
          : '',
      },
      salesOrders: {
        label: 'Sales order #',
        value: deviceGroupsFilterCriteria.salesOrders.join(', '),
      },
      serialNumber: {
        label: 'Serial number',
        value: deviceGroupsFilterCriteria.serialNumber
          ? deviceGroupsFilterCriteria.serialNumberFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', deviceGroupsFilterCriteria.serialNumber)
          : '',
      },
    };
  }, [
    deviceGroupsFilterCriteria.deviceName,
    deviceGroupsFilterCriteria.deviceNameFilter,
    deviceGroupsFilterCriteria.deviceType,
    deviceGroupsFilterCriteria.salesOrders,
    deviceGroupsFilterCriteria.serialNumber,
    deviceGroupsFilterCriteria.serialNumberFilter,
    deviceGroupsFilterCriteria.softwareAssigned,
    deviceGroupsFilterCriteria.softwareTitle,
    deviceGroupsFilterCriteria.softwareTitleFilter,
  ]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const myDevices = useMemo(
    () => ({
      ...myDevicesData,
      displayUnregistered:
        myDevicesDataInitialResponse.unregisteredDevicesCount > 0,
      displayUngrouped: myDevicesDataInitialResponse.ungroupedDevicesCount > 0,
    }),
    [myDevicesData, myDevicesDataInitialResponse]
  );

  const onMoveDevicesToDeviceGroup = useCallback(
    async (moveDevicesData: PartialBy<MoveDevicesData, 'devicesIds'>) => {
      const devicesIds = selectedDevices.map((device) => device.id);
      await moveDevicesToDeviceGroup({ ...moveDevicesData, devicesIds });
      setDeviceGroupsFilterCriteria(initialCriteria(searchParams));
      setIsMoveDevicesModalVisible(false);
      revalidator.revalidate();
      setRefreshAction(GuidedProcessRefreshAction.DEVICES);
      toast.success(SUCCESSFULLY_MOVED_DEVICES_MESSAGE);
    },
    [revalidator, searchParams, selectedDevices, setRefreshAction, toast]
  );

  const onRemoveDevicesFromDeviceGroup = useCallback(
    () =>
      executeRemoveDevicesFromDeviceGroup({
        asyncFunction: async () => {
          const devicesIds = selectedDevices.map((device) => device.id);
          await removeDevicesFromDeviceGroup(devicesIds);
          setDeviceGroupsFilterCriteria(initialCriteria(searchParams));
          setIsRemoveDevicesModalVisible(false);
          revalidator.revalidate();
          setRefreshAction(GuidedProcessRefreshAction.DEVICES);
          toast.success(SUCCESSFULLY_REMOVED_DEVICES_MESSAGE);
        },
        customErrorMessage: REMOVE_DEVICES_ERROR_MESSAGE,
      }),
    [
      executeRemoveDevicesFromDeviceGroup,
      revalidator,
      searchParams,
      selectedDevices,
      setRefreshAction,
      toast,
    ]
  );

  const handleRemoveDevicesFromDeviceGroup = useCallback(async () => {
    if (deviceGroupsToBeEmptied.length > 0) {
      setIsRemoveDevicesModalVisible(true);
    } else {
      await onRemoveDevicesFromDeviceGroup();
    }
  }, [deviceGroupsToBeEmptied.length, onRemoveDevicesFromDeviceGroup]);

  const handleMoveDevicesToGroup = useCallback(
    () => setIsMoveDevicesModalVisible(true),
    []
  );

  const fetchMoreMyDeviceGroups = useCallback(() => {
    executeFetchMoreMyDeviceGroupsRequest({
      asyncFunction: async () => {
        const nextPage = deviceGroupsFilterCriteria.pageNumber + 1;
        const updatedDeviceGroupCriteria = {
          ...deviceGroupsFilterCriteria,
          pageNumber: nextPage,
        };
        const myDeviceGroupsResponse = await fetchDeviceGroups(
          updatedDeviceGroupCriteria
        );
        setDeviceGroupsFilterCriteria(updatedDeviceGroupCriteria);
        setMyDeviceGroups((prevDeviceGroups) =>
          prevDeviceGroups.concat(myDeviceGroupsResponse.data)
        );
        setMyDeviceGroupsDataResponse(myDeviceGroupsResponse);
      },
      customErrorMessage: FETCH_DEVICES_ERROR_MESSAGE,
    });
  }, [deviceGroupsFilterCriteria, executeFetchMoreMyDeviceGroupsRequest]);

  const fetchMyDevices = useCallback(
    (deviceGroupsFilterCriteria: DeviceGroupsCriteria) =>
      executeFetchMyDevicesRequest({
        asyncFunction: async () => {
          const myDevicesResponse = await fetchMyDevicesRequest(
            deviceGroupsFilterCriteria
          );
          setMyDevicesData(myDevicesResponse);
        },
        customErrorMessage: FETCH_DEVICES_ERROR_MESSAGE,
      }),
    [executeFetchMyDevicesRequest]
  );

  const fetchMyDeviceGroups = useCallback(
    (deviceGroupsFilterCriteria: DeviceGroupsCriteria) =>
      executeFetchMyDeviceGroupsRequest({
        asyncFunction: async () => {
          const myDeviceGroupsResponse = await fetchDeviceGroups(
            deviceGroupsFilterCriteria
          );
          setMyDeviceGroups(myDeviceGroupsResponse.data);
        },
        customErrorMessage: FETCH_DEVICES_ERROR_MESSAGE,
      }),
    [executeFetchMyDeviceGroupsRequest]
  );

  const handleManageDeviceClick = useCallback(
    (newDevices: Device[], add: boolean) => {
      setSelectedDevices((devices) => {
        if (add) {
          const updatedDevices = [...devices, ...newDevices];
          return updatedDevices.filter(
            (device, index) =>
              index === updatedDevices.findIndex((d) => d.id === device.id)
          );
        } else {
          const newDevicesId = newDevices.map((device) => device.id);
          return devices.filter((device) => !newDevicesId.includes(device.id));
        }
      });
    },
    []
  );

  const onManageDeviceClick = useMemo(() => {
    const userHasDeviceGroupUpdatePermissions = userHasPermissions({
      permissions: DeviceGroupPermissions.DEVICE_GROUPS_UPDATE,
    });

    if (userHasDeviceGroupUpdatePermissions) {
      return handleManageDeviceClick;
    }
  }, [handleManageDeviceClick, userHasPermissions]);

  const handleTableFilterSubmit = useCallback(
    async (value: DeviceGroupsCriteria) => {
      const { itemsPerPage, pageNumber } = initialDeviceGroupsCriteria;
      const updatedCriteria = {
        ...value,
        itemsPerPage,
        pageNumber,
      } as DeviceGroupsCriteria;
      setDeviceGroupsFilterCriteria(updatedCriteria);
      await fetchMyDevices(updatedCriteria);
      await fetchMyDeviceGroups(updatedCriteria);
    },
    [fetchMyDeviceGroups, fetchMyDevices]
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof DeviceGroupsCriteria) => {
      const newData = { ...deviceGroupsFilterCriteria };
      newData[filterDataKey] = initialDeviceGroupsCriteria[
        filterDataKey
      ] as string & string[] & boolean;
      handleTableFilterSubmit(newData);
    },
    [deviceGroupsFilterCriteria, handleTableFilterSubmit]
  );

  const Header = useCallback(
    () => (
      <>
        <Columns marginless display="flex">
          <Columns.Column>
            <h1 className="is-size-3 has-text-weight-light">My Devices</h1>
            <h3 className="is-size-5 has-text-weight-light">{devicesText}</h3>
          </Columns.Column>
          <Columns.Column
            display="flex"
            justifyContent="flex-end"
            className="gap-3"
          >
            <CheckPermissions
              permissions={DeviceGroupPermissions.DEVICE_GROUPS_CREATE}
            >
              <CheckPermissions.Render>
                <Button
                  color="primary-dark"
                  onClick={navigateToCreateDeviceGroup}
                >
                  <Icon>
                    <FaPlus />
                  </Icon>
                  <span>Create device group</span>
                </Button>
              </CheckPermissions.Render>
            </CheckPermissions>
            <CheckPermissions
              permissions={RegistrationFilePermissions.REGISTRATION_FILES_VIEW}
            >
              <CheckPermissions.Render>
                <Button
                  className="outlined-button-white-background"
                  color="primary-dark"
                  outlined
                  onClick={navigateToDeviceRegistration}
                >
                  <span>Device registration</span>
                  <Icon>
                    <FaArrowRight />
                  </Icon>
                </Button>
              </CheckPermissions.Render>
            </CheckPermissions>
          </Columns.Column>
        </Columns>
        <If condition={appliedFilters.length > 0}>
          <Element ml={3}>
            <FilterTagList
              list={filterTagsData}
              onRemove={(item) =>
                onRemoveFilterTag(item as keyof DeviceGroupsCriteria)
              }
              title="Filters"
            />
          </Element>
        </If>
      </>
    ),
    [
      appliedFilters.length,
      devicesText,
      filterTagsData,
      navigateToCreateDeviceGroup,
      navigateToDeviceRegistration,
      onRemoveFilterTag,
    ]
  );

  const DeviceGroupActions = useCallback(
    () => (
      <If condition={selectedDevices.length > 0}>
        <Element display="flex" className="gap-2">
          <Button
            color="transparent"
            className="is-borderless"
            onClick={handleRemoveDevicesFromDeviceGroup}
          >
            <Conditional condition={isRemovingDevices}>
              <Conditional.True>
                <Icon color="primary-dark" className="animate-spin">
                  <FaSpinner />
                </Icon>
              </Conditional.True>
              <Conditional.False>
                <Icon color="primary-dark">
                  <FaMinus />
                </Icon>
              </Conditional.False>
            </Conditional>
            <span className="is-underlined has-text-primary-dark">
              Remove from group
            </span>
          </Button>
          <Button
            color="transparent"
            className="is-borderless"
            onClick={handleMoveDevicesToGroup}
          >
            <Icon color="primary-dark">
              <FaArrowRightFromBracket />
            </Icon>
            <span className="is-underlined has-text-primary-dark">
              Move to another group
            </span>
          </Button>
        </Element>
      </If>
    ),
    [
      handleMoveDevicesToGroup,
      handleRemoveDevicesFromDeviceGroup,
      isRemovingDevices,
      selectedDevices.length,
    ]
  );

  useEffect(() => {
    if (myDeviceGroupsInitialResponse) {
      setMyDeviceGroups(myDeviceGroupsInitialResponse.data);
      setMyDeviceGroupsDataResponse(myDeviceGroupsInitialResponse);
    }
    if (myDevicesDataInitialResponse) {
      setMyDevicesData(myDevicesDataInitialResponse);
    }
    setSelectedDevices([]);
  }, [myDevicesDataInitialResponse, myDeviceGroupsInitialResponse]);

  return (
    <>
      <BoxLayout
        className="is-min-height-80 mx-10 mb-4"
        headerClassName="is-radiusless"
        header={<Header />}
      >
        <Conditional condition={revalidator.state === 'loading'}>
          <Conditional.True>
            <PageSpinner />
          </Conditional.True>
          <Conditional.False>
            <SelectedDeviceTagList
              selectedDevices={selectedDevices}
              onManageDeviceClick={handleManageDeviceClick}
            />
            <DeviceGroupInboxTable
              myDevices={myDevices}
              deviceGroups={myDeviceGroups}
              fetchMoreDeviceGroups={fetchMoreMyDeviceGroups}
              hasMoreDeviceGroups={hasMoreDeviceGroups}
              onManageDeviceClick={onManageDeviceClick}
              selectedDevices={selectedDevices}
              appliedFilters={appliedFilters}
              loadingInitialData={
                isLoadingMyDevicesData || isLoadingMyDeviceGroups
              }
              onTableFilterSubmit={handleTableFilterSubmit}
              deviceGroupsCriteriaFilter={deviceGroupsFilterCriteria}
              deviceModels={deviceModels}
              leftContent={<DeviceGroupActions />}
            />
          </Conditional.False>
        </Conditional>
      </BoxLayout>

      <MoveDevicesModal
        show={isMoveDevicesModalVisible}
        selectedDevices={selectedDevices}
        deviceGroupsToBeEmptied={deviceGroupsToBeEmptied}
        deviceGroups={allAvailableDeviceGroups}
        onMoveDevicesToDeviceGroup={onMoveDevicesToDeviceGroup}
        onCancel={() => setIsMoveDevicesModalVisible(false)}
      />
      <RemoveDevicesModal
        show={isRemoveDevicesModalVisible}
        deviceGroupsToBeEmptied={deviceGroupsToBeEmptied}
        onRemoveDevicesFromDeviceGroup={onRemoveDevicesFromDeviceGroup}
        onCancel={() => setIsRemoveDevicesModalVisible(false)}
        loading={isRemovingDevices}
        title={'Remove devices'}
        confirmationButtonText={'Yes, remove devices'}
      />
    </>
  );
}

export function MyDevicesPage() {
  const { response } = useLoaderData() as DeferredResponse<
    [
      MyDevicesData,
      PaginatedAPIResponse<DeviceGroup>,
      DeviceModel[],
      BasicDeviceGroupData[]
    ]
  >;

  return (
    <ProtectedPage
      permissions={[
        DeviceGroupPermissions.DEVICE_GROUPS_READ,
        SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
      ]}
    >
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <GuidedProcessStepperTabs activeTab={GuidedProcessSteps.DEVICES} />
          <MyDevicesPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default MyDevicesPage;
