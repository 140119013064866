import {
  PermissionsGroupName,
  userIsManagerOfProvidedSalesOrders,
} from '@zspace/roles';
import { useCallback, useMemo } from 'react';
import useUser from './user';

export type UseSalesOrderPermissions = {
  userHasSalesOrderManagePermissions: (salesOrderId: string) => boolean;
  userIsJustViewOnlySalesOrderManager: boolean;
};

export function useSalesOrderPermissions(): UseSalesOrderPermissions {
  const { user } = useUser();

  const userHasSalesOrderManagePermissions = useCallback(
    (salesOrderId: string) => {
      return userIsManagerOfProvidedSalesOrders(user, salesOrderId);
    },
    [user]
  );

  const userIsJustViewOnlySalesOrderManager = useMemo(
    () =>
      (user.activeRole?.permissionsGroupUserRoleAssignments.every(
        (permissionsGroupAssignment) =>
          permissionsGroupAssignment.permissionsGroup.name ===
          PermissionsGroupName.VIEW_ONLY_SALES_ORDER_MANAGER
      ) ||
        user.activeRole?.permissionsGroupUserRoleAssignments.every(
          (permissionsGroupAssignment) =>
            permissionsGroupAssignment.permissionsGroup.name ===
            PermissionsGroupName.SHIPPING_CONTACT
        ) ||
        user.activeRole?.permissionsGroupUserRoleAssignments.every(
          (permissionsGroupAssignment) =>
            permissionsGroupAssignment.permissionsGroup.name ===
            PermissionsGroupName.BILLING_CONTACT
        )) ??
      false,
    [user.activeRole?.permissionsGroupUserRoleAssignments]
  );

  return {
    userHasSalesOrderManagePermissions,
    userIsJustViewOnlySalesOrderManager,
  };
}

export default useSalesOrderPermissions;
