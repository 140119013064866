import { Dispatch, ReactNode, SetStateAction } from 'react';

export enum InviteUserPageSteps {
  EMAIL,
  PERMISSIONS,
}

export type InviteUserPageContext = {
  email: string;
  setEmail: (value: string) => void;
  setTitle: (value: string) => void;
  setStep: (value: InviteUserPageSteps) => void;
  navigateToPermissionsHome: () => void;
  setFilterTagList: Dispatch<SetStateAction<ReactNode>>;
  internalViewMode: boolean;
};
