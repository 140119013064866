import { formatSalesOrderNumber } from '@zspace/format';
import { DeviceGroupsTableRow, SelectedSalesOrderDevices } from '@zspace/types';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { formatDateToLocaleString } from '../../shared/utils';
import InputSpinner from '../../ui/input-spinner/input-spinner';
import PaginatedTable, {
  CustomPaginatedTableProps,
} from '../../ui/table/paginated-table/paginated-table';
import { Column } from '../../ui/table/types';

export type QuantitySelection = {
  onChange: (
    selectedSalesOrderId: string,
    selectedSalesOrderNumber: string,
    selectedDeviceModelId: string,
    quantity: number
  ) => void;
  selectedDevices: SelectedSalesOrderDevices[];
};

export type DeviceGroupsTableProps =
  CustomPaginatedTableProps<DeviceGroupsTableRow> & {
    quantitySelection: QuantitySelection;
  };

export function DeviceGroupsTable({
  dataSource,
  onChange,
  config,
  form,
  empty,
  quantitySelection,
  errorMessage,
}: DeviceGroupsTableProps) {
  const columns: Column<DeviceGroupsTableRow>[] = [
    {
      key: 'number',
      widthClassname: 'is-size-20',
      render: (el) => (
        <Link to={`/sales-orders/${el.order.id}`}>
          {formatSalesOrderNumber(el.order.number)}
        </Link>
      ),
      title: 'Sales order',
      sortable: true,
    },
    {
      key: 'model_name',
      widthClassname: 'is-size-20',
      render: (el) => <p>{el.deviceModel.name}</p>,
      title: 'Device Type',
      sortable: true,
    },
    {
      key: 'purchase',
      widthClassname: 'is-size-10',
      render: (el) => formatDateToLocaleString(el.purchaseDate),
      title: 'Purchase date',
      sortable: true,
    },
    {
      key: 'close',
      widthClassname: 'is-size-10',
      render: (el) => formatDateToLocaleString(el.closeDate),
      title: 'Close date',
      sortable: true,
    },
    {
      key: 'total',
      widthClassname: 'is-size-10',
      render: (el) => el.totalDevices,
      title: 'Total',
      sortable: true,
    },
    {
      key: 'available',
      widthClassname: 'is-size-10',
      render: (el) => el.totalAvailable,
      title: 'Available',
      sortable: true,
    },
    {
      key: 'selectedQuantity',
      widthClassname: 'is-size-20',
      render: (el) => (
        <InputSpinner
          minValue={0}
          maxValue={el.totalAvailable}
          onValueChange={(value) => {
            quantitySelection.onChange(
              el.order.id,
              el.order.number,
              el.deviceModel.id,
              value
            );
          }}
          value={selectedQuantity(el)}
        />
      ),
      title: '',
      sortable: false,
    },
  ];

  const selectedQuantity = useCallback(
    (el: DeviceGroupsTableRow) =>
      quantitySelection.selectedDevices.find(
        (device) =>
          device.salesOrder.id === el.order.id &&
          device.deviceModelId === el.deviceModel.id
      )?.quantity || 0,
    [quantitySelection.selectedDevices]
  );

  return (
    <PaginatedTable
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      config={config}
      form={form}
      empty={empty}
      rowKey={'id'}
      errorMessage={errorMessage}
    />
  );
}

export default DeviceGroupsTable;
