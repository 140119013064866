import { FeaturePermissions } from '@zspace/roles';
import {
  DeferredResponse,
  DeviceHomeAlertData,
  EulaOutstandingData,
  SoftwareHomeAlertData,
} from '@zspace/types';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Columns } from 'react-bulma-components';
import {
  defer,
  LoaderFunction,
  useAsyncValue,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { fetchDevicesHomeAlertData } from '../../devices/devices-service';
import { fetchEulasOutstandingData } from '../../eula/eula-service';
import ActionsPendingModal from '../../shared/actions-pending-modal/actions-pending-modal';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import HomeCards from '../../shared/home-cards/home-cards';
import HomeHeader from '../../shared/home-header/home-header';
import useUserPendingTasks from '../../shared/hooks/user-pending-tasks';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import { fetchSoftwareSeatsHomeAlertData } from '../../software/software-service';
import BoxLayout from '../../ui/box-layout/box-layout';
import HomeButton from '../../ui/home-button/home-button';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import styles from './customers-home-page.module.scss';

type LoaderFunctionData = [
  EulaOutstandingData,
  DeviceHomeAlertData,
  SoftwareHomeAlertData
];

export const loader: LoaderFunction = async () => {
  const eulasOutstandingResponse = fetchEulasOutstandingData();
  const devicesHomeAlertResponse = fetchDevicesHomeAlertData();
  const softwareSeatsHomeAlertResponse = fetchSoftwareSeatsHomeAlertData();

  return defer({
    response: Promise.all([
      eulasOutstandingResponse,
      devicesHomeAlertResponse,
      softwareSeatsHomeAlertResponse,
    ]),
  });
};

function CustomersHomePageContent() {
  const navigate = useNavigate();
  const [salesOrdersAndEulaTasksData, devicesTasksData, softwareTasksData] =
    useAsyncValue() as LoaderFunctionData;
  const userTasks = useUserPendingTasks(
    salesOrdersAndEulaTasksData,
    devicesTasksData,
    softwareTasksData
  );
  const [showModal, setShowModal] = useState(false);
  const [cardOnClickHandler, setCardOnClickHandler] = useState<() => void>(
    () => () => {}
  );
  const [selectedSection, setSelectedSection] = useState<string>('');

  const hasAlert = useMemo(() => {
    return !![
      ...userTasks.salesOrderAndEulaPendingTasks,
      ...userTasks.devicesPendingTasks,
      ...userTasks.softwarePendingTasks,
    ].length;
  }, [
    userTasks.salesOrderAndEulaPendingTasks,
    userTasks.devicesPendingTasks,
    userTasks.softwarePendingTasks,
  ]);

  const resolveCardsOnClick = useCallback(
    (handler: () => void, sectionName: string) => {
      return () => {
        if (hasAlert) {
          setShowModal(true);
          setCardOnClickHandler(() => handler);
          setSelectedSection(sectionName);
        } else {
          handler();
        }
      };
    },
    [hasAlert]
  );

  const navigateToMyDevices = useCallback(() => {
    navigate('/my-devices');
  }, [navigate]);

  const navigateToMySoftware = useCallback(() => {
    navigate('/my-software');
  }, [navigate]);

  const navigateToMySalesOrdersAndEula = useCallback(() => {
    navigate('/my-sales-orders');
  }, [navigate]);

  const navigateToMyTeam = useCallback(() => {
    navigate('/users/my-team');
  }, [navigate]);

  const homeCards = useMemo(() => {
    const cards = [
      {
        title: 'My Sales orders',
        description:
          'Review your sales order history, their contents and outstanding EULAs.',
        pendingTasks: userTasks.salesOrderAndEulaPendingTasks,
        onClick: navigateToMySalesOrdersAndEula,
      },
      {
        title: 'My Team',
        description:
          'View all the users in your organization. Invite new ones and edit current permissions.',
        pendingTasks: [],
        onClick: navigateToMyTeam,
      },
      {
        title: 'My Devices',
        description:
          'View and rearrange all the devices in your organization and their software assignments.',
        pendingTasks: userTasks.devicesPendingTasks,
        onClick: navigateToMyDevices,
      },
      {
        title: 'My Software',
        description:
          'View all your current software titles and to which devices they have been assigned.',
        pendingTasks: userTasks.softwarePendingTasks,
        onClick: navigateToMySoftware,
      },
    ];

    return cards;
  }, [
    userTasks.salesOrderAndEulaPendingTasks,
    userTasks.devicesPendingTasks,
    userTasks.softwarePendingTasks,
    navigateToMySalesOrdersAndEula,
    navigateToMyTeam,
    navigateToMyDevices,
    navigateToMySoftware,
  ]);

  const handleGetStartedButtonClick = useCallback(() => {
    const firstStepWithPendingActions = homeCards.find(
      (card) => card.pendingTasks.length > 0
    );
    firstStepWithPendingActions?.onClick();
  }, [homeCards]);

  return (
    <BoxLayout
      className={`is-min-height-80 mx-10 my-4 ${styles.layout}`}
      childrenClassName={styles.container}
    >
      <Columns
        display="flex"
        flexDirection="column"
        className="is-min-height-80"
      >
        <Columns.Column display="flex" flexDirection="column" p={4}>
          <HomeHeader
            children={
              <If condition={hasAlert}>
                <h3
                  className={`is-size-6 has-text-weight-light has-text-primary-dark mb-8`}
                >
                  You have actions pending. Resolve them to take full advantage
                  of your zSpace products.
                </h3>
                <If condition={hasAlert}>
                  <HomeButton onClick={handleGetStartedButtonClick} />
                </If>
              </If>
            }
          />
        </Columns.Column>
        <Columns.Column>
          <HomeCards
            cards={homeCards}
            resolveCardsOnClick={resolveCardsOnClick}
          />
        </Columns.Column>
      </Columns>
      <ActionsPendingModal
        show={showModal}
        onSectionClick={cardOnClickHandler}
        selectedSection={selectedSection}
        onGetStartedClick={handleGetStartedButtonClick}
      />
    </BoxLayout>
  );
}

export function CustomersHomePage() {
  const { response } = useLoaderData() as DeferredResponse<LoaderFunctionData>;

  return (
    <ProtectedPage permissions={FeaturePermissions.RENEWALS_ACCESS}>
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <CustomersHomePageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default CustomersHomePage;
