import { faker } from '@faker-js/faker';
import { define } from 'cooky-cutter';
import { RegistrationFilesTableRow, RegistrationFileStatus } from '../types';

export const registrationFileTableRowFactory =
  define<RegistrationFilesTableRow>({
    id: () => faker.string.uuid(),
    name: () => faker.location.city(),
    createdAt: () => faker.date.recent({ days: 120 }),
    expiresAt: () => new Date(),
    status: () => faker.helpers.enumValue(RegistrationFileStatus),
    linkedDevices: () => faker.number.int(),
    registeredDevices: () => faker.number.int(),
  });
