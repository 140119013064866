import { RouteObject } from '../app';
import DeviceGroupsDashboardCardPage, {
  loader as deviceGroupsDashboardCardPageLoader,
} from './device-groups-dashboard-card-page/device-groups-dashboard-card-page';

export const routes: RouteObject[] = [
  {
    loader: deviceGroupsDashboardCardPageLoader,
    path: 'device-groups',
    element: <DeviceGroupsDashboardCardPage />,
  },
];
