import { DeviceGroupPermissions } from '@zspace/roles';
import {
  DeferredResponse,
  DeviceGroup,
  DeviceGroupDetails,
  EditDeviceGroupsTableRow,
  PaginatedAPIResponse,
} from '@zspace/types';
import { Suspense, useCallback, useMemo } from 'react';
import {
  defer,
  LoaderFunction,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import ProtectedPage from '../../shared/protected-page/protected-page';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { fetchDeviceGroup, updateDeviceGroup } from '../device-groups-service';
import EditDeviceGroupLayout from '../edit-device-group-layout/edit-device-group-layout';

const UPDATE_DEVICE_GROUP_ERROR_MESSAGE =
  'The device group could not be updated. Please try again';

export const loader: LoaderFunction = ({ params }) => {
  const groupId = params.id;
  const response = fetchDeviceGroup(groupId!);

  return defer({ response });
};

function EditDeviceGroupCardPageContent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    executeHttpRequest: executeRequest,
    isLoading: isUpdatingDeviceGroup,
  } = useHttpRequest();

  const response = useAsyncValue() as DeviceGroup;

  const navigateToMyDevices = useCallback(() => {
    navigate('/my-devices');
  }, [navigate]);

  const handleOnUpdateDeviceGroup = useCallback(
    async (deviceGroupData: DeviceGroupDetails) =>
      executeRequest({
        asyncFunction: async () => {
          await updateDeviceGroup(id!, deviceGroupData);
          navigateToMyDevices();
        },
        customErrorMessage: UPDATE_DEVICE_GROUP_ERROR_MESSAGE,
      }),
    [executeRequest, id, navigateToMyDevices]
  );

  const deviceGroupSalesOrderIds = useMemo(() => {
    const deviceGroupSalesOrdersSet = new Set(
      response.devices.map((device) => device.salesOrderLine.salesOrder.id)
    );
    return Array.from(deviceGroupSalesOrdersSet.values());
  }, [response.devices]);

  return (
    <ProtectedPage
      permissions={[DeviceGroupPermissions.DEVICE_GROUPS_UPDATE]}
      salesOrderIds={deviceGroupSalesOrderIds}
    >
      <EditDeviceGroupLayout
        deviceGroup={response}
        onCancel={navigateToMyDevices}
        onUpdateDeviceGroup={handleOnUpdateDeviceGroup}
        isUpdatingDeviceGroup={isUpdatingDeviceGroup}
        customer={true}
      />
    </ProtectedPage>
  );
}

export function EditDeviceGroupCardPage() {
  const { response } = useLoaderData() as DeferredResponse<
    PaginatedAPIResponse<EditDeviceGroupsTableRow>
  >;

  return (
    <ProtectedPage permissions={DeviceGroupPermissions.DEVICE_GROUPS_UPDATE}>
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <EditDeviceGroupCardPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default EditDeviceGroupCardPage;
