import { formatSalesOrderNumber, sanitizeExternalUrl } from '@zspace/format';
import { ApplicationVariantType, MySoftwareTableRow } from '@zspace/types';
import { ReactNode, useCallback, useMemo } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import { PiWarningFill } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import useIsCustomerUser from '../../shared/hooks/is-customer-user';
import useSalesOrderPermissions from '../../shared/hooks/sales-order-permissions';
import If from '../../shared/if/if';
import ExternalLink from '../../ui/external-link/external-link';
import InfiniteTable, {
  CustomInfiniteTableProps,
} from '../../ui/table/infinite-table/infinite-table';
import { Column } from '../../ui/table/types';
import styles from './my-software-table.module.scss';

export type MySoftwareTableProps = Omit<
  CustomInfiniteTableProps<MySoftwareTableRow>,
  'empty'
> & {
  softwareVariantType: ApplicationVariantType;
  includeSalesOrderColumn?: boolean;
};

export function MySoftwareTable({
  softwareVariantType,
  includeSalesOrderColumn = false,
  ...tableProps
}: MySoftwareTableProps) {
  const isCustomerUser = useIsCustomerUser();
  const {
    userHasSalesOrderManagePermissions,
    userIsJustViewOnlySalesOrderManager,
  } = useSalesOrderPermissions();

  const isDisabledRow = useCallback(
    (row: MySoftwareTableRow) => {
      if (!isCustomerUser) {
        return false;
      }

      const hasManagePermissions = userHasSalesOrderManagePermissions(
        row.salesOrder.id
      );
      const isViewOnlyManager = userIsJustViewOnlySalesOrderManager;

      return !hasManagePermissions && !isViewOnlyManager;
    },
    [
      isCustomerUser,
      userHasSalesOrderManagePermissions,
      userIsJustViewOnlySalesOrderManager,
    ]
  );

  const ColumnCellContainer = useMemo(
    () =>
      ({
        row,
        children,
      }: {
        row: MySoftwareTableRow;
        children?: ReactNode;
      }) => {
        if (isDisabledRow(row)) {
          return <Element className={styles.disabledCell}>{children}</Element>;
        }
        return children;
      },
    [isDisabledRow]
  );

  const columns: Column<MySoftwareTableRow>[] = useMemo(() => {
    const licensingProductGroupLinkColumn: Column<MySoftwareTableRow> = {
      key: 'licensingProductGroup.name',
      widthClassname: includeSalesOrderColumn ? 'is-size-40' : 'is-size-55',
      title: 'Software title',
      render: (el) => (
        <Link to={`/software/${el.licensingProductGroup.id}`}>
          {el.licensingProductGroup.name}
        </Link>
      ),
      sortable: true,
    };

    const licensingProductGroupColumn: Column<MySoftwareTableRow> = {
      key: 'licensingProductGroup.name',
      title: 'Software title',
      widthClassname: includeSalesOrderColumn ? 'is-size-30' : 'is-size-45',
      render: (el) => (
        <ColumnCellContainer row={el}>
          {el.licensingProductGroup.name}
        </ColumnCellContainer>
      ),
      sortable: true,
    };

    const endUserColumn: Column<MySoftwareTableRow> = {
      key: 'endUser',
      title: 'End User',
      widthClassname: 'is-size-30',
      render: (el) => (
        <ColumnCellContainer row={el}>
          {el.salesOrder.endUser}
        </ColumnCellContainer>
      ),
      sortable: true,
    };

    const softwareTypeColumn: Column<MySoftwareTableRow> = {
      key: 'software.type',
      title: 'Software type',
      widthClassname: 'is-size-10',
      render: (el) => (
        <ColumnCellContainer row={el}>Web-based</ColumnCellContainer>
      ),
      sortable: false,
    };

    const knowledgeArticleColumn: Column<MySoftwareTableRow> = {
      key: 'knowledgeArticleUrl',
      title: 'How to access',
      widthClassname: 'is-size-15',
      render: (el) => {
        if (!el.knowledgeArticleUrl) {
          return <ColumnCellContainer row={el}>N/A</ColumnCellContainer>;
        }

        return (
          <ColumnCellContainer row={el}>
            <ExternalLink to={sanitizeExternalUrl(el.knowledgeArticleUrl)}>
              <Icon color="primary">
                <FaArrowUpRightFromSquare />
              </Icon>
              <span className="has-text-primary is-underlined">
                Documentation
              </span>
            </ExternalLink>
          </ColumnCellContainer>
        );
      },
      sortable: false,
    };

    const assignedSeatsColumn: Column<MySoftwareTableRow> = {
      key: 'seats.assigned',
      widthClassname: 'is-size-15',
      title: 'Assigned seats',
      render: (el) => (
        <ColumnCellContainer row={el}>{el.seats?.assigned}</ColumnCellContainer>
      ),
      sortable: true,
    };

    const availableSeatsColumn: Column<MySoftwareTableRow> = {
      key: 'seats.available',
      widthClassname: 'is-size-15',
      title: 'Unassigned seats',
      render: (el) => (
        <>
          <If condition={!!el.seats && el.seats?.available > 0}>
            <PiWarningFill className="has-text-danger-dark mr-2" />
          </If>
          {el.seats!.available}
        </>
      ),
      sortable: true,
    };

    const totalSeatsColumn: Column<MySoftwareTableRow> = {
      key: 'seats.total',
      widthClassname: 'is-size-15',
      title: 'Total seats',
      render: (el) => (
        <ColumnCellContainer row={el}>{el.seats?.total}</ColumnCellContainer>
      ),
      sortable: true,
    };

    const salesOrderColumn: Column<MySoftwareTableRow> = {
      key: 'salesOrder.number',
      widthClassname: 'is-size-15',
      title: 'SO#',
      render: (el) => (
        <Element display="flex" alignItems="center" className="gap-6">
          <Link to={`/sales-orders/${el.salesOrder.id}`}>
            {formatSalesOrderNumber(el.salesOrder.number)}
          </Link>
        </Element>
      ),
      sortable: true,
    };

    const webVariantColumns = [
      licensingProductGroupColumn,
      endUserColumn,
      softwareTypeColumn,
      knowledgeArticleColumn,
    ];

    const nativeVariantColumns = [
      licensingProductGroupLinkColumn,
      assignedSeatsColumn,
      availableSeatsColumn,
      totalSeatsColumn,
    ];

    const baseColumns =
      softwareVariantType === ApplicationVariantType.NATIVE
        ? nativeVariantColumns
        : webVariantColumns;

    if (includeSalesOrderColumn) {
      baseColumns.splice(1, 0, salesOrderColumn);
    }

    return baseColumns;
  }, [ColumnCellContainer, includeSalesOrderColumn, softwareVariantType]);

  const EmptyTable = useCallback(
    () => (
      <Element
        display="flex"
        justifyContent="center"
        alignItems="center"
        className="h-full"
      >
        <span className="has-text-weight-light">
          There are no software at the moment
        </span>
      </Element>
    ),
    []
  );

  return (
    <InfiniteTable
      {...tableProps}
      rowKey="id"
      columns={columns}
      isDisabledSelection={isDisabledRow}
      empty={<EmptyTable />}
    />
  );
}

export default MySoftwareTable;
