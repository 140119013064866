import { ReactNode, useMemo } from 'react';
import { Element, Tag } from 'react-bulma-components';

type BulmaTagProps = React.ComponentProps<typeof Tag>;

export type TagListProps<T> = {
  list: T[];
  renderItem: (item: T) => ReactNode;
  itemColor?: (item: T) => BulmaTagProps['color'];
  colorVariant?: BulmaTagProps['colorVariant'];
  title?: ReactNode;
  onRemove?: (item: T) => void;
};

export function TagList<T>({
  list,
  renderItem,
  title,
  itemColor = (_) => {
    return 'primary';
  },
  colorVariant = 'light',
  onRemove,
}: TagListProps<T>) {
  const shouldDisplayRemoveButton = useMemo(
    () => typeof onRemove === 'function',
    [onRemove]
  );

  return (
    <Element
      display="flex"
      flexWrap="wrap"
      className="w-full"
      style={{ opacity: list.length ? 1 : 0 }}
    >
      {title}

      {list.map((item, index) => (
        <Tag.Group
          key={`${item}-${JSON.stringify(item)}`}
          hasAddons={shouldDisplayRemoveButton}
          className={`mr-2 ${index === list.length - 1 ? 'mb-4' : ''}`}
          role="listitem"
        >
          {shouldDisplayRemoveButton && (
            <Tag
              rounded
              remove
              onClick={() => {
                onRemove?.(item);
              }}
              backgroundColor={itemColor(item)?.toString()}
              colorVariant={colorVariant}
              className="has-background-primary"
            />
          )}
          <Tag
            rounded
            backgroundColor={itemColor(item)?.toString()}
            colorVariant={colorVariant}
          >
            {renderItem(item)}
          </Tag>
        </Tag.Group>
      ))}
    </Element>
  );
}

export default TagList;
