import {
  Fragment,
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Element, Icon } from 'react-bulma-components';
import { useDebouncedCallback } from 'use-debounce';
import Conditional from '../../../shared/conditional/conditional';
import If from '../../../shared/if/if';
import AnimatedChevronIcon from '../../animated-chevron-icon/animated-chevron-icon';
import Button from '../../button/button';
import Checkbox from '../../checkbox/checkbox';
import { Tooltip } from '../../tooltip/tooltip';
import TableRowCell from '../table-row-cell/table-row-cell';
import TableRowExpandableContent from '../table-row-expandable-content/table-row-expandable-content';
import { Column, ExpandableRow, RowInteraction, RowSelection } from '../types';
import styles from './table-row.module.scss';
import { resolveRowKey } from './utils';

export type TableRowCheckboxTooltip = {
  message: ReactNode;
  backgroundColor?: string;
};

export type TableRowProps<T, K = unknown> = {
  el: T;
  rowKey: keyof T;
  columns: Column<T, K>[];
  resolveRowClassName: (row: T, checked?: boolean) => string | undefined;
  isInvalidExpandableRowValue?: (row: K) => boolean;
  rowSelection?: RowSelection;
  rowInteraction?: RowInteraction;
  isDisabledSelection?: (row: T) => boolean;
  checkboxTooltip?: (row: T) => TableRowCheckboxTooltip;
  expandableRow?: ExpandableRow<T, K>;
};

export function TableRow<T, K = unknown>({
  el,
  rowKey,
  columns,
  resolveRowClassName,
  isInvalidExpandableRowValue,
  rowSelection,
  rowInteraction,
  isDisabledSelection,
  checkboxTooltip,
  expandableRow,
}: TableRowProps<T, K>) {
  const [isExpanded, setIsExpanded] = useState<boolean>();

  const _rowKey = useMemo(() => resolveRowKey(el, rowKey), [el, rowKey]);

  const isChecked = useMemo(
    () => rowSelection?.selectedRowKeys.includes(_rowKey),
    [_rowKey, rowSelection?.selectedRowKeys]
  );
  const isDisabled = useMemo(
    () => isDisabledSelection?.(el),
    [el, isDisabledSelection]
  );

  const displayCheckbox = useCallback(
    (index: number) => !!rowSelection && index === 0 && !isDisabled,
    [isDisabled, rowSelection]
  );

  const resolveExpandableRowClassName = useCallback(
    (row: K) => {
      const defaultClassName = `${styles.expandableRow} ${
        isInvalidExpandableRowValue?.(row)
          ? 'has-background-danger-light'
          : 'has-background-white-bis'
      }`;

      const stateClassName = isExpanded ? styles.expanded : styles.collapsed;
      return `${defaultClassName} ${stateClassName}`;
    },
    [isExpanded, isInvalidExpandableRowValue]
  );

  const expandable = useMemo(
    () => expandableRow?.expandable(el),
    [el, expandableRow]
  );

  const handleExpandRow = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setIsExpanded((previousIsCollapsed) => !previousIsCollapsed);
    },
    []
  );

  const handleMouseEnter = useDebouncedCallback(() => {
    rowInteraction?.onRowEnter(_rowKey);
  }, 100);

  const handleMouseLeave = useDebouncedCallback(() => {
    rowInteraction?.onRowLeave(_rowKey);
  }, 100);

  const handleRowClick = useCallback(() => {
    if (!isDisabled) {
      if (rowSelection) {
        rowSelection.onChange([_rowKey]);
      }

      if (expandableRow) {
        setIsExpanded((previousIsCollapsed) => !previousIsCollapsed);
      }
    }
  }, [_rowKey, expandableRow, isDisabled, rowSelection]);

  const handleCheckboxClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <Fragment>
      <tr
        key={_rowKey}
        className={resolveRowClassName(el, isChecked)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleRowClick}
      >
        <If condition={!!expandableRow}>
          <Conditional condition={!!expandable}>
            <Conditional.True>
              <td className="p-0">
                <Button
                  className={styles.expandButton}
                  text
                  onClick={handleExpandRow}
                >
                  <Icon>
                    <AnimatedChevronIcon active={!!isExpanded} />
                  </Icon>
                </Button>
              </td>
            </Conditional.True>
            <Conditional.False>
              <td />
            </Conditional.False>
          </Conditional>
        </If>
        {columns.map((col, index) => (
          <td
            className="is-align-items-center"
            key={`${_rowKey}-${col.key}`}
            style={col.noWrap ? { whiteSpace: 'nowrap' } : {}}
          >
            <Conditional condition={displayCheckbox(index)}>
              <Conditional.True>
                <Element display="flex" alignItems="center" className="gap-7">
                  <Checkbox
                    onClick={handleCheckboxClick}
                    onChange={handleRowClick}
                    checked={isChecked}
                  />
                  <span>
                    <TableRowCell col={col} row={el} />
                  </span>
                </Element>
              </Conditional.True>
              <Conditional.False>
                <span>
                  <Tooltip
                    message={checkboxTooltip?.(el).message}
                    backgroundColor={checkboxTooltip?.(el).backgroundColor}
                  >
                    <TableRowCell col={col} row={el} />
                  </Tooltip>
                </span>
              </Conditional.False>
            </Conditional>
          </td>
        ))}
      </tr>
      <If condition={!!expandable && isExpanded !== undefined}>
        {expandableRow?.expandableRowIterator(el).map((item, index) => (
          <TableRowExpandableContent
            columns={columns}
            item={item}
            rowClassName={resolveExpandableRowClassName(item)}
            key={`${_rowKey}-expandable-${index}`}
            parentElement={el}
          />
        ))}
      </If>
    </Fragment>
  );
}

export default TableRow;
