import { DeviceGroupPermissions } from '@zspace/roles';
import {
  Criteria,
  DeviceGroup,
  DeviceGroupDetails,
  EditDeviceGroupsTableRow,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  SortDirection,
} from '@zspace/types';
import { useCallback, useMemo, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import { FaCheck, FaCircleInfo, FaTrash } from 'react-icons/fa6';
import { useNavigation, useSearchParams } from 'react-router-dom';
import CancelAlertModal from '../../shared/cancel-alert-modal/cancel-alert-modal';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import Conditional from '../../shared/conditional/conditional';
import useUpdateUrl from '../../shared/hooks/url';
import If from '../../shared/if/if';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { OnTableChangeData } from '../../ui/table/types';
import DeviceGroupDetailsForm, {
  DeviceGroupDetailsFormData,
} from '../create-device-group-layout/device-group-form/device-group-details-form';
import DeviceGroupConfirmDeletionModal from '../device-group-confirm-deletion-modal/device-group-confirm-deletion-modal';
import EditDeviceGroupsTable from './edit-device-groups-table/edit-device-groups-table';

const validateForm = {
  name: (value: string) =>
    !value.trim() ? 'Please input a device group name' : null,
  description: null,
};

const CANCEL_ALERT_MODAL_TITLE = 'Cancel device group';
const CANCEL_ALERT_MODAL_SUBTITLE =
  'Are you sure you want to cancel the device group editing process?\nYou will lose all your progress';

export type EditDeviceGroupLayoutProps = {
  deviceGroup: DeviceGroup;
  devices?: PaginatedAPIResponse<EditDeviceGroupsTableRow>;
  onCancel: () => void;
  onUpdateDeviceGroup: (deviceGroupData: DeviceGroupDetails) => void;
  onDeleteDeviceGroup?: () => void;
  isUpdatingDeviceGroup: boolean;
  isDeletingDeviceGroup?: boolean;
  customer: boolean;
};

function EditDeviceGroupLayout({
  deviceGroup,
  devices,
  onCancel,
  onUpdateDeviceGroup,
  onDeleteDeviceGroup = () => {},
  isUpdatingDeviceGroup,
  isDeletingDeviceGroup = false,
  customer,
}: EditDeviceGroupLayoutProps) {
  const [searchParams] = useSearchParams();
  const navigation = useNavigation();
  const updateUrl = useUpdateUrl();

  const [formData, setFormData] = useState<DeviceGroupDetailsFormData>(() => {
    return {
      name: {
        value: deviceGroup?.name ?? '',
        touched: false,
        error: null,
      },
      description: {
        value: deviceGroup?.description ?? '',
        touched: false,
        error: null,
      },
    };
  });
  const [data, setData] = useState<Criteria>({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: searchParams.get('sortBy') ?? '',
    sortDirection: (searchParams.get('sortDirection') as SortDirection) ?? '',
  });
  const [displayDeleteConfirmationModal, setDisplayDeleteConfirmationModal] =
    useState(false);
  const [isCancelAlertModalVisible, setIsCancelAlertModalVisible] =
    useState(false);

  const isDataLoading = useMemo(
    () => navigation.state === 'loading',
    [navigation.state]
  );

  const tableData: EditDeviceGroupsTableRow[] = useMemo(
    () => devices?.data ?? [],
    [devices?.data]
  );

  const tableConfig: PaginatedContentConfig = useMemo(
    () => ({ ...data, pages: devices?.pages ?? 0 }),
    [data, devices?.pages]
  );

  const handleOnTableChange = useCallback(
    (value: OnTableChangeData<EditDeviceGroupsTableRow>): void => {
      const newData = {
        ...data,
        ...value.config,
        sortBy: value.column?.key || data.sortBy,
      };

      setData(newData);
      updateUrl(newData);
    },
    [data, updateUrl]
  );

  const handleOnEditDeviceGroup = useCallback(async () => {
    const formValidation = Object.keys(formData).reduce(
      (acc, key) => {
        const formDataKey = key as keyof DeviceGroupDetails;
        const error = validateForm[formDataKey]?.(formData[formDataKey].value);
        setFormData((prev) => ({
          ...prev,
          [formDataKey]: { ...prev[formDataKey], error, touched: true },
        }));
        return {
          values: { ...acc.values, [key]: formData[formDataKey].value },
          errors: { ...acc.errors, [key]: error },
        };
      },
      { values: {}, errors: {} }
    );
    const isValidForm = Object.values(formValidation.errors).every(
      (fieldError) => !fieldError
    );
    if (isValidForm) {
      const newDeviceGroupData: DeviceGroupDetails = {
        name: formData.name.value,
        description: formData.description.value ?? '',
      };
      onUpdateDeviceGroup(newDeviceGroupData);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [formData, onUpdateDeviceGroup]);

  const handleOnDeleteDeviceGroup = useCallback(() => {
    setDisplayDeleteConfirmationModal(true);
  }, []);

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Element className="p-4">
          <Columns>
            <Columns.Column>
              <span className="is-size-3 has-text-weight-light">
                Edit device group
              </span>
            </Columns.Column>
          </Columns>
        </Element>
      }
    >
      <DeviceGroupDetailsForm
        data={formData}
        onChangeData={setFormData}
        validate={validateForm}
      />
      <If condition={!customer}>
        <Conditional condition={isDataLoading}>
          <Conditional.True>
            <PageSpinner />
          </Conditional.True>
          <Conditional.False>
            <section>
              <Element display="flex" alignItems="center" mb={4}>
                <div>
                  <h2 className="subtitle is-size-5">Sales orders included</h2>
                </div>
              </Element>
              <Element display="flex" alignItems="center" mb={4}>
                <Icon className="has-text-primary-dark is-justify-content-start">
                  <FaCircleInfo />
                </Icon>
                <span className="is-size" style={{ fontSize: 14 }}>
                  You cannot modify included devices once the group has been
                  created. If you want to modify device quantities, please
                  delete the group and create a new one
                </span>
              </Element>
              <EditDeviceGroupsTable
                dataSource={tableData}
                onChange={handleOnTableChange}
                config={tableConfig}
              />
            </section>
          </Conditional.False>
        </Conditional>
      </If>
      <section
        className="is-flex is-justify-content-flex-end mt-8"
        style={{ gap: '1rem' }}
      >
        <Button
          color="primary-dark"
          outlined
          onClick={() => setIsCancelAlertModalVisible(true)}
        >
          Cancel
        </Button>
        <CheckPermissions
          permissions={DeviceGroupPermissions.DEVICE_GROUPS_DELETE}
        >
          <CheckPermissions.Render>
            <Button
              color="danger-dark"
              outlined
              onClick={handleOnDeleteDeviceGroup}
            >
              <Icon>
                <FaTrash />
              </Icon>
              <span>Delete device group</span>
            </Button>
          </CheckPermissions.Render>
        </CheckPermissions>
        <Button
          color="primary-dark"
          onClick={handleOnEditDeviceGroup}
          isExecutingAction={isUpdatingDeviceGroup}
        >
          <Button.LoadingIcon icon={FaCheck} />
          <span>Save and continue</span>
        </Button>
      </section>

      <DeviceGroupConfirmDeletionModal
        show={displayDeleteConfirmationModal}
        deviceGroupName={deviceGroup!.name}
        loading={isDeletingDeviceGroup}
        onCancel={() => setDisplayDeleteConfirmationModal(false)}
        onDelete={onDeleteDeviceGroup}
      />
      <CancelAlertModal
        show={isCancelAlertModalVisible}
        title={CANCEL_ALERT_MODAL_TITLE}
        subtitle={CANCEL_ALERT_MODAL_SUBTITLE}
        onCancel={onCancel}
        onClose={() => setIsCancelAlertModalVisible(false)}
      />
    </BoxLayout>
  );
}

export default EditDeviceGroupLayout;
