import { capitalizeString, formatWorkOrderNumber } from '@zspace/format';
import { FeaturePermissions, WorkOrderPermissions } from '@zspace/roles';
import {
  DeferredResponse,
  WorkOrderDetailsData,
  WorkOrderStatus,
} from '@zspace/types';
import { ReactNode, Suspense, useMemo, useState } from 'react';
import { Columns, Element } from 'react-bulma-components';
import {
  FaBuilding,
  FaCalendar,
  FaGears,
  FaLaptop,
  FaUser,
} from 'react-icons/fa6';
import {
  Link,
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
} from 'react-router-dom';
import DeviceAssignmentsTable from '../../device-groups/device-assignments-table/device-assignments-table';
import DeviceGroupTable from '../../device-groups/device-group-table/device-group-table';
import BackButton from '../../shared/back-button/back-button';
import Conditional from '../../shared/conditional/conditional';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import { formatDateToLocaleString } from '../../shared/utils';
import BoxLayout from '../../ui/box-layout/box-layout';
import DetailsCard from '../../ui/details-card/details-card';
import InfoLabel from '../../ui/info-label/info-label';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import ViewAllList from '../../ui/view-all-list/view-all-list';
import { fetchWorkOrderDetails } from '../work-orders-service';

export const loader: LoaderFunction = async ({ params }) => {
  const workOrderId = params.id;
  const response = fetchWorkOrderDetails(workOrderId as string);

  return defer({ response });
};

export function WorkOrderDetailsPageContent() {
  const workOrderDetailsData = useAsyncValue() as WorkOrderDetailsData;
  const [filters, setFilters] = useState<ReactNode>(null);

  const title = useMemo(() => {
    const workOrder = workOrderDetailsData.workOrder;
    return `${formatWorkOrderNumber(workOrder.number)} - ${
      workOrder.name
    } details`;
  }, [workOrderDetailsData]);

  const accounts = useMemo(() => {
    const accountsList = [
      ...new Map(
        workOrderDetailsData.workOrder.salesOrders.map((so) => [
          so.opportunity?.account.id,
          so.opportunity?.account,
        ])
      ).values(),
    ];

    return (
      <ViewAllList
        list={accountsList}
        horizontalList
        renderItem={(account) => (
          <Link to={`/accounts/${account?.id}`}>{account?.name}</Link>
        )}
        separator=", "
      />
    );
  }, [workOrderDetailsData]);

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Element className="p-4">
          <BackButton />

          <h1 className="is-size-3 has-text-weight-light">{title}</h1>
          {filters}
        </Element>
      }
    >
      <Columns className="p-4">
        <Columns.Column size="one-fifth">
          <Element>
            <DetailsCard title="Basic Information">
              <InfoLabel
                icon={<FaBuilding />}
                label="Accounts"
                content={accounts}
              />
              <InfoLabel
                icon={<FaLaptop />}
                label="Devices"
                content={workOrderDetailsData.devices.length.toString()}
              />
              <InfoLabel
                icon={<FaUser />}
                label="Created by"
                content={workOrderDetailsData.workOrder.createdBy}
              />
              <InfoLabel
                icon={<FaGears />}
                label="Status"
                content={capitalizeString(
                  workOrderDetailsData.workOrder.currentStatus.status
                )}
              />
              <InfoLabel
                icon={<FaCalendar />}
                label="Creation date"
                content={formatDateToLocaleString(
                  workOrderDetailsData.workOrder.createdAt
                )}
              />
              <If
                condition={
                  workOrderDetailsData.workOrder.currentStatus.status ===
                  WorkOrderStatus.QUEUE
                }
              >
                <InfoLabel
                  icon={<FaCalendar />}
                  label="Priority"
                  content={workOrderDetailsData.workOrder.priority}
                />
              </If>
              <If
                condition={
                  workOrderDetailsData.workOrder.currentStatus.status ===
                  WorkOrderStatus.COMPLETED
                }
              >
                <InfoLabel
                  icon={<FaCalendar />}
                  label="Completed by Genesis date"
                  content={formatDateToLocaleString(
                    workOrderDetailsData.workOrder.currentStatus.createdAt
                  )}
                />
              </If>
            </DetailsCard>
          </Element>
        </Columns.Column>
        <Columns.Column size="four-fifths">
          <Conditional
            condition={
              workOrderDetailsData.workOrder.currentStatus.status ===
              WorkOrderStatus.COMPLETED
            }
          >
            <Conditional.True>
              <DeviceAssignmentsTable
                data={workOrderDetailsData.devices}
                setFilters={setFilters}
              />
            </Conditional.True>
            <Conditional.False>
              <DeviceGroupTable
                data={workOrderDetailsData.devices}
                setFilters={setFilters}
              />
            </Conditional.False>
          </Conditional>
        </Columns.Column>
      </Columns>
    </BoxLayout>
  );
}

export function WorkOrderDetailsPage() {
  const { response } =
    useLoaderData() as DeferredResponse<WorkOrderDetailsData>;

  return (
    <ProtectedPage
      permissions={[
        FeaturePermissions.FULL_CONFIGURATION_ACCESS,
        WorkOrderPermissions.WORK_ORDERS_QUEUE_READ,
        WorkOrderPermissions.WORK_ORDERS_COMPLETED_READ,
      ]}
    >
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <WorkOrderDetailsPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default WorkOrderDetailsPage;
