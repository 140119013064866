import { ComponentProps } from 'react';
import { Element } from 'react-bulma-components';
import Spinner from '../spinner/spinner';

type SpinnerProps = ComponentProps<typeof Spinner>;

export function PageSpinner(props: SpinnerProps) {
  return (
    <Element
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="is-min-height-50"
    >
      <Spinner {...props} />
    </Element>
  );
}

export default PageSpinner;
