import {
  DeferredResponse,
  DeviceGroupDetails,
  EditDeviceGroupsTableRow,
  PaginatedAPIResponse,
  SortDirection,
  WorkOrderDetailPageContext,
} from '@zspace/types';
import { Suspense, useCallback, useMemo } from 'react';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import {
  deleteDeviceGroupById,
  updateDeviceGroup,
} from '../../device-groups/device-groups-service';
import EditDeviceGroupLayout from '../../device-groups/edit-device-group-layout/edit-device-group-layout';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import { createSearchParams } from '../../shared/url';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { fetchWorkOrderDeviceGroupEditDevices } from '../work-orders-service';

const EDIT_DEVICE_GROUP_ERROR_MESSAGE =
  'The device group could not be updated. Please try again';
const DELETE_DEVICE_GROUP_ERROR_MESSAGE =
  'The device group could not be deleted. Please try again';

export const loader: LoaderFunction = ({ params, request }) => {
  const searchParams = createSearchParams(request);
  const workOrderId = params.id;
  const groupId = params.groupId;

  const response = fetchWorkOrderDeviceGroupEditDevices(
    workOrderId!,
    groupId!,
    {
      itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
      pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
      search: searchParams.get('search') ?? '',
      sortBy: searchParams.get('sortBy') ?? '',
      sortDirection: (searchParams.get('sortDirection') as SortDirection) ?? '',
    }
  );

  return defer({ response });
};

function EditDeviceGroupCardPageContent() {
  const { id, groupId } = useParams();

  const { workOrder, revalidate } =
    useOutletContext<WorkOrderDetailPageContext>();

  const navigate = useNavigate();

  const response =
    useAsyncValue() as PaginatedAPIResponse<EditDeviceGroupsTableRow>;

  const { executeHttpRequest, isLoading } = useHttpRequest();

  const deviceGroup = useMemo(
    () =>
      workOrder.deviceGroups.find((deviceGroup) => deviceGroup.id === groupId),
    [groupId, workOrder.deviceGroups]
  );

  const navigateToWorkOrderDeviceGroupsDetails = useCallback(() => {
    navigate(`/work-orders/${id}/device-groups`);
  }, [id, navigate]);

  const handleOnEditDeviceGroup = useCallback(
    (deviceGroupData: DeviceGroupDetails) =>
      executeHttpRequest({
        asyncFunction: async () => {
          await updateDeviceGroup(groupId!, deviceGroupData);
          revalidate();
          navigateToWorkOrderDeviceGroupsDetails();
        },
        customErrorMessage: EDIT_DEVICE_GROUP_ERROR_MESSAGE,
      }),
    [
      executeHttpRequest,
      groupId,
      navigateToWorkOrderDeviceGroupsDetails,
      revalidate,
    ]
  );

  const handleOnDeleteDeviceGroup = useCallback(
    () =>
      executeHttpRequest({
        asyncFunction: async () => {
          await deleteDeviceGroupById(groupId!);
          revalidate();
          navigateToWorkOrderDeviceGroupsDetails();
        },
        customErrorMessage: DELETE_DEVICE_GROUP_ERROR_MESSAGE,
      }),
    [
      executeHttpRequest,
      groupId,
      navigateToWorkOrderDeviceGroupsDetails,
      revalidate,
    ]
  );

  return (
    <EditDeviceGroupLayout
      deviceGroup={deviceGroup!}
      devices={response}
      onCancel={navigateToWorkOrderDeviceGroupsDetails}
      onUpdateDeviceGroup={handleOnEditDeviceGroup}
      onDeleteDeviceGroup={handleOnDeleteDeviceGroup}
      isUpdatingDeviceGroup={isLoading}
      isDeletingDeviceGroup={isLoading}
      customer={false}
    />
  );
}

export function EditDeviceGroupCardPage() {
  const { response } = useLoaderData() as DeferredResponse<
    PaginatedAPIResponse<EditDeviceGroupsTableRow>
  >;

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <EditDeviceGroupCardPageContent />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default EditDeviceGroupCardPage;
