import { getUserAllowedPermissionsGroupsToManage } from '@zspace/roles';
import { useMemo } from 'react';
import useUser from './user';

export function useUserAllowedPermissionsGroupsToManage() {
  const { user } = useUser();

  const userAllowedPermissionsGroupsToManage = useMemo(
    () => getUserAllowedPermissionsGroupsToManage(user),
    [user]
  );

  return userAllowedPermissionsGroupsToManage;
}

export default useUserAllowedPermissionsGroupsToManage;
