import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { Element } from 'react-bulma-components';
import Conditional from '../../shared/conditional/conditional';
import If from '../../shared/if/if';

export type ViewAllListProps<T> = {
  list: T[];
  renderItem: (el: T) => ReactNode;
  horizontalList?: boolean;
  separator?: ReactNode;
  displayLimit?: number;
};

export function ViewAllList<T>({
  list,
  renderItem,
  horizontalList = false,
  separator,
  displayLimit: limit = 3,
}: ViewAllListProps<T>) {
  const [viewAll, setViewAll] = useState(false);
  const visibleItems = useMemo(() => {
    return viewAll ? list : list.slice(0, limit);
  }, [list, limit, viewAll]);

  const handleViewAll = useCallback(() => {
    setViewAll(true);
  }, []);

  const handleViewLess = useCallback(() => {
    setViewAll(false);
  }, []);

  const RenderItem = useCallback(
    ({ item }: { item: T }) => renderItem(item),
    [renderItem]
  );

  return (
    <Element>
      <Conditional condition={horizontalList}>
        <Conditional.True>
          {visibleItems.map((item, index) => (
            <Fragment key={`${index}-${JSON.stringify(item)}`}>
              <RenderItem item={item} />
              <Conditional condition={index === visibleItems.length}>
                <Conditional.True>{` `}</Conditional.True>
                <Conditional.False>
                  <If
                    condition={!!separator && index !== visibleItems.length - 1}
                  >
                    {separator}
                  </If>
                </Conditional.False>
              </Conditional>
            </Fragment>
          ))}
        </Conditional.True>
        <Conditional.False>
          {visibleItems.map((item, index) => (
            <Element key={`${index}-${JSON.stringify(item)}`} role="listitem">
              <RenderItem item={item} />
            </Element>
          ))}
        </Conditional.False>
      </Conditional>

      <If condition={list.length > limit && !viewAll}>
        <Element role="button" renderAs="a" onClick={handleViewAll}>
          View all
        </Element>
      </If>

      <If condition={viewAll}>
        <Element role="button" renderAs="a" onClick={handleViewLess}>
          View less
        </Element>
      </If>
    </Element>
  );
}

export default ViewAllList;
