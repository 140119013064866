import {
  Assignment,
  NewAssignmentFromReview,
  NewAssignments,
  PaginatedAPIResponse,
  SalesOrder,
  SoftwareAssignmentReviewData,
  SoftwareCriteria,
  SoftwareTableRow,
} from '@zspace/types';
import { http } from '../shared/http';

export interface SoftwareAssignmentService {
  fetchAvailableSoftwareSalesOrdersForAssignment: (
    criteria: SoftwareCriteria
  ) => Promise<PaginatedAPIResponse<SalesOrder>>;
  fetchAvailableSoftwareBySalesOrderForAssignment: (
    salesOrderId: string,
    criteria: SoftwareCriteria
  ) => Promise<PaginatedAPIResponse<SoftwareTableRow>>;
  assignSoftwareFromReview(
    assignments: NewAssignmentFromReview[]
  ): Promise<Assignment[]>;
  fetchSoftwareAssignmentReview: (
    assignments: NewAssignments
  ) => Promise<SoftwareAssignmentReviewData>;
}

export class SoftwareAssignmentImpl implements SoftwareAssignmentService {
  public async fetchAvailableSoftwareSalesOrdersForAssignment(
    criteria: SoftwareCriteria
  ): Promise<PaginatedAPIResponse<SalesOrder>> {
    const { data } = await http(
      `/v1/software/available/sales-orders?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchAvailableSoftwareBySalesOrderForAssignment(
    salesOrderId: string,
    criteria: SoftwareCriteria
  ): Promise<PaginatedAPIResponse<SoftwareTableRow>> {
    const { data } = await http(
      `/v1/software/available/sales-orders/${salesOrderId}?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async assignSoftwareFromReview(
    assignments: NewAssignmentFromReview[]
  ): Promise<Assignment[]> {
    const { data } = await http.post(`/v1/devices/software`, assignments);

    return data;
  }

  public async fetchSoftwareAssignmentReview(
    assignments: NewAssignments
  ): Promise<SoftwareAssignmentReviewData> {
    const { data } = await http.post(`/v1/software/review`, assignments);

    return data;
  }
}

export const fetchAvailableSoftwareSalesOrderForAssignment = async (
  criteria: SoftwareCriteria
): Promise<PaginatedAPIResponse<SalesOrder>> => {
  const service = new SoftwareAssignmentImpl();

  return service.fetchAvailableSoftwareSalesOrdersForAssignment(criteria);
};

export const fetchAvailableSoftwareBySalesOrderForAssignment = async (
  salesOrderId: string,
  criteria: SoftwareCriteria
): Promise<PaginatedAPIResponse<SoftwareTableRow>> => {
  const service = new SoftwareAssignmentImpl();

  return service.fetchAvailableSoftwareBySalesOrderForAssignment(
    salesOrderId,
    criteria
  );
};

export const assignSoftwareFromReview = async (
  assignments: NewAssignmentFromReview[]
): Promise<Assignment[]> => {
  const service = new SoftwareAssignmentImpl();

  return service.assignSoftwareFromReview(assignments);
};

export const fetchSoftwareAssignmentReview = async (
  assignments: NewAssignments
): Promise<SoftwareAssignmentReviewData> => {
  const service = new SoftwareAssignmentImpl();

  return service.fetchSoftwareAssignmentReview(assignments);
};
