import { formatSalesOrderNumber } from '@zspace/format';
import {
  LicenseKeysExpandableTableRow,
  LicenseKeysTableRow,
} from '@zspace/types';
import { useCallback, useMemo } from 'react';
import { Element } from 'react-bulma-components';
import { PiWarningFill } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import Conditional from '../../shared/conditional/conditional';
import useSalesOrderPermissions from '../../shared/hooks/sales-order-permissions';
import If from '../../shared/if/if';
import { formatDateToLocaleString } from '../../shared/utils';
import MaskedField from '../../ui/masked-field/masked-field';
import PaginatedTable, {
  CustomPaginatedTableProps,
} from '../../ui/table/paginated-table/paginated-table';
import {
  Column,
  ExpandableRow,
  OnTableChangeFunction,
} from '../../ui/table/types';

export type LicenseKeysTableProps =
  CustomPaginatedTableProps<LicenseKeysTableRow> & {
    onChange: OnTableChangeFunction<
      LicenseKeysTableRow,
      LicenseKeysExpandableTableRow
    >;
  };

export function LicenseKeysTable({
  dataSource,
  config,
  onChange,
  form,
}: LicenseKeysTableProps) {
  const {
    userIsJustViewOnlySalesOrderManager,
    userHasSalesOrderManagePermissions,
  } = useSalesOrderPermissions();

  const isExpandableRow = useCallback((row: LicenseKeysTableRow) => {
    return row.seats.length > 1;
  }, []);

  const LicenseKeyNotAssigned = useCallback(({ hasWarningIcon = false }) => {
    return (
      <Element display="flex" alignItems="center">
        <If condition={hasWarningIcon}>
          <PiWarningFill className="has-text-danger-dark mr-1" />
        </If>
        <span className="is-italic">None</span>
      </Element>
    );
  }, []);

  const columns: Column<LicenseKeysTableRow, LicenseKeysExpandableTableRow>[] =
    useMemo(
      () => [
        {
          key: 'key',
          widthClassname: 'is-size-25',
          render: (el) => (
            <MaskedField
              value={el.key}
              disabled={
                userIsJustViewOnlySalesOrderManager ||
                !userHasSalesOrderManagePermissions(el.salesOrder.id)
              }
            />
          ),
          title: 'License key',
          sortable: false,
          noWrap: true,
        },
        {
          key: 'softwareSeats.salesOrderLine.salesOrder.number',
          widthClassname: 'is-size-15',
          render: (el) => (
            <Link to={`/sales-orders/${el.salesOrder.id}`}>
              <span className="has-text-primary">
                {formatSalesOrderNumber(el.salesOrder.number)}
              </span>
            </Link>
          ),
          title: 'Sales order',
          sortable: true,
        },
        {
          key: 'softwareSeats.salesOrderLine.itemFulfillment.contractEndDate',
          widthClassname: 'is-size-10',
          render: (el) => formatDateToLocaleString(el.expirationDate),
          title: 'Expiration date',
          sortable: true,
        },
        {
          key: 'seats.total',
          widthClassname: 'is-size-10',
          render: (el) => {
            const availableSeats = el.seats.filter(
              (seat) => !seat.assignedDevice
            ).length;
            const totalSeats = el.seats.length;

            return `${availableSeats}/${totalSeats}`;
          },
          title: 'Unassigned seats',
          sortable: false,
        },
        {
          key: 'assignedDevice.name',
          widthClassname: 'is-size-10',
          render: (el) => {
            if (isExpandableRow(el)) {
              return null;
            }
            return (
              <Conditional condition={!!el.seats[0]?.assignedDevice}>
                <Conditional.True>
                  {el.seats[0]?.assignedDevice?.name}
                </Conditional.True>
                <Conditional.False>
                  <LicenseKeyNotAssigned hasWarningIcon />
                </Conditional.False>
              </Conditional>
            );
          },
          expandableContentRender: (el: LicenseKeysExpandableTableRow) => {
            return (
              <Conditional condition={!!el.assignedDevice}>
                <Conditional.True>{el.assignedDevice?.name}</Conditional.True>
                <Conditional.False>
                  <LicenseKeyNotAssigned hasWarningIcon />
                </Conditional.False>
              </Conditional>
            );
          },
          title: 'Assigned device',
          sortable: false,
        },
        {
          key: 'assignedDevice.salesOrder.number',
          widthClassname: 'is-size-15',
          render: (el) => {
            if (isExpandableRow(el)) {
              return null;
            }
            return (
              <Conditional condition={!!el.seats[0]?.assignedDevice}>
                <Conditional.True>
                  <Link
                    to={`/sales-orders/${el.seats[0]?.assignedDevice?.salesOrder.id}`}
                  >
                    <span className="has-text-primary">
                      {formatSalesOrderNumber(
                        el.seats[0]?.assignedDevice?.salesOrder.number ?? ''
                      )}
                    </span>
                  </Link>
                </Conditional.True>
                <Conditional.False>
                  <LicenseKeyNotAssigned />
                </Conditional.False>
              </Conditional>
            );
          },
          expandableContentRender: (el: LicenseKeysExpandableTableRow) => {
            return (
              <Conditional condition={!!el.assignedDevice}>
                <Conditional.True>
                  <Link
                    to={`/sales-orders/${el.assignedDevice?.salesOrder.id}`}
                  >
                    <span className="has-text-primary">
                      {formatSalesOrderNumber(
                        el.assignedDevice?.salesOrder.number ?? ''
                      )}
                    </span>
                  </Link>
                </Conditional.True>
                <Conditional.False>
                  <span className="is-italic">None</span>
                </Conditional.False>
              </Conditional>
            );
          },
          title: 'Assigned device SO#',
          sortable: false,
        },
        {
          key: 'softwareSeats.salesOrderLine.salesOrder.endUser',
          widthClassname: 'is-size-15',
          render: (el) => el.salesOrder.endUser ?? '',
          title: 'End user',
          sortable: true,
        },
      ],
      [
        LicenseKeyNotAssigned,
        isExpandableRow,
        userHasSalesOrderManagePermissions,
        userIsJustViewOnlySalesOrderManager,
      ]
    );

  const emptyContentText = useMemo(() => {
    const hasAppliedFilters = config.search !== '';
    if (hasAppliedFilters) {
      return 'There are no license keys that match the selected criteria';
    }
    return 'There are no license keys at the moment';
  }, [config.search]);

  const expandableRow = useMemo(():
    | ExpandableRow<LicenseKeysTableRow, LicenseKeysExpandableTableRow>
    | undefined => {
    return {
      expandable: (row: LicenseKeysTableRow) => isExpandableRow(row),
      expandableRowIterator: (row: LicenseKeysTableRow) => {
        return row.seats;
      },
    };
  }, [isExpandableRow]);

  const isInvalidRowValue = (row: LicenseKeysTableRow) => {
    if (isExpandableRow(row)) {
      return false;
    }
    return !row.seats[0]?.assignedDevice;
  };

  const isInvalidExpandableRowValue = (row: LicenseKeysExpandableTableRow) => {
    return !row.assignedDevice;
  };

  return (
    <PaginatedTable
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      config={config}
      rowKey={'id'}
      isInvalidRowValue={isInvalidRowValue}
      isInvalidExpandableRowValue={isInvalidExpandableRowValue}
      empty={
        <Element display="flex" justifyContent="center" className="my-2">
          <span className="my-2 has-text-weight-light">{emptyContentText}</span>
        </Element>
      }
      form={form}
      expandableRow={expandableRow}
    />
  );
}

export default LicenseKeysTable;
