import { ReactNode, createContext, useContext } from 'react';

export type ConditionalProps = {
  children: ReactNode;
  condition: boolean;
};

type ConditionalContextType = {
  condition: boolean;
};

const ConditionalContext = createContext<ConditionalContextType>({
  condition: false,
});

export function Conditional({ children, condition }: ConditionalProps) {
  return (
    <ConditionalContext.Provider value={{ condition }}>
      {children}
    </ConditionalContext.Provider>
  );
}

type ConditionalTrueProps = Omit<ConditionalProps, 'condition'>;

const True = ({ children }: ConditionalTrueProps) => {
  const { condition } = useContext(ConditionalContext);
  return condition ? children : null;
};

type ConditionalFalseProps = Omit<ConditionalProps, 'condition'>;

const False = ({ children }: ConditionalFalseProps) => {
  const { condition } = useContext(ConditionalContext);
  return condition ? null : children;
};

Conditional.True = True;
Conditional.False = False;

export { False, True };

export default Conditional;
