import { Navigate } from 'react-router-dom';
import { RouteObject } from '../app';
import PartnersHomePage from '../partners/partners-home/partners-home-page';
import InviteUserDetailsPage from '../users/invite-user-details-page/invite-user-details-page';
import InviteUserPage from '../users/invite-user-page/invite-user-page';
import UsersControlPanelPage, {
  loader as usersControlPanelLoader,
} from '../users/users-control-panel-page/users-control-panel-page';
import WorkOrdersCompletedTab, {
  loader as workOrdersCompletedTabLoader,
} from '../work-orders/work-orders-completed-tab/work-orders-completed-tab';
import WorkOrdersDashboardPage from '../work-orders/work-orders-dashboard-page/work-orders-dashboard-page';
import WorkOrdersDefaultPage from '../work-orders/work-orders-default-page/work-orders-default-page';
import WorkOrdersQueueTab, {
  loader as workOrdersQueueTabLoader,
} from '../work-orders/work-orders-queue-tab/work-orders-queue-tab';
import DeviceFailureReasonSelectionPage from './device-replacement/device-failure-reason-selection/device-failure-reason-selection';
import DeviceReplacementConfirmPage from './device-replacement/device-replacement-confirm/device-replacement-confirm';
import DeviceReplacementLayout from './device-replacement/device-replacement-layout/device-replacement-layout';
import DeviceReplacementSummaryPage from './device-replacement/device-replacement-summary/device-replacement-summary';
import { FaultyDeviceLookupPage } from './device-replacement/faulty-device-lookup/faulty-device-lookup';
import ReplacementDeviceLookupPage from './device-replacement/replacement-device-lookup/replacement-device-lookup';

export const routes: RouteObject[] = [
  {
    index: true,
    element: <PartnersHomePage />,
  },
  {
    path: 'work-orders',
    children: [
      {
        index: true,
        element: <WorkOrdersDefaultPage />,
      },
      {
        path: 'dashboard',
        element: <WorkOrdersDashboardPage />,
        children: [
          {
            loader: workOrdersQueueTabLoader,
            path: 'queue',
            element: <WorkOrdersQueueTab />,
          },
          {
            loader: workOrdersCompletedTabLoader,
            path: 'completed',
            element: <WorkOrdersCompletedTab />,
          },
        ],
      },
    ],
  },
  {
    path: 'device-replacement',
    element: <DeviceReplacementLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="faulty-device" replace />,
      },
      {
        path: 'faulty-device',
        element: <FaultyDeviceLookupPage />,
      },
      {
        path: 'reason-selection',
        element: <DeviceFailureReasonSelectionPage />,
      },
      {
        path: 'new-device',
        element: <ReplacementDeviceLookupPage />,
      },
      {
        path: 'confirm',
        element: <DeviceReplacementConfirmPage />,
      },
      {
        path: 'summary',
        element: <DeviceReplacementSummaryPage />,
      },
    ],
  },
  {
    path: 'users',
    children: [
      {
        index: true,
        element: <Navigate to="/users/panel" replace />,
      },
      {
        loader: usersControlPanelLoader,
        path: 'panel',
        element: <UsersControlPanelPage />,
      },
      {
        path: 'panel/invite',
        element: <InviteUserPage internalViewMode />,
        children: [
          {
            index: true,
            element: <Navigate to="details" replace />,
          },
          {
            path: 'details',
            element: <InviteUserDetailsPage />,
          },
        ],
      },
    ],
  },
];
