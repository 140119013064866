import { WorkOrderDetails, WorkOrderType } from '@zspace/types';
import { useCallback } from 'react';
import { Columns, Form } from 'react-bulma-components';
import { FormFieldData } from '../../../shared/form';
import FeedbackMessage from '../../../ui/feedback-message/feedback-message';

export type WorkOrderDetailsFormData = FormFieldData<WorkOrderDetails>;

type WorkOrderDetailsFormChangeEvent = React.ChangeEvent<
  HTMLInputElement | HTMLSelectElement
>;

type WorkOrderDetailsFormBlurEvent = React.FocusEvent<
  HTMLInputElement | HTMLSelectElement,
  Element
>;

type WorkOrderDetailsFormValidation = Record<
  keyof WorkOrderDetails,
  ((value: string) => string | null) | null
>;

export interface WorkOrderDetailsFormProps {
  data: WorkOrderDetailsFormData;
  onChangeData: (data: WorkOrderDetailsFormData) => void;
  validate: WorkOrderDetailsFormValidation;
  editMode: boolean;
}

export function CreateWorkOrderDetailsForm({
  data: formData,
  onChangeData: setFormData,
  validate,
  editMode,
}: WorkOrderDetailsFormProps) {
  const onInputChange = useCallback(
    (event: WorkOrderDetailsFormChangeEvent) => {
      const newValue = event.target.value;
      const name = event.target.name as keyof WorkOrderDetails;
      setFormData({
        ...formData,
        [name]: { ...formData[name], value: newValue, touched: true },
      });
    },
    [formData, setFormData]
  );

  const onBlur = useCallback(
    (event: WorkOrderDetailsFormBlurEvent) => {
      const value = event.target.value;
      const name = event.target.name as keyof WorkOrderDetails;
      const error = validate[name]?.(value);
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          error,
        },
      });
    },
    [formData, setFormData, validate]
  );

  return (
    <form>
      <Columns desktop={{ gap: 4 }}>
        <Columns.Column>
          <Columns flexDirection="column" display="flex">
            <Columns.Column>
              <Form.Label
                className="has-text-weight-normal"
                htmlFor="workOrderName"
              >
                Work order name
              </Form.Label>
              <Form.Field id="workOrderName">
                <Form.Control>
                  <Form.Input
                    name="name"
                    value={formData.name.value}
                    onChange={onInputChange}
                    onBlur={onBlur}
                  />
                </Form.Control>
                <FeedbackMessage>{formData.name.error}</FeedbackMessage>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Label
                className="has-text-weight-normal"
                htmlFor="workOrderType"
              >
                Work order type
              </Form.Label>
              <Form.Field id="workOrderType">
                <Form.Control>
                  <Form.Select
                    fullwidth
                    name="type"
                    value={formData.type.value}
                    onChange={onInputChange}
                    onBlur={onBlur}
                    disabled={editMode}
                  >
                    <option value={WorkOrderType.FULL_CONFIGURATION}>
                      Full configuration
                    </option>
                    <option value={WorkOrderType.INSTALL_ONLY}>
                      Install only
                    </option>
                    <option value={WorkOrderType.HARDWARE_ONLY}>
                      Hardware only
                    </option>
                  </Form.Select>
                </Form.Control>
                <FeedbackMessage>{formData.type.error}</FeedbackMessage>
              </Form.Field>
            </Columns.Column>
          </Columns>
        </Columns.Column>
        <Columns.Column>
          <Columns flexDirection="column" display="flex">
            <Columns.Column>
              <Form.Label
                className="has-text-weight-normal"
                htmlFor="workOrderDescription"
              >
                Description (Optional)
              </Form.Label>
              <Form.Field id="workOrderDescription">
                <Form.Control>
                  <Form.Input
                    name="description"
                    value={formData.description.value}
                    onChange={onInputChange}
                    onBlur={onBlur}
                  />
                </Form.Control>
                <FeedbackMessage>{formData.description.error}</FeedbackMessage>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Label
                className="has-text-weight-normal"
                htmlFor="workOrderComplex"
              >
                Is this a complex work order?
              </Form.Label>
              <Form.Field id="workOrderComplex">
                <Form.Control>
                  <Form.Select
                    fullwidth
                    name="complex"
                    value={formData.complex.value}
                    onChange={onInputChange}
                    onBlur={onBlur}
                    disabled={editMode}
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </Form.Select>
                </Form.Control>
                <FeedbackMessage>{formData.complex.error}</FeedbackMessage>
              </Form.Field>
            </Columns.Column>
          </Columns>
        </Columns.Column>
      </Columns>
    </form>
  );
}

export default CreateWorkOrderDetailsForm;
