import { filter, FilterOption } from '@zspace/data';
import { removeTimeFromStringDate } from '@zspace/dates';
import { formatSalesOrderNumber } from '@zspace/format';
import {
  getUserAllowedPermissionsGroupsToManage,
  isTemporary,
  PermissionsGroupName,
  SYSTEM_DEFINED_PERMISSIONS_GROUPS,
} from '@zspace/roles';
import {
  Criteria,
  FilterType,
  PaginatedContentConfig,
  UserPermissionsFilterDataType,
  UserSalesOrderPermission,
  UserSalesOrderPermissionTableRow,
} from '@zspace/types';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Columns, Element, Form, Icon } from 'react-bulma-components';
import { FaFilter, FaSort } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import useIsZspaceInternalUser from '../../shared/hooks/is-zspace-internal-user';
import useUser from '../../shared/hooks/user';
import If from '../../shared/if/if';
import { formatDateToLocaleString } from '../../shared/utils';
import Button from '../../ui/button/button';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PaginatedTable, {
  CustomPaginatedTableProps,
} from '../../ui/table/paginated-table/paginated-table';
import { Column, OnTableChangeData } from '../../ui/table/types';
import { filterUserPermissions } from '../filter-modals/filters';
import UserPermissionsTableFilterModal from '../filter-modals/user-permissions-table-filter-modal/user-permissions-table-filter-modal';
import styles from './user-permissions-table.module.scss';

export type UserPermissionsTableProps = Omit<
  CustomPaginatedTableProps<UserSalesOrderPermissionTableRow>,
  'config' | 'onChange' | 'empty' | 'form'
> & {
  errorMessage?: ReactNode;
  setFilters: Dispatch<SetStateAction<ReactNode>>;
  selectedPermissions: UserSalesOrderPermission[];
  setSelectedPermissions: Dispatch<SetStateAction<UserSalesOrderPermission[]>>;
};

const INITIAL_ITEMS_PER_PAGE = 10;
const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 50];
const DEFAULT_PERMISSION_DAYS_DURATION = 10;
const MAX_PERMISSION_DAYS_DURATION = Number(
  environment.temporaryUserPermissionMaxDaysDuration
);

const filterOptions: FilterOption<UserSalesOrderPermissionTableRow>[] = [
  {
    getter: (el) => formatSalesOrderNumber(el.number),
    searchable: true,
  },
  {
    getter: (el) => el.opportunity?.name ?? '',
    searchable: true,
  },
];

const initialCriteria: UserPermissionsFilterDataType = {
  opportunityName: '',
  opportunityNameFilter: FilterType.CONTAINS,
  salesOrder: [],
  closeDateFrom: '',
  closeDateTo: '',
};

export function UserPermissionsTable({
  dataSource,
  errorMessage,
  setFilters,
  selectedPermissions,
  setSelectedPermissions,
}: UserPermissionsTableProps) {
  const { user } = useUser();
  const isZspaceInternalUser = useIsZspaceInternalUser();
  const [activeDurationInputRowId, setActiveDurationInputRowId] =
    useState<string>('');
  const [tableCriteria, setTableCriteria] = useState<Criteria>({
    itemsPerPage: INITIAL_ITEMS_PER_PAGE,
    pageNumber: 1,
    search: '',
    sortBy: '',
  });
  const [filterCriteria, setFilterCriteria] =
    useState<UserPermissionsFilterDataType>(initialCriteria);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

  const isAllowedToEdit = useCallback(
    (permissionsGroupName: PermissionsGroupName): boolean => {
      if (!permissionsGroupName) {
        return true;
      }
      if (isZspaceInternalUser) {
        return SYSTEM_DEFINED_PERMISSIONS_GROUPS.includes(permissionsGroupName);
      } else {
        const userAllowedPermissionsGroupsToAssign =
          getUserAllowedPermissionsGroupsToManage(user);
        return userAllowedPermissionsGroupsToAssign.includes(
          permissionsGroupName
        );
      }
    },
    [isZspaceInternalUser, user]
  );

  const roleOptions = useMemo(
    (): {
      label: string;
      value: string;
      disabled: boolean;
    }[] => [
      { label: 'None', value: '', disabled: false },
      {
        label: 'View',
        value: PermissionsGroupName.VIEW_ONLY_SALES_ORDER_MANAGER,
        disabled:
          !isZspaceInternalUser &&
          !isAllowedToEdit(
            PermissionsGroupName.VIEW_ONLY_SALES_ORDER_MANAGER as PermissionsGroupName
          ),
      },
      {
        label: 'Manage',
        value: PermissionsGroupName.SALES_ORDER_MANAGER,
        disabled:
          !isZspaceInternalUser &&
          !isAllowedToEdit(
            PermissionsGroupName.SALES_ORDER_MANAGER as PermissionsGroupName
          ),
      },
      {
        label: 'Manage - Temporary',
        value: PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER,
        disabled:
          !isZspaceInternalUser &&
          !isAllowedToEdit(
            PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER as PermissionsGroupName
          ),
      },
    ],
    [isAllowedToEdit, isZspaceInternalUser]
  );

  const tableData = useMemo(() => {
    const userPermissions = dataSource.map((so) => {
      const selectedPermission = selectedPermissions.find(
        (permission) => permission.salesOrderId === so.id
      );
      let permissionsGroup: {
        name: string;
        daysDuration?: number;
      } = {
        name: '',
      };
      if (selectedPermission?.permissionsGroup) {
        permissionsGroup = {
          name: selectedPermission.permissionsGroup.name,
          daysDuration: selectedPermission.permissionsGroup.daysDuration,
        };
      }

      return {
        id: so.id,
        number: so.number,
        opportunity: so.opportunity,
        closeDate: so.closeDate,
        permissionsGroup,
      };
    });
    // Filter by modal fields
    const modalFilteredData = filterUserPermissions(
      userPermissions,
      filterCriteria
    ) as UserSalesOrderPermissionTableRow[];
    // Filter by table columns
    return filter(modalFilteredData, tableCriteria, filterOptions);
  }, [dataSource, filterCriteria, selectedPermissions, tableCriteria]);

  const tablePageData = useMemo(() => {
    return tableData.slice(
      (tableCriteria.pageNumber - 1) * tableCriteria.itemsPerPage,
      tableCriteria.pageNumber * tableCriteria.itemsPerPage
    );
  }, [tableCriteria.itemsPerPage, tableCriteria.pageNumber, tableData]);

  const filterTagsData = useMemo(() => {
    return {
      opportunityName: {
        label: 'Opportunity name',
        value: filterCriteria.opportunityName
          ? filterCriteria.opportunityNameFilter
              .toLowerCase()
              .concat(' ', filterCriteria.opportunityName)
          : '',
      },
      salesOrder: {
        label: 'Sales order #',
        value: filterCriteria.salesOrder.join(', '),
      },
      closeDateFrom: {
        label: 'Close date from',
        value: removeTimeFromStringDate(filterCriteria.closeDateFrom),
      },
      closeDateTo: {
        label: 'Close date to',
        value: removeTimeFromStringDate(filterCriteria.closeDateTo),
      },
    };
  }, [
    filterCriteria.closeDateFrom,
    filterCriteria.closeDateTo,
    filterCriteria.opportunityName,
    filterCriteria.opportunityNameFilter,
    filterCriteria.salesOrder,
  ]);

  const handleTableFilterSubmit = useCallback(
    (value: UserPermissionsFilterDataType): void => {
      setFilterCriteria(value);
      setTableCriteria((oldCriteria) => ({
        ...oldCriteria,
        pageNumber: 1,
      }));
    },
    []
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof UserPermissionsFilterDataType) => {
      const newData = { ...filterCriteria };
      newData[filterDataKey] = initialCriteria[filterDataKey] as string &
        string[] &
        FilterType;
      handleTableFilterSubmit(newData);
    },
    [filterCriteria, handleTableFilterSubmit]
  );

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const tableForm = useMemo(
    () => ({
      debounceMillis: 0,
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      extra: (
        <Button
          type="button"
          color="primary-dark"
          className="ml-2"
          outlined={appliedFilters.length === 0}
          onClick={() => setShowFilterModal(true)}
        >
          <Icon>
            <FaFilter />
          </Icon>
          <span>
            Filter
            {appliedFilters.length > 0 && `(${appliedFilters.length})`}
          </span>
        </Button>
      ),
    }),
    [appliedFilters.length]
  );

  const tableConfig = useMemo(() => {
    return {
      ...tableCriteria,
      pages: Math.ceil(tableData.length / tableCriteria.itemsPerPage),
    };
  }, [tableCriteria, tableData.length]);

  useEffect(() => {
    if (appliedFilters.length > 0) {
      setFilters?.(
        <FilterTagList
          list={filterTagsData}
          onRemove={(item: string) =>
            onRemoveFilterTag(item as keyof UserPermissionsFilterDataType)
          }
          title="Filters"
        />
      );
    } else {
      setFilters?.(null);
    }
  }, [appliedFilters.length, filterTagsData, onRemoveFilterTag, setFilters]);

  const handlePermissionChange = useCallback(
    (permission: UserSalesOrderPermissionTableRow) => {
      const index = selectedPermissions.findIndex(
        (p) => p.salesOrderId === String(permission.id)
      );
      if (index >= 0) {
        if (permission.permissionsGroup.daysDuration === 0) {
          const newSelectedSalesOrderAssignments = selectedPermissions.filter(
            (p) => p.salesOrderId === String(permission.id)
          );
          setSelectedPermissions(newSelectedSalesOrderAssignments);
        } else {
          const newSelectedSalesOrderAssignments = [...selectedPermissions];
          newSelectedSalesOrderAssignments[index].permissionsGroup =
            permission.permissionsGroup;
          setSelectedPermissions(newSelectedSalesOrderAssignments);
        }
      } else {
        const newSelectedSalesOrderAssignments = [
          ...selectedPermissions,
          {
            salesOrderId: permission.id,
            permissionsGroup: permission.permissionsGroup,
          },
        ];
        setSelectedPermissions(newSelectedSalesOrderAssignments);
      }
    },
    [selectedPermissions, setSelectedPermissions]
  );

  const handleTableDataChange = useCallback(
    (value: UserSalesOrderPermissionTableRow) => {
      if (!isTemporary(value.permissionsGroup.name as PermissionsGroupName)) {
        value.permissionsGroup.daysDuration = undefined;
      } else {
        value.permissionsGroup.daysDuration =
          value.permissionsGroup.daysDuration ??
          DEFAULT_PERMISSION_DAYS_DURATION;
      }

      handlePermissionChange(value);
    },
    [handlePermissionChange]
  );

  const resolveDaysDuration = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, rowId: string) => {
      const index = tableData.findIndex((item) => item.id === rowId);
      let newValue = Number(tableData[index].permissionsGroup.daysDuration);
      const rect = (event.target as HTMLElement).getBoundingClientRect();
      const mouseY = event.clientY - rect.top;
      const iconHeight = rect.height;
      const relativePosition = mouseY / iconHeight;
      if (relativePosition < 0.5) {
        newValue += 1;
      } else {
        newValue -= 1;
      }
      if (newValue > MAX_PERMISSION_DAYS_DURATION) {
        return MAX_PERMISSION_DAYS_DURATION;
      }
      if (newValue < 1) {
        return 1;
      }
      return newValue;
    },
    [tableData]
  );

  const columns: Column<UserSalesOrderPermissionTableRow>[] = useMemo(
    () => [
      {
        key: 'number',
        widthClassname: 'is-size-15',
        render: (el) => (
          <Link to={`/sales-orders/${el.id}`}>
            {formatSalesOrderNumber(el.number)}
          </Link>
        ),
        title: 'Sales Order',
        sortable: true,
      },
      {
        key: 'opportunity.name',
        widthClassname: 'is-size-45',
        render: (el) => el.opportunity?.name,
        title: 'Opportunity Name',
        sortable: true,
      },
      {
        key: 'closeDate',
        widthClassname: 'is-size-10',
        render: (el) =>
          el.closeDate ? formatDateToLocaleString(el.closeDate) : '',
        title: 'Close date',
        sortable: true,
      },
      {
        key: 'permissions',
        widthClassname: 'is-size-30',
        render: (el) => (
          <Columns>
            <Columns.Column size={'half'}>
              <Form.Field id="type" alignItems="center">
                <Form.Control>
                  <Form.Select
                    id="roleName"
                    fullwidth
                    value={el.permissionsGroup.name}
                    disabled={
                      !isAllowedToEdit(
                        el.permissionsGroup.name as PermissionsGroupName
                      )
                    }
                    onChange={(e) => {
                      const updatedElement = el;
                      updatedElement.permissionsGroup.name = e.target.value;
                      handleTableDataChange(updatedElement);
                    }}
                    className={styles.formSelect}
                  >
                    {roleOptions.map((option, index) => (
                      <option
                        key={`${option.label}-${index}`}
                        value={option.value}
                        disabled={option.disabled}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column
              size={'half'}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <If
                condition={
                  el.permissionsGroup.name ===
                  PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER
                }
              >
                <Form.Field
                  id="roleDaysDuration"
                  className={styles.daysDurationField}
                >
                  <Form.Control
                    onFocus={() => setActiveDurationInputRowId(el.id)}
                    onBlur={() => setActiveDurationInputRowId('')}
                    onMouseEnter={() => setActiveDurationInputRowId(el.id)}
                    onMouseLeave={() => setActiveDurationInputRowId('')}
                    display="flex"
                    flexDirection="column"
                  >
                    <Form.Input
                      className="is-size-6"
                      name="daysDuration"
                      type="number"
                      min={1}
                      max={MAX_PERMISSION_DAYS_DURATION}
                      mr={4}
                      value={
                        el.permissionsGroup.daysDuration ??
                        DEFAULT_PERMISSION_DAYS_DURATION
                      }
                      onChange={(event) => {
                        if (
                          event.target.value !== '' &&
                          Number(event.target.value) < 1
                        ) {
                          event.target.value = '1';
                        }
                        const updatedElement = el;
                        updatedElement.permissionsGroup.daysDuration = Number(
                          event.target.value
                        );
                        handleTableDataChange(updatedElement);
                      }}
                    />

                    <Button
                      className={`is-size-6 ${styles.buttonContainer}`}
                      text
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        const updatedElement = el;
                        updatedElement.permissionsGroup.daysDuration =
                          resolveDaysDuration(event, el.id);
                        handleTableDataChange(updatedElement);
                      }}
                      tabIndex={-1}
                    >
                      <Icon align="right" className={styles.iconContainer}>
                        <FaSort
                          className={
                            activeDurationInputRowId === el.id
                              ? styles.chevronDownActive
                              : styles.chevronDown
                          }
                        />
                      </Icon>
                    </Button>
                  </Form.Control>
                </Form.Field>
                <Element>
                  <span className="is-size-6">days</span>
                </Element>
              </If>
            </Columns.Column>
          </Columns>
        ),
        title: 'Permissions',
        sortable: false,
      },
    ],
    [
      activeDurationInputRowId,
      handleTableDataChange,
      resolveDaysDuration,
      roleOptions,
      isAllowedToEdit,
    ]
  );

  const handleTableChange = (
    value: OnTableChangeData<UserSalesOrderPermissionTableRow>
  ) => {
    const newTableCriteria: PaginatedContentConfig = {
      ...tableCriteria,
      ...value.config,
      sortBy: value.column?.key || tableCriteria.sortBy,
    };

    setTableCriteria(newTableCriteria);
  };

  const emptyContent = useMemo(() => {
    const emptyContentText =
      appliedFilters.length > 0 || tableConfig.search !== ''
        ? 'There are no available permissions that match the selected criteria'
        : 'There are no available permissions at the moment';
    return (
      <Element display="flex" justifyContent="center" className="my-2">
        <span className="my-2 has-text-weight-light">{emptyContentText}</span>
      </Element>
    );
  }, [appliedFilters.length, tableConfig.search]);

  return (
    <>
      <PaginatedTable
        dataSource={tablePageData}
        columns={columns}
        onChange={handleTableChange}
        config={tableConfig}
        form={tableForm}
        empty={emptyContent}
        rowKey={'id'}
        errorMessage={errorMessage}
      />
      <UserPermissionsTableFilterModal
        show={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        onSubmit={handleTableFilterSubmit}
        data={filterCriteria}
      />
    </>
  );
}

export default UserPermissionsTable;
