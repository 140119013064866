import {
  FilterType,
  PaginatedAPIResponse,
  SortDirection,
  WorkOrderDashboardPageTabs,
  WorkOrderData,
  WorkOrderStatus,
  WorkOrderType,
  WorkOrdersCriteria,
} from '@zspace/types';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Element } from 'react-bulma-components';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigation,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import { fetchWorkOrders } from '../work-orders-service';

import { createSearchParams } from '../../shared/url';
import { WorkOrderDashboardPageContext } from '../work-orders-dashboard-page/types';
import WorkOrdersTableCard from '../work-orders-table-card/work-orders-table-card';

import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import Spinner from '../../ui/spinner/spinner';

const initialCriteriaData: WorkOrdersCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  workOrderName: '',
  workOrderNameFilter: FilterType.CONTAINS,
  creationDateFrom: '',
  creationDateTo: '',
  account: '',
  accountFilter: FilterType.CONTAINS,
  type: WorkOrderType.ALL,
  minTotalDevicesNumber: '',
  maxTotalDevicesNumber: '',
  createdBy: [],
  salesOrder: [],
  status: WorkOrderStatus.COMPLETED,
};

export const loader: LoaderFunction = async ({ request }) => {
  const searchParams = createSearchParams(request);

  const response = fetchWorkOrders({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: searchParams.get('sortBy') ?? '',
    sortDirection: searchParams.get('sortDirection') as SortDirection,
    workOrderName: searchParams.get('workOrderName') ?? '',
    workOrderNameFilter:
      (searchParams.get('workOrderNameFilter') as FilterType) ??
      FilterType.CONTAINS,
    creationDateFrom: searchParams.get('creationDateFrom') ?? '',
    creationDateTo: searchParams.get('creationDateTo') ?? '',
    account: searchParams.get('account') ?? '',
    accountFilter:
      (searchParams.get('accountFilter') as FilterType) ?? FilterType.CONTAINS,
    type: (searchParams.get('type') as WorkOrderType) ?? WorkOrderType.ALL,
    minTotalDevicesNumber: searchParams.get('minTotalDevicesNumber') ?? '',
    maxTotalDevicesNumber: searchParams.get('maxTotalDevicesNumber') ?? '',
    createdBy: searchParams.getAll('createdBy') ?? [],
    salesOrder: searchParams.getAll('salesOrder') ?? [],
    status: WorkOrderStatus.COMPLETED,
  });

  return defer({ response });
};

function WorkOrdersCompletedTabContent() {
  const { setActiveTab, setFilterTagList, setShowCreateWorkOrderButton } =
    useOutletContext<WorkOrderDashboardPageContext>();
  const [searchParams] = useSearchParams();
  const navigation = useNavigation();
  const response = useAsyncValue() as PaginatedAPIResponse<WorkOrderData>;

  const [data, setData] = useState<WorkOrdersCriteria>({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: searchParams.get('sortBy') ?? '',
    sortDirection: searchParams.get('sortDirection') as SortDirection,
    workOrderName: searchParams.get('workOrderName') ?? '',
    workOrderNameFilter:
      (searchParams.get('workOrderNameFilter') as FilterType) ??
      FilterType.CONTAINS,
    creationDateFrom: searchParams.get('creationDateFrom') ?? '',
    creationDateTo: searchParams.get('creationDateTo') ?? '',
    account: searchParams.get('account') ?? '',
    accountFilter:
      (searchParams.get('accountFilter') as FilterType) ?? FilterType.CONTAINS,
    type: (searchParams.get('type') as WorkOrderType) ?? WorkOrderType.ALL,
    minTotalDevicesNumber: searchParams.get('minTotalDevicesNumber') ?? '',
    maxTotalDevicesNumber: searchParams.get('maxTotalDevicesNumber') ?? '',
    createdBy: searchParams.getAll('createdBy') ?? [],
    salesOrder: searchParams.getAll('salesOrder') ?? [],
    status: WorkOrderStatus.COMPLETED,
  });

  const emptyContent = useCallback(
    (emptyText: string) => (
      <Element display="flex" justifyContent="center">
        <span className="my-2">{emptyText}</span>
      </Element>
    ),
    []
  );

  const emptyTableContent = emptyContent(
    'There are no completed work orders at the moment'
  );

  const emptyTableContentWithFilter = emptyContent(
    'There are no completed work orders that match the selected criteria'
  );

  const isDataLoading = useMemo(
    () => navigation.state === 'loading',
    [navigation.state]
  );

  useEffect(() => {
    setActiveTab(WorkOrderDashboardPageTabs.COMPLETED);
  }, [setActiveTab]);

  useEffect(() => {
    setShowCreateWorkOrderButton(true);
  }, [setShowCreateWorkOrderButton]);

  if (isDataLoading) {
    return (
      <Element display="flex" justifyContent="center">
        <Spinner />
      </Element>
    );
  }

  return (
    <WorkOrdersTableCard
      criteria={data}
      tableData={response}
      initialCriteria={initialCriteriaData}
      setCriteria={setData}
      workOrdersStatus={WorkOrderStatus.COMPLETED}
      emptyTableContent={emptyTableContent}
      emptyTableContentWithFilters={emptyTableContentWithFilter}
      setFilterTagList={setFilterTagList}
    />
  );
}

export function WorkOrdersCompletedTab() {
  const { response } = useLoaderData() as {
    response: Promise<PaginatedAPIResponse<WorkOrderData>>;
  };

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <WorkOrdersCompletedTabContent />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default WorkOrdersCompletedTab;
