import {
  DeviceHomeAlertData,
  EulaOutstandingData,
  SoftwareHomeAlertData,
} from '@zspace/types';
import { useMemo } from 'react';

export function useUserPendingTasks(
  salesOrderAndEulaTasks: EulaOutstandingData,
  devicesTasks: DeviceHomeAlertData,
  softwareTasks: SoftwareHomeAlertData
) {
  const salesOrderAndEulaPendingTasks = useMemo(() => {
    if (salesOrderAndEulaTasks.outstandingCount) {
      return ['EULA acceptance pending'];
    }
    return [];
  }, [salesOrderAndEulaTasks.outstandingCount]);

  const devicesPendingTasks = useMemo(() => {
    const pendingTasks = [];
    if (devicesTasks.unregisteredDevicesCount) {
      pendingTasks.push(
        `${devicesTasks.unregisteredDevicesCount} unregistered`
      );
    }
    if (devicesTasks.ungroupedDevicesCount) {
      pendingTasks.push(`${devicesTasks.ungroupedDevicesCount} ungrouped`);
    }
    return pendingTasks;
  }, [
    devicesTasks.ungroupedDevicesCount,
    devicesTasks.unregisteredDevicesCount,
  ]);

  const softwarePendingTasks = useMemo(() => {
    if (softwareTasks.unassignedSoftwareCount) {
      return [`${softwareTasks.unassignedSoftwareCount} unassigned seats`];
    }
    return [];
  }, [softwareTasks.unassignedSoftwareCount]);

  return {
    salesOrderAndEulaPendingTasks,
    devicesPendingTasks,
    softwarePendingTasks,
  };
}

export default useUserPendingTasks;
