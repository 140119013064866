import { User } from '@zspace/types';
import { AxiosError } from 'axios';
import { ReactNode, createContext } from 'react';

export type UsersContextProps = {
  user: User;
  loading: boolean;
  error?: AxiosError;
};

export const UsersContext = createContext<UsersContextProps>({
  user: {} as User,
  loading: true,
  error: {} as AxiosError,
});

export type UsersProviderProps = {
  user: User;
  loading: boolean;
  error?: AxiosError;
  children: ReactNode;
};

export const UsersProvider = ({
  user,
  loading,
  error,
  children,
}: UsersProviderProps) => {
  return (
    <UsersContext.Provider value={{ user, loading, error }}>
      {children}
    </UsersContext.Provider>
  );
};
