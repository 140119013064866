import { UTCDate } from '@date-fns/utc';
import { addDays, endOfDay, format, startOfDay } from 'date-fns';

/**
 *
 * Taken from here -> https://www.30secondsofcode.org/js/s/iso-format-date-with-timezone/
 */

const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');

const getTimezoneOffset = (date: { getTimezoneOffset: () => number }) => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  return diff + pad(tzOffset / 60) + ':' + pad(tzOffset % 60);
};

export const toISOStringWithTimezone = (date: Date) => {
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    getTimezoneOffset(date)
  );
};

export const toISOStringWithoutTimezone = (_date: Date | string) => {
  if (!_date) {
    return null;
  }

  let date: Date;

  if (typeof _date === 'string') {
    date = new Date(_date);
  } else {
    date = _date;
  }

  return date.toISOString().split('T')[0];
};

export const toUTCDateString = (date: Date) => {
  const formatedDate = format(new UTCDate(date), 'EEEE MMMM dd yyyy HH:mm');
  return `${formatedDate} (UTC)`;
};

export const toAmericanDateString = (date: Date) => {
  const formatedDate = format(new UTCDate(date), 'MM/dd/yyyy HH:mm');
  return `${formatedDate} (UTC)`;
};

export const calculateTemporaryPermissionsExpiresAt = (
  days?: number
): Date | undefined => {
  if (days) {
    return endOfDay(addDays(new UTCDate(), days));
  }
  return undefined;
};

export const getBetweenDatesForExpiresAt = (days: number): [Date, Date] => {
  const startOfDayDate = startOfDay(addDays(new UTCDate(), days));
  const endOfDayDate = endOfDay(addDays(new UTCDate(), days));
  return [startOfDayDate, endOfDayDate];
};

export const removeTimeFromStringDate = (date: string): string => {
  return date.split('T')[0];
};
