import {
  ComponentProps,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { Button as BulmaButton } from 'react-bulma-components';
import { Color } from 'react-bulma-components/src/components';
import LoadingIcon from '../loading-icon/loading-icon';

export type ButtonProps = ComponentProps<typeof BulmaButton> & {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isExecutingAction?: boolean;
};

type ButtonContextType = {
  showSpinner: boolean;
  contentColor?: Color;
};

const ButtonContext = createContext<ButtonContextType>({
  showSpinner: false,
});

export function Button({
  children,
  isExecutingAction,
  onClick,
  ...props
}: ButtonProps) {
  const showSpinner = useMemo(() => !!isExecutingAction, [isExecutingAction]);
  const isButtonContentColored = useMemo(
    () => props.color && props.outlined,
    [props.color, props.outlined]
  );

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!isExecutingAction) {
        onClick?.(e);
      }
    },
    [isExecutingAction, onClick]
  );

  return (
    <BulmaButton {...props} onClick={handleOnClick}>
      <ButtonContext.Provider
        value={{
          showSpinner,
          ...(isButtonContentColored && { contentColor: props.color }),
        }}
      >
        {children}
      </ButtonContext.Provider>
    </BulmaButton>
  );
}

type ButtonLoadingIconProps = Omit<
  ComponentProps<typeof LoadingIcon>,
  'isLoading'
>;

const ButtonLoadingIcon = (props: ButtonLoadingIconProps) => {
  const { showSpinner } = useContext(ButtonContext);

  return <LoadingIcon isLoading={showSpinner} {...props} />;
};

Button.LoadingIcon = ButtonLoadingIcon;

export { ButtonLoadingIcon as LoadingIcon };

export default Button;
