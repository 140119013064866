import {
  DownloadableData,
  PaginatedAPIResponse,
  RegistrationFileBasicData,
  RegistrationFileData,
  RegistrationFileDevicesCriteria,
  RegistrationFilesCriteria,
  RegistrationFilesTableRow,
} from '@zspace/types';
import { http } from '../shared/http';

export interface RegistrationFilesService {
  fetchRegistrationFiles: (
    criteria: RegistrationFilesCriteria
  ) => Promise<PaginatedAPIResponse<RegistrationFilesTableRow>>;
  fetchRegistrationFile: (
    id: string,
    devicesCriteria: RegistrationFileDevicesCriteria
  ) => Promise<RegistrationFileData>;
  createRegistrationFile: () => Promise<RegistrationFileBasicData>;
  downloadRegistrationFile: (id: string) => Promise<DownloadableData>;
  sendRegistrationFile: (id: string, recipients: string[]) => Promise<void>;
}

export class RegistrationFilesServiceImpl implements RegistrationFilesService {
  public async fetchRegistrationFiles(
    criteria: RegistrationFilesCriteria
  ): Promise<PaginatedAPIResponse<RegistrationFilesTableRow>> {
    const { data } = await http(
      '/v1/registration-files?' +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchRegistrationFile(
    id: string,
    devicesCriteria: RegistrationFileDevicesCriteria
  ): Promise<RegistrationFileData> {
    const { data } = await http(
      `/v1/registration-files/${id}?` +
        new URLSearchParams(
          devicesCriteria as unknown as Record<string, string>
        )
    );

    return data;
  }

  public async createRegistrationFile(): Promise<RegistrationFileBasicData> {
    const { data } = await http.post(`/v1/registration-files`);
    return data;
  }

  public async downloadRegistrationFile(id: string): Promise<DownloadableData> {
    const { data } = await http.get(`/v1/registration-files/${id}/download`);
    return data;
  }

  public async sendRegistrationFile(
    id: string,
    recipients: string[]
  ): Promise<void> {
    await http.post(`/v1/registration-files/${id}/send`, { recipients });
  }
}

export const fetchRegistrationFiles = async (
  criteria: RegistrationFilesCriteria
): Promise<PaginatedAPIResponse<RegistrationFilesTableRow>> => {
  const service = new RegistrationFilesServiceImpl();

  return service.fetchRegistrationFiles(criteria);
};

export const fetchRegistrationFile = async (
  id: string,
  devicesCriteria: RegistrationFileDevicesCriteria
): Promise<RegistrationFileData> => {
  const service = new RegistrationFilesServiceImpl();

  return service.fetchRegistrationFile(id, devicesCriteria);
};

export const createRegistrationFile =
  async (): Promise<RegistrationFileBasicData> => {
    const service = new RegistrationFilesServiceImpl();

    return service.createRegistrationFile();
  };

export const downloadRegistrationFile = async (
  id: string
): Promise<DownloadableData> => {
  const service = new RegistrationFilesServiceImpl();

  return service.downloadRegistrationFile(id);
};

export const sendRegistrationFile = async (
  id: string,
  recipients: string[]
): Promise<void> => {
  const service = new RegistrationFilesServiceImpl();

  return service.sendRegistrationFile(id, recipients);
};
