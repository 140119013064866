import { Criteria, SortDirection } from '@zspace/types';

export type FilterOption<T> = {
  getter: (el: T, index: number) => string;
  searchable: boolean;
};

export function filter<T>(
  data: T[],
  { search, sortBy, sortDirection }: Criteria,
  filterOptions: FilterOption<T>[]
): T[] {
  let response: T[] = [...data];

  if (search?.length) {
    const searchRegexp = new RegExp(search, 'gi');

    response = data.filter((el) => {
      let isAMatch = false;

      filterOptions.forEach((opt, index) => {
        if (opt.searchable) {
          const value = opt.getter(el, index);

          isAMatch = isAMatch || Boolean(value.match(searchRegexp));
        }
      });

      return isAMatch;
    });
  }

  sort<T>(response, sortBy, sortDirection);

  return response;
}

export function sort<T>(
  data: T[],
  sortBy: string,
  sortDirection: SortDirection | undefined
): T[] {
  if (!(sortBy || sortDirection)) {
    return data;
  }

  const sortByKeys = sortBy.split('.');

  data.sort((a, b) => {
    const propertyA = String(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sortByKeys.reduce((obj, key) => (obj as any)[key], a)
    );
    const propertyB = String(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sortByKeys.reduce((obj, key) => (obj as any)[key], b)
    );

    if (sortDirection === SortDirection.ASC) {
      return propertyA.localeCompare(propertyB, undefined, { numeric: true });
    }

    if (sortDirection === SortDirection.DES) {
      return propertyB.localeCompare(propertyA, undefined, { numeric: true });
    }

    return 0;
  });

  return data;
}
