import { DeviceGroupDetails } from '@zspace/types';
import { useCallback } from 'react';
import { Columns, Form } from 'react-bulma-components';
import { FormFieldData } from '../../../shared/form';
import FeedbackMessage from '../../../ui/feedback-message/feedback-message';

export type DeviceGroupDetailsFormData = FormFieldData<DeviceGroupDetails>;

type DeviceGroupDetailsFormChangeEvent = React.ChangeEvent<
  HTMLInputElement | HTMLSelectElement
>;

type DeviceGroupDetailsFormBlurEvent = React.FocusEvent<
  HTMLInputElement | HTMLSelectElement,
  Element
>;

type DeviceGroupDetailsFormValidation = Record<
  keyof DeviceGroupDetails,
  ((value: string) => string | null) | null
>;

export interface DeviceGroupDetailsFormProps {
  data: DeviceGroupDetailsFormData;
  onChangeData: (data: DeviceGroupDetailsFormData) => void;
  validate: DeviceGroupDetailsFormValidation;
}

export function DeviceGroupDetailsForm({
  data: formData,
  onChangeData: setFormData,
  validate,
}: DeviceGroupDetailsFormProps) {
  const onInputChange = useCallback(
    (event: DeviceGroupDetailsFormChangeEvent) => {
      const newValue = event.target.value;
      const name = event.target.name as keyof DeviceGroupDetails;
      setFormData({
        ...formData,
        [name]: { ...formData[name], value: newValue, touched: true },
      });
    },
    [formData, setFormData]
  );

  const onBlur = useCallback(
    (event: DeviceGroupDetailsFormBlurEvent) => {
      const value = event.target.value;
      const name = event.target.name as keyof DeviceGroupDetails;
      const error = validate[name]?.(value);
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          error,
        },
      });
    },
    [formData, setFormData, validate]
  );

  return (
    <form>
      <Columns desktop={{ gap: 4 }} className="mb-2">
        <Columns.Column>
          <Form.Label className="has-text-weight-normal">Group name</Form.Label>
          <Form.Field>
            <Form.Control>
              <Form.Input
                name="name"
                value={formData.name.value}
                onChange={onInputChange}
                onBlur={onBlur}
              />
            </Form.Control>
            <FeedbackMessage>{formData.name.error}</FeedbackMessage>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Label className="has-text-weight-normal">
            Description (Optional)
          </Form.Label>
          <Form.Field>
            <Form.Control>
              <Form.Input
                name="description"
                value={formData.description.value}
                onChange={onInputChange}
                onBlur={onBlur}
              />
            </Form.Control>
            <FeedbackMessage>{formData.description.error}</FeedbackMessage>
          </Form.Field>
        </Columns.Column>
      </Columns>
    </form>
  );
}

export default DeviceGroupDetailsForm;
