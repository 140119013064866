import { faker } from '@faker-js/faker';
import { define } from 'cooky-cutter';
import { Opportunity } from '../types';
import { accountFactory } from './accounts';

export const opportunityFactory = define<Opportunity>({
  id: () => faker.string.uuid(),
  name: () => faker.lorem.word(3),
  account: () => accountFactory(),
  salesOrders: () => [],
});
