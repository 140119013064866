import { formatSalesOrderNumber } from '@zspace/format';
import { SoftwareAssignmentReview } from '@zspace/types';
import { Element } from 'react-bulma-components';
import Conditional from '../../../shared/conditional/conditional';
import { formatDateToLocaleString } from '../../../shared/utils';
import InfoCard from '../../../ui/info-card/info-card';

export type ReviewSoftwareAssignmentCardProps = {
  assignment: SoftwareAssignmentReview;
};

export function ReviewSoftwareAssignmentCard({
  assignment,
}: ReviewSoftwareAssignmentCardProps) {
  return (
    <InfoCard
      clickable={false}
      fullWidth
      title={
        <span className="is-size-4 has-text-weight-light">
          {assignment.licensingProductGroup.displayName}
        </span>
      }
      footer={
        <span className="is-size-6">
          <Conditional condition={!!assignment.licenseKey.expirationDate}>
            <Conditional.True>
              {`Expires on ${formatDateToLocaleString(
                assignment.licenseKey.expirationDate!
              )}`}
            </Conditional.True>
            <Conditional.False>Perpetual</Conditional.False>
          </Conditional>
        </span>
      }
    >
      <Element display="flex" flexDirection="column" className="gap-1">
        <span className="is-size-6">
          {`License key ending in ${assignment.licenseKey.lastFourDigits}`}
        </span>
        <span className="is-underlined is-size-6">
          {formatSalesOrderNumber(assignment.softwareSeat.salesOrder.number)}
        </span>
      </Element>
    </InfoCard>
  );
}

export default ReviewSoftwareAssignmentCard;
