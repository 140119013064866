import { ReactNode, useMemo } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaChevronRight } from 'react-icons/fa6';
import If from '../../../shared/if/if';
import styles from './inbox-item.module.scss';

export type InboxItemProps = {
  children: ReactNode;
  onClick: () => void;
  active: boolean;
};

export function InboxItem({ children, onClick, active }: InboxItemProps) {
  const containerClassName = useMemo(() => {
    const defaultClassName = styles.container;
    const activeClassName = styles.active;
    return active ? `${defaultClassName} ${activeClassName}` : defaultClassName;
  }, [active]);

  return (
    <Element
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={4}
      px={2}
      className={containerClassName}
      onClick={onClick}
    >
      <Element display="flex">{children}</Element>
      <If condition={active}>
        <Icon>
          <FaChevronRight />
        </Icon>
      </If>
    </Element>
  );
}

export default InboxItem;
