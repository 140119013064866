import { PaginatedContentConfig } from '@zspace/types';
import { ReactNode, useMemo } from 'react';
import { Element } from 'react-bulma-components';
import Conditional from '../../../shared/conditional/conditional';
import useIsEmpty from '../../../shared/hooks/is-empty';
import BasicTable, { BasicTableProps } from '../basic-table/basic-table';
import TableForm, { TableFormConfig } from '../table-form/table-form';
import styles from './paginated-table.module.scss';

type TableFormProps = React.ComponentProps<typeof TableForm>;
export type FormProps = Pick<
  TableFormProps,
  'disableSearch' | 'extra' | 'debounceMillis' | 'disableItemsPerPageSelection'
> & {
  show?: boolean;
  itemsPerPageOptions?: number[];
};

export type PaginatedTableProps<T, K = unknown> = Omit<
  BasicTableProps<T, K>,
  'config'
> & {
  config: PaginatedContentConfig;
  empty?: ReactNode;
  form?: FormProps;
};

export type CustomPaginatedTableProps<T> = Omit<
  PaginatedTableProps<T>,
  'columns' | 'rowKey'
>;

export function PaginatedTable<T, K = unknown>({
  dataSource,
  config,
  form,
  empty,
  onChange,
  errorMessage,
  children,
  ...tableProps
}: PaginatedTableProps<T, K>) {
  const tableFormConfig: TableFormConfig = {
    ...config,
  };
  const isDataEmpty = useIsEmpty(dataSource);

  const showForm = useMemo(
    () => (form?.show === undefined ? true : form.show),
    [form]
  );

  const basicTableProps = useMemo(
    () => ({
      dataSource,
      config,
      onChange,
      ...tableProps,
    }),
    [dataSource, config, onChange, tableProps]
  );

  function onTableFormChange(value: PaginatedContentConfig): void {
    const newConfig = { ...value };

    if (config.pageNumber === value.pageNumber) {
      newConfig.pageNumber = 1;
    }

    onChange?.({ config: newConfig });
  }

  return (
    <>
      {showForm && (
        <TableForm
          config={tableFormConfig}
          extra={form?.extra}
          disableSearch={form?.disableSearch}
          debounceMillis={form?.debounceMillis}
          onChange={onTableFormChange}
          itemsPerPageOptions={form?.itemsPerPageOptions}
          disableItemsPerPageSelection={form?.disableItemsPerPageSelection}
        />
      )}
      {errorMessage}
      {children}
      <Conditional condition={isDataEmpty}>
        <Conditional.True>{empty}</Conditional.True>
        <Conditional.False>
          <Element className={styles.wrapper}>
            <BasicTable {...basicTableProps} />
          </Element>
        </Conditional.False>
      </Conditional>
    </>
  );
}

export default PaginatedTable;
