import { formatSalesOrderNumber } from '@zspace/format';
import { DeviceGroupPermissions, WorkOrderPermissions } from '@zspace/roles';
import {
  DeferredResponse,
  WorkOrderData,
  WorkOrderStatus,
} from '@zspace/types';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import {
  FaDisplay,
  FaLaptopFile,
  FaPenToSquare,
  FaTrash,
  FaUsers,
} from 'react-icons/fa6';
import {
  LoaderFunction,
  Outlet,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useParams,
  useRevalidator,
} from 'react-router-dom';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import Button from '../../ui/button/button';
import Vr from '../../ui/vr/vr';
import WorkOrderConfirmDeletionModal from '../work-order-confirm-deletion-modal/work-order-confirm-deletion-modal';
import {
  deleteWorkOrderById,
  fetchWorkOrderById,
} from '../work-orders-service';
import WorkOrderDeviceGroupsDetailLayoutColumns from './work-order-device-groups-detail-layout-columns/work-order-device-groups-detail-layout-columns';

const DELETE_WORK_ORDER_ERROR_MESSAGE =
  'The work order could not be deleted. Please try again';

export const loader: LoaderFunction = async ({ params }) => {
  const { id } = params;

  const response = fetchWorkOrderById(id as string);

  return defer({ response });
};

type WorkOrderDeviceGroupsDetailLayoutContentProps = {
  revalidate: () => void;
};

function WorkOrderDeviceGroupsDetailLayoutContent({
  revalidate,
}: WorkOrderDeviceGroupsDetailLayoutContentProps) {
  const workOrderData = useAsyncValue() as WorkOrderData;

  const { id } = useParams();
  const navigate = useNavigate();
  const { executeHttpRequest, isLoading } = useHttpRequest();

  const [displayDeleteConfirmationModal, setDisplayDeleteConfirmationModal] =
    useState(false);

  const handleEditWorkOrderClick = useCallback(() => {
    navigate(`/work-orders/${id}/edit`);
  }, [id, navigate]);

  const handleDeleteWorkOrderClick = useCallback(() => {
    setDisplayDeleteConfirmationModal(true);
  }, []);

  const handleCancelWorkOrderDeletion = useCallback(() => {
    setDisplayDeleteConfirmationModal(false);
  }, []);

  const handleWorkOrderDeletion = useCallback(
    () =>
      executeHttpRequest({
        asyncFunction: async () => {
          deleteWorkOrderById(workOrderData.id);
          setDisplayDeleteConfirmationModal(false);
          navigate('/');
        },
        customErrorMessage: DELETE_WORK_ORDER_ERROR_MESSAGE,
      }),
    [executeHttpRequest, navigate, workOrderData.id]
  );

  const shouldDisplayEditButton = useMemo(
    () => workOrderData.status === WorkOrderStatus.DRAFT,
    [workOrderData.status]
  );

  const shouldDisplayDeleteButton = useMemo(
    () =>
      workOrderData.status === WorkOrderStatus.DRAFT &&
      !workOrderData.deviceGroups.length,
    [workOrderData.deviceGroups.length, workOrderData.status]
  );

  return (
    <>
      <WorkOrderDeviceGroupsDetailLayoutColumns>
        <WorkOrderDeviceGroupsDetailLayoutColumns.Left>
          <Columns className="w-full">
            <Columns.Column
              size={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Icon size={'medium'} color="primary">
                <FaLaptopFile size={'medium'} />
              </Icon>
            </Columns.Column>
            <Columns.Column>
              <Element>
                <Element display="flex" alignItems="center">
                  <h1 className="is-size-4 has-text-weight-light">
                    {workOrderData.name}
                  </h1>
                  <CheckPermissions
                    permissions={WorkOrderPermissions.WORK_ORDERS_DRAFTS_UPDATE}
                  >
                    <CheckPermissions.Render>
                      {shouldDisplayEditButton && (
                        <Button
                          ml={1}
                          text
                          className="has-text-primary-dark"
                          style={{ textDecoration: 'none', fontSize: '14px' }}
                          onClick={handleEditWorkOrderClick}
                        >
                          <Icon>
                            <FaPenToSquare />
                          </Icon>
                          <span>Edit</span>
                        </Button>
                      )}
                    </CheckPermissions.Render>
                  </CheckPermissions>
                  <CheckPermissions
                    permissions={DeviceGroupPermissions.DEVICE_GROUPS_DELETE}
                  >
                    <CheckPermissions.Render>
                      {shouldDisplayDeleteButton && (
                        <Button
                          color="danger-dark"
                          outlined
                          className="is-borderless"
                          style={{ textDecoration: 'none', fontSize: '14px' }}
                          onClick={handleDeleteWorkOrderClick}
                        >
                          <Icon>
                            <FaTrash />
                          </Icon>
                          <span>Delete</span>
                        </Button>
                      )}
                    </CheckPermissions.Render>
                  </CheckPermissions>
                </Element>
                <span className="is-size-7 mr-4">
                  Work order #{workOrderData.number}
                </span>
                <span className="is-size-7 mr-4">{workOrderData.type}</span>
                <span className="is-size-7 mr-4">
                  {workOrderData.salesOrders
                    .sort((a, b) => a.number - b.number)
                    .map((so) => formatSalesOrderNumber(so.number))
                    .join(', ')}
                </span>
              </Element>
            </Columns.Column>
          </Columns>
        </WorkOrderDeviceGroupsDetailLayoutColumns.Left>
        <WorkOrderDeviceGroupsDetailLayoutColumns.Right>
          <Columns>
            {/* Devices column */}
            <Columns.Column>
              <Columns multiline={false} className="is-gapless">
                <Columns.Column
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon size={'medium'} color="primary">
                    <FaDisplay size={'medium'} />
                  </Icon>
                </Columns.Column>

                <Columns.Column display="flex" flexDirection="column">
                  <span className="is-size-7">Devices</span>
                  <span className="is-size-4">
                    {workOrderData.devices.assigned}
                  </span>
                  <span className="is-size-7">Grouped</span>
                </Columns.Column>

                <Columns.Column
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                >
                  <span className="is-size-4">
                    {workOrderData.devices.unassigned}
                  </span>
                  <span className="is-size-7">Ungrouped</span>
                </Columns.Column>
              </Columns>
            </Columns.Column>

            <Vr />

            {/* Software Seats column */}
            <Columns.Column>
              <Columns multiline={false} className="is-gapless">
                <Columns.Column
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon size={'medium'} color="primary">
                    <FaUsers size={'medium'} />
                  </Icon>
                </Columns.Column>

                <Columns.Column display="flex" flexDirection="column">
                  <span className="is-size-7" style={{ whiteSpace: 'nowrap' }}>
                    Software seats
                  </span>
                  <span className="is-size-4">
                    {workOrderData.softwareSeats?.assigned}
                  </span>
                  <span className="is-size-7">Assigned</span>
                </Columns.Column>

                <Columns.Column
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                >
                  <span className="is-size-4">
                    {workOrderData.softwareSeats?.unassigned}
                  </span>
                  <span className="is-size-7">Unassigned</span>
                </Columns.Column>
              </Columns>
            </Columns.Column>
          </Columns>
        </WorkOrderDeviceGroupsDetailLayoutColumns.Right>
      </WorkOrderDeviceGroupsDetailLayoutColumns>

      <Outlet context={{ workOrder: workOrderData, revalidate }} />

      <WorkOrderConfirmDeletionModal
        show={displayDeleteConfirmationModal}
        workOrder={workOrderData}
        loading={isLoading}
        onCancel={handleCancelWorkOrderDeletion}
        onDelete={handleWorkOrderDeletion}
      />
    </>
  );
}

function WorkOrderDeviceGroupsDetailLayout() {
  const { response } = useLoaderData() as DeferredResponse<WorkOrderData>;
  const revalidator = useRevalidator();

  const revalidate = useCallback(() => {
    revalidator.revalidate();
  }, [revalidator]);

  if (revalidator.state === 'loading') {
    return <WorkOrderDeviceGroupsDetailLayoutColumns.Skeleton />;
  }

  return (
    <Suspense fallback={<WorkOrderDeviceGroupsDetailLayoutColumns.Skeleton />}>
      <ErrorHandlingAwait resolve={response}>
        <WorkOrderDeviceGroupsDetailLayoutContent revalidate={revalidate} />
      </ErrorHandlingAwait>
    </Suspense>
  );
}
export default WorkOrderDeviceGroupsDetailLayout;
