import { SVGProps } from 'react';
const Zcentral = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="url(#b)" d="M19.725 16.876H5.31v7.123h14.414v-7.123Z" />
      <path
        fill="url(#c)"
        d="m9.282 6.956 4.81-1.116c2.697.046 3.696 3.474 1.916 5.502l-5.76 5.817s-2.206 2.207-2.619 4.361c-.499 2.599 1.366 2.477 1.366 2.477H4.158A3.789 3.789 0 0 1 .36 20.132v-.584c0-2.138 1.169-4.071 2.344-5.32l6.577-7.272Z"
      />
      <path
        fill="url(#d)"
        d="M18.432 8.587 15.71 11.68s1.497-1.95.068-3.484c-1.43-1.534-5.99-1.106-5.99-1.106l-9.444.03V0H14.55a5.171 5.171 0 0 1 3.881 8.587Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={0.344}
        x2={24.08}
        y1={12.001}
        y2={12.001}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.31} stopColor="#E64B38" />
        <stop offset={1} stopColor="#655DC6" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={9.906}
        x2={10.201}
        y1={-3.545}
        y2={23.775}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.06} stopColor="#00BC70" />
        <stop offset={0.1} stopColor="#13BA69" />
        <stop offset={0.24} stopColor="#51B254" />
        <stop offset={0.39} stopColor="#86AC42" />
        <stop offset={0.53} stopColor="#B1A733" />
        <stop offset={0.66} stopColor="#D3A327" />
        <stop offset={0.79} stopColor="#EBA01F" />
        <stop offset={0.9} stopColor="#FA9F1A" />
        <stop offset={1} stopColor="#FF9E18" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={0.193}
        x2={19.571}
        y1={5.84}
        y2={5.84}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0071CE" />
        <stop offset={0.67} stopColor="#00C1DE" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M.344 0h19.38v24H.344z" />
      </clipPath>
    </defs>
  </svg>
);
export default Zcentral;
