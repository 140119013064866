import { SVGProps } from 'react';

const CircleDisplay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
    {...props}
  >
    <rect width="64" height="64" rx="32" fill="#194F7B" />
    <path
      d="M47 16C48.625 16 50 17.375 50 19V39C50 40.6875 48.625 42 47 42H35L36 45H40.5C41.3125 45 42 45.6875 42 46.5C42 47.375 41.3125 48 40.5 48H23.5C22.625 48 22 47.375 22 46.5C22 45.6875 22.625 45 23.5 45H28L29 42H17C15.3125 42 14 40.6875 14 39V19C14 17.375 15.3125 16 17 16H47ZM46 38V20H18V38H46Z"
      fill="white"
    />
  </svg>
);

export default CircleDisplay;
