import {
  HTMLAttributes,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Element, Icon } from 'react-bulma-components';
import { Color } from 'react-bulma-components/src/components';
import If from '../../shared/if/if';
import AnimatedChevronIcon from '../animated-chevron-icon/animated-chevron-icon';
import Button from '../button/button';
import styles from './dropdown-menu.module.scss';

export interface DropdownMenuProps {
  children: React.ReactNode;
  label?: string;
  labelStyle?: string;
  borderless?: boolean;
  outlined?: boolean;
  prefixIcon?: React.ReactNode;
  hasSuffixChevron?: boolean;
  color?: Color;
  alignedRight?: boolean;
}

type DropdownMenuContextType = {
  close: () => void;
};

const DropdownMenuContext = createContext<DropdownMenuContextType>({
  close: () => {},
});

export function DropdownMenu({
  children,
  label,
  labelStyle,
  borderless,
  outlined,
  prefixIcon,
  hasSuffixChevron,
  color,
  alignedRight,
}: DropdownMenuProps) {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setIsActive(!isActive);
    },
    [isActive]
  );

  return (
    <div
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setIsActive(false);
        }
      }}
      className={`dropdown ${alignedRight ? `is-right` : ``} ${
        isActive ? `is-active` : ``
      }`}
    >
      <div className="dropdown-trigger">
        <Button
          outlined={outlined}
          color={color}
          className={`${styles.triggerButton} ${
            borderless ? `is-borderless` : ``
          }`}
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={toggleMenu}
        >
          <If condition={!!prefixIcon}>{prefixIcon}</If>
          <If condition={!!label}>
            <Element display="flex" alignItems="center" className="gap-0">
              <span className={labelStyle}>{label}</span>
              <If condition={!!hasSuffixChevron}>
                <Icon color="primary-dark">
                  <AnimatedChevronIcon active={isActive} />
                </Icon>
              </If>
            </Element>
          </If>
        </Button>
      </div>
      <div
        className={`dropdown-menu ${styles.dropdownMenuContainer}`}
        id="dropdown-menu"
        role="menu"
        tabIndex={0}
      >
        <div className={`dropdown-content ${styles.clickableContent}`}>
          <DropdownMenuContext.Provider
            value={{ close: () => setIsActive(false) }}
          >
            {children}
          </DropdownMenuContext.Provider>
        </div>
      </div>
    </div>
  );
}

type ItemProps = HTMLAttributes<HTMLDivElement> & {
  closeOnClick?: boolean;
  stopEventPropagationOnClick?: boolean;
  children: ReactNode;
  show?: boolean;
};

const Item = ({
  onClick,
  closeOnClick = false,
  stopEventPropagationOnClick = true,
  children,
  show = true,
}: ItemProps) => {
  const { close } = useContext(DropdownMenuContext);

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (stopEventPropagationOnClick) {
      e.stopPropagation();
    }

    onClick?.(e);

    if (closeOnClick) {
      close();
    }
  };

  return (
    <If condition={show}>
      <div onClick={handleOnClick}>{children}</div>
    </If>
  );
};

DropdownMenu.Item = Item;

export default DropdownMenu;
