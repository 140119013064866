import {
  DeferredResponse,
  DeviceGroupsTableRow,
  NewDeviceGroup,
  PaginatedAPIResponse,
  SortDirection,
  WorkOrderDetailPageContext,
} from '@zspace/types';
import { Suspense, useCallback } from 'react';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import CreateDeviceGroupLayout from '../../device-groups/create-device-group-layout/create-device-group-layout';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import { createSearchParams } from '../../shared/url';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import {
  createDeviceGroup,
  fetchWorkOrderDevices,
} from '../work-orders-service';

const DEVICE_GROUP_CREATE_ERROR_MESSAGE =
  'The device group could not be created. Please try again';

export const loader: LoaderFunction = ({ params, request }) => {
  const searchParams = createSearchParams(request);
  const workOrderId = params.id;

  const response = fetchWorkOrderDevices(workOrderId!, {
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: searchParams.get('sortBy') ?? '',
    sortDirection: (searchParams.get('sortDirection') as SortDirection) ?? '',
  });

  return defer({ response });
};

function CreateDeviceGroupCardPageContent() {
  const { id } = useParams();
  const { revalidate } = useOutletContext<WorkOrderDetailPageContext>();
  const navigate = useNavigate();
  const { executeHttpRequest, isLoading: isCreatingNewDeviceGroup } =
    useHttpRequest();

  const response =
    useAsyncValue() as PaginatedAPIResponse<DeviceGroupsTableRow>;

  const navigateToWorkOrderDeviceGroupsDetails = useCallback(() => {
    revalidate();
    navigate(`/work-orders/${id}/device-groups`);
  }, [id, navigate, revalidate]);

  const handleOnCreateDeviceGroup = useCallback(
    (newDeviceGroupData: NewDeviceGroup) =>
      executeHttpRequest({
        asyncFunction: async () => {
          await createDeviceGroup(id!, newDeviceGroupData);
          navigateToWorkOrderDeviceGroupsDetails();
        },
        customErrorMessage: DEVICE_GROUP_CREATE_ERROR_MESSAGE,
      }),
    [executeHttpRequest, id, navigateToWorkOrderDeviceGroupsDetails]
  );

  return (
    <CreateDeviceGroupLayout
      devices={response}
      onCancel={navigateToWorkOrderDeviceGroupsDetails}
      isCreatingNewDeviceGroup={isCreatingNewDeviceGroup}
      onCreateDeviceGroup={handleOnCreateDeviceGroup}
      customer={false}
    />
  );
}

export function CreateDeviceGroupCardPage() {
  const { response } = useLoaderData() as DeferredResponse<
    PaginatedAPIResponse<DeviceGroupsTableRow>
  >;

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <CreateDeviceGroupCardPageContent />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default CreateDeviceGroupCardPage;
