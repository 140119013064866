import { ComponentProps } from 'react';
import { Icon } from 'react-bulma-components';
import { FaChevronRight } from 'react-icons/fa6';
import Button from '../button/button';
import styles from './home-button.module.scss';

export type HomeButtonProps = ComponentProps<typeof Button> & {
  title?: string;
};

export function HomeButton({
  title = 'Get started',
  ...props
}: HomeButtonProps) {
  return (
    <Button className={styles.actionButton} {...props}>
      <span className="has-text-weight-bold is-size-4 has-text-white">
        {title}
      </span>
      <Icon className="is-size-4">
        <FaChevronRight className={styles.buttonIcon} />
      </Icon>
    </Button>
  );
}

export default HomeButton;
