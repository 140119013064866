import { formatWorkOrderNumber } from '@zspace/format';
import {
  AllYesNoFilter,
  ApplicationVariantType,
  FilterType,
  HardwareModel,
  PaginatedAPIResponse,
  SalesOrderData,
  SalesOrderDevicesTableRow,
  SalesOrderSoftwareTitlesTableRow,
  SortDirection,
  User,
  UserRoleType,
  UserStatus,
  UserViewMode,
  UsersCriteria,
} from '@zspace/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import {
  FaArrowUpRightFromSquare,
  FaBolt,
  FaBuilding,
  FaCalendar,
  FaGear,
  FaLaptop,
  FaUser,
} from 'react-icons/fa6';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Conditional from '../../shared/conditional/conditional';
import useHttpRequest from '../../shared/hooks/http-request';
import useIsCustomerUser from '../../shared/hooks/is-customer-user';
import If from '../../shared/if/if';
import { formatDateToLocaleString } from '../../shared/utils';
import DetailsCard from '../../ui/details-card/details-card';
import InboxTable from '../../ui/inbox-table/inbox-table';
import InfoLabel from '../../ui/info-label/info-label';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { OnTableChangeData } from '../../ui/table/types';
import MyTeamTable from '../../users/my-team-table/my-team-table';
import { PermissionsGroupBySalesOrder } from '../../users/users-table/users-table';
import { fetchSalesOrderRelatedUsers } from '../../work-orders/sales-orders-service';
import SalesOrderDevicesTable from '../sales-order-devices-table/sales-order-devices-table';
import SalesOrderSoftwareTitlesTable from '../sales-order-software-titles-table/sales-order-software-titles-table';
import styles from './sales-order-details-layout.module.scss';

export type SalesOrderDetailsLayoutProps = {
  salesOrder: SalesOrderData;
  summaryLayout?: boolean;
};

const initialUsersCriteria: UsersCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
  email: '',
  emailFilter: FilterType.CONTAINS,
  lastActiveDateFrom: '',
  lastActiveDateTo: '',
  permissionsGroup: '',
  temporaryRoles: AllYesNoFilter.ALL,
  status: UserStatus.ALL,
  viewMode: UserViewMode.RELATED_USERS,
  salesOrder: [],
};

export function SalesOrderDetailsLayout({
  salesOrder,
  summaryLayout = false,
}: SalesOrderDetailsLayoutProps) {
  const params = useParams();
  const isCustomerUser = useIsCustomerUser();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [relatedUsers, setRelatedUsers] = useState<User[]>([]);
  const [criteria, setCriteria] = useState<UsersCriteria>({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: searchParams.get('sortBy') ?? '',
    sortDirection: searchParams.get('sortDirection') as SortDirection,
    email: '',
    emailFilter: FilterType.CONTAINS,
    lastActiveDateFrom: '',
    lastActiveDateTo: '',
    permissionsGroup: '',
    temporaryRoles: AllYesNoFilter.ALL,
    status: UserStatus.ALL,
    viewMode: UserViewMode.RELATED_USERS,
    salesOrder: [salesOrder.number.toString()],
  });
  const { executeHttpRequest: executeFetchMoreRelatedUsers } = useHttpRequest();
  const {
    executeHttpRequest: executeFetchRelatedUsers,
    isLoading: isLoadingMyDeviceGroups,
  } = useHttpRequest();
  const [relatedUsersDataResponse, setRelatedUsersDataResponse] = useState<
    PaginatedAPIResponse<User>
  >({
    data: [],
    count: 0,
    page: 0,
    total: 0,
    pages: 0,
    hasMore: true,
  });

  const fetchRelatedUsers = useCallback(
    async (criteria: UsersCriteria) => {
      executeFetchRelatedUsers({
        asyncFunction: async () => {
          const salesOrderId = params.id! ?? salesOrder.id;
          const usersResponse = await fetchSalesOrderRelatedUsers(
            salesOrderId,
            criteria
          );
          setRelatedUsers(usersResponse.data);
          setRelatedUsersDataResponse(usersResponse);
        },
      });
    },
    [params.id, executeFetchRelatedUsers, salesOrder.id]
  );

  const fetchMoreRelatedUsers = useCallback(async () => {
    executeFetchMoreRelatedUsers({
      asyncFunction: async () => {
        const nextPage = criteria.pageNumber + 1;
        const updatedCriteria = {
          ...criteria,
          pageNumber: nextPage,
        };
        const salesOrderId = params.id! ?? salesOrder.id;
        const usersResponse = await fetchSalesOrderRelatedUsers(
          salesOrderId,
          updatedCriteria
        );

        setRelatedUsers((prevRelatedUsers) => [
          ...prevRelatedUsers,
          ...usersResponse.data,
        ]);
        setRelatedUsersDataResponse(usersResponse);
        setCriteria(updatedCriteria);
      },
    });
  }, [criteria, executeFetchMoreRelatedUsers, params.id, salesOrder.id]);

  const hasMoreRelatedUsers = useMemo(
    () => !!relatedUsersDataResponse.hasMore,
    [relatedUsersDataResponse]
  );

  useEffect(() => {
    if (!summaryLayout) {
      const fetchUsersCriteria = {
        ...initialUsersCriteria,
        salesOrder: [salesOrder.number.toString()],
      };
      fetchRelatedUsers(fetchUsersCriteria);
    }
  }, [fetchRelatedUsers, salesOrder.number, searchParams, summaryLayout]);

  const tableConfig = useMemo(() => {
    const { search, sortBy, sortDirection } = criteria;
    return {
      search,
      sortBy,
      sortDirection,
    };
  }, [criteria]);

  const emptyContentText = useMemo(() => {
    const hasAppliedFilters = criteria.search !== '';
    if (hasAppliedFilters) {
      return 'There are no users that match the selected criteria';
    }
    return 'There are no users at the moment';
  }, [criteria.search]);

  const workOrderNumbers = useMemo(
    () =>
      salesOrder.workOrders?.map((wo) => formatWorkOrderNumber(wo.number)) ??
      [],
    [salesOrder.workOrders]
  );

  const softwareTitlesLines = useMemo(() => {
    return salesOrder.lines.filter((sol) => sol.softwareSeat);
  }, [salesOrder.lines]);

  const devicesLines = useMemo(() => {
    return salesOrder.lines.filter((sol) => sol.device);
  }, [salesOrder.lines]);

  const devicesTableData = useMemo(() => {
    return Object.values(
      devicesLines.reduce(
        (acc: Record<string, SalesOrderDevicesTableRow>, deviceLine) => {
          const key = deviceLine.device!.model.displayName;
          if (!acc[key]) {
            acc[key] = {
              model: key as HardwareModel,
              amount: 0,
            };
          }
          acc[key] = {
            ...acc[key],
            amount: acc[key].amount + 1,
          };

          return acc;
        },
        {}
      )
    ).slice(0, 10);
  }, [devicesLines]);

  const softwareTitlesTableData = useMemo(() => {
    // Group by software title and software type
    const groupedSoftwareTitles = softwareTitlesLines.reduce(
      (acc: Record<string, SalesOrderSoftwareTitlesTableRow>, swLine) => {
        const swTitle = swLine.softwareSeat!.licensingProductGroup.displayName;
        const swType = ApplicationVariantType.NATIVE;
        const key = `${swTitle}-${swType}`;

        if (!acc[key]) {
          acc[key] = {
            id: key,
            title: swTitle,
            seats: 0,
            type: swType,
          };
        }
        acc[key] = {
          ...acc[key],
          seats: acc[key].seats + 1,
        };

        return acc;
      },
      {}
    );
    return Object.values(groupedSoftwareTitles).slice(0, 10);
  }, [softwareTitlesLines]);

  const handleOnTableChange = useCallback(
    (value: OnTableChangeData<User, PermissionsGroupBySalesOrder>): void => {
      const newCriteria = {
        ...criteria,
        ...value.config,
        sortBy: value.column?.key || criteria.sortBy,
      };

      setCriteria(newCriteria);
    },
    [criteria]
  );

  const onSearchChange = useCallback(
    (value: string) => {
      const fetchUsersCriteria = {
        ...initialUsersCriteria,
        salesOrder: [salesOrder.number.toString()],
        search: value,
      };
      setCriteria(fetchUsersCriteria);
      fetchRelatedUsers(fetchUsersCriteria);
    },
    [fetchRelatedUsers, salesOrder.number]
  );

  const navigateToEditUserPermissions = useCallback(
    (user: User) => {
      navigate(`/users/my-team/${user.id}/edit`);
    },
    [navigate]
  );

  const myTeamTable = useMemo(() => {
    return (
      <MyTeamTable
        onChange={handleOnTableChange}
        dataSource={relatedUsers}
        config={tableConfig}
        userRoleType={UserRoleType.CUSTOMER}
        fetchMore={fetchMoreRelatedUsers}
        hasMore={hasMoreRelatedUsers}
        empty={
          <Element display="flex" justifyContent="center" className="my-2">
            <span className="has-text-weight-light">{emptyContentText}</span>
          </Element>
        }
        handleUserTemporaryPermissionsClick={navigateToEditUserPermissions}
      />
    );
  }, [
    emptyContentText,
    fetchMoreRelatedUsers,
    handleOnTableChange,
    hasMoreRelatedUsers,
    relatedUsers,
    tableConfig,
    navigateToEditUserPermissions,
  ]);

  return (
    <Columns className="p-4">
      <Columns
        display="flex"
        flexDirection="column"
        className={`pr-7 ${styles.informationColumn}`}
      >
        <Columns.Column>
          <DetailsCard title="Basic information">
            <InfoLabel
              icon={<FaBuilding />}
              label="Account"
              content={
                <Conditional condition={!!salesOrder.opportunity}>
                  <Conditional.True>
                    <Conditional condition={!!summaryLayout}>
                      <Conditional.True>
                        <span>{salesOrder.opportunity?.account.name}</span>
                      </Conditional.True>
                      <Conditional.False>
                        <Link
                          to={`/accounts/${salesOrder.opportunity?.account.id}`}
                          className={styles.content}
                        >
                          {salesOrder.opportunity?.account.name}
                        </Link>
                      </Conditional.False>
                    </Conditional>
                  </Conditional.True>
                  <Conditional.False>-</Conditional.False>
                </Conditional>
              }
            />
            <InfoLabel
              icon={<FaBuilding />}
              label="Parent account"
              content={
                <Conditional
                  condition={!!salesOrder.opportunity?.account.parent}
                >
                  <Conditional.True>
                    <Conditional condition={!!summaryLayout}>
                      <Conditional.True>
                        <span>
                          {salesOrder.opportunity?.account.parent?.name}
                        </span>
                      </Conditional.True>
                      <Conditional.False>
                        <Link
                          to={`/accounts/${salesOrder.opportunity?.account.parent?.id}`}
                          className={styles.content}
                        >
                          {salesOrder.opportunity?.account.parent?.name}
                        </Link>
                      </Conditional.False>
                    </Conditional>
                  </Conditional.True>
                  <Conditional.False>-</Conditional.False>
                </Conditional>
              }
            />
            <InfoLabel
              icon={<FaCalendar />}
              label="Close date"
              content={
                <span className={styles.content}>
                  {salesOrder.closeDate
                    ? formatDateToLocaleString(salesOrder.closeDate)
                    : '-'}
                </span>
              }
            />
            <If condition={!isCustomerUser && workOrderNumbers.length > 0}>
              <InfoLabel
                icon={<FaGear />}
                label="Work orders"
                content={workOrderNumbers.join(', ')}
              />
            </If>
          </DetailsCard>
        </Columns.Column>
        <Columns.Column>
          <DetailsCard title="Contacts">
            <InfoLabel
              icon={<FaUser />}
              label="End User Software Contact"
              content={
                <>
                  <If condition={!!salesOrder.endUser?.fullName}>
                    <span className={styles.content}>
                      {`${
                        salesOrder.endUser
                          ? `${salesOrder.endUser?.fullName}`
                          : ''
                      } `}
                    </span>
                  </If>
                  <span className={styles.content}>
                    {salesOrder.endUser?.email ?? '-'}
                  </span>
                </>
              }
            />
            <InfoLabel
              icon={<FaUser />}
              label="Billing Contact"
              content={
                <>
                  <If condition={!!salesOrder.billingContact?.fullName}>
                    <span className={styles.content}>
                      {`${
                        salesOrder.billingContact
                          ? `${salesOrder.billingContact.fullName}`
                          : ''
                      } `}
                    </span>
                  </If>
                  <span className={styles.content}>
                    {salesOrder.billingContact?.email ?? '-'}
                  </span>
                </>
              }
            />
            <InfoLabel
              icon={<FaUser />}
              label="Shipping Contact"
              content={
                <>
                  <If condition={!!salesOrder.shippingContact?.fullName}>
                    <span className={styles.content}>
                      {`${
                        salesOrder.shippingContact
                          ? `${salesOrder.shippingContact?.fullName}`
                          : ''
                      } `}
                    </span>
                  </If>
                  <span className={styles.content}>
                    {salesOrder.shippingContact?.email ?? '-'}
                  </span>
                </>
              }
            />
          </DetailsCard>
        </Columns.Column>
      </Columns>

      <Columns
        display="flex"
        flexDirection="column"
        className={`is-flex-grow-1 ${styles.informationColumn}`}
      >
        <Columns.Column display="flex" paddingless>
          <Columns.Column className="pb-0">
            <DetailsCard>
              <>
                <section className="pb-6 is-flex is-align-items-center">
                  <Icon color="primary" mr={2}>
                    <FaBolt size={20} />
                  </Icon>
                  <span className="is-size-4 has-text-weight-light">
                    {`${softwareTitlesTableData.length} Software ${
                      softwareTitlesTableData.length !== 1 ? 'Titles' : 'Title'
                    }`}
                  </span>
                  <If condition={!summaryLayout}>
                    <Link
                      to={`/my-software?salesOrders=SO${salesOrder.number}`}
                      className="is-underlined has-text-primary pl-2"
                    >
                      <Icon color="primary">
                        <FaArrowUpRightFromSquare />
                      </Icon>
                      Details
                    </Link>
                  </If>
                </section>
                <SalesOrderSoftwareTitlesTable data={softwareTitlesTableData} />
              </>
            </DetailsCard>
          </Columns.Column>
          <Columns.Column>
            <DetailsCard>
              <>
                <section className="pb-6 is-flex is-align-items-center">
                  <Icon color="primary" mr={2}>
                    <FaLaptop size={24} />
                  </Icon>
                  <span className="is-size-4 has-text-weight-light">
                    {`${devicesLines.length} Total ${
                      devicesLines.length !== 1 ? 'Devices' : 'Device'
                    }`}
                  </span>
                  <If condition={!summaryLayout}>
                    <Link
                      to={`/my-devices?salesOrders=SO${salesOrder.number}`}
                      className="is-underlined has-text-primary pl-2"
                    >
                      <Icon color="primary">
                        <FaArrowUpRightFromSquare />
                      </Icon>
                      Details
                    </Link>
                  </If>
                </section>
                <SalesOrderDevicesTable data={devicesTableData} />
              </>
            </DetailsCard>
          </Columns.Column>
        </Columns.Column>
        <If condition={!summaryLayout}>
          <Columns.Column className={`pt-0 ${styles.tablesColumn}`}>
            <DetailsCard>
              <>
                <section className="pb-6 is-flex is-align-items-center">
                  <Icon color="primary" mr={2}>
                    <FaUser size={24} />
                  </Icon>
                  <span className="is-size-4 has-text-weight-light">Team</span>
                </section>
                <Conditional condition={isLoadingMyDeviceGroups}>
                  <Conditional.True>
                    <PageSpinner />
                  </Conditional.True>
                  <Conditional.False>
                    <InboxTable
                      table={myTeamTable}
                      isTableEmpty={relatedUsers.length === 0}
                      config={tableConfig}
                      onSearchChange={onSearchChange}
                      disableSearch={true}
                    />
                  </Conditional.False>
                </Conditional>
              </>
            </DetailsCard>
          </Columns.Column>
        </If>
      </Columns>
    </Columns>
  );
}

export default SalesOrderDetailsLayout;
