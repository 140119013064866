import { userManagerSalesOrders } from '@zspace/roles';
import { SalesOrder } from '@zspace/types';
import { useMemo } from 'react';
import useUser from './user';

export type UseUserSalesOrders = {
  userSalesOrders: SalesOrder[];
};

export function useUserSalesOrders(): UseUserSalesOrders {
  const { user } = useUser();

  const userSalesOrders = useMemo(() => userManagerSalesOrders(user), [user]);

  return { userSalesOrders };
}

export default useUserSalesOrders;
