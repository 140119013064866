import { formatSalesOrderNumber } from '@zspace/format';
import { SalesOrder } from '@zspace/types';
import { Link } from 'react-router-dom';
import { formatDateToLocaleString, formatExpiresAt } from '../../shared/utils';
import { Column } from '../../ui/table/types';

const userDetailsSalesOrderDetailsColumns: Column<SalesOrder>[] = [
  {
    key: 'number',
    widthClassname: 'is-size-15',
    render: (el) => (
      <Link to={`/sales-orders/${el.id}`}>
        {formatSalesOrderNumber(el.number)}
      </Link>
    ),
    title: 'Sales Order',
    sortable: true,
  },
  {
    key: 'opportunity.name',
    widthClassname: 'is-size-50',
    render: (el) => el.opportunity?.name,
    title: 'Opportunity name',
    sortable: true,
  },
  {
    key: 'closeDate',
    widthClassname: 'is-size-10',
    render: (el) =>
      el.closeDate ? formatDateToLocaleString(el.closeDate) : '',
    title: 'Close date',
    sortable: true,
  },
  {
    key: 'permissionsGroupUserRoleAssignments.permissionsGroup.name',
    widthClassname: 'is-size-20',
    render: (el) =>
      el.permissionsGroupUserRoleAssignments
        .reduce((acc, item) => {
          acc.push(item.permissionsGroup.name);
          return acc;
        }, [] as string[])
        .join(', '),
    title: 'Permissions',
    sortable: false,
  },
  {
    key: 'remainingDays',
    widthClassname: 'is-size-5',
    render: (el) =>
      el.permissionsGroupUserRoleAssignments
        .reduce((acc, item) => {
          if (item.expiresAt) acc.push(formatExpiresAt(item.expiresAt));
          return acc;
        }, [] as string[])
        .join(', '),
    title: 'Remaining Days',
    sortable: true,
  },
];

export { userDetailsSalesOrderDetailsColumns };
