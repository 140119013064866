import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const A4_PAGE_WIDTH = 595.28;
const A4_PAGE_CONTENT_WIDTH = 592.28;
const A4_PAGE_HEIGHT = 841.89;

export async function generatePdfFromHTML(element: HTMLDivElement) {
  const canvas = await html2canvas(element);
  const contentWidth = canvas.width;
  const contentHeight = canvas.height;

  const pageHeight = (contentWidth / A4_PAGE_CONTENT_WIDTH) * A4_PAGE_HEIGHT;
  let remainingHeight = contentHeight;

  let position = 0;

  const imgWidth = A4_PAGE_WIDTH;
  const imgHeight = (A4_PAGE_CONTENT_WIDTH / contentWidth) * contentHeight;
  const pageData = canvas.toDataURL('image/jpeg', 1.0);
  const pdf = new jsPDF('p', 'pt', 'a4');

  if (remainingHeight < pageHeight) {
    pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
  } else {
    while (remainingHeight > 0) {
      pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
      remainingHeight -= pageHeight;
      position -= A4_PAGE_HEIGHT;
      if (remainingHeight > 0) {
        pdf.addPage();
      }
    }
  }

  return pdf;
}
