import { SoftwareTableRow, WorkOrderDetails } from '@zspace/types';
import {
  SessionStorageServiceImpl,
  WORK_ORDER_DETAILS_STORAGE_KEY,
  WORK_ORDER_GROUP_SOFTWARE_STORAGE_KEY,
} from '../shared/storage';

export class WorkOrdersDetailsStorageService {
  private storageService: SessionStorageServiceImpl<WorkOrderDetails> =
    new SessionStorageServiceImpl();
  public get() {
    return this.storageService.getItem(WORK_ORDER_DETAILS_STORAGE_KEY) || {};
  }
  public save(workOrderDetails: WorkOrderDetails) {
    return this.storageService.setItem(
      WORK_ORDER_DETAILS_STORAGE_KEY,
      workOrderDetails
    );
  }
  public remove() {
    return this.storageService.removeItem(WORK_ORDER_DETAILS_STORAGE_KEY);
  }
}

export class WorkOrdersGroupSoftwareStorageService {
  private storageService: SessionStorageServiceImpl<SoftwareTableRow[]> =
    new SessionStorageServiceImpl();

  public get(): SoftwareTableRow[] {
    return (
      this.storageService.getItem(WORK_ORDER_GROUP_SOFTWARE_STORAGE_KEY) || {}
    );
  }
  public save(selectedSoftware: SoftwareTableRow[]) {
    return this.storageService.setItem(
      WORK_ORDER_GROUP_SOFTWARE_STORAGE_KEY,
      selectedSoftware
    );
  }
  public remove(): void {
    return this.storageService.removeItem(
      WORK_ORDER_GROUP_SOFTWARE_STORAGE_KEY
    );
  }
}
