// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  apiUrl: window.env.API_URL,
  isDev: Boolean(window.env.DEV ?? false),
  supportEmail: window.env.SUPPORT_EMAIL,
  temporaryUserPermissionMaxDaysDuration:
    window.env.TEMPORARY_USER_PERMISSION_MAX_DAYS_DURATION ?? 30,
  clarityProjectId: window.env.CLARITY_PROJECT_ID ?? null,
  internalUserEmailDomains: window.env.INTERNAL_USER_EMAIL_DOMAINS ?? '',
};
