import { NewUser } from '@zspace/types';
import { ChangeEvent, FocusEvent, useCallback } from 'react';
import { Columns, Form } from 'react-bulma-components';
import Conditional from '../../../shared/conditional/conditional';
import { FormFieldData } from '../../../shared/form';
import useUserAllowedPermissionsGroupsToManage from '../../../shared/hooks/user-allowed-permissions-groups-to-manage';
import If from '../../../shared/if/if';
import FeedbackMessage from '../../../ui/feedback-message/feedback-message';

export type InviteUserFields = NewUser & {
  emailConfirmation: string;
};

export type InviteUserFormData = FormFieldData<InviteUserFields>;

type InviteUserFormChangeEvent = ChangeEvent<
  HTMLInputElement | HTMLSelectElement
>;

type InviteUserFormBlurEvent = FocusEvent<
  HTMLInputElement | HTMLSelectElement,
  Element
>;

export type InviteUserFormValidation = Record<
  keyof InviteUserFields,
  ((value: string) => string | null) | null
>;

export type InviteUsersFormProps = {
  data: InviteUserFormData;
  onChangeData: (data: InviteUserFormData) => void;
  validate: InviteUserFormValidation;
  formError: string | null;
  submitButtonPressed: boolean;
  isCustomerInvite: boolean;
};

const newUserProperties: Record<keyof NewUser, string> & {
  emailConfirmation: string;
} = {
  email: 'email',
  emailConfirmation: 'emailConfirmation',
  permissionsGroup: 'permissionsGroup',
};

const formFieldsLabels = {
  email: {
    title: 'What’s the user’s email? What will their role be?',
    subtitle:
      'If they are not currently registered on zSpace.com they will be asked to create a user',
    label: 'New user email',
  },
  emailConfirmation: {
    title: '',
    subtititle: '',
    label: 'Confirm New user email',
  },
  permissionsGroup: {
    title: '',
    subtitle: '',
    label: 'Role',
  },
};

const NONE_SELECTED = 'None selected';

export function InviteUserForm({
  data: formData,
  onChangeData: setFormData,
  validate,
  formError,
  submitButtonPressed,
  isCustomerInvite,
}: InviteUsersFormProps) {
  const userAllowedRolesToManage = useUserAllowedPermissionsGroupsToManage();

  const onInputChange = useCallback(
    (event: InviteUserFormChangeEvent) => {
      const newValue = event.target.value;
      const name = event.target.name as keyof InviteUserFields;
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          value: newValue,
          ...(submitButtonPressed && { error: null }),
          touched: true,
        },
      });
    },
    [formData, setFormData, submitButtonPressed]
  );

  const onBlur = useCallback(
    (event: InviteUserFormBlurEvent) => {
      const value = event.target.value;
      const name = event.target.name as keyof NewUser;
      const error = validate[name]?.(value);
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          error,
        },
      });
    },
    [formData, setFormData, validate]
  );

  return (
    <form>
      <If condition={!isCustomerInvite}>
        <h5 className="is-size-7">{formFieldsLabels.email.title}</h5>
        <h5 className="is-size-7 mb-2">{formFieldsLabels.email.subtitle}</h5>
      </If>

      <Columns>
        <Columns.Column size="one-fifth">
          <Form.Label
            htmlFor="email"
            className="is-size-7 has-text-weight-normal"
          >
            {formFieldsLabels.email.label}
          </Form.Label>
          <Form.Field id="email">
            <Form.Control>
              <Form.Input
                type="email"
                placeholder="mail@mail.com"
                className="is-size-7 placeholder-text-gray"
                name={newUserProperties.email}
                value={formData.email.value}
                onChange={onInputChange}
                onBlur={onBlur}
              />
            </Form.Control>
            <FeedbackMessage textClassName="is-size-7">
              {formData.email.error}
            </FeedbackMessage>
          </Form.Field>
        </Columns.Column>
        <Columns.Column size="one-fifth">
          <Conditional condition={isCustomerInvite}>
            <Conditional.True>
              <Form.Label
                htmlFor="email"
                className="is-size-7 has-text-weight-normal"
              >
                {formFieldsLabels.emailConfirmation.label}
              </Form.Label>
              <Form.Field id="email">
                <Form.Control>
                  <Form.Input
                    type="emailConfirmation"
                    placeholder="mail@mail.com"
                    className="is-size-7 placeholder-text-gray"
                    name={newUserProperties.emailConfirmation}
                    value={formData.emailConfirmation.value}
                    onChange={onInputChange}
                    onBlur={onBlur}
                  />
                </Form.Control>
                <FeedbackMessage textClassName="is-size-7">
                  {formData.emailConfirmation.error}
                </FeedbackMessage>
              </Form.Field>
            </Conditional.True>
            <Conditional.False>
              <Form.Label
                htmlFor="permissionsGroup"
                className="is-size-7 has-text-weight-normal"
              >
                {formFieldsLabels.permissionsGroup.label}
              </Form.Label>
              <Form.Field id="permissionsGroup">
                <Form.Control>
                  <Form.Select
                    fullwidth
                    name={newUserProperties.permissionsGroup}
                    className="is-size-7"
                    value={formData.permissionsGroup.value}
                    onChange={onInputChange}
                    onBlur={onBlur}
                  >
                    <option value="">{NONE_SELECTED}</option>
                    {userAllowedRolesToManage.map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Control>
                <FeedbackMessage textClassName="is-size-7">
                  {formData.permissionsGroup.error}
                </FeedbackMessage>
              </Form.Field>
            </Conditional.False>
          </Conditional>
        </Columns.Column>
      </Columns>

      <If condition={isCustomerInvite}>
        <span>
          If they are not currently registered on zSpace.com they will be asked
          to create a user before accessing this organization
        </span>
      </If>

      <FeedbackMessage textClassName="is-size-7">{formError}</FeedbackMessage>
    </form>
  );
}

export default InviteUserForm;
