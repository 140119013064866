import {
  LicenseKeysCriteria,
  LicenseKeysTableRow,
  MySoftwareCriteria,
  MySoftwareTableRow,
  PaginatedAPIResponse,
  SalesOrder,
  SoftwareData,
  SoftwareHomeAlertData,
} from '@zspace/types';
import { http } from '../shared/http';

export interface SoftwareService {
  fetchMySoftware: (
    criteria: MySoftwareCriteria
  ) => Promise<MySoftwareTableRow[]>;
  fetchMySoftwareSalesOrders: (
    criteria: MySoftwareCriteria
  ) => Promise<PaginatedAPIResponse<SalesOrder>>;
  fetchMySoftwareBySalesOrder: (
    salesOrderId: string,
    criteria: MySoftwareCriteria
  ) => Promise<PaginatedAPIResponse<MySoftwareTableRow>>;
  fetchSoftwareDetails: (id: string) => Promise<SoftwareData>;
  fetchSoftwareRelatedSeats: (
    id: string,
    criteria: LicenseKeysCriteria
  ) => Promise<PaginatedAPIResponse<LicenseKeysTableRow>>;
  fetchSoftwareSeatsHomeAlertData: () => Promise<SoftwareHomeAlertData>;
}

export class SoftwareServiceImpl implements SoftwareService {
  public async fetchMySoftware(
    criteria: MySoftwareCriteria
  ): Promise<MySoftwareTableRow[]> {
    const { data } = await http(
      '/v1/software?' +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchMySoftwareSalesOrders(
    criteria: MySoftwareCriteria
  ): Promise<PaginatedAPIResponse<SalesOrder>> {
    const { data } = await http(
      '/v1/software/sales-orders?' +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchMySoftwareBySalesOrder(
    salesOrderId: string,
    criteria: MySoftwareCriteria
  ): Promise<PaginatedAPIResponse<MySoftwareTableRow>> {
    const { data } = await http(
      `/v1/software/sales-orders/${salesOrderId}?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchSoftwareDetails(id: string): Promise<SoftwareData> {
    const { data } = await http(`/v1/software/${id}`);

    return data;
  }

  public async fetchSoftwareRelatedSeats(
    id: string,
    criteria: LicenseKeysCriteria
  ): Promise<PaginatedAPIResponse<LicenseKeysTableRow>> {
    const { data } = await http(
      `/v1/software/${id}/seats?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchSoftwareSeatsHomeAlertData(): Promise<SoftwareHomeAlertData> {
    const { data } = await http('/v1/software/home-alerts');
    return data;
  }
}

export const fetchMySoftware = async (
  criteria: MySoftwareCriteria
): Promise<MySoftwareTableRow[]> => {
  const service = new SoftwareServiceImpl();

  return service.fetchMySoftware(criteria);
};

export const fetchMySoftwareSalesOrders = async (
  criteria: MySoftwareCriteria
): Promise<PaginatedAPIResponse<SalesOrder>> => {
  const service = new SoftwareServiceImpl();

  return service.fetchMySoftwareSalesOrders(criteria);
};

export const fetchMySoftwareBySalesOrder = async (
  salesOrderId: string,
  criteria: MySoftwareCriteria
): Promise<PaginatedAPIResponse<MySoftwareTableRow>> => {
  const service = new SoftwareServiceImpl();

  return service.fetchMySoftwareBySalesOrder(salesOrderId, criteria);
};

export const fetchSoftwareDetails = async (
  id: string
): Promise<SoftwareData> => {
  const service = new SoftwareServiceImpl();

  return service.fetchSoftwareDetails(id);
};

export const fetchSoftwareRelatedSeats = async (
  id: string,
  criteria: LicenseKeysCriteria
): Promise<PaginatedAPIResponse<LicenseKeysTableRow>> => {
  const service = new SoftwareServiceImpl();

  return service.fetchSoftwareRelatedSeats(id, criteria);
};

export const fetchSoftwareSeatsHomeAlertData =
  async (): Promise<SoftwareHomeAlertData> => {
    const service = new SoftwareServiceImpl();

    return service.fetchSoftwareSeatsHomeAlertData();
  };
