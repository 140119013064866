import { WorkOrderPermissions } from '@zspace/roles';
import { WorkOrderDashboardPageTabs } from '@zspace/types';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Columns, Element, Icon, Tabs } from 'react-bulma-components';
import { FaPlus } from 'react-icons/fa6';
import { Outlet, useNavigate } from 'react-router-dom';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import usePermissions from '../../shared/hooks/permissions';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import { SalesOrdersStorageService } from '../sales-orders-storage-service';
import { WorkOrdersDetailsStorageService } from '../work-orders-storage-service';
import { WorkOrderDashboardPageContext } from './types';

const PageTabsLabels = {
  [WorkOrderDashboardPageTabs.DRAFTS]: 'Drafts',
  [WorkOrderDashboardPageTabs.QUEUE]: 'Genesis queue',
  [WorkOrderDashboardPageTabs.COMPLETED]: 'Completed',
};

const workOrderDetailsSessionStorageService =
  new WorkOrdersDetailsStorageService();
const salesOrdersSessionStorageService = new SalesOrdersStorageService();

export function WorkOrdersDashboardPage() {
  const navigate = useNavigate();
  const userHasPermissions = usePermissions();
  const [activeTab, setActiveTab] = useState<WorkOrderDashboardPageTabs>(
    userHasPermissions({
      permissions: WorkOrderPermissions.WORK_ORDERS_DRAFTS_READ,
    })
      ? WorkOrderDashboardPageTabs.DRAFTS
      : WorkOrderDashboardPageTabs.QUEUE
  );
  const [filterTagList, setFilterTagList] = useState<ReactNode>(null);
  const [showCreateWorkOrderButton, setShowCreateWorkOrderButton] =
    useState(false);

  const workOrderDashboardTabs = useMemo(() => {
    const tabs: WorkOrderDashboardPageTabs[] = [];
    if (
      userHasPermissions({
        permissions: WorkOrderPermissions.WORK_ORDERS_DRAFTS_READ,
      })
    ) {
      tabs.push(WorkOrderDashboardPageTabs.DRAFTS);
    }
    if (
      userHasPermissions({
        permissions: WorkOrderPermissions.WORK_ORDERS_QUEUE_READ,
      })
    ) {
      tabs.push(WorkOrderDashboardPageTabs.QUEUE);
    }
    if (
      userHasPermissions({
        permissions: WorkOrderPermissions.WORK_ORDERS_COMPLETED_READ,
      })
    ) {
      tabs.push(WorkOrderDashboardPageTabs.COMPLETED);
    }
    return tabs;
  }, [userHasPermissions]);

  const pagePermissions = useMemo(() => {
    const permissionsToCheck: WorkOrderPermissions[] = [];
    if (activeTab === WorkOrderDashboardPageTabs.DRAFTS) {
      permissionsToCheck.push(WorkOrderPermissions.WORK_ORDERS_DRAFTS_READ);
    } else if (activeTab === WorkOrderDashboardPageTabs.QUEUE) {
      permissionsToCheck.push(WorkOrderPermissions.WORK_ORDERS_QUEUE_READ);
    } else if (activeTab === WorkOrderDashboardPageTabs.COMPLETED) {
      permissionsToCheck.push(WorkOrderPermissions.WORK_ORDERS_COMPLETED_READ);
    }
    return permissionsToCheck;
  }, [activeTab]);

  const isActiveTab = useCallback(
    (tab: WorkOrderDashboardPageTabs) => tab === activeTab,
    [activeTab]
  );

  const navigateToTab = useCallback(
    (tab: string) => {
      navigate(`/work-orders/dashboard/${tab}`);
    },
    [navigate]
  );

  const handleCreateWorkOrderButtonClick = useCallback(() => {
    workOrderDetailsSessionStorageService.remove();
    salesOrdersSessionStorageService.remove();
    navigate(`/work-orders/create/sales-orders`);
  }, [navigate]);

  return (
    <ProtectedPage permissions={pagePermissions}>
      <BoxLayout
        className={'mx-10 my-4'}
        header={
          <>
            <Columns marginless>
              <Columns.Column className="pl-4">
                <h1 className="is-size-3 has-text-weight-light">Work orders</h1>
              </Columns.Column>
              <CheckPermissions
                permissions={WorkOrderPermissions.WORK_ORDERS_CREATE}
              >
                <CheckPermissions.Render>
                  <If condition={showCreateWorkOrderButton}>
                    <Columns.Column display="flex" justifyContent="flex-end">
                      <Button
                        color="primary-dark"
                        onClick={handleCreateWorkOrderButtonClick}
                      >
                        <Icon>
                          <FaPlus />
                        </Icon>
                        <span>Create work order</span>
                      </Button>
                    </Columns.Column>
                  </If>
                </CheckPermissions.Render>
              </CheckPermissions>
            </Columns>

            {filterTagList && <Element mt={3}>{filterTagList}</Element>}
          </>
        }
      >
        <Tabs>
          {workOrderDashboardTabs.map((tab) => (
            <Tabs.Tab
              key={tab}
              active={isActiveTab(tab)}
              onClick={() => navigateToTab(tab.toLowerCase())}
              className={
                isActiveTab(tab)
                  ? 'has-text-weight-bold'
                  : 'has-text-weight-normal'
              }
            >
              {PageTabsLabels[tab]}
            </Tabs.Tab>
          ))}
        </Tabs>

        <Outlet
          context={
            {
              setActiveTab,
              setFilterTagList,
              setShowCreateWorkOrderButton,
              handleCreateWorkOrderButtonClick,
            } as WorkOrderDashboardPageContext
          }
        />
      </BoxLayout>
    </ProtectedPage>
  );
}

export default WorkOrdersDashboardPage;
