import { SVGProps } from 'react';
const Zspace = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#2ED6EF"
      d="M10.469 17.213H0v-2.406l6.72-8.283H.274V4.118h9.875v2.453l-6.812 8.236h7.132v2.406Z"
    />
    <path
      fill="#267ABF"
      d="M18.057 17.63c-1.874 0-3.657-.51-4.891-1.435l-.366-.324v-3.609l1.6 1.296c1.097.88 2.24 1.296 3.657 1.296 1.143 0 3.018-.278 3.018-2.222 0-1.295-.686-1.85-3.246-2.776-2.24-.787-5.029-1.805-5.029-5.182C12.8 1.897 15.086 0 18.332 0c1.691 0 3.383.509 4.434 1.388l.366.324V5.23l-1.6-1.296c-1.006-.786-1.966-1.157-3.2-1.157-.457 0-2.606.093-2.606 1.898 0 .879.229 1.527 3.017 2.498C21.486 8.098 24 9.208 24 12.632c0 2.962-2.377 4.998-5.943 4.998Z"
    />
  </svg>
);
export default Zspace;
