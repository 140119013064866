import { faker } from '@faker-js/faker';
import { array, define } from 'cooky-cutter';
import {
  Device,
  DeviceDetailsTableRow,
  DeviceForSoftwareAssignmentGroup,
  DeviceRegistrationStatus,
  DeviceStatus,
  GroupCode,
  RegistrationFileDevicesTableRow,
} from '../types';
import { deviceModelFactory } from './device-models';
import { salesOrderLineFactory } from './sales-order-lines';
import { serialNumberFactory } from './serial-numbers';
import { deviceGroupFactory } from './work-orders';

export const deviceFactory = define<Device>({
  id: () => faker.string.uuid(),
  status: () => DeviceStatus.ACTIVE,
  model: deviceModelFactory,
  serialNumber: serialNumberFactory,
  salesOrderLine: salesOrderLineFactory,
  assignments: () => [],
  deviceRegistrationRequest: () => [],
  deviceRegistration: () => [],
  defaultName: () => '',
});

export const groupCodeFactory = define<GroupCode>({
  code: () => faker.string.alphanumeric({ length: 8 }),
  deviceGroup: () => deviceGroupFactory(),
});

export const deviceForSoftwareAssignmentGroupFactory =
  define<DeviceForSoftwareAssignmentGroup>({
    id: () => faker.string.uuid(),
    name: () => faker.lorem.word({ strategy: 'any-length' }),
    devices: array(deviceFactory, 3),
    groupCode: () => groupCodeFactory(),
    creator: () => faker.string.uuid(),
  });

export const registrationFileDevicesTableRowFactory =
  define<RegistrationFileDevicesTableRow>({
    id: () => faker.string.uuid(),
    serialNumber: () => faker.string.uuid(),
    name: () => faker.lorem.word({ strategy: 'any-length' }),
    type: () => faker.lorem.word(3),
    status: () => faker.helpers.enumValue(DeviceRegistrationStatus),
    registrations: () => [],
  });

export const deviceDetailsTableRowFactory = define<DeviceDetailsTableRow>({
  serialNumber: () => faker.string.alphanumeric({ length: 8 }),
  deviceType: () => faker.lorem.word({ strategy: 'any-length' }),
  inventoryLocation: () => 'Genesis',
});
