import { FeaturePermissions } from '@zspace/roles';
import { useCallback, useMemo } from 'react';
import { Box, Columns, Element, Icon } from 'react-bulma-components';
import { FaArrowLeft, FaLock } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import Button from '../../ui/button/button';
import useError from '../hooks/error';
import usePermissions from '../hooks/permissions';
import styles from './permissions-error-card.module.scss';

const RETURN_TO_HOME_TITLE = 'Return to Home';
const GO_BACK_TITLE = 'Go to zspace.com';

export function PermissionsErrorCard() {
  const navigate = useNavigate();
  const userHasPermissions = usePermissions();
  const { clearError } = useError();

  const shouldNavigateToHome = useMemo(() => {
    const hasZspaceInternalRole = userHasPermissions({
      permissions: FeaturePermissions.FULL_CONFIGURATION_ACCESS,
    });
    const hasCustomerRole = userHasPermissions({
      permissions: FeaturePermissions.RENEWALS_ACCESS,
    });
    return hasZspaceInternalRole || hasCustomerRole;
  }, [userHasPermissions]);

  const buttonTitle = useMemo(
    () => (shouldNavigateToHome ? RETURN_TO_HOME_TITLE : GO_BACK_TITLE),
    [shouldNavigateToHome]
  );

  const handleBackButtonClick = useCallback(() => {
    clearError();

    if (shouldNavigateToHome) {
      navigate('/');
    } else {
      window.location.href = 'https://www.zspace.com';
    }
  }, [clearError, navigate, shouldNavigateToHome]);

  return (
    <Box paddingless className="my-4 mx-10 box-container is-min-height-80">
      <Element p={4} className={styles.container}>
        <div className="is-flex is-flex-direction-column is-align-items-center">
          <Icon mb={8}>
            <FaLock size={32} />
          </Icon>
          <h1 className="is-size-4 has-text-weight-light mb-2">
            You don’t have permission to access this page
          </h1>
          <h2 className="is-size-6 has-text-weight-light">
            If you think you should be able to, you can request permissions from
            your admin
          </h2>
          <Columns className="mt-4">
            <Columns.Column>
              <Button
                color="primary-dark"
                outlined
                onClick={handleBackButtonClick}
              >
                <Icon>
                  <FaArrowLeft />
                </Icon>
                <span>{buttonTitle}</span>
              </Button>
            </Columns.Column>
          </Columns>
        </div>
      </Element>
    </Box>
  );
}

export default PermissionsErrorCard;
