import {
  getUserAllowedPermissionsGroupsToManage,
  PermissionsGroupName,
  SYSTEM_DEFINED_PERMISSIONS_GROUPS,
} from '@zspace/roles';
import { User } from '@zspace/types';
import { useCallback } from 'react';
import useUser from './user';

export function useIsUserEditable() {
  const { user: currentUser } = useUser();

  const isOwnUser = useCallback(
    (user: User) => user.id === currentUser.id,
    [currentUser.id]
  );
  const isEditableByCustomerUser = useCallback(
    (user: User): boolean => {
      const isSelf = isOwnUser(user);
      const { deletedAt: isDeleted } = user;

      const hasNoRoles = !user.roles?.length;
      if (isSelf || isDeleted || hasNoRoles) return false;

      const hasSystemDefinedRole = user.roles.some((userRoleAssignment) =>
        userRoleAssignment.permissionsGroupUserRoleAssignments.some(
          (permissionsGroupUserRoleAssignment) =>
            getUserAllowedPermissionsGroupsToManage(currentUser).includes(
              permissionsGroupUserRoleAssignment.permissionsGroup
                .name as PermissionsGroupName
            )
        )
      );

      return hasSystemDefinedRole;
    },
    [currentUser, isOwnUser]
  );
  const isEditableByZspaceInternalUser = useCallback(
    (user: User): boolean => {
      const isSelf = isOwnUser(user);
      const { deletedAt: isDeleted } = user;

      const hasNoRoles = !user.roles?.length;
      if (isSelf || isDeleted || hasNoRoles) return false;

      const hasSystemDefinedRole = user.roles.some((userRoleAssignment) =>
        userRoleAssignment.permissionsGroupUserRoleAssignments.some(
          (permissionsGroupUserRoleAssignment) =>
            SYSTEM_DEFINED_PERMISSIONS_GROUPS.includes(
              permissionsGroupUserRoleAssignment.permissionsGroup
                .name as PermissionsGroupName
            )
        )
      );

      return hasSystemDefinedRole;
    },
    [isOwnUser]
  );

  return {
    isOwnUser,
    isEditableByCustomerUser,
    isEditableByZspaceInternalUser,
  };
}

export default useIsUserEditable;
