import { DeviceSwapError } from '@zspace/types';
import { AxiosError, HttpStatusCode } from 'axios';
import {
  ChangeEvent,
  FocusEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Columns, Element, Form, Section } from 'react-bulma-components';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';
import BackButton from '../../../shared/back-button/back-button';
import { FormFieldData } from '../../../shared/form';
import useHttpRequest from '../../../shared/hooks/http-request';
import BoxLayout from '../../../ui/box-layout/box-layout';
import Button from '../../../ui/button/button';
import DefaultErrorMessage from '../../../ui/default-error-message/default-error-message';
import FeedbackMessage from '../../../ui/feedback-message/feedback-message';
import { replacementDeviceLookup } from '../../partners-service';
import DeviceDetailsTable from '../device-details-table/device-details-table';
import {
  columnSizes,
  DeviceReplacementContext,
} from '../device-replacement-layout/device-replacement-layout';

type ReplacementDeviceLookup = {
  serialNumber: string;
  serialNumberConfirmation: string;
};

type ReplacementDeviceLookupFormData = FormFieldData<ReplacementDeviceLookup>;

const validateForm = {
  serialNumber: (value: string) =>
    !value.trim() ? 'Please provide a serial number' : null,
  serialNumberConfirmation: (value: string) =>
    !value.trim() ? 'Please confirm the serial number' : null,
};

const SERIAL_NUMBER_MISMATCH_ERROR = 'The serial numbers do not match';

export function ReplacementDeviceLookupPage() {
  const { faultyDevice, deviceFailureReason, setReplacementDevice } =
    useOutletContext<DeviceReplacementContext>();
  const { executeHttpRequest, isLoading: isLoadingDeviceLookup } =
    useHttpRequest();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ReplacementDeviceLookupFormData>({
    serialNumber: { value: '', touched: false, error: null },
    serialNumberConfirmation: { value: '', touched: false, error: null },
  });
  const [replacementDeviceLookupError, setReplacementDeviceLookupError] =
    useState<string | ReactNode>();

  const tableDataSource = useMemo(() => [faultyDevice], [faultyDevice]);

  const isSerialNumberConfirmationMismatch = useMemo(
    () =>
      formData.serialNumber.value !== formData.serialNumberConfirmation.value &&
      formData.serialNumberConfirmation.touched &&
      formData.serialNumber.touched,
    [
      formData.serialNumber.touched,
      formData.serialNumber.value,
      formData.serialNumberConfirmation.touched,
      formData.serialNumberConfirmation.value,
    ]
  );

  const errorMessage = useMemo(() => {
    const emptyErrorMessage = '';
    return (
      replacementDeviceLookupError ||
      (isSerialNumberConfirmationMismatch && SERIAL_NUMBER_MISMATCH_ERROR) ||
      formData.serialNumber.error ||
      formData.serialNumberConfirmation.error ||
      emptyErrorMessage
    );
  }, [
    formData.serialNumber.error,
    formData.serialNumberConfirmation.error,
    isSerialNumberConfirmationMismatch,
    replacementDeviceLookupError,
  ]);

  const onBackClick = useCallback(
    () => navigate('/device-replacement/reason-selection'),
    [navigate]
  );

  const cleanReplacementDeviceLookupError = useCallback(() => {
    setReplacementDeviceLookupError(undefined);
  }, []);

  const onReplacementDeviceLookup = useCallback(() => {
    cleanReplacementDeviceLookupError();
    const formValidation = Object.keys(formData).reduce(
      (acc, key) => {
        const formDataKey = key as keyof ReplacementDeviceLookup;
        const error = validateForm[formDataKey]?.(formData[formDataKey].value);
        setFormData((prev) => ({
          ...prev,
          [formDataKey]: { ...prev[formDataKey], error, touched: true },
        }));
        return {
          values: { ...acc.values, [key]: formData[formDataKey].value },
          errors: { ...acc.errors, [key]: error },
        };
      },
      { values: {}, errors: {} }
    );

    const isValidForm =
      Object.values(formValidation.errors).every((fieldError) => !fieldError) &&
      !isSerialNumberConfirmationMismatch;

    if (isValidForm && faultyDevice && deviceFailureReason) {
      const replacementDeviceSerialNumber = formData.serialNumber.value;
      executeHttpRequest({
        asyncFunction: async () => {
          await replacementDeviceLookup({
            faultyDeviceSerialNumber: faultyDevice.serialNumber,
            replacementDeviceSerialNumber,
            deviceFailureReason,
          });
          setReplacementDevice({
            serialNumber: replacementDeviceSerialNumber,
            deviceType: faultyDevice.deviceType,
            inventoryLocation: faultyDevice.inventoryLocation,
          });
          navigate('/device-replacement/confirm');
        },
        customErrorHandler: (error: AxiosError<DeviceSwapError>) => {
          switch (error.response?.status) {
            case HttpStatusCode.BadRequest:
            case HttpStatusCode.Conflict:
              setReplacementDeviceLookupError(error.response?.data.message);
              break;
            default:
              setReplacementDeviceLookupError(DefaultErrorMessage);
              break;
          }
        },
      });
    }
  }, [
    cleanReplacementDeviceLookupError,
    deviceFailureReason,
    executeHttpRequest,
    faultyDevice,
    formData,
    isSerialNumberConfirmationMismatch,
    navigate,
    setReplacementDevice,
  ]);

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const name = event.target.name as keyof ReplacementDeviceLookup;
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          value: newValue,
          touched: true,
        },
      });
    },
    [formData]
  );

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const name = event.target.name as keyof ReplacementDeviceLookup;
      const error = validateForm[name]?.(value);
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          error,
        },
      });
    },
    [formData, setFormData]
  );

  if (!faultyDevice || !deviceFailureReason) {
    return <Navigate to="/device-replacement" replace />;
  }

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Section p={4}>
          <BackButton onClick={onBackClick} />
          <h1 className="is-size-3 has-text-weight-light">
            Replace faulty device
          </h1>
          <h3 className="is-size-5 has-text-weight-light">
            New device validation with Netsuite
          </h3>
        </Section>
      }
    >
      <Section
        paddingless
        display="flex"
        className="gap-8"
        flexDirection="column"
      >
        <span className="is-size-6">
          You are replacing the following faulty device:
        </span>
        <DeviceDetailsTable dataSource={tableDataSource} />
        <Element>
          <span>
            Please scan or manually input the serial number of the new device.
          </span>
          <br />
          <span>
            If you are using a scanner, make sure you click on the input before
            scanning
          </span>
        </Element>
        <Element>
          <Columns>
            <Columns.Column {...columnSizes}>
              <Form.Field>
                <Form.Label
                  className="has-text-weight-normal"
                  htmlFor="serialNumber"
                >
                  Serial number
                </Form.Label>
                <Form.Control>
                  <Form.Input
                    id="serialNumber"
                    name="serialNumber"
                    value={formData.serialNumber.value}
                    onChange={onInputChange}
                    onBlur={onBlur}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns display="flex" alignItems="flex-end">
            <Columns.Column {...columnSizes}>
              <Form.Field>
                <Form.Label
                  className="is-vertical has-text-weight-normal"
                  htmlFor="serialNumberConfirmation"
                >
                  Confirm serial number
                </Form.Label>
                <Form.Control>
                  <Form.Input
                    id="serialNumberConfirmation"
                    name="serialNumberConfirmation"
                    value={formData.serialNumberConfirmation.value}
                    onChange={onInputChange}
                    onBlur={onBlur}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Control>
                <Button
                  color="primary-dark"
                  onClick={onReplacementDeviceLookup}
                  isExecutingAction={isLoadingDeviceLookup}
                >
                  <Button.LoadingIcon icon={FaMagnifyingGlass} />
                  <span>Device lookup</span>
                </Button>
              </Form.Control>
            </Columns.Column>
          </Columns>
        </Element>
        <FeedbackMessage>{errorMessage}</FeedbackMessage>
      </Section>
    </BoxLayout>
  );
}

export default ReplacementDeviceLookupPage;
