import { faker } from '@faker-js/faker';
import { array, define } from 'cooky-cutter';
import { DeviceModel } from '../types';
import { itemFactory } from './items';

export const deviceModelFactory = define<DeviceModel>({
  id: faker.string.uuid(),
  nameId: () => faker.lorem.word({ strategy: 'any-length' }),
  displayName: () => faker.lorem.word({ strategy: 'any-length' }),
  items: array(itemFactory, faker.number.int({ min: 1, max: 5 })),
});
