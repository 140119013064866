import {
  FilterTableModalFieldAlignment,
  InputType,
  LicenseKeysModalFilterDataType,
} from '@zspace/types';
import FilterTableModal from '../../../shared/filter-table-modal/filter-table-modal';

export type LicenseKeysFilterModalProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: (value: LicenseKeysModalFilterDataType) => void;
  data: LicenseKeysModalFilterDataType;
};

export function LicenseKeysFilterModal({
  show,
  data,
  onClose,
  onSubmit,
}: LicenseKeysFilterModalProps) {
  return (
    <FilterTableModal
      show={show}
      onClose={onClose}
      data={data}
      onSubmit={onSubmit}
      fields={[
        {
          input: {
            type: InputType.FILTER_CRITERIA,
            valueKey: 'licenseKey',
            filterValueKey: 'licenseKeyFilter',
          },
          name: 'licenseKey',
          label: 'License key',
          alignment: FilterTableModalFieldAlignment.LEFT,
        },
        {
          input: {
            type: InputType.FILTER_CRITERIA,
            valueKey: 'assignedDevice',
            filterValueKey: 'assignedDeviceFilter',
          },
          name: 'assignedDevice',
          label: 'Assigned Device',
          alignment: FilterTableModalFieldAlignment.LEFT,
        },
        {
          input: {
            type: InputType.TAG_INPUT,
            valueKey: 'salesOrders',
            placeholder: '',
            errorText: 'Please enter a valid sales order number',
          },
          name: 'salesOrders',
          label: 'Sales Order #',
          alignment: FilterTableModalFieldAlignment.LEFT,
        },
        {
          input: {
            type: InputType.DATE_RANGE,
            fromDateValueKey: 'expirationDateFrom',
            toDateValueKey: 'expirationDateTo',
          },
          name: 'rexpirationDate',
          label: 'Expiration date range',
          alignment: FilterTableModalFieldAlignment.RIGHT,
        },
        {
          input: {
            type: InputType.TAG_INPUT,
            valueKey: 'endUsers',
            placeholder: '',
            errorText: 'Please enter a valid email',
          },
          name: 'endUsers',
          label: 'End User',
          alignment: FilterTableModalFieldAlignment.RIGHT,
        },
      ]}
    />
  );
}

export default LicenseKeysFilterModal;
