import { HttpStatusCode } from 'axios';
import { ReactNode, createContext, useCallback, useState } from 'react';

export type ApiError =
  | {
      status: HttpStatusCode;
      message?: string;
      data?: object;
    }
  | string;

export type ErrorsContextProps = {
  error?: ApiError;
  setError: (error: ApiError) => void;
  clearError: () => void;
};

export const ErrorsContext = createContext<ErrorsContextProps>({
  error: undefined,
  setError: () => {},
  clearError: () => {},
});

export type ErrorsProviderProps = {
  children: ReactNode;
};

export const ErrorsProvider = ({ children }: ErrorsProviderProps) => {
  const [error, setError] = useState<ApiError>();

  const clearError = useCallback(() => {
    setError(undefined);
  }, []);

  return (
    <ErrorsContext.Provider value={{ error, setError, clearError }}>
      {children}
    </ErrorsContext.Provider>
  );
};
