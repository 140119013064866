import { SelectInputType } from '@zspace/types';
import { Columns, Element, Form } from 'react-bulma-components';
import FeedbackMessage from '../../../../ui/feedback-message/feedback-message';
import { FilterModalInput } from '../../filter-table-modal-input/filter-table-modal-input';
import styles from './select-input.module.scss';

export type SelectInputProps<T> = FilterModalInput<T> & {
  input: SelectInputType<T>;
};

export function SelectInput<T>({
  input: { valueKey, options },
  localData,
  setLocalData,
  name,
  errorText,
}: SelectInputProps<T>) {
  return (
    <Element>
      <Columns marginless display="flex">
        <Columns.Column paddingless>
          <Form.Field id="type" alignItems="center">
            <Form.Control>
              <Form.Select
                id={name}
                fullwidth
                value={localData[valueKey]}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    [valueKey]: e.target.value,
                  })
                }
                className={styles.formSelect}
              >
                {options.map((option, index) => (
                  <option key={`${option.label}-${index}`} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>
      <FeedbackMessage>{errorText}</FeedbackMessage>
    </Element>
  );
}

export default SelectInput;
