import { capitalizeString } from '@zspace/format';
import {
  RegistrationFilesTableRow,
  RegistrationFileStatus,
} from '@zspace/types';
import { useState } from 'react';
import { Icon } from 'react-bulma-components';
import { FaDownload, FaShare } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import If from '../../shared/if/if';
import { formatDateToLocaleString } from '../../shared/utils';
import Button from '../../ui/button/button';
import PaginatedTable, {
  CustomPaginatedTableProps,
} from '../../ui/table/paginated-table/paginated-table';
import { Column, RowInteraction } from '../../ui/table/types';
import styles from './registration-files-table.module.scss';

export type RegistrationFilesTableProps =
  CustomPaginatedTableProps<RegistrationFilesTableRow> & {
    handleDownloadRegistrationFileClick: (fileName: string) => void;
    isDownloading: boolean;
    handleSendRegistrationFileClick: (fileId: string) => void;
  };

export function RegistrationFilesTable({
  dataSource,
  config,
  form,
  onChange,
  empty,
  handleDownloadRegistrationFileClick,
  isDownloading,
  handleSendRegistrationFileClick,
}: RegistrationFilesTableProps) {
  const [activeRowId, setActiveRowId] = useState<string>('');

  const columns: Column<RegistrationFilesTableRow>[] = [
    {
      key: 'name',
      widthClassname: 'is-size-30',
      render: (el) => el.name,
      title: 'Registration file',
      sortable: true,
    },
    {
      key: 'createdAt',
      widthClassname: 'is-size-10',
      render: (el) => formatDateToLocaleString(el.createdAt),
      title: 'Creation date',
      sortable: true,
    },
    {
      key: 'expiresAt',
      widthClassname: 'is-size-10',
      render: (el) => formatDateToLocaleString(el.expiresAt),
      title: 'Expiration date',
      sortable: true,
    },
    {
      key: 'status',
      widthClassname: 'is-size-10',
      render: (el) => capitalizeString(el.status),
      title: 'Status',
      sortable: false,
    },
    {
      key: 'linkedDevices',
      widthClassname: 'is-size-10',
      render: (el) => (
        <>
          <span>{el.linkedDevices}</span>{' '}
          <Link
            className={`is-underlined has-text-primary pl-2 is-size-7 ${
              el.id === activeRowId ? '' : `${styles.invisible}`
            }`}
            to={`${el.id}`}
          >
            Details
          </Link>
        </>
      ),
      title: 'Linked devices',
      sortable: false,
    },
    {
      key: 'registeredDevices',
      widthClassname: 'is-size-15',
      render: (el) => el.registeredDevices,
      title: 'Devices registered with this file',
      sortable: false,
    },
    {
      key: 'actions',
      widthClassname: 'is-size-15',
      render: (el: RegistrationFilesTableRow) => (
        <If condition={el.status === RegistrationFileStatus.ACTIVE}>
          <Button
            ml={1}
            text
            className={`has-text-primary-dark ${styles.buttonText} ${
              el.id === activeRowId ? '' : `${styles.invisible}`
            }`}
            onClick={() => handleDownloadRegistrationFileClick(el.id)}
            isExecutingAction={isDownloading}
          >
            <Button.LoadingIcon icon={FaDownload} />
            <span className="is-underlined">Download</span>
          </Button>

          <Button
            ml={1}
            text
            className={`has-text-primary-dark ${styles.buttonText} ${
              el.id === activeRowId ? '' : `${styles.invisible}`
            }`}
            onClick={() => handleSendRegistrationFileClick(el.id)}
          >
            <Icon>
              <FaShare />
            </Icon>
            <span className="is-underlined">Send</span>
          </Button>
        </If>
      ),
      title: '',
      sortable: false,
    },
  ];

  const rowInteraction: RowInteraction = {
    onRowEnter: function (rowKey: string): void {
      setActiveRowId(rowKey);
    },
    onRowLeave: function (rowKey: string): void {
      setActiveRowId('');
    },
  };

  return (
    <PaginatedTable
      dataSource={dataSource}
      columns={columns}
      config={config}
      rowKey={'id'}
      empty={empty}
      form={form}
      rowInteraction={rowInteraction}
      onChange={onChange}
    />
  );
}

export default RegistrationFilesTable;
