import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaCircleCheck, FaCircleXmark, FaSpinner } from 'react-icons/fa6';
import { useSearchParams } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import Conditional from '../../shared/conditional/conditional';
import useHttpRequest from '../../shared/hooks/http-request';
import ExternalLink from '../../ui/external-link/external-link';
import { registerDevice } from '../devices-service';
import styles from './device-registration-page.module.scss';

const REGISTRATION_IN_PROGRESS_TITLE = 'Registering your device';
const REGISTRATION_IN_PROGRESS_SUBTITLE = 'Please don’t close this tab';
const REGISTRATION_SUCCESSFUL_SUBTITLE = 'You can now safely close this tab';
const REGISTRATION_FAILED_TITLE =
  'An error ocurred while registering your device';

export function DeviceRegistrationPage() {
  const { executeHttpRequest, isLoading } = useHttpRequest();
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState<string>(REGISTRATION_IN_PROGRESS_TITLE);
  const [registrationCompleted, setRegistrationCompleted] =
    useState<boolean>(false);

  const StatusIcon = useCallback(() => {
    const iconColor = registrationCompleted ? 'success-dark' : 'danger-dark';

    return (
      <Conditional condition={isLoading}>
        <Conditional.True>
          <Icon
            className={`${styles.statusIcon} animate-spin`}
            color="primary-dark"
          >
            <FaSpinner />
          </Icon>
        </Conditional.True>
        <Conditional.False>
          <Icon color={iconColor} className={`${styles.statusIcon} is-size-3`}>
            <Conditional condition={registrationCompleted}>
              <Conditional.True>
                <FaCircleCheck />
              </Conditional.True>
              <Conditional.False>
                <FaCircleXmark />
              </Conditional.False>
            </Conditional>
          </Icon>
        </Conditional.False>
      </Conditional>
    );
  }, [isLoading, registrationCompleted]);

  const registerDeviceWithCode = useCallback(
    async (serialNumber: string, code: string) =>
      executeHttpRequest({
        asyncFunction: async () => {
          await registerDevice(serialNumber, code);
          setTitle(
            `Your device ${serialNumber} has been successfully registered`
          );
          setRegistrationCompleted(true);
        },
        customErrorHandler: (error: AxiosError) => {
          setTitle(REGISTRATION_FAILED_TITLE);
          setRegistrationCompleted(false);
        },
      }),
    [executeHttpRequest]
  );

  useEffect(() => {
    const serialNumber = searchParams.get('serialNumber');
    const code = searchParams.get('code');
    if (serialNumber && code) {
      registerDeviceWithCode(serialNumber, code);
    } else {
      setTitle(REGISTRATION_FAILED_TITLE);
    }
  }, [registerDeviceWithCode, searchParams]);

  return (
    <Element
      className={styles.container}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <StatusIcon />
      <h1 className="is-size-4 mb-8 has-text-weight-light">{title}</h1>
      <h2 className="is-size-8">
        <Conditional condition={isLoading}>
          <Conditional.True>
            {REGISTRATION_IN_PROGRESS_SUBTITLE}
          </Conditional.True>
          <Conditional.False>
            <Conditional condition={registrationCompleted}>
              <Conditional.True>
                {REGISTRATION_SUCCESSFUL_SUBTITLE}
              </Conditional.True>
              <Conditional.False>
                <span>Retry or </span>
                <ExternalLink to={`mailto:${environment.supportEmail}`}>
                  contact us
                </ExternalLink>
              </Conditional.False>
            </Conditional>
          </Conditional.False>
        </Conditional>
      </h2>
    </Element>
  );
}

export default DeviceRegistrationPage;
