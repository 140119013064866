import { UnauthorizedResponse } from '@zspace/types';
import { AxiosError, HttpStatusCode } from 'axios';
import { ReactNode } from 'react';
import { ApiError } from '../context/errors-context';
import ErrorMessageCard from '../error-message-card/error-message-card';
import useError from '../hooks/error';
import PermissionsErrorCard from '../permissions-error-card/permissions-error-card';
import UserErrorElement from '../user-error-element/user-error-element';

export function ApiErrorElement() {
  const { error } = useError();

  const Component = resolveComponentToRender(error);

  return Component;
}

export default ApiErrorElement;

function resolveComponentToRender(error?: ApiError | string): ReactNode {
  if (typeof error === 'string') {
    return <ErrorMessageCard />;
  }

  switch (error?.status) {
    case HttpStatusCode.Forbidden:
      return <PermissionsErrorCard />;
    case HttpStatusCode.Unauthorized:
      return (
        <UserErrorElement
          error={
            {
              response: { status: HttpStatusCode.Unauthorized },
            } as AxiosError
          }
          defaultAuthUrl={(error?.data as UnauthorizedResponse).authUrl}
        />
      );
    default:
      return <ErrorMessageCard />;
  }
}
