import { formatSalesOrderNumber } from '@zspace/format';
import { SalesOrder } from '@zspace/types';
import { Link } from 'react-router-dom';
import { formatDateToLocaleString } from '../../shared/utils';
import { Column } from '../../ui/table/types';

const salesOrderSummaryColumns: Column<SalesOrder>[] = [
  {
    key: 'number',
    widthClassname: 'is-size-10',
    render: (el) => (
      <Link to={`/sales-orders/${el.id}`}>
        {formatSalesOrderNumber(el.number)}
      </Link>
    ),
    title: 'Sales Order',
    sortable: true,
  },
  {
    key: 'opportunity.account.name',
    widthClassname: 'is-size-20',
    render: (el) => (
      <Link to={`/accounts/${el.opportunity?.account.id}`}>
        {el.opportunity?.account.name}
      </Link>
    ),
    title: 'Account',
    sortable: true,
  },
  {
    key: 'opportunity.name',
    widthClassname: 'is-size-20',
    render: (el) => el.opportunity?.name,
    title: 'Opportunity Name',
    sortable: true,
  },
  {
    key: 'seats',
    widthClassname: 'is-size-10',
    render: (el) => el.lines.filter((line) => line.softwareSeat).length,
    title: 'Total seats',
  },
  {
    key: 'closeDate',
    widthClassname: 'is-size-5',
    render: (el) =>
      el.closeDate ? formatDateToLocaleString(el.closeDate) : '',
    title: 'Close date',
    sortable: false,
  },
  {
    key: 'user',
    widthClassname: 'is-size-20',
    dataIndex: 'endUser',
    title: 'End user',
    sortable: false,
  },
  {
    key: 'hardware',
    widthClassname: 'is-size-5',
    render: (el) =>
      el.lines.some((line) => Boolean(line.device)) ? 'Yes' : 'No',
    title: 'Hardware',
    sortable: false,
  },
  {
    key: 'assignedDevices',
    widthClassname: 'is-size-10',
    render: (el) => {
      const totalDevices = el.lines.filter((line) => line.device);
      const total = el.lines.filter((line) => line.device).length;
      const assigned = totalDevices.filter(
        (line) => line.device?.deviceGroup
      ).length;

      return total ? `${assigned}/${total}` : 'N/A';
    },
    title: 'Assigned devices',
    sortable: false,
  },
];

const salesOrderOverviewColumns: Column<SalesOrder>[] = [
  {
    key: 'number',
    widthClassname: 'is-size-10',
    render: (el) => (
      <Link to={`/sales-orders/${el.id}`}>
        {formatSalesOrderNumber(el.number)}
      </Link>
    ),
    title: 'Sales Order',
    sortable: true,
  },
  {
    key: 'opportunity.account.name',
    widthClassname: 'is-size-20',
    render: (el) => (
      <Link to={`/accounts/${el.opportunity?.account?.id}`}>
        {el.opportunity?.account.name}
      </Link>
    ),
    title: 'Account',
    sortable: true,
  },
  {
    key: 'opportunity.name',
    widthClassname: 'is-size-20',
    render: (el) => el.opportunity?.name,
    title: 'Opportunity Name',
    sortable: true,
  },
  {
    key: 'seats',
    widthClassname: 'is-size-10',
    render: (el) => el.lines.filter((line) => line.softwareSeat).length,
    title: 'Total seats',
  },
  {
    key: 'closeDate',
    widthClassname: 'is-size-5',
    render: (el) =>
      el.closeDate ? formatDateToLocaleString(el.closeDate) : '',
    title: 'Close date',
    sortable: false,
  },
  {
    key: 'user',
    widthClassname: 'is-size-20',
    dataIndex: 'endUser',
    title: 'End user',
    sortable: false,
  },
  {
    key: 'hardware',
    widthClassname: 'is-size-5',
    render: (el) =>
      el.lines.some((line) => Boolean(line.device)) ? 'Yes' : 'No',
    title: 'Hardware',
    sortable: false,
  },
  {
    key: 'assignedDevices',
    widthClassname: 'is-size-10',
    render: (el) => {
      const totalDevices = el.lines.filter((line) => line.device);
      const total = el.lines.filter((line) => line.device).length;
      const assigned = totalDevices.filter(
        (line) => line.device?.assignments.length
      ).length;

      return total ? `${assigned}/${total}` : 'N/A';
    },
    title: 'Assigned devices',
    sortable: false,
  },
];

const salesOrderDetailsColumns: Column<SalesOrder>[] = [
  {
    key: 'number',
    widthClassname: 'is-size-20',
    render: (el) => (
      <Link to={`/sales-orders/${el.id}`}>
        {formatSalesOrderNumber(el.number)}
      </Link>
    ),
    title: 'Sales Order',
    sortable: true,
  },
  {
    key: 'seats',
    widthClassname: 'is-size-15',
    render: (el) => el.lines.filter((line) => line.softwareSeat).length,
    title: 'Total seats',
  },
  {
    key: 'closeDate',
    widthClassname: 'is-size-10',
    render: (el) =>
      el.closeDate ? formatDateToLocaleString(el.closeDate) : '',
    title: 'Close date',
    sortable: false,
  },
  {
    key: 'user',
    widthClassname: 'is-size-35',
    dataIndex: 'endUser',
    title: 'End user',
    sortable: false,
  },
  {
    key: 'hardware',
    widthClassname: 'is-size-20',
    render: (el) => el.lines.filter((line) => line.device).length,
    title: 'Hardware',
    sortable: false,
  },
];

export {
  salesOrderDetailsColumns,
  salesOrderOverviewColumns,
  salesOrderSummaryColumns,
};
