import { ReactNode, useMemo } from 'react';
import { Column } from '../types';

export type TableRowCellProps<T, K = unknown> = {
  row: T;
  col: Column<T, K>;
};

const noValueAvailable = Symbol('NO_VALUE_AVAILABLE');

export function TableRowCell<T, K = unknown>({
  row,
  col,
}: TableRowCellProps<T, K>) {
  const cellData = useMemo(() => {
    const value = col.dataIndex
      ? row[col.dataIndex]
      : col.render
      ? col.render(row)
      : noValueAvailable;

    if (value === noValueAvailable) {
      throw new Error(`No value available for column ${col.key}`);
    }

    return value as ReactNode;
  }, [col, row]);

  return cellData;
}

export default TableRowCell;
