import { ReactNode } from 'react';
import styles from './sticky.module.scss';

export interface StickyProps {
  children: ReactNode;
}

export function Sticky({ children }: StickyProps) {
  return <div className={styles.sticky}>{children}</div>;
}

export default Sticky;
