import { PaginatedContentConfig } from '@zspace/types';
import { ReactNode, useCallback, useState } from 'react';
import { Form } from 'react-bulma-components';
import { useDebouncedCallback } from 'use-debounce';
import Paginator from '../../paginator/paginator';
import SearchBar from '../../search-bar/search-bar';

export type OnTableFormChangeFunction = (value: PaginatedContentConfig) => void;
export type TableFormConfig = PaginatedContentConfig;

export type TableFormProps = {
  config: TableFormConfig;
  onChange: OnTableFormChangeFunction;
  extra?: ReactNode;
  disableSearch?: boolean;
  disableItemsPerPageSelection?: boolean;
  debounceMillis?: number;
  pagesDisplayLimit?: number;
  itemsPerPageOptions?: number[];
};

export function TableForm({
  config,
  onChange,
  extra,
  disableSearch,
  debounceMillis = 1000,
  itemsPerPageOptions,
  disableItemsPerPageSelection,
}: TableFormProps) {
  const [search, setSearch] = useState(config.search);

  const handleSeachInputChange = useDebouncedCallback((value: string) => {
    setSearch(value);
    onChange({ ...config, search: value });
  });

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: number) => {
      onChange({ ...config, itemsPerPage });
    },
    [config, onChange]
  );

  const handlePageNumberChange = useCallback(
    (pageNumber: number) => {
      onChange({ ...config, pageNumber });
    },
    [config, onChange]
  );

  return (
    <form
      role="search"
      className="w-full is-flex is-justify-content-space-between"
    >
      <Form.Field>
        <Paginator
          config={config}
          onItemsPerPageChange={handleItemsPerPageChange}
          onPageNumberChange={handlePageNumberChange}
          itemsPerPageOptions={itemsPerPageOptions}
          disableItemsPerPageSelection={disableItemsPerPageSelection}
        />
      </Form.Field>

      <Form.Field className="is-flex is-align-items-center mb-3">
        {!disableSearch && (
          <SearchBar
            onChange={handleSeachInputChange}
            searchValue={search}
            debounceMillis={debounceMillis}
          />
        )}

        {extra}
      </Form.Field>
    </form>
  );
}

export default TableForm;
