import { HttpStatusCode } from 'axios';
import { useMemo } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Conditional from '../conditional/conditional';
import ErrorMessageCard from '../error-message-card/error-message-card';
import NotFoundErrorCard from '../not-found-error-card/not-found-error-card';

export function AppErrorElement() {
  const error = useRouteError();

  const isNotFoundError = useMemo(
    () =>
      isRouteErrorResponse(error) && error.status === HttpStatusCode.NotFound,
    [error]
  );

  return (
    <Conditional condition={isNotFoundError}>
      <Conditional.True>
        <NotFoundErrorCard />
      </Conditional.True>
      <Conditional.False>
        <ErrorMessageCard />
      </Conditional.False>
    </Conditional>
  );
}

export default AppErrorElement;
