import {
  Criteria,
  PaginatedAPIResponse,
  SalesOrder,
  SoftwareFilter,
  SoftwareTableRow,
  SortDirection,
} from '@zspace/types';
import { useCallback, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaRegFolderOpen } from 'react-icons/fa6';
import useHttpRequest from '../../shared/hooks/http-request';
import SoftwareInbox from '../../software/software-inbox/software-inbox';
import InboxTable from '../../ui/inbox-table/inbox-table';
import { OnTableChangeData } from '../../ui/table/types';
import SoftwareAssignmentTable from '../software-assignment-table/software-assignment-table';

const FETCH_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE =
  'The software could not be fetched. Please try again';

const initialCriteria: Criteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
};

export type SoftwareAssignmentInboxTableProps = {
  salesOrders: SalesOrder[];
  fetchMoreSalesOrders: () => void;
  hasMoreSalesOrders: boolean;
  fetchSoftwareBySalesOrderRequest: (
    salesOrderId: string,
    criteria: Criteria
  ) => Promise<PaginatedAPIResponse<SoftwareTableRow>>;
  loadingInitialData: boolean;
  onFilter: (value: Criteria) => void;
};

export function SoftwareAssignmentInboxTable({
  salesOrders,
  fetchMoreSalesOrders,
  hasMoreSalesOrders,
  fetchSoftwareBySalesOrderRequest,
  loadingInitialData,
  onFilter,
}: SoftwareAssignmentInboxTableProps) {
  const [selectedSalesOrderId, setSelectedSalesOrderId] = useState<string>();
  const [software, setSoftware] = useState<SoftwareTableRow[]>([]);
  const [softwareBySalesOrderResponse, setSoftwareBySalesOrderResponse] =
    useState<PaginatedAPIResponse<SoftwareTableRow>>();
  const [loadingInitialSoftware, setLoadingInitialSoftware] = useState(false);
  const [criteriaFilter, setCriteriaFilter] = useState<Criteria>({
    ...initialCriteria,
  });
  const { executeHttpRequest } = useHttpRequest();

  const tableConfig = useMemo(() => {
    const { search, sortBy, sortDirection } = criteriaFilter;
    return {
      search,
      sortBy,
      sortDirection,
    };
  }, [criteriaFilter]);

  const fetchSoftwareBySalesOrder = useCallback(
    (salesOrderId: string, criteria: Criteria) =>
      executeHttpRequest({
        asyncFunction: async () => {
          const response = await fetchSoftwareBySalesOrderRequest(
            salesOrderId,
            criteria
          );
          setSoftwareBySalesOrderResponse(response);
          setSoftware((prevSoftware) => [...prevSoftware, ...response.data]);
        },
        customErrorMessage: FETCH_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE,
      }),
    [executeHttpRequest, fetchSoftwareBySalesOrderRequest]
  );

  const fetchMoreSoftware = useCallback(async () => {
    const updatedCriteriaFilter = {
      ...criteriaFilter,
      pageNumber: criteriaFilter.pageNumber + 1,
    };

    if (selectedSalesOrderId) {
      await fetchSoftwareBySalesOrder(
        selectedSalesOrderId,
        updatedCriteriaFilter
      );
      setCriteriaFilter(updatedCriteriaFilter);
    }
  }, [criteriaFilter, fetchSoftwareBySalesOrder, selectedSalesOrderId]);

  const onSalesOrderSelect = useCallback(
    async (salesOrderId: string) => {
      const updatedCriteria = {
        ...initialCriteria,
        search: criteriaFilter.search,
      };
      setCriteriaFilter(updatedCriteria);
      setSoftware([]);
      setSelectedSalesOrderId(salesOrderId);
      setLoadingInitialSoftware(true);
      await fetchSoftwareBySalesOrder(salesOrderId, updatedCriteria);
      setLoadingInitialSoftware(false);
    },
    [criteriaFilter.search, fetchSoftwareBySalesOrder]
  );

  const onTableChange = useCallback(
    async (value: OnTableChangeData<SoftwareTableRow>) => {
      const { pageNumber } = initialCriteria;
      const updatedCriteria = {
        ...criteriaFilter,
        ...value.config,
        sortBy: value.column?.key ?? '',
        pageNumber,
      };
      setCriteriaFilter(updatedCriteria);
      setLoadingInitialSoftware(true);
      setSoftware([]);
      await fetchSoftwareBySalesOrder(selectedSalesOrderId!, {
        ...criteriaFilter,
        ...updatedCriteria,
      });
      setLoadingInitialSoftware(false);
    },
    [criteriaFilter, fetchSoftwareBySalesOrder, selectedSalesOrderId]
  );

  const onSearchChange = useCallback(
    async (value: string) => {
      const updatedCriteria = {
        ...initialCriteria,
        search: value,
      };
      setCriteriaFilter(updatedCriteria);
      setSoftware([]);
      setSelectedSalesOrderId(undefined);
      onFilter(updatedCriteria);
    },
    [onFilter]
  );

  const inbox = useMemo(
    () => (
      <SoftwareInbox
        fetchMore={fetchMoreSalesOrders}
        hasMore={hasMoreSalesOrders}
        onClick={onSalesOrderSelect}
        salesOrders={salesOrders}
        selectedSalesOrderId={selectedSalesOrderId}
        loadingInitialData={loadingInitialData}
      />
    ),
    [
      fetchMoreSalesOrders,
      hasMoreSalesOrders,
      loadingInitialData,
      onSalesOrderSelect,
      salesOrders,
      selectedSalesOrderId,
    ]
  );

  const NoSalesOrderSelected = useCallback(
    () => (
      <Element
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="h-full gap-8"
      >
        <Icon className="folder-icon-big">
          <FaRegFolderOpen />
        </Icon>
        <Element textAlign="center">
          <h1 className="is-size-3 has-text-weight-light">
            Nothing to show here
          </h1>
          <h3 className="is-size-5 has-text-weight-light">
            Please select a sales order to display software
          </h3>
        </Element>
      </Element>
    ),
    []
  );

  const table = useMemo(() => {
    if (selectedSalesOrderId) {
      return (
        <SoftwareAssignmentTable
          dataSource={software}
          fetchMore={fetchMoreSoftware}
          hasMore={!!softwareBySalesOrderResponse?.hasMore}
          loadingInitialData={loadingInitialSoftware}
          config={tableConfig}
          onChange={onTableChange}
          includeSalesOrderColumn={selectedSalesOrderId === SoftwareFilter.ALL}
        />
      );
    }
    return <NoSalesOrderSelected />;
  }, [
    NoSalesOrderSelected,
    fetchMoreSoftware,
    loadingInitialSoftware,
    onTableChange,
    selectedSalesOrderId,
    software,
    softwareBySalesOrderResponse?.hasMore,
    tableConfig,
  ]);

  return (
    <InboxTable
      inbox={inbox}
      table={table}
      config={tableConfig}
      onSearchChange={onSearchChange}
    />
  );
}

export default SoftwareAssignmentInboxTable;
