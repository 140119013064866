export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type DeferredResponse<T> = {
  response: Promise<T>;
};

export type PaginatedAPIResponse<T> = {
  data: T[];
  count: number;
  page: number;
  total: number;
  pages: number;
  hasMore?: boolean;
};

export enum SortDirection {
  ASC = 'ASC',
  DES = 'DESC',
}

export enum NullsSorting {
  FIRST = 'FIRST',
  LAST = 'LAST',
}

export enum FilterType {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  EXACTLY = 'EXACTLY',
}

export type Criteria = {
  itemsPerPage: number;
  pageNumber: number;
  search: string;
  sortBy: string;
  sortDirection?: SortDirection;
};

export type PaginatedContentConfig = Criteria & {
  pages: number;
};

export type BasicTableConfig = Pick<
  Criteria,
  'sortBy' | 'sortDirection' | 'search'
>;

export enum TableCardActiveView {
  TABLE = 'TABLE',
  CARD = 'CARD',
}

export enum WorkOrderDashboardPageTabs {
  DRAFTS = 'DRAFTS',
  QUEUE = 'QUEUE',
  COMPLETED = 'COMPLETED',
}

export type ExternalResource = {
  externalId: string;
};

export type WorkOrdersCriteria = Criteria & {
  workOrderName?: string;
  workOrderNameFilter: FilterType;
  creationDateFrom: string;
  creationDateTo: string;
  account: string;
  accountFilter: FilterType;
  type: WorkOrderType;
  minTotalDevicesNumber?: string;
  maxTotalDevicesNumber?: string;
  createdBy: string[];
  salesOrder: string[];
  status: WorkOrderStatus;
  minPriority?: string;
  maxPriority?: string;
  minAssignedDevicesNumber?: string;
  maxAssignedDevicesNumber?: string;
};

export type SalesOrdersCriteria = Criteria & {
  opportunityName: string;
  opportunityNameFilter: FilterType;
  account: string;
  accountFilter: FilterType;
  minTotalSeats: string;
  maxTotalSeats: string;
  salesOrders: string[];
  hardware?: boolean;
  closeDateFrom: string;
  closeDateTo: string;
  parentAccountName: string;
  parentAccountNameFilter: FilterType;
  endUser: string[];
  accountId?: string;
  minHardwareNumber: string;
  maxHardwareNumber: string;
  excludeShippingStatus?: SalesOrderShippingStatus[];
};

export type SalesOrdersIncludedCriteria = Criteria & {
  salesOrders: string[];
};

export type SalesOrdersTableModalFilterDataType = Pick<
  SalesOrdersCriteria,
  | 'opportunityName'
  | 'opportunityNameFilter'
  | 'account'
  | 'accountFilter'
  | 'minTotalSeats'
  | 'maxTotalSeats'
  | 'salesOrders'
  | 'closeDateFrom'
  | 'closeDateTo'
  | 'parentAccountName'
  | 'parentAccountNameFilter'
  | 'endUser'
  | 'minHardwareNumber'
  | 'maxHardwareNumber'
>;

export type WorkOrdersTableModalFilterDataType = Omit<
  WorkOrdersCriteria,
  'itemsPerPage' | 'pageNumber' | 'search' | 'sortBy' | 'status'
>;

export type DeviceGroupsCriteriaFilterDataType = {
  deviceType: string;
  softwareAssigned: string;
  softwareTitle: string;
  softwareTitleFilter: FilterType;
  deviceName: string;
  deviceNameFilter: FilterType;
  serialNumber: string;
  serialNumberFilter: FilterType;
  salesOrders: string[];
  excludeEmptyGroups?: boolean;
};

export type DeviceGroupsCriteria = Criteria &
  DeviceGroupsCriteriaFilterDataType;

export type DeviceAssignmentsModalFilterDataType = Omit<
  DeviceGroupsCriteriaFilterDataType,
  'deviceName' | 'deviceNameFilter' | 'serialNumber' | 'serialNumberFilter'
>;

export type InstalledBaseRecord = {
  licenseKey: string;
};

export type Application = {
  id: string;
  nameId: string;
  displayName: string;
  internalName?: string;
  developerOrganization: DeveloperOrganization;
};

export type ApplicationMode = {
  id: string;
  nameId: string;
  displayName: string;
  licensingModeId: string;
  application: Application;
};

export type ApplicationSupplement = {
  id: string;
  nameId: string;
  displayName: string;
  applicationVariant?: ApplicationVariant;
  licensingProductGroups: LicensingProductGroup[];
  packages: AppManagerPackage[];
};

export type ApplicationSupplementLicensingProductGroup = {
  id: string;
  applicationSupplement: ApplicationSupplement;
  licensingProductGroup: LicensingProductGroup;
};

export enum ApplicationVariantType {
  NATIVE = 'NATIVE',
  WEB = 'WEB',
}

export type ApplicationVariant = {
  id: string;
  nameId: string;
  displayName: string;
  type: ApplicationVariantType;
  active: boolean;
  application: Application;
  networkCheckRequiredUrls?: NetworkCheckRequiredURL[];
  knowledgeArticle: ApplicationPrimaryKnowledgeArticle;
  supportedLocales: Locale[];
  packages: AppManagerPackage[];
  applicationSupplements: ApplicationSupplement[];
};

export type ApplicationModeVariant = {
  id: string;
  nameId: string;
  displayName: string;
  localAppId?: string;
  applicationVariant: ApplicationVariant;
  applicationMode: ApplicationMode;
  licensingProductGroups: LicensingProductGroup[];
};

export type ApplicationContentItem = {
  id: string;
  launchId: string;
  name: string;
  application: Application;
};

export type NetworkCheckRequiredURL = {
  id: string;
  url: string;
  additionalSuccessHttpStatusCode: number;
  active: boolean;
  requiredForAllDevices: boolean;
  name: string | null;
  applicationVariants: ApplicationVariant[];
  appManagerPackages: AppManagerPackage[];
};

export type DeviceCompatibility = {
  id: string;
  package: AppManagerPackage;
  deviceModel: DeviceModel;
  required: boolean;
};

export type AppManagerPackage = {
  id: string;
  appManagerExternalId: string;
  nameId: string;
  displayName: string;
  description?: string;
  compatibleDeviceModels: DeviceCompatibility[];
  supportedRegions: Region[];
  networkCheckRequiredUrls: NetworkCheckRequiredURL[];
};

export type ApplicationPrimaryKnowledgeArticle = {
  id: string;
  title: string;
  url: string;
  applicationVariant: ApplicationVariant;
};

export type Item = {
  id: string;
  externalId: number;
  name?: string;
  numberName: string;
  productName?: string;
  productCategory?: string;
  productDescription?: string;
  thirdPartyProductId?: string;
  productType?: string;
  licensingProductGroup?: LicensingProductGroup;
};

export enum DeviceStatus {
  ACTIVE = 'ACTIVE',
  RETURNED = 'RETURNED',
}

export type Device = {
  id: string;
  status: DeviceStatus;
  model: DeviceModel;
  name?: string;
  defaultName: string;
  serialNumber?: SerialNumber;
  salesOrderLine: SalesOrderLine;
  deviceGroup?: DeviceGroup;
  assignments: Assignment[];
  deviceRegistrationRequest?: DeviceRegistrationRequest[];
  deviceRegistration?: DeviceRegistration[];
  deletedAt?: Date;
};

export type DeviceModel = {
  id: string;
  nameId: string;
  displayName: string;
  internalName?: string;
  items: Item[];
};

export type DeviceApplicationVariantAssignment = {
  id: string;
  device: Device;
  applicationVariant: ApplicationVariant;
};

export type LicensingSystem = {
  id: string;
  nameId: string;
  displayName: string;
  internalName?: string;
  requiresManualActivation: boolean;
  active: boolean;
};

export type LicensingProductGroup = {
  id: string;
  nameId: string;
  displayName: string;
  active: boolean;
  items: Item[];
  licensingSystem: LicensingSystem;
  limelmProductVersion?: LimeLMProductVersion;
  applicationModeVariants: ApplicationModeVariant[];
  applicationSupplements: ApplicationSupplement[];
};

export enum LicenseActivationStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  EXPIRED = 'EXPIRED',
}

export type LicenseActivation = {
  id: string;
  endDate: Date;
  status: LicenseActivationStatus;
  license: LicenseKey;
  device: Device;
};

export type LimeLMProductVersion = {
  id: string;
  nameId: string;
  displayName: string;
  webId: string;
  sensitiveData: LimeLMProductVersionSensitiveData;
  licensingProductGroups: LicensingProductGroup[];
};

export type LimeLMProductVersionSensitiveData = {
  id: string;
  limelmProductVersionId: string;
  limelmProductVersion: LimeLMProductVersion;
  productVersionGuid: Buffer;
  turboActivateDat: Buffer;
};

export type LimeLMLicenseKey = {
  id: string;
  limelmProductVersion: LimeLMProductVersion;
  licenseKey: LicenseKey;
};

export type LimeLMLicenseKeyActivation = {
  id: string;
  limelmLicenseKey: LimeLMLicenseKey;
  licenseKeyActivation: LicenseActivation;
};

export type SoftwareCriteria = Criteria & {
  modelId: string;
};

export type SalesOrderLine = {
  id: string;
  salesOrder: SalesOrder;
  eulaAccepted: boolean;
  device?: Device;
  softwareSeat?: SoftwareSeat;
  item: Item;
  itemFulfillment: ItemFulfillment;
};

export enum SalesOrderStatus {
  FULFILLED = 'FULFILLED',
  PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
  NOT_FULFILLED = 'NOT_FULFILLED',
}

export enum SalesOrderShippingStatus {
  SCHEDULED_PENDING_SHIPMENT = 'Scheduled Pending shipment',
  OPS_TO_SCHEDULE = 'OPS TO SCHEDULE',
  READY_FOR_BOOKING = 'Ready for booking',
  HOLD_SOFTWARE = 'Hold Software',
  FULLY_SHIPPED = 'Fully Shipped',
  ADVANCE_PAYMENT = 'Advance Payment',
  RELEASE_SOFTWARE = 'Release Software',
  INVENTORY_SHORTAGE = 'Inventory Shortage',
  HOLD_FOR_FIRM_PO = 'HOLD FOR FIRM PO',
  PARTIAL_SHIPPED = 'Partial Shipped',
  HOLD_OTHER = 'HOLD - other',
  DEBOOKED = 'Debooked',
  PRE_CONFIG_PENDING = 'Pre Config Pending',
}

export type SalesOrder = {
  id: string;
  number: number;
  type?: string;
  status: SalesOrderStatus;
  lines: SalesOrderLine[];
  opportunity?: Opportunity;
  closeDate?: Date;
  purchaseDate: Date;
  endUser?: string;
  billingContact?: string;
  shippingContact?: string;
  permissionsGroupUserRoleAssignments: PermissionsGroupUserRoleAssignment[];
  shippingStatus?: SalesOrderShippingStatus;
};

export type SelectedSalesOrder = {
  number: number;
  id: string;
  hardware: boolean;
};

export type SelectedSalesOrderDevices = {
  salesOrder: {
    id: string;
    number: number;
  };
  deviceModelId: string;
  quantity: number;
};

export type SelectedSoftware = {
  id: string;
  title: string;
  seats: number;
};

export type SalesOrdersTableRow = {
  id: string;
  order: { id: string; number: string };
  opportunityName: string;
  account: { id: string; name: string };
  parentAccount: { id: string; name: string };
  seats: { available: string; total: string };
  closeDate: string;
  user: string;
  configurationType: string;
  hardware: boolean;
};

export type SalesOrdersIncludedTableRow = {
  id: string;
  order: { id: string; number: string };
  account: { id: string; name: string };
  opportunityName: string;
  seats: { total: string };
  closeDate: string;
  user: string;
  configurationType: string;
  hardware: boolean;
  serialNumbers: { assigned: string; total: string };
};

export type DeviceGroupsTableRow = {
  id: string;
  order: { id: string; number: string };
  deviceModel: {
    id: string;
    name: string;
  };
  purchaseDate: Date;
  closeDate: Date;
  totalDevices: number;
  totalAvailable: number;
};

export type EditDeviceGroupsTableRow = DeviceGroupsTableRow & {
  deviceGroupAssignedDevices: number;
};

export type SoftwareTableRow = {
  id: string;
  licensingProductGroup: { id: string; displayName: string };
  seats: { available: number };
  salesOrder: { id: string; number: string };
  itemFulfillment: {
    id: string;
    number: string;
    contractEndDate?: string;
  };
  type: string;
  availableToAssign: boolean;
  availableFromDate: Date;
};

export type Opportunity = {
  id: string;
  name: string;
  account: Account;
  salesOrders: SalesOrder[];
};

export type Account = {
  id: string;
  name: string;
  parent?: Account;
  children?: Account[];
  opportunities?: Opportunity[];
};

export enum WorkOrderType {
  ALL = 'ALL',
  FULL_CONFIGURATION = 'FULL_CONFIGURATION',
  INSTALL_ONLY = 'INSTALL_ONLY',
  HARDWARE_ONLY = 'HARDWARE_ONLY',
}

export enum WorkOrderStatus {
  DRAFT = 'DRAFT',
  QUEUE = 'QUEUE',
  COMPLETED = 'COMPLETED',
}

export type WorkOrder = {
  id: string;
  number: number;
  name: string;
  description: string;
  salesOrders: SalesOrder[];
  type: WorkOrderType;
  statusHistory: WorkOrderStatusRecord[];
  deviceSetupStatuses: WorkOrderDeviceSetupStatusRecord[];
  complex: boolean;
  createdAt: Date;
  createdBy: string;
  deviceGroups: DeviceGroup[];
  priority?: number;
  status?: WorkOrderStatus;
};

export type WorkOrderDetails = {
  name: string;
  type: WorkOrderType;
  description?: string;
  complex: boolean;
};

export type NewWorkOrder = WorkOrderDetails & {
  salesOrdersIds: string[];
};

export type EditWorkOrderDetails = Pick<
  WorkOrderDetails,
  'name' | 'description'
>;

export type WorkOrderData = WorkOrder & {
  status: WorkOrderStatus;
  devices: {
    assigned?: number;
    unassigned: number;
    total?: number;
  };
  softwareSeats?: {
    assigned: number;
    unassigned: number;
  };
};

export type GroupCode = {
  code: string;
  deviceGroup: DeviceGroup;
};

export type DeviceGroup = {
  id: string;
  name: string;
  description?: string;
  devices: Device[];
  workOrder?: WorkOrder;
  creator: User;
  groupCodes?: GroupCode[];
  groupCode?: GroupCode;
  visibility: UserRoleType;
};

export type BasicDeviceGroupData = Pick<DeviceGroup, 'id' | 'name'>;

export type DeviceGroupDetails = {
  name: string;
  description?: string;
};

export type NewDeviceGroup = DeviceGroupDetails & {
  devices: SelectedSalesOrderDevices[];
};

export type AssignedSoftwareTitle = {
  name: string;
};

export type SelectedSoftwareTitle = {
  id: string;
  quantity: number;
};

export type WorkOrderDetailPageContext = {
  workOrder: WorkOrderData;
  revalidate: () => void;
};

export type DeviceForSoftwareAssignmentGroup = Pick<
  DeviceGroup,
  'id' | 'name'
> & {
  devices: Device[];
  groupCode?: GroupCode;
  creator: string;
};

export type SoftwareForAssignment = {
  licensingProductGroupId: string;
  salesOrderId: string;
  itemFulfillmentId: string;
};

export type Assignment = {
  id: string;
  device: Device;
  softwareSeat: SoftwareSeat;
};

export type NewAssignments = {
  devices: Device[];
  software: SoftwareForAssignment[];
};

export type SerialNumber = {
  id: string;
  number: string;
};

export enum SoftwareSeatStatus {
  ACTIVE = 'ACTIVE',
  RETURNED = 'RETURNED',
}

export type SoftwareSeat = {
  id: string;
  status: SoftwareSeatStatus;
  salesOrderLine: SalesOrderLine;
  licenseKey: LicenseKey;
  assignments?: Assignment[];
  licensingProductGroup: LicensingProductGroup;
  deletedAt?: Date;
};

export type LicenseKey = {
  id: string;
  key: string;
};

export type Locale = {
  id: string;
  nameId: string;
  displayName: string;
  active: boolean;
};

export type Region = {
  id: string;
  nameId: string;
  displayName: string;
};

export type MySoftwareTableRow = {
  id: string;
  licensingProductGroup: { id: string; name: string };
  salesOrder: { id: string; number: string; endUser: string };
  seats?: { available: number; assigned: number; total: number };
  knowledgeArticleUrl?: string;
};

export type BasicUserData = {
  email: string;
  fullName: string;
};

export type BasicSalesOrderData = Pick<SalesOrder, 'id' | 'number'> & {
  devicesCount: number;
  licensingProductGroupsCount: number;
  eulaPending: boolean;
};

export type SalesOrderData = Omit<
  SalesOrder,
  'endUser' | 'shippingContact' | 'billingContact'
> & {
  endUser?: BasicUserData;
  billingContact?: BasicUserData;
  shippingContact?: BasicUserData;
  managersCount: number;
  workOrders: WorkOrder[];
  permissionsGroupUserRoleAssignments: PermissionsGroupUserRoleAssignment[];
};

export type SalesOrderDevicesTableRow = {
  model: HardwareModel;
  amount: number;
};

export type SalesOrderSoftwareTitlesTableRow = {
  id: string;
  title: string;
  seats: number;
  type: ApplicationVariantType;
};

export type SoftwareTitleForAssignment = Pick<
  SoftwareTableRow,
  'id' | 'licensingProductGroup' | 'salesOrder' | 'seats' | 'itemFulfillment'
> & {
  seats: { available: number; total?: number };
};

export enum HardwareModel {
  ALL = 'All',
  INSPIRE = 'Inspire',
  INSPIRE_PRO = 'Inspire pro',
}

export type UnauthorizedResponse = {
  authUrl: string;
};

export type LogoutResponse = UnauthorizedResponse;

export type User = {
  id: string;
  externalId?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  avatarUrl?: string;
  lastActive?: Date;
  roles: UserRoleAssignment[];
  deletedAt?: Date;
  deviceGroups: DeviceGroup[];
  activeRole?: UserRoleAssignment;
  isAllowedToAccess: boolean;
};

export type UserSettings = {
  tableCardActiveView: TableCardActiveView;
};

export type UserWithSettings = User & UserSettings;

export type Role = {
  id: string;
  name: UserRoleType;
};

export type UserRoleAssignment = {
  id: string;
  user: User;
  role: Role;
  active: boolean;
  permissionsGroupUserRoleAssignments: PermissionsGroupUserRoleAssignment[];
  createdAt: Date;
  deletedAt?: Date;
};

export type SalesOrderAssignment = {
  id: string;
  salesOrder: SalesOrder;
  createdAt?: Date;
  expiresAt?: Date;
  deletedAt?: Date;
};

export type UserSalesOrderPermissionTableRow = Pick<
  SalesOrder,
  'id' | 'number' | 'opportunity' | 'closeDate'
> & {
  permissionsGroup: {
    name: string;
    daysDuration?: number;
  };
};

export type UserSalesOrderPermission = {
  salesOrderId: string;
  permissionsGroup?: {
    name: string;
    daysDuration?: number;
  };
};

export type NewCustomerUser = {
  email: string;
  permissions: UserSalesOrderPermission[];
};

export type NewUser = {
  email: string;
  permissionsGroup: string;
};

export type NewUserRole = {
  role: string;
};

export type UserResourcePermission = {
  salesOrder: string;
  daysDuration?: number;
};

export type EmailAvailability = {
  available: boolean;
  user?: Pick<User, 'id'>;
};

export type EditedUser = {
  permissionsGroup?: string;
  permissions?: UserSalesOrderPermission[];
};

export type EditUserPermissionsTableRow = SalesOrder & {
  remainingDays: number;
};

export type UserPermissionsFilterDataType = {
  opportunityName: string;
  opportunityNameFilter: FilterType;
  salesOrder: string[];
  closeDateFrom: string;
  closeDateTo: string;
};

export type UsersCriteria = Criteria & {
  email: string;
  emailFilter: FilterType;
  lastActiveDateFrom: string;
  lastActiveDateTo: string;
  permissionsGroup: string;
  temporaryRoles: AllYesNoFilter;
  status: UserStatus;
  viewMode?: UserViewMode;
  salesOrder: string[];
  accountId?: string;
  salesOrderId?: string;
};

export type UsersTableFilterDataType = Omit<
  UsersCriteria,
  'itemsPerPage' | 'pageNumber' | 'search' | 'sortBy' | 'sortDirection'
>;

export type UserSalesOrdersCriteria = Criteria & {
  userPermissionsGroups?: string[];
};

export enum AllYesNoFilter {
  ALL = 'ALL',
  YES = 'YES',
  NO = 'NO',
}

export enum UserStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  PENDING_FIRST_LOGIN = 'PENDING FIRST LOGIN',
  INACTIVE = 'INACTIVE',
}

export enum YesNo {
  YES = 'YES',
  NO = 'NO',
}

export enum CustomerSalesOrderManagerDuration {
  PERMANENT = 'PERMANENT',
  TEMPORARY = 'TEMPORARY',
}

export type ItemFulfillment = {
  id: string;
  number: number;
  contractEndDate?: Date;
  salesOrderLine?: SalesOrderLine;
};

export enum UserViewMode {
  RELATED_USERS = 'RELATED_USERS',
  ORG_END_USER_SOFTWARE_CONTACTS = 'ORG_END_USER_SOFTWARE_CONTACTS',
}

export type HomeAlertsData = {
  outstandingEula?: boolean;
  ungroupedDevices: number;
  unassignedSoftwareTitles: number;
};

export type DeviceHomeAlertData = {
  ungroupedDevicesCount: number;
  unregisteredDevicesCount: number;
};

export type SoftwareHomeAlertData = {
  unassignedSoftwareCount: number;
};

export type EulaOutstandingData = {
  outstandingCount: number;
  acceptableByUser: boolean;
};

export enum MyDevicesTab {
  DEVICE_GROUPS = 'DEVICE_GROUPS',
  UNGROUPED_DEVICES = 'UNGROUPED_DEVICES',
}

export type MyDevicesData = {
  unregisteredDevicesCount: number;
  ungroupedDevicesCount: number;
  allDevicesCount: number;
};

export type EulaDocument = {
  title: string;
  content: string;
};

export type EulaData = {
  outstanding: boolean;
  documents: EulaDocument[];
};

export type WorkOrderDetailsData = {
  workOrder: Omit<WorkOrder, 'status'> & {
    currentStatus: { status: string; createdAt: Date };
  };
  devices: Device[];
};

export type MoveDevicesData = {
  devicesIds: string[];
  deviceGroupId?: string;
  deviceGroupCode?: string;
};

export type MyCourses = {
  coursesUrl: string;
};

export type WorkOrderStatusRecord = {
  id: string;
  status: WorkOrderStatus;
  workOrder: WorkOrder;
  createdBy: string;
  createdAt: Date;
  assignmentRecords: WorkOrderAssignmentRecord[];
};

export type WorkOrderDeviceSetupStatusRecord = {
  id: string;
  workOrder: WorkOrder;
  device: Device;
  setupStartedAt: Date;
  setupFinishedAt: Date | null;
  createdAt: Date;
};

export type WorkOrderAssignmentRecord = {
  id: string;
  statusRecord: WorkOrderStatusRecord;
  deviceGroup: DeviceGroup;
  device: Device;
  softwareAssignment?: Assignment;
};

export enum DirectusDeletableEnum {
  DELETED = 'DELETED',
  ACTIVE = 'ACTIVE',
}

export type DirectusCollectionMeta = {
  hidden?: boolean;
  archiveField?: string;
  archiveValue?: string;
  unarchiveValue?: string;
};
export type DirectusField = {
  name: string;
  type?: string;
  meta: DirectusFieldMeta;
  schema?: DirectusFieldSchema;
};
export type DirectusFieldSchema = {
  defaultValue?: string | number | boolean;
};
export type DirectusFieldMeta = {
  readonly?: boolean;
  hidden?: boolean;
  required?: boolean;
  special?: string[];
  interface?: string;
  options?: {
    template?: string;
    choices?: { text: string; value: string }[];
    filter?: unknown;
  };
  display?: string;
  displayOptions?: { template?: string };
  conditions?: DirectusCondition[];
};
export type DirectusRelationMeta = {
  junctionField: string;
  oneDeselectAction: string;
  oneField: string;
  sortField: null;
};

export type DirectusRelation = {
  collection: string;
  field: string;
  relatedCollection: string;
  meta: DirectusRelationMeta;
};

export type DirectusCollection = {
  name: string;
  meta: DirectusCollectionMeta;
  fields?: DirectusField[];
  relations?: DirectusRelation[];
};

export type DirectusCondition = {
  name?: string;
  rule?: DirectusRule;
  hidden?: boolean;
  options?: DirectusOptions;
  readonly?: boolean;
  required?: boolean;
};

export type DirectusOptions = {
  layout?: string;
  enableCreate?: boolean;
  enableSelect?: boolean;
  limit?: number;
  junctionFieldLocation?: string;
  allowDuplicates?: boolean;
  enableSearchFilter?: boolean;
  enableLink?: boolean;
  clear?: boolean;
  font?: string;
  iconLeft?: string;
  iconRight?: string;
  masked?: boolean;
  placeholder?: string;
  slug?: boolean;
  softLength?: number;
  trim?: boolean;
};

export type DirectusRule = {
  _and: DirectusAnd[];
};

export type DirectusAnd = {
  type: DirectusType;
};

export type DirectusType = {
  _eq: string;
};

export type DirectusNotification = {
  recipient: string;
  subject: string;
  message?: string;
  collection?: string;
  item?: string;
};

export enum DeveloperOrganizationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type DeveloperOrganization = {
  id: string;
  nameId: string;
  displayName: string;
  active: boolean;
};
export type PermissionsGroupUserRoleAssignment = {
  id: string;
  userRoleAssignment: UserRoleAssignment;
  permissionsGroup: PermissionsGroup;
  salesOrder?: SalesOrder;
  expiresAt?: Date;
  createdAt: Date;
  deletedAt?: Date;
};

export type PermissionsGroup = {
  id: string;
  name: string;
};

export enum UserRoleType {
  ZSPACE_INTERNAL = 'ZSPACE_INTERNAL',
  CUSTOMER = 'CUSTOMER',
  PARTNER = 'PARTNER',
}

export enum UserAction {
  ACCEPT_EULA = 'Accept EULA',
}

export enum UserActionStatus {
  IN_PROGRESS = 'In progress',
  COMPLETED_SUCCESSFULLY = 'Completed successfully',
  FAILED = 'Failed',
}

export type UserActivityRecord = {
  id: string;
  action: UserAction;
  status: UserActionStatus;
  startTimestamp: Date;
  endTimestamp?: Date;
  user?: User;
};

export enum DeviceRegistrationTab {
  REGISTRATION_FILES = 'REGISTRATION_FILES',
  REGISTERED_DEVICES = 'REGISTERED_DEVICES',
}

export enum RegistrationFileStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export type RegistrationFile = {
  id: string;
  name: string;
  createdAt: Date;
  createdBy: User;
  expiresAt: Date;
  deviceRegistrationToken: DeviceRegistrationToken;
};

export type FileRegistrationDownloadableData = {
  fileName: string;
  data: {
    deviceRegistrationTokenId: string;
    deviceRegistrationTokenSecret: string;
  };
};

export type DownloadableData = {
  fileName: string;
  fileContent: string;
};

export type RegistrationFileBasicData = DownloadableData & {
  id: string;
};

export type RegistrationFilesTableRow = Omit<
  RegistrationFile,
  'createdBy' | 'deviceRegistrationToken'
> & {
  status: RegistrationFileStatus;
  linkedDevices: number;
  registeredDevices: number;
};

export type RegistrationFileData = Pick<RegistrationFile, 'id' | 'name'> & {
  devices: PaginatedAPIResponse<RegistrationFileDevicesTableRow>;
};

export enum DeviceRegistrationStatus {
  ALL = 'All',
  REGISTERED = 'Registered',
  UNREGISTERED = 'Unregistered',
}

export enum DeviceRegistrationMethod {
  REGISTRATION_FILE = 'Registration file',
  TPM = 'TPM',
  QR_CODE = 'QR Code',
}

export type RegistrationFileDevicesExpandableTableRow = {
  registrationMethod: DeviceRegistrationMethod;
  registrationDate?: Date;
};

export type RegistrationFileDevicesTableRow = {
  id: string;
  serialNumber: string;
  name: string;
  type: string;
  status: DeviceRegistrationStatus;
  registrations: RegistrationFileDevicesExpandableTableRow[];
};

export type RegistrationFilesModalFilterDataType = Pick<
  RegistrationFilesTableRow,
  'name' | 'status'
> & {
  nameFilter: FilterType;
  expirationDateFrom: string;
  expirationDateTo: string;
  creationDateFrom: string;
  creationDateTo: string;
};

export type RegistrationFilesCriteria = Criteria &
  RegistrationFilesModalFilterDataType;

export type RegistrationFileDevicesModalFilterDataType = Pick<
  RegistrationFileDevicesTableRow,
  'type' | 'name'
> & {
  serialNumber: string[];
  status: string;
  registrationFileName: string;
  registrationFileNameFilter: FilterType;
  name: string;
  nameFilter: FilterType;
  registrationMethods: string[];
};

export type RegistrationFileDevicesCriteria = Criteria &
  RegistrationFileDevicesModalFilterDataType & {
    relatedRegisteredDevices: boolean;
  };

export enum MySoftwareTab {
  DEVICE_BASED_SOFTWARE = 'DEVICE_BASED_SOFTWARE',
  WEB_BASED_SOFTWARE = 'WEB_BASED_SOFTWARE',
}

export enum SoftwareAvailabilityStatus {
  ALL = 'ALL',
  AVAILABLE = 'AVAILABLE',
}

export type MySoftwareCriteria = Criteria & {
  availabilityStatus: SoftwareAvailabilityStatus;
  type: ApplicationVariantType;
  salesOrders: string[];
};

export type LicenseKeysExpandableTableRow = {
  id: string;
  assignedDevice?: {
    name: string;
    salesOrder: Pick<SalesOrder, 'id' | 'number'>;
  };
};

export type SoftwareData = {
  id: string;
  displayName: string;
  seats: {
    available: number;
    total: number;
  };
  salesOrders: Pick<SalesOrder, 'id' | 'number'>[];
};

export type LicenseKeysTableRow = LicenseKey & {
  expirationDate: Date;
  salesOrder: Pick<SalesOrder, 'id' | 'number' | 'endUser'>;
  seats: LicenseKeysExpandableTableRow[];
};

export type LicenseKeysCriteria = Criteria & {
  licenseKey: string;
  licenseKeyFilter: FilterType;
  expirationDateFrom: string;
  expirationDateTo: string;
  minTotalSeats: string;
  maxTotalSeats: string;
  assignedDevice: string;
  assignedDeviceFilter: FilterType;
  salesOrders: string[];
  endUsers: string[];
  licensingProductGroupId: string;
};

export type LicenseKeysModalFilterDataType = Pick<
  LicenseKeysCriteria,
  | 'licenseKey'
  | 'licenseKeyFilter'
  | 'expirationDateFrom'
  | 'expirationDateTo'
  | 'minTotalSeats'
  | 'maxTotalSeats'
  | 'assignedDevice'
  | 'assignedDeviceFilter'
  | 'salesOrders'
  | 'endUsers'
>;

export enum GuidedProcessSteps {
  SALES_ORDERS_AND_EULA = 'My Sales orders',
  TEAM = 'My Team',
  DEVICES = 'My Devices',
  SOFTWARE = 'My Software',
}

export enum DeviceOauth2GrantType {
  ZSPACE_DEVICE_CODE_V1 = 'ZSPACE_DEVICE_CODE_V1',
  ZSPACE_DEVICE_REFRESH_TOKEN_V1 = 'ZSPACE_DEVICE_REFRESH_TOKEN_V1',
  ZSPACE_DEVICE_REGISTRATION_TOKEN_V1 = 'ZSPACE_DEVICE_REGISTRATION_TOKEN_V1',
  ZSPACE_DEVICE_TPM_WINDOWS_NCRYPT_PCP_V1 = 'ZSPACE_DEVICE_TPM_WINDOWS_NCRYPT_PCP_V1',
}

export type DeviceOauth2ClientAllowedGrantType = {
  oauth2ClientId: string;
  oauth2Client: DeviceOauth2Client;
  grantType: DeviceOauth2GrantType;
};

export type DeviceOauth2Client = {
  id: string;
  externalId: string;
  secretHash: string;
  name: string;
  description: string;
  allowedGrantTypes: DeviceOauth2ClientAllowedGrantType[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

export type DeviceRegistrationTokenAllowedToRegisterDevice = {
  deviceRegistrationTokenId: string;
  deviceRegistrationToken: DeviceRegistrationToken;
  deviceId: string;
  device: Device;
};

export type DeviceRegistrationToken = {
  id: string;
  externalId: string;
  secretHash: string;
  secretHashPlain: string;
  name: string;
  description: string;
  lastUsedAt: Date | null;
  allowedToRegisterDevices: DeviceRegistrationTokenAllowedToRegisterDevice[];
  allowedToRegisterAnyDevice: boolean;
  expiresAt: Date | null;
  revokedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  registrationFile: RegistrationFile | null;
  deviceRegistrationRequests: DeviceRegistrationRequest[];
};

export enum DeviceRegistrationRequestType {
  DEVICE_CODE = 'DEVICE_CODE',
  DEVICE_REGISTRATION_TOKEN = 'DEVICE_REGISTRATION_TOKEN',
  DEVICE_TPM_WINDOWS_NCRYPT_PCP = 'DEVICE_TPM_WINDOWS_NCRYPT_PCP',
}

export enum DeviceRegistrationRequestStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED_PENDING_TOKEN_GRANT = 'APPROVED_PENDING_TOKEN_GRANT',
  REGISTERED = 'REGISTERED',
  DENIED = 'DENIED',
}

export type DeviceRegistrationRequest = {
  id: string;
  externalId: string;
  type: DeviceRegistrationRequestType;
  status: DeviceRegistrationRequestStatus;
  requesterOauth2Client: DeviceOauth2Client;
  deviceId: string;
  device: Device;
  deviceLastSeenName: string;
  deviceLastSeenDomain: string;
  deviceLastSeenIpAddress: string;
  deviceLastSeenAt: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  deviceCodeTypeDeviceCodeHash: string | null;
  deviceCodeTypeUserCode: string | null;
  deviceCodeTypeApprovalUrlCode: string | null;
  deviceCodeTypeExpiresAt: Date | null;
  deviceCodeTypeApprovedAt: Date | null;
  deviceCodeTypeDeniedAt: Date | null;
  deviceRegistrationTokenTypeDeviceRegistrationToken: DeviceRegistrationToken | null;
  deviceTpmWindowsNcryptPcpTypeAikIdBindingCreationNonceBytesBase64:
    | string
    | null;
  deviceTpmWindowsNcryptPcpTypeActivationCredentialSecretHash: string | null;
};

export type DeviceRegistration = {
  id: string;
  deviceId: string;
  device: Device;
  deviceLastSeenName: string;
  deviceLastSeenDomain: string;
  deviceLastSeenIpAddress: string;
  deviceLastSeenAt: Date;
  createdByRequest: DeviceRegistrationRequest;
  revokedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

export type DeviceOauth2Token = {
  id: string;
  oauth2Client: DeviceOauth2Client;
  accessTokenHash: string;
  accessTokenExpiresAt: Date;
  accessTokenLastUsedAt: Date | null;
  refreshTokenHash: string;
  refreshTokenExpiresAt: Date;
  refreshTokenReceiptConfirmedAt: Date | null;
  refreshTokenLastUsedAt: Date | null;
  grantorRefreshTokenId: string | null;
  grantorRefreshToken: DeviceOauth2Token | null;
  deviceRegistration: DeviceRegistration;
  revokedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

export type DeviceTpmInfo = {
  id: string;
  deviceId: string;
  device: Device;
  tpmEndorsementKeyPublicKeyJwk: string;
  tpmEndorsementKeyNameAlgorithm: number;
  tpmEndorsementKeySymmetricAlgorithm: number;
  tpmEndorsementKeySchemeHashAlgorithm: number;
  tpmMiscInfo: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

export type DeviceTpmEndorsementKeyCertificate = {
  id: string;
  deviceTpmInfoId: string;
  deviceTpmInfo: DeviceTpmInfo;
  certificateDerBase64: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

export type DeviceFailureReason = {
  id: string;
  nameId: string;
  displayName: string;
};

export type FaultyDeviceLookup = {
  serialNumber: string;
};

export type DeviceDetailsTableRow = {
  serialNumber: string;
  workOrder?: {
    id: string;
    number: number;
    status: WorkOrderStatus;
  };
  salesOrder?: {
    id: string;
    number: number;
  };
  inventoryLocation: string;
  itemFulfillment?: {
    number: number;
  };
  deviceType: string;
  assignedSoftware?: string;
};

export type DeviceSwapData = {
  faultyDeviceSerialNumber: string;
  replacementDeviceSerialNumber: string;
  deviceFailureReason: string;
};

export type DeviceSwapError = {
  message: string;
  code: string;
  error: string;
};

export enum MyDevicesFilter {
  UNGROUPED = 'UNGROUPED',
  UNREGISTERED = 'UNREGISTERED',
  ALL = 'ALL',
}

export enum SoftwareFilter {
  ALL = 'ALL',
}

export enum DeviceSwapResponseCodes {
  ACTION_INVALID = 'ACTION_INVALID',
  INTERNAL_SCRIPT_PARAMETER_MISSING = 'INTERNAL_SCRIPT_PARAMETER_MISSING',
  LOCATION_NOT_FOUND = 'LOCATION_NOT_FOUND',
  ORIGINAL_SERIAL_NUMBER_FULFILLMENT_PERIOD_CLOSED = 'ORIGINAL_SERIAL_NUMBER_FULFILLMENT_PERIOD_CLOSED',
  ORIGINAL_SERIAL_NUMBER_INSTALLED_BASE_RECORD_NOT_FOUND = 'ORIGINAL_SERIAL_NUMBER_INSTALLED_BASE_RECORD_NOT_FOUND',
  ORIGINAL_SERIAL_NUMBER_INVALID_ITEM = 'ORIGINAL_SERIAL_NUMBER_INVALID_ITEM',
  ORIGINAL_SERIAL_NUMBER_INVALID_LOCATION = 'ORIGINAL_SERIAL_NUMBER_INVALID_LOCATION',
  ORIGINAL_SERIAL_NUMBER_NOT_IN_INVENTORY = 'ORIGINAL_SERIAL_NUMBER_NOT_IN_INVENTORY',
  PARAMETER_MISSING = 'PARAMETER_MISSING',
  REPLACEMENT_SERIAL_NUMBER_ALREADY_IN_USE = 'REPLACEMENT_SERIAL_NUMBER_ALREADY_IN_USE',
  REPLACEMENT_SERIAL_NUMBER_INVALID_ITEM = 'REPLACEMENT_SERIAL_NUMBER_INVALID_ITEM',
  REPLACEMENT_SERIAL_NUMBER_INVALID_LOCATION = 'REPLACEMENT_SERIAL_NUMBER_INVALID_LOCATION',
  REPLACEMENT_SERIAL_NUMBER_NOT_DIFFERENT = 'REPLACEMENT_SERIAL_NUMBER_NOT_DIFFERENT',
  REPLACEMENT_SERIAL_NUMBER_NOT_IN_INVENTORY = 'REPLACEMENT_SERIAL_NUMBER_NOT_IN_INVENTORY',
  REPLACEMENT_SERIAL_NUMBER_ZERO_QUANTITY_ON_HAND = 'REPLACEMENT_SERIAL_NUMBER_ZERO_QUANTITY_ON_HAND',
  REPLACEMENT_SUCCESS = 'REPLACEMENT_SUCCESS',
}

export type SoftwareAssignmentReview = {
  id: string;
  device: {
    id: string;
    salesOrder: {
      id: string;
      number: number;
    };
  };
  softwareSeat: {
    id: string;
    salesOrder: {
      id: string;
      number: number;
    };
  };
  licensingProductGroup: {
    displayName: string;
  };
  licenseKey: {
    lastFourDigits: string;
    expirationDate?: Date;
  };
};

export type SoftwareAssignmentReviewData = {
  updatedAssignments: {
    oldAssignment: SoftwareAssignmentReview;
    newAssignment: SoftwareAssignmentReview;
  }[];
  newAssignments: SoftwareAssignmentReview[];
};

export type NewAssignmentFromReview = Pick<
  SoftwareAssignmentReview,
  'device' | 'softwareSeat'
>;

export type MySalesOrdersCriteria = Criteria & {
  salesOrders: string[];
  softwareTitle: string;
  softwareTitleFilter: FilterType;
  deviceType: string;
  relatedUsers: string[];
  minDevicesNumber: string;
  maxDevicesNumber: string;
};

export type MySalesOrdersCriteriaFilterDataType = Pick<
  MySalesOrdersCriteria,
  | 'salesOrders'
  | 'softwareTitle'
  | 'softwareTitleFilter'
  | 'deviceType'
  | 'relatedUsers'
  | 'minDevicesNumber'
  | 'maxDevicesNumber'
>;

export enum JobStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type JobHistory = {
  id: string;
  jobName: string;
  jobId: string;
  payload?: unknown;
  status: JobStatus;
  error?: string;
  startedAt: Date;
  completedAt?: Date;
};
