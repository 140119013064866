import { DeviceGroupPermissions, SoftwareSeatPermissions } from '@zspace/roles';
import { GuidedProcessSteps, MySoftwareTab } from '@zspace/types';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Columns, Element, Icon, Tabs } from 'react-bulma-components';
import { FaArrowRight } from 'react-icons/fa6';
import { Outlet, useNavigate } from 'react-router-dom';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import GuidedProcessStepperTabs from '../../shared/guided-process-stepper-tabs/guided-process-stepper-tabs';
import useIsPartnerUser from '../../shared/hooks/is-partner-user';
import If from '../../shared/if/if';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';

export type MySoftwarePageOutletContext = {
  setActiveTab: (tab: MySoftwareTab) => void;
  setFilterTagList: Dispatch<SetStateAction<ReactNode>>;
};

function MySoftwarePage() {
  const navigate = useNavigate();
  const isPartnerUser = useIsPartnerUser();
  const [activeTab, setActiveTab] = useState<MySoftwareTab>(
    MySoftwareTab.DEVICE_BASED_SOFTWARE
  );
  const [filterTagList, setFilterTagList] = useState<ReactNode>(null);

  const mySoftwarePageTabs = useMemo(() => Object.values(MySoftwareTab), []);

  const pageTabLabel = (tab: MySoftwareTab) => {
    switch (tab) {
      case MySoftwareTab.DEVICE_BASED_SOFTWARE:
        return 'Device based software';
      case MySoftwareTab.WEB_BASED_SOFTWARE:
        return 'Web based software';
    }
  };

  const isActiveTab = useCallback(
    (tab: MySoftwareTab) => tab === activeTab,
    [activeTab]
  );

  const navigateToTab = useCallback(
    (tab: MySoftwareTab) => () => {
      setActiveTab(tab);
      const tabPath =
        tab === MySoftwareTab.DEVICE_BASED_SOFTWARE
          ? 'device-based'
          : 'web-based';
      navigate(tabPath);
    },
    [navigate]
  );

  const handleManageUnassignedLicenses = useCallback(
    () => navigate('/device-groups'),
    [navigate]
  );

  return (
    <>
      <If condition={!isPartnerUser}>
        <GuidedProcessStepperTabs activeTab={GuidedProcessSteps.SOFTWARE} />
      </If>
      <BoxLayout
        className={`is-min-height-80 mx-10 ${isPartnerUser ? 'my-4' : 'mb-4'}`}
        headerClassName={isPartnerUser ? '' : 'is-radiusless'}
        header={
          <>
            <Columns marginless>
              <Columns.Column className="pl-4">
                <span className="is-size-3 has-text-weight-light">
                  My software
                </span>
              </Columns.Column>

              <Columns.Column alignItems="center">
                <CheckPermissions
                  permissions={[
                    DeviceGroupPermissions.DEVICE_GROUPS_READ,
                    SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
                    SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN,
                  ]}
                >
                  <CheckPermissions.Render>
                    <Element display="flex" justifyContent="flex-end">
                      <Button
                        role="button"
                        color="primary-dark"
                        onClick={handleManageUnassignedLicenses}
                      >
                        <span>Manage unassigned licenses</span>
                        <Icon>
                          <FaArrowRight />
                        </Icon>
                      </Button>
                    </Element>
                  </CheckPermissions.Render>
                </CheckPermissions>
              </Columns.Column>
            </Columns>
            <If condition={!!filterTagList}>
              <Element mt={3}>{filterTagList}</Element>
            </If>
          </>
        }
      >
        <Tabs>
          {mySoftwarePageTabs.map((tab) => (
            <Tabs.Tab
              key={tab}
              active={isActiveTab(tab)}
              onClick={navigateToTab(tab)}
            >
              {pageTabLabel(tab)}
            </Tabs.Tab>
          ))}
        </Tabs>
        <Outlet
          context={
            {
              setActiveTab,
              setFilterTagList,
            } as MySoftwarePageOutletContext
          }
        />
      </BoxLayout>
    </>
  );
}

export default MySoftwarePage;
