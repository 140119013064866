import { FilterCriteriaInputType, FilterType } from '@zspace/types';
import { KeyboardEvent, useCallback } from 'react';
import { Columns, Element, Form } from 'react-bulma-components';
import FeedbackMessage from '../../../../ui/feedback-message/feedback-message';
import { FilterModalInput } from '../../filter-table-modal-input/filter-table-modal-input';
import styles from './filter-criteria-input.module.scss';

export type FilterCriteriaInputProps<T> = FilterModalInput<T> & {
  input: FilterCriteriaInputType<T>;
};

export function FilterCriteriaInput<T>({
  input: { valueKey, filterValueKey },
  name,
  errorText,
  localData,
  setLocalData,
}: FilterCriteriaInputProps<T>) {
  const handleSearchOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
    []
  );

  return (
    <Element>
      <Columns marginless display="flex" className="gap-6">
        <Columns.Column paddingless size="one-third">
          <Form.Field>
            <Form.Control>
              <Form.Select
                value={localData[filterValueKey]}
                fullwidth
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    [filterValueKey]: e.target.value,
                  })
                }
                className={styles.formSelect}
              >
                <option value={FilterType.CONTAINS}>Contains</option>
                <option value={FilterType.EXACTLY}>Is exactly</option>
                <option value={FilterType.NOT_CONTAINS}>
                  Does not contain
                </option>
              </Form.Select>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column paddingless>
          <Form.Field>
            <Form.Control>
              <Form.Input
                id={name}
                value={localData[valueKey]}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    [valueKey]: e.target.value,
                  })
                }
                onKeyDown={handleSearchOnKeyDown}
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>
      <FeedbackMessage>{errorText}</FeedbackMessage>
    </Element>
  );
}

export default FilterCriteriaInput;
