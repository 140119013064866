import { AxiosError } from 'axios';
import { ComponentProps, useEffect } from 'react';
import { Await, useAsyncError } from 'react-router-dom';
import useError from '../hooks/error';

export type ErrorHandlingAwaitProps = ComponentProps<typeof Await>;

export function ErrorHandlingAwait({
  errorElement,
  ...props
}: ErrorHandlingAwaitProps) {
  return (
    <Await {...props} errorElement={errorElement ?? <DefaultErrorElement />} />
  );
}

const DefaultErrorElement = () => {
  const { setError } = useError();
  const asyncError = useAsyncError() as AxiosError;

  const errorMessage =
    (asyncError.response?.data as { message: string }).message ||
    asyncError.message;

  useEffect(() => {
    setError({
      status: asyncError.response?.status || 500,
      message: errorMessage,
      data: asyncError.response?.data || {},
    });
  }, [asyncError, errorMessage, setError]);

  return null;
};

export default ErrorHandlingAwait;
