import { ReactNode } from 'react';
import { RouteObject } from '../../app';
import Conditional from '../../shared/conditional/conditional';
import ResolutionWarningMessage from '../resolution-warning-message/resolution-warning-message';
import styles from './responsive-route-wrapper.module.scss';

export type ResponsiveRouteWrapperProps = {
  route: RouteObject;
  children: ReactNode;
};

export function ResponsiveRouteWrapper({
  route,
  children,
}: ResponsiveRouteWrapperProps) {
  return (
    <Conditional condition={!!route.isMobileFriendly}>
      <Conditional.True>{children}</Conditional.True>
      <Conditional.False>
        <div className={styles.warning}>
          <ResolutionWarningMessage />
        </div>
        <div className={styles.content}>{children}</div>
      </Conditional.False>
    </Conditional>
  );
}

export default ResponsiveRouteWrapper;
