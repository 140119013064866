import {
  getUserHighestPermissionsGroupUserRoleAssignment,
  getUserRoleSalesOrderRelatedPermissionGroupAssignments,
  PermissionsGroupName,
  UserPermissions,
} from '@zspace/roles';
import { SalesOrder, User, UserRoleType } from '@zspace/types';
import { useCallback, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaPenToSquare } from 'react-icons/fa6';
import SalesOrdersList from '../../sales-orders/sales-orders-list/sales-orders-list';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import Conditional from '../../shared/conditional/conditional';
import { remainingDays } from '../../shared/dates';
import useIsCustomerUser from '../../shared/hooks/is-customer-user';
import useIsUserEditable from '../../shared/hooks/is-user-editable';
import If from '../../shared/if/if';
import { formatLastActiveDate } from '../../shared/utils';
import Button from '../../ui/button/button';
import InfiniteTable, {
  CustomInfiniteTableProps,
} from '../../ui/table/infinite-table/infinite-table';
import {
  Column,
  ExpandableRow,
  OnTableChangeFunction,
  RowInteraction,
} from '../../ui/table/types';
import { UserStatusText } from '../types';
import { PermissionsGroupBySalesOrder } from '../users-table/users-table';
import styles from './my-team-table.module.scss';

type InfiniteTableProps = CustomInfiniteTableProps<User>;

export type MyTeamTableProps = InfiniteTableProps & {
  onChange: OnTableChangeFunction<User, PermissionsGroupBySalesOrder>;
  userRoleType?: UserRoleType;
  handleUserTemporaryPermissionsClick?: (user: User) => void;
};

export function MyTeamTable({
  dataSource,
  onChange,
  config,
  userRoleType,
  fetchMore,
  hasMore,
  loadingInitialData,
  empty,
  handleUserTemporaryPermissionsClick,
}: MyTeamTableProps) {
  const { isEditableByCustomerUser, isEditableByZspaceInternalUser } =
    useIsUserEditable();
  const isCustomerUser = useIsCustomerUser();
  const [, setSelectedUser] = useState<User | undefined>(undefined);
  const [activeRowId, setActiveRowId] = useState<string>('');

  const isExpandableRow = useCallback((row: User) => {
    const userRoles = new Set<PermissionsGroupName>();
    row.roles.forEach((userRoleAssignment) => {
      userRoleAssignment.permissionsGroupUserRoleAssignments.forEach(
        (permissionsGroupUserRoleAssignment) => {
          userRoles.add(
            permissionsGroupUserRoleAssignment.permissionsGroup
              .name as PermissionsGroupName
          );
        }
      );
    });
    return userRoles.size > 1;
  }, []);

  const rowInteraction: RowInteraction = {
    onRowEnter: function (rowKey: string): void {
      setActiveRowId(rowKey);
    },
    onRowLeave: function (): void {
      setActiveRowId('');
    },
  };

  const customerColumns: Column<User, PermissionsGroupBySalesOrder>[] = useMemo(
    () => [
      {
        key: 'email',
        widthClassname: 'is-size-25',
        render: (el) => (
          <Element display="flex" alignItems="center">
            <span>{el.email}</span>
            <CheckPermissions permissions={UserPermissions.USERS_ASSIGN_ROLES}>
              <CheckPermissions.Render>
                <If
                  condition={
                    isCustomerUser
                      ? isEditableByCustomerUser(el)
                      : isEditableByZspaceInternalUser(el)
                  }
                >
                  <Button
                    ml={1}
                    text
                    className={`has-text-primary-dark is-size-7 ${
                      el.id === activeRowId ? '' : `${styles.invisible}`
                    }`}
                    onClick={() => {
                      setSelectedUser(el);
                      handleUserTemporaryPermissionsClick?.(el);
                    }}
                  >
                    <Icon>
                      <FaPenToSquare />
                    </Icon>
                    <span>Edit details</span>
                  </Button>
                </If>
              </CheckPermissions.Render>
            </CheckPermissions>
          </Element>
        ),
        title: 'User',
        sortable: true,
      },
      {
        key: 'role',
        widthClassname: 'is-size-20',
        render: (el) => {
          if (isExpandableRow(el)) {
            return null;
          }
          const role =
            el.roles[0].permissionsGroupUserRoleAssignments[0].permissionsGroup
              .name;
          return <span>{role}</span>;
        },
        title: 'Role',
        expandableContentRender: (el) => el.permissionsGroupName,
      },
      {
        key: 'salesOrders',
        widthClassname: 'is-size-35',
        render: (el) => {
          if (isExpandableRow(el)) {
            return null;
          }
          const parsedSalesOrdersSet = el.roles.reduce(
            (acc, roleAssignment) => {
              roleAssignment.permissionsGroupUserRoleAssignments.forEach(
                (permissionsGroupUserRoleAssignment) => {
                  if (permissionsGroupUserRoleAssignment.salesOrder) {
                    acc.add(permissionsGroupUserRoleAssignment.salesOrder);
                  }
                }
              );
              return acc;
            },
            new Set<SalesOrder>()
          );

          const parsedSalesOrders = Array.from(parsedSalesOrdersSet.values());
          const link = isEditableByCustomerUser(el)
            ? `/users/${el.id}/edit`
            : '';

          return (
            <SalesOrdersList
              list={parsedSalesOrders}
              textColor="primary"
              link={link}
            />
          );
        },
        title: 'Sales orders',
        sortable: false,
        expandableContentRender: (el, parentElement) => {
          if (!el.salesOrders) {
            return null;
          }

          const salesOrders = el.salesOrders;

          const link = isEditableByCustomerUser(parentElement)
            ? `/users/${parentElement.id}/edit`
            : '';

          return (
            <SalesOrdersList
              list={salesOrders}
              textColor="primary"
              link={link}
            />
          );
        },
      },
      {
        key: 'deletedAt',
        widthClassname: 'is-size-15',
        render: (el) => {
          const userStatus = el.lastActive
            ? UserStatusText.ACTIVE
            : UserStatusText.PENDING_FIRST_LOGIN;

          return <span>{userStatus}</span>;
        },
        title: 'Status',
        sortable: false,
      },
    ],
    [
      activeRowId,
      isCustomerUser,
      isEditableByCustomerUser,
      isEditableByZspaceInternalUser,
      isExpandableRow,
      handleUserTemporaryPermissionsClick,
    ]
  );

  const zspaceStaffAndPartnerColumns: Column<User>[] = useMemo(
    () => [
      {
        key: 'email',
        dataIndex: 'email',
        title: 'User',
        sortable: true,
        widthClassname: 'is-size-25',
      },
      {
        key: 'roles.role.name',
        widthClassname: 'is-size-15',
        render: (el) => {
          const userPermissionsGroupUserRoleAssignment =
            getUserHighestPermissionsGroupUserRoleAssignment(
              el,
              UserRoleType.ZSPACE_INTERNAL
            );
          const role =
            userPermissionsGroupUserRoleAssignment?.permissionsGroup?.name ??
            '-';

          return <span>{role}</span>;
        },
        title: 'Role',
      },
      {
        key: 'temporaryRoles',
        widthClassname: 'is-size-35',
        render: (el) => {
          const temporaryPermissionsGroupUserRoleAssignments = el.roles.find(
            (roleAssignment) =>
              roleAssignment.role.name === UserRoleType.CUSTOMER
          )?.permissionsGroupUserRoleAssignments;

          if (
            !temporaryPermissionsGroupUserRoleAssignments ||
            temporaryPermissionsGroupUserRoleAssignments.length === 0
          ) {
            return '';
          }

          return temporaryPermissionsGroupUserRoleAssignments.map(
            (permissionsGroupUserRoleAssignment) => {
              const daysLeft = remainingDays(
                new Date(permissionsGroupUserRoleAssignment.expiresAt!)
              );

              if (!daysLeft || !permissionsGroupUserRoleAssignment.salesOrder) {
                return null;
              }

              const content = `${permissionsGroupUserRoleAssignment.salesOrder.opportunity?.account.name} (${daysLeft})`;

              return <span className={`is-size-7`}>{content}</span>;
            }
          );
        },
        title: 'Temporary manager roles (days remaining)',
        sortable: false,
      },
      {
        key: 'lastActive',
        widthClassname: 'is-size-10',
        render: (el) =>
          el.lastActive ? formatLastActiveDate(new Date(el.lastActive)) : '',
        title: 'Last active',
        sortable: true,
      },
      {
        key: 'deletedAt',
        widthClassname: 'is-size-15',
        render: (el) => (
          <Element display="flex" alignItems="center">
            <Conditional condition={el.deletedAt === null}>
              <Conditional.True>
                <span>
                  {el.lastActive
                    ? UserStatusText.ACTIVE
                    : UserStatusText.PENDING_FIRST_LOGIN}
                </span>
              </Conditional.True>
              <Conditional.False>
                <span>Inactive</span>
              </Conditional.False>
            </Conditional>
          </Element>
        ),
        title: 'Status',
        sortable: false,
      },
    ],
    []
  );

  const columns = useMemo(
    () =>
      userRoleType === UserRoleType.CUSTOMER
        ? customerColumns
        : zspaceStaffAndPartnerColumns,
    [customerColumns, userRoleType, zspaceStaffAndPartnerColumns]
  );

  const expandableRow = useMemo(():
    | ExpandableRow<User, PermissionsGroupBySalesOrder>
    | undefined => {
    return {
      expandable: (row: User) => isExpandableRow(row),
      expandableRowIterator: (row: User) => {
        const permissionsGroupsBySalesOrderMap = new Map<
          PermissionsGroupName,
          PermissionsGroupBySalesOrder
        >();

        const userSalesOrderRelatedPermissionGroupsAssignments =
          row.roles.flatMap((userRoleAssignment) =>
            getUserRoleSalesOrderRelatedPermissionGroupAssignments(
              userRoleAssignment
            )
          );

        if (!userSalesOrderRelatedPermissionGroupsAssignments) {
          return [];
        }

        userSalesOrderRelatedPermissionGroupsAssignments.forEach(
          (assignment) => {
            const permissionsGroupName = assignment.permissionsGroup
              .name as PermissionsGroupName;

            const existingGroup =
              permissionsGroupsBySalesOrderMap.get(permissionsGroupName);
            const salesOrders = existingGroup
              ? [...existingGroup.salesOrders, assignment.salesOrder!]
              : [assignment.salesOrder!];

            permissionsGroupsBySalesOrderMap.set(permissionsGroupName, {
              permissionsGroupName,
              salesOrders,
            });
          }
        );

        return Array.from(permissionsGroupsBySalesOrderMap.values());
      },
    };
  }, [isExpandableRow]);

  return (
    <InfiniteTable
      fetchMore={fetchMore}
      hasMore={hasMore}
      columns={columns}
      rowKey="id"
      loadingInitialData={loadingInitialData}
      config={config}
      dataSource={dataSource}
      onChange={onChange}
      empty={empty}
      expandableRow={expandableRow}
      rowInteraction={rowInteraction}
    />
  );
}

export default MyTeamTable;
