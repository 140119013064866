import { filter } from '@zspace/data';
import { WorkOrderPermissions } from '@zspace/roles';
import {
  DeferredResponse,
  NewWorkOrder,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  SalesOrder,
  SalesOrdersIncludedCriteria,
  WorkOrder,
  WorkOrderDetails,
  WorkOrderType,
} from '@zspace/types';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaCheck, FaPenToSquare } from 'react-icons/fa6';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import CancelAlertModal from '../../shared/cancel-alert-modal/cancel-alert-modal';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import Spinner from '../../ui/spinner/spinner';
import { OnTableChangeData } from '../../ui/table/types';
import { fetchSalesOrders } from '../sales-orders-service';
import { SalesOrdersStorageService } from '../sales-orders-storage-service';
import { salesOrderOverviewColumns } from '../sales-orders-table/constants';
import SalesOrdersTable from '../sales-orders-table/sales-orders-table';
import WorkOrderCreatedConfirmationModal from '../work-order-created-confirmation-modal/work-order-created-confirmation-modal';
import { createNewWorkOrder } from '../work-orders-service';
import { WorkOrdersDetailsStorageService } from '../work-orders-storage-service';
import {
  CreateWorkOrderDetailsForm,
  WorkOrderDetailsFormData,
} from './create-work-order-details-form/create-work-order-details-form';

const salesOrdersSessionStorageService = new SalesOrdersStorageService();
const workOrderDetailsSessionStorageService =
  new WorkOrdersDetailsStorageService();

const CANCEL_ALERT_MODAL_TITLE = 'Cancel work order';
const CANCEL_ALERT_MODAL_SUBTITLE =
  'Are you sure you want to cancel the work order creation process?\nYou will lose all your progress';
const CREATE_WORK_ORDER_ERROR_MESSAGE =
  'The work order could not be created. Please try again';

const validateForm = {
  name: (value: string) =>
    !value.trim() ? 'Please provide a work order name' : null,
  type: null,
  description: null,
  complex: null,
};

export const loader: LoaderFunction = async () => {
  const selectedSalesOrdersFromStorage = salesOrdersSessionStorageService.get();
  const response = fetchSalesOrders({
    itemsPerPage: 30,
    pageNumber: 1,
    search: '',
    sortBy: '',
    salesOrders: selectedSalesOrdersFromStorage.map((so) =>
      so.number.toString()
    ),
  });

  return defer({ response });
};

function CreateWorkOrderDetailsPageContent() {
  const navigate = useNavigate();
  const response = useAsyncValue() as PaginatedAPIResponse<SalesOrder>;

  const selectedSalesOrders = salesOrdersSessionStorageService.get();

  const [tableData, setTableData] = useState<SalesOrder[]>(response.data);
  const [salesOrdersTableCriteria, setSalesOrdersTableCriteria] =
    useState<SalesOrdersIncludedCriteria>({
      itemsPerPage: 30,
      pageNumber: 1,
      search: '',
      sortBy: '',
      salesOrders: selectedSalesOrders.map((so) => so.number.toString()),
    });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isCancelAlertModalVisible, setIsCancelAlertModalVisible] =
    useState(false);
  const [formData, setFormData] = useState<WorkOrderDetailsFormData>(() => {
    const formDataFromSessionStorage =
      workOrderDetailsSessionStorageService.get();
    return {
      name: {
        value: formDataFromSessionStorage.name ?? '',
        touched: false,
        error: null,
      },
      type: {
        value:
          formDataFromSessionStorage.type ?? WorkOrderType.FULL_CONFIGURATION,
        touched: false,
        error: null,
      },
      description: {
        value: formDataFromSessionStorage.description ?? '',
        touched: false,
        error: null,
      },
      complex: {
        value: formDataFromSessionStorage.complex?.toString() ?? '0',
        touched: false,
        error: null,
      },
    };
  });
  const [newWorkOrder, setNewWorkOrder] = useState<WorkOrder>();
  const [loading, setLoading] = useState(false);
  const { executeHttpRequest, isLoading: isCreatingNewWorkOrder } =
    useHttpRequest();

  useEffect(() => {
    if (selectedSalesOrders.length === 0) {
      setLoading(true);
      navigate('/work-orders/create/sales-orders', { replace: true });
    }
  }, [navigate, selectedSalesOrders.length]);

  const tableConfig: PaginatedContentConfig = useMemo(() => {
    return {
      ...salesOrdersTableCriteria,
      pages: response.pages,
    };
  }, [response.pages, salesOrdersTableCriteria]);

  const handleOnTableChange = useCallback(
    (value: OnTableChangeData<SalesOrder>): void => {
      const newData = {
        ...salesOrdersTableCriteria,
        ...value.config,
        sortBy: value.column?.key || salesOrdersTableCriteria.sortBy,
      };

      const newTableData = filter(response.data, newData, []);
      setSalesOrdersTableCriteria(newData);
      setTableData(newTableData);
    },
    [response.data, salesOrdersTableCriteria]
  );

  const navigateToWorkOrderList = useCallback(() => {
    workOrderDetailsSessionStorageService.remove();
    navigate('/work-orders/dashboard/drafts');
  }, [navigate]);

  const handleCreateNewWorkOrder = useCallback(() => {
    executeHttpRequest({
      asyncFunction: async () => {
        const newWorkOrderData: NewWorkOrder = {
          name: formData.name.value,
          type: formData.type.value as WorkOrderType,
          description: formData.description.value,
          complex: formData.complex.value === '1',
          salesOrdersIds: selectedSalesOrders.map((so) => so.id),
        };
        const newWorkOrder = await createNewWorkOrder(newWorkOrderData);
        setNewWorkOrder(newWorkOrder);
        setShowConfirmationModal(true);
        workOrderDetailsSessionStorageService.remove();
      },
      customErrorMessage: CREATE_WORK_ORDER_ERROR_MESSAGE,
    });
  }, [
    executeHttpRequest,
    formData.complex.value,
    formData.description.value,
    formData.name.value,
    formData.type.value,
    selectedSalesOrders,
  ]);

  const onCreateNewWorkOrder = useCallback(async () => {
    //Validate form
    const formValidation = Object.keys(formData).reduce(
      (acc, key) => {
        const formDataKey = key as keyof WorkOrderDetails;
        const error = validateForm[formDataKey]?.(formData[formDataKey].value);
        setFormData((prev) => ({
          ...prev,
          [formDataKey]: { ...prev[formDataKey], error, touched: true },
        }));
        return {
          values: { ...acc.values, [key]: formData[formDataKey].value },
          errors: { ...acc.errors, [key]: error },
        };
      },
      { values: {}, errors: {} }
    );
    const isValidForm = Object.values(formValidation.errors).every(
      (fieldError) => !fieldError
    );
    if (isValidForm) {
      await handleCreateNewWorkOrder();
    } else {
      //Scroll to top to show errors
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [formData, handleCreateNewWorkOrder]);

  const handleModify = useCallback(() => {
    const formDataValues: WorkOrderDetails = {
      name: formData.name.value,
      type: formData.type.value as WorkOrderType,
      description: formData.description.value,
      complex: Boolean(formData.complex.value),
    };
    workOrderDetailsSessionStorageService.save(formDataValues);
    navigate('/work-orders/create/sales-orders');
  }, [navigate, formData]);

  return (
    <BoxLayout
      className="mx-10 my-4"
      header={
        <section className="p-4">
          <h1 className="is-size-3 has-text-weight-light">Create work order</h1>
        </section>
      }
    >
      <section>
        <CreateWorkOrderDetailsForm
          data={formData}
          onChangeData={setFormData}
          validate={validateForm}
          editMode={false}
        />
      </section>

      {loading ? (
        <Spinner />
      ) : (
        <>
          <section>
            <Element className="my-8 is-flex is-align-items-center">
              <div>
                <h2 className="subtitle is-size-5">Included sales orders</h2>
              </div>
              <Button
                color="primary-dark"
                outlined
                ml={8}
                onClick={handleModify}
              >
                <Icon>
                  <FaPenToSquare />
                </Icon>
                <span>Modify</span>
              </Button>
            </Element>
            <SalesOrdersTable
              columns={salesOrderOverviewColumns}
              dataSource={tableData}
              config={tableConfig}
              onChange={handleOnTableChange}
            />
          </section>

          <section className="is-flex is-justify-content-flex-end mt-8">
            <Button
              mr={4}
              color="primary-dark"
              outlined
              onClick={() => setIsCancelAlertModalVisible(true)}
            >
              Cancel
            </Button>
            <Button
              color="primary-dark"
              onClick={onCreateNewWorkOrder}
              isExecutingAction={isCreatingNewWorkOrder}
            >
              <Button.LoadingIcon icon={FaCheck} />
              <span>Create work order</span>
            </Button>
          </section>
          <WorkOrderCreatedConfirmationModal
            show={showConfirmationModal}
            workOrder={newWorkOrder}
          />
          <CancelAlertModal
            show={isCancelAlertModalVisible}
            title={CANCEL_ALERT_MODAL_TITLE}
            subtitle={CANCEL_ALERT_MODAL_SUBTITLE}
            onCancel={navigateToWorkOrderList}
            onClose={() => setIsCancelAlertModalVisible(false)}
          />
        </>
      )}
    </BoxLayout>
  );
}

function CreateWorkOrderDetailsPage() {
  const { response } = useLoaderData() as DeferredResponse<
    PaginatedAPIResponse<SalesOrder>
  >;

  return (
    <ProtectedPage permissions={WorkOrderPermissions.WORK_ORDERS_CREATE}>
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <CreateWorkOrderDetailsPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default CreateWorkOrderDetailsPage;
