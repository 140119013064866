import { SalesOrder } from '@zspace/types';
import {
  SELECTED_SALES_ORDERS_STORAGE_KEY,
  SessionStorageServiceImpl,
} from '../shared/storage';

export class SalesOrdersStorageService {
  private storageService: SessionStorageServiceImpl<SalesOrder[]> =
    new SessionStorageServiceImpl();
  public get() {
    return this.storageService.getItem(SELECTED_SALES_ORDERS_STORAGE_KEY) || [];
  }
  public save(selectedSalesOrders: SalesOrder[]) {
    return this.storageService.setItem(
      SELECTED_SALES_ORDERS_STORAGE_KEY,
      selectedSalesOrders
    );
  }
  public remove() {
    return this.storageService.removeItem(SELECTED_SALES_ORDERS_STORAGE_KEY);
  }
}
