import { faker } from '@faker-js/faker';
import { define } from 'cooky-cutter';
import { PermissionsGroup, PermissionsGroupUserRoleAssignment } from '../types';
import { userRoleAssignmentFactory } from './user-role-assignment';

export const permissionsGroupNames = [
  'Super Admin',
  'IT Admin',
  'Support Standard',
  'Customer Success',
  'Work Order Creator',
  'Work Order Manager',
  'Warehouse Partner',
  'End User Software Contact',
  'Billing Contact',
  'Shipping Contact',
  'Sales Order Manager',
  'View Only Sales Order Manager',
  'Temporary Sales Order Manager',
] as const;

export const permissionsGroupFactory = define<PermissionsGroup>({
  id: () => faker.string.uuid(),
  name: () => faker.helpers.arrayElement(permissionsGroupNames),
});

export const permissionsGroupUserRoleAssignmentFactory =
  define<PermissionsGroupUserRoleAssignment>({
    id: () => faker.string.uuid(),
    permissionsGroup: () => permissionsGroupFactory(),
    createdAt: () => faker.date.recent(),
    userRoleAssignment: () => userRoleAssignmentFactory(),
  });
