import { DeferredResponse, EulaData } from '@zspace/types';
import { AxiosError } from 'axios';
import DOMPurify from 'dompurify';
import { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { Columns, Element, Icon, Section } from 'react-bulma-components';
import { FaCheck, FaDownload, FaXmark } from 'react-icons/fa6';
import { IoCheckmarkCircle } from 'react-icons/io5';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import BackButton from '../../shared/back-button/back-button';
import Conditional from '../../shared/conditional/conditional';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import useIsMobileDisplay from '../../shared/hooks/is-mobile-display';
import useToast from '../../shared/hooks/toasts';
import If from '../../shared/if/if';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import FeedbackMessage from '../../ui/feedback-message/feedback-message';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { acceptEula, fetchEulasData } from '../eula-service';
import styles from './accept-eula-page.module.scss';
import { generatePdfFromHTML } from './utils';

export const loader: LoaderFunction = () => {
  const response = fetchEulasData();

  return defer({ response });
};

const HEADER_TITLE = 'Outstanding EULAs';
const EULA_INSTRUCTIONS_TEXT =
  'Please read carefully and accept the following End user license agreement (EULA) in order to use your software';
const NO_OUTSTANDING_EULAS_MESSAGE = 'You don’t have any outstanding EULAs';
const EULA_ACCEPTANCE_ERROR_MESSAGE =
  'Please try again later or contact support if the error persists';

export function AcceptEulaPageContent() {
  const navigate = useNavigate();
  const eulaData = useAsyncValue() as EulaData;
  const toast = useToast();
  const { executeHttpRequest, isLoading: isAcceptingEula } = useHttpRequest();
  const isMobileDisplay = useIsMobileDisplay();
  const [isDownloadingEula, setIsDownloadingEula] = useState<boolean>(false);
  const [displayEulaAcceptanceError, setDisplayEulaAcceptanceError] =
    useState<boolean>(false);
  const eulaDocumentRef = useRef<HTMLDivElement>(null);

  const eulaTitle = useMemo(
    () => eulaData.documents[0]?.title ?? '',
    [eulaData.documents]
  );

  const eulaContent = useMemo(
    () => DOMPurify.sanitize(eulaData.documents[0]?.content ?? ''),
    [eulaData.documents]
  );

  const downloadPdf = useCallback(async () => {
    const eulaDiv = eulaDocumentRef.current;
    if (!eulaData || !eulaDiv) return;

    /* A clone is generated to ensure that the scrollable
     * overflow is visible in the generated pdf document
     */
    const eulaDocumentClone = eulaDiv.cloneNode(true) as HTMLDivElement;
    document.body.appendChild(eulaDocumentClone);
    eulaDocumentClone.className = `${eulaDocumentClone.className} ${styles.eulaPdf}`;
    eulaDocumentClone.style.height = `${eulaDocumentClone.scrollHeight}px`;

    try {
      const pdf = await generatePdfFromHTML(eulaDocumentClone);
      pdf.save('content.pdf');
    } catch (error) {
      toast.error('Error downloading EULA. Please try again later');
    }

    document.body.removeChild(eulaDocumentClone);
  }, [eulaData, toast]);

  const hasOutstandingEulas = useMemo(
    () => eulaData.outstanding,
    [eulaData.outstanding]
  );

  const navigateToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const handleAcceptEula = useCallback(
    async () =>
      executeHttpRequest({
        asyncFunction: async () => {
          await acceptEula();
          navigateToHome();
        },
        customErrorHandler: (_: AxiosError) => {
          setDisplayEulaAcceptanceError(true);
          if (isMobileDisplay) {
            toast.error(EULA_ACCEPTANCE_ERROR_MESSAGE);
          }
        },
      }),
    [executeHttpRequest, isMobileDisplay, navigateToHome, toast]
  );

  const handleDownloadEula = useCallback(async () => {
    setIsDownloadingEula(true);
    await downloadPdf();
    setIsDownloadingEula(false);
  }, [downloadPdf]);

  const AcceptEulaButton = useCallback(
    () => (
      <Button
        color="primary-dark"
        onClick={handleAcceptEula}
        isExecutingAction={isAcceptingEula}
      >
        <Button.LoadingIcon icon={FaCheck} />
        <span>I accept</span>
      </Button>
    ),
    [handleAcceptEula, isAcceptingEula]
  );

  const DeclineEulaButton = useCallback(
    () => (
      <Button color="primary-dark" outlined onClick={navigateToHome}>
        <Icon>
          <FaXmark />
        </Icon>
        <span>I decline</span>
      </Button>
    ),
    [navigateToHome]
  );

  return (
    <BoxLayout
      className={styles.box}
      header={
        <Element className={styles.header}>
          <Section paddingless>
            <If condition={!isMobileDisplay}>
              <BackButton onClick={navigateToHome} />
            </If>
            <h1 className={styles.title}>{HEADER_TITLE}</h1>
          </Section>
        </Element>
      }
      headerClassName={styles.headerWrapper}
    >
      <Conditional condition={hasOutstandingEulas}>
        <Conditional.True>
          <section>
            <section className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center mb-8">
              <h2 className={styles.instructionsText}>
                {EULA_INSTRUCTIONS_TEXT}
              </h2>
            </section>
            <Columns display="flex" flexDirection="column">
              <Columns.Column>
                <div
                  className={`${styles.eulaDocument} is-flex is-flex-direction-column is-align-items-center`}
                  ref={eulaDocumentRef}
                >
                  <h2 className="is-size-4 has-text-weight-light">
                    {eulaTitle}
                  </h2>
                  <div dangerouslySetInnerHTML={{ __html: eulaContent }} />
                </div>
              </Columns.Column>
              <Columns.Column>
                <Columns marginless>
                  <If condition={!isMobileDisplay}>
                    <Columns.Column>
                      <Section paddingless display="flex" mt={8}>
                        <Button
                          color="primary-dark"
                          outlined
                          onClick={handleDownloadEula}
                          isExecutingAction={isDownloadingEula}
                        >
                          <Button.LoadingIcon icon={FaDownload} />
                          <span>Download</span>
                        </Button>
                      </Section>
                    </Columns.Column>
                  </If>
                  <Columns.Column>
                    <Section className={styles.actionsWrapper}>
                      <Conditional condition={isMobileDisplay}>
                        <Conditional.True>
                          <AcceptEulaButton />
                          <DeclineEulaButton />
                        </Conditional.True>
                        <Conditional.False>
                          <If condition={displayEulaAcceptanceError}>
                            <FeedbackMessage>
                              {EULA_ACCEPTANCE_ERROR_MESSAGE}
                            </FeedbackMessage>
                          </If>
                          <DeclineEulaButton />
                          <AcceptEulaButton />
                        </Conditional.False>
                      </Conditional>
                    </Section>
                  </Columns.Column>
                </Columns>
              </Columns.Column>
            </Columns>
          </section>
        </Conditional.True>
        <Conditional.False>
          <section className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-9">
            <Icon className={styles.checkIcon}>
              <IoCheckmarkCircle />
            </Icon>
            <h2 className="is-size-4 has-text-weight-light">
              {NO_OUTSTANDING_EULAS_MESSAGE}
            </h2>
            <BackButton onClick={navigateToHome} />
          </section>
        </Conditional.False>
      </Conditional>
    </BoxLayout>
  );
}

export function AcceptEulaPage() {
  const { response } = useLoaderData() as DeferredResponse<EulaData>;

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <AcceptEulaPageContent />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default AcceptEulaPage;
