import { MAX_INTEGER_DIGITS } from '@zspace/format';
import { MinMaxInputType } from '@zspace/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Columns, Element, Form, Icon } from 'react-bulma-components';
import { FaSort } from 'react-icons/fa6';
import { Button } from '../../../../ui/button/button';
import FeedbackMessage from '../../../../ui/feedback-message/feedback-message';
import { FilterModalInput } from '../../filter-table-modal-input/filter-table-modal-input';
import styles from './min-max-input.module.scss';

const INVALID_MAX_VALUE_ERROR_MESSAGE = `Max value can't be lower that min value`;
const EQUAL_VALUES_ERROR_MESSAGE = `Min and max values can't be equal`;
const MAX_VALUE_ERROR_MESSAGE = `Min and max values can't have more than ${MAX_INTEGER_DIGITS} digits`;

export type MinMaxInputProps<T> = FilterModalInput<T> & {
  input: MinMaxInputType<T>;
};

export function MinMaxInput<T>({
  input: { maxValueKey, minValueKey, maxValue, minValue },
  name,
  localData,
  setLocalData,
  onValidateField,
}: MinMaxInputProps<T>) {
  const [showError, setShowError] = useState(false);
  const [inputTouched, setInputTouched] = useState(false);
  const [isMinInputActive, setIsMinInputActive] = useState(false);
  const [isMaxInputActive, setIsMaxInputActive] = useState(false);

  const showErrorMessage = useMemo(
    () => showError && inputTouched,
    [inputTouched, showError]
  );

  const errorMessage = useMemo(() => {
    if (
      (localData[maxValueKey] as string).length > MAX_INTEGER_DIGITS ||
      (localData[minValueKey] as string).length > MAX_INTEGER_DIGITS
    ) {
      return MAX_VALUE_ERROR_MESSAGE;
    }
    if (Number(localData[maxValueKey]) < Number(localData[minValueKey])) {
      return INVALID_MAX_VALUE_ERROR_MESSAGE;
    }
    if (localData[maxValueKey] === localData[minValueKey]) {
      return EQUAL_VALUES_ERROR_MESSAGE;
    }
  }, [localData, maxValueKey, minValueKey]);

  const calculateNewMinValue = useCallback(
    (newValue: number) => {
      const newMinValue = Math.max(newValue, minValue ?? 0);
      return Math.min(newMinValue, Number(localData[maxValueKey] || Infinity));
    },
    [localData, maxValueKey, minValue]
  );

  const calculateNewMaxValue = useCallback(
    (newValue: number) => {
      const newMaxValue = Math.min(Number(newValue), maxValue ?? Infinity);
      return Math.max(newMaxValue, Number(localData[minValueKey]));
    },
    [localData, maxValue, minValueKey]
  );

  const isValidInputValue = useCallback((value: string) => {
    return value !== '' && value.length <= MAX_INTEGER_DIGITS;
  }, []);

  const handleMinValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const eventValue = e.target.value;
      let newValue = eventValue;
      if (isValidInputValue(eventValue)) {
        newValue = calculateNewMinValue(Number(newValue)).toString();
      }
      setLocalData({
        ...localData,
        [minValueKey]: newValue,
      });
    },
    [
      calculateNewMinValue,
      isValidInputValue,
      localData,
      minValueKey,
      setLocalData,
    ]
  );

  const handleMaxValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const eventValue = e.target.value;
      let newValue = e.target.value;
      if (isValidInputValue(eventValue)) {
        newValue = calculateNewMaxValue(Number(newValue)).toString();
      }
      setLocalData({
        ...localData,
        [maxValueKey]: newValue,
      });
    },
    [
      calculateNewMaxValue,
      isValidInputValue,
      localData,
      maxValueKey,
      setLocalData,
    ]
  );

  const handleOnBlur = useCallback(() => {
    setInputTouched(true);
  }, []);

  const handleIncrementValue = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, valueKey: keyof T) => {
      let newValue = Number(localData[valueKey]);
      const rect = (event.target as HTMLElement).getBoundingClientRect();
      const mouseY = event.clientY - rect.top;

      const iconHeight = rect.height;
      const relativePosition = mouseY / iconHeight;

      if (relativePosition < 0.5) {
        newValue += 1;
      } else {
        newValue -= 1;
      }
      newValue =
        valueKey === minValueKey
          ? calculateNewMinValue(newValue)
          : calculateNewMaxValue(newValue);
      setLocalData({
        ...localData,
        [valueKey]: newValue.toString(),
      });
    },
    [
      calculateNewMaxValue,
      calculateNewMinValue,
      localData,
      minValueKey,
      setLocalData,
    ]
  );

  const handleIncrementMinValue = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      handleIncrementValue(event, minValueKey);
    },
    [handleIncrementValue, minValueKey]
  );

  const handleIncrementMaxValue = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      handleIncrementValue(event, maxValueKey);
      setInputTouched(true);
    },
    [handleIncrementValue, maxValueKey]
  );

  useEffect(() => {
    const maxValue = localData[maxValueKey];
    const minValue = localData[minValueKey];
    const maxValueIsGreaterThanMinValue = Number(maxValue) > Number(minValue);
    const maxValueIsEmpty = maxValue === '';
    const minValueIsEmpty = minValue === '';
    const maxValueIsLessThanMaxIntegerDigits =
      (maxValue as string).length <= MAX_INTEGER_DIGITS;
    const minValueIsLessThanMaxIntegerDigits =
      (minValue as string).length <= MAX_INTEGER_DIGITS;

    const isValid =
      (maxValueIsGreaterThanMinValue || maxValueIsEmpty || minValueIsEmpty) &&
      maxValueIsLessThanMaxIntegerDigits &&
      minValueIsLessThanMaxIntegerDigits;
    setShowError(!isValid);
    onValidateField?.(name, isValid);
  }, [localData, maxValueKey, minValueKey, name, onValidateField]);

  return (
    <Element>
      <Columns marginless display="flex" className="gap-6">
        <Columns.Column paddingless>
          <Form.Field>
            <Form.Control
              onFocus={() => setIsMinInputActive(true)}
              onBlur={() => setIsMinInputActive(false)}
              onMouseEnter={() => setIsMinInputActive(true)}
              onMouseLeave={() => setIsMinInputActive(false)}
            >
              <Form.Input
                id={name}
                className="placeholder-text-gray"
                type="number"
                min={minValue ?? 0}
                max={localData[maxValueKey] as number}
                value={localData[minValueKey]}
                onChange={handleMinValueChange}
                onBlur={handleOnBlur}
                placeholder="Min"
              />
              <Button
                className={styles.buttonContainer}
                text
                type="button"
                onClick={handleIncrementMinValue}
                tabIndex={-1}
              >
                <Icon align="right" className={styles.iconContainer}>
                  <FaSort
                    className={
                      isMinInputActive
                        ? styles.chevronDownActive
                        : styles.chevronDown
                    }
                  />
                </Icon>
              </Button>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column paddingless>
          <Form.Field>
            <Form.Control
              onFocus={() => setIsMaxInputActive(true)}
              onBlur={() => setIsMaxInputActive(false)}
              onMouseEnter={() => setIsMaxInputActive(true)}
              onMouseLeave={() => setIsMaxInputActive(false)}
            >
              <Form.Input
                className="placeholder-text-gray"
                type="number"
                value={localData[maxValueKey]}
                min={localData[minValueKey] as number}
                max={maxValue ?? Infinity}
                onChange={handleMaxValueChange}
                onBlur={handleOnBlur}
                placeholder="Max"
              />
              <Button
                className={styles.buttonContainer}
                text
                type="button"
                onClick={handleIncrementMaxValue}
                tabIndex={-1}
              >
                <Icon align="right">
                  <FaSort
                    className={
                      isMaxInputActive
                        ? styles.chevronDownActive
                        : styles.chevronDown
                    }
                  />
                </Icon>
              </Button>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>
      <FeedbackMessage>{showErrorMessage && errorMessage}</FeedbackMessage>
    </Element>
  );
}

export default MinMaxInput;
