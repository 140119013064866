import { FilterOption, filter } from '@zspace/data';
import { capitalizeString, formatSalesOrderNumber } from '@zspace/format';
import {
  AllYesNoFilter,
  Criteria,
  Device,
  DeviceGroupsCriteria,
  DeviceGroupsCriteriaFilterDataType,
  FilterType,
  HardwareModel,
  PaginatedContentConfig,
} from '@zspace/types';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaCheck, FaFilter } from 'react-icons/fa6';
import { PiWarningFill } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import Conditional from '../../shared/conditional/conditional';
import useIsCustomerUser from '../../shared/hooks/is-customer-user';
import useSalesOrderPermissions from '../../shared/hooks/sales-order-permissions';
import { removeDuplicates } from '../../shared/utils';
import Button from '../../ui/button/button';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PaginatedTable, {
  FormProps,
} from '../../ui/table/paginated-table/paginated-table';
import { Column, OnTableChangeData, RowSelection } from '../../ui/table/types';
import DeviceGroupsFilterModal from '../filter-modals/device-groups-filter-modal/device-groups-filter-modal';
import { filterDevices } from '../filter-modals/filters';

const NO_SOFTWARE_ASSIGNED = 'No software assigned yet';

const filterOptions: FilterOption<Device>[] = [
  {
    getter: (el) => el.serialNumber?.number ?? el.name ?? el.defaultName,
    searchable: true,
  },
  {
    getter: (el) => formatSalesOrderNumber(el.salesOrderLine.salesOrder.number),
    searchable: true,
  },
  {
    getter: (el) => el.name ?? el.defaultName,
    searchable: true,
  },
  {
    getter: (el) => el.model.displayName,
    searchable: true,
  },
  {
    getter: (el) =>
      el.assignments
        .filter((assignment) => assignment.softwareSeat)
        .map(
          (assignment) =>
            assignment.softwareSeat.licensingProductGroup.displayName
        )
        .join(', '),
    searchable: true,
  },
];

const initialDeviceGroupsCriteria: DeviceGroupsCriteriaFilterDataType = {
  deviceType: HardwareModel.ALL,
  softwareAssigned: AllYesNoFilter.ALL,
  softwareTitle: '',
  softwareTitleFilter: FilterType.CONTAINS,
  deviceName: '',
  deviceNameFilter: FilterType.CONTAINS,
  salesOrders: [],
  serialNumber: '',
  serialNumberFilter: FilterType.CONTAINS,
};

export type DeviceGroupTableProps = {
  data: Device[];
  selectedDevices?: Device[];
  selectedSoftware?: string[];
  onManageDeviceClick?: (devices: Device[], add: boolean) => void;
  disableSearch?: boolean;
  disableFilter?: boolean;
  isInvalidSelection?: (devices: Device[]) => boolean;
  showCompleted?: boolean;
  isDataFiltered?: boolean;
  setFilters?: Dispatch<SetStateAction<ReactNode>>;
};

const INITIAL_ITEMS_PER_PAGE = 50;
const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 50];

export function DeviceGroupTable({
  data,
  selectedDevices,
  onManageDeviceClick,
  selectedSoftware = [],
  disableSearch = false,
  disableFilter = false,
  isInvalidSelection = () => false,
  showCompleted = false,
  isDataFiltered = false,
  setFilters,
}: DeviceGroupTableProps) {
  const isCustomerUser = useIsCustomerUser();

  const {
    userHasSalesOrderManagePermissions,
    userIsJustViewOnlySalesOrderManager,
  } = useSalesOrderPermissions();

  const [tableCriteria, setTableCriteria] = useState<Criteria>({
    itemsPerPage: INITIAL_ITEMS_PER_PAGE,
    pageNumber: 1,
    search: '',
    sortBy: '',
  });
  const [filterCriteria, setFilterCriteria] =
    useState<DeviceGroupsCriteriaFilterDataType>(initialDeviceGroupsCriteria);
  const [isFilterModalVisible, setIsFilterModalVisible] =
    useState<boolean>(false);

  const tableData = useMemo(() => {
    // Filter by modal fields
    const modalFilteredData = filterDevices(data, filterCriteria);
    // Filter by table columns
    const columnFilteredData = filter(
      modalFilteredData,
      tableCriteria,
      filterOptions
    );
    if (isCustomerUser && !userIsJustViewOnlySalesOrderManager) {
      return columnFilteredData.sort((deviceA, deviceB) => {
        const canManageDeviceA = userHasSalesOrderManagePermissions(
          deviceA.salesOrderLine.salesOrder.id
        );
        const canManageDeviceB = userHasSalesOrderManagePermissions(
          deviceB.salesOrderLine.salesOrder.id
        );
        if (canManageDeviceA && !canManageDeviceB) return -1;
        if (canManageDeviceA && canManageDeviceB) return 0;
        return 1;
      });
    }
    return columnFilteredData;
  }, [
    data,
    filterCriteria,
    isCustomerUser,
    tableCriteria,
    userHasSalesOrderManagePermissions,
    userIsJustViewOnlySalesOrderManager,
  ]);

  const tablePageData = useMemo(() => {
    return tableData.slice(
      (tableCriteria.pageNumber - 1) * tableCriteria.itemsPerPage,
      tableCriteria.pageNumber * tableCriteria.itemsPerPage
    );
  }, [tableCriteria.itemsPerPage, tableCriteria.pageNumber, tableData]);

  const deviceTypeOptions = useMemo(() => {
    const deviceTypes = data.map((device) => ({
      label: device.model.displayName,
      value: device.model.displayName,
    }));
    return deviceTypes.filter(
      (device, index) =>
        deviceTypes.findIndex((d) => d.value === device.value) === index
    );
  }, [data]);

  const filterTagsData = useMemo(() => {
    return {
      deviceType: {
        label: 'Device type',
        value:
          filterCriteria.deviceType !== HardwareModel.ALL
            ? capitalizeString(filterCriteria.deviceType)
            : '',
      },
      softwareAssigned: {
        label: 'Has software assigned',
        value:
          filterCriteria.softwareAssigned !== AllYesNoFilter.ALL
            ? capitalizeString(filterCriteria.softwareAssigned)
            : '',
      },
      softwareTitle: {
        label: 'Assigned software title',
        value: filterCriteria.softwareTitle.length
          ? filterCriteria.softwareTitleFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', filterCriteria.softwareTitle)
          : '',
      },
      deviceName: {
        label: 'Device name',
        value: filterCriteria.deviceName
          ? filterCriteria.deviceNameFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', filterCriteria.deviceName)
          : '',
      },
      salesOrders: {
        label: 'Sales order #',
        value: filterCriteria.salesOrders.join(', '),
      },
    };
  }, [filterCriteria]);

  const isDisabledRow = useCallback(
    (row: Device) => {
      if (isCustomerUser && !userIsJustViewOnlySalesOrderManager) {
        return !userHasSalesOrderManagePermissions(
          row.salesOrderLine.salesOrder.id
        );
      }
      return false;
    },
    [
      isCustomerUser,
      userHasSalesOrderManagePermissions,
      userIsJustViewOnlySalesOrderManager,
    ]
  );

  const handleTableFilterSubmit = useCallback(
    (value: DeviceGroupsCriteriaFilterDataType): void => {
      setFilterCriteria(value);
      setTableCriteria((oldCriteria) => ({
        ...oldCriteria,
        pageNumber: 1,
      }));
    },
    []
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof DeviceGroupsCriteriaFilterDataType) => {
      const newData = { ...filterCriteria };
      newData[filterDataKey] = initialDeviceGroupsCriteria[
        filterDataKey
      ] as string & string[] & boolean;
      handleTableFilterSubmit(newData);
    },
    [filterCriteria, handleTableFilterSubmit]
  );

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const tableForm = useMemo(() => {
    const form: FormProps = {
      debounceMillis: 0,
      disableSearch: disableSearch,
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
    };
    if (!disableFilter) {
      form.extra = (
        <Button
          type="button"
          color="primary-dark"
          className="ml-2"
          outlined={appliedFilters.length === 0}
          onClick={() => setIsFilterModalVisible(true)}
        >
          <Icon>
            <FaFilter />
          </Icon>
          <span>
            Filter
            {appliedFilters.length > 0 && `(${appliedFilters.length})`}
          </span>
        </Button>
      );
    }
    return form;
  }, [appliedFilters.length, disableFilter, disableSearch]);

  const tableConfig = useMemo(() => {
    return {
      ...tableCriteria,
      pages: Math.ceil(tableData.length / tableCriteria.itemsPerPage),
    };
  }, [tableCriteria, tableData.length]);

  useEffect(() => {
    if (appliedFilters.length > 0) {
      setFilters?.(
        <FilterTagList
          list={filterTagsData}
          onRemove={(item: string) =>
            onRemoveFilterTag(item as keyof DeviceGroupsCriteriaFilterDataType)
          }
          title="Filters"
        />
      );
    } else {
      setFilters?.(null);
    }
  }, [appliedFilters.length, filterTagsData, onRemoveFilterTag, setFilters]);

  const columns: Column<Device>[] = useMemo(
    () => [
      {
        key: 'serialNumber.number',
        widthClassname: 'is-size-20',
        render: (el) => {
          const showError = isInvalidSelection([el]);
          let deviceIdentifier = el.serialNumber?.number;
          if (!deviceIdentifier) {
            deviceIdentifier = el.name ?? el.defaultName;
          }
          return (
            <>
              {showError && (
                <PiWarningFill
                  className="has-text-danger-dark"
                  style={{ verticalAlign: 'text-bottom' }}
                />
              )}
              {deviceIdentifier}
              {showCompleted &&
                el.assignments.some(
                  (assignment) => assignment.softwareSeat
                ) && (
                  <Icon className="has-text-success-dark">
                    <FaCheck />
                  </Icon>
                )}
            </>
          );
        },
        title: 'Identifier',
        sortable: false,
        noWrap: true,
      },
      {
        key: 'salesOrderLine.salesOrder.number',
        widthClassname: 'is-size-15',
        render: (el) => (
          <Conditional condition={isDisabledRow(el)}>
            <Conditional.True>
              <span className="is-underlined">
                {formatSalesOrderNumber(el.salesOrderLine.salesOrder.number)}
              </span>
            </Conditional.True>
            <Conditional.False>
              <Link to={`/sales-orders/${el.salesOrderLine.salesOrder.id}`}>
                {formatSalesOrderNumber(el.salesOrderLine.salesOrder.number)}
              </Link>
            </Conditional.False>
          </Conditional>
        ),
        title: 'Sales order',
        sortable: true,
      },
      {
        key: 'name',
        widthClassname: 'is-size-15',
        render: (el) => el.name ?? el.serialNumber?.number ?? el.defaultName,
        title: 'Device name',
        sortable: false,
      },
      {
        key: 'model.name',
        widthClassname: 'is-size-20',
        render: (el) => el.model.displayName,
        title: 'Device type',
        noWrap: true,
      },
      {
        key: 'software',
        widthClassname: 'is-size-25',
        render: (el) => {
          const assignedSoftwareTitles = el.assignments
            .filter((assignment) => assignment.softwareSeat)
            .map(
              (assignment) =>
                assignment.softwareSeat.licensingProductGroup.displayName
            );
          if (
            assignedSoftwareTitles.length === 0 &&
            selectedSoftware.length === 0
          )
            return NO_SOFTWARE_ASSIGNED;
          return removeDuplicates([
            ...selectedSoftware,
            ...assignedSoftwareTitles,
          ]).join(', ');
        },
        title: 'Assigned software',
      },
    ],
    [isDisabledRow, isInvalidSelection, selectedSoftware, showCompleted]
  );

  const rowSelection: RowSelection | undefined = useMemo(() => {
    let hasDevicesManagerPermission = true;
    if (isCustomerUser && !userIsJustViewOnlySalesOrderManager) {
      hasDevicesManagerPermission = tableData.some((device) =>
        userHasSalesOrderManagePermissions(device.salesOrderLine.salesOrder.id)
      );
    }
    if (selectedDevices && onManageDeviceClick && hasDevicesManagerPermission) {
      return {
        onChange: (selectedRowIds) => {
          selectedRowIds.forEach((id) => {
            const foundRow = selectedDevices.find((sd) => sd.id === id);

            if (foundRow) {
              onManageDeviceClick([foundRow], false);
            } else {
              const newSelectedDevice =
                tableData.find((device) => device.id === id) || ({} as Device);
              onManageDeviceClick([newSelectedDevice], true);
            }
          });
        },
        selectedRowKeys: tableData
          .filter((tableDataDevice) =>
            selectedDevices
              .map((selectedDevice) => selectedDevice.id)
              .includes(tableDataDevice.id)
          )
          .map((d) => d.id),
      };
    }
  }, [
    isCustomerUser,
    onManageDeviceClick,
    selectedDevices,
    tableData,
    userHasSalesOrderManagePermissions,
    userIsJustViewOnlySalesOrderManager,
  ]);

  const handleTableChange = (value: OnTableChangeData<Device>) => {
    const newTableCriteria: PaginatedContentConfig = {
      ...tableCriteria,
      ...value.config,
      sortBy: value.column?.key || tableCriteria.sortBy,
    };

    setTableCriteria(newTableCriteria);
  };

  const emptyContent = useMemo(() => {
    const emptyContentText =
      appliedFilters.length > 0 || isDataFiltered || tableCriteria.search !== ''
        ? 'There are no devices that match the selected criteria'
        : 'There are no devices at the moment';
    return (
      <Element display="flex" justifyContent="center" className="my-2">
        <span className="has-text-weight-light">{emptyContentText}</span>
      </Element>
    );
  }, [appliedFilters.length, isDataFiltered, tableCriteria.search]);

  return (
    <>
      <PaginatedTable
        form={tableForm}
        dataSource={tablePageData}
        columns={columns}
        onChange={handleTableChange}
        config={tableConfig}
        rowKey={'id'}
        rowSelection={rowSelection}
        isInvalidSelection={isInvalidSelection}
        isDisabledSelection={isDisabledRow}
        empty={emptyContent}
      />
      <DeviceGroupsFilterModal
        show={isFilterModalVisible}
        onSubmit={handleTableFilterSubmit}
        onClose={() => setIsFilterModalVisible(false)}
        data={filterCriteria as DeviceGroupsCriteria}
        deviceTypeOptions={deviceTypeOptions}
      />
    </>
  );
}

export default DeviceGroupTable;
