import { WorkOrder } from '@zspace/types';
import { useCallback } from 'react';
import { Icon } from 'react-bulma-components';
import { FaArrowRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';

export interface WorkOrderCreatedConfirmationModalProps {
  show: boolean;
  workOrder?: WorkOrder;
}

export function WorkOrderCreatedConfirmationModal({
  show,
  workOrder,
}: WorkOrderCreatedConfirmationModalProps) {
  const navigate = useNavigate();

  const navigateToDrafts = useCallback(() => {
    navigate('/work-orders/dashboard/drafts');
  }, [navigate]);

  const navigateToCreateNewOrder = useCallback(() => {
    navigate('/work-orders/create/sales-orders');
  }, [navigate]);

  const navigateToSetUp = useCallback(() => {
    navigate(`/work-orders/${workOrder?.id}/device-groups`);
  }, [navigate, workOrder]);

  return (
    <Modal show={show}>
      <h1 className="has-text-weight-light is-size-4 mb-4">
        Work order created
      </h1>
      <p>
        You have successfully created work order{' '}
        <span className="has-text-weight-bold">
          #{workOrder?.number} - {workOrder?.name}
        </span>
      </p>
      <div className="is-flex flex-direction-column is-align-items-center is-justify-content-space-between mt-8">
        <div>
          <Button color="primary-dark" outlined onClick={navigateToDrafts}>
            Back to drafts
          </Button>
          <Button
            color="primary-dark"
            outlined
            ml={3}
            onClick={navigateToCreateNewOrder}
          >
            Create a new work order
          </Button>
        </div>
        <Button color="primary-dark" onClick={navigateToSetUp}>
          <span>Set up</span>
          <Icon>
            <FaArrowRight />
          </Icon>
        </Button>
      </div>
    </Modal>
  );
}

export default WorkOrderCreatedConfirmationModal;
