import { useCallback, useEffect, useState } from 'react';
import { Element, Form, Icon } from 'react-bulma-components';
import { FaCheck, FaPencil, FaSort, FaXmark } from 'react-icons/fa6';
import Button from '../button/button';
import styles from './numeric-input.module.scss';

type NumericInputProps = {
  value: number;
  onChange: (value: number) => void;
  disabled: boolean;
};

const NumericInput = ({
  value: initialValue,
  onChange,
  disabled,
}: NumericInputProps) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const handleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(e.target.value);
      if (newValue > 0) {
        setValue(newValue);
      }
    },
    [setValue]
  );

  const handleSaveValueUpdate = useCallback(() => {
    if (!disabled) {
      onChange(value);
      setIsEditing(false);
    }
  }, [disabled, onChange, value]);

  const handleIncrementPriority = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!disabled) {
        const rect = (event.target as HTMLElement).getBoundingClientRect();
        const mouseY = event.clientY - rect.top;

        const iconHeight = rect.height;
        const relativePosition = mouseY / iconHeight;

        if (relativePosition < 0.5) {
          setValue((prevPriority) => prevPriority + 1);
        } else {
          if (value > 1) {
            setValue((prevPriority) => prevPriority - 1);
          }
        }
      }
    },
    [disabled, value]
  );

  const handleCloseEditing = useCallback(() => {
    setIsEditing(false);
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Element display="flex" alignItems="center">
      {isEditing ? (
        <>
          <Form.Field className={styles.field} marginless>
            <Form.Control>
              <Form.Input
                type="number"
                min={1}
                value={value}
                onChange={handleValueChange}
                disabled={disabled}
              />
            </Form.Control>
            <Element
              className={styles.button}
              onClick={handleIncrementPriority}
            >
              <FaSort />
            </Element>
          </Form.Field>
          <Button
            text
            textColor="success"
            p={2}
            onClick={handleSaveValueUpdate}
          >
            <Icon>
              <FaCheck />
            </Icon>
          </Button>
          <Button
            text
            textColor="danger-dark"
            p={2}
            onClick={handleCloseEditing}
          >
            <Icon>
              <FaXmark />
            </Icon>
          </Button>
        </>
      ) : (
        <>
          <Button
            textColor="primary"
            text
            pl={2}
            pr={1}
            onClick={() => setIsEditing(true)}
          >
            <FaPencil />
          </Button>
          <span className="has-text-primary">{initialValue}</span>
        </>
      )}
    </Element>
  );
};

export default NumericInput;
