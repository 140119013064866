import { ReactNode, useMemo } from 'react';
import { Element } from 'react-bulma-components';
import { FaCircleInfo } from 'react-icons/fa6';
import { PiWarningFill } from 'react-icons/pi';
import styles from './field-error-message.module.scss';

type DivProps = React.HTMLProps<HTMLDivElement>;
export type FeedbackMessageProps = DivProps & {
  type?: 'error' | 'info';
  textClassName?: string;
  children?: ReactNode;
};

export function FeedbackMessage({
  className,
  children,
  textClassName,
  type = 'error',
  ...rest
}: FeedbackMessageProps) {
  const feedbackTypeStyles = useMemo(() => {
    let className = '';
    let icon = null;

    switch (type) {
      case 'error':
        className = 'has-text-danger-dark';
        icon = <PiWarningFill />;
        break;
      case 'info':
        className = 'has-text-black-ter';
        icon = <FaCircleInfo className="has-text-info-dark" />;
        break;
    }

    return { className, icon };
  }, [type]);

  const containerClassName = useMemo(() => {
    const baseClassName = `is-flex flex-direction-column is-align-items-center mt-1`;
    const typeClassName = feedbackTypeStyles.className;

    return `${baseClassName} ${typeClassName} ${styles.container} ${className}`;
  }, [className, feedbackTypeStyles.className]);

  return (
    <div
      style={{ opacity: children ? '1' : '0' }}
      className={containerClassName}
      {...rest}
    >
      <Element mr={2} display="flex">
        {feedbackTypeStyles.icon}
      </Element>
      <span className={`${styles.text} ${textClassName}`}>{children}</span>
    </div>
  );
}

export default FeedbackMessage;
