import { createContext, ReactNode, useState } from 'react';

export enum GuidedProcessRefreshAction {
  ALL = 'ALL',
  SALES_ORDERS_AND_EULA = 'SALES_ORDERS_AND_EULA',
  DEVICES = 'DEVICES',
  SOFTWARE = 'SOFTWARE',
  WORK_ORDERS = 'WORK_ORDERS',
}

export type GuidedProcessContextProps = {
  refreshAction?: {
    action: GuidedProcessRefreshAction;
    timestamp: number;
  };
  setRefreshAction: (action: GuidedProcessRefreshAction) => void;
};

export const GuidedProcessContext = createContext<GuidedProcessContextProps>(
  {} as GuidedProcessContextProps
);

export type GuidedProcessProviderProps = {
  children: ReactNode;
};

export const GuidedProcessProvider = ({
  children,
}: GuidedProcessProviderProps) => {
  const [refreshAction, setAction] = useState<{
    action: GuidedProcessRefreshAction;
    timestamp: number;
  }>();

  const setRefreshAction = (action: GuidedProcessRefreshAction) => {
    setAction({ action, timestamp: Date.now() });
  };

  return (
    <GuidedProcessContext.Provider value={{ setRefreshAction, refreshAction }}>
      {children}
    </GuidedProcessContext.Provider>
  );
};
