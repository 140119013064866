export const SELECTED_SALES_ORDERS_STORAGE_KEY = 'selectedSalesOrders';
export const WORK_ORDER_DETAILS_STORAGE_KEY = 'workOrderDetailsFormData';
export const WORK_ORDER_GROUP_SOFTWARE_STORAGE_KEY = 'selectedSoftware';
export const DEVICE_FOR_SOFTWARE_ASSIGNMENT_STORAGE_KEY =
  'deviceForSoftwareAssignments';
export const SOFTWARE_TITLE_FOR_ASSIGNMENT_STORAGE_KEY =
  'softwareForAssignments';
export const USER_INVITATION_STORAGE_KEY = 'userInvitationEmail';

export interface SessionStorageService<T> {
  getItem: (key: string) => T;
  setItem: (key: string, item: T) => void;
  removeItem: (key: string) => void;
}

export class SessionStorageServiceImpl<T> implements SessionStorageService<T> {
  public getItem(key: string) {
    const itemFromStorage = sessionStorage.getItem(key);
    const item = itemFromStorage ? JSON.parse(itemFromStorage) : null;
    return item as T;
  }
  public setItem(key: string, item: T) {
    sessionStorage.setItem(key, JSON.stringify(item));
  }
  public removeItem(key: string) {
    sessionStorage.removeItem(key);
  }
}
