import { Columns, Element, Icon } from 'react-bulma-components';
import { FaTrash } from 'react-icons/fa6';
import useIsCustomerUser from '../../shared/hooks/is-customer-user';
import If from '../../shared/if/if';
import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';

export type DeviceGroupConfirmDeletionModalProps = {
  show: boolean;
  deviceGroupName: string;
  loading: boolean;
  onCancel: () => void;
  onDelete: () => void;
};

export function DeviceGroupConfirmDeletionModal({
  show,
  deviceGroupName,
  loading,
  onCancel,
  onDelete,
}: DeviceGroupConfirmDeletionModalProps) {
  const isCustomerUser = useIsCustomerUser();

  return (
    <Modal show={show}>
      <h1 className="is-size-4 has-text-weight-light">Delete device group</h1>
      <Element renderAs="p" my={4}>
        Are you sure you want to delete
        <span className="has-text-weight-bold"> {deviceGroupName}?</span>
      </Element>
      <If condition={!isCustomerUser}>
        <span>
          Please note that deleting a device group will also cancel all software
          assignments for devices in that group
        </span>
      </If>
      <Columns mt={4}>
        <Columns.Column>
          <Button color="primary-dark" outlined onClick={onCancel}>
            No, cancel
          </Button>
        </Columns.Column>
        <Columns.Column display="flex" justifyContent="flex-end">
          <Button
            color="danger-dark"
            loading={loading}
            isExecutingAction={loading}
            onClick={onDelete}
          >
            <Icon>
              <FaTrash />
            </Icon>
            <span>Yes, delete device group</span>
          </Button>
        </Columns.Column>
      </Columns>
    </Modal>
  );
}

export default DeviceGroupConfirmDeletionModal;
