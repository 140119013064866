import {
  PermissionsGroupUserRoleAssignment,
  User,
  UserRoleAssignment,
  UserRoleType,
} from '@zspace/types';
import {
  ALL_CUSTOMER_MANAGER_PERMISSIONS_GROUPS,
  ALL_PARTNER_PERMISSIONS_GROUPS,
  ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS,
  ALL_ZSPACE_INTERNAL_PERMISSIONS_GROUPS,
  ALL_ZSPACE_STAFF_AND_PARTNER_PERMISSIONS_GROUPS,
  ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS,
  billingContactPermissions,
  customerSuccessPermissions,
  endUserSoftwareContactPermissions,
  itAdminPermissions,
  Permissions,
  PermissionsGroupName,
  salesOrderManagerPermissions,
  SalesOrderPermissions,
  shippingContactPermissions,
  superAdminPermissions,
  supportStandardPermissions,
  SYSTEM_DEFINED_PERMISSIONS_GROUPS,
  temporarySalesOrderManagerPermissions,
  viewOnlySalesOrderManagerPermissions,
  warehousePartnerPermissions,
  workOrderCreatorPermissions,
  workOrderManagerPermissions,
} from './types';

export function getPermissionsGroupPermissions(
  permissionsGroupName: PermissionsGroupName
) {
  let permissions: Permissions[] = [];

  function toCamelCase(input: string): string {
    return input
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (_, character) => character.toUpperCase());
  }

  const role = toCamelCase(permissionsGroupName);

  switch (role) {
    case 'superAdmin':
      permissions = superAdminPermissions;
      break;
    case 'itAdmin':
      permissions = itAdminPermissions;
      break;
    case 'supportStandard':
      permissions = supportStandardPermissions;
      break;
    case 'customerSuccess':
      permissions = customerSuccessPermissions;
      break;
    case 'workOrderCreator':
      permissions = workOrderCreatorPermissions;
      break;
    case 'workOrderManager':
      permissions = workOrderManagerPermissions;
      break;
    case 'warehousePartner':
      permissions = warehousePartnerPermissions;
      break;
    case 'endUserSoftwareContact':
      permissions = endUserSoftwareContactPermissions;
      break;
    case 'billingContact':
      permissions = billingContactPermissions;
      break;
    case 'shippingContact':
      permissions = shippingContactPermissions;
      break;
    case 'salesOrderManager':
      permissions = salesOrderManagerPermissions;
      break;
    case 'viewOnlySalesOrderManager':
      permissions = viewOnlySalesOrderManagerPermissions;
      break;
    case 'temporarySalesOrderManager':
      permissions = temporarySalesOrderManagerPermissions;
      break;
  }

  return permissions;
}

export function hasPermissions(
  user: User,
  requiredPermissions: Permissions[],
  salesOrderIdsRelatedPermissions?: string[],
  allowedPermissionGroups?: PermissionsGroupName[]
): boolean {
  const userActiveRoleAssignment = user.activeRole;

  if (!userActiveRoleAssignment) {
    return false;
  }

  let userActiveRolePermissionsGroupAssignments =
    userActiveRoleAssignment.permissionsGroupUserRoleAssignments;

  if (allowedPermissionGroups) {
    userActiveRolePermissionsGroupAssignments =
      userActiveRolePermissionsGroupAssignments.filter(
        (permissionGroupUserRoleAssignment) =>
          allowedPermissionGroups.includes(
            permissionGroupUserRoleAssignment.permissionsGroup
              .name as PermissionsGroupName
          )
      );
  }

  if (
    salesOrderIdsRelatedPermissions &&
    salesOrderIdsRelatedPermissions.length > 0
  ) {
    userActiveRolePermissionsGroupAssignments =
      userActiveRolePermissionsGroupAssignments.filter(
        (permissionsGroupUserRoleAssignment) =>
          salesOrderIdsRelatedPermissions.includes(
            permissionsGroupUserRoleAssignment.salesOrder?.id as string
          )
      );
  }

  const userRolesPermissions =
    userActiveRolePermissionsGroupAssignments.flatMap(
      (permissisionsGroupUserRoleAssignment) =>
        getPermissionsGroupPermissions(
          permissisionsGroupUserRoleAssignment.permissionsGroup
            .name as PermissionsGroupName
        )
    );

  return requiredPermissions.every((permission) =>
    userRolesPermissions.includes(permission)
  );
}

export function getUserAllowedZspaceInternalPermissionsGroupToManage(
  assignments: PermissionsGroupUserRoleAssignment[]
) {
  const userPermissionsGroups = assignments.map(
    (permissionsGroupUserRoleAssignment) =>
      permissionsGroupUserRoleAssignment.permissionsGroup
        .name as PermissionsGroupName
  );

  if (!userPermissionsGroups) {
    return [];
  }

  const userHighestZspaceStaffPermissionsGroup =
    ALL_ZSPACE_STAFF_AND_PARTNER_PERMISSIONS_GROUPS.findIndex(
      (permissionsGroup) => userPermissionsGroups.includes(permissionsGroup)
    );
  switch (
    ALL_ZSPACE_STAFF_AND_PARTNER_PERMISSIONS_GROUPS[
      userHighestZspaceStaffPermissionsGroup
    ]
  ) {
    case PermissionsGroupName.SUPER_ADMIN:
      return ALL_ZSPACE_STAFF_AND_PARTNER_PERMISSIONS_GROUPS;
    case PermissionsGroupName.IT_ADMIN:
    case PermissionsGroupName.SUPPORT_STANDARD:
      return ALL_ZSPACE_STAFF_AND_PARTNER_PERMISSIONS_GROUPS.slice(
        userHighestZspaceStaffPermissionsGroup + 1
      );
  }
  return [];
}

export function getUserAllowedSalesOrderRelatedPermissionsGroupToManage(
  assignments: PermissionsGroupUserRoleAssignment[]
) {
  const userPermissionsGroups = assignments.map(
    (permissionsGroupUserRoleAssignment) =>
      permissionsGroupUserRoleAssignment.permissionsGroup
        .name as PermissionsGroupName
  );

  if (!userPermissionsGroups) {
    return [];
  }

  const userHighestCustomerPermissionsGroup =
    ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS.findIndex((permissionsGroup) =>
      userPermissionsGroups.includes(permissionsGroup)
    );
  switch (
    ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS[
      userHighestCustomerPermissionsGroup
    ]
  ) {
    case PermissionsGroupName.END_USER_SOFTWARE_CONTACT:
      return [
        PermissionsGroupName.SALES_ORDER_MANAGER,
        PermissionsGroupName.VIEW_ONLY_SALES_ORDER_MANAGER,
        PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER,
      ];
    case PermissionsGroupName.SALES_ORDER_MANAGER:
      return [
        PermissionsGroupName.VIEW_ONLY_SALES_ORDER_MANAGER,
        PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER,
      ];
  }
  return [];
}

export function getUserAllowedPermissionsGroupsToManage(
  user: User
): PermissionsGroupName[] {
  const userPermissionsGroups =
    user.activeRole?.permissionsGroupUserRoleAssignments;

  if (!userPermissionsGroups) {
    return [];
  }

  let allowedPermissionsGroupToManage: PermissionsGroupName[] = [];

  switch (user.activeRole!.role.name) {
    case UserRoleType.PARTNER:
      allowedPermissionsGroupToManage = [
        PermissionsGroupName.WAREHOUSE_PARTNER,
      ];
      break;
    case UserRoleType.ZSPACE_INTERNAL:
      allowedPermissionsGroupToManage =
        getUserAllowedZspaceInternalPermissionsGroupToManage(
          userPermissionsGroups
        );
      break;
    case UserRoleType.CUSTOMER:
      allowedPermissionsGroupToManage =
        getUserAllowedSalesOrderRelatedPermissionsGroupToManage(
          userPermissionsGroups
        );
      break;
  }

  return allowedPermissionsGroupToManage;
}

export function validSalesOrderManageRole(roleName: PermissionsGroupName) {
  return (
    roleName === PermissionsGroupName.SALES_ORDER_MANAGER ||
    roleName === PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER ||
    roleName === PermissionsGroupName.END_USER_SOFTWARE_CONTACT
  );
}

export function userHasPermissionToViewSalesOrderSummary(
  user: User,
  salesOrderId: string
) {
  if (!user.activeRole) return false;

  const isActiveRoleCustomer =
    user.activeRole.role.name === UserRoleType.CUSTOMER;

  if (isActiveRoleCustomer) {
    return user.activeRole.permissionsGroupUserRoleAssignments.some(
      (permissionsGroupAssignment) =>
        permissionsGroupAssignment.salesOrder?.id === salesOrderId
    );
  } else {
    return hasPermissions(user, [
      SalesOrderPermissions.SALES_ORDERS_SUMMARY_READ,
    ]);
  }
}

export function getUserHighestPermissionsGroupUserRoleAssignment(
  user: User,
  userRoleType?: UserRoleType
) {
  const userActiveRole =
    user.activeRole ??
    user.roles.find(
      (roleAssignment) =>
        roleAssignment.active || roleAssignment.role.name === userRoleType
    );

  if (!userActiveRole) {
    return;
  }

  const userActiveGroupPermissions =
    userActiveRole.permissionsGroupUserRoleAssignments.map(
      (permissionsGroupUserRoleAssignments) =>
        permissionsGroupUserRoleAssignments.permissionsGroup.name
    );

  let userHighestPermissionGroup: PermissionsGroupName | undefined;
  switch (userActiveRole.role.name) {
    case UserRoleType.CUSTOMER:
      userHighestPermissionGroup =
        ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS.find((permissionsGroup) =>
          userActiveGroupPermissions.includes(permissionsGroup)
        );
      break;
    case UserRoleType.ZSPACE_INTERNAL:
    case UserRoleType.PARTNER:
      userHighestPermissionGroup =
        ALL_ZSPACE_STAFF_AND_PARTNER_PERMISSIONS_GROUPS.find(
          (permissionsGroup) =>
            userActiveGroupPermissions.includes(permissionsGroup)
        );
      break;
  }

  return userActiveRole.permissionsGroupUserRoleAssignments.find(
    (permissionsGroupUserRoleAssignment) =>
      permissionsGroupUserRoleAssignment.permissionsGroup.name ===
      userHighestPermissionGroup
  );
}

export function getUserAllowedPermissionsGroupToView(
  userRoleType: UserRoleType,
  relatedBySalesOrder?: boolean
): PermissionsGroupName[] {
  const userIsWarehousePartner = userRoleType === UserRoleType.PARTNER;

  if (userIsWarehousePartner) {
    return [PermissionsGroupName.WAREHOUSE_PARTNER];
  }

  const isZspaceInternalUser = userRoleType === UserRoleType.ZSPACE_INTERNAL;

  if (isZspaceInternalUser && !relatedBySalesOrder) {
    return [
      ...ALL_ZSPACE_INTERNAL_PERMISSIONS_GROUPS,
      ...ALL_PARTNER_PERMISSIONS_GROUPS,
    ];
  } else {
    return ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS;
  }
}

export function getUserRoleSalesOrderRelatedPermissionGroupAssignments(
  userRoleAssignment: UserRoleAssignment
): PermissionsGroupUserRoleAssignment[] {
  return userRoleAssignment.permissionsGroupUserRoleAssignments.filter(
    (assignment) => isSalesOrderRelatedPermissionGroupAssignment(assignment)
  );
}

export function isSalesOrderRelatedPermissionGroupAssignment(
  permissionsGroupAssignment: PermissionsGroupUserRoleAssignment
): boolean {
  return (
    ALL_SALES_ORDER_RELATED_PERMISSIONS_GROUPS.includes(
      permissionsGroupAssignment.permissionsGroup.name as PermissionsGroupName
    ) && !!permissionsGroupAssignment.salesOrder
  );
}

/** Check if user is allowed to create or assign a specific permissions group
 * @param user Current logged in user
 * @param newPermissionsGroup New permissions group to be created/assigned
 * @returns boolean
 */
export function userIsAllowedToCreateOrAssignPermissionsGroup(
  user: User,
  newPermissionsGroup: PermissionsGroupName
): boolean {
  const userAllowedPermissionsGroupsToAssign =
    getUserAllowedPermissionsGroupsToManage(user);

  return userAllowedPermissionsGroupsToAssign.includes(newPermissionsGroup);
}

/** Returns all sales orders that the current logged in user can manage
 * @param user Current logged in user
 * @returns SalesOrder[]
 * */
export function userManagerSalesOrders(user: User) {
  return (
    user.activeRole?.permissionsGroupUserRoleAssignments
      .filter(
        (permissionsGroupAssignment) =>
          ALL_CUSTOMER_MANAGER_PERMISSIONS_GROUPS.includes(
            permissionsGroupAssignment.permissionsGroup
              .name as PermissionsGroupName
          ) &&
          permissionsGroupAssignment.salesOrder &&
          !isExpired(permissionsGroupAssignment)
      )
      .map(
        (permissionsGroupAssignment) => permissionsGroupAssignment.salesOrder!
      ) ?? []
  );
}

/** Returns all sales orders that the current logged in user can manage
 * as an End User Software Contact having a zSpace internal role
 * as their currently active role
 * @param user Current logged in user with a zSpace internal role
 * @returns SalesOrder[]
 * */
export function userZspaceInternalSalesOrders(user: User) {
  if (user.activeRole?.role.name !== UserRoleType.ZSPACE_INTERNAL) {
    return [];
  }
  return (
    user.activeRole.permissionsGroupUserRoleAssignments
      .filter(
        (permissionsGroupAssignment) =>
          permissionsGroupAssignment.permissionsGroup.name ===
          PermissionsGroupName.END_USER_SOFTWARE_CONTACT
      )
      .filter(
        (permissionsGroupAssignment) =>
          ALL_CUSTOMER_MANAGER_PERMISSIONS_GROUPS.includes(
            permissionsGroupAssignment.permissionsGroup
              .name as PermissionsGroupName
          ) &&
          permissionsGroupAssignment.salesOrder &&
          !isExpired(permissionsGroupAssignment)
      )
      .map(
        (permissionsGroupAssignment) => permissionsGroupAssignment.salesOrder!
      ) ?? []
  );
}

/** Check if the current logged in user has a manager role for the provided sales orders
 * @param user Current logged in user
 * @param salesOrdersIds IDs of sales orders to check
 * @returns boolean
 */
export function userIsManagerOfProvidedSalesOrders(
  user: User,
  salesOrdersIds: string | string[]
): boolean {
  const currentUserSalesOrders = userManagerSalesOrders(user).map(
    (salesOrder) => salesOrder.id
  );

  if (Array.isArray(salesOrdersIds)) {
    return salesOrdersIds.every((salesOrderId) =>
      currentUserSalesOrders.includes(salesOrderId)
    );
  }

  return currentUserSalesOrders.includes(salesOrdersIds);
}

export function isCustomerUser(user: User) {
  return user.activeRole?.role.name === UserRoleType.CUSTOMER;
}

export function isZspaceInternalUser(user: User) {
  return user.activeRole?.role.name === UserRoleType.ZSPACE_INTERNAL;
}

export function isPartnerUser(user: User) {
  return user.activeRole?.role.name === UserRoleType.PARTNER;
}

export function isTemporary(permissionGroup: PermissionsGroupName): boolean {
  return permissionGroup === PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER;
}

export function isSystemDefined(role: PermissionsGroupName): boolean {
  return SYSTEM_DEFINED_PERMISSIONS_GROUPS.includes(role);
}

export function isExpired(
  temporaryPermissionsGroupAssignment: PermissionsGroupUserRoleAssignment
): boolean {
  const today = new Date();

  return (
    !!temporaryPermissionsGroupAssignment.expiresAt &&
    temporaryPermissionsGroupAssignment.permissionsGroup.name ===
      PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER &&
    new Date(temporaryPermissionsGroupAssignment.expiresAt as Date) < today
  );
}

export function isCustomerRole(roleAssignment: UserRoleAssignment): boolean {
  return roleAssignment.role.name === UserRoleType.CUSTOMER;
}

export function hasZspaceStaffPermissions(user: User) {
  return (
    !!user.activeRole &&
    isZspaceInternalUser(user) &&
    user.activeRole.permissionsGroupUserRoleAssignments.some((permission) =>
      ALL_ZSPACE_STAFF_PERMISSIONS_GROUPS.includes(
        permission.permissionsGroup.name as PermissionsGroupName
      )
    )
  );
}
