export enum InputType {
  FILTER_CRITERIA = 'FILTER_CRITERIA',
  MIN_MAX = 'MIN_MAX',
  DATE_RANGE = 'DATE_RANGE',
  SELECT = 'SELECT',
  TAG_INPUT = 'TAG_INPUT',
  CHECKLIST = 'CHECKLIST',
}

export enum FilterTableModalFieldAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export type FilterCriteriaInputType<T> = {
  type: InputType.FILTER_CRITERIA;
  valueKey: keyof T;
  filterValueKey: keyof T;
};

export type SelectInputType<T> = {
  type: InputType.SELECT;
  valueKey: keyof T;
  options: { label: string; value: string }[];
};

export type MinMaxInputType<T> = {
  type: InputType.MIN_MAX;
  minValueKey: keyof T;
  maxValueKey: keyof T;
  minValue?: number;
  maxValue?: number;
};

export type TagInputType<T> = {
  type: InputType.TAG_INPUT;
  valueKey: keyof T;
  placeholder: string;
  validateFn?: (value: string) => boolean;
  errorText?: string;
};

export type DateRangeInputType<T> = {
  type: InputType.DATE_RANGE;
  fromDateValueKey: keyof T;
  toDateValueKey: keyof T;
};

export type ChecklistInputType<T> = {
  type: InputType.CHECKLIST;
  valueKey: keyof T;
  options: string[];
};

export type FilterTableModalInputType<T> =
  | FilterCriteriaInputType<T>
  | SelectInputType<T>
  | MinMaxInputType<T>
  | TagInputType<T>
  | DateRangeInputType<T>
  | ChecklistInputType<T>;

export type FilterTableModalField<T> = {
  name: string;
  label: string;
  alignment: FilterTableModalFieldAlignment;
  errorText?: string;
  input: FilterTableModalInputType<T>;
};
