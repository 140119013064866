import {
  BasicDeviceGroupData,
  Device,
  DeviceGroup,
  MoveDevicesData,
  PartialBy,
} from '@zspace/types';
import { HttpStatusCode } from 'axios';
import {
  ClipboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Element, Form } from 'react-bulma-components';
import useHttpRequest from '../../shared/hooks/http-request';
import useToast from '../../shared/hooks/toasts';
import If from '../../shared/if/if';
import Button from '../../ui/button/button';
import FeedbackMessage from '../../ui/feedback-message/feedback-message';
import Modal from '../../ui/modal/modal';
import RemoveDevicesModal from '../remove-devices-modal/remove-devices-modal';
import {
  MoveDevicesFormBlurEvent,
  MoveDevicesFormChangeEvent,
  MoveDevicesFormData,
  initialFormData,
  validateForm,
} from './form';

export const GROUP_CODE_LENGTH = 6;
const GROUP_CODE = 'GROUP_CODE';
const onlyDigitsRegex = /^\d*$/;

const MODAL_TITLE = 'Move devices';
const WARNING_BUTTON_CONFIRMATION_TEXT = 'Yes, move devices';
const SUBMIT_BUTTON_TEXT = 'Move devices';
const CANCEL_BUTTON_TEXT = 'Cancel';

export type MoveDevicesModalProps = {
  show: boolean;
  selectedDevices: Device[];
  deviceGroupsToBeEmptied: DeviceGroup[];
  deviceGroups: BasicDeviceGroupData[];
  onMoveDevicesToDeviceGroup: (
    moveDevicesData: PartialBy<MoveDevicesData, 'devicesIds'>
  ) => Promise<void>;
  onCancel: () => void;
};

const MOVE_DEVICES_ERROR_MESSAGE =
  'The devices could not be moved. Please try again';
const GROUP_CODE_NOT_FOUND_ERROR_MESSAGE =
  'Group code not found. Please try again with a valid group code';

export function MoveDevicesModal({
  show,
  selectedDevices,
  deviceGroupsToBeEmptied,
  deviceGroups,
  onMoveDevicesToDeviceGroup,
  onCancel,
}: MoveDevicesModalProps) {
  const [selectedDeviceGroup, setSelectedDeviceGroup] =
    useState<string>(GROUP_CODE);
  const [formData, setFormData] =
    useState<MoveDevicesFormData>(initialFormData);
  const {
    executeHttpRequest: executeMoveDevicesToDeviceGroup,
    isLoading: isMovingDevices,
  } = useHttpRequest();
  const [emptyGroupWarningAccepted, setEmptyGroupWarningAccepted] =
    useState(false);
  const toast = useToast();

  const deviceGroupCode = useMemo(
    () => formData.deviceGroupCode.value ?? '',
    [formData.deviceGroupCode.value]
  );

  const isGroupCodeSelected = useMemo(
    () => selectedDeviceGroup === GROUP_CODE,
    [selectedDeviceGroup]
  );

  const showEmptyGroupWarning = useMemo(() => {
    return (
      show && !emptyGroupWarningAccepted && deviceGroupsToBeEmptied.length > 0
    );
  }, [deviceGroupsToBeEmptied.length, emptyGroupWarningAccepted, show]);

  const deviceGroupsFromWhichDevicesAreBeingMoved = useMemo(() => {
    const deviceGroupsNamesSet = new Set<string>(
      selectedDevices
        .filter((device) => !!device.deviceGroup)
        .map((device) => device.deviceGroup!.name)
    );
    const deviceGroupsNamesArray = Array.from(deviceGroupsNamesSet);
    if (selectedDevices.some((device) => !device.deviceGroup)) {
      deviceGroupsNamesArray.push('Ungrouped');
    }
    return deviceGroupsNamesArray;
  }, [selectedDevices]);

  const availableDeviceGroupOptions = useMemo(
    () =>
      deviceGroups.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      )),
    [deviceGroups]
  );

  const resetData = useCallback(() => {
    setSelectedDeviceGroup(GROUP_CODE);
    setFormData(initialFormData);
  }, []);

  const handleSelectDeviceGroup = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue = event.target.value;
      setSelectedDeviceGroup(newValue);

      if (newValue !== GROUP_CODE) {
        setFormData(initialFormData);
      }
    },
    []
  );

  const onInputChange = useCallback(
    (event: MoveDevicesFormChangeEvent) => {
      const newValue = event.target.value;
      const name = event.target.name as keyof MoveDevicesFormData;
      const isOnlyDigits = onlyDigitsRegex.test(newValue);
      if (isOnlyDigits) {
        setFormData({
          ...formData,
          [name]: { ...formData[name], value: newValue, touched: true },
        });
      }
    },
    [formData, setFormData]
  );

  const onBlur = useCallback(
    (event: MoveDevicesFormBlurEvent) => {
      const value = event.target.value;
      const name = event.target.name as keyof MoveDevicesFormData;
      const error = validateForm[name]?.(value);
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          error,
        },
      });
    },
    [formData, setFormData]
  );

  const handleMoveDevicesToDeviceGroup = useCallback(
    async () =>
      executeMoveDevicesToDeviceGroup({
        asyncFunction: async () => {
          if (isGroupCodeSelected) {
            const formValidation = Object.keys(formData).reduce(
              (acc, key) => {
                const formDataKey = key as keyof MoveDevicesFormData;
                const error = validateForm[formDataKey]?.(
                  formData[formDataKey].value
                );
                setFormData((prev) => ({
                  ...prev,
                  [formDataKey]: { ...prev[formDataKey], error, touched: true },
                }));
                return {
                  values: { ...acc.values, [key]: formData[formDataKey].value },
                  errors: { ...acc.errors, [key]: error },
                };
              },
              { values: {}, errors: {} }
            );
            const isValidForm = Object.values(formValidation.errors).every(
              (fieldError) => !fieldError
            );

            if (!isValidForm) {
              return;
            }
          }

          let deviceGroupId;

          if (!isGroupCodeSelected) {
            deviceGroupId = selectedDeviceGroup;
          }

          await onMoveDevicesToDeviceGroup({
            deviceGroupId,
            deviceGroupCode,
          });
        },
        customErrorHandler: (error) => {
          if (error.status === HttpStatusCode.NotFound)
            return toast.error(GROUP_CODE_NOT_FOUND_ERROR_MESSAGE);
          return toast.error(MOVE_DEVICES_ERROR_MESSAGE);
        },
      }),
    [
      deviceGroupCode,
      executeMoveDevicesToDeviceGroup,
      formData,
      isGroupCodeSelected,
      onMoveDevicesToDeviceGroup,
      selectedDeviceGroup,
      toast,
    ]
  );

  const handleAcceptEmptyGroupWarning = useCallback(() => {
    setEmptyGroupWarningAccepted(true);
  }, []);

  const handleOnCancel = useCallback(() => {
    onCancel();
    setEmptyGroupWarningAccepted(false);
  }, [onCancel]);

  const onPasteGroupCode = useCallback(
    (event: ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const pasteData = event.clipboardData.getData('text');
      const filteredData = pasteData.replace(/\D/g, '');
      if (filteredData) {
        setFormData({
          deviceGroupCode: {
            ...formData.deviceGroupCode,
            value: filteredData,
            touched: true,
          },
        });
      }
    },
    [formData.deviceGroupCode]
  );

  useEffect(() => {
    if (!show) {
      resetData();
    }
  });

  if (showEmptyGroupWarning) {
    return (
      <RemoveDevicesModal
        show={showEmptyGroupWarning}
        deviceGroupsToBeEmptied={deviceGroupsToBeEmptied}
        onRemoveDevicesFromDeviceGroup={handleAcceptEmptyGroupWarning}
        onCancel={handleOnCancel}
        loading={false}
        title={MODAL_TITLE}
        confirmationButtonText={WARNING_BUTTON_CONFIRMATION_TEXT}
      />
    );
  }

  return (
    <Modal show={show} onClose={resetData}>
      <Element
        paddingless
        display="flex"
        flexDirection="column"
        className="gap-8"
      >
        <Element
          paddingless
          display="flex"
          flexDirection="column"
          className="gap-6"
        >
          <h1 className="has-text-weight-light is-size-4">{MODAL_TITLE}</h1>
          <If condition={deviceGroupsFromWhichDevicesAreBeingMoved.length > 0}>
            <h3 className="is-size-6">
              Moving devices from{' '}
              <span className="has-text-weight-bold">
                {deviceGroupsFromWhichDevicesAreBeingMoved.join(', ')}
              </span>
            </h3>
          </If>
          <h3 className="is-size-6">
            To which device group would you like to move the selected devices?
          </h3>
        </Element>

        <Form.Field>
          <Form.Label
            htmlFor="deviceGroup"
            className="is-size-7 has-text-weight-normal"
          >
            Device group
          </Form.Label>
          <Form.Control>
            <Form.Select
              id="deviceGroup"
              fullwidth
              value={selectedDeviceGroup}
              onChange={handleSelectDeviceGroup}
            >
              <option value={GROUP_CODE}>I have a group code</option>
              {availableDeviceGroupOptions}
            </Form.Select>
          </Form.Control>
        </Form.Field>

        <If condition={isGroupCodeSelected}>
          <Form.Control>
            <Form.Field>
              <Form.Label
                htmlFor="deviceGroupCode"
                className="is-size-7 has-text-weight-normal"
              >
                Group code
              </Form.Label>
              <Form.Control
                display="flex"
                flexDirection="row"
                className="gap-4"
              >
                <Form.Input
                  id="deviceGroupCode"
                  placeholder="------"
                  maxLength={GROUP_CODE_LENGTH}
                  name="deviceGroupCode"
                  value={formData.deviceGroupCode.value}
                  onChange={onInputChange}
                  onBlur={onBlur}
                  onPaste={onPasteGroupCode}
                />
              </Form.Control>
            </Form.Field>

            <FeedbackMessage>{formData.deviceGroupCode.error}</FeedbackMessage>
          </Form.Control>
        </If>

        <Element display="flex" justifyContent="space-between">
          <Button color="primary-dark" outlined onClick={handleOnCancel}>
            {CANCEL_BUTTON_TEXT}
          </Button>
          <Button
            color="primary-dark"
            onClick={handleMoveDevicesToDeviceGroup}
            loading={isMovingDevices}
            isExecutingAction={isMovingDevices}
          >
            {SUBMIT_BUTTON_TEXT}
          </Button>
        </Element>
      </Element>
    </Modal>
  );
}

export default MoveDevicesModal;
