import { Navigate } from 'react-router-dom';
import { RouteObject } from '../app';
import MySoftwareDeviceBasedTab, {
  loader as mySoftwareDeviceBasedTabLoader,
} from './my-software-device-based-tab/my-software-device-based-tab';
import MySoftwarePage from './my-software-page/my-software-page';
import MySoftwareWebBasedTab, {
  loader as mySoftwareWebBasedTabLoader,
} from './my-software-web-based-tab/my-software-web-based-tab';
import SoftwareDetailsPage, {
  loader as softwareDetailsLoader,
} from './software-details-page/software-details-page';

export const routes: RouteObject[] = [
  {
    path: 'software',
    children: [
      {
        loader: softwareDetailsLoader,
        path: ':id',
        element: <SoftwareDetailsPage />,
      },
    ],
  },
  {
    path: 'my-software',
    element: <MySoftwarePage />,
    children: [
      {
        index: true,
        element: <Navigate to="device-based" replace />,
      },
      {
        loader: mySoftwareDeviceBasedTabLoader,
        path: 'device-based',
        element: <MySoftwareDeviceBasedTab />,
      },
      {
        loader: mySoftwareWebBasedTabLoader,
        path: 'web-based',
        element: <MySoftwareWebBasedTab />,
      },
    ],
  },
];
