import { formatSalesOrderNumber } from '@zspace/format';
import { Device } from '@zspace/types';
import { useCallback, useMemo } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaCheck } from 'react-icons/fa6';
import { PiWarningFill } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import Conditional from '../../../shared/conditional/conditional';
import useIsCustomerUser from '../../../shared/hooks/is-customer-user';
import useSalesOrderPermissions from '../../../shared/hooks/sales-order-permissions';
import { removeDuplicates } from '../../../shared/utils';
import InfiniteTable, {
  CustomInfiniteTableProps,
} from '../../../ui/table/infinite-table/infinite-table';
import { Column, RowSelection } from '../../../ui/table/types';
import styles from './device-group-table.module.scss';

type InfiniteTableProps = Omit<CustomInfiniteTableProps<Device>, 'empty'>;

export type DeviceGroupTableProps = InfiniteTableProps & {
  selectedSoftware?: string[];
  showCompleted?: boolean;
  onManageDeviceClick?: (devices: Device[], add: boolean) => void;
  selectedDevices?: Device[];
  disableSelection?: boolean;
  ignoreSalesOrderPermissions?: boolean;
};

const NO_SOFTWARE_ASSIGNED = 'No software assigned yet';

export function DeviceGroupTable({
  dataSource: devices,
  hasMore,
  fetchMore,
  loadingInitialData,
  selectedSoftware = [],
  isInvalidSelection = () => false,
  showCompleted = false,
  onManageDeviceClick,
  selectedDevices,
  config,
  onChange,
  disableSelection,
  ignoreSalesOrderPermissions = false,
}: DeviceGroupTableProps) {
  const isCustomerUser = useIsCustomerUser();
  const {
    userHasSalesOrderManagePermissions,
    userIsJustViewOnlySalesOrderManager,
  } = useSalesOrderPermissions();

  const isDisabledRow = useCallback(
    (row: Device) => {
      if (ignoreSalesOrderPermissions) {
        return false;
      }

      if (!userIsJustViewOnlySalesOrderManager) {
        return !userHasSalesOrderManagePermissions(
          row.salesOrderLine.salesOrder.id
        );
      }
      return false;
    },
    [
      userHasSalesOrderManagePermissions,
      userIsJustViewOnlySalesOrderManager,
      ignoreSalesOrderPermissions,
    ]
  );

  const EmptyTable = useCallback(
    () => (
      <Element
        display="flex"
        justifyContent="center"
        alignItems="center"
        className="h-full"
      >
        <span className="has-text-weight-light">
          There are no devices at the moment
        </span>
      </Element>
    ),
    []
  );

  const columns: Column<Device>[] = useMemo(
    () => [
      {
        key: 'serialNumber.number',
        widthClassname: 'is-size-20',
        render: (el) => {
          const showError = isInvalidSelection([el]);
          let deviceIdentifier = el.serialNumber?.number;
          if (!deviceIdentifier) {
            deviceIdentifier = el.name ?? el.defaultName;
          }
          return (
            <>
              {showError && (
                <PiWarningFill
                  className="has-text-danger-dark"
                  style={{ verticalAlign: 'text-bottom' }}
                />
              )}
              {deviceIdentifier}
              {showCompleted &&
                el.assignments.some(
                  (assignment) => assignment.softwareSeat
                ) && (
                  <Icon className="has-text-success-dark">
                    <FaCheck />
                  </Icon>
                )}
            </>
          );
        },
        title: 'Serial Number',
        sortable: false,
        noWrap: true,
      },
      {
        key: 'salesOrderLine.salesOrder.number',
        widthClassname: 'is-size-15',
        render: (el) => (
          <Conditional condition={isDisabledRow(el)}>
            <Conditional.True>
              <span className="is-underlined">
                {formatSalesOrderNumber(el.salesOrderLine.salesOrder.number)}
              </span>
            </Conditional.True>
            <Conditional.False>
              <Link to={`/sales-orders/${el.salesOrderLine.salesOrder.id}`}>
                {formatSalesOrderNumber(el.salesOrderLine.salesOrder.number)}
              </Link>
            </Conditional.False>
          </Conditional>
        ),
        title: 'Sales order',
        sortable: true,
      },
      {
        key: 'name',
        widthClassname: 'is-size-20',
        render: (el) => el.name ?? el.serialNumber?.number ?? el.defaultName,
        title: 'Device name',
        sortable: false,
      },
      {
        key: 'model.name',
        widthClassname: 'is-size-20',
        render: (el) => el.model.displayName,
        title: 'Device type',
        noWrap: true,
      },
      {
        key: 'software',
        widthClassname: 'is-size-25',
        render: (el) => {
          const assignedSoftwareTitles = el.assignments
            .filter((assignment) => assignment.softwareSeat)
            .map(
              (assignment) =>
                assignment.softwareSeat.licensingProductGroup.displayName
            );

          let software = '';

          if (
            assignedSoftwareTitles.length === 0 &&
            selectedSoftware.length === 0
          ) {
            software = NO_SOFTWARE_ASSIGNED;
          } else {
            software = removeDuplicates([
              ...selectedSoftware,
              ...assignedSoftwareTitles,
            ])
              .sort()
              .join(', ');
          }

          return (
            <Element className={styles.softwareContainer}>{software}</Element>
          );
        },
        title: 'Assigned software',
      },
    ],
    [isDisabledRow, isInvalidSelection, selectedSoftware, showCompleted]
  );

  const rowSelection: RowSelection | undefined = useMemo(() => {
    if (disableSelection) return;
    let hasDevicesManagerPermission = true;
    if (isCustomerUser && !userIsJustViewOnlySalesOrderManager) {
      hasDevicesManagerPermission = devices.some((device) =>
        userHasSalesOrderManagePermissions(device.salesOrderLine.salesOrder.id)
      );
    }
    if (selectedDevices && onManageDeviceClick && hasDevicesManagerPermission) {
      return {
        onChange: (selectedRowIds) => {
          selectedRowIds.forEach((id) => {
            const foundRow = selectedDevices.find((sd) => sd.id === id);

            if (foundRow) {
              onManageDeviceClick([foundRow], false);
            } else {
              const newSelectedDevice =
                devices.find((device) => device.id === id) || ({} as Device);
              onManageDeviceClick([newSelectedDevice], true);
            }
          });
        },
        selectedRowKeys: devices
          .filter((device) =>
            selectedDevices
              .map((selectedDevice) => selectedDevice.id)
              .includes(device.id)
          )
          .map((d) => d.id),
      };
    }
  }, [
    disableSelection,
    isCustomerUser,
    userIsJustViewOnlySalesOrderManager,
    selectedDevices,
    onManageDeviceClick,
    devices,
    userHasSalesOrderManagePermissions,
  ]);

  return (
    <InfiniteTable
      fetchMore={fetchMore}
      hasMore={hasMore}
      columns={columns}
      rowKey="id"
      loadingInitialData={loadingInitialData}
      config={config}
      dataSource={devices}
      rowSelection={rowSelection}
      empty={<EmptyTable />}
      isInvalidSelection={isInvalidSelection}
      isDisabledSelection={isDisabledRow}
      onChange={onChange}
    />
  );
}

export default DeviceGroupTable;
