import { useEffect, useRef } from 'react';
import styles from './checkbox.module.scss';

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  indeterminate?: boolean;
};

export function Checkbox({
  indeterminate,
  className,
  ...props
}: CheckboxProps) {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate ?? false;
    }
  }, [indeterminate]);

  return (
    <label className="checkbox">
      <input
        className={`${className} ${styles.customCheckbox}`}
        type="checkbox"
        ref={checkboxRef}
        {...props}
      />
    </label>
  );
}

export default Checkbox;
