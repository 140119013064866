import { FeaturePermissions } from '@zspace/roles';
import { useCallback, useMemo } from 'react';
import { Columns } from 'react-bulma-components';
import { useNavigate } from 'react-router-dom';
import HomeCards from '../../shared/home-cards/home-cards';
import HomeHeader from '../../shared/home-header/home-header';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import styles from './partners-home-page.module.scss';

function PartnersHomePage() {
  const navigate = useNavigate();

  const navigateToFaultyDeviceReplacement = useCallback(() => {
    navigate('/device-replacement');
  }, [navigate]);

  const navigateToWorkOrderQueue = useCallback(() => {
    navigate('/work-orders');
  }, [navigate]);

  const navigateToUserControlPanel = useCallback(() => {
    navigate('/users');
  }, [navigate]);

  const homeCards = useMemo(() => {
    const cards = [
      {
        title: 'Work order queue',
        description:
          'Review the work order queue, priorities and work order history.',
        onClick: navigateToWorkOrderQueue,
      },
      {
        title: 'Faulty device replacement',
        description:
          'Scan a faulty device’s serial number and switch it for a new working device.',
        onClick: navigateToFaultyDeviceReplacement,
      },
      {
        title: 'Users',
        description:
          'View all the users in your organization. Invite new ones and edit current permissions.',
        onClick: navigateToUserControlPanel,
      },
    ];
    return cards;
  }, [
    navigateToFaultyDeviceReplacement,
    navigateToUserControlPanel,
    navigateToWorkOrderQueue,
  ]);

  return (
    <ProtectedPage permissions={FeaturePermissions.PARTNERS_ACCESS}>
      <BoxLayout
        className={`is-min-height-80 mx-10 my-4 ${styles.layout} is-flex is-flex-direction-column`}
        childrenClassName={styles.container}
      >
        <Columns
          display="flex"
          flexDirection="column"
          className="is-min-height-80"
        >
          <Columns.Column display="flex" flexDirection="column" p={4}>
            <HomeHeader />
          </Columns.Column>
          <Columns.Column>
            <HomeCards
              cards={homeCards}
              resolveCardsOnClick={(handler, _) => handler}
            />
          </Columns.Column>
        </Columns>
      </BoxLayout>
    </ProtectedPage>
  );
}

export default PartnersHomePage;
