import { ReactNode } from 'react';
import Conditional from '../conditional/conditional';

export type IfProps = {
  condition: boolean;
  children: ReactNode;
};

export function If({ condition, children }: IfProps) {
  return (
    <Conditional condition={condition}>
      <Conditional.True>{children}</Conditional.True>
    </Conditional>
  );
}

export default If;
