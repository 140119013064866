import { WorkOrderPermissions } from '@zspace/roles';
import {
  FilterType,
  PaginatedAPIResponse,
  SortDirection,
  WorkOrderDashboardPageTabs,
  WorkOrderData,
  WorkOrderStatus,
  WorkOrderType,
  WorkOrdersCriteria,
} from '@zspace/types';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaPlus } from 'react-icons/fa6';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigation,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import { createSearchParams } from '../../shared/url';
import Button from '../../ui/button/button';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import Spinner from '../../ui/spinner/spinner';
import { WorkOrderDashboardPageContext } from '../work-orders-dashboard-page/types';
import { fetchWorkOrders } from '../work-orders-service';
import WorkOrdersTableCard from '../work-orders-table-card/work-orders-table-card';

const initialCriteriaData: WorkOrdersCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  workOrderName: '',
  workOrderNameFilter: FilterType.CONTAINS,
  creationDateFrom: '',
  creationDateTo: '',
  account: '',
  accountFilter: FilterType.CONTAINS,
  type: WorkOrderType.ALL,
  minTotalDevicesNumber: '',
  maxTotalDevicesNumber: '',
  createdBy: [],
  salesOrder: [],
  status: WorkOrderStatus.DRAFT,
};

export const loader: LoaderFunction = async ({ request }) => {
  const searchParams = createSearchParams(request);

  const response = fetchWorkOrders({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: searchParams.get('sortBy') ?? '',
    sortDirection: (searchParams.get('sortDirection') as SortDirection) ?? '',
    workOrderName: searchParams.get('workOrderName') ?? '',
    workOrderNameFilter:
      (searchParams.get('workOrderNameFilter') as FilterType) ??
      FilterType.CONTAINS,
    creationDateFrom: searchParams.get('creationDateFrom') ?? '',
    creationDateTo: searchParams.get('creationDateTo') ?? '',
    account: searchParams.get('account') ?? '',
    accountFilter:
      (searchParams.get('accountFilter') as FilterType) ?? FilterType.CONTAINS,
    type: (searchParams.get('type') as WorkOrderType) ?? WorkOrderType.ALL,
    minTotalDevicesNumber: searchParams.get('minTotalDevicesNumber') ?? '',
    maxTotalDevicesNumber: searchParams.get('maxTotalDevicesNumber') ?? '',
    createdBy: searchParams.getAll('createdBy') ?? [],
    salesOrder: searchParams.getAll('salesOrder') ?? [],
    status: WorkOrderStatus.DRAFT,
  });

  return defer({ response });
};

function WorkOrdersDraftsTabContent() {
  const {
    setActiveTab,
    setFilterTagList,
    setShowCreateWorkOrderButton,
    handleCreateWorkOrderButtonClick,
  } = useOutletContext<WorkOrderDashboardPageContext>();
  const navigation = useNavigation();
  const [searchParams] = useSearchParams();

  const response = useAsyncValue() as PaginatedAPIResponse<WorkOrderData>;

  const [data, setData] = useState<WorkOrdersCriteria>({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: searchParams.get('sortBy') ?? '',
    sortDirection: (searchParams.get('sortDirection') as SortDirection) ?? '',
    workOrderName: searchParams.get('workOrderName') ?? '',
    workOrderNameFilter:
      (searchParams.get('workOrderNameFilter') as FilterType) ??
      FilterType.CONTAINS,
    creationDateFrom: searchParams.get('creationDateFrom') ?? '',
    creationDateTo: searchParams.get('creationDateTo') ?? '',
    account: searchParams.get('account') ?? '',
    accountFilter:
      (searchParams.get('accountFilter') as FilterType) ?? FilterType.CONTAINS,
    type: (searchParams.get('type') as WorkOrderType) ?? WorkOrderType.ALL,
    minTotalDevicesNumber: searchParams.get('minTotalDevicesNumber') ?? '',
    maxTotalDevicesNumber: searchParams.get('maxTotalDevicesNumber') ?? '',
    createdBy: searchParams.getAll('createdBy') ?? [],
    salesOrder: searchParams.getAll('salesOrder') ?? [],
    status: WorkOrderStatus.DRAFT,
  });

  const emptyTableContent = (
    <>
      <Element display="flex" justifyContent="center">
        <span className="my-2 has-text-weight-light">
          There are no work orders drafts at the moment
        </span>
      </Element>
      <CheckPermissions permissions={WorkOrderPermissions.WORK_ORDERS_CREATE}>
        <CheckPermissions.Render>
          <Element display="flex" justifyContent="center">
            <Button
              color="primary-dark"
              onClick={handleCreateWorkOrderButtonClick}
            >
              <Icon>
                <FaPlus />
              </Icon>
              <span>Create work order</span>
            </Button>
          </Element>
        </CheckPermissions.Render>
      </CheckPermissions>
    </>
  );

  const emptyTableContentWithFilters = (
    <Element display="flex" justifyContent="center">
      <span className="my-2">
        There are no work orders drafts that match the selected criteria
      </span>
    </Element>
  );

  const isDataLoading = useMemo(
    () => navigation.state === 'loading',
    [navigation.state]
  );

  useEffect(() => {
    setActiveTab(WorkOrderDashboardPageTabs.DRAFTS);
  }, [setActiveTab]);

  useEffect(() => {
    setShowCreateWorkOrderButton(response?.data?.length > 0);
  }, [response?.data?.length, setShowCreateWorkOrderButton]);

  if (isDataLoading) {
    return (
      <Element display="flex" justifyContent="center">
        <Spinner />
      </Element>
    );
  }

  return (
    <WorkOrdersTableCard
      initialCriteria={initialCriteriaData}
      criteria={data}
      setCriteria={setData}
      tableData={response}
      workOrdersStatus={WorkOrderStatus.DRAFT}
      emptyTableContent={emptyTableContent}
      emptyTableContentWithFilters={emptyTableContentWithFilters}
      setFilterTagList={setFilterTagList}
    />
  );
}

export function WorkOrdersDraftsTab() {
  const { response } = useLoaderData() as {
    response: Promise<PaginatedAPIResponse<WorkOrderData>>;
  };

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <WorkOrdersDraftsTabContent />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default WorkOrdersDraftsTab;
