import { EULAPermissions } from '@zspace/roles';
import { UserRoleType } from '@zspace/types';
import FileSaver from 'file-saver';
import { useCallback, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import {
  FaArrowsRotate,
  FaDownload,
  FaFileInvoice,
  FaGear,
  FaUsers,
} from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { downloadEulaAuditLog } from '../../eula/eula-service';
import Button from '../../ui/button/button';
import DropdownMenu from '../../ui/dropdown-menu/dropdown-menu';
import LoadingIcon from '../../ui/loading-icon/loading-icon';
import { switchRole } from '../../users/users-service';
import SwitchToCustomerRoleModal from '../../zspace-internal/switch-to-customer-role-modal/switch-to-customer-role-modal';
import CheckPermissions from '../check-permissions/check-permissions';
import useHttpRequest from '../hooks/http-request';
import useUser from '../hooks/user';
import If from '../if/if';

const SWITCH_ROLE_ERROR_MESSAGE = 'Could not switch role. Please try again';
const EULA_AUDIT_LOG_DOWNLOAD_ERROR_MESSAGE =
  'EULA Audit Log download failed. Please try again.';

export function AdminToolbar() {
  const navigate = useNavigate();
  const { user } = useUser();
  const {
    executeHttpRequest: executeSwitchRole,
    isLoading: isLoadingSwitchRole,
  } = useHttpRequest();
  const [showSwitchRoleModal, setShowSwitchRoleModal] =
    useState<boolean>(false);
  const {
    executeHttpRequest: executeEulaAuditLogDownload,
    isLoading: isLoadingEulaAuditLogDownload,
  } = useHttpRequest();

  const hasCustomerRoles = useMemo(() => {
    let hasCustomerRole = false;
    user.roles.forEach((roleAssignment) => {
      switch (roleAssignment.role.name) {
        case UserRoleType.CUSTOMER:
          hasCustomerRole = true;
          break;
      }
    });
    return hasCustomerRole;
  }, [user.roles]);

  const availableUserRoleToSwitch = useMemo(() => {
    return user.roles.find(
      (roleAssignment) => roleAssignment.role.name === UserRoleType.CUSTOMER
    );
  }, [user.roles]);

  const handleSwitchRole = useCallback(async () => {
    return executeSwitchRole({
      asyncFunction: async () => {
        await switchRole(availableUserRoleToSwitch!.id);
        window.location.replace('/');
      },
      customErrorMessage: SWITCH_ROLE_ERROR_MESSAGE,
    });
  }, [availableUserRoleToSwitch, executeSwitchRole]);

  const handleDownloadEulaAuditLog = useCallback(
    () =>
      executeEulaAuditLogDownload({
        asyncFunction: async () => {
          const eulaAuditLogDownloadableData = await downloadEulaAuditLog();
          const blob = new Blob([eulaAuditLogDownloadableData.fileContent], {
            type: 'text/csv',
          });
          FileSaver.saveAs(blob, eulaAuditLogDownloadableData.fileName);
        },
        customErrorMessage: EULA_AUDIT_LOG_DOWNLOAD_ERROR_MESSAGE,
      }),
    [executeEulaAuditLogDownload]
  );

  const navigateToWorkOrders = useCallback(() => {
    navigate('/work-orders');
  }, [navigate]);

  const navigateToUserControlPanel = useCallback(() => {
    navigate('/users');
  }, [navigate]);

  return (
    <>
      <Element display="flex" flexDirection="row">
        <If condition={hasCustomerRoles}>
          <Button
            className="is-borderless"
            color="transparent"
            onClick={() => setShowSwitchRoleModal(true)}
          >
            <Icon color="primary-dark">
              <FaArrowsRotate />
            </Icon>
            <span className="is-underlined has-text-primary-dark">
              Manage my customer’s orders
            </span>
          </Button>
        </If>
        <Button
          className="is-borderless"
          color="transparent"
          onClick={navigateToWorkOrders}
        >
          <Icon color="primary-dark">
            <FaFileInvoice />
          </Icon>
          <span className="is-underlined has-text-primary-dark">
            Work orders
          </span>
        </Button>

        <DropdownMenu
          borderless
          color="transparent"
          prefixIcon={
            <Icon color="primary-dark">
              <FaGear />
            </Icon>
          }
          hasSuffixChevron
          label="Admin tools"
          labelStyle="is-underlined has-text-primary-dark"
          alignedRight
        >
          <DropdownMenu.Item onClick={navigateToUserControlPanel}>
            <Element display="flex" alignItems="center" className="gap-1">
              <Icon color="primary-dark">
                <FaUsers />
              </Icon>
              <span className="is-size-6">User management</span>
            </Element>
          </DropdownMenu.Item>
          <CheckPermissions
            permissions={EULAPermissions.EULA_DOWNLOAD_AUDIT_LOGS}
          >
            <CheckPermissions.Render>
              <DropdownMenu.Item onClick={handleDownloadEulaAuditLog}>
                <Element display="flex" alignItems="center" className="gap-1">
                  <LoadingIcon
                    isLoading={isLoadingEulaAuditLogDownload}
                    icon={FaDownload}
                    iconColor="primary-dark"
                  />
                  <span className="is-size-6">EULA Audit Log</span>
                </Element>
              </DropdownMenu.Item>
            </CheckPermissions.Render>
          </CheckPermissions>
        </DropdownMenu>
      </Element>
      <SwitchToCustomerRoleModal
        show={showSwitchRoleModal}
        loading={isLoadingSwitchRole}
        onClose={() => setShowSwitchRoleModal(false)}
        onSwitchRole={handleSwitchRole}
      />
    </>
  );
}

export default AdminToolbar;
