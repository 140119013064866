import { formatSalesOrderNumber } from '@zspace/format';
import { PermissionsGroupName } from '@zspace/roles';
import { EditUserPermissionsTableRow, User, UserRoleType } from '@zspace/types';
import { useMemo } from 'react';
import { Columns, Element } from 'react-bulma-components';
import { FaTrash } from 'react-icons/fa6';
import Button from '../../../ui/button/button';
import Modal from '../../../ui/modal/modal';

export type RevokeUserPermissionConfirmationModalProps = {
  show: boolean;
  user: User;
  title?: string;
  message?: string;
  permission?: EditUserPermissionsTableRow;
  loading: boolean;
  onCancel: () => void;
  onRevoke: (permission?: EditUserPermissionsTableRow) => void;
};

export function RevokeUserPermissionConfirmationModal({
  show,
  user,
  title: _title,
  message: _message,
  permission,
  loading,
  onCancel,
  onRevoke,
}: RevokeUserPermissionConfirmationModalProps) {
  const title = useMemo(() => {
    if (_title) return _title;

    const hasTemporaryRole =
      user.roles
        .find(
          (roleAssignment) => roleAssignment.role.name === UserRoleType.CUSTOMER
        )
        ?.permissionsGroupUserRoleAssignments.some(
          (permissionsGroupAssignment) =>
            permissionsGroupAssignment.permissionsGroup.name ===
            PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER
        ) ?? false;

    return hasTemporaryRole
      ? `Revoke ${permission ? 'temporary role' : 'all temporary roles'}`
      : 'Revoke role';
  }, [_title, permission, user.roles]);

  const buttonText = useMemo(
    () => `Yes, revoke ${permission ? 'role' : 'all roles'}`,
    [permission]
  );

  const message = useMemo(() => {
    if (_message) return _message;

    return (
      <>
        Are you sure you want to revoke
        <span className="has-text-weight-bold"> {user?.email}'s </span>
        access to
        {permission
          ? ` ${formatSalesOrderNumber(permission.number)}`
          : ' all sales orders'}
        ?
      </>
    );
  }, [_message, permission, user?.email]);

  return (
    <Modal show={show}>
      <h1 className="has-text-weight-light is-size-4">{title}</h1>
      <Element renderAs="p" my={4}>
        {message}
      </Element>
      <Columns mt={4}>
        <Columns.Column>
          <Button color="primary-dark" outlined onClick={onCancel}>
            No, cancel
          </Button>
        </Columns.Column>
        <Columns.Column display="flex" justifyContent="flex-end">
          <Button
            color="danger-dark"
            onClick={() => {
              onRevoke(permission);
            }}
            isExecutingAction={loading}
          >
            <Button.LoadingIcon icon={FaTrash} />
            <span>{buttonText}</span>
          </Button>
        </Columns.Column>
      </Columns>
    </Modal>
  );
}

export default RevokeUserPermissionConfirmationModal;
