import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import Conditional from '../../shared/conditional/conditional';
import If from '../../shared/if/if';

type MaskedFieldProps = ComponentProps<typeof Element> & {
  value: string;
  disabled?: boolean;
  maskedValueLength?: number;
};

const DEFAULT_MASKED_VALUE_LENGTH = 17;

export function MaskedField({
  value,
  disabled,
  maskedValueLength,
  ...props
}: MaskedFieldProps) {
  const [isValueVisible, setIsValueVisible] = useState<boolean>(true);

  const maskedValue = useMemo(() => {
    return '*'.repeat(maskedValueLength ?? DEFAULT_MASKED_VALUE_LENGTH);
  }, [maskedValueLength]);

  const handleToggleValueVisibility = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      setIsValueVisible(!isValueVisible);
    },
    [isValueVisible]
  );

  return (
    <Element display="flex" alignItems="center" {...props}>
      <Conditional condition={isValueVisible && !disabled}>
        <Conditional.True>
          <span>{value}</span>
        </Conditional.True>
        <Conditional.False>
          <span>{maskedValue}</span>
        </Conditional.False>
        <If condition={!disabled}>
          <Icon
            color="primary"
            className="is-clickable"
            onClick={handleToggleValueVisibility}
          >
            <Conditional condition={isValueVisible}>
              <Conditional.True>
                <FaEyeSlash />
              </Conditional.True>
              <Conditional.False>
                <FaEye />
              </Conditional.False>
            </Conditional>
          </Icon>
        </If>
      </Conditional>
    </Element>
  );
}

export default MaskedField;
