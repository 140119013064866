import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';
import { Form, Icon } from 'react-bulma-components';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { IoMdClose } from 'react-icons/io';
import { useDebouncedCallback } from 'use-debounce';
import Button from '../button/button';
import styles from './search-bar.module.scss';

export interface SearchBarProps {
  debounceMillis?: number;
  onChange: (value: string) => void;
  searchValue: string;
}

export function SearchBar({
  debounceMillis,
  onChange,
  searchValue,
}: SearchBarProps) {
  const [inputValue, setInputValue] = useState(searchValue);

  const debouncedOnChange = useDebouncedCallback((value: string) => {
    onChange(value);
  }, debounceMillis);

  const updateSearchInput = useCallback(
    (value: string) => {
      setInputValue(value);

      debouncedOnChange(value);
    },
    [debouncedOnChange]
  );

  const handleSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      updateSearchInput(event.target.value);
    },
    [updateSearchInput]
  );

  const handleSearchOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
    []
  );

  const handleClearClick = useCallback(() => {
    const emptyValue = '';
    setInputValue(emptyValue);
    onChange(emptyValue);
  }, [onChange]);

  const ClearFilterButton = inputValue.length ? (
    <Button
      type="button"
      color="primary-dark"
      outlined
      className="ml-2"
      onClick={handleClearClick}
    >
      <Icon>
        <IoMdClose />
      </Icon>
      <span>Clear search</span>
    </Button>
  ) : null;

  return (
    <>
      <Form.Control>
        <Icon align="left">
          <FaMagnifyingGlass className={styles.searchIcon} />
        </Icon>
        <Form.Input
          className={styles.searchInput}
          aria-label="Search"
          placeholder="Search"
          type="text"
          id="q"
          name="q"
          value={inputValue}
          onChange={handleSearchInputChange}
          onKeyDown={handleSearchOnKeyDown}
        />
      </Form.Control>

      {ClearFilterButton}
    </>
  );
}

export default SearchBar;
