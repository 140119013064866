import { RouteObject } from '../app';
import CreateDeviceGroupCardPage from '../device-groups/create-device-group-card-page/create-device-group-card-page';
import EditDeviceGroupCardPage, {
  loader as editDeviceGroupCardPageLoader,
} from '../device-groups/edit-device-group-card-page/edit-device-group-card-page';
import RegistrationFilesPage, {
  loader as registrationFilesPageLoader,
} from '../devices/registration-files-page/registration-files-page';
import DeviceRegistrationPage from './device-registration-page/device-registration-page';
import MyDevicesPage, {
  loader as myDevicesPageLoader,
} from './my-devices-page/my-devices-page';
import RegistrationFileDetailsPage, {
  loader as registrationFilesDetailsPageLoader,
} from './registration-file-details-page/registration-file-details-page';

export const routes: RouteObject[] = [
  {
    loader: myDevicesPageLoader,
    path: 'my-devices',
    element: <MyDevicesPage />,
  },
  {
    path: 'my-devices/device-groups/create',
    element: <CreateDeviceGroupCardPage />,
  },
  {
    loader: editDeviceGroupCardPageLoader,
    path: 'my-devices/device-groups/:id',
    element: <EditDeviceGroupCardPage />,
  },
  {
    loader: registrationFilesDetailsPageLoader,
    path: 'my-devices/device-registration/registration-files/:id',
    element: <RegistrationFileDetailsPage />,
  },
  {
    loader: registrationFilesPageLoader,
    path: 'my-devices/device-registration/registration-files',
    element: <RegistrationFilesPage />,
  },
  {
    path: 'my-devices/device-registration/register',
    element: <DeviceRegistrationPage />,
    isMobileFriendly: true,
  },
];
