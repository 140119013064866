import {
  DeviceHomeAlertData,
  EulaOutstandingData,
  GuidedProcessSteps,
  SoftwareHomeAlertData,
} from '@zspace/types';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Columns, Element } from 'react-bulma-components';
import { CgSpinner } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { fetchDevicesHomeAlertData } from '../../devices/devices-service';
import { fetchEulasOutstandingData } from '../../eula/eula-service';
import { fetchSoftwareSeatsHomeAlertData } from '../../software/software-service';
import StepTab from '../../ui/step-tab/step-tab';
import Conditional from '../conditional/conditional';
import {
  GuidedProcessContext,
  GuidedProcessRefreshAction,
} from '../context/guided-process-context';
import useError from '../hooks/error';
import useIsCustomerUser from '../hooks/is-customer-user';
import useIsZspaceInternalUser from '../hooks/is-zspace-internal-user';
import useUserPendingTasks from '../hooks/user-pending-tasks';
import TaskTag from '../task-tag/task-tag';
import styles from './guided-process-stepper-tabs.module.scss';

export type GuidedProcessStepperTabsProps = {
  activeTab: GuidedProcessSteps;
};

type InformationTag = { tasks: string[]; isLoading: boolean };

type StepData = {
  title: GuidedProcessSteps;
  informationTag?: ReactNode;
  onClick: () => void;
};

export function GuidedProcessStepperTabs({
  activeTab,
}: GuidedProcessStepperTabsProps) {
  const navigate = useNavigate();
  const { setError } = useError();
  const isCustomerUser = useIsCustomerUser();
  const isZspaceInternalUser = useIsZspaceInternalUser();
  const [loading, setLoading] = useState({
    salesOrderAndEula: false,
    devices: false,
    software: false,
  });
  const [salesOrdersAndEulaTasksData, setSalesOrdersAndEulaTasksData] =
    useState<EulaOutstandingData>({
      outstandingCount: 0,
      acceptableByUser: false,
    });
  const [devicesTasksData, setDevicesTasksData] = useState<DeviceHomeAlertData>(
    {
      ungroupedDevicesCount: 0,
      unregisteredDevicesCount: 0,
    }
  );
  const [softwareTasksData, setSoftwareTasksData] =
    useState<SoftwareHomeAlertData>({ unassignedSoftwareCount: 0 });
  const userTasks = useUserPendingTasks(
    salesOrdersAndEulaTasksData,
    devicesTasksData,
    softwareTasksData
  );
  const { refreshAction } = useContext(GuidedProcessContext);

  const navigateToSalesOrdersAndEulaPage = useCallback(() => {
    navigate('/my-sales-orders');
  }, [navigate]);

  const navigateToMyTeamPage = useCallback(() => {
    navigate('/users/my-team');
  }, [navigate]);

  const navigateToDevicesPage = useCallback(() => {
    navigate('/my-devices');
  }, [navigate]);

  const navigateToSoftwarePage = useCallback(() => {
    navigate('/my-software');
  }, [navigate]);

  const InformationTag = useCallback(({ tasks, isLoading }: InformationTag) => {
    if (isLoading) {
      return <CgSpinner className="animate-spin" />;
    }
    return (
      <Conditional condition={tasks.length > 0}>
        <Conditional.True>
          {tasks.map((task) => {
            return <TaskTag taskContent={task} key={task} pending />;
          })}
        </Conditional.True>
        <Conditional.False>
          <TaskTag taskContent="No actions pending" />
        </Conditional.False>
      </Conditional>
    );
  }, []);

  const steps: StepData[] = useMemo(() => {
    if (isCustomerUser) {
      return [
        {
          title: GuidedProcessSteps.SALES_ORDERS_AND_EULA,
          informationTag: (
            <InformationTag
              tasks={userTasks.salesOrderAndEulaPendingTasks}
              isLoading={loading.salesOrderAndEula}
            />
          ),
          onClick: navigateToSalesOrdersAndEulaPage,
        },
        { title: GuidedProcessSteps.TEAM, onClick: navigateToMyTeamPage },
        {
          title: GuidedProcessSteps.DEVICES,
          informationTag: (
            <InformationTag
              tasks={userTasks.devicesPendingTasks}
              isLoading={loading.devices}
            />
          ),
          onClick: navigateToDevicesPage,
        },
        {
          title: GuidedProcessSteps.SOFTWARE,
          informationTag: (
            <InformationTag
              tasks={userTasks.softwarePendingTasks}
              isLoading={loading.software}
            />
          ),
          onClick: navigateToSoftwarePage,
        },
      ];
    } else if (isZspaceInternalUser) {
      return [
        {
          title: GuidedProcessSteps.SALES_ORDERS_AND_EULA,
          onClick: navigateToSalesOrdersAndEulaPage,
        },
        { title: GuidedProcessSteps.TEAM, onClick: navigateToMyTeamPage },
        {
          title: GuidedProcessSteps.DEVICES,
          onClick: navigateToDevicesPage,
        },
        {
          title: GuidedProcessSteps.SOFTWARE,
          onClick: navigateToSoftwarePage,
        },
      ];
    }
    return [];
  }, [
    InformationTag,
    isCustomerUser,
    isZspaceInternalUser,
    loading.devices,
    loading.salesOrderAndEula,
    loading.software,
    navigateToDevicesPage,
    navigateToSalesOrdersAndEulaPage,
    navigateToSoftwarePage,
    navigateToMyTeamPage,
    userTasks.devicesPendingTasks,
    userTasks.salesOrderAndEulaPendingTasks,
    userTasks.softwarePendingTasks,
  ]);

  const isActiveStepTab = useCallback(
    (tab: GuidedProcessSteps) => {
      return tab === activeTab;
    },
    [activeTab]
  );

  const getEulasOutstandingAlertData = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, salesOrderAndEula: true }));
      const response = await fetchEulasOutstandingData();
      setSalesOrdersAndEulaTasksData(response);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setLoading((prev) => ({ ...prev, salesOrderAndEula: false }));
    }
  }, [setError]);

  const getDevicesHomeAlertData = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, devices: true }));
      const response = await fetchDevicesHomeAlertData();
      setDevicesTasksData(response);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setLoading((prev) => ({ ...prev, devices: false }));
    }
  }, [setError]);

  const getSoftwareSeatsHomeAlertDataRequest = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, software: true }));
      const response = await fetchSoftwareSeatsHomeAlertData();
      setSoftwareTasksData(response);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setLoading((prev) => ({ ...prev, software: false }));
    }
  }, [setError]);

  const getAllAlerts = useCallback(async () => {
    try {
      setLoading({ salesOrderAndEula: true, devices: true, software: true });
      const [salesOrderAndEulaTasks, devicesTasks, softwareTasks] =
        await Promise.all([
          fetchEulasOutstandingData(),
          fetchDevicesHomeAlertData(),
          fetchSoftwareSeatsHomeAlertData(),
        ]);

      setSalesOrdersAndEulaTasksData(salesOrderAndEulaTasks);
      setDevicesTasksData(devicesTasks);
      setSoftwareTasksData(softwareTasks);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setLoading({ salesOrderAndEula: false, devices: false, software: false });
    }
  }, [setError]);

  useEffect(() => {
    if (isCustomerUser) {
      getAllAlerts();
    }
  }, [getAllAlerts, isCustomerUser, setError]);

  useEffect(() => {
    switch (refreshAction?.action) {
      case GuidedProcessRefreshAction.ALL:
        getAllAlerts();
        break;
      case GuidedProcessRefreshAction.SALES_ORDERS_AND_EULA:
        getEulasOutstandingAlertData();
        break;
      case GuidedProcessRefreshAction.DEVICES:
        getDevicesHomeAlertData();
        break;
      case GuidedProcessRefreshAction.SOFTWARE:
        getSoftwareSeatsHomeAlertDataRequest();
        break;
    }
  }, [
    getAllAlerts,
    getDevicesHomeAlertData,
    getEulasOutstandingAlertData,
    getSoftwareSeatsHomeAlertDataRequest,
    refreshAction,
  ]);

  return (
    <Element className={`${styles.container} mt-4 mx-10`}>
      <Columns
        display="flex"
        alignItems="stretch"
        className={`is-gapless ${styles.stepColumns}`}
      >
        {steps.map((step) => (
          <Columns.Column key={step.title} display="flex">
            <StepTab
              title={step.title}
              informationTag={step.informationTag}
              active={isActiveStepTab(step.title)}
              onClick={step.onClick}
            />
          </Columns.Column>
        ))}
      </Columns>
    </Element>
  );
}

export default GuidedProcessStepperTabs;
