import { SoftwareTitleForAssignment } from '@zspace/types';
import {
  SOFTWARE_TITLE_FOR_ASSIGNMENT_STORAGE_KEY,
  SessionStorageServiceImpl,
} from '../shared/storage';

export class SoftwareTitlesForAssignmentStorageService {
  private storageService: SessionStorageServiceImpl<
    SoftwareTitleForAssignment[]
  > = new SessionStorageServiceImpl();

  public get() {
    return (
      this.storageService.getItem(SOFTWARE_TITLE_FOR_ASSIGNMENT_STORAGE_KEY) ||
      []
    );
  }
  public save(softwareTitleForAssignment: SoftwareTitleForAssignment[]) {
    return this.storageService.setItem(
      SOFTWARE_TITLE_FOR_ASSIGNMENT_STORAGE_KEY,
      softwareTitleForAssignment
    );
  }
  public remove() {
    return this.storageService.removeItem(
      SOFTWARE_TITLE_FOR_ASSIGNMENT_STORAGE_KEY
    );
  }
}
