import { faker } from '@faker-js/faker';
import { array, define } from 'cooky-cutter';
import { SalesOrder, SalesOrderAssignment, User, UserRoleType } from '../types';
import {
  permissionsGroupFactory,
  permissionsGroupNames,
  permissionsGroupUserRoleAssignmentFactory,
} from './permissions';
import { rolesFactory } from './roles';
import { salesOrderFactory } from './sales-orders';
import { userRoleAssignmentFactory } from './user-role-assignment';

export const salesOrderAssigmentFactory = define<SalesOrderAssignment>({
  id: () => faker.string.uuid(),
  createdAt: () => faker.date.recent(),
  salesOrder: salesOrderFactory,
});

export const userFactory = define<User>({
  id: () => faker.string.uuid(),
  externalId: () => faker.string.uuid(),
  email: () => faker.internet.email(),
  firstName: () => faker.person.firstName(),
  lastName: () => faker.person.lastName(),
  roles: () => [],
  lastActive: () => faker.date.recent(),
  avatarUrl: '',
  deviceGroups: () => [],
  isAllowedToAccess: () => faker.datatype.boolean(),
});

type PermissionsGroupName = (typeof permissionsGroupNames)[number];

export const userFactoryWithPermissionsGroup = (
  userRoleType: UserRoleType,
  permissionsGroupName: PermissionsGroupName,
  salesOrder?: SalesOrder
) => {
  const user = userFactory();
  const role = rolesFactory({ name: userRoleType });

  const permissionsGroup = permissionsGroupFactory({
    name: permissionsGroupName,
  });
  const permissionsGroupUserRoleAssignments = array(
    permissionsGroupUserRoleAssignmentFactory,
    1
  )({ permissionsGroup, salesOrder });
  permissionsGroupUserRoleAssignmentFactory();

  user.roles = [
    userRoleAssignmentFactory({
      role,
      permissionsGroupUserRoleAssignments,
    }),
  ];

  user.activeRole = user.roles[0];

  return user;
};

export const superAdminUserFactory = () =>
  userFactoryWithPermissionsGroup(
    UserRoleType.ZSPACE_INTERNAL,
    permissionsGroupNames[0]
  );

export const endUserSoftwareContactUserFactory = () =>
  userFactoryWithPermissionsGroup(
    UserRoleType.CUSTOMER,
    permissionsGroupNames[7],
    salesOrderFactory()
  );

export const warehousePartnerUserFactory = () =>
  userFactoryWithPermissionsGroup(
    UserRoleType.PARTNER,
    permissionsGroupNames[6]
  );
