import {
  Device,
  DeviceGroup,
  SoftwareTitleForAssignment,
  WorkOrderDetailPageContext,
} from '@zspace/types';
import {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Columns, Section } from 'react-bulma-components';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { DeviceForSoftwareAssignmentStorageService } from '../../device-groups/device-for-software-assignment-storage-service';
import { SoftwareTitlesForAssignmentStorageService } from '../../device-groups/software-titles-for-assignment-storage-service';
import If from '../../shared/if/if';
import BoxLayout from '../../ui/box-layout/box-layout';

export type ManageSoftwareLayoutContext = {
  selectedSoftware: SoftwareTitleForAssignment[];
  deviceGroup: DeviceGroup;
  selectedDevices: Device[];
  setTitle: (value: string) => void;
  setSelectedDevices: (value: Device[]) => void;
  setSelectedSoftware: (value: SoftwareTitleForAssignment[]) => void;
  isInvalidSelection: (items: SoftwareTitleForAssignment[]) => boolean;
  setHeaderRightContent: (value: ReactNode) => void;
  revalidate: () => void;
};

const deviceStorage = new DeviceForSoftwareAssignmentStorageService();
const softwareStorage = new SoftwareTitlesForAssignmentStorageService();

export function ManageSoftwareLayout() {
  const context = useOutletContext<WorkOrderDetailPageContext>();
  const location = useLocation();
  const [title, setTitle] = useState('Assign software');
  const [selectedDevices, _setSelectedDevices] = useState(deviceStorage.get());
  const [headerRightContent, setHeaderRightContent] = useState<ReactNode>();

  const [selectedSoftware, _setSelectedSoftware] = useState(
    softwareStorage.get()
  );

  const subtitle = useMemo(() => {
    return `Managing ${selectedDevices.length} ${
      selectedDevices.length === 1 ? `device` : `devices`
    }${
      selectedDevices[0] ? ` - ${selectedDevices[0].model.displayName} ` : ``
    }`;
  }, [selectedDevices]);

  const isInvalidSelection = useCallback(
    (items: SoftwareTitleForAssignment[]) => {
      let notEnoughSeats = false;
      let repeatedSoftwareTitle = false;
      for (const item of items) {
        if (item.seats.available < selectedDevices.length) {
          notEnoughSeats = true;
          break;
        }
        for (const _selectedSoftware of selectedSoftware) {
          const isSoftwareRepeated =
            _selectedSoftware.licensingProductGroup.displayName ===
            item.licensingProductGroup.displayName;
          const isSoftwareSelectedFromDifferentSalesOrder =
            _selectedSoftware.salesOrder.id !== item.salesOrder.id;
          const isSoftwareSelectedFromDifferentItemFulfillment =
            _selectedSoftware.itemFulfillment.id !== item.itemFulfillment.id;
          if (
            isSoftwareRepeated &&
            (isSoftwareSelectedFromDifferentSalesOrder ||
              isSoftwareSelectedFromDifferentItemFulfillment)
          ) {
            repeatedSoftwareTitle = true;
            break;
          }
        }
      }
      return notEnoughSeats || repeatedSoftwareTitle;
    },
    [selectedDevices.length, selectedSoftware]
  );

  const setSelectedDevices = useCallback((value: Device[]): void => {
    deviceStorage.save(value);
    if (value.length === 0) {
      deviceStorage.remove();
    }

    _setSelectedDevices(value);
  }, []);

  const setSelectedSoftware = useCallback(
    (value: SoftwareTitleForAssignment[]): void => {
      softwareStorage.save(value);
      if (value.length === 0) {
        softwareStorage.remove();
      }

      _setSelectedSoftware(value);
    },
    []
  );

  useLayoutEffect(() => {
    setHeaderRightContent(null);
  }, [location.pathname]);

  return (
    <Section paddingless py={4}>
      <BoxLayout
        className="is-min-height-80 mx-10 my-4"
        header={
          <Columns marginless>
            <Columns.Column
              display="flex"
              flexDirection="column"
              className="p-4"
            >
              <h1 className="is-size-3 has-text-weight-light">{title}</h1>
              <h2 className="is-size-5 has-text-weight-light">{subtitle}</h2>
            </Columns.Column>
            <If condition={!!headerRightContent}>
              <Columns.Column display="flex" justifyContent="end">
                {headerRightContent}
              </Columns.Column>
            </If>
          </Columns>
        }
      >
        <Outlet
          context={
            {
              selectedDevices,
              selectedSoftware,
              setSelectedSoftware,
              setTitle,
              setSelectedDevices,
              isInvalidSelection,
              setHeaderRightContent,
              ...(context && { revalidate: context.revalidate }),
            } as ManageSoftwareLayoutContext
          }
        />
      </BoxLayout>
    </Section>
  );
}

export default ManageSoftwareLayout;
