import {
  getUserHighestPermissionsGroupUserRoleAssignment,
  PermissionsGroupName,
} from '@zspace/roles';
import { User, UserRoleType } from '@zspace/types';
import { ChangeEvent, useCallback, useState } from 'react';
import { Columns, Element, Form } from 'react-bulma-components';
import { FaFloppyDisk } from 'react-icons/fa6';
import useUserAllowedPermissionsGroupsToManage from '../../shared/hooks/user-allowed-permissions-groups-to-manage';
import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';
import styles from './edit-user-permissions-modal.module.scss';

type EditUserPermissionsFormChangeEvent = ChangeEvent<HTMLSelectElement>;

const TITLE = (email: string) => `Edit permissions for ${email}`;
const LABEL = 'Role';
const CANCEL_BUTTON_TEXT = 'Cancel';
const SAVE_BUTTON_TEXT = 'Save';

export type EditUserPermissionsModalProps = {
  show: boolean;
  user: User;
  onCancel: () => void;
  onEditUserPermissions: (
    id: string,
    newPermissionsGroup: PermissionsGroupName
  ) => Promise<void>;
};

export function EditUserPermissionsModal({
  show,
  user,
  onCancel,
  onEditUserPermissions,
}: EditUserPermissionsModalProps) {
  const userAllowedPermissionsGroupsToManage =
    useUserAllowedPermissionsGroupsToManage();
  const [newPermissionsGroup, setNewPermissionsGroup] =
    useState<PermissionsGroupName>(() => {
      const isWarehousePartnerUser = user.roles.every(
        (roleAssignment) => roleAssignment.role.name === UserRoleType.PARTNER
      );

      if (isWarehousePartnerUser) {
        return PermissionsGroupName.WAREHOUSE_PARTNER as PermissionsGroupName;
      }

      const userHighestGroupPermissionsGroup =
        getUserHighestPermissionsGroupUserRoleAssignment(
          user,
          UserRoleType.ZSPACE_INTERNAL
        );
      const currentPermissionsGroup =
        userHighestGroupPermissionsGroup?.permissionsGroup?.name ??
        userAllowedPermissionsGroupsToManage[0];
      return currentPermissionsGroup as PermissionsGroupName;
    });
  const [isSavingNewPermissionsGroup, setIsSavingNewPermissionsGroup] =
    useState<boolean>(false);

  const onInputChange = useCallback(
    (event: EditUserPermissionsFormChangeEvent) => {
      const newValue = event.target.value as PermissionsGroupName;
      setNewPermissionsGroup(newValue);
    },
    [setNewPermissionsGroup]
  );

  const handleOnSave = useCallback(async () => {
    setIsSavingNewPermissionsGroup(true);
    await onEditUserPermissions(user.id, newPermissionsGroup);
    setIsSavingNewPermissionsGroup(false);
  }, [newPermissionsGroup, onEditUserPermissions, user.id]);

  return (
    <Modal show={show}>
      <form>
        <h1 className={`has-text-weight-light is-size-4 ${styles.title}`}>
          {TITLE(user.email)}
        </h1>
        <Columns marginless py={4}>
          <Columns.Column paddingless size="half">
            <Form.Label
              htmlFor="permissionsGroup"
              className="has-text-weight-normal is-size-7"
            >
              {LABEL}
            </Form.Label>
            <Form.Field id="permissionsGroup">
              <Form.Select
                className="is-size-7"
                fullwidth
                value={newPermissionsGroup}
                onChange={onInputChange}
              >
                {userAllowedPermissionsGroupsToManage.map(
                  (permissionsGroup) => (
                    <option key={permissionsGroup} value={permissionsGroup}>
                      {permissionsGroup}
                    </option>
                  )
                )}
              </Form.Select>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Element display="flex" justifyContent="flex-end" className="gap-6">
          <Button color="primary-dark" outlined onClick={onCancel}>
            {CANCEL_BUTTON_TEXT}
          </Button>
          <Button
            color="primary-dark"
            onClick={handleOnSave}
            type="button"
            isExecutingAction={isSavingNewPermissionsGroup}
          >
            <Button.LoadingIcon icon={FaFloppyDisk} />
            <span>{SAVE_BUTTON_TEXT}</span>
          </Button>
        </Element>
      </form>
    </Modal>
  );
}

export default EditUserPermissionsModal;
