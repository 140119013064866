import {
  BasicSalesOrderData,
  Criteria,
  EditedUser,
  EmailAvailability,
  MyCourses,
  NewCustomerUser,
  NewUser,
  PaginatedAPIResponse,
  SalesOrder,
  User,
  UserSalesOrdersCriteria,
  UsersCriteria,
} from '@zspace/types';
import { http } from '../shared/http';

export interface UsersService {
  fetchMe: () => Promise<User>;
  fetchUser: (id: string) => Promise<User>;
  fetchUsers: (criteria: UsersCriteria) => Promise<PaginatedAPIResponse<User>>;
  fetchEmailAvailability: (email: string) => Promise<EmailAvailability>;
  inviteUser: (newUser: NewUser | NewCustomerUser) => Promise<void>;
  inviteTeamUser: (newUser: NewUser | NewCustomerUser) => Promise<void>;
  editUserPermissions: (id: string, userData: EditedUser) => Promise<void>;
  editTeamUserPermissions: (id: string, userData: EditedUser) => Promise<void>;
  disableUser: (id: string) => Promise<void>;
  fetchMyTeam: (criteria: UsersCriteria) => Promise<PaginatedAPIResponse<User>>;
  fetchMyCourses: () => Promise<MyCourses>;
  fetchMySalesOrders: (
    criteria: Criteria
  ) => Promise<PaginatedAPIResponse<BasicSalesOrderData>>;
  switchRole: (roleAssignmentId: string) => Promise<void>;
}

export class UsersServiceImpl implements UsersService {
  public async fetchMe(): Promise<User> {
    const { data } = await http('/v1/users/me');

    return data;
  }

  public async fetchUser(id: string): Promise<User> {
    const { data } = await http(`/v1/users/${id}`);

    return data;
  }

  public async fetchUsers(
    criteria: UsersCriteria
  ): Promise<PaginatedAPIResponse<User>> {
    const { data } = await http(
      '/v1/users?' +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchUserSalesOrders(
    userId: string,
    criteria: UserSalesOrdersCriteria
  ): Promise<PaginatedAPIResponse<SalesOrder>> {
    const { data } = await http(
      `/v1/users/${userId}/sales-orders?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchEmailAvailability(
    email: string
  ): Promise<EmailAvailability> {
    const { data } = await http(
      `/v1/users/email-availability?` + new URLSearchParams({ email })
    );

    return data;
  }

  public async inviteUser(newUser: NewUser | NewCustomerUser): Promise<void> {
    const { data } = await http.post('/v1/users', newUser);

    return data;
  }

  public async inviteTeamUser(
    newUser: NewUser | NewCustomerUser
  ): Promise<void> {
    const { data } = await http.post('/v1/users/my-team', newUser);

    return data;
  }

  public async editUserPermissions(
    id: string,
    userData: EditedUser
  ): Promise<void> {
    const { data } = await http.patch(`/v1/users/${id}/permissions`, userData);

    return data;
  }

  public async editTeamUserPermissions(
    id: string,
    userData: EditedUser
  ): Promise<void> {
    const { data } = await http.patch(
      `/v1/users/my-team/${id}/permissions`,
      userData
    );

    return data;
  }

  public async disableUser(id: string): Promise<void> {
    return await http.delete(`/v1/users/${id}`);
  }

  public async fetchMyCourses(): Promise<MyCourses> {
    const { data } = await http('/v1/users/courses');

    return data;
  }

  public async fetchMyTeam(
    criteria: UsersCriteria
  ): Promise<PaginatedAPIResponse<User>> {
    const { data } = await http(
      '/v1/users/my-team?' +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchMySalesOrders(
    criteria: Criteria
  ): Promise<PaginatedAPIResponse<BasicSalesOrderData>> {
    const { data } = await http(
      '/v1/users/me/sales-orders?' +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async switchRole(roleAssignmentId: string): Promise<void> {
    return await http.patch('/v1/users/me/roles', {
      roleAssignmentId,
    });
  }
}

export const fetchMe = async (): Promise<User> => {
  const service = new UsersServiceImpl();

  return service.fetchMe();
};

export const fetchUser = async (id: string): Promise<User> => {
  const service = new UsersServiceImpl();

  return service.fetchUser(id);
};

export const fetchUsers = async (
  criteria: UsersCriteria
): Promise<PaginatedAPIResponse<User>> => {
  const service = new UsersServiceImpl();

  return service.fetchUsers(criteria);
};

export const fetchUserSalesOrders = async (
  userId: string,
  criteria: UserSalesOrdersCriteria
): Promise<PaginatedAPIResponse<SalesOrder>> => {
  const service = new UsersServiceImpl();

  return service.fetchUserSalesOrders(userId, criteria);
};

export const fetchEmailAvailability = async (
  email: string
): Promise<EmailAvailability> => {
  const service = new UsersServiceImpl();

  return service.fetchEmailAvailability(email);
};

export const inviteUser = async (
  newUser: NewUser | NewCustomerUser
): Promise<void> => {
  const service = new UsersServiceImpl();

  return service.inviteUser(newUser);
};

export const inviteTeamUser = async (
  newUser: NewUser | NewCustomerUser
): Promise<void> => {
  const service = new UsersServiceImpl();

  return service.inviteTeamUser(newUser);
};

export const editUserPermissions = async (
  id: string,
  userData: EditedUser
): Promise<void> => {
  const service = new UsersServiceImpl();

  return service.editUserPermissions(id, userData);
};

export const editTeamUserPermissions = async (
  id: string,
  userData: EditedUser
): Promise<void> => {
  const service = new UsersServiceImpl();

  return service.editTeamUserPermissions(id, userData);
};

export const disableUser = async (id: string): Promise<void> => {
  const service = new UsersServiceImpl();

  return service.disableUser(id);
};

export const fetchMyTeam = async (
  criteria: UsersCriteria
): Promise<PaginatedAPIResponse<User>> => {
  const service = new UsersServiceImpl();

  return service.fetchMyTeam(criteria);
};

export const fetchMyCourses = async (): Promise<MyCourses> => {
  const service = new UsersServiceImpl();

  return service.fetchMyCourses();
};

export const fetchMySalesOrders = async (
  criteria: Criteria
): Promise<PaginatedAPIResponse<BasicSalesOrderData>> => {
  const service = new UsersServiceImpl();

  return service.fetchMySalesOrders(criteria);
};

export const switchRole = async (roleAssignmentId: string): Promise<void> => {
  const service = new UsersServiceImpl();

  return service.switchRole(roleAssignmentId);
};
