import {
  ChangeEvent,
  DetailedHTMLProps,
  SelectHTMLAttributes,
  useCallback,
  useMemo,
} from 'react';
import { Columns, Element, Form, Icon } from 'react-bulma-components';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import If from '../../shared/if/if';
import Button from '../button/button';
import { TableFormConfig } from '../table/table-form/table-form';
import styles from './paginator.module.scss';

const PAGES_DISPLAY_LIMIT = 5;

type PaginatorConfigProps = Pick<
  TableFormConfig,
  'pages' | 'itemsPerPage' | 'pageNumber'
> & { disableItemsPerPageSelection?: boolean };

export type PaginatorProps = {
  config: PaginatorConfigProps;
  itemsPerPageOptions?: number[];
  onItemsPerPageChange: (itemsPerPage: number) => void;
  onPageNumberChange: (pageNumber: number) => void;
  disableItemsPerPageSelection?: boolean;
};

export function Paginator({
  config,
  itemsPerPageOptions = [10, 20, 30],
  onItemsPerPageChange,
  onPageNumberChange,
  disableItemsPerPageSelection,
}: PaginatorProps) {
  const totalPages = useMemo(() => config.pages || 1, [config.pages]);
  const pages = useMemo(
    () => Array.from({ length: totalPages }, (_, i) => i + 1),
    [totalPages]
  );

  const currentPage = useMemo(() => config.pageNumber, [config.pageNumber]);

  const itemsPerPage = useMemo(
    () => config.itemsPerPage,
    [config.itemsPerPage]
  );

  // Calculate the intermediate pages to display given the fact that the first and last pages are always displayed
  const intermediatePages = useMemo(() => {
    // If there are less pages than the limit, return all intermediate pages
    if (totalPages <= PAGES_DISPLAY_LIMIT) {
      return pages.slice(1, totalPages - 1);
    } else {
      // If the current page is less or equal than the limit, return just the first pages [2:5]
      if (currentPage <= PAGES_DISPLAY_LIMIT)
        return pages.slice(1, PAGES_DISPLAY_LIMIT);

      // If the current page is one of the last 4 pages, return the previous 4 pages before the last one [totalPages - 4:totalPages - 1]
      if (currentPage > totalPages - (PAGES_DISPLAY_LIMIT - 1))
        return pages.slice(totalPages - PAGES_DISPLAY_LIMIT, totalPages - 1);

      // Otherwise, return the previous, current and next pages
      const startIndex = currentPage - 2;
      const endIndex = currentPage + 1;
      return pages.slice(startIndex, endIndex);
    }
  }, [currentPage, totalPages, pages]);

  const showInitialEllipsis = useMemo(
    () => currentPage > PAGES_DISPLAY_LIMIT && totalPages > PAGES_DISPLAY_LIMIT,
    [currentPage, totalPages]
  );

  const showFinalEllipsis = useMemo(
    () =>
      currentPage <= totalPages - (PAGES_DISPLAY_LIMIT - 1) &&
      totalPages > PAGES_DISPLAY_LIMIT,
    [currentPage, totalPages]
  );

  const handlePreviousPageButton = useCallback(() => {
    if (currentPage > 1) {
      onPageNumberChange(currentPage - 1);
    }
  }, [currentPage, onPageNumberChange]);

  const handleNextPageButton = useCallback(() => {
    if (currentPage < totalPages) {
      onPageNumberChange(currentPage + 1);
    }
  }, [currentPage, totalPages, onPageNumberChange]);

  const handlePageClick = useCallback(
    (page: number) => {
      onPageNumberChange(page);
    },
    [onPageNumberChange]
  );

  const handleItemsPerPageChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      onItemsPerPageChange(parseInt(e.target.value));
    },
    [onItemsPerPageChange]
  );

  const isActivePage = useCallback(
    (page: number) => {
      return currentPage === page;
    },
    [currentPage]
  );

  const pageNumberClassName = useCallback(
    (page: number) => {
      const defaultClassName = 'has-text-primary-dark is-clickable';
      const activeClassName = isActivePage(page)
        ? 'has-background-primary-light'
        : 'is-underlined';
      return `${defaultClassName} ${activeClassName}`;
    },
    [isActivePage]
  );

  return (
    <Element
      marginless
      paddingless
      className={`is-flex is-align-items-center ${styles.paginatorContainer}`}
    >
      <If condition={!disableItemsPerPageSelection}>
        <Form.Control>
          <Paginator.PageSelect
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            name="itemsPerPage"
            id="itemsPerPage"
            aria-label="Items per page"
            className="custom-select ml-2 has-text-primary-dark is-size-7"
            label="per page"
            labelClassName="ml-2 is-size-7"
          >
            {itemsPerPageOptions.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Paginator.PageSelect>
        </Form.Control>

        <Element className={styles.divider} />
      </If>
      <Form.Control className="is-flex is-align-items-center">
        <Button
          text
          aria-label="Go to previous page"
          type="button"
          onClick={handlePreviousPageButton}
        >
          <Icon color="primary-dark" className="is-size-7">
            <FaChevronLeft />
          </Icon>
        </Button>

        <Columns display="flex" alignItems="center" marginless>
          {totalPages === 1 && (
            <Paginator.Page
              className="has-text-primary-dark is-clickable"
              page={1}
            />
          )}

          {totalPages > 1 && (
            <>
              <Paginator.Page
                page={pages[0]}
                className={pageNumberClassName(pages[0])}
                onClick={() => handlePageClick(pages[0])}
              />

              <Paginator.Ellipsis show={showInitialEllipsis} />

              {intermediatePages.map((page) => (
                <Paginator.Page
                  key={page}
                  page={page}
                  className={pageNumberClassName(page)}
                  onClick={() => handlePageClick(page)}
                />
              ))}

              <Paginator.Ellipsis show={showFinalEllipsis} />

              <Paginator.Page
                page={pages[pages.length - 1]}
                className={pageNumberClassName(pages[pages.length - 1])}
                onClick={() => handlePageClick(pages[pages.length - 1])}
              />
            </>
          )}
        </Columns>

        <Button
          text
          aria-label="Go to next page"
          type="button"
          onClick={handleNextPageButton}
        >
          <Icon color="primary-dark" className="is-size-7">
            <FaChevronRight />
          </Icon>
        </Button>
      </Form.Control>
    </Element>
  );
}

type PageSelectProps = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  label: string;
  labelClassName: string;
};

const PageSelect = ({
  label,
  labelClassName,
  children,
  ...selectProps
}: PageSelectProps) => {
  return (
    <>
      <select {...selectProps}>{children}</select>
      <span className={labelClassName}>{label}</span>
    </>
  );
};

const Ellipsis = ({ show }: { show: boolean }) => {
  return show ? (
    <Columns.Column className={styles.pageContainer}>
      <p>...</p>
    </Columns.Column>
  ) : null;
};

type PageProps = {
  page: number;
  onClick?: () => void;
  className: string;
};

const Page = ({ page, onClick, className }: PageProps) => {
  return (
    <Columns.Column
      key={page}
      className={`${className} ${styles.pageContainer}`}
      onClick={onClick}
    >
      <span className="is-size-7">{page}</span>
    </Columns.Column>
  );
};

Paginator.PageSelect = PageSelect;
Paginator.Ellipsis = Ellipsis;
Paginator.Page = Page;

export default Paginator;
