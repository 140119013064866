import { formatSalesOrderNumber } from '@zspace/format';
import { EditDeviceGroupsTableRow } from '@zspace/types';
import { useMemo } from 'react';
import { Element } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { formatDateToLocaleString } from '../../../shared/utils';
import PaginatedTable, {
  CustomPaginatedTableProps,
} from '../../../ui/table/paginated-table/paginated-table';
import { Column } from '../../../ui/table/types';

export type EditDeviceGroupsTableProps =
  CustomPaginatedTableProps<EditDeviceGroupsTableRow>;

export function EditDeviceGroupsTable({
  dataSource,
  onChange,
  config,
  form,
}: EditDeviceGroupsTableProps) {
  const columns: Column<EditDeviceGroupsTableRow>[] = [
    {
      key: 'number',
      widthClassname: 'is-size-20',
      render: (el) => (
        <Link to={`/sales-orders/${el.order.id}`}>
          {formatSalesOrderNumber(el.order.number)}
        </Link>
      ),
      title: 'Sales order',
      sortable: true,
    },
    {
      key: 'model_name',
      widthClassname: 'is-size-30',
      render: (el) => <p>{el.deviceModel.name}</p>,
      title: 'Device Type',
      sortable: true,
    },
    {
      key: 'purchase',
      widthClassname: 'is-size-10',
      render: (el) => formatDateToLocaleString(el.purchaseDate),
      title: 'Purchase date',
      sortable: true,
    },
    {
      key: 'close',
      widthClassname: 'is-size-10',
      render: (el) => formatDateToLocaleString(el.closeDate),
      title: 'Close date',
      sortable: true,
    },
    {
      key: 'total',
      widthClassname: 'is-size-10',
      render: (el) => el.totalDevices,
      title: 'Total',
      sortable: true,
    },
    {
      key: 'available',
      widthClassname: 'is-size-10',
      render: (el) => el.totalAvailable,
      title: 'Available',
      sortable: true,
    },
    {
      key: 'devices',
      widthClassname: 'is-size-10',
      render: (el) => el.deviceGroupAssignedDevices,
      title: 'Devices',
      sortable: false,
    },
  ];

  const emptyContentText = useMemo(() => {
    const hasAppliedFilters = config.search !== '';
    if (hasAppliedFilters) {
      return 'There are no devices that match the selected criteria';
    }

    return 'There are no devices at the moment';
  }, [config.search]);

  return (
    <PaginatedTable
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      config={config}
      form={form}
      rowKey={'id'}
      empty={
        <Element display="flex" justifyContent="center" className="my-2">
          <span className="has-text-weight-light">{emptyContentText}</span>
        </Element>
      }
    />
  );
}

export default EditDeviceGroupsTable;
