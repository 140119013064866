import { isZspaceInternalUser } from '@zspace/roles';
import useUser from './user';

export function useIsZspaceInternalUser() {
  const { user: currentUser } = useUser();

  return isZspaceInternalUser(currentUser);
}

export default useIsZspaceInternalUser;
