import { DeviceDetailsTableRow } from '@zspace/types';
import { ComponentProps, useCallback, useState } from 'react';
import { Columns } from 'react-bulma-components';
import { Outlet } from 'react-router-dom';

export type DeviceReplacementContext = {
  faultyDevice: DeviceDetailsTableRow;
  setFaultyDevice: (value: DeviceDetailsTableRow) => void;
  deviceFailureReason: string;
  setDeviceFailureReason: (value: string) => void;
  replacementDevice: DeviceDetailsTableRow;
  setReplacementDevice: (value: DeviceDetailsTableRow) => void;
  cleanDeviceReplacementData: () => void;
};

export const columnSizes: ComponentProps<typeof Columns.Column> = {
  mobile: { size: 12 },
  tablet: { size: 8 },
  desktop: { size: 8 },
  widescreen: { size: 6 },
  fullhd: { size: 5 },
};

export function DeviceReplacementLayout() {
  const [faultyDevice, setFaultyDevice] = useState<DeviceDetailsTableRow>();
  const [deviceFailureReason, setDeviceFailureReason] = useState<string>();
  const [replacementDevice, setReplacementDevice] =
    useState<DeviceDetailsTableRow>();

  const cleanDeviceReplacementData = useCallback(() => {
    setFaultyDevice(undefined);
    setDeviceFailureReason(undefined);
    setReplacementDevice(undefined);
  }, []);

  return (
    <Outlet
      context={
        {
          faultyDevice,
          setFaultyDevice,
          deviceFailureReason,
          setDeviceFailureReason,
          replacementDevice,
          setReplacementDevice,
          cleanDeviceReplacementData,
        } as DeviceReplacementContext
      }
    />
  );
}

export default DeviceReplacementLayout;
