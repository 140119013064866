import { ReactNode, useCallback, useMemo } from 'react';
import { Box, Element } from 'react-bulma-components';
import styles from './info-card.module.scss';

export type InfoCardProps = {
  title?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  showError?: boolean;
  clickable?: boolean;
  fullWidth?: boolean;
};

export function InfoCard({
  title,
  footer,
  children,
  active = false,
  disabled = false,
  onClick,
  showError = false,
  clickable = true,
  fullWidth = false,
}: InfoCardProps) {
  const boxStyles = useMemo(() => {
    const _styles = [styles.container];
    if (showError) {
      _styles.push(styles.activeError);
    } else if (active) {
      _styles.push(styles.active);
    }
    if (disabled) {
      _styles.push(styles.disabled);
    }
    if (clickable) {
      _styles.push('is-clickable');
    }
    if (fullWidth) {
      _styles.push(styles.fullWidth);
    }
    return _styles.join(' ');
  }, [active, clickable, disabled, fullWidth, showError]);

  const handleOnClick = useCallback(() => {
    if (onClick && !disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <Box
      className={boxStyles}
      paddingless
      onClick={handleOnClick}
      display="flex"
      flexDirection="column"
    >
      <Element
        p={4}
        display="flex"
        flexDirection="column"
        className="is-flex-grow-1"
      >
        {title}

        <hr className="my-2" />

        {children}
      </Element>

      <Element
        p={4}
        className={showError ? styles.errorBackground : styles.background}
      >
        {footer}
      </Element>
    </Box>
  );
}

export default InfoCard;
