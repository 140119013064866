import { capitalizeString } from '@zspace/format';
import { DeviceGroupPermissions, SoftwareSeatPermissions } from '@zspace/roles';
import {
  AllYesNoFilter,
  DeferredResponse,
  Device,
  DeviceGroup,
  DeviceGroupsCriteria,
  DeviceGroupsCriteriaFilterDataType,
  DeviceModel,
  FilterType,
  HardwareModel,
  MyDevicesData,
  PaginatedAPIResponse,
  SortDirection,
} from '@zspace/types';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Columns, Element, Icon, Section } from 'react-bulma-components';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { fetchDeviceModels } from '../../devices/devices-service';
import Conditional from '../../shared/conditional/conditional';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import useIsEmpty from '../../shared/hooks/is-empty';
import usePermissions from '../../shared/hooks/permissions';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import FeedbackMessage from '../../ui/feedback-message/feedback-message';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { DeviceForSoftwareAssignmentStorageService } from '../device-for-software-assignment-storage-service';
import DeviceGroupInboxTable from '../device-group-inbox-table/device-group-inbox-table';
import SelectedDeviceTagList from '../device-group-table/selected-device-tag-list/selected-device-tag-list';
import { fetchDeviceGroups } from '../device-groups-service';
import { SoftwareTitlesForAssignmentStorageService } from '../software-titles-for-assignment-storage-service';

const devicesStorage = new DeviceForSoftwareAssignmentStorageService();
const softwareStorage = new SoftwareTitlesForAssignmentStorageService();

const FETCH_DEVICES_ERROR_MESSAGE =
  'My devices could not be fetched. Please try again';
const MULTIPLE_DEVICE_TYPES_ERROR_MESSAGE =
  'You can only manage one device type at a time';
const NO_DEVICE_SELECTED_ERROR_MESSAGE =
  'You must select at least one device to manage software';

const initialDeviceGroupsCriteria: DeviceGroupsCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
  deviceName: '',
  deviceNameFilter: FilterType.CONTAINS,
  deviceType: HardwareModel.ALL,
  softwareAssigned: AllYesNoFilter.ALL,
  softwareTitle: '',
  softwareTitleFilter: FilterType.CONTAINS,
  salesOrders: [],
  serialNumber: '',
  serialNumberFilter: FilterType.CONTAINS,
  excludeEmptyGroups: true,
};

export const loader: LoaderFunction = async () => {
  const deviceModels = fetchDeviceModels();
  const myDeviceGroups = fetchDeviceGroups(initialDeviceGroupsCriteria);

  const response = Promise.all([myDeviceGroups, deviceModels]);
  return defer({ response });
};

export function DeviceGroupsDashboardCardPageContent() {
  const navigate = useNavigate();
  const [myDeviceGroupsInitialResponse, deviceModels] = useAsyncValue() as [
    PaginatedAPIResponse<DeviceGroup>,
    DeviceModel[]
  ];
  const [myDeviceGroupsDataResponse, setMyDeviceGroupsDataResponse] = useState<
    PaginatedAPIResponse<DeviceGroup>
  >(myDeviceGroupsInitialResponse);
  const [deviceGroupsFilterCriteria, setDeviceGroupsFilterCriteria] =
    useState<DeviceGroupsCriteria>(initialDeviceGroupsCriteria);
  const { executeHttpRequest: executeFetchMoreMyDeviceGroups } =
    useHttpRequest();
  const {
    executeHttpRequest: executeFetchMyDeviceGroupsRequest,
    isLoading: isLoadingMyDeviceGroups,
  } = useHttpRequest();
  const [myDeviceGroups, setMyDeviceGroups] = useState<DeviceGroup[]>(
    myDeviceGroupsInitialResponse.data
  );
  const userHasPermissions = usePermissions();
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [manageSoftwareButtonPressed, setManageSoftwareButtonPressed] =
    useState(false);
  const isDataEmpty = useIsEmpty(myDeviceGroupsInitialResponse.data);

  const hasMoreDeviceGroups = useMemo(
    () => !!myDeviceGroupsDataResponse.hasMore,
    [myDeviceGroupsDataResponse]
  );

  const filterTagsData = useMemo(() => {
    return {
      deviceType: {
        label: 'Device type',
        value:
          deviceGroupsFilterCriteria.deviceType !== HardwareModel.ALL
            ? capitalizeString(deviceGroupsFilterCriteria.deviceType)
            : '',
      },
      softwareAssigned: {
        label: 'Has software assigned',
        value:
          deviceGroupsFilterCriteria.softwareAssigned !== AllYesNoFilter.ALL
            ? capitalizeString(deviceGroupsFilterCriteria.softwareAssigned)
            : '',
      },
      softwareTitle: {
        label: 'Assigned software title',
        value: deviceGroupsFilterCriteria.softwareTitle.length
          ? deviceGroupsFilterCriteria.softwareTitleFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', deviceGroupsFilterCriteria.softwareTitle)
          : '',
      },
      deviceName: {
        label: 'Device name',
        value: deviceGroupsFilterCriteria.deviceName
          ? deviceGroupsFilterCriteria.deviceNameFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', deviceGroupsFilterCriteria.deviceName)
          : '',
      },
      salesOrders: {
        label: 'Sales order #',
        value: deviceGroupsFilterCriteria.salesOrders.join(', '),
      },
      serialNumber: {
        label: 'Serial number',
        value: deviceGroupsFilterCriteria.serialNumber
          ? deviceGroupsFilterCriteria.serialNumberFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', deviceGroupsFilterCriteria.serialNumber)
          : '',
      },
    };
  }, [
    deviceGroupsFilterCriteria.deviceName,
    deviceGroupsFilterCriteria.deviceNameFilter,
    deviceGroupsFilterCriteria.deviceType,
    deviceGroupsFilterCriteria.salesOrders,
    deviceGroupsFilterCriteria.serialNumber,
    deviceGroupsFilterCriteria.serialNumberFilter,
    deviceGroupsFilterCriteria.softwareAssigned,
    deviceGroupsFilterCriteria.softwareTitle,
    deviceGroupsFilterCriteria.softwareTitleFilter,
  ]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const showNoDeviceSelectedError = useMemo(
    () => selectedDevices.length === 0,
    [selectedDevices]
  );

  const showMultipleDeviceModelsError = useMemo(() => {
    if (selectedDevices.length === 0) return false;

    const deviceModels = selectedDevices.map((device) => device.model.id);
    return deviceModels.some((deviceModel) => deviceModel !== deviceModels[0]);
  }, [selectedDevices]);

  const showErrorText = useMemo(
    () =>
      (showMultipleDeviceModelsError || showNoDeviceSelectedError) &&
      manageSoftwareButtonPressed,
    [
      showMultipleDeviceModelsError,
      showNoDeviceSelectedError,
      manageSoftwareButtonPressed,
    ]
  );

  const errorText = useMemo(() => {
    if (showMultipleDeviceModelsError) {
      return MULTIPLE_DEVICE_TYPES_ERROR_MESSAGE;
    } else if (showNoDeviceSelectedError) {
      return NO_DEVICE_SELECTED_ERROR_MESSAGE;
    }
  }, [showMultipleDeviceModelsError, showNoDeviceSelectedError]);

  const handleOnManageSoftware = useCallback(() => {
    setManageSoftwareButtonPressed(true);
    if (!showNoDeviceSelectedError && !showMultipleDeviceModelsError) {
      devicesStorage.save(selectedDevices);
      softwareStorage.remove();

      const modelId = selectedDevices[0].model.id;
      navigate(`/device-models/${modelId}/software/select`);
    }
  }, [
    navigate,
    selectedDevices,
    showMultipleDeviceModelsError,
    showNoDeviceSelectedError,
  ]);

  const fetchMoreDeviceGroups = useCallback(
    () =>
      executeFetchMoreMyDeviceGroups({
        asyncFunction: async () => {
          const nextPage = deviceGroupsFilterCriteria.pageNumber + 1;
          const updatedDeviceGroupCriteria = {
            ...deviceGroupsFilterCriteria,
            pageNumber: nextPage,
          };
          const myDeviceGroupsResponse = await fetchDeviceGroups(
            updatedDeviceGroupCriteria
          );
          setDeviceGroupsFilterCriteria(updatedDeviceGroupCriteria);
          setMyDeviceGroups((prevDeviceGroups) =>
            prevDeviceGroups.concat(myDeviceGroupsResponse.data)
          );
          setMyDeviceGroupsDataResponse(myDeviceGroupsResponse);
        },
      }),
    [deviceGroupsFilterCriteria, executeFetchMoreMyDeviceGroups]
  );

  const handleManageDeviceClick = useCallback(
    (newDevices: Device[], add: boolean) => {
      const userHasDeviceGroupUpdatePermissions = userHasPermissions({
        permissions: DeviceGroupPermissions.DEVICE_GROUPS_UPDATE,
      });

      if (userHasDeviceGroupUpdatePermissions) {
        setSelectedDevices((devices) => {
          if (add) {
            const updatedDevices = [...devices, ...newDevices];
            return updatedDevices.filter(
              (device, index) =>
                index === updatedDevices.findIndex((d) => d.id === device.id)
            );
          } else {
            const newDevicesId = newDevices.map((device) => device.id);
            return devices.filter(
              (device) => !newDevicesId.includes(device.id)
            );
          }
        });
      }
    },
    [userHasPermissions]
  );

  const fetchMyDeviceGroups = useCallback(
    (deviceGroupsFilterCriteria: DeviceGroupsCriteria) =>
      executeFetchMyDeviceGroupsRequest({
        asyncFunction: async () => {
          const myDeviceGroupsResponse = await fetchDeviceGroups(
            deviceGroupsFilterCriteria
          );
          setMyDeviceGroups(myDeviceGroupsResponse.data);
        },
        customErrorMessage: FETCH_DEVICES_ERROR_MESSAGE,
      }),
    [executeFetchMyDeviceGroupsRequest]
  );

  const handleTableFilterSubmit = useCallback(
    async (value: DeviceGroupsCriteriaFilterDataType) => {
      const { itemsPerPage, pageNumber } = initialDeviceGroupsCriteria;
      const updatedCriteria = {
        ...value,
        itemsPerPage,
        pageNumber,
      } as DeviceGroupsCriteria;
      setDeviceGroupsFilterCriteria(updatedCriteria);
      await fetchMyDeviceGroups(updatedCriteria);
    },
    [fetchMyDeviceGroups]
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof DeviceGroupsCriteria) => {
      const newData = { ...deviceGroupsFilterCriteria };
      newData[filterDataKey] = initialDeviceGroupsCriteria[
        filterDataKey
      ] as string & string[] & boolean;
      handleTableFilterSubmit(newData);
    },
    [deviceGroupsFilterCriteria, handleTableFilterSubmit]
  );

  const isInvalidSelection = useCallback(
    (devices: Device[]) => {
      let isUniqueDeviceModelSelected = true;

      devices.forEach((device) => {
        isUniqueDeviceModelSelected =
          isUniqueDeviceModelSelected &&
          !selectedDevices.some(
            (selectedDevice) =>
              selectedDevice.id === device.id &&
              selectedDevices.some(
                (device) => device.model.id !== selectedDevice.model.id
              )
          );
      });

      return !isUniqueDeviceModelSelected;
    },
    [selectedDevices]
  );

  const navigateToMyDevices = useCallback(() => {
    navigate('/my-devices');
  }, [navigate]);

  const Header = useCallback(
    () => (
      <>
        <Columns marginless display="flex">
          <Columns.Column>
            <h1 className="is-size-3 has-text-weight-light">Device groups</h1>
            <h3 className="is-size-5 has-text-weight-light">
              Select to which devices you want to assign the new software seats
            </h3>
          </Columns.Column>
          <Columns.Column display="flex" justifyContent="flex-end">
            <If condition={!isDataEmpty}>
              <Button color="primary-dark" onClick={handleOnManageSoftware}>
                <span>Assign new licenses</span>
                <Icon>
                  <FaArrowRight />
                </Icon>
              </Button>
            </If>
          </Columns.Column>
        </Columns>
        <If condition={appliedFilters.length > 0}>
          <Element ml={3}>
            <FilterTagList
              list={filterTagsData}
              onRemove={(item) =>
                onRemoveFilterTag(item as keyof DeviceGroupsCriteria)
              }
              title="Filters"
            />
          </Element>
        </If>
      </>
    ),
    [
      appliedFilters.length,
      filterTagsData,
      handleOnManageSoftware,
      isDataEmpty,
      onRemoveFilterTag,
    ]
  );

  const EmptyState = useCallback(
    () => (
      <Section
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingless
        className="gap-6"
      >
        <Element display="flex" flexDirection="column" alignItems="center">
          <span className="is-size-5 has-text-weight-light">
            No device groups yet
          </span>
          <span>
            You need to group your devices in order to manage their software
          </span>
        </Element>
        <Button color="primary-dark" onClick={navigateToMyDevices}>
          <Icon>
            <FaArrowLeft />
          </Icon>
          <span>Back to my devices</span>
        </Button>
      </Section>
    ),
    [navigateToMyDevices]
  );

  return (
    <BoxLayout className="is-min-height-80 mx-10 mb-4 my-4" header={<Header />}>
      <Conditional condition={isDataEmpty}>
        <Conditional.True>
          <EmptyState />
        </Conditional.True>
        <Conditional.False>
          <If condition={showErrorText}>
            <Element mb={2}>
              <FeedbackMessage>{errorText}</FeedbackMessage>
            </Element>
          </If>
          <SelectedDeviceTagList
            selectedDevices={selectedDevices}
            onManageDeviceClick={handleManageDeviceClick}
            isInvalidSelection={isInvalidSelection}
          />
          <DeviceGroupInboxTable
            deviceGroups={myDeviceGroups}
            fetchMoreDeviceGroups={fetchMoreDeviceGroups}
            hasMoreDeviceGroups={hasMoreDeviceGroups}
            onManageDeviceClick={handleManageDeviceClick}
            selectedDevices={selectedDevices}
            loadingInitialData={isLoadingMyDeviceGroups}
            onTableFilterSubmit={handleTableFilterSubmit}
            deviceGroupsCriteriaFilter={deviceGroupsFilterCriteria}
            deviceModels={deviceModels}
            appliedFilters={appliedFilters}
            isInvalidSelection={isInvalidSelection}
          />
        </Conditional.False>
      </Conditional>
    </BoxLayout>
  );
}

export function DeviceGroupsDashboardCardPage() {
  const { response } = useLoaderData() as DeferredResponse<
    [MyDevicesData, PaginatedAPIResponse<DeviceGroup>, DeviceModel[]]
  >;

  return (
    <ProtectedPage
      permissions={[
        DeviceGroupPermissions.DEVICE_GROUPS_READ,
        SoftwareSeatPermissions.SOFTWARE_SEATS_READ,
        SoftwareSeatPermissions.SOFTWARE_SEATS_ASSIGN,
      ]}
    >
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <DeviceGroupsDashboardCardPageContent />;
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default DeviceGroupsDashboardCardPage;
