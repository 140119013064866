import { faker } from '@faker-js/faker';
import { define } from 'cooky-cutter';
import { Assignment, SoftwareAssignmentReview } from '../types';
import { deviceFactory } from './devices';
import { softwareSeatFactory } from './software-seats';

export const assignmentFactory = define<Assignment>({
  id: () => faker.string.uuid(),
  device: deviceFactory,
  softwareSeat: softwareSeatFactory,
});

export const softwareAssignmentReview = define<SoftwareAssignmentReview>({
  id: () => faker.string.uuid(),
  device: () => ({
    id: faker.string.uuid(),
    salesOrder: {
      id: faker.string.uuid(),
      number: faker.number.int(),
    },
  }),
  softwareSeat: () => ({
    id: faker.string.uuid(),
    salesOrder: {
      id: faker.string.uuid(),
      number: faker.number.int(),
    },
  }),
  licenseKey: () => ({
    lastFourDigits: faker.string.alphanumeric({ length: 4 }),
    expirationDate: faker.date.future(),
  }),
  licensingProductGroup: () => ({
    displayName: faker.lorem.word({ strategy: 'any-length' }),
  }),
});
