import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';
import styles from './cancel-alert-modal.module.scss';

export type CancelAlertModalProps = {
  show: boolean;
  title: string;
  subtitle: string;
  onCancel: () => void;
  onClose: () => void;
};

export function CancelAlertModal({
  show,
  title,
  subtitle,
  onCancel,
  onClose,
}: CancelAlertModalProps) {
  return (
    <Modal show={show}>
      <h1 className="has-text-weight-light is-size-4 mb-4">{title}</h1>
      <p className={styles.subtitleContainer}>{subtitle}</p>
      <div className="is-flex flex-direction-column is-align-items-center is-justify-content-space-between mt-8">
        <Button color="primary-dark" outlined onClick={onClose}>
          No, continue
        </Button>
        <Button color="danger-dark" onClick={onCancel}>
          Yes, cancel
        </Button>
      </div>
    </Modal>
  );
}

export default CancelAlertModal;
