import { useEffect, useState } from 'react';

const MOBILE_DISPLAY_MAX_SIZE = 767;

export function useIsMobileDisplay() {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = document.documentElement.clientWidth;
      if (windowWidth !== currentWidth) {
        setWindowWidth(currentWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  return windowWidth <= MOBILE_DISPLAY_MAX_SIZE;
}

export default useIsMobileDisplay;
