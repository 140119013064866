import CheckPermissions, {
  CheckPermissionsProps,
} from '../check-permissions/check-permissions';
import PermissionsErrorCard from '../permissions-error-card/permissions-error-card';

export type ProtectedPageProps = CheckPermissionsProps;

export function ProtectedPage({ children, ...props }: ProtectedPageProps) {
  return (
    <CheckPermissions {...props}>
      <CheckPermissions.Render>{children}</CheckPermissions.Render>
      <CheckPermissions.Fallback>
        <PermissionsErrorCard />
      </CheckPermissions.Fallback>
    </CheckPermissions>
  );
}

export default ProtectedPage;
