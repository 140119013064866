import { capitalizeString } from '@zspace/format';
import {
  FilterType,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  SortDirection,
  WorkOrderDashboardPageTabs,
  WorkOrderData,
  WorkOrderStatus,
  WorkOrderType,
  WorkOrdersCriteria,
  WorkOrdersTableModalFilterDataType,
} from '@zspace/types';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaFilter } from 'react-icons/fa6';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigation,
  useOutletContext,
  useRevalidator,
  useSearchParams,
} from 'react-router-dom';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import useUpdateUrl from '../../shared/hooks/url';
import { createSearchParams } from '../../shared/url';
import Button from '../../ui/button/button';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import Spinner from '../../ui/spinner/spinner';
import { OnTableChangeData } from '../../ui/table/types';
import { WorkOrderDashboardPageContext } from '../work-orders-dashboard-page/types';
import WorkOrdersQueueTable from '../work-orders-queue-table/work-orders-queue-table';
import {
  fetchWorkOrders,
  updateWorkOrderPriority,
} from '../work-orders-service';
import { workOrderQueueTableFilterModalFields } from '../work-orders-table-filter-modal/utils';
import WorkOrdersTableFilterModal from '../work-orders-table-filter-modal/work-orders-table-filter-modal';

type QueueWorkOrders = {
  currentlyProcessedWorkOrders: PaginatedAPIResponse<WorkOrderData>;
  queueWorkOrders: PaginatedAPIResponse<WorkOrderData>;
};

type QueueWorkOrdersResponse = [
  PaginatedAPIResponse<WorkOrderData>,
  PaginatedAPIResponse<WorkOrderData>
];

const PRIORITY_UPDATE_ERROR_MESSAGE =
  'The priority could not be updated. Please try again';

const initialCriteria: WorkOrdersCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: 'priority',
  workOrderName: '',
  workOrderNameFilter: FilterType.CONTAINS,
  creationDateFrom: '',
  creationDateTo: '',
  account: '',
  accountFilter: FilterType.CONTAINS,
  type: WorkOrderType.ALL,
  minAssignedDevicesNumber: '',
  maxAssignedDevicesNumber: '',
  createdBy: [],
  salesOrder: [],
  status: WorkOrderStatus.QUEUE,
  minPriority: '',
  maxPriority: '',
};

export const loader: LoaderFunction = async ({ request }) => {
  const searchParams = createSearchParams(request);

  const currentlyProcessedWorkOrdersPromise = fetchWorkOrders({
    itemsPerPage: 10,
    pageNumber: 1,
    status: WorkOrderStatus.QUEUE,
    maxPriority: '0',
  });

  const queueWorkOrdersPromise = fetchWorkOrders({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: 'priority',
    sortDirection: SortDirection.ASC,
    workOrderName: searchParams.get('workOrderName') ?? '',
    workOrderNameFilter:
      (searchParams.get('workOrderNameFilter') as FilterType) ??
      FilterType.CONTAINS,
    creationDateFrom: searchParams.get('creationDateFrom') ?? '',
    creationDateTo: searchParams.get('creationDateTo') ?? '',
    account: searchParams.get('account') ?? '',
    accountFilter:
      (searchParams.get('accountFilter') as FilterType) ?? FilterType.CONTAINS,
    type: (searchParams.get('type') as WorkOrderType) ?? WorkOrderType.ALL,
    minAssignedDevicesNumber:
      searchParams.get('minAssignedDevicesNumber') ?? '',
    maxAssignedDevicesNumber:
      searchParams.get('maxAssignedDevicesNumber') ?? '',
    createdBy: searchParams.getAll('createdBy') ?? [],
    salesOrder: searchParams.getAll('salesOrder') ?? [],
    status: WorkOrderStatus.QUEUE,
    minPriority:
      Number(searchParams.get('minPriority')) >= 1
        ? searchParams.get('minPriority')!
        : '1',
    maxPriority: searchParams.get('maxPriority') ?? '',
  });

  const response = Promise.all([
    currentlyProcessedWorkOrdersPromise,
    queueWorkOrdersPromise,
  ]);

  return defer({ response });
};

function WorkOrdersQueueTabContent() {
  const asyncValues = useAsyncValue() as QueueWorkOrdersResponse;

  const { setActiveTab, setFilterTagList, setShowCreateWorkOrderButton } =
    useOutletContext<WorkOrderDashboardPageContext>();

  const navigation = useNavigation();
  const [searchParams] = useSearchParams();
  const updateUrl = useUpdateUrl();

  const revalidator = useRevalidator();

  const { executeHttpRequest, isLoading: isUpdatingPriority } =
    useHttpRequest();

  const response: QueueWorkOrders = useMemo(
    () => ({
      currentlyProcessedWorkOrders: asyncValues[0],
      queueWorkOrders: asyncValues[1],
    }),
    [asyncValues]
  );

  const [criteria, setCriteria] = useState<WorkOrdersCriteria>({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '10'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: 'priority',
    sortDirection: SortDirection.ASC,
    workOrderName: searchParams.get('workOrderName') ?? '',
    workOrderNameFilter:
      (searchParams.get('workOrderNameFilter') as FilterType) ??
      FilterType.CONTAINS,
    creationDateFrom: searchParams.get('creationDateFrom') ?? '',
    creationDateTo: searchParams.get('creationDateTo') ?? '',
    account: searchParams.get('account') ?? '',
    accountFilter:
      (searchParams.get('accountFilter') as FilterType) ?? FilterType.CONTAINS,
    type: (searchParams.get('type') as WorkOrderType) ?? WorkOrderType.ALL,
    minAssignedDevicesNumber:
      searchParams.get('minAssignedDevicesNumber') ?? '',
    maxAssignedDevicesNumber:
      searchParams.get('maxAssignedDevicesNumber') ?? '',
    createdBy: searchParams.getAll('createdBy') ?? [],
    salesOrder: searchParams.getAll('salesOrder') ?? [],
    status: WorkOrderStatus.QUEUE,
    minPriority: searchParams.get('minPriority') ?? '',
    maxPriority: searchParams.get('maxPriority') ?? '',
  });
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);

  const currentlyBeingProcessedWorkOrder = useMemo(
    () => response?.currentlyProcessedWorkOrders.data ?? [],
    [response?.currentlyProcessedWorkOrders.data]
  );

  const queueWorkOrders = useMemo(
    () => response?.queueWorkOrders.data ?? [],
    [response]
  );

  const tableConfig: PaginatedContentConfig = useMemo(
    () => ({
      ...criteria,
      pages: response?.queueWorkOrders.pages ?? 1,
    }),
    [criteria, response?.queueWorkOrders.pages]
  );

  const isDataLoading = useMemo(
    () => navigation.state === 'loading',
    [navigation.state]
  );

  const filterTagsData = useMemo(() => {
    return {
      workOrderName: {
        label: 'Work order name',
        value: criteria.workOrderName
          ? criteria.workOrderNameFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', criteria.workOrderName)
          : '',
      },
      account: {
        label: 'Account',
        value:
          criteria.account.length > 0
            ? criteria.accountFilter
                .toLowerCase()
                .replace('_', ' ')
                .concat(' ', criteria.account)
            : '',
      },
      minAssignedDevicesNumber: {
        label: 'Min number of assigned devices',
        value: criteria.minAssignedDevicesNumber ?? '',
      },
      maxAssignedDevicesNumber: {
        label: 'Max number of assigned devices',
        value: criteria.maxAssignedDevicesNumber ?? '',
      },
      salesOrder: {
        label: 'Sales order #',
        value: criteria.salesOrder.join(', '),
      },
      creationDateFrom: {
        label: 'Creation date from',
        value: criteria.creationDateFrom,
      },
      creationDateTo: {
        label: 'Creation date to',
        value: criteria.creationDateTo,
      },
      type: {
        label: 'Type',
        value:
          criteria.type !== WorkOrderType.ALL
            ? capitalizeString(criteria.type.replace('_', ' '))
            : '',
      },
      minPriority: {
        label: 'Min priority',
        value: criteria.minPriority?.toString() ?? '',
      },
      maxPriority: {
        label: 'Max priority',
        value: criteria.maxPriority?.toString() ?? '',
      },
      createdBy: {
        label: 'Created by',
        value: criteria.createdBy.join(', '),
      },
    };
  }, [criteria]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const showTableForm = useMemo(
    () =>
      appliedFilters.length > 0 ||
      Boolean(criteria.search) ||
      queueWorkOrders.length > 0,
    [appliedFilters.length, criteria.search, queueWorkOrders.length]
  );

  const emptyContentText = useMemo(() => {
    const hasAppliedFilters =
      criteria.search !== '' || appliedFilters.length > 0;
    return hasAppliedFilters
      ? 'There are no work orders in queue that match the selected criteria'
      : 'There are no work orders in queue at the moment';
  }, [appliedFilters.length, criteria.search]);

  const revalidate = useCallback(() => {
    revalidator.revalidate();
  }, [revalidator]);

  const handlePriorityUpdate = useCallback(
    (id: string, priority: number) => {
      executeHttpRequest({
        asyncFunction: async () => {
          await updateWorkOrderPriority(id, priority);
          revalidate();
        },
        customErrorMessage: PRIORITY_UPDATE_ERROR_MESSAGE,
      });
    },
    [executeHttpRequest, revalidate]
  );

  const handleOnTableChange = useCallback(
    (value: OnTableChangeData<WorkOrderData>): void => {
      const newData = {
        ...criteria,
        ...value.config,
        sortBy: value.column?.key || criteria.sortBy,
      };

      setCriteria(newData);
      updateUrl(newData);
    },
    [criteria, updateUrl]
  );

  const handleTableFilterSubmit = useCallback(
    (value: WorkOrdersTableModalFilterDataType): void => {
      const newCriteria: WorkOrdersCriteria = {
        itemsPerPage: criteria.itemsPerPage,
        pageNumber: criteria.pageNumber,
        search: criteria.search,
        sortBy: criteria.sortBy,
        status: WorkOrderStatus.QUEUE,
        ...value,
      };
      setCriteria(newCriteria);
      updateUrl(newCriteria);
    },
    [criteria, setCriteria, updateUrl]
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof WorkOrdersTableModalFilterDataType) => {
      const newCriteria: WorkOrdersCriteria = { ...criteria };

      newCriteria[filterDataKey] = initialCriteria[filterDataKey] as string &
        number &
        string[];

      handleTableFilterSubmit(newCriteria);
    },
    [criteria, handleTableFilterSubmit]
  );

  useEffect(() => {
    setActiveTab(WorkOrderDashboardPageTabs.QUEUE);
  }, [setActiveTab]);

  useEffect(() => {
    let appliedFiltersList = null;
    if (appliedFilters.length > 0) {
      appliedFiltersList = (
        <FilterTagList
          list={filterTagsData}
          onRemove={(item) => {
            onRemoveFilterTag(item as keyof WorkOrdersTableModalFilterDataType);
          }}
          title="Filters"
        />
      );
    }
    setFilterTagList(appliedFiltersList);
  }, [
    appliedFilters.length,
    filterTagsData,
    onRemoveFilterTag,
    setFilterTagList,
  ]);

  useEffect(() => {
    setShowCreateWorkOrderButton(true);
  }, [setShowCreateWorkOrderButton]);

  if (isDataLoading) {
    return (
      <Element display="flex" justifyContent="center">
        <Spinner />
      </Element>
    );
  }

  return (
    <Element>
      <h1 className="is-size-4 mb-6 has-text-weight-light">
        Currently being processed
      </h1>
      <WorkOrdersQueueTable
        dataSource={currentlyBeingProcessedWorkOrder}
        config={tableConfig}
        form={{ show: false }}
        empty={
          <Element display="flex" justifyContent="center" className="mb-6">
            <span className="has-text-weight-light is-size-5">
              There are no work orders currently being processed
            </span>
          </Element>
        }
      />
      <h1 className="is-size-4 mb-4 has-text-weight-light">Queue</h1>
      <WorkOrdersQueueTable
        dataSource={queueWorkOrders}
        config={tableConfig}
        onChange={handleOnTableChange}
        includePriority
        onPriorityUpdate={handlePriorityUpdate}
        isUpdatingPriority={
          isUpdatingPriority || revalidator.state === 'loading'
        }
        empty={
          <Element display="flex" justifyContent="center" className="my-2">
            <span className="has-text-weight-light">{emptyContentText}</span>
          </Element>
        }
        form={{
          show: showTableForm,
          extra: (
            <Button
              type="button"
              color="primary-dark"
              className="ml-2"
              outlined={appliedFilters.length === 0}
              onClick={() => setIsFilterModalVisible(true)}
            >
              <Icon>
                <FaFilter />
              </Icon>
              <span>
                Filter
                {appliedFilters.length > 0 && `(${appliedFilters.length})`}
              </span>
            </Button>
          ),
        }}
      />
      <WorkOrdersTableFilterModal
        show={isFilterModalVisible}
        data={criteria}
        fields={workOrderQueueTableFilterModalFields}
        onClose={() => setIsFilterModalVisible(false)}
        onSubmit={handleTableFilterSubmit}
      />
    </Element>
  );
}

export function WorkOrdersQueueTab() {
  const { response } = useLoaderData() as {
    response: Promise<QueueWorkOrdersResponse>;
  };

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <WorkOrdersQueueTabContent />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default WorkOrdersQueueTab;
