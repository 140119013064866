export function formatWorkOrderNumber(input: string | number): string {
  return `WO${input}`;
}

export function formatSalesOrderNumber(input: string | number): string {
  return `SO${input}`;
}

export function formatDefaultDeviceName(
  salesOrderNumber: number,
  modelDisplayName: string,
  index: number
): string {
  const salesOrder = formatSalesOrderNumber(salesOrderNumber);
  const deviceModel = modelDisplayName.split(' ').join('-');
  return `${salesOrder}-${deviceModel}-${index}`;
}

export function capitalizeString(input: string): string {
  return input.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());
}

export function sanitizeSalesOrderInput(input: string): string {
  const numberPattern = /\d+/g;
  const salesOrderNumber = input.match(numberPattern);
  return salesOrderNumber ? salesOrderNumber[0] : '';
}

export function sanitizeExternalUrl(url: string): string {
  if (url.startsWith('https://')) {
    return url;
  }
  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://');
  }
  return `https://${url}`;
}

/**
 * The max number of digits supported by integer type in PostgreSQL.
 *
 * PostgreSQL's integer type specific ranges:
 * - `integer` (or `int`): -2,147,483,648 to 2,147,483,647
 *
 * For more details, refer to the PostgreSQL documentation:
 * https://www.postgresql.org/docs/current/datatype-numeric.html
 **/
export const MAX_INTEGER_DIGITS = 9;
