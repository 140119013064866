import { ReactNode } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import ManagementHubIcon from '../../../assets/icons/management-hub-icon';
import AdminToolbar from '../admin-toolbar/admin-toolbar';
import useUser from '../hooks/user';
import useUserHasZspaceStaffPermissions from '../hooks/user-has-zspace-staff-permissions';
import If from '../if/if';
import styles from './home-header.module.scss';

export type HomeHeaderProps = {
  children?: ReactNode;
};

export function HomeHeader({ children }: HomeHeaderProps) {
  const { user } = useUser();
  const hasZspaceStaffPermissions = useUserHasZspaceStaffPermissions();

  return (
    <Columns display="flex" flexDirection="column" className="is-flex-grow-1">
      <If condition={hasZspaceStaffPermissions}>
        <Columns.Column
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-end"
        >
          <AdminToolbar />
        </Columns.Column>
      </If>

      <Columns.Column
        display="flex"
        flexDirection="column"
        alignContent="flex-end"
        alignItems="center"
        justifyContent="flex-end"
      >
        <h3
          className={`is-size-6 has-text-weight-normal has-text-primary-dark mb-5 ${styles.email}`}
        >
          {user.email}
        </h3>
        <Element display="flex" alignItems="center" mb={5}>
          <Icon className={styles.managementHubIcon} mr={4}>
            <ManagementHubIcon width={20} />
          </Icon>
          <h1
            className={`is-size-3 has-text-weight-light has-text-primary-dark ${styles.title}`}
          >
            Welcome to zSpace Management Hub
          </h1>
        </Element>
        {children}
      </Columns.Column>
    </Columns>
  );
}

export default HomeHeader;
