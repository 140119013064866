import { ChangeEvent, FocusEvent } from 'react';
import { FormFieldData } from '../../shared/form';
import { GROUP_CODE_LENGTH } from './move-devices-modal';

type MoveDevicesData = {
  deviceGroupCode: string;
};

export type MoveDevicesFormData = FormFieldData<MoveDevicesData>;

export type MoveDevicesFormChangeEvent = ChangeEvent<
  HTMLInputElement | HTMLSelectElement
>;

export type MoveDevicesFormBlurEvent = FocusEvent<
  HTMLInputElement | HTMLSelectElement,
  Element
>;

export const initialFormData: MoveDevicesFormData = {
  deviceGroupCode: {
    value: '',
    touched: false,
    error: null,
  },
};

export const validateDeviceGroupCode = (value: string) => {
  if (!value.trim()) {
    return 'Please provide the group code';
  }

  if (value.length < GROUP_CODE_LENGTH) {
    return `Group code must be ${GROUP_CODE_LENGTH} characters long`;
  }

  return null;
};

export const validateForm = {
  deviceGroupCode: validateDeviceGroupCode,
};
