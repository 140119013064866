import { ReactNode, useCallback } from 'react';
import { Columns } from 'react-bulma-components';
import Books from '../../../assets/icons/books';
import Developers from '../../../assets/icons/developers';
import HandShake from '../../../assets/icons/handshake';
import Headset from '../../../assets/icons/headset';
import ManagementHubIcon from '../../../assets/icons/management-hub-icon';
import UserGroup from '../../../assets/icons/user-group';
import Zcentral from '../../../assets/icons/zcentral';
import Zspace from '../../../assets/icons/zspace';
import useToast from '../../shared/hooks/toasts';
import { fetchMyCourses } from '../../users/users-service';
import QuickAccessApp from '../quick-access-app/quick-access-app';
import styles from './app-launcher.module.scss';
import {
  DEVELOPERS_URL,
  DEVELOPER_COMMUNITY_URL,
  INVESTOR_RELATIONS_URL,
  SUPPORT_URL,
  ZCENTRAL_URL,
  ZSPACE_URL,
} from './urls';

export function AppContainer({
  children,
  active = false,
}: {
  children: ReactNode;
  active?: boolean;
}) {
  return (
    <Columns.Column
      paddingless
      className={`${styles.appContainer} ${active && styles.active}`}
      size={4}
    >
      {children}
    </Columns.Column>
  );
}

export function AppLauncher() {
  const toast = useToast();

  const getMyCoursesUrl = useCallback(async () => {
    try {
      const myCourses = await fetchMyCourses();
      window.open(myCourses.coursesUrl, '_blank');
    } catch (error) {
      toast.error('Your courses could not be loaded. Please try again');
    }
  }, [toast]);

  return (
    <Columns marginless alignItems="center">
      <AppContainer>
        <QuickAccessApp icon={<Zspace />} app="zSpace.com" url={ZSPACE_URL} />
      </AppContainer>

      <AppContainer>
        <QuickAccessApp icon={<Headset />} app="Support" url={SUPPORT_URL} />
      </AppContainer>

      <AppContainer>
        <QuickAccessApp
          icon={<Books />}
          app="My Courses"
          onClick={getMyCoursesUrl}
        />
      </AppContainer>

      <AppContainer>
        <QuickAccessApp icon={<Zcentral />} app="zCentral" url={ZCENTRAL_URL} />
      </AppContainer>

      <AppContainer>
        <QuickAccessApp
          icon={<Developers />}
          app="Developers"
          url={DEVELOPERS_URL}
        />
      </AppContainer>

      <AppContainer>
        <QuickAccessApp
          icon={<UserGroup />}
          app="Developer Community"
          url={DEVELOPER_COMMUNITY_URL}
        />
      </AppContainer>

      <AppContainer>
        <QuickAccessApp
          icon={<HandShake />}
          app={`Investor\nRelations`}
          url={INVESTOR_RELATIONS_URL}
        />
      </AppContainer>

      <AppContainer active>
        <QuickAccessApp
          icon={<ManagementHubIcon />}
          app="zSpace Management Hub"
          url="/"
          target="_self"
        />
      </AppContainer>
    </Columns>
  );
}

export default AppLauncher;
