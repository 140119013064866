import { RouteObject } from '../app';
import AccountDetailsPageContent from './account-details-page/account-details-page';

export const routes: RouteObject[] = [
  {
    path: 'accounts',
    children: [
      {
        path: ':id',
        element: <AccountDetailsPageContent />,
      },
    ],
  },
];
