import { faker } from '@faker-js/faker';
import { define } from 'cooky-cutter';
import { SoftwareTitleForAssignment } from '../types';

export const softwareTitleForAssignmentFactory =
  define<SoftwareTitleForAssignment>({
    id: () => faker.string.uuid(),
    licensingProductGroup: () => ({
      id: faker.string.uuid(),
      displayName: faker.lorem.word(3),
    }),
    salesOrder: () => ({
      id: faker.string.uuid(),
      number: faker.number.int().toString(),
    }),
    seats: () => {
      return { available: faker.number.int() };
    },
    itemFulfillment: () => ({
      id: faker.string.uuid(),
      number: faker.number.int().toString(),
    }),
  });
