import { ReactNode } from 'react';
import { Element, Icon } from 'react-bulma-components';
import styles from './info-label.module.scss';

export interface InfoLabelProps {
  icon: ReactNode;
  label: string;
  content: ReactNode;
}

export function InfoLabel({ icon, label, content }: InfoLabelProps) {
  return (
    <Element display="flex" flexDirection="column" className="pb-5">
      <Element display="flex" flexDirection="row">
        <Icon display="flex" justifyContent="flex-start" color="primary-dark">
          {icon}
        </Icon>
        <span className={styles.label}>{label}</span>
      </Element>
      {content}
    </Element>
  );
}

export default InfoLabel;
