import { formatSalesOrderNumber } from '@zspace/format';
import {
  FilterTableModalFieldAlignment,
  InputType,
  UserPermissionsFilterDataType,
} from '@zspace/types';
import FilterTableModal from '../../../shared/filter-table-modal/filter-table-modal';

export type UserPermissionsTableFilterModalProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: (value: UserPermissionsFilterDataType) => void;
  data: UserPermissionsFilterDataType;
};

export function UserPermissionsTableFilterModal({
  show,
  data,
  onClose,
  onSubmit,
}: UserPermissionsTableFilterModalProps) {
  return (
    <FilterTableModal
      show={show}
      data={data}
      onClose={onClose}
      onSubmit={onSubmit}
      fields={[
        {
          input: {
            type: InputType.FILTER_CRITERIA,
            valueKey: 'opportunityName',
            filterValueKey: 'opportunityNameFilter',
          },
          name: 'opportunityName',
          label: 'Opportunity Name',
          alignment: FilterTableModalFieldAlignment.LEFT,
        },
        {
          input: {
            type: InputType.TAG_INPUT,
            valueKey: 'salesOrder',
            placeholder: formatSalesOrderNumber('12345'),
          },
          label: 'Sales order #',
          name: 'salesOrder',
          alignment: FilterTableModalFieldAlignment.LEFT,
        },
        {
          input: {
            type: InputType.DATE_RANGE,
            fromDateValueKey: 'closeDateFrom',
            toDateValueKey: 'closeDateTo',
          },
          name: 'closeDate',
          label: 'Close Date',
          alignment: FilterTableModalFieldAlignment.RIGHT,
        },
      ]}
    />
  );
}

export default UserPermissionsTableFilterModal;
