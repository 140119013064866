import { User } from '@zspace/types';
import { ReactNode } from 'react';
import { Element, Section } from 'react-bulma-components';
import BackButton from '../../shared/back-button/back-button';

export type HeaderPropsType = {
  navigateToPermissionsHome: () => void;
  userToEdit: User;
  filters: ReactNode;
};

export function Header({
  navigateToPermissionsHome,
  userToEdit,
  filters,
}: HeaderPropsType) {
  return (
    <Element className="p-4">
      <Section paddingless>
        <BackButton onClick={navigateToPermissionsHome} />
        <h1 className="is-size-3 has-text-weight-light">
          Edit permissions for {userToEdit.email}
        </h1>
        {filters}
      </Section>
    </Element>
  );
}
