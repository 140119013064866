import { formatSalesOrderNumber } from '@zspace/format';
import {
  FilterTableModalField,
  FilterTableModalFieldAlignment,
  HardwareModel,
  InputType,
  MySalesOrdersCriteriaFilterDataType,
} from '@zspace/types';
import FilterTableModal from '../../shared/filter-table-modal/filter-table-modal';
import { isEmail } from '../../shared/utils';

export type MySalesOrdersTableFilterModalProps = {
  show: boolean;
  data: MySalesOrdersCriteriaFilterDataType;
  onClose: () => void;
  onSubmit: (data: MySalesOrdersCriteriaFilterDataType) => void;
  deviceTypeOptions: { label: string; value: string }[];
};

export function MySalesOrdersTableFilterModal({
  deviceTypeOptions,
  ...props
}: MySalesOrdersTableFilterModalProps) {
  const fields: FilterTableModalField<MySalesOrdersCriteriaFilterDataType>[] = [
    {
      input: {
        type: InputType.FILTER_CRITERIA,
        valueKey: 'softwareTitle',
        filterValueKey: 'softwareTitleFilter',
      },
      name: 'softwareTitle',
      label: 'Software title',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.SELECT,
        valueKey: 'deviceType',
        options: [
          {
            label: 'All',
            value: HardwareModel.ALL,
          },
          ...deviceTypeOptions,
        ],
      },
      name: 'deviceType',
      label: 'Device type',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.TAG_INPUT,
        valueKey: 'salesOrders',
        placeholder: formatSalesOrderNumber('12345'),
      },
      name: 'salesOrders',
      label: 'Sales order #',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.MIN_MAX,
        minValueKey: 'minDevicesNumber',
        maxValueKey: 'maxDevicesNumber',
      },
      name: 'devicesNumber',
      label: 'Amount of devices',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
    {
      input: {
        type: InputType.TAG_INPUT,
        valueKey: 'relatedUsers',
        placeholder: 'name@mail.com',
        validateFn: (value: string) => isEmail(value),
        errorText: 'Please enter a valid email address',
      },
      name: 'relatedUser',
      label: 'Related user',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
  ];

  return <FilterTableModal {...props} fields={fields} />;
}

export default MySalesOrdersTableFilterModal;
