import { useCallback } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaDownload, FaShare } from 'react-icons/fa6';
import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';

export type ActionsPendingModalProps = {
  show: boolean;
  id: string;
  onDownloadFileClick: (id: string) => void;
  onSendEmailClick: () => void;
  onCloseClick: () => void;
  isExecutingAction: boolean;
};

const TITLE = 'Registration file created';
const GENERIC_TEXT =
  'Send this file to members of your organization to have them register devices without having to create a user for them.';
const GET_DOWNLOAD_BUTTON_TEXT = 'Download';
const GET_SEND_BUTTON_TEXT = 'Send';
const LEARN_MORE_LINK = '#';
const LEARN_MORE_TEXT = 'Learn more';

export function CreateRegistrationFileModal({
  show,
  id,
  onDownloadFileClick,
  onSendEmailClick,
  onCloseClick,
  isExecutingAction,
}: ActionsPendingModalProps) {
  const handleDownloadFileClick = useCallback(() => {
    onDownloadFileClick(id);
  }, [id, onDownloadFileClick]);

  return (
    <Modal show={show} className="modal-home">
      <h1 className="has-text-weight-light is-size-4 mb-4">{TITLE}</h1>
      <p className="is-size-7 py-2">
        {GENERIC_TEXT} <a href={LEARN_MORE_LINK}>{LEARN_MORE_TEXT}</a>
      </p>
      <Element
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={8}
      >
        <Button color="primary-dark" outlined onClick={onCloseClick}>
          Close
        </Button>
        <div className="field is-grouped">
          <p className="control">
            <Button
              color="primary-dark"
              isExecutingAction={isExecutingAction}
              onClick={handleDownloadFileClick}
            >
              <Button.LoadingIcon icon={FaDownload} />
              <span>{GET_DOWNLOAD_BUTTON_TEXT}</span>
            </Button>
          </p>
          <p className="control">
            <Button color="primary-dark" onClick={onSendEmailClick}>
              <Icon mr={2}>
                <FaShare />
              </Icon>
              {GET_SEND_BUTTON_TEXT}
            </Button>
          </p>
        </div>
      </Element>
    </Modal>
  );
}

export default CreateRegistrationFileModal;
