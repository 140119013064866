import { userZspaceInternalSalesOrders } from '@zspace/roles';
import { SalesOrder } from '@zspace/types';
import { useMemo } from 'react';
import useUser from './user';

export type UserSalesOrders = {
  userSalesOrders: SalesOrder[];
};

export function useZspaceInternalUserSalesOrders(): UserSalesOrders {
  const { user } = useUser();

  const userSalesOrders = useMemo(
    () => userZspaceInternalSalesOrders(user),
    [user]
  );

  return { userSalesOrders };
}

export default useZspaceInternalUserSalesOrders;
