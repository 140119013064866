import { removeTimeFromStringDate } from '@zspace/dates';
import { DateRangeInputType } from '@zspace/types';
import { useMemo, useState } from 'react';
import { Columns, Element, Form, Icon } from 'react-bulma-components';
import { FaCalendar } from 'react-icons/fa6';
import FeedbackMessage from '../../../../ui/feedback-message/feedback-message';
import { FilterModalInput } from '../../filter-table-modal-input/filter-table-modal-input';
import styles from './date-range-input.module.scss';

function formatDate(date: Date): string {
  return date.toISOString().split('T')[0];
}

export type DateRangeInputProps<T> = FilterModalInput<T> & {
  input: DateRangeInputType<T>;
};

export function DateRangeInput<T>({
  input: { fromDateValueKey, toDateValueKey },
  name,
  errorText,
  localData,
  setLocalData,
}: DateRangeInputProps<T>) {
  const [isDateFromInputActive, setIsDateFromInputActive] = useState(false);
  const [isDateToInputActive, setIsDateToInputActive] = useState(false);

  const maxCreationDateFrom = useMemo(() => {
    let date = '';
    if (localData[toDateValueKey]) {
      date = formatDate(
        new Date(removeTimeFromStringDate(localData[toDateValueKey] as string))
      );
    }
    return date;
  }, [localData, toDateValueKey]);

  const minCreationDateTo = useMemo(() => {
    let date = '';
    if (localData[fromDateValueKey]) {
      date = formatDate(
        new Date(
          removeTimeFromStringDate(localData[fromDateValueKey] as string)
        )
      );
    }
    return date;
  }, [fromDateValueKey, localData]);

  const fromValue = useMemo(() => {
    if (localData[fromDateValueKey]) {
      return removeTimeFromStringDate(localData[fromDateValueKey] as string);
    }
  }, [fromDateValueKey, localData]);

  const toValue = useMemo(() => {
    if (localData[toDateValueKey]) {
      return removeTimeFromStringDate(localData[toDateValueKey] as string);
    }
  }, [toDateValueKey, localData]);

  return (
    <Element>
      <Columns marginless className="gap-6">
        <Columns.Column paddingless>
          <Form.Field alignItems="center">
            <Form.Control>
              <Form.Input
                id={name}
                type="date"
                placeholder="From"
                max={maxCreationDateFrom}
                value={fromValue}
                onKeyDownCapture={(e) => {
                  e.preventDefault();
                }}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    [fromDateValueKey]: e.target.value
                      ? e.target.value + 'T00:00:00.000Z'
                      : e.target.value,
                  })
                }
                onFocus={() => setIsDateFromInputActive(true)}
                onBlur={() => setIsDateFromInputActive(false)}
                onMouseEnter={() => setIsDateFromInputActive(true)}
                onMouseLeave={() => setIsDateFromInputActive(false)}
                onClick={(e) => e.currentTarget.showPicker()}
              />
              <Icon align="right" tabIndex={-1}>
                <FaCalendar
                  className={
                    isDateFromInputActive
                      ? styles.chevronDownActive
                      : styles.chevronDown
                  }
                />
              </Icon>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column paddingless>
          <Form.Field alignItems="center">
            <Form.Control>
              <Form.Input
                type="date"
                placeholder="To"
                min={minCreationDateTo}
                value={toValue}
                onKeyDownCapture={(e) => {
                  e.preventDefault();
                }}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    [toDateValueKey]: e.target.value
                      ? e.target.value + 'T23:59:59.999Z'
                      : e.target.value,
                  })
                }
                onFocus={() => setIsDateToInputActive(true)}
                onBlur={() => setIsDateToInputActive(false)}
                onMouseEnter={() => setIsDateToInputActive(true)}
                onMouseLeave={() => setIsDateToInputActive(false)}
                onClick={(e) => e.currentTarget.showPicker()}
              />
              <Icon align="right">
                <FaCalendar
                  className={
                    isDateToInputActive
                      ? styles.chevronDownActive
                      : styles.chevronDown
                  }
                />
              </Icon>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>
      <FeedbackMessage>{errorText}</FeedbackMessage>
    </Element>
  );
}

export default DateRangeInput;
