import { BasicTableConfig } from '@zspace/types';
import { ReactNode, useMemo, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import { FaFilter } from 'react-icons/fa6';
import { useDebouncedCallback } from 'use-debounce';
import If from '../../shared/if/if';
import Button from '../button/button';
import SearchBar from '../search-bar/search-bar';
import styles from './inbox-table.module.scss';

export type InboxTableProps = {
  inbox?: ReactNode;
  table: ReactNode;
  isTableEmpty?: boolean;
  leftContent?: ReactNode;
  appliedFilters?: object[];
  onFilterClick?: () => void;
  onSearchChange?: (value: string) => void;
  config: BasicTableConfig;
  showSearchAndFilter?: boolean;
  disableSearch?: boolean;
};

export function InboxTable({
  inbox,
  table,
  isTableEmpty,
  leftContent,
  onSearchChange,
  onFilterClick,
  appliedFilters,
  config,
  showSearchAndFilter = true,
  disableSearch = false,
}: InboxTableProps) {
  const [search, setSearch] = useState(config.search);

  const filterText = useMemo(() => {
    return appliedFilters && appliedFilters.length > 0
      ? `(${appliedFilters.length})`
      : '';
  }, [appliedFilters]);

  const layoutClassName = useMemo(() => {
    if (!inbox && isTableEmpty) {
      return `${styles.layout}`;
    }
    return `${styles.layout} ${styles.borderedLayout} ${
      inbox ? `${styles.doubleColumnLayout}` : `${styles.singleColumnLayout}`
    }`;
  }, [inbox, isTableEmpty]);

  const handleSearchInputChange = useDebouncedCallback((value: string) => {
    setSearch(value);
    onSearchChange?.(value);
  });

  return (
    <>
      <Element display="flex" justifyContent="space-between" mb={3}>
        {leftContent}
        <If condition={showSearchAndFilter}>
          <Element
            display="flex"
            className="gap-3 w-full"
            justifyContent="flex-end"
          >
            {!disableSearch && (
              <SearchBar
                onChange={handleSearchInputChange}
                searchValue={search}
                debounceMillis={1000}
              />
            )}
            <If condition={!!onFilterClick && !!appliedFilters}>
              <Button color="primary-dark" outlined onClick={onFilterClick}>
                <Icon>
                  <FaFilter />
                </Icon>
                <span>Filter {filterText}</span>
              </Button>
            </If>
          </Element>
        </If>
      </Element>
      <Columns marginless className={layoutClassName}>
        <If condition={!!inbox}>
          <Columns.Column
            mobile={{ size: 6 }}
            tablet={{ size: 5 }}
            desktop={{ size: 4 }}
            widescreen={{ size: 3 }}
            paddingless
            className={styles.leftColumn}
          >
            {inbox}
          </Columns.Column>
        </If>
        <Columns.Column
          className={inbox ? styles.rightColumn : styles.singleColumn}
          paddingless
        >
          {table}
        </Columns.Column>
      </Columns>
    </>
  );
}

export default InboxTable;
