import {
  Account,
  Criteria,
  PaginatedAPIResponse,
  SalesOrder,
  User,
} from '@zspace/types';
import { http } from '../shared/http';

export interface AccountsService {
  fetchAccountById: (id: string) => Promise<Account>;
  fetchAccountRelatedSalesOrders: (
    accountId: string,
    criteria: Criteria
  ) => Promise<PaginatedAPIResponse<SalesOrder>>;
  fetchAccountRelatedUsers: (
    accountId: string,
    criteria: Criteria
  ) => Promise<PaginatedAPIResponse<User>>;
}

export class AccountsServiceImpl implements AccountsService {
  public async fetchAccountById(id: string): Promise<Account> {
    const { data } = await http(`/v1/accounts/${id}`);

    return data;
  }

  public async fetchAccountRelatedSalesOrders(
    accountId: string,
    criteria: Criteria
  ): Promise<PaginatedAPIResponse<SalesOrder>> {
    const { data } = await http(
      `/v1/accounts/${accountId}/sales-orders?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchAccountRelatedUsers(
    accountId: string,
    criteria: Criteria
  ): Promise<PaginatedAPIResponse<User>> {
    const { data } = await http(
      `/v1/accounts/${accountId}/users?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }
}

export const fetchAccountById = async (id: string): Promise<Account> => {
  const service = new AccountsServiceImpl();

  return service.fetchAccountById(id);
};

export const fetchAccountRelatedSalesOrders = async (
  accountId: string,
  criteria: Criteria
): Promise<PaginatedAPIResponse<SalesOrder>> => {
  const service = new AccountsServiceImpl();

  return service.fetchAccountRelatedSalesOrders(accountId, criteria);
};

export const fetchAccountRelatedUsers = async (
  accountId: string,
  criteria: Criteria
): Promise<PaginatedAPIResponse<User>> => {
  const service = new AccountsServiceImpl();

  return service.fetchAccountRelatedUsers(accountId, criteria);
};
