import {
  Assignment,
  Criteria,
  Device,
  DeviceGroup,
  DeviceGroupsCriteria,
  DeviceGroupsTableRow,
  EditDeviceGroupsTableRow,
  EditWorkOrderDetails,
  NewAssignments,
  NewDeviceGroup,
  NewWorkOrder,
  PaginatedAPIResponse,
  SalesOrder,
  SoftwareCriteria,
  SoftwareTableRow,
  WorkOrder,
  WorkOrderData,
  WorkOrderDetailsData,
  WorkOrdersCriteria,
} from '@zspace/types';
import { http } from '../shared/http';

export interface WorkOrdersService {
  fetchWorkOrders: (
    criteria: Partial<WorkOrdersCriteria>
  ) => Promise<PaginatedAPIResponse<WorkOrder>>;
  createNewWorkOrder: (newWorkOrder: NewWorkOrder) => Promise<WorkOrder>;
  updateWorkOrderById: (
    id: string,
    workOrderData: EditWorkOrderDetails
  ) => Promise<WorkOrder>;
  deleteWorkOrderById: (id: string) => Promise<void>;
  fetchWorkOrderById(id: string): Promise<WorkOrderData>;
  fetchWorkOrderDetails(id: string): Promise<WorkOrderDetailsData>;
  sendWorkOrderToQueue: (id: string) => Promise<void>;
  updateWorkOrderPriority: (id: string, priority: number) => Promise<void>;
  createDeviceGroup(
    id: string,
    newDeviceGroup: NewDeviceGroup
  ): Promise<DeviceGroup>;
  fetchWorkOrderSoftwareSalesOrders(
    id: string,
    criteria: SoftwareCriteria
  ): Promise<PaginatedAPIResponse<SalesOrder>>;
  fetchWorkOrderSoftwareBySalesOrder(
    id: string,
    salesOrderId: string,
    criteria: SoftwareCriteria
  ): Promise<PaginatedAPIResponse<SoftwareTableRow>>;
  fetchWorkOrderDevices(
    id: string,
    criteria: Criteria
  ): Promise<PaginatedAPIResponse<DeviceGroupsTableRow>>;
  assignSoftware(
    id: string,
    assignments: NewAssignments
  ): Promise<Assignment[]>;
  fetchWorkOrderDeviceGroups(
    id: string,
    criteria: DeviceGroupsCriteria
  ): Promise<PaginatedAPIResponse<DeviceGroup>>;
  fetchWorkOrderDeviceGroupEditDevices(
    id: string,
    groupId: string,
    criteria: Criteria
  ): Promise<PaginatedAPIResponse<EditDeviceGroupsTableRow>>;
  fetchWorkOrderDeviceGroupViewDevices(
    id: string,
    groupId: string,
    criteria: DeviceGroupsCriteria
  ): Promise<PaginatedAPIResponse<Device>>;
}

export class WorkOrdersServiceImpl implements WorkOrdersService {
  public async fetchWorkOrders(
    criteria: Partial<WorkOrdersCriteria>
  ): Promise<PaginatedAPIResponse<WorkOrder>> {
    const { data } = await http(
      '/v1/work-orders?' +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async createNewWorkOrder(newWorkOrder: NewWorkOrder) {
    const { data } = await http.post('/v1/work-orders', newWorkOrder);

    return data;
  }

  public async updateWorkOrderById(
    id: string,
    workOrderData: EditWorkOrderDetails
  ): Promise<WorkOrder> {
    const { data } = await http(`/v1/work-orders/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      data: workOrderData,
    });

    return data;
  }

  public async deleteWorkOrderById(id: string): Promise<void> {
    await http.delete(`/v1/work-orders/${id}`);
  }

  public async fetchWorkOrderById(id: string): Promise<WorkOrderData> {
    const { data } = await http(`/v1/work-orders/${id}`);

    return data;
  }

  public async fetchWorkOrderDetails(
    id: string
  ): Promise<WorkOrderDetailsData> {
    const { data } = await http(`/v1/work-orders/${id}/details`);

    return data;
  }

  public async sendWorkOrderToQueue(id: string): Promise<void> {
    await http.patch(`/v1/work-orders/${id}/queue`);
  }

  public async updateWorkOrderPriority(
    id: string,
    priority: number
  ): Promise<void> {
    await http(`/v1/work-orders/${id}/queue/priority`, {
      method: 'PATCH',
      data: { priority },
    });
  }

  public async createDeviceGroup(
    id: string,
    newDeviceGroup: NewDeviceGroup
  ): Promise<DeviceGroup> {
    const { data } = await http.post(
      `/v1/work-orders/${id}/device-groups`,
      newDeviceGroup
    );

    return data;
  }

  public async fetchWorkOrderSoftwareSalesOrders(
    id: string,
    criteria: Criteria
  ): Promise<PaginatedAPIResponse<SalesOrder>> {
    const { data } = await http(
      `/v1/work-orders/${id}/software/sales-orders?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchWorkOrderSoftwareBySalesOrder(
    id: string,
    salesOrderId: string,
    criteria: Criteria
  ): Promise<PaginatedAPIResponse<SoftwareTableRow>> {
    const { data } = await http(
      `/v1/work-orders/${id}/software/sales-orders/${salesOrderId}?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchWorkOrderDevices(
    id: string,
    criteria: Criteria
  ): Promise<PaginatedAPIResponse<DeviceGroupsTableRow>> {
    const { data } = await http(
      `/v1/work-orders/${id}/devices?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async assignSoftware(
    id: string,
    assignments: NewAssignments
  ): Promise<Assignment[]> {
    const { data } = await http(`/v1/work-orders/${id}/software`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(assignments),
    });

    return data;
  }
  public async fetchWorkOrderDeviceGroups(
    id: string,
    criteria: DeviceGroupsCriteria
  ): Promise<PaginatedAPIResponse<DeviceGroup>> {
    const { data } = await http(
      `/v1/work-orders/${id}/device-groups?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchWorkOrderDeviceGroupEditDevices(
    id: string,
    groupId: string,
    criteria: Criteria
  ): Promise<PaginatedAPIResponse<EditDeviceGroupsTableRow>> {
    const { data } = await http(
      `/v1/work-orders/${id}/device-groups/${groupId}/edit-devices?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }

  public async fetchWorkOrderDeviceGroupViewDevices(
    id: string,
    groupId: string,
    criteria: DeviceGroupsCriteria
  ): Promise<PaginatedAPIResponse<Device>> {
    const { data } = await http(
      `/v1/work-orders/${id}/device-groups/${groupId}/view-devices?` +
        new URLSearchParams(criteria as unknown as Record<string, string>)
    );

    return data;
  }
}

export const fetchWorkOrders = async (
  criteria: Partial<WorkOrdersCriteria>
): Promise<PaginatedAPIResponse<WorkOrder>> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.fetchWorkOrders(criteria);
};

export const createNewWorkOrder = async (
  newWorkOrder: NewWorkOrder
): Promise<WorkOrder> => {
  const service = new WorkOrdersServiceImpl();
  return service.createNewWorkOrder(newWorkOrder);
};

export const updateWorkOrderById = async (
  id: string,
  workOrderData: EditWorkOrderDetails
): Promise<WorkOrder> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.updateWorkOrderById(id, workOrderData);
};

export const deleteWorkOrderById = async (id: string): Promise<void> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.deleteWorkOrderById(id);
};

export const fetchWorkOrderById = async (
  id: string
): Promise<WorkOrderData> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.fetchWorkOrderById(id);
};

export const fetchWorkOrderDetails = async (
  id: string
): Promise<WorkOrderDetailsData> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.fetchWorkOrderDetails(id);
};

export const sendWorkOrderToQueue = async (id: string): Promise<void> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.sendWorkOrderToQueue(id);
};

export const updateWorkOrderPriority = async (
  id: string,
  priority: number
): Promise<void> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.updateWorkOrderPriority(id, priority);
};

export const createDeviceGroup = async (
  workOrderId: string,
  newDeviceGroup: NewDeviceGroup
): Promise<DeviceGroup> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.createDeviceGroup(workOrderId, newDeviceGroup);
};

export const fetchWorkOrderSoftwareSalesOrders = async (
  id: string,
  criteria: SoftwareCriteria
): Promise<PaginatedAPIResponse<SalesOrder>> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.fetchWorkOrderSoftwareSalesOrders(id, criteria);
};

export const fetchWorkOrderSoftwareBySalesOrder = async (
  id: string,
  salesOrderId: string,
  criteria: SoftwareCriteria
): Promise<PaginatedAPIResponse<SoftwareTableRow>> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.fetchWorkOrderSoftwareBySalesOrder(id, salesOrderId, criteria);
};

export const fetchWorkOrderDevices = async (
  id: string,
  criteria: Criteria
): Promise<PaginatedAPIResponse<DeviceGroupsTableRow>> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.fetchWorkOrderDevices(id, criteria);
};

export const assignSoftware = async (
  id: string,
  assignments: NewAssignments
): Promise<Assignment[]> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.assignSoftware(id, assignments);
};
export const fetchWorkOrderDeviceGroups = async (
  id: string,
  criteria: DeviceGroupsCriteria
): Promise<PaginatedAPIResponse<DeviceGroup>> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.fetchWorkOrderDeviceGroups(id, criteria);
};

export const fetchWorkOrderDeviceGroupEditDevices = async (
  id: string,
  groupId: string,
  criteria: Criteria
): Promise<PaginatedAPIResponse<EditDeviceGroupsTableRow>> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.fetchWorkOrderDeviceGroupEditDevices(id, groupId, criteria);
};

export const fetchWorkOrderDeviceGroupViewDevices = async (
  id: string,
  groupId: string,
  criteria: DeviceGroupsCriteria
): Promise<PaginatedAPIResponse<Device>> => {
  const service: WorkOrdersService = new WorkOrdersServiceImpl();

  return service.fetchWorkOrderDeviceGroupViewDevices(id, groupId, criteria);
};
