import {
  getUserHighestPermissionsGroupUserRoleAssignment,
  getUserRoleSalesOrderRelatedPermissionGroupAssignments,
  isSystemDefined,
  PermissionsGroupName,
} from '@zspace/roles';
import { SalesOrder, User, UserRoleType } from '@zspace/types';
import { useCallback, useMemo } from 'react';
import { Element } from 'react-bulma-components';
import SalesOrdersList from '../../sales-orders/sales-orders-list/sales-orders-list';
import Conditional from '../../shared/conditional/conditional';
import { remainingDays } from '../../shared/dates';
import useUser from '../../shared/hooks/user';
import { formatLastActiveDate } from '../../shared/utils';
import PaginatedTable, {
  CustomPaginatedTableProps,
} from '../../ui/table/paginated-table/paginated-table';
import {
  Column,
  ExpandableRow,
  OnTableChangeFunction,
} from '../../ui/table/types';
import { UserStatusText } from '../types';
import { PermissionsGroupBySalesOrder } from '../users-table/users-table';

export type ReadOnlyUsersTableProps = CustomPaginatedTableProps<User> & {
  onChange: OnTableChangeFunction<User, PermissionsGroupBySalesOrder>;
  userRoleType?: UserRoleType;
};

export function ReadOnlyUsersTable({
  dataSource,
  onChange,
  config,
  empty,
  form,
  userRoleType,
}: ReadOnlyUsersTableProps) {
  const { user: currentUser } = useUser();

  const isOwnUser = useCallback(
    (user: User) => user.id === currentUser.id,
    [currentUser.id]
  );

  const isExpandableRow = useCallback((row: User) => {
    const userRoles = new Set<PermissionsGroupName>();
    row.roles.forEach((userRoleAssignment) => {
      userRoleAssignment.permissionsGroupUserRoleAssignments.forEach(
        (permissionsGroupUserRoleAssignment) => {
          userRoles.add(
            permissionsGroupUserRoleAssignment.permissionsGroup
              .name as PermissionsGroupName
          );
        }
      );
    });
    return userRoles.size > 1;
  }, []);

  const isEditableByCustomerUser = useCallback(
    (user: User) => {
      const isSelf = isOwnUser(user);
      const { deletedAt: isDeleted } = user;

      if (isSelf || isDeleted) return false;

      const hasNoRoles = !user.roles.length;

      if (hasNoRoles) return true;

      const hasSystemDefinedRole = user.roles.some((userRoleAssignment) =>
        userRoleAssignment.permissionsGroupUserRoleAssignments.some(
          (permissionsGroupUserRoleAssignment) =>
            isSystemDefined(
              permissionsGroupUserRoleAssignment.permissionsGroup
                .name as PermissionsGroupName
            )
        )
      );

      return hasSystemDefinedRole;
    },
    [isOwnUser]
  );

  const customerColumns: Column<User, PermissionsGroupBySalesOrder>[] = useMemo(
    () => [
      {
        key: 'email',
        widthClassname: 'is-size-25',
        render: (el) => el.email,
        title: 'User',
        sortable: true,
      },
      {
        key: 'role',
        widthClassname: 'is-size-25',
        render: (el) => {
          if (isExpandableRow(el)) {
            return null;
          }
          const role =
            el.roles[0].permissionsGroupUserRoleAssignments[0].permissionsGroup
              .name;
          return <span>{role}</span>;
        },
        title: 'Role',
        expandableContentRender: (el) => el.permissionsGroupName,
      },
      {
        key: 'salesOrders',
        widthClassname: 'is-size-30',
        render: (el) => {
          if (isExpandableRow(el)) {
            return null;
          }
          const parsedSalesOrdersSet = el.roles.reduce(
            (acc, roleAssignment) => {
              roleAssignment.permissionsGroupUserRoleAssignments.forEach(
                (permissionsGroupUserRoleAssignment) => {
                  if (permissionsGroupUserRoleAssignment.salesOrder) {
                    acc.add(permissionsGroupUserRoleAssignment.salesOrder);
                  }
                }
              );
              return acc;
            },
            new Set<SalesOrder>()
          );

          const parsedSalesOrders = Array.from(parsedSalesOrdersSet.values());
          const link = isEditableByCustomerUser(el)
            ? `/users/${el.id}/edit`
            : '';

          return (
            <SalesOrdersList
              list={parsedSalesOrders}
              textColor="primary"
              link={link}
            />
          );
        },
        title: 'Sales orders',
        sortable: false,
        expandableContentRender: (el, parentElement) => {
          if (!el.salesOrders) {
            return null;
          }

          const salesOrders = el.salesOrders;

          const link = isEditableByCustomerUser(parentElement)
            ? `/users/${parentElement.id}/edit`
            : '';

          return (
            <SalesOrdersList
              list={salesOrders}
              textColor="primary"
              link={link}
            />
          );
        },
      },
      {
        key: 'deletedAt',
        widthClassname: 'is-size-15',
        render: (el) => {
          const userStatus = el.lastActive
            ? UserStatusText.ACTIVE
            : UserStatusText.PENDING_FIRST_LOGIN;

          return <span>{userStatus}</span>;
        },
        title: 'Status',
        sortable: false,
      },
    ],
    [isEditableByCustomerUser, isExpandableRow]
  );

  const zspaceStaffAndPartnerColumns: Column<User>[] = useMemo(
    () => [
      {
        key: 'email',
        widthClassname: 'is-size-20',
        dataIndex: 'email',
        title: 'User',
        sortable: true,
      },
      {
        key: 'roles.role.name',
        widthClassname: 'is-size-25',
        render: (el) => {
          const isWarehousePartnerUser = el.roles.every(
            (role) => role.role.name === UserRoleType.PARTNER
          );
          const userPermissionsGroupUserRoleAssignment = isWarehousePartnerUser
            ? el.roles.find((role) => role.role.name === UserRoleType.PARTNER)
                ?.permissionsGroupUserRoleAssignments[0]
            : getUserHighestPermissionsGroupUserRoleAssignment(
                el,
                UserRoleType.ZSPACE_INTERNAL
              );
          const role =
            userPermissionsGroupUserRoleAssignment?.permissionsGroup?.name ??
            '-';

          return <span>{role}</span>;
        },
        title: 'Role',
      },
      {
        key: 'temporaryRoles',
        widthClassname: 'is-size-30',
        render: (el) => {
          const temporaryPermissionsGroupUserRoleAssignments = el.roles.find(
            (roleAssignment) =>
              roleAssignment.role.name === UserRoleType.CUSTOMER
          )?.permissionsGroupUserRoleAssignments;

          if (
            !temporaryPermissionsGroupUserRoleAssignments ||
            temporaryPermissionsGroupUserRoleAssignments.length === 0
          ) {
            return '';
          }

          return temporaryPermissionsGroupUserRoleAssignments.map(
            (permissionsGroupUserRoleAssignment) => {
              const daysLeft = remainingDays(
                new Date(permissionsGroupUserRoleAssignment.expiresAt!)
              );

              if (!daysLeft || !permissionsGroupUserRoleAssignment.salesOrder) {
                return null;
              }

              const content = `${permissionsGroupUserRoleAssignment.salesOrder.opportunity?.account.name} (${daysLeft})`;

              return <span className={`is-size-7`}>{content}</span>;
            }
          );
        },
        title: 'Temporary manager roles (days remaining)',
        sortable: false,
      },
      {
        key: 'lastActive',
        widthClassname: 'is-size-15',
        render: (el) =>
          el.lastActive ? formatLastActiveDate(new Date(el.lastActive)) : '',
        title: 'Last active',
        sortable: true,
      },
      {
        key: 'deletedAt',
        widthClassname: 'is-size-15',
        render: (el) => (
          <Element display="flex" alignItems="center">
            <Conditional condition={el.deletedAt === null}>
              <Conditional.True>
                <span>
                  {el.lastActive
                    ? UserStatusText.ACTIVE
                    : UserStatusText.PENDING_FIRST_LOGIN}
                </span>
              </Conditional.True>
              <Conditional.False>
                <span>Inactive</span>
              </Conditional.False>
            </Conditional>
          </Element>
        ),
        title: 'Status',
        sortable: false,
      },
    ],
    []
  );

  const columns = useMemo(
    () =>
      userRoleType === UserRoleType.CUSTOMER
        ? customerColumns
        : zspaceStaffAndPartnerColumns,
    [customerColumns, userRoleType, zspaceStaffAndPartnerColumns]
  );

  const expandableRow = useMemo(():
    | ExpandableRow<User, PermissionsGroupBySalesOrder>
    | undefined => {
    return {
      expandable: (row: User) => isExpandableRow(row),
      expandableRowIterator: (row: User) => {
        const permissionsGroupsBySalesOrderMap = new Map<
          PermissionsGroupName,
          PermissionsGroupBySalesOrder
        >();

        const userSalesOrderRelatedPermissionGroupsAssignments =
          row.roles.flatMap((userRoleAssignment) =>
            getUserRoleSalesOrderRelatedPermissionGroupAssignments(
              userRoleAssignment
            )
          );

        if (!userSalesOrderRelatedPermissionGroupsAssignments) {
          return [];
        }

        userSalesOrderRelatedPermissionGroupsAssignments.forEach(
          (assignment) => {
            const permissionsGroupName = assignment.permissionsGroup
              .name as PermissionsGroupName;

            const existingGroup =
              permissionsGroupsBySalesOrderMap.get(permissionsGroupName);
            const salesOrders = existingGroup
              ? [...existingGroup.salesOrders, assignment.salesOrder!]
              : [assignment.salesOrder!];

            permissionsGroupsBySalesOrderMap.set(permissionsGroupName, {
              permissionsGroupName,
              salesOrders,
            });
          }
        );

        return Array.from(permissionsGroupsBySalesOrderMap.values());
      },
    };
  }, [isExpandableRow]);

  return (
    <PaginatedTable
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      config={config}
      empty={empty}
      rowKey={'id'}
      form={form}
      expandableRow={expandableRow}
    />
  );
}

export default ReadOnlyUsersTable;
