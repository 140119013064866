import { WorkOrderPermissions } from '@zspace/roles';
import { ReactNode, useState } from 'react';
import { Element } from 'react-bulma-components';
import { Outlet } from 'react-router-dom';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import { CreateWorkOrderPageContext } from './types';

export function CreateWorkOrderPage() {
  const [filterTagList, setFilterTagList] = useState<ReactNode>(null);
  return (
    <ProtectedPage permissions={WorkOrderPermissions.WORK_ORDERS_CREATE}>
      <BoxLayout
        className="mx-10 my-4"
        header={
          <Element className="p-4">
            <section>
              <h1 className="is-size-3 has-text-weight-light">
                Create work order
              </h1>
              <h2 className="is-size-4 has-text-weight-light">
                Select sales orders to include in this work order
              </h2>
            </section>

            {filterTagList && <Element mt={3}>{filterTagList}</Element>}
          </Element>
        }
      >
        <Outlet context={{ setFilterTagList } as CreateWorkOrderPageContext} />
      </BoxLayout>
    </ProtectedPage>
  );
}

export default CreateWorkOrderPage;
