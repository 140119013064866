import { SVGProps } from 'react';
const HandShake = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#363636"
        d="M5.857 15.2H4.8V6.8L7.5 5c.585-.39 1.275-.6 1.98-.6a3.56 3.56 0 0 1 2.321.859l.101.086-3.348 2.392a1.801 1.801 0 0 0 2.092 2.933l2.145-1.53.492.45 5.332 4.883a1.803 1.803 0 0 1 .109 2.543 1.798 1.798 0 0 1-2.543.108l-.292-.266a1.798 1.798 0 0 1-3.008 1.166l-.637-.585a1.798 1.798 0 0 1-2.959.889L5.857 15.2ZM0 7.4c0-.33.27-.6.6-.6h3v8.4c0 .664-.536 1.2-1.2 1.2H1.2c-.664 0-1.2-.536-1.2-1.2V7.4Zm2.4 7.2a.6.6 0 1 0-1.2 0 .6.6 0 0 0 1.2 0Z"
      />
      <path
        fill="#267ABF"
        d="M19.2 15.2V6.8h-.056L16.48 5.026A3.706 3.706 0 0 0 14.42 4.4c-.777 0-1.534.244-2.164.694l-3.698 2.64a1.801 1.801 0 1 0 2.093 2.932l2.145-1.53 5.82 5.337c.225.206.386.457.48.727h.105Zm1.2 0c0 .664.536 1.2 1.2 1.2h1.2c.664 0 1.2-.536 1.2-1.2V7.4c0-.33-.27-.6-.6-.6h-3v8.4Zm1.8-1.2a.6.6 0 1 1 0 1.2.6.6 0 0 1 0-1.2Z"
        opacity={0.4}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default HandShake;
