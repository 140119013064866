import {
  Criteria,
  Device,
  DeviceGroup,
  DeviceGroupsCriteria,
  DeviceModel,
  MyDevicesData,
  PaginatedAPIResponse,
  SortDirection,
} from '@zspace/types';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaRegFolderOpen } from 'react-icons/fa6';
import { fetchDeviceGroupDevices as fetchDeviceGroupDevicesRequest } from '../../device-groups/device-groups-service';
import useHttpRequest from '../../shared/hooks/http-request';
import InboxTable from '../../ui/inbox-table/inbox-table';
import { OnTableChangeData } from '../../ui/table/types';
import { fetchWorkOrderDeviceGroupViewDevices } from '../../work-orders/work-orders-service';
import DeviceGroupsFilterModal from '../filter-modals/device-groups-filter-modal/device-groups-filter-modal';
import DeviceGroupInbox from './device-group-inbox/device-group-inbox';
import DeviceGroupTable from './device-group-table/device-group-table';

export type MyDevices = MyDevicesData & {
  displayUnregistered: boolean;
  displayUngrouped: boolean;
};

export type DeviceGroupInboxTableProps = {
  myDevices?: MyDevices;
  deviceGroups: DeviceGroup[];
  fetchMoreDeviceGroups: () => void;
  hasMoreDeviceGroups: boolean;
  onManageDeviceClick?: (devices: Device[], add: boolean) => void;
  onDeviceGroupClick?: (deviceGroup: DeviceGroup) => void;
  selectedDevices?: Device[];
  leftContent?: ReactNode;
  loadingInitialData?: boolean;
  appliedFilters: object[];
  onTableFilterSubmit: (value: DeviceGroupsCriteria) => void;
  deviceGroupsCriteriaFilter: DeviceGroupsCriteria;
  deviceModels: DeviceModel[];
  isInvalidSelection?: (devices: Device[]) => boolean;
  workOrderId?: string;
  disableSelection?: boolean;
};

const initialCriteria: Criteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
};

export function DeviceGroupInboxTable({
  myDevices,
  deviceGroups,
  fetchMoreDeviceGroups,
  hasMoreDeviceGroups,
  onManageDeviceClick,
  onDeviceGroupClick,
  selectedDevices,
  leftContent,
  appliedFilters,
  loadingInitialData,
  onTableFilterSubmit: handleTableFilterSubmit,
  deviceModels,
  deviceGroupsCriteriaFilter,
  isInvalidSelection,
  workOrderId,
  disableSelection,
}: DeviceGroupInboxTableProps) {
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<string>();
  const [devices, setDevices] = useState<Device[]>([]);
  const [deviceGroupDevicesResponse, setDeviceGroupDevicesResponse] =
    useState<PaginatedAPIResponse<Device>>();
  const { executeHttpRequest } = useHttpRequest();
  const [loadingInitialDevices, setLoadingInitialDevices] = useState(false);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [criteriaFilter, setCriteriaFilter] = useState<Criteria>({
    ...initialCriteria,
  });

  const deviceTypeOptions = useMemo(() => {
    return deviceModels.map((model) => ({
      label: model.displayName,
      value: model.displayName,
    }));
  }, [deviceModels]);

  const tableConfig = useMemo(() => {
    const { search, sortBy, sortDirection } = criteriaFilter;
    return {
      search,
      sortBy,
      sortDirection,
    };
  }, [criteriaFilter]);

  const fetchDeviceGroupDevices = useCallback(
    async (deviceGroupId: string, criteria: DeviceGroupsCriteria) =>
      executeHttpRequest({
        asyncFunction: async () => {
          let response: PaginatedAPIResponse<Device>;

          if (workOrderId) {
            response = await fetchWorkOrderDeviceGroupViewDevices(
              workOrderId,
              deviceGroupId,
              criteria
            );
          } else {
            response = await fetchDeviceGroupDevicesRequest(
              deviceGroupId,
              criteria
            );
          }
          setDeviceGroupDevicesResponse(response);
          setDevices((prevDevices) => [...prevDevices, ...response.data]);
        },
      }),
    [executeHttpRequest, workOrderId]
  );

  const fetchMoreDeviceGroupDevices = useCallback(async () => {
    const updatedCriteriaFilter = {
      ...criteriaFilter,
      pageNumber: criteriaFilter.pageNumber + 1,
    };
    if (selectedDeviceGroup) {
      await fetchDeviceGroupDevices(selectedDeviceGroup, {
        ...deviceGroupsCriteriaFilter,
        ...updatedCriteriaFilter,
      });
      setCriteriaFilter(updatedCriteriaFilter);
    }
  }, [
    criteriaFilter,
    deviceGroupsCriteriaFilter,
    fetchDeviceGroupDevices,
    selectedDeviceGroup,
  ]);

  const onDeviceGroupSelect = useCallback(
    async (deviceGroupId: string) => {
      const { itemsPerPage, pageNumber, sortBy, sortDirection } =
        initialCriteria;
      const updatedCriteria = {
        ...deviceGroupsCriteriaFilter,
        itemsPerPage,
        pageNumber,
        sortBy,
        sortDirection,
      };
      setCriteriaFilter((prevCriteria) => ({
        ...initialCriteria,
        search: prevCriteria.search,
      }));
      setDevices([]);
      setSelectedDeviceGroup(deviceGroupId);
      setLoadingInitialDevices(true);
      await fetchDeviceGroupDevices(deviceGroupId, updatedCriteria);
      setLoadingInitialDevices(false);
    },
    [deviceGroupsCriteriaFilter, fetchDeviceGroupDevices]
  );

  const onFilterModalSubmit = useCallback(
    (value: DeviceGroupsCriteria) => {
      handleTableFilterSubmit(value);
    },
    [handleTableFilterSubmit]
  );

  const onTableChange = useCallback(
    async (value: OnTableChangeData<Device>) => {
      const updatedCriteria = {
        ...value.config,
        sortBy: value.column?.key || deviceGroupsCriteriaFilter.sortBy,
      };
      const { pageNumber } = initialCriteria;
      setCriteriaFilter((prevCriteria) => ({
        ...prevCriteria,
        ...updatedCriteria,
        pageNumber,
      }));
      setLoadingInitialDevices(true);
      setDevices([]);
      await fetchDeviceGroupDevices(selectedDeviceGroup!, {
        ...deviceGroupsCriteriaFilter,
        ...updatedCriteria,
      });
      setLoadingInitialDevices(false);
    },
    [deviceGroupsCriteriaFilter, fetchDeviceGroupDevices, selectedDeviceGroup]
  );

  const onSearchChange = useCallback(
    (value: string) => {
      handleTableFilterSubmit({
        ...deviceGroupsCriteriaFilter,
        search: value,
      });
      setCriteriaFilter((prevCriteria) => ({ ...prevCriteria, search: value }));
    },
    [deviceGroupsCriteriaFilter, handleTableFilterSubmit]
  );

  useEffect(() => {
    setSelectedDeviceGroup(undefined);
  }, [appliedFilters]);

  const NoDeviceGroupSelected = useCallback(
    () => (
      <Element
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="h-full gap-8"
      >
        <Icon className="folder-icon-big">
          <FaRegFolderOpen />
        </Icon>
        <Element textAlign="center">
          <h1 className="is-size-3 has-text-weight-light">
            Nothing to show here
          </h1>
          <h3 className="is-size-5 has-text-weight-light">
            Please select a device group to continue
          </h3>
        </Element>
      </Element>
    ),
    []
  );

  const table = useMemo(() => {
    if (selectedDeviceGroup) {
      return (
        <DeviceGroupTable
          dataSource={devices}
          fetchMore={fetchMoreDeviceGroupDevices}
          hasMore={!!deviceGroupDevicesResponse?.hasMore}
          loadingInitialData={loadingInitialDevices}
          onManageDeviceClick={onManageDeviceClick}
          selectedDevices={selectedDevices}
          isInvalidSelection={isInvalidSelection}
          config={tableConfig}
          onChange={onTableChange}
          disableSelection={disableSelection}
          ignoreSalesOrderPermissions={!!workOrderId}
        />
      );
    }
    return <NoDeviceGroupSelected />;
  }, [
    selectedDeviceGroup,
    NoDeviceGroupSelected,
    devices,
    fetchMoreDeviceGroupDevices,
    deviceGroupDevicesResponse?.hasMore,
    loadingInitialDevices,
    onManageDeviceClick,
    selectedDevices,
    isInvalidSelection,
    tableConfig,
    onTableChange,
    disableSelection,
    workOrderId,
  ]);

  const inbox = useMemo(
    () => (
      <DeviceGroupInbox
        fetchMore={fetchMoreDeviceGroups}
        myDevices={myDevices}
        deviceGroups={deviceGroups}
        hasMore={hasMoreDeviceGroups}
        onClick={onDeviceGroupSelect}
        onClickDeviceGroup={onDeviceGroupClick}
        selectedDeviceGroupId={selectedDeviceGroup}
        loadingInitialData={loadingInitialData}
      />
    ),
    [
      deviceGroups,
      fetchMoreDeviceGroups,
      hasMoreDeviceGroups,
      loadingInitialData,
      myDevices,
      onDeviceGroupClick,
      onDeviceGroupSelect,
      selectedDeviceGroup,
    ]
  );

  return (
    <>
      <InboxTable
        inbox={inbox}
        table={table}
        leftContent={leftContent}
        appliedFilters={appliedFilters}
        onSearchChange={onSearchChange}
        config={tableConfig}
        onFilterClick={() => setIsFilterModalVisible(true)}
      />
      <DeviceGroupsFilterModal
        show={isFilterModalVisible}
        onSubmit={onFilterModalSubmit}
        onClose={() => setIsFilterModalVisible(false)}
        data={deviceGroupsCriteriaFilter}
        deviceTypeOptions={deviceTypeOptions}
      />
    </>
  );
}

export default DeviceGroupInboxTable;
