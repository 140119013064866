import {
  DeviceAssignmentsModalFilterDataType,
  FilterTableModalField,
} from '@zspace/types';
import FilterTableModal from '../../../shared/filter-table-modal/filter-table-modal';
import { deviceAssignmentsFilterModalFields } from '../fields';

export type DeviceAssignmentsModalProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: (value: DeviceAssignmentsModalFilterDataType) => void;
  data: DeviceAssignmentsModalFilterDataType;
  deviceTypeOptions: { label: string; value: string }[];
};

export function DeviceAssignmentsFilterModal({
  show,
  data,
  onClose,
  onSubmit,
  deviceTypeOptions,
}: DeviceAssignmentsModalProps) {
  return (
    <FilterTableModal
      show={show}
      onClose={onClose}
      data={data}
      onSubmit={onSubmit}
      fields={
        deviceAssignmentsFilterModalFields(
          deviceTypeOptions
        ) as FilterTableModalField<DeviceAssignmentsModalFilterDataType>[]
      }
    />
  );
}

export default DeviceAssignmentsFilterModal;
