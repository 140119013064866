import { useCallback } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { CgSpinner } from 'react-icons/cg';
import If from '../../shared/if/if';
import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';

const TITLE = 'Manage my customer’s orders';
const CANCEL_BUTTON_TEXT = 'Cancel';
const SWITCH_ROLE_TEXT = 'Switch role';

export type SwitchToCustomerRoleModalProps = {
  show: boolean;
  onSwitchRole: () => void;
  onClose: () => void;
  loading: boolean;
};

export function SwitchToCustomerRoleModal({
  show,
  onSwitchRole,
  onClose,
  loading,
}: SwitchToCustomerRoleModalProps) {
  const Subtitle = useCallback(
    () => (
      // TODO: Once invitation permissions are restricted, this subtitle has to be updated
      <div>
        <p className="is-size-7 py-2">
          <span>
            Only use this option to manage a customer’s software and devices for
            which you have been granted a{' '}
          </span>
          <span className="has-text-weight-bold">Manager </span>
          <span>or </span>
          <span className="has-text-weight-bold">Temporary Manager </span>
          <span>role.</span>
        </p>
        <p className="is-size-7 py-2">
          <span>To manage your own software and devices, go to </span>
          <span className="has-text-weight-bold">My Devices </span>
          <span>or </span>
          <span className="has-text-weight-bold">My Software </span>
          <span>section.</span>
        </p>
      </div>
    ),
    []
  );

  return (
    <Modal show={show}>
      <h1 className="has-text-weight-light is-size-4 mb-4">{TITLE}</h1>
      <Subtitle />
      <Element
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={8}
      >
        <Button color="primary-dark" outlined onClick={onClose}>
          {CANCEL_BUTTON_TEXT}
        </Button>
        <Button color="primary-dark" outlined onClick={onSwitchRole}>
          <If condition={loading}>
            <Icon>
              <CgSpinner className="animate-spin" />
            </Icon>
          </If>
          <span>{SWITCH_ROLE_TEXT}</span>
        </Button>
      </Element>
    </Modal>
  );
}

export default SwitchToCustomerRoleModal;
