import { RouteObject } from '../app';
import MySalesOrdersPage, {
  loader as mySalesOrderPageLoader,
} from './my-sales-orders-page/my-sales-orders-page';
import SalesOrderDetailsPage, {
  loader as salesOrderDetailsPageLoader,
} from './sales-order-details-page/sales-order-details-page';

export const routes: RouteObject[] = [
  {
    path: 'sales-orders',
    children: [
      {
        loader: salesOrderDetailsPageLoader,
        path: ':id',
        element: <SalesOrderDetailsPage />,
      },
    ],
  },
  {
    path: 'my-sales-orders',
    children: [
      {
        loader: mySalesOrderPageLoader,
        index: true,
        element: <MySalesOrdersPage />,
      },
    ],
  },
];
