import { Element, Icon } from 'react-bulma-components';
import { FaArrowRight } from 'react-icons/fa6';
import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';

export type ActionsPendingModalProps = {
  show: boolean;
  selectedSection: string;
  onSectionClick: () => void;
  onGetStartedClick: () => void;
};

const TITLE = 'Actions pending';
const GENERIC_TEXT =
  'You have multiple actions pending. We recommend that you click on Get started and go through a guided process which will make sure you solve all your pending actions in order to take full advantage of your zSpace ecosystem.';
const GET_STARTED_BUTTON_TEXT = 'Get started';

export function ActionsPendingModal({
  show,
  selectedSection,
  onSectionClick,
  onGetStartedClick,
}: ActionsPendingModalProps) {
  return (
    <Modal show={show} className="modal-home">
      <h1 className="has-text-weight-light is-size-4 mb-4">{TITLE}</h1>
      <p className="is-size-7 py-2">{GENERIC_TEXT}</p>
      <p className="is-size-7 py-2">
        Do you want to go to {selectedSection} or go through the guided process?
      </p>
      <Element
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={8}
      >
        <Button color="primary-dark" outlined onClick={onSectionClick}>
          {selectedSection}
        </Button>
        <Button color="primary-dark" onClick={onGetStartedClick}>
          {GET_STARTED_BUTTON_TEXT}
          <Icon ml={2}>
            <FaArrowRight />
          </Icon>
        </Button>
      </Element>
    </Modal>
  );
}

export default ActionsPendingModal;
