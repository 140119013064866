import { formatSalesOrderNumber } from '@zspace/format';
import { SoftwareTableRow } from '@zspace/types';
import { useCallback, useMemo } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { PiWarningFill } from 'react-icons/pi';
import { Link, useOutletContext } from 'react-router-dom';
import Conditional from '../../shared/conditional/conditional';
import {
  formatDateToLocaleString,
  formatItemFulfillmentNumber,
} from '../../shared/utils';
import InfiniteTable, {
  CustomInfiniteTableProps,
} from '../../ui/table/infinite-table/infinite-table';
import { TableRowCheckboxTooltip } from '../../ui/table/table-row/table-row';
import { Column, RowSelection } from '../../ui/table/types';
import { ManageSoftwareLayoutContext } from '../manage-software-layout/manage-software-layout';

export type SoftwareTableProps = Omit<
  CustomInfiniteTableProps<SoftwareTableRow>,
  'empty'
> & {
  includeSalesOrderColumn?: boolean;
};

export function SoftwareAssignmentTable({
  includeSalesOrderColumn,
  ...tableProps
}: SoftwareTableProps) {
  const {
    selectedDevices,
    selectedSoftware,
    setSelectedSoftware,
    isInvalidSelection,
  } = useOutletContext<ManageSoftwareLayoutContext>();

  const rowSelection: RowSelection = useMemo(() => {
    return {
      onChange: (selectedRowIds) => {
        let newSelectedSoftware = [...selectedSoftware];
        selectedRowIds.forEach((id) => {
          const foundRow = newSelectedSoftware.find((sw) => sw.id === id);

          if (foundRow) {
            newSelectedSoftware = newSelectedSoftware.filter(
              (sw) => sw.id !== id
            );
          } else {
            const softwareFromDataSource =
              tableProps.dataSource.find((sw) => sw.id === id) ||
              ({} as SoftwareTableRow);
            newSelectedSoftware.push(softwareFromDataSource);
          }
        });
        setSelectedSoftware(newSelectedSoftware);
      },
      selectedRowKeys: tableProps.dataSource
        .filter((v) => selectedSoftware.map((sw) => sw.id).includes(v.id))
        .map((v) => v.id),
    };
  }, [selectedSoftware, setSelectedSoftware, tableProps.dataSource]);

  const columns: Column<SoftwareTableRow>[] = useMemo(() => {
    const baseColumns: Column<SoftwareTableRow>[] = [
      {
        key: 'title',
        widthClassname: includeSalesOrderColumn ? 'is-size-30' : 'is-size-45',
        render: (el) => {
          const notEnoughSeats = el.seats.available < selectedDevices.length;
          const repeatedSoftwareTitle =
            selectedSoftware.some((software) => software.id === el.id) &&
            selectedSoftware.some(
              (software) =>
                software.licensingProductGroup.displayName ===
                  el.licensingProductGroup.displayName &&
                (software.salesOrder.id !== el.salesOrder.id ||
                  software.itemFulfillment.id !== el.itemFulfillment.id)
            );
          return (
            <>
              {(notEnoughSeats || repeatedSoftwareTitle) && (
                <Icon color="danger-dark">
                  <PiWarningFill />
                </Icon>
              )}
              <span className="ml-1">
                {el.licensingProductGroup.displayName}
              </span>
            </>
          );
        },
        title: 'Software title',
        sortable: true,
      },
      {
        key: 'item_fulfillment_number',
        widthClassname: 'is-size-15',
        title: 'Fulfillment#',
        render: (el) => formatItemFulfillmentNumber(el.itemFulfillment.number),
        sortable: true,
      },
      {
        key: 'item_fulfillment_contract_end_date',
        widthClassname: 'is-size-15',
        title: 'Contract end date',
        render: (el) => (
          <Conditional condition={!!el.itemFulfillment.contractEndDate}>
            <Conditional.True>
              {formatDateToLocaleString(el.itemFulfillment.contractEndDate!)}
            </Conditional.True>
            <Conditional.False>Perpetual</Conditional.False>
          </Conditional>
        ),
        sortable: true,
      },
      {
        key: 'available',
        widthClassname: 'is-size-15',
        render: (el) => `${el.seats.available}`,
        title: 'Available seats',
        sortable: true,
      },
      {
        key: 'type',
        widthClassname: 'is-size-10',
        dataIndex: 'type',
        title: 'Software type',
        sortable: false,
      },
    ];

    const salesOrderColumn: Column<SoftwareTableRow> = {
      key: 'so_number',
      widthClassname: 'is-size-15',
      title: 'Sales order',
      render: (el) => (
        <Link to={`/sales-orders/${el.salesOrder.id}`}>
          {formatSalesOrderNumber(el.salesOrder.number)}
        </Link>
      ),
      sortable: true,
    };

    if (includeSalesOrderColumn) {
      baseColumns.splice(1, 0, salesOrderColumn);
    }

    return baseColumns;
  }, [includeSalesOrderColumn, selectedDevices.length, selectedSoftware]);

  const EmptyTable = useCallback(
    () => (
      <Element
        display="flex"
        justifyContent="center"
        alignItems="center"
        className="h-full"
      >
        <span className="has-text-weight-light">
          There are no software at the moment
        </span>
      </Element>
    ),
    []
  );

  const isDisabledSelection = useCallback((el: SoftwareTableRow): boolean => {
    return !el.availableToAssign;
  }, []);

  const checkboxTooltipMessage = useCallback(
    (el: SoftwareTableRow): TableRowCheckboxTooltip => {
      return {
        message: el.availableToAssign ? (
          ''
        ) : (
          <div className="is-flex is-align-items-center">
            <Icon color="danger-dark" mr={1}>
              <FaTriangleExclamation />
            </Icon>
            {`Software purchased before ${new Date(
              el.availableFromDate
            ).toLocaleDateString()} cannot be assigned using
            Management Hub. Please contact support.`}
          </div>
        ),
        backgroundColor: el.availableToAssign ? '' : '#FEECF0',
      };
    },
    []
  );

  return (
    <InfiniteTable
      {...tableProps}
      rowKey={'id'}
      columns={columns}
      empty={<EmptyTable />}
      rowSelection={rowSelection}
      isInvalidSelection={isInvalidSelection}
      isDisabledSelection={isDisabledSelection}
      checkboxTooltip={checkboxTooltipMessage}
    />
  );
}

export default SoftwareAssignmentTable;
