import { useCallback, useEffect, useState } from 'react';
import { Element, Form } from 'react-bulma-components';
import { FaArrowRight } from 'react-icons/fa6';
import If from '../../shared/if/if';
import { isEmail } from '../../shared/utils';
import { Button } from '../../ui/button/button';
import FeedbackMessage from '../../ui/feedback-message/feedback-message';
import Modal from '../../ui/modal/modal';
import TagListInput from '../../ui/tag-list-input/tag-list-input';

export type SendRegistrationFileModalProps = {
  show: boolean;
  onCancel: () => void;
  isSendingFile: boolean;
  handleSendRegistrationFile: (recipients: string[]) => void;
};

const MODAL_TITLE = 'Send registration file';
const MODAL_SUBTITLE =
  'Please provide the emails of the people who should have this registration file';
const SUBMIT_BUTTON_TEXT = 'Send registration file';
const CANCEL_BUTTON_TEXT = 'Cancel';

const NO_RECIPIENTS_ERROR_MESSAGE =
  'You must send the file to at least one person';

export function SendRegistrationFileModal({
  show,
  onCancel,
  isSendingFile,
  handleSendRegistrationFile,
}: SendRegistrationFileModalProps) {
  const [recipients, setRecipients] = useState<string[]>([]);
  const [formError, setFormError] = useState<string>();

  const onSendRegistrationFileClick = useCallback(() => {
    if (recipients.length === 0) {
      setFormError(NO_RECIPIENTS_ERROR_MESSAGE);
    } else {
      setFormError(undefined);
      const uniqueRecipients = Array.from(new Set(recipients));
      handleSendRegistrationFile(uniqueRecipients);
    }
  }, [recipients, handleSendRegistrationFile]);

  useEffect(() => {
    if (!show) {
      setFormError(undefined);
      setRecipients([]);
    }
  }, [show]);

  return (
    <Modal show={show}>
      <Element mb={8}>
        <h1 className="has-text-weight-light is-size-4 mb-5">{MODAL_TITLE}</h1>
        <h3 className="is-size-6">{MODAL_SUBTITLE}</h3>
      </Element>
      <Element mb={8}>
        <Form.Label className="has-text-weight-normal is-size-6" marginless>
          Email
        </Form.Label>
        <TagListInput
          errorText="Please enter a valid email address"
          validate={(value: string) => isEmail(value)}
          placeholder="mail@mail.com"
          elements={recipients}
          onChange={(recipients) => setRecipients(recipients as string[])}
        />
        <If condition={!!formError}>
          <FeedbackMessage>{formError}</FeedbackMessage>
        </If>
      </Element>
      <Element display="flex" justifyContent="space-between">
        <Button color="primary-dark" outlined onClick={onCancel}>
          {CANCEL_BUTTON_TEXT}
        </Button>
        <Button
          color="primary-dark"
          onClick={onSendRegistrationFileClick}
          isExecutingAction={isSendingFile}
        >
          <span>{SUBMIT_BUTTON_TEXT}</span>
          <Button.LoadingIcon icon={FaArrowRight} />
        </Button>
      </Element>
    </Modal>
  );
}

export default SendRegistrationFileModal;
