import {
  getUserHighestPermissionsGroupUserRoleAssignment,
  isCustomerRole,
} from '@zspace/roles';
import { useMemo } from 'react';
import { Element, Icon } from 'react-bulma-components';
import useUser from '../../shared/hooks/user';
import If from '../../shared/if/if';
import AnimatedChevronIcon from '../animated-chevron-icon/animated-chevron-icon';
import styles from './profile.module.scss';

export type ProfileProps = {
  active: boolean;
};

export function Profile({ active }: ProfileProps) {
  const { user } = useUser();

  const userFirstNameInitial = useMemo(
    () => user.firstName?.[0] ?? '',
    [user.firstName]
  );

  const hasInternalAndCustomerRoles = useMemo(() => {
    return !user.roles.every((roleAssignment) =>
      isCustomerRole(roleAssignment)
    );
  }, [user.roles]);

  const userHighestGroupPermission = useMemo(
    () => getUserHighestPermissionsGroupUserRoleAssignment(user),
    [user]
  );

  return (
    <Element
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="gap-3"
    >
      <Icon>
        <Element
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={styles.avatar}
        >
          <span className="has-text-grey-darker">{userFirstNameInitial}</span>
        </Element>
      </Icon>
      <If condition={hasInternalAndCustomerRoles}>
        <Element display="flex" alignItems="center" className="gap-1">
          <span className="has-text-primary">
            {userHighestGroupPermission?.permissionsGroup.name}
          </span>
          <Icon color="primary" className={styles.chevronIcon}>
            <AnimatedChevronIcon active={active} />
          </Icon>
        </Element>
      </If>
    </Element>
  );
}

export default Profile;
