import { DeviceGroupsCriteria, FilterTableModalField } from '@zspace/types';
import FilterTableModal from '../../../shared/filter-table-modal/filter-table-modal';
import { deviceGroupsFilterModalFields } from '../fields';

export type DeviceGroupsFilterModalProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: (value: DeviceGroupsCriteria) => void;
  data: DeviceGroupsCriteria;
  deviceTypeOptions: { label: string; value: string }[];
};

export function DeviceGroupsFilterModal({
  show,
  data,
  onClose,
  onSubmit,
  deviceTypeOptions,
}: DeviceGroupsFilterModalProps) {
  return (
    <FilterTableModal
      show={show}
      onClose={onClose}
      data={data}
      onSubmit={onSubmit}
      fields={
        deviceGroupsFilterModalFields(
          deviceTypeOptions
        ) as FilterTableModalField<DeviceGroupsCriteria>[]
      }
    />
  );
}

export default DeviceGroupsFilterModal;
