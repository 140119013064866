import {
  DeviceDetailsTableRow,
  DeviceFailureReason,
  DeviceSwapData,
} from '@zspace/types';
import { http } from '../shared/http';

export interface PartnersService {
  faultyDeviceLookup(serialNumber: string): Promise<DeviceDetailsTableRow>;
  getDeviceFailureReasons(): Promise<DeviceFailureReason[]>;
  replacementDeviceLookup(
    deviceSwapData: DeviceSwapData
  ): Promise<DeviceDetailsTableRow>;
  replaceDevice(deviceSwapData: DeviceSwapData): Promise<DeviceDetailsTableRow>;
}

export class PartnersServiceImpl implements PartnersService {
  public async faultyDeviceLookup(
    serialNumber: string
  ): Promise<DeviceDetailsTableRow> {
    const { data } = await http.get<DeviceDetailsTableRow>(
      `/v1/device-swap/${serialNumber}`
    );

    return data;
  }

  public async getDeviceFailureReasons(): Promise<DeviceFailureReason[]> {
    const { data } = await http.get<DeviceFailureReason[]>(
      '/v1/device-swap/failure-reasons'
    );

    return data;
  }

  public async replacementDeviceLookup(
    deviceSwapData: DeviceSwapData
  ): Promise<DeviceDetailsTableRow> {
    const { data } = await http.post<DeviceDetailsTableRow>(
      `/v1/device-swap/validate`,
      deviceSwapData
    );

    return data;
  }

  public async replaceDevice(
    deviceSwapData: DeviceSwapData
  ): Promise<DeviceDetailsTableRow> {
    const { data } = await http.post<DeviceDetailsTableRow>(
      '/v1/device-swap/replace',
      deviceSwapData
    );

    return data;
  }
}

export const faultyDeviceLookup = async (
  serialNumber: string
): Promise<DeviceDetailsTableRow> => {
  const service = new PartnersServiceImpl();

  return service.faultyDeviceLookup(serialNumber);
};

export const getDeviceFailureReasons = async (): Promise<
  DeviceFailureReason[]
> => {
  const service = new PartnersServiceImpl();

  return service.getDeviceFailureReasons();
};

export const replacementDeviceLookup = async (
  deviceSwapData: DeviceSwapData
): Promise<DeviceDetailsTableRow> => {
  const service = new PartnersServiceImpl();

  return service.replacementDeviceLookup(deviceSwapData);
};

export const replaceDevice = async (
  deviceSwapData: DeviceSwapData
): Promise<DeviceDetailsTableRow> => {
  const service = new PartnersServiceImpl();

  return service.replaceDevice(deviceSwapData);
};
