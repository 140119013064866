import { formatSalesOrderNumber, formatWorkOrderNumber } from '@zspace/format';
import { DeviceDetailsTableRow } from '@zspace/types';
import { ReactNode, useMemo } from 'react';
import { Element } from 'react-bulma-components';
import { formatItemFulfillmentNumber } from '../../../shared/utils';
import PaginatedTable, {
  CustomPaginatedTableProps,
  PaginatedTableProps,
} from '../../../ui/table/paginated-table/paginated-table';
import { Column } from '../../../ui/table/types';
import styles from './device-details-table.module.scss';

export type DeviceDetailsTableProps = Pick<
  CustomPaginatedTableProps<DeviceDetailsTableRow>,
  'dataSource'
>;

const OptionalContent = ({
  condition,
  children,
}: {
  condition: boolean;
  children: ReactNode;
}) => {
  if (!condition) {
    return <span>-</span>;
  }
  return children;
};

export function DeviceDetailsTable({ dataSource }: DeviceDetailsTableProps) {
  const columns: Column<DeviceDetailsTableRow>[] = useMemo(
    () => [
      {
        key: 'serialNumber',
        widthClassname: 'is-size-20',
        title: 'Serial number',
        render: (el) => el.serialNumber,
      },
      {
        key: 'deviceType',
        widthClassname: 'is-size-20',
        title: 'Device type',
        render: (el) => el.deviceType,
      },
      {
        key: 'inventoryLocation',
        widthClassname: 'is-size-10',
        title: 'Inventory location',
        render: (el) => el.inventoryLocation,
      },
      {
        key: 'assignedSoftware',
        widthClassname: 'is-size-20',
        title: 'Assigned software',
        render: (el) => (
          <Element className={styles.overflowTextColumn}>
            <OptionalContent condition={!!el.assignedSoftware}>
              {el.assignedSoftware}
            </OptionalContent>
          </Element>
        ),
      },
      {
        key: 'workOrder',
        widthClassname: 'is-size-10',
        title: 'Work order',
        render: (el) => (
          <OptionalContent condition={!!el.workOrder?.number}>
            {formatWorkOrderNumber(el.workOrder?.number || '')}
          </OptionalContent>
        ),
      },
      {
        key: 'salesOrder',
        widthClassname: 'is-size-10',
        title: 'Sales order',
        render: (el) => (
          <OptionalContent condition={!!el.salesOrder?.number}>
            {formatSalesOrderNumber(el.salesOrder?.number || '')}
          </OptionalContent>
        ),
      },
      {
        key: 'itemFulfillment',
        widthClassname: 'is-size-10',
        title: 'Item fulfillment',
        render: (el) => (
          <OptionalContent condition={!!el.itemFulfillment?.number}>
            {formatItemFulfillmentNumber(el.itemFulfillment?.number || '')}
          </OptionalContent>
        ),
      },
    ],
    []
  );

  const tableProps: PaginatedTableProps<DeviceDetailsTableRow> = useMemo(
    () => ({
      columns,
      dataSource,
      rowKey: 'serialNumber',
      form: { show: false },
      config: {
        pageNumber: 1,
        itemsPerPage: 10,
        pages: 1,
        search: '',
        sortBy: '',
        totalItems: dataSource.length,
      },
    }),
    [columns, dataSource]
  );

  return <PaginatedTable {...tableProps} />;
}

export default DeviceDetailsTable;
