import { UnauthorizedResponse, UserRoleType } from '@zspace/types';
import { AxiosError } from 'axios';
import { MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { Navbar as BulmaNavbar, Element } from 'react-bulma-components';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../shared/auth-service';
import Conditional from '../../../shared/conditional/conditional';
import useHttpRequest from '../../../shared/hooks/http-request';
import useIsCustomerUser from '../../../shared/hooks/is-customer-user';
import useIsZspaceInternalUser from '../../../shared/hooks/is-zspace-internal-user';
import useToast from '../../../shared/hooks/toasts';
import useUser from '../../../shared/hooks/user';
import If from '../../../shared/if/if';
import { switchRole } from '../../../users/users-service';
import SwitchToCustomerRoleModal from '../../../zspace-internal/switch-to-customer-role-modal/switch-to-customer-role-modal';
import Spinner from '../../spinner/spinner';

const SWITCH_ROLE_ERROR_MESSAGE = 'Could not switch role. Please try again';

export type LoadingAction = {
  switchRole?: boolean;
  logout?: boolean;
};

export function ProfileActions() {
  const { user } = useUser();
  const isZspaceInternalUser = useIsZspaceInternalUser();
  const isCustomerUser = useIsCustomerUser();
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoadingAction, setIsLoadingAction] = useState<LoadingAction>({
    logout: false,
  });
  const { executeHttpRequest, isLoading: isSwitchingRole } = useHttpRequest();
  const [showSwitchRoleModal, setShowSwitchRoleModal] =
    useState<boolean>(false);

  const hasZspaceInternalAndCustomerRoles = useMemo(() => {
    let hasZspaceInternalRole = false;
    let hasCustomerRole = false;
    user.roles.forEach((roleAssignment) => {
      switch (roleAssignment.role.name) {
        case UserRoleType.ZSPACE_INTERNAL:
          hasZspaceInternalRole = true;
          break;
        case UserRoleType.CUSTOMER:
          hasCustomerRole = true;
          break;
      }
    });
    return hasZspaceInternalRole && hasCustomerRole;
  }, [user.roles]);

  const availableUserRoleToSwitch = useMemo(() => {
    const newActiveRoleType = isZspaceInternalUser
      ? UserRoleType.CUSTOMER
      : UserRoleType.ZSPACE_INTERNAL;
    const newActiveRoleAssignment = user.roles.find(
      (roleAssignment) => roleAssignment.role.name === newActiveRoleType
    );
    return newActiveRoleAssignment;
  }, [isZspaceInternalUser, user.roles]);

  const switchToRoleText = useMemo(() => {
    return isZspaceInternalUser
      ? 'Manage my customer’s orders'
      : 'Switch to zSpace role';
  }, [isZspaceInternalUser]);

  const handleLogout = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      try {
        setIsLoadingAction({ logout: true });
        event.stopPropagation();
        const logoutResponse = await logout();
        const loginUrl = logoutResponse.authUrl;
        if (loginUrl) {
          window.location.assign(loginUrl);
        }
        setIsLoadingAction({ logout: false });
      } catch (error) {
        setIsLoadingAction({ logout: false });
        if (error instanceof AxiosError) {
          const loginUrl = (error as AxiosError<UnauthorizedResponse>).response
            ?.data?.authUrl;
          if (loginUrl) {
            window.location.assign(loginUrl);
          } else {
            toast.error('Could not logout. Please try again');
          }
        }
      }
    },
    [toast]
  );

  const handleSwitchRole = useCallback(async () => {
    return executeHttpRequest({
      asyncFunction: async () => {
        await switchRole(availableUserRoleToSwitch!.id);
        window.location.replace('/');
      },
      customErrorMessage: SWITCH_ROLE_ERROR_MESSAGE,
    });
  }, [availableUserRoleToSwitch, executeHttpRequest]);

  const onSwitchRoleClick = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation();
      if (isZspaceInternalUser) {
        setShowSwitchRoleModal(true);
      } else {
        await handleSwitchRole();
      }
    },
    [handleSwitchRole, isZspaceInternalUser]
  );

  const navigateToUserDetails = useCallback(() => {
    navigate(`users/${user.id}`);
  }, [navigate, user.id]);

  return (
    <>
      <If condition={hasZspaceInternalAndCustomerRoles}>
        <BulmaNavbar.Item onClick={onSwitchRoleClick}>
          <Conditional condition={isCustomerUser}>
            <Conditional.True>
              <LoadingElement loading={!!isSwitchingRole}>
                {switchToRoleText}
              </LoadingElement>
            </Conditional.True>
            <Conditional.False>{switchToRoleText}</Conditional.False>
          </Conditional>
        </BulmaNavbar.Item>
      </If>
      <If condition={isCustomerUser}>
        <BulmaNavbar.Item onClick={navigateToUserDetails}>
          Account
        </BulmaNavbar.Item>
      </If>
      <BulmaNavbar.Item onClick={handleLogout}>
        <LoadingElement loading={!!isLoadingAction.logout}>
          Logout
        </LoadingElement>
      </BulmaNavbar.Item>
      <SwitchToCustomerRoleModal
        show={showSwitchRoleModal}
        loading={isSwitchingRole}
        onClose={() => setShowSwitchRoleModal(false)}
        onSwitchRole={handleSwitchRole}
      />
    </>
  );
}

function LoadingElement({
  children,
  loading,
}: {
  children: ReactNode;
  loading: boolean;
}) {
  return (
    <Element display="flex" alignItems="center" className="gap-5">
      {children}
      <Spinner style={{ opacity: loading ? 1 : 0 }} size="small" />
    </Element>
  );
}

export default ProfileActions;
