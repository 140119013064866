import { Element } from 'react-bulma-components';
import { createPortal } from 'react-dom';
import Toast, { ToastProps } from '../toast';
import styles from '../toast.module.scss';

export type ToastsListProps = {
  toasts: ToastProps[];
};

export function ToastsList({ toasts }: ToastsListProps) {
  return createPortal(
    <Element className={styles.toastList}>
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </Element>,
    document.body
  );
}

export default ToastsList;
