import { formatSalesOrderNumber } from '@zspace/format';
import { SalesOrder } from '@zspace/types';
import { useMemo } from 'react';
import { Element } from 'react-bulma-components';
import InboxItem from '../../ui/inbox-table/inbox-item/inbox-item';

export type SoftwareInboxItemProps = {
  salesOrder: SalesOrder;
  onClick: () => void;
  active: boolean;
};

export function SoftwareInboxItem({
  active,
  onClick,
  salesOrder,
}: SoftwareInboxItemProps) {
  const subtitle = useMemo(() => {
    const account = salesOrder.opportunity?.account.name;
    const opportunityName = salesOrder.opportunity?.name;

    if (!account && !opportunityName) return null;

    return `${account} - ${opportunityName}`;
  }, [salesOrder.opportunity?.account.name, salesOrder.opportunity?.name]);

  return (
    <InboxItem active={active} onClick={onClick}>
      <Element display="flex" flexDirection="column" className="gap-1">
        <span>{formatSalesOrderNumber(salesOrder.number)}</span>
        <span className="is-size-7">{subtitle}</span>
      </Element>
    </InboxItem>
  );
}

export default SoftwareInboxItem;
