import { useMemo } from 'react';

export function useIsEmpty<T>(data: T) {
  const isDataEmpty = useMemo(() => {
    if (Array.isArray(data)) {
      return data.length === 0;
    }

    if (typeof data === 'object') {
      return Object.keys(data as object).length === 0;
    }

    return !data;
  }, [data]);

  return isDataEmpty;
}

export default useIsEmpty;
