import {
  Permissions,
  PermissionsGroupName,
  hasPermissions,
} from '@zspace/roles';
import { useCallback } from 'react';
import useUser from './user';

export function usePermissions() {
  const { user } = useUser();

  const userHasPermissions = useCallback(
    ({
      permissions,
      salesOrderIdsRelatedPermissions,
      allowedPermissionGroups,
    }: {
      permissions: Permissions | Permissions[];
      salesOrderIdsRelatedPermissions?: string[];
      allowedPermissionGroups?: PermissionsGroupName[];
    }) => {
      const permissionsToCheck = Array.isArray(permissions)
        ? permissions
        : [permissions];

      return hasPermissions(
        user,
        permissionsToCheck,
        salesOrderIdsRelatedPermissions,
        allowedPermissionGroups
      );
    },
    [user]
  );

  return userHasPermissions;
}

export default usePermissions;
