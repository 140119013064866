import { Element, Icon } from 'react-bulma-components';
import { FaArrowRight } from 'react-icons/fa6';
import If from '../if/if';
import TaskTag from '../task-tag/task-tag';
import styles from './home-card-cta.module.scss';

export type HomeCardCtaProps = {
  title: string;
  description?: string;
  onClick: () => void;
  pendingTasks?: string[];
};

export function HomeCardCta({
  title,
  description,
  onClick,
  pendingTasks,
}: HomeCardCtaProps) {
  return (
    <Element
      display="flex"
      flexDirection="column"
      className={`${styles.container} h-full gap-8 box`}
    >
      <Element
        className={`is-clickable ${styles.titleContainer}`}
        display="flex"
        alignItems="center"
        onClick={onClick}
      >
        <h3
          className={`is-size-4 has-text-primary-dark has-text-weight-light ${styles.title}`}
        >
          {title}
        </h3>
        <Icon color="primary-dark" className={styles.chevronIcon} ml={2}>
          <FaArrowRight />
        </Icon>
      </Element>
      <If condition={!!description}>
        <Element>
          <h5 className="is-size-6 has-text-primary-dark has-text-weight-normal">
            {description}
          </h5>
        </Element>
      </If>
      <If condition={!!pendingTasks && pendingTasks?.length > 0}>
        <Element display="flex" flexDirection="row">
          {pendingTasks?.map((task, index) => (
            <TaskTag taskContent={task} key={`${task}-${index}`} pending />
          ))}
        </Element>
      </If>
    </Element>
  );
}

export default HomeCardCta;
