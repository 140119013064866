import {
  DeviceRegistrationStatus,
  RegistrationFileDevicesExpandableTableRow,
  RegistrationFileDevicesTableRow,
} from '@zspace/types';
import { useCallback, useMemo } from 'react';
import { formatDateToLocaleString } from '../../shared/utils';
import PaginatedTable, {
  CustomPaginatedTableProps,
} from '../../ui/table/paginated-table/paginated-table';
import {
  Column,
  ExpandableRow,
  OnTableChangeFunction,
} from '../../ui/table/types';

export type RegistrationFileDevicesTableProps =
  CustomPaginatedTableProps<RegistrationFileDevicesTableRow> & {
    onChange: OnTableChangeFunction<
      RegistrationFileDevicesTableRow,
      RegistrationFileDevicesExpandableTableRow
    >;
  };

export function RegistrationFileDevicesTable({
  dataSource,
  config,
  onChange,
  empty,
  form,
}: RegistrationFileDevicesTableProps) {
  const isExpandableRow = useCallback(
    (row: RegistrationFileDevicesTableRow) => {
      return row.registrations.length > 1;
    },
    []
  );

  const expandableRow = useMemo(():
    | ExpandableRow<
        RegistrationFileDevicesTableRow,
        RegistrationFileDevicesExpandableTableRow
      >
    | undefined => {
    return {
      expandable: (row: RegistrationFileDevicesTableRow) =>
        isExpandableRow(row),
      expandableRowIterator: (row: RegistrationFileDevicesTableRow) => {
        return row.registrations;
      },
    };
  }, [isExpandableRow]);

  const columns: Column<
    RegistrationFileDevicesTableRow,
    RegistrationFileDevicesExpandableTableRow
  >[] = [
    {
      key: 'serialNumber.number',
      widthClassname: 'is-size-15',
      render: (el) => el.serialNumber,
      title: 'Serial number',
      sortable: true,
    },
    {
      key: 'name',
      widthClassname: 'is-size-15',
      render: (el) => el.name,
      title: 'Device name',
      sortable: true,
    },
    {
      key: 'model.displayName',
      widthClassname: 'is-size-10',
      render: (el) => el.type,
      title: 'Device type',
      sortable: true,
    },
    {
      key: 'status',
      widthClassname: 'is-size-10',
      render: (el) => el.status,
      title: 'Status',
      sortable: false,
    },
    {
      key: 'registrations.registrationMethod',
      widthClassname: 'is-size-35',
      render: (el) => {
        if (isExpandableRow(el)) {
          return null;
        }
        if (el.status === DeviceRegistrationStatus.UNREGISTERED) {
          return '-';
        }
        return <span>{el.registrations[0].registrationMethod}</span>;
      },
      expandableContentRender: (
        el: RegistrationFileDevicesExpandableTableRow
      ) => <span>{el.registrationMethod}</span>,
      title: 'Registration method',
      sortable: false,
    },
    {
      key: 'registrations.registrationDate',
      widthClassname: 'is-size-10',
      render: (el) => {
        if (isExpandableRow(el)) {
          return null;
        }
        if (el.status === DeviceRegistrationStatus.UNREGISTERED) {
          return '-';
        }
        return (
          <span>
            {el.registrations[0].registrationDate
              ? formatDateToLocaleString(el.registrations[0].registrationDate)
              : '-'}
          </span>
        );
      },
      expandableContentRender: (
        el: RegistrationFileDevicesExpandableTableRow
      ) => (
        <span>
          {el.registrationDate
            ? formatDateToLocaleString(el.registrationDate)
            : '-'}
        </span>
      ),
      title: 'Registration date',
      sortable: false,
    },
  ];

  return (
    <PaginatedTable
      dataSource={dataSource}
      columns={columns}
      config={config}
      rowKey={'id'}
      onChange={onChange}
      empty={empty}
      form={form}
      expandableRow={expandableRow}
    />
  );
}

export default RegistrationFileDevicesTable;
