import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Element, Icon } from 'react-bulma-components';
import { RenderAsComponent } from 'react-bulma-components/src/components';
import { FaSpinner } from 'react-icons/fa6';
import If from '../../shared/if/if';
import styles from './infinite-scroll.module.scss';

export type InfiniteScrollProps = {
  children: ReactNode;
  fetchMore: () => void;
  hasMore: boolean;
  footer?: ReactNode;
  fadeAtBottom?: boolean;
};

const FADE_THRESHOLD = 150;

export function InfiniteScroll({
  children,
  fetchMore,
  hasMore,
  footer,
  fadeAtBottom = true,
}: InfiniteScrollProps) {
  const loaderRef = useRef(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const fadeClassName = useMemo(() => {
    let baseClassName = styles.fade;

    if (footer) {
      baseClassName += ` ${styles.footerFade}`;
    }

    return baseClassName;
  }, [footer]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        fetchMore();
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current!);
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore, fetchMore]);

  const handleScroll = useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const isBottom =
      container.scrollHeight - container.scrollTop <=
      container.clientHeight + FADE_THRESHOLD;
    setIsAtBottom(isBottom);
  }, []);

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (!container) return;

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    handleScroll();
  }, [children, handleScroll]);

  return (
    <Element
      display="flex"
      flexDirection="column"
      className={styles.container}
      domRef={scrollContainerRef as RefObject<RenderAsComponent>}
    >
      {children}

      <If condition={hasMore}>
        <Element
          domRef={loaderRef}
          display="flex"
          justifyContent="center"
          pb={3}
        >
          <Icon className="animate-spin">
            <FaSpinner />
          </Icon>
        </Element>
      </If>

      <If condition={!isAtBottom && fadeAtBottom}>
        <Element className={fadeClassName} />
      </If>

      <If condition={!!footer}>
        <Element className={styles.footer}>{footer}</Element>
      </If>
    </Element>
  );
}

export default InfiniteScroll;
