import { formatSalesOrderNumber } from '@zspace/format';
import {
  AllYesNoFilter,
  FilterTableModalField,
  FilterTableModalFieldAlignment,
  InputType,
  UserStatus,
  UsersTableFilterDataType,
} from '@zspace/types';

type PermissionsGroupOption = {
  label: string;
  value: string;
};

export const NON_CUSTOMER_FILTER_MODAL_FIELDS = (
  permissionsGroupOptions: PermissionsGroupOption[]
): FilterTableModalField<UsersTableFilterDataType>[] => [
  {
    input: {
      type: InputType.FILTER_CRITERIA,
      filterValueKey: 'emailFilter',
      valueKey: 'email',
    },
    label: 'User',
    name: 'user',
    alignment: FilterTableModalFieldAlignment.LEFT,
  },
  {
    input: {
      type: InputType.DATE_RANGE,
      fromDateValueKey: 'lastActiveDateFrom',
      toDateValueKey: 'lastActiveDateTo',
    },
    label: 'Last active',
    name: 'lastActiveDateRange',
    alignment: FilterTableModalFieldAlignment.LEFT,
  },
  {
    input: {
      type: InputType.SELECT,
      valueKey: 'temporaryRoles',
      options: [
        {
          label: 'All',
          value: AllYesNoFilter.ALL,
        },
        {
          label: 'Yes',
          value: AllYesNoFilter.YES,
        },
        {
          label: 'No',
          value: AllYesNoFilter.NO,
        },
      ],
    },
    name: 'temporaryRoles',
    label: 'Temporary roles',
    alignment: FilterTableModalFieldAlignment.LEFT,
  },
  {
    input: {
      type: InputType.SELECT,
      valueKey: 'permissionsGroup',
      options: permissionsGroupOptions,
    },
    label: 'Role',
    name: 'role',
    alignment: FilterTableModalFieldAlignment.RIGHT,
  },
  {
    input: {
      type: InputType.SELECT,
      valueKey: 'status',
      options: [
        {
          label: 'All',
          value: UserStatus.ALL,
        },
        {
          label: 'Active',
          value: UserStatus.ACTIVE,
        },
        {
          label: 'Pending first login',
          value: UserStatus.PENDING_FIRST_LOGIN,
        },
        {
          label: 'Inactive',
          value: UserStatus.INACTIVE,
        },
      ],
    },
    name: 'status',
    label: 'Status',
    alignment: FilterTableModalFieldAlignment.RIGHT,
  },
];

export const CUSTOMER_FILTER_MODAL_FIELDS = (
  permissionsGroupOptions: PermissionsGroupOption[]
): FilterTableModalField<UsersTableFilterDataType>[] => [
  {
    input: {
      type: InputType.FILTER_CRITERIA,
      filterValueKey: 'emailFilter',
      valueKey: 'email',
    },
    label: 'User',
    name: 'user',
    alignment: FilterTableModalFieldAlignment.LEFT,
  },
  {
    input: {
      type: InputType.TAG_INPUT,
      valueKey: 'salesOrder',
      placeholder: formatSalesOrderNumber('12345'),
    },
    label: 'Sales order #',
    name: 'salesOrder',
    alignment: FilterTableModalFieldAlignment.LEFT,
  },
  {
    input: {
      type: InputType.SELECT,
      valueKey: 'temporaryRoles',
      options: [
        {
          label: 'All',
          value: AllYesNoFilter.ALL,
        },
        {
          label: 'Yes',
          value: AllYesNoFilter.YES,
        },
        {
          label: 'No',
          value: AllYesNoFilter.NO,
        },
      ],
    },
    name: 'temporaryRoles',
    label: 'Temporary role',
    alignment: FilterTableModalFieldAlignment.LEFT,
  },
  {
    input: {
      type: InputType.SELECT,
      valueKey: 'permissionsGroup',
      options: permissionsGroupOptions,
    },
    label: 'Role',
    name: 'role',
    alignment: FilterTableModalFieldAlignment.RIGHT,
  },
  {
    input: {
      type: InputType.SELECT,
      valueKey: 'status',
      options: [
        {
          label: 'All',
          value: UserStatus.ALL,
        },
        {
          label: 'Active',
          value: UserStatus.ACTIVE,
        },
        {
          label: 'Pending first login',
          value: UserStatus.PENDING_FIRST_LOGIN,
        },
      ],
    },
    name: 'status',
    label: 'Status',
    alignment: FilterTableModalFieldAlignment.RIGHT,
  },
];

export const CUSTOMER_FILTER_MODAL_FIELDS_ORG_END_USER_SOFTWARE_CONTACTS: FilterTableModalField<UsersTableFilterDataType>[] =
  [
    {
      input: {
        type: InputType.FILTER_CRITERIA,
        filterValueKey: 'emailFilter',
        valueKey: 'email',
      },
      label: 'User',
      name: 'user',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.TAG_INPUT,
        valueKey: 'salesOrder',
        placeholder: formatSalesOrderNumber('12345'),
      },
      label: 'Sales order #',
      name: 'salesOrder',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.SELECT,
        valueKey: 'status',
        options: [
          {
            label: 'All',
            value: UserStatus.ALL,
          },
          {
            label: 'Active',
            value: UserStatus.ACTIVE,
          },
          {
            label: 'Pending first login',
            value: UserStatus.PENDING_FIRST_LOGIN,
          },
        ],
      },
      name: 'status',
      label: 'Status',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
  ];

export const WAREHOUSE_PARTNER_FILTER_MODAL_FIELDS: FilterTableModalField<UsersTableFilterDataType>[] =
  [
    {
      input: {
        type: InputType.FILTER_CRITERIA,
        filterValueKey: 'emailFilter',
        valueKey: 'email',
      },
      label: 'User',
      name: 'user',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.DATE_RANGE,
        fromDateValueKey: 'lastActiveDateFrom',
        toDateValueKey: 'lastActiveDateTo',
      },
      label: 'Last active',
      name: 'lastActiveDateRange',
      alignment: FilterTableModalFieldAlignment.LEFT,
    },
    {
      input: {
        type: InputType.SELECT,
        valueKey: 'status',
        options: [
          {
            label: 'All',
            value: UserStatus.ALL,
          },
          {
            label: 'Active',
            value: UserStatus.ACTIVE,
          },
          {
            label: 'Pending first login',
            value: UserStatus.PENDING_FIRST_LOGIN,
          },
          {
            label: 'Inactive',
            value: UserStatus.INACTIVE,
          },
        ],
      },
      name: 'status',
      label: 'Status',
      alignment: FilterTableModalFieldAlignment.RIGHT,
    },
  ];
