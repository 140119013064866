import { capitalizeString } from '@zspace/format';
import { SalesOrderPermissions } from '@zspace/roles';
import {
  Account,
  AllYesNoFilter,
  FilterType,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  SalesOrder,
  SalesOrdersCriteria,
  SalesOrdersTableModalFilterDataType,
  User,
  UserRoleType,
  UsersCriteria,
  UsersTableFilterDataType,
  UserStatus,
  UserViewMode,
} from '@zspace/types';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaFileInvoice, FaFilter, FaUsers } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import BackButton from '../../shared/back-button/back-button';
import Conditional from '../../shared/conditional/conditional';
import useError from '../../shared/hooks/error';
import ProtectedPage from '../../shared/protected-page/protected-page';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import DetailsCard from '../../ui/details-card/details-card';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { OnTableChangeData } from '../../ui/table/types';
import UsersTableFilterModal from '../../users/filter-modals/users-table-filter-modal/users-table-filter-modal';
import ReadOnlyUsersTable from '../../users/read-only-users-table/read-only-users-table';
import { PermissionsGroupBySalesOrder } from '../../users/users-table/users-table';
import { salesOrderDetailsFilterFields } from '../../work-orders/sales-orders-table-filter-modal/constants';
import SalesOrdersTableFilterModal from '../../work-orders/sales-orders-table-filter-modal/sales-orders-table-filter-modal';
import { salesOrderDetailsColumns } from '../../work-orders/sales-orders-table/constants';
import SalesOrdersTable from '../../work-orders/sales-orders-table/sales-orders-table';
import {
  fetchAccountById,
  fetchAccountRelatedSalesOrders,
  fetchAccountRelatedUsers,
} from '../accounts-service';

const defaultSalesOrdersTableCriteria: SalesOrdersCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  opportunityName: '',
  opportunityNameFilter: FilterType.CONTAINS,
  account: '',
  accountFilter: FilterType.CONTAINS,
  minTotalSeats: '',
  maxTotalSeats: '',
  salesOrders: [],
  closeDateFrom: '',
  closeDateTo: '',
  parentAccountName: '',
  parentAccountNameFilter: FilterType.CONTAINS,
  endUser: [],
  minHardwareNumber: '',
  maxHardwareNumber: '',
};

const defaultUsersTableCriteria: UsersCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  email: '',
  emailFilter: FilterType.CONTAINS,
  lastActiveDateFrom: '',
  lastActiveDateTo: '',
  permissionsGroup: '',
  temporaryRoles: AllYesNoFilter.ALL,
  status: UserStatus.ALL,
  viewMode: UserViewMode.RELATED_USERS,
  salesOrder: [],
  accountId: '',
};

export function AccountDetailsPage() {
  const params = useParams();
  const { setError } = useError();
  const [isLoadingAccount, setIsLoadingAccount] = useState(true);
  const [isLoadingSalesOrders, setIsLoadingSalesOrders] = useState(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [account, setAccount] = useState<Account>();
  const [relatedSalesOrders, setRelatedSalesOrders] = useState<
    PaginatedAPIResponse<SalesOrder>
  >({
    data: [],
    count: 0,
    page: 0,
    total: 0,
    pages: 0,
  });
  const [relatedUsers, setRelatedUsers] = useState<PaginatedAPIResponse<User>>({
    data: [],
    count: 0,
    page: 0,
    total: 0,
    pages: 0,
  });
  const [salesOrdersTableCriteria, setSalesOrdersTableCriteria] =
    useState<SalesOrdersCriteria>(defaultSalesOrdersTableCriteria);
  const [usersTableCriteria, setUsersTableCriteria] = useState<UsersCriteria>(
    defaultUsersTableCriteria
  );
  const [isSalesOrdersFilterModalVisible, setIsSalesOrdersFilterModalVisible] =
    useState(false);
  const [isUsersFilterModalVisible, setIsUsersFilterModalVisible] =
    useState(false);

  const accountSalesOrders = useMemo(() => {
    const salesOrders = account?.opportunities?.reduce(
      (acc, current) => acc + current.salesOrders.length,
      0
    );
    const text = salesOrders === 1 ? 'sales order' : 'sales orders';
    return `${salesOrders} ${text}`;
  }, [account?.opportunities]);

  const salesOrdersTableConfig: PaginatedContentConfig = useMemo(
    () => ({
      ...salesOrdersTableCriteria,
      pages: relatedSalesOrders.pages,
    }),
    [relatedSalesOrders.pages, salesOrdersTableCriteria]
  );

  const usersTableConfig: PaginatedContentConfig = useMemo(
    () => ({
      ...usersTableCriteria,
      pages: relatedUsers.pages,
    }),
    [relatedUsers.pages, usersTableCriteria]
  );

  const emptyUsersContentText = useMemo(() => {
    const hasAppliedFilters = usersTableCriteria.search !== '';
    if (hasAppliedFilters) {
      return 'There are no users that match the selected criteria';
    }
    return 'There are no users at the moment';
  }, [usersTableCriteria.search]);

  const emptySalesOrdersContentText = useMemo(() => {
    const hasAppliedFilters = salesOrdersTableCriteria.search !== '';
    if (hasAppliedFilters) {
      return 'There are no sales orders that match the selected criteria';
    }
    return 'There are no sales orders at the moment';
  }, [salesOrdersTableCriteria.search]);

  const relatedSalesOrdersFilterTagsData = useMemo(
    () => ({
      salesOrders: {
        label: 'Sales order #',
        value: salesOrdersTableCriteria.salesOrders.join(', '),
      },
      minTotalSeats: {
        label: 'Min total seats',
        value: salesOrdersTableCriteria.minTotalSeats,
      },
      maxTotalSeats: {
        label: 'Max total seats',
        value: salesOrdersTableCriteria.maxTotalSeats,
      },
      minHardwareNumber: {
        label: 'Min hardware number',
        value: salesOrdersTableCriteria.minHardwareNumber,
      },
      maxHardwareNumber: {
        label: 'Max hardware number',
        value: salesOrdersTableCriteria.maxHardwareNumber,
      },
      closeDateFrom: {
        label: 'Close date from',
        value: salesOrdersTableCriteria.closeDateFrom,
      },
      closeDateTo: {
        label: 'Close date to',
        value: salesOrdersTableCriteria.closeDateTo,
      },
      endUser: {
        label: 'End user',
        value: salesOrdersTableCriteria.endUser.join(', '),
      },
    }),
    [salesOrdersTableCriteria]
  );

  const relatedUsersFilterTagsData = useMemo(
    () => ({
      email: {
        label: 'Email',
        value:
          usersTableCriteria.email.length > 0
            ? usersTableCriteria.emailFilter
                .toLowerCase()
                .replace('_', ' ')
                .concat(' ', usersTableCriteria.email)
            : '',
      },
      salesOrder: {
        label: 'Sales order #',
        value: usersTableCriteria.salesOrder.join(', '),
      },
      temporaryRoles: {
        label: 'Temporary roles',
        value:
          usersTableCriteria.temporaryRoles !== AllYesNoFilter.ALL
            ? capitalizeString(usersTableCriteria.temporaryRoles)
            : '',
      },
      permissionsGroup: {
        label: 'Role',
        value: usersTableCriteria.permissionsGroup,
      },
      status: {
        label: 'Status',
        value:
          usersTableCriteria.status !== UserStatus.ALL
            ? capitalizeString(usersTableCriteria.status)
            : '',
      },
    }),
    [usersTableCriteria]
  );

  const appliedSalesOrdersFilters = useMemo(
    () =>
      Object.values(relatedSalesOrdersFilterTagsData).filter(
        ({ value }) => value.length > 0
      ),
    [relatedSalesOrdersFilterTagsData]
  );

  const appliedUsersFilters = useMemo(
    () =>
      Object.values(relatedUsersFilterTagsData).filter(
        ({ value }) => value.length > 0
      ),
    [relatedUsersFilterTagsData]
  );

  const fetchAccountDetails = useCallback(async () => {
    try {
      setIsLoadingAccount(true);
      const accountId = params.id;
      const account = await fetchAccountById(accountId!);
      setAccount(account);
    } catch (error) {
      if (error instanceof AxiosError) {
        setError({
          status: error.response?.status || 500,
          message: error.response?.data?.message || error.message,
        });
      }
    } finally {
      setIsLoadingAccount(false);
    }
  }, [params.id, setError]);

  const fetchRelatedSalesOrders = useCallback(async () => {
    try {
      setIsLoadingSalesOrders(true);
      const salesOrders = await fetchAccountRelatedSalesOrders(
        params.id!,
        salesOrdersTableCriteria
      );
      setRelatedSalesOrders(salesOrders);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setIsLoadingSalesOrders(false);
    }
  }, [params.id, salesOrdersTableCriteria, setError]);

  const fetchRelatedUsers = useCallback(async () => {
    try {
      setIsLoadingUsers(true);
      const users = await fetchAccountRelatedUsers(
        params.id!,
        usersTableCriteria
      );
      setRelatedUsers(users);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setIsLoadingUsers(false);
    }
  }, [usersTableCriteria, params.id, setError]);

  const handleOnSalesOrdersTableChange = useCallback(
    (value: OnTableChangeData<SalesOrder>) => {
      const newData = {
        ...salesOrdersTableCriteria,
        ...value.config,
        sortBy: value.column?.key || salesOrdersTableCriteria.sortBy,
      };
      setSalesOrdersTableCriteria(newData);
    },
    [salesOrdersTableCriteria]
  );

  const handleOnUsersTableChange = useCallback(
    (value: OnTableChangeData<User, PermissionsGroupBySalesOrder>) => {
      const newData = {
        ...usersTableCriteria,
        ...value.config,
        sortBy: value.column?.key || usersTableCriteria.sortBy,
      };
      setUsersTableCriteria(newData);
    },
    [usersTableCriteria]
  );

  const handleSalesOrdersTableFilterModalSubmit = useCallback(
    (tableFilterValue: SalesOrdersTableModalFilterDataType) => {
      const newCriteria: SalesOrdersCriteria = {
        ...salesOrdersTableCriteria,
        ...tableFilterValue,
        pageNumber: 1,
      };
      setSalesOrdersTableCriteria(newCriteria);
    },
    [salesOrdersTableCriteria]
  );

  const onRemoveRelatedSalesOrdersFilterTag = useCallback(
    (filterDataKey: keyof SalesOrdersTableModalFilterDataType) => {
      const newSalesOrdersTableCriteria = { ...salesOrdersTableCriteria };
      newSalesOrdersTableCriteria[filterDataKey] =
        defaultSalesOrdersTableCriteria[filterDataKey] as string &
          string[] &
          FilterType;
      setSalesOrdersTableCriteria(newSalesOrdersTableCriteria);
    },
    [salesOrdersTableCriteria]
  );

  const handleUsersTableFilterModalSubmit = useCallback(
    (tableFilterValue: UsersTableFilterDataType) => {
      const newUsersTableCriteria = {
        ...usersTableCriteria,
        ...tableFilterValue,
        pageNumber: 1,
      };
      setUsersTableCriteria(newUsersTableCriteria);
    },
    [usersTableCriteria]
  );

  const onRemoveRelatedUsersFilterTag = useCallback(
    (filterDataKey: keyof UsersTableFilterDataType) => {
      const newUsersTableCriteria = { ...usersTableCriteria };
      newUsersTableCriteria[filterDataKey] = defaultUsersTableCriteria[
        filterDataKey
      ] as string & string[] & boolean;
      setUsersTableCriteria(newUsersTableCriteria);
    },
    [usersTableCriteria]
  );

  useEffect(() => {
    fetchAccountDetails();
  }, [fetchAccountDetails]);

  useEffect(() => {
    fetchRelatedSalesOrders();
  }, [fetchRelatedSalesOrders, salesOrdersTableCriteria]);

  useEffect(() => {
    fetchRelatedUsers();
  }, [fetchRelatedUsers, usersTableCriteria]);

  return (
    <ProtectedPage
      permissions={
        SalesOrderPermissions.SALES_ORDERS_ACCOUNT_AND_PARENT_ACCOUNT_READ
      }
    >
      <Conditional condition={isLoadingAccount || !account}>
        <Conditional.True>
          <PageSpinner />
        </Conditional.True>
        <Conditional.False>
          <BoxLayout
            className="is-min-height-80 mx-10 my-4"
            header={
              <Element className="p-4">
                <BackButton />
                <h1 className="is-size-3 has-text-weight-light">
                  {account?.name}
                </h1>
                <h2 className="is-size-5 has-text-weight-light">
                  {accountSalesOrders}
                </h2>
              </Element>
            }
          >
            <DetailsCard>
              <Element pb={6}>
                <Icon color="primary" mr={2}>
                  <FaFileInvoice size={24} />
                </Icon>
                <span className="is-size-4 has-text-weight-light">
                  Related sales orders
                </span>
              </Element>
              <Conditional condition={isLoadingSalesOrders}>
                <Conditional.True>
                  <PageSpinner />
                </Conditional.True>
                <Conditional.False>
                  <Element
                    display="flex"
                    flexDirection="column"
                    className="gap-7"
                  >
                    <FilterTagList
                      title="Filters"
                      list={relatedSalesOrdersFilterTagsData}
                      onRemove={(item) =>
                        onRemoveRelatedSalesOrdersFilterTag(
                          item as keyof SalesOrdersTableModalFilterDataType
                        )
                      }
                    />
                    <SalesOrdersTable
                      columns={salesOrderDetailsColumns}
                      dataSource={relatedSalesOrders.data}
                      config={salesOrdersTableConfig}
                      onChange={handleOnSalesOrdersTableChange}
                      empty={
                        <Element
                          display="flex"
                          justifyContent="center"
                          className="my-2 has-text-weight-light"
                        >
                          <span>{emptySalesOrdersContentText}</span>
                        </Element>
                      }
                      form={{
                        extra: (
                          <Button
                            type="button"
                            color="primary-dark"
                            className="ml-2"
                            outlined={appliedSalesOrdersFilters.length === 0}
                            onClick={() =>
                              setIsSalesOrdersFilterModalVisible(true)
                            }
                          >
                            <Icon>
                              <FaFilter />
                            </Icon>
                            <span>
                              Filter
                              {appliedSalesOrdersFilters.length > 0 &&
                                `(${appliedSalesOrdersFilters.length})`}
                            </span>
                          </Button>
                        ),
                      }}
                    />
                  </Element>
                </Conditional.False>
              </Conditional>
            </DetailsCard>
            <DetailsCard>
              <Element pb={6}>
                <Icon color="primary" mr={2}>
                  <FaUsers size={24} />
                </Icon>
                <span className="is-size-4 has-text-weight-light">
                  Related users
                </span>
              </Element>
              <Conditional condition={isLoadingUsers}>
                <Conditional.True>
                  <PageSpinner />
                </Conditional.True>
                <Conditional.False>
                  <Element
                    display="flex"
                    flexDirection="column"
                    className="gap-7"
                  >
                    <FilterTagList
                      title="Filters"
                      list={relatedUsersFilterTagsData}
                      onRemove={(item) =>
                        onRemoveRelatedUsersFilterTag(
                          item as keyof UsersTableFilterDataType
                        )
                      }
                    />
                    <ReadOnlyUsersTable
                      dataSource={relatedUsers.data}
                      config={usersTableConfig}
                      onChange={handleOnUsersTableChange}
                      userRoleType={UserRoleType.CUSTOMER}
                      empty={
                        <Element
                          display="flex"
                          justifyContent="center"
                          className="my-2"
                        >
                          <span className="has-text-weight-light">
                            {emptyUsersContentText}
                          </span>
                        </Element>
                      }
                      form={{
                        extra: (
                          <Button
                            type="button"
                            color="primary-dark"
                            className="ml-2"
                            outlined={appliedUsersFilters.length === 0}
                            onClick={() => setIsUsersFilterModalVisible(true)}
                          >
                            <Icon>
                              <FaFilter />
                            </Icon>
                            <span>
                              Filter
                              {appliedUsersFilters.length > 0 &&
                                `(${appliedUsersFilters.length})`}
                            </span>
                          </Button>
                        ),
                      }}
                    />
                  </Element>
                </Conditional.False>
              </Conditional>
            </DetailsCard>
          </BoxLayout>
          <SalesOrdersTableFilterModal
            fields={salesOrderDetailsFilterFields}
            show={isSalesOrdersFilterModalVisible}
            data={salesOrdersTableCriteria}
            onSubmit={handleSalesOrdersTableFilterModalSubmit}
            onClose={() => setIsSalesOrdersFilterModalVisible(false)}
          />
        </Conditional.False>
        <UsersTableFilterModal
          show={isUsersFilterModalVisible}
          data={usersTableCriteria}
          onSubmit={handleUsersTableFilterModalSubmit}
          onClose={() => setIsUsersFilterModalVisible(false)}
        />
      </Conditional>
    </ProtectedPage>
  );
}

export default AccountDetailsPage;
