import {
  AllYesNoFilter,
  Device,
  DeviceAssignmentsModalFilterDataType,
  DeviceGroupsCriteria,
  FilterType,
  HardwareModel,
} from '@zspace/types';

type DeviceGroupFilterCriteria =
  | DeviceGroupsCriteria
  | DeviceAssignmentsModalFilterDataType;

export function filterDevices(
  devices: Device[],
  criteria: DeviceGroupFilterCriteria
) {
  return devices.filter((device) => filterDeviceByCriteria(device, criteria));
}

function filterDeviceByCriteria(
  device: Device,
  criteria: DeviceGroupFilterCriteria
) {
  if (
    criteria.deviceType !== HardwareModel.ALL &&
    !(device.model.displayName === criteria.deviceType)
  )
    return false;

  if (criteria.softwareAssigned !== AllYesNoFilter.ALL) {
    switch (criteria.softwareAssigned) {
      case AllYesNoFilter.YES:
        if (!(device.assignments.filter((a) => a.softwareSeat).length > 0))
          return false;
        break;
      case AllYesNoFilter.NO:
        if (!(device.assignments.filter((a) => a.softwareSeat).length === 0))
          return false;
        break;
    }
  }

  if (criteria.softwareTitle && criteria.softwareTitleFilter) {
    const criteriaSoftwareTitleFilter = criteria.softwareTitle;
    switch (criteria.softwareTitleFilter) {
      case FilterType.EXACTLY:
        if (
          !device.assignments
            .filter((a) => a.softwareSeat)
            .some(
              (a) =>
                a.softwareSeat.licensingProductGroup.displayName ===
                criteriaSoftwareTitleFilter
            )
        )
          return false;

        break;
      case FilterType.CONTAINS:
        if (
          !device.assignments
            .filter((a) => a.softwareSeat)
            .some((a) =>
              a.softwareSeat.licensingProductGroup.displayName
                .toLowerCase()
                .includes(criteriaSoftwareTitleFilter.toLowerCase())
            )
        )
          return false;
        break;
      case FilterType.NOT_CONTAINS:
        if (
          device.assignments
            .filter((a) => a.softwareSeat)
            .some((a) =>
              a.softwareSeat.licensingProductGroup.displayName
                .toLowerCase()
                .includes(criteriaSoftwareTitleFilter.toLowerCase())
            )
        )
          return false;
        break;
    }
  }

  if (
    criteria.salesOrders.length > 0 &&
    !criteria.salesOrders.some((number) => {
      const criteriaSearchNumberValue = number.replace(/\D/g, '');
      return (
        device.salesOrderLine.salesOrder.number ===
        Number(criteriaSearchNumberValue)
      );
    })
  )
    return false;
  return true;
}

export function getDevicesWithSameSoftwareTitles(devices: Device[]) {
  const groupedDevices = new Map<string, Device[]>();
  devices.forEach((device) => {
    const deviceSoftwareTitles = device.assignments
      .filter((assignment) => assignment.softwareSeat)
      .map(
        (assignment) =>
          assignment.softwareSeat.licensingProductGroup.displayName
      )
      .sort()
      .join('');
    const deviceConfiguration = `${device.model.id}${deviceSoftwareTitles}`;
    const devicesWithSameConfiguration =
      groupedDevices.get(deviceConfiguration);
    if (devicesWithSameConfiguration) {
      devicesWithSameConfiguration.push(device);
    } else {
      groupedDevices.set(deviceConfiguration, [device]);
    }
  });
  return Array.from(groupedDevices.values());
}
