import {
  EditUserPermissionsTableRow,
  FilterType,
  UserPermissionsFilterDataType,
  UserSalesOrderPermissionTableRow,
} from '@zspace/types';

export function filterUserPermissions(
  userPermissions:
    | EditUserPermissionsTableRow[]
    | UserSalesOrderPermissionTableRow[],
  criteria: UserPermissionsFilterDataType
) {
  return userPermissions.filter((permission) => {
    if (criteria.opportunityName && criteria.opportunityNameFilter) {
      const criteriaOpportunityFilter = criteria.opportunityName;
      switch (criteria.opportunityNameFilter) {
        case FilterType.EXACTLY:
          if (!(permission.opportunity?.name === criteriaOpportunityFilter))
            return false;
          break;
        case FilterType.CONTAINS:
          if (
            !permission.opportunity?.name
              .toLowerCase()
              .includes(criteriaOpportunityFilter.toLowerCase())
          )
            return false;
          break;
        case FilterType.NOT_CONTAINS:
          if (
            permission.opportunity?.name
              .toLowerCase()
              .includes(criteriaOpportunityFilter.toLowerCase())
          )
            return false;
          break;
      }
    }

    if (
      criteria.salesOrder.length > 0 &&
      !criteria.salesOrder.some((number) => {
        const criteriaSearchNumberValue = number.replace(/\D/g, '');
        return permission.number === Number(criteriaSearchNumberValue);
      })
    ) {
      return false;
    }

    if (criteria.closeDateFrom || criteria.closeDateTo) {
      if (!permission.closeDate) {
        return false;
      }
      const closeDate = new Date(permission.closeDate);
      const dateFrom = new Date(criteria.closeDateFrom);
      const dateTo = new Date(criteria.closeDateTo);
      if (criteria.closeDateFrom && criteria.closeDateTo) {
        if (!(dateFrom <= closeDate && dateTo >= closeDate)) return false;
      } else {
        if (criteria.closeDateFrom) {
          if (!(dateFrom <= closeDate)) return false;
        }

        if (criteria.closeDateTo) {
          if (!(dateTo >= closeDate)) return false;
        }
      }
    }

    return true;
  });
}
