import { PermissionsGroupName } from '@zspace/roles';
import {
  NewCustomerUser,
  SalesOrder,
  UserSalesOrderPermission,
  UserSalesOrderPermissionTableRow,
} from '@zspace/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Section } from 'react-bulma-components';
import { FaCheck } from 'react-icons/fa6';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import CancelAlertModal from '../../shared/cancel-alert-modal/cancel-alert-modal';
import useHttpRequest from '../../shared/hooks/http-request';
import useIsZspaceInternalUser from '../../shared/hooks/is-zspace-internal-user';
import { default as useUserSalesOrders } from '../../shared/hooks/user-sales-orders';
import useZspaceInternalUserSalesOrders from '../../shared/hooks/zspace-internal-sales-orders';
import Button from '../../ui/button/button';
import FeedbackMessage from '../../ui/feedback-message/feedback-message';
import {
  InviteUserPageContext,
  InviteUserPageSteps,
} from '../invite-user-page/types';
import UserInvitedConfirmationModal from '../user-invited-confirmation-modal/user-invited-confirmation-modal';
import UserPermissionsTable from '../user-permissions-table/user-permissions-table';
import { inviteTeamUser, inviteUser } from '../users-service';

const CANCEL_ALERT_MODAL_TITLE = 'Cancel user invite';
const CANCEL_ALERT_MODAL_SUBTITLE =
  'Are you sure you want to cancel the user invite process?';
const CANCEL_BUTTON_TEXT = 'Cancel';
const INVITE_BUTTON_TEXT = 'Invite user';
const NO_ASSIGNED_PERMISSION_TO_ANY_SALES_ORDER_ERROR =
  'Please assign a permission to at least one sales order before proceeding.';
const USER_INVITE_ERROR_MESSAGE =
  'The user could not be invited. Please try again';
const INVALID_TEMPORARY_PERMISSION_DAYS_DURATION_ERROR_MESSAGE = `Temporary permissions cannot be granted more than ${Number(
  environment.temporaryUserPermissionMaxDaysDuration
)} days`;

export function InviteUserPermissionsPage() {
  const navigate = useNavigate();
  const {
    email,
    setEmail,
    setStep,
    setTitle,
    setFilterTagList,
    navigateToPermissionsHome,
    internalViewMode,
  } = useOutletContext<InviteUserPageContext>();
  const isZspaceInternalUser = useIsZspaceInternalUser();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showCancelAlertModal, setShowCancelAlertModal] =
    useState<boolean>(false);
  const [selectedPermissions, setSelectedPermissions] = useState<
    UserSalesOrderPermission[]
  >([]);

  const [inviteUserButtonPressed, setInviteUserButtonPressed] =
    useState<boolean>(false);
  const { userSalesOrders: activeUserRoleSalesOrders } = useUserSalesOrders();
  const { userSalesOrders: zspaceInternalUserSalesOrders } =
    useZspaceInternalUserSalesOrders();
  const { executeHttpRequest, isLoading } = useHttpRequest();

  useEffect(() => {
    setStep(InviteUserPageSteps.PERMISSIONS);
    setTitle(`Permissions for ${email}`);
  }, [email, setStep, setTitle]);

  const salesOrders: SalesOrder[] = useMemo(() => {
    if (isZspaceInternalUser) {
      return zspaceInternalUserSalesOrders;
    } else {
      return activeUserRoleSalesOrders;
    }
  }, [
    activeUserRoleSalesOrders,
    isZspaceInternalUser,
    zspaceInternalUserSalesOrders,
  ]);

  const userPermissions: UserSalesOrderPermissionTableRow[] = useMemo(() => {
    return salesOrders.map((so) => {
      return {
        id: so.id,
        number: so.number,
        opportunity: so.opportunity,
        closeDate: so.closeDate,
        permissionsGroup: {
          name: '',
        },
      };
    });
  }, [salesOrders]);

  const showNoAssignedPermissionToAnySalesOrderError = useMemo(
    () =>
      selectedPermissions.filter((p) => p.permissionsGroup?.name !== '')
        .length === 0 && inviteUserButtonPressed,
    [selectedPermissions, inviteUserButtonPressed]
  );

  const showInvalidTemporaryPermissionDaysDurationError = useMemo(() => {
    return (
      selectedPermissions.filter(
        (p) =>
          p.permissionsGroup?.name ===
            PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER &&
          p.permissionsGroup.daysDuration &&
          p.permissionsGroup.daysDuration >
            Number(environment.temporaryUserPermissionMaxDaysDuration)
      ).length > 0 && inviteUserButtonPressed
    );
  }, [selectedPermissions, inviteUserButtonPressed]);

  const errorMessage = useMemo(() => {
    if (showNoAssignedPermissionToAnySalesOrderError) {
      return NO_ASSIGNED_PERMISSION_TO_ANY_SALES_ORDER_ERROR;
    }
    if (showInvalidTemporaryPermissionDaysDurationError) {
      return INVALID_TEMPORARY_PERMISSION_DAYS_DURATION_ERROR_MESSAGE;
    }
  }, [
    showInvalidTemporaryPermissionDaysDurationError,
    showNoAssignedPermissionToAnySalesOrderError,
  ]);

  const handleInviteUser = useCallback(
    () =>
      executeHttpRequest({
        asyncFunction: async () => {
          const validSelectedPermissions = selectedPermissions
            .filter((p) => p.permissionsGroup?.name !== '')
            .filter(
              (p) =>
                p.permissionsGroup?.name !==
                  PermissionsGroupName.TEMPORARY_SALES_ORDER_MANAGER ||
                (p.permissionsGroup.daysDuration &&
                  p.permissionsGroup.daysDuration <=
                    Number(environment.temporaryUserPermissionMaxDaysDuration))
            );

          if (validSelectedPermissions.length === 0) {
            return setInviteUserButtonPressed(true);
          }

          const newUser: NewCustomerUser = {
            email,
            permissions: validSelectedPermissions,
          };

          if (internalViewMode) {
            await inviteUser(newUser);
          } else {
            await inviteTeamUser(newUser);
          }
          setShowConfirmationModal(true);
        },
        customErrorMessage: USER_INVITE_ERROR_MESSAGE,
      }),
    [email, executeHttpRequest, internalViewMode, selectedPermissions]
  );

  const handleInviteAnotherUser = useCallback(() => {
    setEmail('');
    setInviteUserButtonPressed(false);
    navigate('/users/my-team/invite/details');
  }, [navigate, setEmail]);

  return (
    <>
      <UserPermissionsTable
        dataSource={userPermissions}
        setFilters={setFilterTagList}
        selectedPermissions={selectedPermissions}
        setSelectedPermissions={setSelectedPermissions}
        errorMessage={
          <FeedbackMessage className="mb-2">{errorMessage}</FeedbackMessage>
        }
      />
      <Section
        paddingless
        display="flex"
        justifyContent={'flex-end'}
        alignItems={'flex-end'}
        className="gap-6 is-flex-grow-1 pt-0 pl-4"
      >
        <Button
          color="primary-dark"
          outlined
          onClick={() => setShowCancelAlertModal(true)}
        >
          {CANCEL_BUTTON_TEXT}
        </Button>
        <Button
          color="primary-dark"
          onClick={handleInviteUser}
          isExecutingAction={isLoading}
        >
          <Button.LoadingIcon icon={FaCheck} />
          <span>{INVITE_BUTTON_TEXT}</span>
        </Button>
      </Section>

      <UserInvitedConfirmationModal
        show={showConfirmationModal}
        email={email}
        onInviteAnotherUser={handleInviteAnotherUser}
        navigateToPermissionsHome={navigateToPermissionsHome}
      />
      <CancelAlertModal
        show={showCancelAlertModal}
        title={CANCEL_ALERT_MODAL_TITLE}
        subtitle={CANCEL_ALERT_MODAL_SUBTITLE}
        onCancel={navigateToPermissionsHome}
        onClose={() => setShowCancelAlertModal(false)}
      />
    </>
  );
}

export default InviteUserPermissionsPage;
