import { SoftwareAssignmentReviewData } from '@zspace/types';
import { Columns, Element } from 'react-bulma-components';
import ReviewSoftwareAssignmentCard from '../review-software-assignment-card/review-software-assignment-card';

export type NewLicenseKeysProps = {
  newAssignments: SoftwareAssignmentReviewData['newAssignments'];
};

export function NewLicenseKeys({ newAssignments }: NewLicenseKeysProps) {
  return (
    <Element display="flex" flexDirection="column" className="gap-6">
      <h2 className="is-size-5 has-text-weight-light">New license keys</h2>
      <Columns>
        {newAssignments.map((assignment) => (
          <Columns.Column size={4} key={assignment.id}>
            <ReviewSoftwareAssignmentCard assignment={assignment} />
          </Columns.Column>
        ))}
      </Columns>
    </Element>
  );
}

export default NewLicenseKeys;
