import {
  Criteria,
  DeferredResponse,
  PaginatedAPIResponse,
  SalesOrder,
  SoftwareTableRow,
  SoftwareTitleForAssignment,
  SortDirection,
} from '@zspace/types';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaArrowRight, FaXmark } from 'react-icons/fa6';
import { PiWarningFill } from 'react-icons/pi';
import {
  useAsyncValue,
  useLoaderData,
  useNavigation,
  useOutletContext,
} from 'react-router-dom';
import CancelAlertModal from '../../shared/cancel-alert-modal/cancel-alert-modal';
import Conditional from '../../shared/conditional/conditional';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import If from '../../shared/if/if';
import { ManageSoftwareLayoutContext } from '../../software-assignment/manage-software-layout/manage-software-layout';
import SoftwareAssignmentInboxTable from '../../software-assignment/software-assignment-inbox-table/software-assignment-inbox-table';
import Button from '../../ui/button/button';
import FeedbackMessage from '../../ui/feedback-message/feedback-message';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import TagList from '../../ui/tag-list/tag-list';

const NO_SOFTWARE_SELECTED_ERROR_MESSAGE =
  'Please select at least one software title';
const ALREADY_ASSIGNED_SOFTWARE_INFO_MESSAGE_SINGULAR =
  'You are assigning {SOFTWARE}, which is already present in the selected devices. This will replace the current license and update the expiration date. You’ll be able to review this in the next step.';
const ALREADY_ASSIGNED_SOFTWARE_INFO_MESSAGE_PLURAL =
  'You are assigning {SOFTWARE}, which are already present in the selected devices. This will replace the current licenses and update the expiration dates. You’ll be able to review this in the next step.';
const CANCEL_ALERT_MODAL_TITLE = 'Cancel software assignment';
const CANCEL_ALERT_MODAL_SUBTITLE =
  'Are you sure you want to cancel the software assignment process?\nYou will lose all your progress';
const FETCH_AVAILABLE_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE =
  'Available software sales orders could not be fetched. Please try again';

export type DeviceGroupSoftwareSelectionCardProps = {
  fetchSalesOrders: (
    criteria: Criteria
  ) => Promise<PaginatedAPIResponse<SalesOrder>>;
  fetchSoftwareBySalesOrder: (
    salesOrderId: string,
    criteria: Criteria
  ) => Promise<PaginatedAPIResponse<SoftwareTableRow>>;
  onNext: () => void;
  onCancel: () => void;
};

function DeviceGroupSoftwareSelectionCardContent({
  onNext,
  onCancel,
  fetchSalesOrders,
  fetchSoftwareBySalesOrder,
}: DeviceGroupSoftwareSelectionCardProps) {
  const navigation = useNavigation();

  const {
    selectedDevices,
    selectedSoftware,
    setSelectedSoftware,
    isInvalidSelection,
    setHeaderRightContent,
  } = useOutletContext<ManageSoftwareLayoutContext>();

  const availableSoftwareSalesOrdersInitialResponse =
    useAsyncValue() as PaginatedAPIResponse<SalesOrder>;

  const [softwareCriteria, setSoftwareCriteria] = useState<Criteria>({
    itemsPerPage: 10,
    pageNumber: 1,
    search: '',
    sortBy: '',
    sortDirection: SortDirection.ASC,
  });
  const [nextButtonPressed, setNextButtonPressed] = useState(false);
  const [isCancelAlertModalVisible, setIsCancelAlertModalVisible] =
    useState(false);
  const [salesOrders, setSalesOrders] = useState<SalesOrder[]>(
    availableSoftwareSalesOrdersInitialResponse.data
  );
  const [
    availableSoftwareSalesOrdersResponse,
    setAvailableSoftwareSalesOrdersResponse,
  ] = useState<PaginatedAPIResponse<SalesOrder>>(
    availableSoftwareSalesOrdersInitialResponse
  );
  const {
    executeHttpRequest: executeFetchMoreAvailableSoftwareSalesOrderRequest,
  } = useHttpRequest();
  const {
    executeHttpRequest: executeFetchAvailableSoftwareSalesOrderRequest,
    isLoading: isLoadingFetchAvailableSoftwareSalesOrder,
  } = useHttpRequest();

  const notEnoughSeatsErrorMessage = useMemo(() => {
    const totalDevices = selectedDevices.length;
    const notEnoughSeatsSoftwareTitles = selectedSoftware
      .filter((sw) => sw.seats.available < totalDevices)
      .map((sw) => sw.licensingProductGroup.displayName);
    if (notEnoughSeatsSoftwareTitles.length > 0) {
      return `Not enough seats available for ${notEnoughSeatsSoftwareTitles.join(
        ','
      )}. Please go back and select fewer devices or review the current software selection to be able to move forward `;
    }
  }, [selectedDevices.length, selectedSoftware]);

  const repeatedSoftwareTitleErrorMessage = useMemo(() => {
    const repeatedSoftwareTitles = selectedSoftware.filter((software) =>
      selectedSoftware.some(
        (searchSoftware) =>
          software.licensingProductGroup.displayName ===
            searchSoftware.licensingProductGroup.displayName &&
          (software.salesOrder.id !== searchSoftware.salesOrder.id ||
            software.itemFulfillment.id !== searchSoftware.itemFulfillment.id)
      )
    );
    if (repeatedSoftwareTitles.length > 0) {
      return `You can't select the same software title twice even if they belong to different sales orders`;
    }
  }, [selectedSoftware]);

  const showNoSoftwareSelectedError = useMemo(
    () => selectedSoftware.length === 0 && nextButtonPressed,
    [selectedSoftware, nextButtonPressed]
  );

  const showNotEnoughSeatsError = useMemo(
    () => notEnoughSeatsErrorMessage && nextButtonPressed,
    [notEnoughSeatsErrorMessage, nextButtonPressed]
  );

  const showRepeatedSoftwareTitleError = useMemo(
    () => repeatedSoftwareTitleErrorMessage && nextButtonPressed,
    [repeatedSoftwareTitleErrorMessage, nextButtonPressed]
  );

  const alreadyAssignedSoftware = useMemo(() => {
    const selectedDevicesAlreadyAssignedSoftware = new Set(
      selectedDevices.flatMap((device) =>
        device.assignments.map(
          (assignment) => assignment.softwareSeat.licensingProductGroup.id
        )
      )
    );
    const selectedSoftwareAlreadyAssigned = selectedSoftware.filter(
      (software) =>
        selectedDevicesAlreadyAssignedSoftware.has(
          software.licensingProductGroup.id
        )
    );
    return selectedSoftwareAlreadyAssigned;
  }, [selectedDevices, selectedSoftware]);

  const showAlreadyAssignedSoftwareInfoMessage = useMemo(
    () => alreadyAssignedSoftware.length > 0,
    [alreadyAssignedSoftware]
  );

  const errorText = useMemo(() => {
    if (showNoSoftwareSelectedError) {
      return NO_SOFTWARE_SELECTED_ERROR_MESSAGE;
    }
    if (showNotEnoughSeatsError) {
      return notEnoughSeatsErrorMessage;
    }
    if (showRepeatedSoftwareTitleError) {
      return repeatedSoftwareTitleErrorMessage;
    }
  }, [
    notEnoughSeatsErrorMessage,
    repeatedSoftwareTitleErrorMessage,
    showNoSoftwareSelectedError,
    showNotEnoughSeatsError,
    showRepeatedSoftwareTitleError,
  ]);

  const isDataLoading = useMemo(
    () => navigation.state === 'loading',
    [navigation.state]
  );

  const alreadyAssignedSoftwareInfoMessage = useMemo(() => {
    const names = alreadyAssignedSoftware.map(
      (sw) => sw.licensingProductGroup.displayName
    );
    let softwareNames = '';
    let messageTemplate = ALREADY_ASSIGNED_SOFTWARE_INFO_MESSAGE_SINGULAR;

    if (names.length === 1) {
      softwareNames = names[0];
    } else if (names.length === 2) {
      softwareNames = names.join(' and ');
      messageTemplate = ALREADY_ASSIGNED_SOFTWARE_INFO_MESSAGE_PLURAL;
    } else if (names.length > 2) {
      softwareNames = `${names.slice(0, -1).join(', ')}, and ${
        names[names.length - 1]
      }`;
      messageTemplate = ALREADY_ASSIGNED_SOFTWARE_INFO_MESSAGE_PLURAL;
    }

    return messageTemplate.replace('{SOFTWARE}', softwareNames);
  }, [alreadyAssignedSoftware]);

  const handleSelectedSoftwareRemoval = useCallback(
    (swToRemove: SoftwareTitleForAssignment) =>
      setSelectedSoftware(
        selectedSoftware.filter((selectedSw) => selectedSw.id !== swToRemove.id)
      ),
    [selectedSoftware, setSelectedSoftware]
  );

  const handleOnNext = useCallback(() => {
    const isValidSelection =
      selectedSoftware.length > 0 &&
      !notEnoughSeatsErrorMessage &&
      !repeatedSoftwareTitleErrorMessage;
    if (isValidSelection) {
      onNext();
    } else {
      setNextButtonPressed(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [
    notEnoughSeatsErrorMessage,
    onNext,
    repeatedSoftwareTitleErrorMessage,
    selectedSoftware.length,
  ]);

  const fetchMoreAvailableSoftwareSalesOrders = useCallback(
    () =>
      executeFetchMoreAvailableSoftwareSalesOrderRequest({
        asyncFunction: async () => {
          const updatedCriteria = {
            ...softwareCriteria,
            pageNumber: softwareCriteria.pageNumber + 1,
          };
          const response = await fetchSalesOrders(updatedCriteria);
          setAvailableSoftwareSalesOrdersResponse(response);
          setSoftwareCriteria(updatedCriteria);
          setSalesOrders((prevSalesOrders) =>
            prevSalesOrders.concat(response.data)
          );
        },
        customErrorMessage: FETCH_AVAILABLE_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE,
      }),
    [
      executeFetchMoreAvailableSoftwareSalesOrderRequest,
      fetchSalesOrders,
      softwareCriteria,
    ]
  );

  const onTableFilter = useCallback(
    (criteria: Criteria) => {
      executeFetchAvailableSoftwareSalesOrderRequest({
        asyncFunction: async () => {
          const updatedCriteria = {
            ...softwareCriteria,
            ...criteria,
          };
          const response = await fetchSalesOrders(updatedCriteria);
          setAvailableSoftwareSalesOrdersResponse(response);
          setSoftwareCriteria(updatedCriteria);
          setSalesOrders(response.data);
        },
        customErrorMessage: FETCH_AVAILABLE_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE,
      });
    },
    [
      executeFetchAvailableSoftwareSalesOrderRequest,
      fetchSalesOrders,
      softwareCriteria,
    ]
  );

  const headerRightContent = useMemo(
    () => (
      <Element display="flex" className="gap-6">
        <Button
          color="primary-dark"
          outlined
          className="outlined-button-white-background"
          onClick={() => setIsCancelAlertModalVisible(true)}
        >
          <Icon>
            <FaXmark />
          </Icon>
          <span>Cancel</span>
        </Button>
        <Button color="primary-dark" onClick={handleOnNext}>
          <span>Next</span>
          <Icon>
            <FaArrowRight />
          </Icon>
        </Button>
      </Element>
    ),
    [handleOnNext]
  );

  useEffect(() => {
    setHeaderRightContent(headerRightContent);
  }, [headerRightContent, setHeaderRightContent]);

  return (
    <>
      <h3 className="is-size-6 my-8 has-text-weight-normal">
        {`Please select which software titles you want to assign to ${
          selectedDevices.length > 1 ? `these devices` : `this device`
        }`}
      </h3>

      <TagList
        key="number"
        list={selectedSoftware}
        renderItem={(item) => {
          if (isInvalidSelection([item])) {
            return (
              <>
                <span>{item.licensingProductGroup.displayName}</span>
                <Element ml={2} display="flex">
                  <PiWarningFill />
                </Element>
              </>
            );
          } else {
            return item.licensingProductGroup.displayName;
          }
        }}
        onRemove={handleSelectedSoftwareRemoval}
        title={<span className="mx-2">Selected</span>}
        itemColor={(item) => {
          const notEnoughSeats = item.seats.available < selectedDevices.length;
          const repeatedSoftwareTitle = selectedSoftware.some(
            (software) =>
              software.licensingProductGroup.displayName ===
                item.licensingProductGroup.displayName &&
              (software.salesOrder.id !== item.salesOrder.id ||
                software.itemFulfillment.id !== item.itemFulfillment.id)
          );
          if (notEnoughSeats || repeatedSoftwareTitle) {
            return 'danger-dark';
          } else {
            return 'primary';
          }
        }}
        colorVariant={'dark'}
      />

      <Conditional condition={isDataLoading}>
        <Conditional.True>
          <Element display="flex" justifyContent="center">
            <PageSpinner />
          </Element>
        </Conditional.True>
        <Conditional.False>
          <If
            condition={
              showNoSoftwareSelectedError ||
              !!showNotEnoughSeatsError ||
              !!showRepeatedSoftwareTitleError
            }
          >
            <Element mb={3}>
              <FeedbackMessage>{errorText}</FeedbackMessage>
            </Element>
          </If>
          <If condition={showAlreadyAssignedSoftwareInfoMessage}>
            <Element mb={3}>
              <FeedbackMessage type="info">
                {alreadyAssignedSoftwareInfoMessage}
              </FeedbackMessage>
            </Element>
          </If>
          <SoftwareAssignmentInboxTable
            salesOrders={salesOrders}
            fetchMoreSalesOrders={fetchMoreAvailableSoftwareSalesOrders}
            hasMoreSalesOrders={!!availableSoftwareSalesOrdersResponse.hasMore}
            fetchSoftwareBySalesOrderRequest={fetchSoftwareBySalesOrder}
            loadingInitialData={
              isDataLoading || isLoadingFetchAvailableSoftwareSalesOrder
            }
            onFilter={onTableFilter}
          />
        </Conditional.False>
      </Conditional>

      <CancelAlertModal
        show={isCancelAlertModalVisible}
        title={CANCEL_ALERT_MODAL_TITLE}
        subtitle={CANCEL_ALERT_MODAL_SUBTITLE}
        onCancel={onCancel}
        onClose={() => setIsCancelAlertModalVisible(false)}
      />
    </>
  );
}

export function DeviceGroupSoftwareSelectionCard(
  props: DeviceGroupSoftwareSelectionCardProps
) {
  const { response } = useLoaderData() as DeferredResponse<
    PaginatedAPIResponse<SoftwareTableRow>
  >;

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <DeviceGroupSoftwareSelectionCardContent {...props} />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default DeviceGroupSoftwareSelectionCard;
