import { formatSalesOrderNumber } from '@zspace/format';
import {
  AllYesNoFilter,
  FilterTableModalFieldAlignment,
  HardwareModel,
  InputType,
} from '@zspace/types';

export const deviceGroupsFilterModalFields = (
  deviceTypeOptions: { label: string; value: string }[]
) => [
  {
    input: {
      type: InputType.SELECT,
      valueKey: 'deviceType',
      options: [
        {
          label: 'All',
          value: HardwareModel.ALL,
        },
        ...deviceTypeOptions,
      ],
    },
    name: 'hardware',
    label: 'Device type',
    alignment: FilterTableModalFieldAlignment.LEFT,
  },
  {
    input: {
      type: InputType.SELECT,
      valueKey: 'softwareAssigned',
      options: [
        {
          label: 'All',
          value: AllYesNoFilter.ALL,
        },
        {
          label: 'Yes',
          value: AllYesNoFilter.YES,
        },
        {
          label: 'No',
          value: AllYesNoFilter.NO,
        },
      ],
    },
    name: 'hasSoftwareAssigned',
    label: 'Has software assigned',
    alignment: FilterTableModalFieldAlignment.LEFT,
  },
  {
    input: {
      type: InputType.FILTER_CRITERIA,
      valueKey: 'softwareTitle',
      filterValueKey: 'softwareTitleFilter',
    },
    name: 'softwareTitle',
    label: 'Assigned software title',
    alignment: FilterTableModalFieldAlignment.LEFT,
  },
  {
    input: {
      type: InputType.FILTER_CRITERIA,
      valueKey: 'deviceName',
      filterValueKey: 'deviceNameFilter',
    },
    name: 'name',
    label: 'Device name',
    alignment: FilterTableModalFieldAlignment.RIGHT,
  },
  {
    input: {
      type: InputType.FILTER_CRITERIA,
      valueKey: 'serialNumber',
      filterValueKey: 'serialNumberFilter',
    },
    name: 'serialNumber',
    label: 'Serial Number',
    alignment: FilterTableModalFieldAlignment.RIGHT,
  },
  {
    input: {
      type: InputType.TAG_INPUT,
      valueKey: 'salesOrders',
      placeholder: formatSalesOrderNumber('12345'),
    },
    name: 'salesOrders',
    label: 'Sales order #',
    alignment: FilterTableModalFieldAlignment.RIGHT,
  },
];

export const deviceAssignmentsFilterModalFields = (
  deviceTypeOptions: { label: string; value: string }[]
) => [
  {
    input: {
      type: InputType.SELECT,
      valueKey: 'deviceType',
      options: [
        {
          label: 'All',
          value: HardwareModel.ALL,
        },
        ...deviceTypeOptions,
      ],
    },
    name: 'hardware',
    label: 'Device type',
    alignment: FilterTableModalFieldAlignment.LEFT,
  },
  {
    input: {
      type: InputType.SELECT,
      valueKey: 'softwareAssigned',
      options: [
        {
          label: 'All',
          value: AllYesNoFilter.ALL,
        },
        {
          label: 'Yes',
          value: AllYesNoFilter.YES,
        },
        {
          label: 'No',
          value: AllYesNoFilter.NO,
        },
      ],
    },
    name: 'hasSoftwareAssigned',
    label: 'Has software assigned',
    alignment: FilterTableModalFieldAlignment.LEFT,
  },
  {
    input: {
      type: InputType.FILTER_CRITERIA,
      valueKey: 'softwareTitle',
      filterValueKey: 'softwareTitleFilter',
    },
    name: 'softwareTitle',
    label: 'Assigned software title',
    alignment: FilterTableModalFieldAlignment.RIGHT,
  },
  {
    input: {
      type: InputType.TAG_INPUT,
      valueKey: 'salesOrders',
      placeholder: formatSalesOrderNumber('12345'),
    },
    name: 'salesOrders',
    label: 'Sales order #',
    alignment: FilterTableModalFieldAlignment.RIGHT,
  },
];
