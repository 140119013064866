import { removeTimeFromStringDate } from '@zspace/dates';
import { capitalizeString } from '@zspace/format';
import { RegistrationFilePermissions } from '@zspace/roles';
import {
  FilterType,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  RegistrationFilesCriteria,
  RegistrationFilesModalFilterDataType,
  RegistrationFilesTableRow,
  RegistrationFileStatus,
  SortDirection,
} from '@zspace/types';
import FileSaver from 'file-saver';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Columns, Element, Icon } from 'react-bulma-components';
import { FaCircleInfo, FaFilter, FaPlus } from 'react-icons/fa6';
import {
  defer,
  Link,
  LoaderFunction,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useNavigation,
  useSearchParams,
} from 'react-router-dom';
import BackButton from '../../shared/back-button/back-button';
import CheckPermissions from '../../shared/check-permissions/check-permissions';
import Conditional from '../../shared/conditional/conditional';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import useToast from '../../shared/hooks/toasts';
import If from '../../shared/if/if';
import ProtectedPage from '../../shared/protected-page/protected-page';
import { createSearchParams } from '../../shared/url';
import BoxLayout from '../../ui/box-layout/box-layout';
import Button from '../../ui/button/button';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { OnTableChangeData } from '../../ui/table/types';
import CreateRegistrationFileModal from '../create-registration-file-modal/create-registration-file-modal';
import RegistrationFilesFilterModal from '../filter-modals/registration-files-filter-modal/registration-files-filter-modal';
import {
  createRegistrationFile,
  downloadRegistrationFile,
  fetchRegistrationFiles,
  sendRegistrationFile,
} from '../registration-files-service';
import RegistrationFilesTable from '../registration-files-table/registration-files-table';
import SendRegistrationFileModal from '../send-registration-file-modal/send-registration-file-modal';

const CREATE_REGISTRATION_FILE_ERROR_MESSAGE =
  'The registration file could not be created. Please try again';
const DOWNLOAD_REGISTRATION_FILE_ERROR_MESSAGE =
  'The registration file could not be downloaded. Please try again';
const SEND_REGISTRATION_FILE_ERROR_MESSAGE =
  'The registration file could not be sent. Please try again';
const SEND_REGISTRATION_FILE_SUCCESS_MESSAGE =
  'Registration file sent successfully';

const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 50];

const initialCriteria: RegistrationFilesCriteria = {
  itemsPerPage: 50,
  pageNumber: 1,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
  name: '',
  nameFilter: FilterType.CONTAINS,
  status: RegistrationFileStatus.ALL,
  creationDateFrom: '',
  creationDateTo: '',
  expirationDateFrom: '',
  expirationDateTo: '',
};

export const loader: LoaderFunction = async ({ request }) => {
  const searchParams = createSearchParams(request);

  const response = fetchRegistrationFiles({
    itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '50'),
    pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
    search: searchParams.get('search') ?? '',
    sortBy: searchParams.get('sortBy') ?? 'name',
    sortDirection:
      (searchParams.get('sortDirection') as SortDirection) ?? SortDirection.DES,
    name: searchParams.get('name') ?? '',
    nameFilter:
      (searchParams.get('nameFilter') as FilterType) ?? FilterType.CONTAINS,
    status:
      (searchParams.get('status') as RegistrationFileStatus) ??
      RegistrationFileStatus.ALL,
    creationDateFrom: searchParams.get('creationDateFrom') ?? '',
    creationDateTo: searchParams.get('creationDateTo') ?? '',
    expirationDateFrom: searchParams.get('expirationDateFrom') ?? '',
    expirationDateTo: searchParams.get('expirationDateTo') ?? '',
  });

  return defer({ response });
};

export function RegistrationFilesPageContent() {
  const response =
    useAsyncValue() as PaginatedAPIResponse<RegistrationFilesTableRow>;
  const navigation = useNavigation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const [filterCriteria, setFilterCriteria] =
    useState<RegistrationFilesCriteria>({
      itemsPerPage: parseInt(searchParams.get('itemsPerPage') ?? '50'),
      pageNumber: parseInt(searchParams.get('pageNumber') ?? '1'),
      search: searchParams.get('search') ?? '',
      sortBy: searchParams.get('sortBy') ?? 'name',
      sortDirection: SortDirection.DES,
      name: searchParams.get('name') ?? '',
      nameFilter:
        (searchParams.get('nameFilter') as FilterType) ?? FilterType.CONTAINS,
      status:
        (searchParams.get('status') as RegistrationFileStatus) ??
        RegistrationFileStatus.ALL,
      creationDateFrom: searchParams.get('creationDateFrom') ?? '',
      creationDateTo: searchParams.get('creationDateTo') ?? '',
      expirationDateFrom: searchParams.get('expirationDateFrom') ?? '',
      expirationDateTo: searchParams.get('expirationDateTo') ?? '',
    });
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [showCreateRegistrationFileModal, setShowCreateRegistrationFileModal] =
    useState<boolean>(false);
  const {
    executeHttpRequest: executeFetchRegistrationFileData,
    isLoading: isLoadingFetchRegistrationFileData,
  } = useHttpRequest();
  const {
    executeHttpRequest: executeSendregistrationFile,
    isLoading: isLoadingSendRegistrationFile,
  } = useHttpRequest();
  const {
    executeHttpRequest: executeCreateNewRegistrationFile,
    isLoading: isLoadingCreateNewRegistrationFile,
  } = useHttpRequest();
  const {
    executeHttpRequest: executeDownloadRegistrationFile,
    isLoading: isLoadingDownloadRegistrationFile,
  } = useHttpRequest();

  const [showSendRegistrationFileModal, setShowSendRegistrationFileModal] =
    useState<boolean>(false);
  const [selectedRegistrationFileId, setSelectedRegistrationFileId] =
    useState<string>('');

  const [tableData, setTableData] = useState<RegistrationFilesTableRow[]>(
    response.data
  );
  const [tableConfig, setTableConfig] = useState<PaginatedContentConfig>({
    ...filterCriteria,
    pages: response.pages,
  });

  const filterTagsData = useMemo(() => {
    return {
      name: {
        label: 'Registration file',
        value:
          filterCriteria.name.length > 0
            ? filterCriteria.nameFilter
                .toLowerCase()
                .replace('_', ' ')
                .concat(' ', filterCriteria.name)
            : '',
      },
      expirationDateFrom: {
        label: 'Expiration date from',
        value: removeTimeFromStringDate(filterCriteria.expirationDateFrom),
      },
      expirationDateTo: {
        label: 'Expiration date to',
        value: removeTimeFromStringDate(filterCriteria.expirationDateTo),
      },
      creationDateFrom: {
        label: 'Creation date from',
        value: removeTimeFromStringDate(filterCriteria.creationDateFrom),
      },
      creationDateTo: {
        label: 'Creation date to',
        value: removeTimeFromStringDate(filterCriteria.creationDateTo),
      },
      status: {
        label: 'Registration status',
        value:
          filterCriteria.status !== RegistrationFileStatus.ALL
            ? capitalizeString(filterCriteria.status)
            : '',
      },
    };
  }, [filterCriteria]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const emptyContent = useMemo(() => {
    const emptyContentText =
      appliedFilters.length > 0 || tableConfig.search !== ''
        ? 'There are no registration files that match the selected criteria'
        : 'No registration files have been created yet';
    return (
      <Element
        display="flex"
        justifyContent="center"
        className="my-2 has-text-weight-light"
      >
        <span>{emptyContentText}</span>
      </Element>
    );
  }, [appliedFilters.length, tableConfig.search]);

  const isDataLoading = useMemo(
    () => navigation.state === 'loading' || isLoadingFetchRegistrationFileData,
    [isLoadingFetchRegistrationFileData, navigation.state]
  );

  const fetchRegistrationFilesData = useCallback(
    (criteria: RegistrationFilesCriteria) => {
      executeFetchRegistrationFileData({
        asyncFunction: async () => {
          const response = await fetchRegistrationFiles(criteria);
          setTableData(response.data);
          setTableConfig({ ...criteria, pages: response.pages });
        },
      });
    },
    [executeFetchRegistrationFileData]
  );

  const handleOnTableChange = useCallback(
    (value: OnTableChangeData<RegistrationFilesTableRow>): void => {
      const newData = {
        ...filterCriteria,
        ...value.config,
        sortBy: value.column?.key || filterCriteria.sortBy,
      };

      setFilterCriteria(newData);
      fetchRegistrationFilesData(newData);
    },
    [fetchRegistrationFilesData, filterCriteria]
  );

  const handleTableFilterSubmit = useCallback(
    async (value: RegistrationFilesModalFilterDataType) => {
      const newCriteria: RegistrationFilesCriteria = {
        ...filterCriteria,
        ...value,
        ...{ pageNumber: 1 },
      };
      setFilterCriteria(newCriteria);
      fetchRegistrationFilesData(newCriteria);
      setShowFilterModal(false);
    },
    [fetchRegistrationFilesData, filterCriteria]
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof RegistrationFilesModalFilterDataType) => {
      const newCriteria: RegistrationFilesCriteria = { ...filterCriteria };

      newCriteria[filterDataKey] = initialCriteria[filterDataKey] as string &
        number &
        string[];

      handleTableFilterSubmit(newCriteria);
    },
    [filterCriteria, handleTableFilterSubmit]
  );

  const filterTagList = useMemo(() => {
    let appliedFiltersList = null;
    if (appliedFilters.length > 0) {
      appliedFiltersList = (
        <FilterTagList
          list={filterTagsData}
          onRemove={(item) => {
            onRemoveFilterTag(
              item as keyof RegistrationFilesModalFilterDataType
            );
          }}
          title="Filters"
        />
      );
    }
    return appliedFiltersList;
  }, [appliedFilters.length, filterTagsData, onRemoveFilterTag]);

  const handleCreateNewRegistrationFile = useCallback(async () => {
    await executeCreateNewRegistrationFile({
      asyncFunction: async () => {
        const response = await createRegistrationFile();
        setSelectedRegistrationFileId(response.id);
        setShowCreateRegistrationFileModal(true);
      },
      customErrorMessage: CREATE_REGISTRATION_FILE_ERROR_MESSAGE,
    });
    fetchRegistrationFilesData(filterCriteria);
  }, [
    executeCreateNewRegistrationFile,
    fetchRegistrationFilesData,
    filterCriteria,
  ]);

  const handleDownloadRegistrationFileClick = useCallback(
    async (id: string) => {
      await executeDownloadRegistrationFile({
        asyncFunction: async () => {
          const response = await downloadRegistrationFile(id);
          const blob = new Blob([response.fileContent], { type: 'text/plain' });
          FileSaver.saveAs(blob, response.fileName);
        },
        customErrorMessage: DOWNLOAD_REGISTRATION_FILE_ERROR_MESSAGE,
      });

      function onDownload() {
        setShowCreateRegistrationFileModal(false);
        window.removeEventListener('focus', onDownload);
        fetchRegistrationFilesData(filterCriteria);
      }
      window.addEventListener('focus', onDownload);
    },
    [
      executeDownloadRegistrationFile,
      fetchRegistrationFilesData,
      filterCriteria,
    ]
  );

  const handleDisplaySendRegistrationFileClick = useCallback(
    async (fileId: string) => {
      setSelectedRegistrationFileId(fileId);
      setShowSendRegistrationFileModal(true);
    },
    []
  );

  const handleSendRegistrationFile = useCallback(
    async (recipients: string[]) => {
      await executeSendregistrationFile({
        asyncFunction: async () => {
          await sendRegistrationFile(selectedRegistrationFileId, recipients);
          setShowSendRegistrationFileModal(false);
          toast.success(SEND_REGISTRATION_FILE_SUCCESS_MESSAGE);
        },
        customErrorMessage: SEND_REGISTRATION_FILE_ERROR_MESSAGE,
      });
    },
    [executeSendregistrationFile, selectedRegistrationFileId, toast]
  );

  const handleCloseSendRegistrationFileModal = useCallback(() => {
    setShowSendRegistrationFileModal(false);
  }, []);

  const handleCloseRegistrationFileModal = useCallback(() => {
    setShowCreateRegistrationFileModal(false);
  }, []);

  const navigateToMyDevices = useCallback(() => {
    navigate('/my-devices');
  }, [navigate]);

  return (
    <BoxLayout
      className="is-min-height-80 mx-10 my-4"
      header={
        <Element className="p-4">
          <BackButton onClick={navigateToMyDevices} />
          <Columns marginless>
            <Columns.Column className="pl-0">
              <h1 className="is-size-3 has-text-weight-light">
                Device registration
              </h1>
              <h3 className="is-size-5 has-text-weight-light">
                Create and email a registration file which zSpace devices
                already on the field can use to enroll into the current system
              </h3>
            </Columns.Column>
            <CheckPermissions
              permissions={
                RegistrationFilePermissions.REGISTRATION_FILES_CREATE
              }
            >
              <CheckPermissions.Render>
                <Columns.Column
                  display="flex"
                  justifyContent="flex-end"
                  className="gap-3 is-narrow"
                >
                  <Button
                    color="primary-dark"
                    onClick={handleCreateNewRegistrationFile}
                    isExecutingAction={isLoadingCreateNewRegistrationFile}
                  >
                    <Button.LoadingIcon icon={FaPlus} />
                    <span>Create new registration file</span>
                  </Button>
                </Columns.Column>
              </CheckPermissions.Render>
            </CheckPermissions>
          </Columns>

          <If condition={!!filterTagList}>
            <Element mt={3}>{filterTagList}</Element>
          </If>
        </Element>
      }
    >
      <Conditional condition={isDataLoading}>
        <Conditional.True>
          <Element display="flex" justifyContent="center">
            <PageSpinner />
          </Element>
        </Conditional.True>
        <Conditional.False>
          <RegistrationFilesTable
            dataSource={tableData}
            config={tableConfig}
            onChange={handleOnTableChange}
            empty={emptyContent}
            handleDownloadRegistrationFileClick={
              handleDownloadRegistrationFileClick
            }
            isDownloading={isLoadingDownloadRegistrationFile}
            handleSendRegistrationFileClick={
              handleDisplaySendRegistrationFileClick
            }
            form={{
              extra: (
                <Button
                  type="button"
                  color="primary-dark"
                  className="ml-2"
                  outlined={appliedFilters.length === 0}
                  onClick={() => setShowFilterModal(true)}
                >
                  <Icon>
                    <FaFilter />
                  </Icon>
                  <span>
                    Filter
                    {appliedFilters.length > 0 && `(${appliedFilters.length})`}
                  </span>
                </Button>
              ),
              itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
            }}
          />
          <p>
            <Icon className="has-text-primary-dark is-justify-content-start">
              <FaCircleInfo />
            </Icon>
            <span>
              Alternatively, devices can be registered manually on site without
              a registration file.&nbsp;
            </span>
            <Link className="is-underlined has-text-primary" to={'#'}>
              Learn more
            </Link>
          </p>
        </Conditional.False>
      </Conditional>
      <RegistrationFilesFilterModal
        show={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        onSubmit={handleTableFilterSubmit}
        data={filterCriteria}
      />
      <CreateRegistrationFileModal
        show={showCreateRegistrationFileModal}
        id={selectedRegistrationFileId}
        onDownloadFileClick={handleDownloadRegistrationFileClick}
        onSendEmailClick={() => {
          handleDisplaySendRegistrationFileClick(selectedRegistrationFileId);
          setShowCreateRegistrationFileModal(false);
        }}
        onCloseClick={handleCloseRegistrationFileModal}
        isExecutingAction={isLoadingDownloadRegistrationFile}
      />
      <SendRegistrationFileModal
        show={showSendRegistrationFileModal}
        onCancel={handleCloseSendRegistrationFileModal}
        isSendingFile={isLoadingSendRegistrationFile}
        handleSendRegistrationFile={handleSendRegistrationFile}
      />
    </BoxLayout>
  );
}

export function RegistrationFilesPage() {
  const { response } = useLoaderData() as {
    response: Promise<PaginatedAPIResponse<RegistrationFilesTableRow>>;
  };

  return (
    <ProtectedPage
      permissions={RegistrationFilePermissions.REGISTRATION_FILES_VIEW}
    >
      <Suspense fallback={<PageSpinner />}>
        <ErrorHandlingAwait resolve={response}>
          <RegistrationFilesPageContent />
        </ErrorHandlingAwait>
      </Suspense>
    </ProtectedPage>
  );
}

export default RegistrationFilesPage;
