import { formatSalesOrderNumber } from '@zspace/format';
import {
  DeferredResponse,
  FilterType,
  PaginatedAPIResponse,
  PaginatedContentConfig,
  SalesOrder,
  SalesOrdersCriteria,
  SalesOrdersTableModalFilterDataType,
  SelectedSalesOrder,
  SortDirection,
} from '@zspace/types';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaArrowRight, FaFilter } from 'react-icons/fa6';
import {
  LoaderFunction,
  defer,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useNavigation,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import CancelAlertModal from '../../shared/cancel-alert-modal/cancel-alert-modal';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useUpdateUrl from '../../shared/hooks/url';
import { createSearchParams } from '../../shared/url';
import { Button } from '../../ui/button/button';
import FeedbackMessage from '../../ui/feedback-message/feedback-message';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { OnTableChangeData, RowSelection } from '../../ui/table/types';
import TagList from '../../ui/tag-list/tag-list';
import { CreateWorkOrderPageContext } from '../create-work-order-page/types';
import { fetchAvailableSalesOrders } from '../sales-orders-service';
import { SalesOrdersStorageService } from '../sales-orders-storage-service';
import { salesOrderSummaryFilterFields } from '../sales-orders-table-filter-modal/constants';
import SalesOrdersTableFilterModal from '../sales-orders-table-filter-modal/sales-orders-table-filter-modal';
import SalesOrdersTable from '../sales-orders-table/sales-orders-table';

const salesOrdersSessionStorageService = new SalesOrdersStorageService();

const NO_HARDWARE_ERROR_MESSAGE =
  'Work orders must contain at least one sales order with hardware';
const NO_SALES_ORDERS_ERROR_MESSAGE =
  'Work orders must contain at least one sales order';
const CANCEL_ALERT_MODAL_TITLE = 'Cancel work order';
const CANCEL_ALERT_MODAL_SUBTITLE =
  'Are you sure you want to cancel the work order creation process?\nYou will lose all your progress';

export const loader: LoaderFunction = ({ request }) => {
  const searchParams = createSearchParams(request);
  const salesOrdersCriteria: SalesOrdersCriteria = {
    itemsPerPage: Number(searchParams.get('itemsPerPage')) || 10,
    pageNumber: Number(searchParams.get('pageNumber')) || 1,
    search: searchParams.get('search') || '',
    sortBy: searchParams.get('sortBy') || 'number',
    sortDirection:
      (searchParams.get('sortDirection') as SortDirection) || 'ASC',
    opportunityName: searchParams.get('opportunityName') || '',
    opportunityNameFilter:
      (searchParams.get('opportunityNameFilter') as FilterType) ||
      FilterType.CONTAINS,
    account: searchParams.get('account') || '',
    accountFilter:
      (searchParams.get('accountFilter') as FilterType) || FilterType.CONTAINS,
    minTotalSeats: searchParams.get('minTotalSeats') || '',
    maxTotalSeats: searchParams.get('maxTotalSeats') || '',
    salesOrders: searchParams.get('salesOrders')?.split(',') || [],
    closeDateFrom: searchParams.get('closeDateFrom') || '',
    closeDateTo: searchParams.get('closeDateTo') || '',
    parentAccountName: searchParams.get('parentAccountName') || '',
    parentAccountNameFilter:
      (searchParams.get('parentAccountNameFilter') as FilterType) ||
      FilterType.CONTAINS,
    endUser: searchParams.get('endUser')?.split(',') || [],
    minHardwareNumber: '',
    maxHardwareNumber: '',
  };

  const response = fetchAvailableSalesOrders(salesOrdersCriteria);

  return defer({ response });
};

const initialSalesOrderCriteria: SalesOrdersCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  opportunityName: '',
  opportunityNameFilter: FilterType.CONTAINS,
  account: '',
  accountFilter: FilterType.CONTAINS,
  minTotalSeats: '',
  maxTotalSeats: '',
  salesOrders: [],
  closeDateFrom: '',
  closeDateTo: '',
  parentAccountName: '',
  parentAccountNameFilter: FilterType.CONTAINS,
  endUser: [],
  minHardwareNumber: '',
  maxHardwareNumber: '',
};

export function SelectSalesOrderPageContent() {
  const response = useAsyncValue() as PaginatedAPIResponse<SalesOrder>;
  const navigation = useNavigation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const updateUrl = useUpdateUrl();

  const { setFilterTagList } = useOutletContext() as CreateWorkOrderPageContext;

  const [nextButtonPressed, setNextButtonPressed] = useState(false);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [isCancelAlertModalVisible, setIsCancelAlertModalVisible] =
    useState(false);
  const [data, setData] = useState<SalesOrdersCriteria>({
    itemsPerPage: Number(searchParams.get('itemsPerPage')) || 10,
    pageNumber: Number(searchParams.get('pageNumber')) || 1,
    search: searchParams.get('search') || '',
    sortBy: searchParams.get('sortBy') || '',
    sortDirection: (searchParams.get('sortDirection') as SortDirection) || '',
    opportunityName: searchParams.get('opportunityName') || '',
    opportunityNameFilter:
      (searchParams.get('opportunityNameFilter') as FilterType) ||
      FilterType.CONTAINS,
    account: searchParams.get('account') || '',
    accountFilter:
      (searchParams.get('accountFilter') as FilterType) || FilterType.CONTAINS,
    minTotalSeats: searchParams.get('minTotalSeats') || '',
    maxTotalSeats: searchParams.get('maxTotalSeats') || '',
    salesOrders: searchParams.get('salesOrders')?.split(',') || [],
    closeDateFrom: searchParams.get('closeDateFrom') || '',
    closeDateTo: searchParams.get('closeDateTo') || '',
    parentAccountName: searchParams.get('parentAccountName') || '',
    parentAccountNameFilter:
      (searchParams.get('parentAccountNameFilter') as FilterType) ||
      FilterType.CONTAINS,
    endUser: searchParams.get('endUser')?.split(',') || [],
    minHardwareNumber: '',
    maxHardwareNumber: '',
  });
  const [salesOrders, setSalesOrders] = useState<SalesOrder[]>(() =>
    salesOrdersSessionStorageService.get()
  );

  const selectedSalesOrders: SelectedSalesOrder[] = useMemo(
    () => salesOrders.map(transformToSelectedSalesOrder),
    [salesOrders]
  );

  const tableData: SalesOrder[] = useMemo(() => response.data, [response]);

  const tableConfig: PaginatedContentConfig = useMemo(
    () => ({ ...data, pages: response.pages }),
    [data, response.pages]
  );

  const rowSelection: RowSelection = useMemo(() => {
    return {
      onChange: (selectedRowIds) => {
        selectedRowIds.forEach((id) => {
          const foundRow = salesOrders.find((i) => i.id === id);

          if (foundRow) {
            setSalesOrders((oldSelectedSalesOrders) => {
              return oldSelectedSalesOrders.filter((so) => so.id !== id);
            });
          } else {
            const newSalesOrder = tableData.find((so) => so.id === id)!;
            setSalesOrders((oldSalesOrders) => {
              return [...oldSalesOrders, newSalesOrder];
            });
          }
        });
      },
      selectedRowKeys: tableData
        .filter((v) => salesOrders.map((s) => s.id).includes(v.id))
        .map((v) => v.id),
    };
  }, [salesOrders, tableData]);

  const filterTagsData = useMemo(() => {
    return {
      opportunityName: {
        label: 'Opportunity name',
        value:
          data.opportunityName.length > 0
            ? data.opportunityNameFilter
                .toLowerCase()
                .replace('_', ' ')
                .concat(' ', data.opportunityName)
            : '',
      },
      account: {
        label: 'Account',
        value:
          data.account.length > 0
            ? data.accountFilter
                .toLowerCase()
                .replace('_', ' ')
                .concat(' ', data.account)
            : '',
      },
      minTotalSeats: {
        label: 'Min total seats',
        value: data.minTotalSeats,
      },
      maxTotalSeats: {
        label: 'Max total seats',
        value: data.maxTotalSeats,
      },
      salesOrders: {
        label: 'Sales order #',
        value: data.salesOrders.join(', '),
      },
      closeDateFrom: {
        label: 'Close date from',
        value: data.closeDateFrom,
      },
      closeDateTo: {
        label: 'Close date to',
        value: data.closeDateTo,
      },
      parentAccountName: {
        label: 'Parent account name',
        value:
          data.parentAccountName.length > 0
            ? data.parentAccountNameFilter
                .toLowerCase()
                .replace('_', ' ')
                .concat(' ', data.parentAccountName)
            : '',
      },
      endUser: {
        label: 'End user',
        value: data.endUser.join(', '),
      },
    };
  }, [data]);

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const showMissingSalesOrdersError = useMemo(
    () => salesOrders.length === 0 && nextButtonPressed,
    [salesOrders.length, nextButtonPressed]
  );

  const showNoHardwareSalesOrderSelectedError = useMemo(
    () => !selectedSalesOrders.some((so) => so.hardware) && nextButtonPressed,
    [selectedSalesOrders, nextButtonPressed]
  );

  const errorText = useMemo(() => {
    if (showMissingSalesOrdersError) {
      return NO_SALES_ORDERS_ERROR_MESSAGE;
    } else if (showNoHardwareSalesOrderSelectedError) {
      return NO_HARDWARE_ERROR_MESSAGE;
    }
  }, [showMissingSalesOrdersError, showNoHardwareSalesOrderSelectedError]);

  const emptyContentText = useMemo(() => {
    const hasAppliedFilters = data.search !== '' || appliedFilters.length > 0;
    if (hasAppliedFilters) {
      return 'There are no sales orders that match the selected criteria';
    }

    return 'There are no sales orders to select at the moment';
  }, [data.search, appliedFilters]);

  const isDataLoading = useMemo(
    () => navigation.state === 'loading',
    [navigation.state]
  );

  const handleOnTableChange = useCallback(
    async (value: OnTableChangeData<SalesOrder>): Promise<void> => {
      const newData = {
        ...data,
        ...value.config,
        sortBy: value.column?.key || data.sortBy,
      };

      setData(newData);
      updateUrl(newData);
    },
    [data, updateUrl]
  );

  const handleTableFilterSubmit = useCallback(
    (tableFilterValue: SalesOrdersTableModalFilterDataType): void => {
      const newData = { ...data, ...tableFilterValue };
      setData(newData);
      updateUrl(newData);
    },
    [data, updateUrl]
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof SalesOrdersTableModalFilterDataType) => {
      const newData = { ...data };
      newData[filterDataKey] = initialSalesOrderCriteria[
        filterDataKey
      ] as string & string[] & FilterType;
      handleTableFilterSubmit(newData);
    },
    [data, handleTableFilterSubmit]
  );

  const handleSelectedSalesOrdersRemoval = useCallback(
    (soToRemove: SelectedSalesOrder) =>
      setSalesOrders(
        salesOrders.filter((selectedSO) => selectedSO.id !== soToRemove.id)
      ),
    [salesOrders]
  );

  const navigateToWorkOrderList = useCallback(() => {
    navigate('/work-orders/dashboard/drafts');
  }, [navigate]);

  const navigateToCreateWorkOrderDetails = useCallback(() => {
    if (
      selectedSalesOrders.length > 0 &&
      selectedSalesOrders.some((so) => so.hardware)
    ) {
      navigate('/work-orders/create/details');
    } else {
      setNextButtonPressed(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [selectedSalesOrders, navigate]);

  useEffect(() => {
    salesOrdersSessionStorageService.save(salesOrders);
  }, [salesOrders]);

  useEffect(() => {
    let appliedFiltersList = null;
    if (appliedFilters.length > 0) {
      appliedFiltersList = (
        <FilterTagList
          list={filterTagsData}
          onRemove={(item) => {
            onRemoveFilterTag(
              item as keyof SalesOrdersTableModalFilterDataType
            );
          }}
          title="Filters"
        />
      );
    }
    setFilterTagList(appliedFiltersList);
  }, [
    appliedFilters.length,
    filterTagsData,
    onRemoveFilterTag,
    setFilterTagList,
  ]);

  if (isDataLoading) {
    return (
      <Element display="flex" justifyContent="center">
        <PageSpinner />
      </Element>
    );
  }

  return (
    <>
      <TagList
        key="number"
        list={selectedSalesOrders}
        renderItem={(item) => formatSalesOrderNumber(item.number)}
        onRemove={handleSelectedSalesOrdersRemoval}
        title={<span className="mx-2">Selected</span>}
        itemColor={(_) => 'primary'}
        colorVariant={'dark'}
      />
      <SalesOrdersTable
        dataSource={tableData}
        onChange={handleOnTableChange}
        config={tableConfig}
        empty={
          <span className="my-2 is-block has-text-centered has-text-weight-light">
            {emptyContentText}
          </span>
        }
        form={{
          extra: (
            <Button
              type="button"
              color="primary-dark"
              className="ml-2"
              outlined={appliedFilters.length === 0}
              onClick={() => setIsFilterModalVisible(true)}
            >
              <Icon>
                <FaFilter />
              </Icon>
              <span>
                Filter
                {appliedFilters.length > 0 && `(${appliedFilters.length})`}
              </span>
            </Button>
          ),
        }}
        rowSelection={rowSelection}
        errorMessage={
          (showMissingSalesOrdersError ||
            showNoHardwareSalesOrderSelectedError) && (
            <Element mb={3}>
              <FeedbackMessage>{errorText}</FeedbackMessage>
            </Element>
          )
        }
      />

      <section className="is-flex is-justify-content-flex-end mt-8">
        <Button
          mr={4}
          color="primary-dark"
          outlined
          onClick={() => setIsCancelAlertModalVisible(true)}
        >
          Cancel
        </Button>
        <Button color="primary-dark" onClick={navigateToCreateWorkOrderDetails}>
          <span>Next</span>
          <Icon>
            <FaArrowRight />
          </Icon>
        </Button>
      </section>

      <SalesOrdersTableFilterModal
        fields={salesOrderSummaryFilterFields}
        show={isFilterModalVisible}
        onSubmit={handleTableFilterSubmit}
        onClose={() => setIsFilterModalVisible(false)}
        data={data}
      />

      <CancelAlertModal
        show={isCancelAlertModalVisible}
        title={CANCEL_ALERT_MODAL_TITLE}
        subtitle={CANCEL_ALERT_MODAL_SUBTITLE}
        onCancel={navigateToWorkOrderList}
        onClose={() => setIsCancelAlertModalVisible(false)}
      />
    </>
  );
}

export function SelectSalesOrderPage() {
  const { response } = useLoaderData() as DeferredResponse<
    PaginatedAPIResponse<SalesOrder>
  >;

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <SelectSalesOrderPageContent />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default SelectSalesOrderPage;

function transformToSelectedSalesOrder(
  salesOrder: SalesOrder
): SelectedSalesOrder {
  return {
    ...salesOrder,
    hardware: salesOrder.lines.some((line) => Boolean(line.device)),
  };
}
