import { DeviceGroup } from '@zspace/types';
import { useMemo } from 'react';
import { Element } from 'react-bulma-components';
import Button from '../../ui/button/button';
import Modal from '../../ui/modal/modal';

const WARNING_CONFIRMATION_TEXT = 'Are you sure you want to continue?';
const CANCEL_BUTTON_TEXT = 'No, cancel';

export type RemoveDevicesModalProps = {
  show: boolean;
  deviceGroupsToBeEmptied: DeviceGroup[];
  onRemoveDevicesFromDeviceGroup: () => void;
  onCancel: () => void;
  loading: boolean;
  title: string;
  confirmationButtonText: string;
};

export function RemoveDevicesModal({
  show,
  deviceGroupsToBeEmptied,
  onRemoveDevicesFromDeviceGroup,
  onCancel,
  loading,
  title,
  confirmationButtonText,
}: RemoveDevicesModalProps) {
  const groupNames = useMemo(
    () => deviceGroupsToBeEmptied.map((group) => group.name),
    [deviceGroupsToBeEmptied]
  );

  return (
    <Modal show={show}>
      <Element
        paddingless
        display="flex"
        flexDirection="column"
        className="gap-8"
      >
        <Element
          paddingless
          display="flex"
          flexDirection="column"
          className="gap-6"
        >
          <h1 className="has-text-weight-light is-size-4">{title}</h1>
        </Element>

        <p>
          By removing the selected device(s) from{' '}
          <span className="has-text-weight-bold">{groupNames.join(', ')}</span>{' '}
          you will no longer be able to see
          {deviceGroupsToBeEmptied.length === 1 ? ' it' : ' them'}.
        </p>
        <p>
          If you want to add new devices to
          {deviceGroupsToBeEmptied.length === 1 ? ' it' : ' them'}, you will
          have to do it using the group code.
        </p>
        <p>{WARNING_CONFIRMATION_TEXT}</p>

        <Element display="flex" justifyContent="space-between">
          <Button color="primary-dark" outlined onClick={onCancel}>
            {CANCEL_BUTTON_TEXT}
          </Button>
          <Button
            color="primary-dark"
            onClick={onRemoveDevicesFromDeviceGroup}
            isExecutingAction={loading}
            loading={loading}
          >
            <span>{confirmationButtonText}</span>
          </Button>
        </Element>
      </Element>
    </Modal>
  );
}

export default RemoveDevicesModal;
