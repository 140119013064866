import { SVGProps } from 'react';
const Headset = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#0A0A0A"
        d="M2.25 12A9.749 9.749 0 0 1 12 2.25 9.749 9.749 0 0 1 21.75 12v6.755c0 1.036-.84 1.875-1.875 1.875l-5.175-.005a2.244 2.244 0 0 0-1.95-1.125h-1.5a2.25 2.25 0 0 0 0 4.5h1.5c.834 0 1.56-.455 1.95-1.125l5.175.005a4.13 4.13 0 0 0 4.13-4.125V12C24 5.372 18.628 0 12 0 5.372 0 0 5.372 0 12v1.875C0 14.498.502 15 1.125 15s1.125-.502 1.125-1.125V12Z"
        opacity={0.4}
      />
      <path
        fill="#267ABF"
        d="M6.75 9.75c-1.655 0-3 1.345-3 3V15c0 1.655 1.345 3 3 3h.75c.83 0 1.5-.67 1.5-1.5v-5.25c0-.83-.67-1.5-1.5-1.5h-.75Zm10.5 0h-.75c-.83 0-1.5.67-1.5 1.5v5.25c0 .83.67 1.5 1.5 1.5h.75c1.655 0 3-1.345 3-3v-2.25c0-1.655-1.345-3-3-3Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Headset;
