import { useEffect } from 'react';
import { Element } from 'react-bulma-components';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import Footer from '../../ui/footer/footer';
import Navbar from '../../ui/navbar/navbar';
import ApiErrorElement from '../api-error-element/api-error-element';
import Conditional from '../conditional/conditional';
import useError from '../hooks/error';
import useUser from '../hooks/user';
import PermissionsErrorCard from '../permissions-error-card/permissions-error-card';
import styles from './layout.module.scss';

function RootLayout() {
  const { error, clearError } = useError();
  const { user } = useUser();
  const location = useLocation();

  useEffect(() => {
    clearError();
  }, [clearError, location.key]);

  return (
    <Element className={styles.app}>
      <ScrollRestoration />
      <Navbar />
      <Element className={styles.content}>
        <Conditional condition={!error}>
          <Conditional.True>
            <Conditional condition={!!user.activeRole}>
              <Conditional.True>
                <Outlet />
              </Conditional.True>
              <Conditional.False>
                <PermissionsErrorCard />
              </Conditional.False>
            </Conditional>
          </Conditional.True>
          <Conditional.False>
            <ApiErrorElement />
          </Conditional.False>
        </Conditional>
      </Element>
      <Footer />
    </Element>
  );
}

export default RootLayout;
