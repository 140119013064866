import {
  ApplicationVariantType,
  DeferredResponse,
  MySoftwareCriteria,
  MySoftwareTab,
  PaginatedAPIResponse,
  SalesOrder,
  SoftwareAvailabilityStatus,
} from '@zspace/types';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Element } from 'react-bulma-components';
import {
  defer,
  LoaderFunction,
  useAsyncValue,
  useLoaderData,
  useNavigation,
  useOutletContext,
} from 'react-router-dom';
import ErrorHandlingAwait from '../../shared/error-handling-await/error-handling-await';
import useHttpRequest from '../../shared/hooks/http-request';
import { fetchMySoftwareSalesOrders as fetchMySoftwareSalesOrdersRequest } from '../../software/software-service';
import PageSpinner from '../../ui/page-spinner/page-spinner';
import { MySoftwarePageOutletContext } from '../my-software-page/my-software-page';
import SoftwareInboxTable from '../software-inbox-table/software-inbox-table';

const initialCriteria: MySoftwareCriteria = {
  itemsPerPage: 10,
  pageNumber: 1,
  search: '',
  sortBy: '',
  availabilityStatus: SoftwareAvailabilityStatus.ALL,
  type: ApplicationVariantType.WEB,
  salesOrders: [],
};

const FETCH_MY_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE =
  'My software could not be fetched. Please try again';

export const loader: LoaderFunction = () => {
  const response = fetchMySoftwareSalesOrdersRequest(initialCriteria);
  return defer({ response });
};

export function MySoftwareWebBasedTabContent() {
  const { setActiveTab } = useOutletContext<MySoftwarePageOutletContext>();
  const navigation = useNavigation();

  const mySoftwareSalesOrdersInitialResponse =
    useAsyncValue() as PaginatedAPIResponse<SalesOrder>;

  const [mySoftwareSalesOrders, setMySoftwareSalesOrders] = useState<
    SalesOrder[]
  >(mySoftwareSalesOrdersInitialResponse.data);
  const [
    mySoftwareSalesOrdersDataResponse,
    setMySoftwareSalesOrdersDataResponse,
  ] = useState<PaginatedAPIResponse<SalesOrder>>(
    mySoftwareSalesOrdersInitialResponse
  );

  const { executeHttpRequest: executeFetchMoreMySoftwareSalesOrdersRequest } =
    useHttpRequest();

  const hasMoreSalesOrders = useMemo(
    () => !!mySoftwareSalesOrdersDataResponse.hasMore,
    [mySoftwareSalesOrdersDataResponse.hasMore]
  );

  const [mySoftwareFilterCriteria, setMySoftwareFilterCriteria] =
    useState<MySoftwareCriteria>(initialCriteria);

  const isDataLoading = useMemo(
    () => navigation.state === 'loading',
    [navigation.state]
  );

  const fetchMoreMySoftwareSalesOrders = useCallback(
    () =>
      executeFetchMoreMySoftwareSalesOrdersRequest({
        asyncFunction: async () => {
          const nextPage = mySoftwareFilterCriteria.pageNumber + 1;
          const updatedMySoftwareFilterCriteria = {
            ...mySoftwareFilterCriteria,
            pageNumber: nextPage,
          };
          const mySoftwareSalesOrdersResponse =
            await fetchMySoftwareSalesOrdersRequest(
              updatedMySoftwareFilterCriteria
            );
          setMySoftwareFilterCriteria(updatedMySoftwareFilterCriteria);
          setMySoftwareSalesOrders((prevSalesOrders) =>
            prevSalesOrders.concat(mySoftwareSalesOrdersResponse.data)
          );
          setMySoftwareSalesOrdersDataResponse(mySoftwareSalesOrdersResponse);
        },
        customErrorMessage: FETCH_MY_SOFTWARE_SALES_ORDERS_ERROR_MESSAGE,
      }),
    [executeFetchMoreMySoftwareSalesOrdersRequest, mySoftwareFilterCriteria]
  );

  useEffect(() => {
    setActiveTab(MySoftwareTab.WEB_BASED_SOFTWARE);
  }, [setActiveTab]);

  return (
    <Element display="flex" flexDirection="column" className="gap-4">
      <span>
        These software titles are managed by their respective End User. Contact
        them or your admin to request access
      </span>
      <SoftwareInboxTable
        salesOrders={mySoftwareSalesOrders}
        fetchMoreSalesOrders={fetchMoreMySoftwareSalesOrders}
        hasMoreSalesOrders={hasMoreSalesOrders}
        loadingInitialData={isDataLoading}
        mySoftwareCriteriaFilter={mySoftwareFilterCriteria}
      />
    </Element>
  );
}

export function MySoftwareWebBasedTab() {
  const { response } = useLoaderData() as DeferredResponse<
    PaginatedAPIResponse<SalesOrder>
  >;

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorHandlingAwait resolve={response}>
        <MySoftwareWebBasedTabContent />
      </ErrorHandlingAwait>
    </Suspense>
  );
}

export default MySoftwareWebBasedTab;
