import { FilterOption, filter } from '@zspace/data';
import { capitalizeString, formatSalesOrderNumber } from '@zspace/format';
import {
  AllYesNoFilter,
  Criteria,
  Device,
  DeviceAssignmentsModalFilterDataType,
  FilterType,
  HardwareModel,
  PaginatedContentConfig,
} from '@zspace/types';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Element, Icon } from 'react-bulma-components';
import { FaFilter } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import Button from '../../ui/button/button';
import FilterTagList from '../../ui/filter-tag-list/filter-tag-list';
import PaginatedTable from '../../ui/table/paginated-table/paginated-table';
import { Column, OnTableChangeData } from '../../ui/table/types';
import DeviceAssignmentsFilterModal from '../filter-modals/device-assignments-filter-modal/device-assignments-filter-modal';
import { filterDevices } from '../filter-modals/filters';

const NO_SOFTWARE_ASSIGNED = 'No software assigned';

const filterOptions: FilterOption<Device>[] = [
  {
    getter: (el) => el.name ?? el.serialNumber?.number ?? el.defaultName,
    searchable: true,
  },
  {
    getter: (el) => String(el.salesOrderLine.salesOrder.number),
    searchable: true,
  },
  {
    getter: (el) => el.model.displayName,
    searchable: true,
  },
  {
    getter: (el) => el.deviceGroup?.name ?? '-',
    searchable: true,
  },
  {
    getter: (el) =>
      el.assignments
        .filter((assignment) => assignment.softwareSeat)
        .map(
          (assignment) =>
            assignment.softwareSeat.licensingProductGroup.displayName
        )
        .join(', '),
    searchable: true,
  },
];

const initialDeviceAssignmentsCriteria: DeviceAssignmentsModalFilterDataType = {
  deviceType: HardwareModel.ALL,
  softwareAssigned: AllYesNoFilter.ALL,
  softwareTitle: '',
  softwareTitleFilter: FilterType.CONTAINS,
  salesOrders: [],
};

export type DeviceAssignmentsTableProps = {
  data: Device[];
  disableSearch?: boolean;
  setFilters?: Dispatch<SetStateAction<ReactNode>>;
};

const INITIAL_ITEMS_PER_PAGE = 50;
const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 50];

export function DeviceAssignmentsTable({
  data,
  disableSearch = false,
  setFilters,
}: DeviceAssignmentsTableProps) {
  const [tableCriteria, setTableCriteria] = useState<Criteria>({
    itemsPerPage: INITIAL_ITEMS_PER_PAGE,
    pageNumber: 1,
    search: '',
    sortBy: '',
  });
  const [filterCriteria, setFilterCriteria] =
    useState<DeviceAssignmentsModalFilterDataType>(
      initialDeviceAssignmentsCriteria
    );
  const [isFilterModalVisible, setIsFilterModalVisible] =
    useState<boolean>(false);

  const tableData = useMemo(() => {
    // Filter by modal fields
    const modalFilteredData = filterDevices(data, filterCriteria);
    // Filter by table columns
    return filter(modalFilteredData, tableCriteria, filterOptions);
  }, [data, filterCriteria, tableCriteria]);

  const tablePageData = useMemo(() => {
    return tableData.slice(
      (tableCriteria.pageNumber - 1) * tableCriteria.itemsPerPage,
      tableCriteria.pageNumber * tableCriteria.itemsPerPage
    );
  }, [tableCriteria.itemsPerPage, tableCriteria.pageNumber, tableData]);

  const deviceTypeOptions = useMemo(() => {
    const deviceTypes = data.map((device) => ({
      label: device.model.displayName,
      value: device.model.displayName,
    }));
    return deviceTypes.filter(
      (device, index) =>
        deviceTypes.findIndex((d) => d.value === device.value) === index
    );
  }, [data]);

  const filterTagsData = useMemo(() => {
    return {
      deviceType: {
        label: 'Device type',
        value:
          filterCriteria.deviceType !== HardwareModel.ALL
            ? capitalizeString(filterCriteria.deviceType)
            : '',
      },
      softwareAssigned: {
        label: 'Has software assigned',
        value:
          filterCriteria.softwareAssigned !== AllYesNoFilter.ALL
            ? capitalizeString(filterCriteria.softwareAssigned)
            : '',
      },
      softwareTitle: {
        label: 'Assigned software title',
        value: filterCriteria.softwareTitle.length
          ? filterCriteria.softwareTitleFilter
              .toLowerCase()
              .replace('_', ' ')
              .concat(' ', filterCriteria.softwareTitle)
          : '',
      },
      salesOrders: {
        label: 'Sales order #',
        value: filterCriteria.salesOrders.join(', '),
      },
    };
  }, [filterCriteria]);

  const handleTableFilterSubmit = useCallback(
    (value: DeviceAssignmentsModalFilterDataType): void => {
      setFilterCriteria(value);
      setTableCriteria((oldCriteria) => ({
        ...oldCriteria,
        pageNumber: 1,
      }));
    },
    []
  );

  const onRemoveFilterTag = useCallback(
    (filterDataKey: keyof DeviceAssignmentsModalFilterDataType) => {
      const newData = { ...filterCriteria };
      newData[filterDataKey] = initialDeviceAssignmentsCriteria[
        filterDataKey
      ] as string & string[] & boolean;
      handleTableFilterSubmit(newData);
    },
    [filterCriteria, handleTableFilterSubmit]
  );

  const appliedFilters = useMemo(() => {
    return Object.entries(filterTagsData).filter(
      ([_, { value }]) => value.length > 0
    );
  }, [filterTagsData]);

  const tableForm = useMemo(
    () => ({
      debounceMillis: 0,
      disableSearch: disableSearch,
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      extra: (
        <Button
          type="button"
          color="primary-dark"
          className="ml-2"
          outlined={appliedFilters.length === 0}
          onClick={() => setIsFilterModalVisible(true)}
        >
          <Icon>
            <FaFilter />
          </Icon>
          <span>
            Filter
            {appliedFilters.length > 0 && `(${appliedFilters.length})`}
          </span>
        </Button>
      ),
    }),
    [appliedFilters.length, disableSearch]
  );

  const tableConfig = useMemo(() => {
    return {
      ...tableCriteria,
      pages: Math.ceil(tableData.length / tableCriteria.itemsPerPage),
    };
  }, [tableCriteria, tableData.length]);

  useEffect(() => {
    if (appliedFilters.length > 0) {
      setFilters?.(
        <FilterTagList
          list={filterTagsData}
          onRemove={(item: string) =>
            onRemoveFilterTag(
              item as keyof DeviceAssignmentsModalFilterDataType
            )
          }
          title="Filters"
        />
      );
    } else {
      setFilters?.(null);
    }
  }, [appliedFilters.length, filterTagsData, onRemoveFilterTag, setFilters]);

  const columns: Column<Device>[] = useMemo(
    () => [
      {
        key: 'serialNumber.number',
        widthClassname: 'is-size-20',
        render: (el) => {
          let deviceIdentifier = el.serialNumber?.number;
          if (!deviceIdentifier) {
            deviceIdentifier = el.name ?? el.defaultName;
          }
          return <div>{deviceIdentifier}</div>;
        },
        title: 'Identifier',
        sortable: false,
        noWrap: true,
      },
      {
        key: 'salesOrderLine.salesOrder.number',
        widthClassname: 'is-size-15',
        render: (el) => (
          <Link to={`/sales-orders/${el.salesOrderLine.salesOrder.id}`}>
            {formatSalesOrderNumber(el.salesOrderLine.salesOrder.number)}
          </Link>
        ),
        title: 'Sales order',
        sortable: true,
      },
      {
        key: 'model.name',
        widthClassname: 'is-size-15',
        render: (el) => el.model.displayName,
        title: 'Device type',
        noWrap: true,
      },
      {
        key: 'group',
        widthClassname: 'is-size-20',
        render: (el) => el.deviceGroup?.name ?? '',
        title: 'Device group',
      },
      {
        key: 'software',
        widthClassname: 'is-size-25',
        render: (el) => {
          const assignedSoftwareTitles = el.assignments
            .filter((assignment) => assignment.softwareSeat)
            .map(
              (assignment) =>
                assignment.softwareSeat.licensingProductGroup.displayName
            );
          if (assignedSoftwareTitles.length === 0) return NO_SOFTWARE_ASSIGNED;
          return assignedSoftwareTitles.join(', ');
        },
        title: 'Original software configuration',
      },
    ],
    []
  );

  const handleTableChange = (value: OnTableChangeData<Device>) => {
    const newTableCriteria: PaginatedContentConfig = {
      ...tableCriteria,
      ...value.config,
      sortBy: value.column?.key || tableCriteria.sortBy,
    };

    setTableCriteria(newTableCriteria);
  };

  const emptyContent = useMemo(() => {
    const emptyContentText =
      appliedFilters.length > 0 || tableConfig.search !== ''
        ? 'There are no devices that match the selected criteria'
        : 'There are no devices at the moment';
    return (
      <Element display="flex" justifyContent="center" className="my-2">
        <span className="has-text-weight-light">{emptyContentText}</span>
      </Element>
    );
  }, [appliedFilters.length, tableConfig.search]);

  return (
    <>
      <PaginatedTable
        form={tableForm}
        dataSource={tablePageData}
        columns={columns}
        onChange={handleTableChange}
        config={tableConfig}
        rowKey={'id'}
        empty={emptyContent}
      />
      <DeviceAssignmentsFilterModal
        show={isFilterModalVisible}
        onSubmit={handleTableFilterSubmit}
        onClose={() => setIsFilterModalVisible(false)}
        data={filterCriteria}
        deviceTypeOptions={deviceTypeOptions}
      />
    </>
  );
}

export default DeviceAssignmentsTable;
